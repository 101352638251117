import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import MbmLeaseReturnEditUtil from 'modules/MbmLeaseReturnEditUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'mbm-lease-return-edit-form',
    SITE_NAME: 'site-name',
    CANCELBUTTON: 'cancelbtn',
    VIEWEDITBUTTON: 'viewEdit',
};

export default class MbmLeaseReturnEdit {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.getEditRecord();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'mbmLeaseReturnEdit';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.MbmLeaseReturnEditUtil = new MbmLeaseReturnEditUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    getEditRecord() {
        const param = new URLSearchParams(window.location.search);
        const uniqueId = param.get('ref');
        const url = '/bin/microsites/mb-lease-edit-record';
        const data = {
            uniqueId,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            // console.log(success);
            this.setFormData(success);
        });
    }

    setFormData(success) {
        console.log(this);
        console.log('Set form data called');
        const param = new URLSearchParams(window.location.search);
        const mbmleaseResponse = JSON.parse(JSON.parse(success));
        document.getElementById('referenceNumber').value = mbmleaseResponse.referenceNumber;
        document.getElementById('mbmLeaseNumber').value = mbmleaseResponse.mbmLeaseNumber;
        document.getElementById('leaseDate').value = mbmleaseResponse.leaseDate;
        document.getElementById('action').value = mbmleaseResponse.action;
        document.getElementById('custLastName').value = mbmleaseResponse.custLastName;
        document.getElementById('year').value = mbmleaseResponse.year;
        document.getElementById('model').value = mbmleaseResponse.model;
        document.getElementById('color').value = mbmleaseResponse.color;
        document.getElementById('mileage').value = this.MbmLeaseReturnEditUtil.addCommas(mbmleaseResponse.mileage);
        document.getElementById('price').value = this.MbmLeaseReturnEditUtil.addCommas(mbmleaseResponse.price);
        document.getElementById('vin').value = mbmleaseResponse.vin;
        document.getElementById('serviceCampaigns').value = mbmleaseResponse.serviceCampaigns;
        document.getElementById('location').value = mbmleaseResponse.location;
        document.getElementById('type').value = mbmleaseResponse.type;
        document.getElementById('pickupDate').value = mbmleaseResponse.pickupDate;
        document.getElementById('notes').value = mbmleaseResponse.notes;
        document.getElementById('payoff').value = this.MbmLeaseReturnEditUtil.addCommas(mbmleaseResponse.payoff);
        document.getElementById(mbmleaseResponse.gradeCheck).checked = 'true';
        if (param.has('mode')) {
            console.log('view if called');
            document.getElementById('form-heading').innerHTML = 'MBM Lease Returns View';
            document.getElementById('form-subheading').style.display = 'none';
            document.getElementById('mbmLeaseNumber').setAttribute('disabled', 'disabled');
            document.getElementById('leaseDate').setAttribute('disabled', 'disabled');
            document.getElementById('action').setAttribute('disabled', 'disabled');
            document.getElementById('custLastName').setAttribute('disabled', 'disabled');
            document.getElementById('year').setAttribute('disabled', 'disabled');
            document.getElementById('model').setAttribute('disabled', 'disabled');
            document.getElementById('color').setAttribute('disabled', 'disabled');
            document.getElementById('mileage').setAttribute('disabled', 'disabled');
            document.getElementById('price').setAttribute('disabled', 'disabled');
            document.getElementById('vin').setAttribute('disabled', 'disabled');
            document.getElementById('serviceCampaigns').setAttribute('disabled', 'disabled');
            document.getElementById('location').setAttribute('disabled', 'disabled');
            document.getElementById('type').setAttribute('disabled', 'disabled');
            document.getElementById('pickupDate').setAttribute('disabled', 'disabled');
            document.getElementById('notes').setAttribute('disabled', 'disabled');
            document.getElementById('payoff').setAttribute('disabled', 'disabled');
            document.getElementById(mbmleaseResponse.gradeCheck).setAttribute('disabled', 'disabled');
            document.getElementById('buttons').style.display = 'none';
            document.getElementById('view-form-buttons').removeAttribute('style');
        } else {
            document.getElementById('form-heading').innerHTML = 'MBM Lease Returns Edit Form';
            document.getElementById('form-subheading').removeAttribute('style');
            document.getElementById('mbmLeaseNumber').removeAttribute('disabled');
            document.getElementById('leaseDate').removeAttribute('disabled');
            document.getElementById('action').removeAttribute('disabled');
            document.getElementById('custLastName').removeAttribute('disabled');
            document.getElementById('year').removeAttribute('disabled');
            document.getElementById('model').removeAttribute('disabled');
            document.getElementById('color').removeAttribute('disabled');
            document.getElementById('mileage').removeAttribute('disabled');
            document.getElementById('price').removeAttribute('disabled');
            document.getElementById('vin').removeAttribute('disabled');
            document.getElementById('serviceCampaigns').removeAttribute('disabled');
            document.getElementById('location').removeAttribute('disabled');
            document.getElementById('type').removeAttribute('disabled');
            document.getElementById('pickupDate').removeAttribute('disabled');
            document.getElementById('notes').removeAttribute('disabled');
            document.getElementById('payoff').removeAttribute('disabled');
            document.getElementById(mbmleaseResponse.gradeCheck).removeAttribute('disabled');
            document.getElementById('buttons').removeAttribute('style');
            document.getElementById('view-form-buttons').style.display = 'none';
            this.loadCaptcha();
        }
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const gradeCheckBox = document.getElementsByName('gradeCheck');
                const checkedgrade = [];

                for (let i = 0; i < gradeCheckBox.length; i += 1) {
                    if (gradeCheckBox[i].checked) {
                        checkedgrade.push(gradeCheckBox[i].value);
                    }
                }

                const gradeChkErr = document.getElementById('gradeCheckBox');
                let gradeCk = false;

                for (let i = 0; i < gradeCheckBox.length; i += 1) {
                    if (gradeCheckBox[i].checked) {
                        gradeCk = true;
                        break;
                    }
                }

                if (gradeCk) {
                    gradeChkErr.classList.add('mbm-lease-return-form__error-field--hidden');
                } else {
                    gradeChkErr.classList.remove('mbm-lease-return-form__error-field--hidden');
                }

                const capRes = grecaptcha.getResponse();
                if (this.MbmLeaseReturnEditUtil.validateAllFields() && capRes.length !== 0) {
                    document.getElementById('mileage').value = this.MbmLeaseReturnEditUtil.removeComma(document.getElementById('mileage').value);
                    document.getElementById('price').value = this.MbmLeaseReturnEditUtil.removeComma(document.getElementById('price').value);
                    document.getElementById('payoff').value = this.MbmLeaseReturnEditUtil.removeComma(document.getElementById('payoff').value);
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('mbm edit form');
                    const formToJSONObject = elements => [].reduce.call(elements,
                            (data, element) => {
                                data[element.name] = element.value;
                                return data;
                            }, {});

                    console.log(formToJSONObject(inputs));

                    const editUrl = `${window.location.origin}/content/MBMLeaseEditForm.html?ref=`;
                    const siteName = 'mbmLeaseReturnEdit';
                    const url = '/bin/microsites/mbm-lease-return-edit';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                        checkedgrade,
                        editUrl,
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.MbmLeaseReturnEditUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            form.reset();
                            this.MbmLeaseReturnEditUtil.displayPopUp('Values Updated successfully');

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.MbmLeaseReturnEditUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="mbm-lease-return-form__error-field">This field is required.</span>';
                    this.MbmLeaseReturnEditUtil.validateAllFields();
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.CANCELBUTTON}`)) {
                e.preventDefault();
                window.close();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.VIEWEDITBUTTON}`)) {
                e.preventDefault();
                const param = new URLSearchParams(window.location.search);
                const mbmleaseRefNum = param.get('ref');
                const currentUrl = window.location.href;
                if (!currentUrl) return;
                const editUrl = `${currentUrl.split('?')[0]}?ref=${mbmleaseRefNum}`;
                window.location.assign(editUrl);
            }
        });
    }
}
