import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    CATEGORY_SECTION: 'category--section',
    CATEGORY_SECTION_BLOCK: 'category--section-block',
    CATEGORY_FILTERS: 'category--filters',
    CATEGORY_FILTER_LIST: 'category--filters-list',
    CATEGORY_FILTER_ITEM: 'category--filters-list-item',
    CATEGORY_FILTER_ITEM_ACTIVE: 'category--filters-list-item--active',
    HIDDEN: 'hidden',
    LATEST_NEWS_SECTION: 'latest-news--section',
    LATEST_NEWS_SECTION_BLOCK: 'latest-news--section-block',
    LATEST_NEWS_DATE: 'latest-news--section-date',
    LATEST_NEWS_FEED: 'latest-news--section-feed',
    LATEST_NEWS_IMAGE: 'latest-news--section-image',
    VEHICLE_MODEL_SECTION: 'vehicle-model__section',
    VEHICLE_MODEL_SECTION_BLOCK: 'vehicle-model__section-block',
    VEHICLE_MODEL__TITLE: 'vehicle-model__title',
    VEHICLE_MODEL__DROPDOWN__TITLE: 'vehicle-model__dropdown__title',
    ICON_CARET_DROPDOWN_SMALL: 'icon-caret-down-small',
    VEHICLE_MODEL__DROPDOWN: 'vehicle-model__dropdown',
    VEHICLE_MODEL__DROPDOWN_OPEN: 'vehicle-model__dropdown--open',
    VEHICLE_MODEL__DROPDOWN_MENU: 'vehicle-model__dropdown__menu',
    VEHICLE_MODEL__DROPDOWN_MENU_ITEMS: 'vehicle-model__dropdown__menu-items',
    VEHICLE_MODEL__IMAGE_SECTION: 'vehicle-model__image_section',
    VEHICLE_MODEL__IMAGE: 'vehicle-model__image',
    VEHICLE_MODEL__DROPDOWN_LINK: 'vehicle-model__dropdown__link',
    VEHICLE_MODEL__DROPDOWN_LINK_YEAR: 'vehicle-model__dropdown__link--year',
    VEHICLE_MODEL__DROPDOWN_LINK_CODE: 'vehicle-model__dropdown__link--code',
    VEHICLE_MODEL__SECTION_TITLE: 'vehicle-model__section-title'
};

export default class FilterByCategory {
    constructor(element) {
        this.element = element;
        this.filters = [];
        this.newsLatest = false;
        this.url = '';
        this.type = '';
        this.init();
    }
    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    setUrl(url, type) {
        this.url = url;
        this.type = type;
        this.loadJSON();
    }

    loadJSON() {
        this.Ajax.readTextFile(this.url, (data) => {
            if (data) {
                // Success!
                const jsonData = JSON.parse(data);
                [].forEach.call(jsonData, (item) => {
                    if (this.type === 'getVehicles') {
                        // Get html content of vehicle model
                        this.getVehicles(item);
                    } else if (this.type === 'getNews') {
                        // Get html content of news
                        this.getNews(item);
                    }
                });

                // News Filters
                this.filters = this.filters.filter((value, index) =>
                    this.filters.indexOf(value) === index
                );
                [].forEach.call(this.filters, (currentFilter) => {
                    const newsFilter = currentFilter.replace(/ &/g, '').replace(/ /g, '-').toLowerCase();
                    document.querySelector(`.${CLASSES.CATEGORY_FILTERS}`).innerHTML += `<li id="${newsFilter}" class="${CLASSES.CATEGORY_FILTER_LIST}"><a class="${CLASSES.CATEGORY_FILTER_ITEM}" href="#${newsFilter}">${currentFilter}</a></li>`;
                });

                // Hide item Latest if no latest news
                if (this.newsLatest === false && document.querySelector(`.${CLASSES.CATEGORY_FILTER_ITEM}[href="#latest"]`)) {
                    document.querySelector(`.${CLASSES.CATEGORY_FILTER_ITEM}[href="#latest"]`).parentElement.remove();
                }
            } else {
                console.log('Error! ');
            }
        });
    }

    attachEvents() {
        console.log('attachEvents: ', this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.CATEGORY_FILTER_ITEM}`)) {
                e.preventDefault();
                const selectedFilter = e.target.getAttribute('href').replace('#', '');
                this.toggleFilterCategories(selectedFilter);
            }
        });
    }

    toggleFilterCategories(selectedFilter) {
        console.log('toggleFilterCategories: ', this);
        const filtersArray = Array.from(document.querySelectorAll(`.${CLASSES.CATEGORY_FILTER_ITEM}`));
        filtersArray.forEach((item) => {
            item.classList.remove(`${CLASSES.CATEGORY_FILTER_ITEM_ACTIVE}`);
        });

        const newsArray = Array.from(document.querySelectorAll(`.${CLASSES.CATEGORY_SECTION_BLOCK}`));
        newsArray.forEach((newsBlock) => {
            newsBlock.classList.add(`${CLASSES.HIDDEN}`);
        });

        document.querySelector(`.${CLASSES.CATEGORY_FILTER_ITEM}[href="#${selectedFilter}"]`).classList.add(`${CLASSES.CATEGORY_FILTER_ITEM_ACTIVE}`);

        const selectedNewsArray = Array.from(document.querySelectorAll(`.${CLASSES.CATEGORY_SECTION_BLOCK}[data-category*="${selectedFilter}"]`));
        selectedNewsArray.forEach((selectedNewsBlock) => {
            selectedNewsBlock.classList.remove(`${CLASSES.HIDDEN}`);
        });
    }

    // get latest news content
    getNews(currentNews) {
        if (currentNews.latest && currentNews.latest === true) {
            this.newsLatest = true;
        }
        this.filters.push(`${currentNews.type}`);

        // News content
        const newsImage = currentNews.type.replace(/ &/g, '').replace(/ /g, '-').toLowerCase();
        let newsPath = '';
        if (currentNews.imageUrl && currentNews.imageUrl !== '') {
            newsPath = currentNews.imageUrl;
        } else {
            newsPath = `/content/dam/microsites/mb-collision-centers/images/${newsImage}.png`;
        }
        if (currentNews.latest === undefined || currentNews.latest === false) {
            currentNews.latest = '';
        } else {
            currentNews.latest = 'latest';
        }
        let news = `<div class="${CLASSES.CATEGORY_SECTION_BLOCK} ${CLASSES.LATEST_NEWS_SECTION_BLOCK}" data-category="all ${currentNews.latest} ${newsImage}">`;
        news += `<a href="${currentNews.url}" target="_blank" rel="external noopener noreferrer">`;
        news += `<img class="${CLASSES.LATEST_NEWS_IMAGE}" src="${newsPath}" alt="${currentNews.type}" /></a>`;
        news += `<p class="${CLASSES.LATEST_NEWS_DATE}">${currentNews.publishedDate}</p>`;
        news += `<h4 class="${CLASSES.LATEST_NEWS_FEED}">${currentNews.title}</h4>`;
        news += '</div>';
        document.querySelector(`.${CLASSES.LATEST_NEWS_SECTION}`).innerHTML += news;

        this.toggleFilterCategories('latest');
    }

    // get vehicle model content
    getVehicles(currentVehicle) {
        this.filters.push(`${currentVehicle.bodyStyle}`);
        const currentVehicleStyle = currentVehicle.bodyStyle.replace(/ &/g, '').replace(/ /g, '-').toLowerCase();

        let vehicle = `<div class="vehicle-model__section-block ${CLASSES.CATEGORY_SECTION_BLOCK}" data-category="all ${currentVehicleStyle}">`;
        vehicle += `<h4 class="${CLASSES.VEHICLE_MODEL__SECTION_TITLE}">${currentVehicle.bodyStyleClass}</h4>`;
        vehicle += `<div class="${CLASSES.VEHICLE_MODEL__IMAGE_SECTION}">`;
        if (currentVehicle.imageUrl) {
            vehicle += `<img class="${CLASSES.VEHICLE_MODEL__IMAGE}" src="${currentVehicle.imageUrl}" alt="${currentVehicle.bodyStyle}" />`;
        }
        vehicle += '</div>';
        if (currentVehicle.models) {
            vehicle += '<hr/>';
            vehicle += `<div class="${CLASSES.VEHICLE_MODEL__DROPDOWN}">`;
            vehicle += `<p class="${CLASSES.VEHICLE_MODEL__DROPDOWN__TITLE}">`;
            vehicle += `<span class="${CLASSES.ICON_CARET_DROPDOWN_SMALL}"></span>`;
            vehicle += 'Body Structure';
            vehicle += '</p>';
            vehicle += `<ul class="${CLASSES.VEHICLE_MODEL__DROPDOWN_MENU}">`;
            [].forEach.call(currentVehicle.models, (model) => {
                vehicle += `<li class="${CLASSES.VEHICLE_MODEL__DROPDOWN_MENU_ITEMS}">`;
                vehicle += '<a ';
                if (model.link && model.link !== '') {
                    vehicle += `href="${model.link}" `;
                }
                vehicle += `class="${CLASSES.VEHICLE_MODEL__DROPDOWN_LINK}" target="_self">`;
                vehicle += `<span class="${CLASSES.VEHICLE_MODEL__DROPDOWN_LINK_YEAR}">`;
                if (model.endYear === '0') model.endYear = 'Present';
                vehicle += `${model.startYear}-${model.endYear}`;
                vehicle += '</span>';
                vehicle += `<span class="${CLASSES.VEHICLE_MODEL__DROPDOWN_LINK_CODE}">`;
                vehicle += `${model.code}`;
                vehicle += '</span>';
                vehicle += '</a>';
                vehicle += '</li>';
            });
            vehicle += '</ul>';
            vehicle += '</div>';
        }
        vehicle += '</div>';

        document.querySelector(`.${CLASSES.VEHICLE_MODEL_SECTION}`).innerHTML += vehicle;
    }
}
