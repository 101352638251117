export default class MediaIconTitleAnimation {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        const height = $(window).innerHeight();
        const titleContainerList = document.querySelectorAll('.media-rich-icon-list');
        let count = Array(titleContainerList.length).fill(1);
        titleContainerList.forEach((titleContainer) => {
            const applyAnimatin = Boolean(titleContainer.getAttribute('data-animation'));
            if (applyAnimatin) {
                this.makeHeaderAnimation(titleContainer);
            }
        });

        $(window).on('scroll', () => {
            var scrollTopValue = $(window).scrollTop();
            titleContainerList.forEach((titleContainer, index) => {
                const applyAnimatin = Boolean(titleContainer.getAttribute('data-animation'));
                if (!applyAnimatin) {
                    return;
                }
                const elementScroll = titleContainer.offsetTop;
                const elementHeight = titleContainer.clientHeight;
                if ((scrollTopValue + height) > (elementScroll + elementHeight)) {
                    if (count[index]) {
                        this.makeHeaderAnimation(titleContainer);
                        count[index] = 0;
                    }
                    if(scrollTopValue > (elementScroll + elementHeight)) {
                        count[index] = 1;
                    }
                }else {
                    count[index] = 1;
                }
            })
        });
    }

    makeHeaderAnimation(titleElement) {
        const selector = [titleElement];
        selector.forEach((elem) => {
            const headingValue = elem.querySelectorAll('input.heading-text')[0];
            const headingElement = elem.getElementsByTagName('h1')[0];
            headingElement.innerText = headingValue.value;
            let words = headingElement.innerText;
            words = words.split(' ');

            headingElement.innerHTML = '';
            const isUnderLine = headingElement.style.textDecoration === 'underline';
            words.forEach((el, index) => {
                const spanTag = document.createElement('span');
                if (isUnderLine) {
                    spanTag.classList.add('span-underline');
                }
                const singleSpace = document.createTextNode("\u00A0");
                const iTag = document.createElement('i');
                iTag.innerText = words[index];
                if (index < (words.length - 1)) {
                    iTag.appendChild(singleSpace);
                }
                spanTag.appendChild(iTag);
                headingElement.appendChild(spanTag);
            });
            const children = headingElement.querySelectorAll('i');
            children.forEach((node, index) => {
                node.style.animationDelay = (index * .2) + 's';
            });
        });
    }
}
