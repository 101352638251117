import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import MbmLeaseReturnEditUtil from 'modules/MbmLeaseReturnEditUtil';

const ID = {
    SITE_NAME: 'site-name',
    SEARCH: 'search',
    FILTER: 'mbmlrfilter',
    SHOW: 'lrreport_length',
    VIEWBUTTON: 'viewbutton',
    SEARCHBUTTON: 'searchBtn',
    DISPLAY_RESULT: 'mblease-listing',
    NOT_FOUND_RESULT: 'mblease-listing-not-found-error',
    PAGIN: 'pagin',
    SHOWING: 'showing',

};

let currentFilter = 'referenceNumber';
let prevFilter = '';
let orderAsc = true;
let sessionData = '';
let filterData = '';
let searchData = '';
let sessionDataDouble = '';
let searchDataDouble = '';
let filterDataDouble = '';
let totalentries = '';
let index = 0;

const CLASSES = {
    PAGINATION__INDEX_ANCHOR: 'mb-lease-report__index--anchor',
    PAGINATION__INDEX_ACTIVE: 'mb-lease-report__index--active',
    PAGINATION__INDEX_DISABLED: 'mb-lease-report__index--disabled',
};

export default class MbmLeaseReport {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.ajaxcall();
    }


    loadModules() {
        this.MbmLeaseReturnEditUtil = new MbmLeaseReturnEditUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    ajaxcall() {
        document.getElementById('mb-lease-report').style.display = 'none';
        const siteName = 'mbm-lease-form-report';
        const url = '/bin/microsites/mb-lease-report';
        const data = {
            siteName,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            setTimeout(() => {
                const reportList = JSON.parse(success);
                sessionData = reportList;
                const pages = Math.ceil(sessionData.length / 10);
                document.getElementById('loader').style.display = 'none';
                document.getElementById('mblease-listing-loader').style.display = 'none';
                document.getElementById('mb-lease-report').style.display = 'block';
                document.getElementById('mb-lease-report').style.width = '100%';
                const doublearraydata = this.dividepages(reportList, 10, pages);
                sessionDataDouble = doublearraydata;
                this.sort(doublearraydata, 'referenceNumber');
                this.handleSortClick();
            }, 5000);
        });
    }

    sort(reportList) {
        let singlearray = [];
        let finaldata = [];
        const items = reportList[0].length;
        console.log(items);
        const pages = reportList.length;
        console.log(pages);
        for (let i = 0; i < reportList.length; i += 1) {
            singlearray = singlearray.concat(reportList[i]);
        }
        totalentries = singlearray.length;
        console.log(totalentries);
        const sortdata = this.sortTable(singlearray, currentFilter);
        if (items < 10) {
            finaldata = this.dividepages(sortdata.reverse(), 10, pages);
        } else {
            finaldata = this.dividepages(sortdata.reverse(), items, pages);
        }
        this.displayDetailsPage(finaldata, totalentries);
        prevFilter = currentFilter;
        this.handlePaginationClick(finaldata);
        return finaldata;
    }

    dividepages(array, items, pages) {
        console.log(this);
        const finaldata = [];
        let x = 0;
        const extra = (array.length) % items;
        if (extra === 0 && array.length === items) {
            const temp = [];
            for (let i = 0; i < array.length; i += 1) {
                temp.push(array[x]);
                x += 1;
            }
            finaldata.push(temp);
            return finaldata;
        }

        for (let i = 0; i < pages - 1; i += 1) {
            const temp = [];
            for (let j = 0; j < items; j += 1) {
                temp.push(array[x]);
                x += 1;
            }
            finaldata.push(temp);
        }
        const temp = [];
        for (let i = 0; i < extra; i += 1) {
            temp.push(array[x]);
            x += 1;
        }
        finaldata.push(temp);
        return finaldata;
    }

    sortTable(array, sortKey) {
        console.log(this);
        return array.sort((a, b) => {
            const c = JSON.parse(a);
            const d = JSON.parse(b);
            const x = c[sortKey];
            const y = d[sortKey];

            if (sortKey === 'referenceNumber') {
                return orderAsc ? x.substring(2) - y.substring(2) : y.substring(2) - x.substring(2);
            }

            if (sortKey === 'year' || sortKey === 'mileage') {
                return orderAsc ? x - y : y - x;
            }

            if (orderAsc) {
                return x.toLocaleLowerCase().localeCompare(y.toLocaleLowerCase());
            }
            return y.toLocaleLowerCase().localeCompare(x.toLocaleLowerCase());
        });
    }

    handleSortClick() {
        const filters = document.querySelectorAll('#reportTable th');
        Array.prototype.forEach.call(filters, (filter) => {
            filter.addEventListener('click', () => {
                if (!filter.dataset.filterValue) {
                    return false;
                }
                Array.prototype.forEach.call(filters, (fltr) => {
                    fltr.classList.remove('active');
                });
                filter.classList.add('active');
                currentFilter = filter.dataset.filterValue;
                if (currentFilter === prevFilter) {
                    orderAsc = !orderAsc;
                } else {
                    orderAsc = true;
                }
                console.log(orderAsc);
                if (filterData !== '') {
                    return this.sort(filterDataDouble);
                } else if (searchData !== '') {
                    return this.sort(searchDataDouble);
                }
                return this.sort(sessionDataDouble);
            });
        });
    }

    handlePaginationClick(sortdata) {
        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`)) {
                e.preventDefault();
                index = e.target.getAttribute('data-filter-value');
                console.log(index);
                this.displayDetailsPage(sortdata, totalentries);
            }
        });
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (e) => {
            if (e.target && e.target.matches(`#${ID.SEARCH}`)) {
                e.preventDefault();
                filterData = '';
                filterDataDouble = '';
                searchData = '';
                searchDataDouble = '';
                index = 0;
                const form = document.getElementById(`${ID.SEARCH}`).value;
                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                if (form.trim().length === 0) {
                    document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                    document.getElementById(`${ID.PAGIN}`).style.display = '';
                    document.getElementById(`${ID.SHOWING}`).style.display = '';
                    document.getElementById(`${ID.FILTER}`).options[0].selected = true;
                    document.getElementById(`${ID.SHOW}`).options[0].selected = true;
                    const pages = Math.ceil(sessionData.length / 10);
                    const doublearraydata = this.dividepages(sessionData, 10, pages);
                    this.sort(doublearraydata, 'referenceNumber');
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target && e.target.matches(`#${ID.SEARCHBUTTON}`)) {
                e.preventDefault();
                filterData = '';
                filterDataDouble = '';
                index = 0;
                const form = document.getElementById(`${ID.SEARCH}`).value;
                if (form.trim().length === 0) {
                    document.getElementById('emptysearch').classList.remove('mbm-lease-return-form__error-field--hidden');
                } else {
                    document.getElementById('emptysearch').classList.add('mbm-lease-return-form__error-field--hidden');
                    document.getElementById('mblease-listing-loader').style.display = '';
                    document.getElementById(`${ID.PAGIN}`).style.display = 'none';
                    document.getElementById(`${ID.SHOWING}`).style.display = 'none';
                    document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
                    document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                    document.getElementById(`${ID.FILTER}`).options[0].selected = true;
                    document.getElementById(`${ID.SHOW}`).options[0].selected = true;
                    const url = '/bin/microsites/mb-lease-report-search';
                    const data = {
                        formData: form,
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        setTimeout(() => {
                            document.getElementById('mblease-listing-loader').style.display = 'none';
                            const reportList = JSON.parse(success);
                            searchData = reportList;
                            if (reportList.length > 0) {
                                const pages = Math.ceil(reportList.length / 10);
                                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                                document.getElementById(`${ID.PAGIN}`).style.display = '';
                                document.getElementById(`${ID.SHOWING}`).style.display = '';
                                const doublearraydata = this.dividepages(searchData, 10, pages);
                                searchDataDouble = doublearraydata;
                                this.sort(doublearraydata, 'referenceNumber');
                            } else {
                                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
                                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = '';
                                const searchErrorDisplay = document.getElementById(`${ID.NOT_FOUND_RESULT}`);
                                searchErrorDisplay.innerHTML = '<p class="mb-lease-report__error-field">No matching records found</p>';
                            }
                        }, 2000);
                    });
                }
            }
        });


        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.FILTER}`)) {
                e.preventDefault();
                index = 0;
                searchData = '';
                searchDataDouble = '';
                document.getElementById('emptysearch').classList.add('mbm-lease-return-form__error-field--hidden');
                document.getElementById('mblease-listing-loader').style.display = '';
                document.getElementById(`${ID.PAGIN}`).style.display = 'none';
                document.getElementById(`${ID.SHOWING}`).style.display = 'none';
                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                document.getElementById(`${ID.SEARCH}`).value = '';
                document.getElementById(`${ID.SEARCH}`).placeholder = 'Search by Lease Num/ Name/ Model/ VIN ';
                document.getElementById(`${ID.SHOW}`).options[0].selected = true;
                const inputs = document.getElementById(`${ID.FILTER}`).value;
                if (inputs === 'All') {
                    setTimeout(() => {
                        filterData = '';
                        filterDataDouble = '';
                        document.getElementById('mblease-listing-loader').style.display = 'none';
                        document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                        document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                        document.getElementById(`${ID.PAGIN}`).style.display = '';
                        document.getElementById(`${ID.SHOWING}`).style.display = '';
                        const pages = Math.ceil(sessionData.length / 10);
                        const doublearraydata = this.dividepages(sessionData, 10, pages);
                        this.sort(doublearraydata, 'referenceNumber');
                    }, 200);
                }

                if (inputs === 'Inspect' || inputs === 'Return' || inputs === 'Keep for Retail' || inputs === 'Keep for wholesale') {
                    const url = '/bin/microsites/mb-lease-report-filter';
                    const data = {
                        formData: inputs,
                    };
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        setTimeout(() => {
                            document.getElementById('mblease-listing-loader').style.display = 'none';
                            const reportList = JSON.parse(success);
                            filterData = reportList;
                            if (reportList.length > 0) {
                                const pages = Math.ceil(reportList.length / 10);
                                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                                document.getElementById(`${ID.PAGIN}`).style.display = '';
                                document.getElementById(`${ID.SHOWING}`).style.display = '';
                                const doublearraydata = this.dividepages(filterData, 10, pages);
                                filterDataDouble = doublearraydata;
                                this.sort(doublearraydata, 'referenceNumber');
                            } else {
                                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
                                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = '';
                                const searchErrorDisplay = document.getElementById(`${ID.NOT_FOUND_RESULT}`);
                                searchErrorDisplay.innerHTML = '<p class="mb-lease-report__error-field">No matching records found</p>';
                            }
                        }, 2000);
                    });
                }
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.SHOW}`)) {
                e.preventDefault();
                index = 0;
                document.getElementById('mblease-listing-loader').style.display = '';
                document.getElementById('emptysearch').classList.add('mbm-lease-return-form__error-field--hidden');
                document.getElementById(`${ID.PAGIN}`).style.display = 'none';
                document.getElementById(`${ID.SHOWING}`).style.display = 'none';
                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                const inputs = document.getElementById(`${ID.SHOW}`).value;
                setTimeout(() => {
                    document.getElementById('mblease-listing-loader').style.display = 'none';
                    document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                    if (inputs === '-1') {
                        document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                        document.getElementById(`${ID.PAGIN}`).style.display = '';
                        document.getElementById(`${ID.SHOWING}`).style.display = '';
                        if (filterData === '' && searchData === '') {
                            const items = sessionData.length;
                            const doublearraydata = this.dividepages(sessionData, items, 1);
                            sessionDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        } else if (filterData !== '') {
                            const items = filterData.length;
                            const doublearraydata = this.dividepages(filterData, items, 1);
                            filterDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        } else if (searchData !== '') {
                            const items = searchData.length;
                            const doublearraydata = this.dividepages(searchData, items, 1);
                            searchDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        }
                    }

                    if (inputs === '25' || inputs === '50' || inputs === '10') {
                        document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                        document.getElementById(`${ID.PAGIN}`).style.display = '';
                        document.getElementById(`${ID.SHOWING}`).style.display = '';
                        if (filterData === '' && searchData === '') {
                            const pages = Math.ceil(sessionData.length / inputs);
                            const doublearraydata = this.dividepages(sessionData, inputs, pages);
                            sessionDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        } else if (filterData !== '') {
                            const pages = Math.ceil(filterData.length / inputs);
                            const doublearraydata = this.dividepages(filterData, inputs, pages);
                            filterDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        } else if (searchData !== '') {
                            const pages = Math.ceil(searchData.length / inputs);
                            const doublearraydata = this.dividepages(searchData, inputs, pages);
                            searchDataDouble = doublearraydata;
                            this.sort(doublearraydata, 'referenceNumber');
                        }
                    }
                }, 200);
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`#${ID.VIEWBUTTON}`)) {
                e.preventDefault();
                const mbmleaseRefNum = e.target.getAttribute('data-ref');
                const edit = document.getElementById('editLink').value;
                const editUrl = `${edit}?ref=${mbmleaseRefNum}&mode=view`;
                window.open(editUrl);
            }
        });
    }

    CheckPagination() {
        console.log(this);
        console.log(index);
        const checkIndex = parseInt(index, 10);
        console.log(checkIndex);
        const paginationAnchors = document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`);
        [].forEach.call(paginationAnchors, (e) => {
            if (parseInt(e.dataset.filterValue, 10) === checkIndex) {
                e.classList.add(`${CLASSES.PAGINATION__INDEX_ACTIVE}`);
            }
        });
        const firstIndex = paginationAnchors[0].getAttribute('data-filter-value');
        const lastIndex = paginationAnchors[(paginationAnchors.length) - 1].getAttribute('data-filter-value');

        if (firstIndex === lastIndex || checkIndex === parseInt(firstIndex, 10)) {
            // previousDisable
            paginationAnchors[1].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
        }
        if (checkIndex === parseInt(lastIndex, 10)) {
            // nextDisable
            paginationAnchors[(paginationAnchors.length) - 2].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
        }
    }

    displayDetailsPage(data) {
        console.log(this);
        const doubleArray = data;
        let htmlpagedisplay = '';
        let i;
        let htmlBuild = '';
        let htmlShowing = '';
        const resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
        const paginDisplay = document.getElementById(`${ID.PAGIN}`);
        const showingDisplay = document.getElementById(`${ID.SHOWING}`);
        const nextIndex = parseInt(index, 10) + 1;
        const prevIndex = parseInt(index, 10) - 1;
        const start = Math.max(0, index - 2);
        const end = Math.min(start + 5, doubleArray.length - 1);
        const mbmleasePage = doubleArray[index];
        const entriesPerPage = mbmleasePage.length;
        for (let key = 0; key < entriesPerPage; key += 1) {
            const mbmleaseRecord = JSON.parse(mbmleasePage[key]);
            const referenceNum = mbmleaseRecord.referenceNumber;
            const leasenumber = mbmleaseRecord.mbmLeaseNumber;
            const date = mbmleaseRecord.leaseDate;
            const action = mbmleaseRecord.action;
            const name = mbmleaseRecord.custLastName;
            const year = mbmleaseRecord.year;
            const model = mbmleaseRecord.model;
            const color = mbmleaseRecord.color;
            const dbmileage = mbmleaseRecord.mileage;
            const mileage = this.MbmLeaseReturnEditUtil.addCommas(dbmileage);
            const dbprice = mbmleaseRecord.price;
            const price = '$'.concat(this.MbmLeaseReturnEditUtil.addCommas(dbprice));
            const vin = mbmleaseRecord.vin;
            const Mbmlocation = mbmleaseRecord.location;
            const pickupDate = mbmleaseRecord.pickupDate;
            const notes = mbmleaseRecord.notes;
            htmlBuild += `<tr role="row" id="reportRow" class="mb-lease-report__border_rule">
                    <td colspan="1" class="mb-lease-report__td_padding"><a href="#" title="View/Edit Record" id="viewbutton" target="_blank" rel="noopener noreferrer" data-ref=${referenceNum}>${leasenumber}</a></td>
                    <td colspan="1" class="mb-lease-report__td_padding">${date}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${action}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${name}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${year}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${model}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${color}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${mileage}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${price}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${vin}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${Mbmlocation}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${pickupDate}</td>
                    <td colspan="1" class="mb-lease-report__td_padding">${notes}</td>
                    </tr>`;
        }


        if ((i + 1) !== 1) {
            htmlpagedisplay += (`<button value="${1}"  class="mb-lease-report__index--anchor" data-filter-value=${0}>First</button>`);
        }
        if (start !== -1) {
            htmlpagedisplay += (`<button value="${start - 1}"  class="mb-lease-report__index--anchor" data-filter-value=${prevIndex}>&laquo;</button>`);
        }
        for (i = start; i <= end; i += 1) {
            htmlpagedisplay += (`<button value="${i + 1}"  class="mb-lease-report__index--anchor" data-filter-value=${i}>${i + 1}</button>`);
        }
        if (start !== end) {
            htmlpagedisplay += (`<button value="${start + 1}"  class="mb-lease-report__index--anchor" data-filter-value=${nextIndex}>&raquo;</button>`);
        }
        if ((i + 1) !== doubleArray.length - 1) {
            htmlpagedisplay += (`<button value="${doubleArray.length - 1}"  class="mb-lease-report__index--anchor" data-filter-value=${doubleArray.length - 1}>Last</button>`);
        }
        // for last page
        const pageindex = parseInt(index, 10);
        if (pageindex === (doubleArray.length - 1)) {
            if (pageindex !== 0) {
                const startentry = (pageindex * doubleArray[pageindex - 1].length) + 1;
                const endentry = totalentries;
                htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
            } else {
                const startentry = (pageindex * entriesPerPage) + 1;
                const endentry = totalentries;
                htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
            }
        } else { // for other pages
            const startentry = (pageindex * entriesPerPage) + 1;
            const endentry = (pageindex + 1) * entriesPerPage;
            htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
        }
        resultDisplay.innerHTML = htmlBuild;
        paginDisplay.innerHTML = htmlpagedisplay;
        showingDisplay.innerHTML = htmlShowing;
        this.CheckPagination();
    }
}
