import { EVENTS } from 'Constants';

const CLASSES = {
    REVISIONS: 'revisions',
    REVISION: 'revision',
    REVISION_HIDDEN: 'revision--hidden',
    REVISIONS_LIST: 'revisions__list'
};
const ID = {
    MORE_BTN: 'revisions__more-btn'
};

export default class DogRevisions {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.moreBtn = document.querySelector(`#${ID.MORE_BTN}`);
        this.revisions = document.querySelector(`.${CLASSES.REVISIONS}`);
        this.revision = document.querySelectorAll(`.${CLASSES.REVISION}`);
        this.revisionList = document.querySelector(`.${CLASSES.REVISIONS_LIST}`);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.MORE_BTN}`)) {
                if (this.moreBtn.innerHTML === 'more') {
                    this.moreBtn.innerHTML = 'less';
                    this.showRevisionList();
                } else if (this.moreBtn.innerHTML === 'less') {
                    this.moreBtn.innerHTML = 'more';
                    this.hideRevisionList();
                    this.revisions.scrollIntoView(true);
                }
            }
        });
    }

    showRevisionList() {
        [].forEach.call(this.revision, (item) => {
            item.classList.remove(`${CLASSES.REVISION_HIDDEN}`);
        });
    }

    hideRevisionList() {
        let tableIndex = this.revision.length - 1;
        while (tableIndex >= 5) {
            this.revision[tableIndex].classList.add(`${CLASSES.REVISION_HIDDEN}`);
            tableIndex -= 1;
        }
    }
}
