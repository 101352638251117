function getColumnHead(columnHead, formType) {
    if (formType === 'fep-eab-filter' && (columnHead === 'stateCode' || columnHead === 'city' || columnHead === 'companyName' || columnHead === 'zip' || columnHead === 'vppType')) {
        return '';
    } else if (formType === 'can-applications') {
        if (columnHead === 'firstName') {
            return `<th class="sort" data-sort="${columnHead}">Officer First Name<span class="icon icon-descendant "></span></th>`;
        } else if (columnHead === 'lastName') {
            return `<th class="sort" data-sort="${columnHead}">Officer Last Name<span class="icon icon-descendant "></span></th>`;
        }
        return `<th class="sort" data-sort="${columnHead}">${columnHead.replace(/([A-Z])/g, ' $1').trim()}<span class="icon icon-descendant "></span></th>`;
    }
    return `<th class="sort" data-sort="${columnHead}">${columnHead.replace(/([A-Z])/g, ' $1').trim()}<span class="icon icon-descendant "></span></th>`;
}

export default (columnHeadList = [], formType) => `
    ${columnHeadList.map(columnHead => `
        ${getColumnHead(columnHead, formType)}
        `).join('')}
`;
