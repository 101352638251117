import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    DOG_WIDGET: 'dog-widget',
    DOG_WIDGET__AMG: 'dog-widget__amg',
    DOG_WIDGET__AMG_ACTIVE: 'dog-widget__amg--active',
    DOG_WIDGET__ARROW: 'dog-widget__arrow'
};

const ID = {
    ORDER_GUIDES: 'order-guides'
};

export default class DogArchive {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.onloadAmgCheck();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.dogWidget = document.querySelector(`.${CLASSES.DOG_WIDGET}`);
        this.dogWidgetAmg = document.querySelector(`.${CLASSES.DOG_WIDGET__AMG}`);
        this.dogWidgetArrow = document.querySelector(`.${CLASSES.DOG_WIDGET__ARROW}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        let amgUrl = location.href;
        let amgUrlParam = '';

        if (amgUrl.indexOf('/amg')) {
            amgUrl = amgUrl.replace('/amg', '');
        }

        amgUrl = amgUrl.split('/');
        amgUrlParam = amgUrl.pop();
        amgUrl = amgUrl.join('/');

        if (this.dogWidgetAmg) {
            if (amgUrlParam.indexOf('.html') > -1) {
                amgUrlParam = amgUrlParam.split('.html');
                amgUrlParam[1] = `.html${amgUrlParam[1]}`;
                this.dogWidgetAmg.setAttribute('data-url', `${amgUrl}/${amgUrlParam[0]}${amgUrlParam[1]}`);
                this.dogWidgetAmg.setAttribute('data-amg-url', `${amgUrl}/${amgUrlParam[0]}/amg${amgUrlParam[1]}`);
            } else if (amgUrlParam.indexOf('?') > -1) {
                amgUrlParam = amgUrlParam.split('?');
                amgUrlParam[1] = `?${amgUrlParam[1]}`;
                this.dogWidgetAmg.setAttribute('data-url', `${amgUrl}/${amgUrlParam[0]}${amgUrlParam[1]}`);
                this.dogWidgetAmg.setAttribute('data-amg-url', `${amgUrl}/${amgUrlParam[0]}/amg${amgUrlParam[1]}`);
            } else if (amgUrlParam.indexOf('#') > -1) {
                amgUrlParam = amgUrlParam.split('#');
                amgUrlParam[1] = `#${amgUrlParam[1]}`;
                this.dogWidgetAmg.setAttribute('data-url', `${amgUrl}/${amgUrlParam[0]}${amgUrlParam[1]}`);
                this.dogWidgetAmg.setAttribute('data-amg-url', `${amgUrl}/${amgUrlParam[0]}/amg${amgUrlParam[1]}`);
            } else {
                this.dogWidgetAmg.setAttribute('data-url', `${amgUrl}/${amgUrlParam}`);
                this.dogWidgetAmg.setAttribute('data-amg-url', `${amgUrl}/${amgUrlParam}/amg`);
            }
        }
    }

    attachEvents() {
        if (this.dogWidgetAmg) {
            this.dogWidgetAmg.addEventListener(EVENTS.CLICK, () => {
                if (this.checkAmg() === false) {
                    this.updateURL(true);
                } else {
                    this.updateURL(false);
                }
            });

            window.addEventListener(EVENTS.SCROLL, () => {
                if (window.pageYOffset + window.innerHeight >= document.querySelector('footer').offsetTop) {
                    const bottom = (window.pageYOffset + window.innerHeight) - document.querySelector('footer').offsetTop;
                    document.querySelector('.dog-widget').style.bottom = `${bottom}px`;
                } else {
                    document.querySelector('.dog-widget').style.bottom = 0;
                }
            });
        }
    }

    onloadAmgCheck() {
        if (this.dogWidgetAmg) {
            if (this.checkAmg() === true) {
                this.dogWidgetAmg.classList.add(`${CLASSES.DOG_WIDGET__AMG_ACTIVE}`);
                document.querySelector('body').setAttribute('data-theme', 'amg');
            } else {
                this.dogWidgetAmg.classList.remove(`${CLASSES.DOG_WIDGET__AMG_ACTIVE}`);
                document.querySelector('body').removeAttribute('data-theme');
            }
        }
        if (document.querySelector(`#${ID.ORDER_GUIDES}`).getAttribute('amg-only') === 'true') {
            document.querySelector('body').setAttribute('data-theme', 'amg');
        }
    }

    checkAmg() {
        console.log(this);
        const queryString = window.location.search;

        if (queryString !== '') {
            const keyword = window.location.href.split('?')[0].split('/');
            const amgKey = keyword[keyword.length - 1];
            if (amgKey.indexOf('amg') >= 0) {
                return true;
            }
            return false;
        }
        const keyword = window.location.href.split('/');
        const amgKey = keyword[keyword.length - 1];

        if (amgKey.indexOf('amg') >= 0) {
            return true;
        }
        return false;
    }

    updateURL(flag) {
        if (!flag) {
            location.href = this.dogWidgetAmg.getAttribute('data-url');
        } else {
            location.href = this.dogWidgetAmg.getAttribute('data-amg-url');
        }
    }
}
