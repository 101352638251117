export default (content) => {
    let formElement = '';
    formElement += `<form action=""id ="bluetecupdate-resend-confirmation-form" method="post">
    <fieldset>
        <legend class="bluetecupdate-form__title">${content.resendConfirmationLink}</legend>
        <div class="bluetecupdate-form__body">
            <div class="bluetecupdate-form__input-group">
                <label class="bluetecupdate-form__input-label"></label>
                <input type="text" name="email" class="bluetecupdate-form__input" placeholder="${content.email}" value="" required>
                <p class="bluetecupdate-form__error-field bluetecupdate-form__error-field--hidden">${content.reenterEmail}</p>
            </div>
            <div class="bluetecupdate-form__input-group">
                <button type="submit" id="blueTecUpdateResendConfirmationBtn" class="button button_primary" value="Resend">${content.resend}</button>
            </div>
        </div>
    </fieldset>
    </form>`;
    return formElement;
};
