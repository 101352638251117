import { CLASSES } from 'Constants';

export default class SiteMap {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.sitemapTemplate();
    }

    cacheDOMElement() {
        this.sitemapJson = document.querySelector(`.${CLASSES.SITEMAP_JSON}`);
        this.sitemapContainer = document.querySelector(`.${CLASSES.SITEMAP_CONTAINER}`);
    }

    sitemapTemplate() {
            if(this.sitemapJson.value){
                const jsonData = JSON.parse(this.sitemapJson.value);
                const len = jsonData.length;
                const level = 1;
                const ulNode = document.createElement('ul');
        
                ulNode.setAttribute('class', 'sitemap__item-container');
                this.sitemapContainer.appendChild(ulNode);
                this.sitemapContainer = this.sitemapContainer.getElementsByTagName('UL')[0];
        
                this.sitemapListStructure(jsonData, len, level);
            } else {
                console.error("this.sitemapJson.value is empty");
            }
    }

    // create list structure from data
    sitemapListStructure(data, len, level) {
        for (let i = 0; i < len; i += 1) {
            // append title on currentElement
            // const liNode = document.createElement('LI');
            // const anchorNode = document.createElement('a');
            // const iconList = document.createElement('span');
            // const itemText = document.createElement('span');
            // const textNode = document.createTextNode(data[i].title);
            // const iconNav = document.createElement('span');

            // const hrefKey = 'href';
            // liNode.setAttribute('class', 'sitemap__listItems');
            // anchorNode.setAttribute(hrefKey, data[i].path);
            // anchorNode.setAttribute('class', 'sitemap__itemLink');
            // iconList.setAttribute('class', 'sitemap__icon sitemap__icon-list icon-web-link');
            // itemText.setAttribute('class', 'sitemap__item-text');
            // iconNav.setAttribute('class', 'sitemap__icon sitemap__icon-nav icon-arrow-right-square');

            // itemText.appendChild(textNode);
            // anchorNode.appendChild(iconList);
            // anchorNode.appendChild(itemText);
            // anchorNode.appendChild(iconNav);
            // liNode.appendChild(anchorNode);
            const liNode = `<li class='sitemap__listItems'>
                <a class='sitemap__itemLink' id='sitemap-path'>
                    <span class='sitemap__icon sitemap__icon-nav icon-arrow-right-square'></span>
                    <span class='sitemap__item-text' id='sitemap-title'></span>
                    <span class='sitemap__icon sitemap__icon-list icon-web-link'></span>
                </a>
            </li>`;
            this.sitemapContainer.innerHTML += liNode;

            this.insertValuesInList(data[i].path, data[i].title);

            if (data[i].childPath.length) {
                level += 1;
                // added sublist element
                const childElementsLength = this.sitemapContainer.children.length;
                const lastChildElement = this.sitemapContainer.children[childElementsLength - 1];
                // const ulNode = document.createElement('UL');
                // ulNode.setAttribute('class', 'sitemap__item-container');
                const ulNode = "<ul class='sitemap__item-container'></ul>"
                lastChildElement.innerHTML += ulNode;

                // udpate currentElement
                this.sitemapContainer = lastChildElement.getElementsByTagName('UL')[0];

                // Recurssive function
                this.sitemapListStructure(data[i].childPath, data[i].childPath.length, level);
                level -= 1;
            }

            // update currentElement one level back
            if (i + 1 === len) {
                this.sitemapContainer = this.sitemapContainer.parentNode.parentNode;
                level -= 1;
            }
        }
    }

    insertValuesInList(path, title) {
        const aTag = $('#sitemap-path');
        const spanTag = $('#sitemap-title');
        const hrefAttr = 'href';
        const pathValue = path;
        const titleValue = title;
        aTag.attr(hrefAttr, pathValue);
        aTag.removeAttr('id');
        spanTag.removeAttr('id');
        spanTag[0].innerText = titleValue;
    }
}
