import { EVENTS } from 'Constants';

const ID = {
    OPEN_POPUP: 'openPopUp',
    CLOSE_POPUP: 'closePopup'
};

export default class VideoPopUp {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.OPEN_POPUP}`)) {
                document.getElementById('popup-container').style.display = 'flex';
            }
        });

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.CLOSE_POPUP}`)) {
                const iframe = document.querySelector('iframe');
                const video = document.querySelector('video');
                if (iframe) {
                    const iframeSrc = iframe.src;
                    iframe.src = iframeSrc;
                }

                if (video) {
                    video.pause();
                }
                document.getElementById('popup-container').style.display = 'none';
            }
        });
    }
}
