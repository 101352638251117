import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import VehicleEndDateUpdateUtil from 'modules/VehicleEndDateUpdateUtil';

const ID = {
    DISPLAY_RESULT: 'stopdrive-dashboard-listing',
    UPDATEBUTTON: 'updateRecord',
    PAGIN: 'pagin',
    SHOWING: 'showing',
    SITE_NAME: 'site-name'
};

let totalentries = '';
let index = 0;

const CLASSES = {
    PAGINATION__INDEX_ANCHOR: 'cvp-dashboard__index--anchor',
    PAGINATION__INDEX_ACTIVE: 'cvp-dashboard__index--active',
    PAGINATION__INDEX_DISABLED: 'cvp-dashboard__index--disabled',
};


export default class StopdriveDashboard {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.htmlBuild = '';
        this.resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
        this.loadModules();
        this.ajaxcall();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.VehicleEndDateUpdateUtil = new VehicleEndDateUpdateUtil(this.element);
    }


    ajaxcall() {
        const dealerCode = document.getElementById('dealerCode').value;
        document.getElementById('Stopdrive-Dashboard').style.display = 'none';
        const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        const option = 'display';
        const url = `/bin/microsites/stopDrive?dealerCode=${dealerCode}`;
        const data = {
            siteName,
            option
        };

        let displayData = [];
        let doublearraydata = [];
        this.Ajax.ajaxPostFn(url, data, (success) => {
            displayData = JSON.parse(success);
            const pages = Math.ceil(displayData.length / 10);
            document.getElementById('loader').style.display = 'none';
            document.getElementById('Stopdrive-Dashboard').style.display = 'block';
            document.getElementById('Stopdrive-Dashboard').style.width = '100%';
            this.sortTable(displayData, 'lastUpdated', false);
            doublearraydata = this.dividepages(displayData, 10, pages);
            this.sort(doublearraydata);
        });

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target && e.target.matches(`#${ID.UPDATEBUTTON}`)) {
                e.preventDefault();
                const key = e.target.getAttribute('data-key');
                const date = e.target.parentElement.nextSibling
                .parentElement.children[2].firstElementChild;
                const breakBoosterVinEle = e.target.parentElement.nextSibling
                .parentElement.children[3].firstElementChild;
                const breakBoosterVin = breakBoosterVinEle.value;
                const endDateValue = date.value.split('-');
                const endDateFinal = `${endDateValue[1]}/${endDateValue[2]}/${endDateValue[0]}`;
                const updateData = {
                    dealerCode: doublearraydata[index][key].dealerCode,
                    startDate: doublearraydata[index][key].startDate,
                    gvinno: doublearraydata[index][key].gvinno,
                    maybachVin: breakBoosterVin,
                    endDate: endDateFinal,
                };
                const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                const formData = {
                    formData: JSON.stringify(updateData),
                    siteName,
                    option: 'update'
                };
                const urlUpdate = '/bin/microsites/stopDrive';
                const nextElement = e.target.nextElementSibling;
                const nexttonextElement = nextElement.nextElementSibling;
                const remove = document.querySelector('.nothidden');
                if (remove !== null) {
                    remove.classList.add('vehicle-end-date-update__error-field--hidden');
                    remove.classList.remove('nothidden');
                }
                if (this.validateDate(updateData.startDate,
                    updateData.endDate, nextElement, nexttonextElement)
                    && this.validateBoosterVin(breakBoosterVinEle, breakBoosterVin)) {
                    this.Ajax.ajaxPostFn(urlUpdate, formData, (success) => {
                        if (success.includes('Record Updated Successfully')) {
                            this.VehicleEndDateUpdateUtil.displayPopUp('Record Updated Successfully');
                        } else {
                            date.value = displayData[key].endDate;
                            this.VehicleEndDateUpdateUtil.displayPopUp(success);
                        }
                    });
                }
            }
        });
    }

    sort(reportList) {
        let singlearray = [];
        let finaldata = [];
        const items = reportList[0].length;
        const pages = reportList.length;
        for (let i = 0; i < reportList.length; i += 1) {
            singlearray = singlearray.concat(reportList[i]);
        }
        totalentries = singlearray.length;
        if (items < 10) {
            finaldata = this.dividepages(singlearray, 10, pages);
        } else {
            finaldata = this.dividepages(singlearray, items, pages);
        }
        this.displayDetailsPage(finaldata, totalentries);
        this.handlePaginationClick(finaldata);
        return finaldata;
    }

    sortTable(array, sortKey, orderAsc) {
        console.log(this);
        array.sort((a1, b1) => {
            const first = new Date(a1[sortKey]);
            const second = new Date(b1[sortKey]);
            if (orderAsc) {
                if (first > second) return 1;
                return -1;
            }
            if (first > second) return -1;
            return 1;
        });
    }

    handlePaginationClick(sortdata) {
        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`)) {
                e.preventDefault();
                index = e.target.getAttribute('data-filter-value');
                this.displayDetailsPage(sortdata, totalentries);
            }
        });
    }

    dividepages(array, items, pages) {
        console.log(this);
        const finaldata = [];
        let x = 0;
        const extra = (array.length) % items;
        if (extra === 0 && array.length % 2 === 0) {
            for (let i = 0; i < pages; i += 1) {
                const temp = [];
                for (let j = 0; j < items; j += 1) {
                    temp.push(array[x]);
                    x += 1;
                }
                finaldata.push(temp);
            }
            return finaldata;
        }

        for (let i = 0; i < pages - 1; i += 1) {
            const temp = [];
            for (let j = 0; j < items; j += 1) {
                temp.push(array[x]);
                x += 1;
            }
            finaldata.push(temp);
        }
        const temp = [];
        for (let i = 0; i < extra; i += 1) {
            temp.push(array[x]);
            x += 1;
        }
        finaldata.push(temp);
        return finaldata;
    }

    CheckPagination() {
        console.log(this);
        const checkIndex = parseInt(index, 10);
        const paginationAnchors = document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`);
        [].forEach.call(paginationAnchors, (e) => {
            if (parseInt(e.dataset.filterValue, 10) === checkIndex) {
                e.classList.add(`${CLASSES.PAGINATION__INDEX_ACTIVE}`);
            }
        });
        const firstIndex = paginationAnchors[0].getAttribute('data-filter-value');
        const lastIndex = paginationAnchors[(paginationAnchors.length) - 1].getAttribute('data-filter-value');

        if (firstIndex === lastIndex || checkIndex === parseInt(firstIndex, 10)) {
            // previousDisable
            paginationAnchors[1].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
        }
        if (checkIndex === parseInt(lastIndex, 10)) {
            // nextDisable
            paginationAnchors[(paginationAnchors.length) - 2].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
        }
    }

    displayDetailsPage(displayData) {
        this.htmlBuild = '';
        let htmlpagedisplay = '';
        let i;
        let htmlShowing = '';
        const paginDisplay = document.getElementById(`${ID.PAGIN}`);
        const showingDisplay = document.getElementById(`${ID.SHOWING}`);
        const nextIndex = parseInt(index, 10) + 1;
        const prevIndex = parseInt(index, 10) - 1;
        const start = Math.max(0, index - 2);
        const end = Math.min(start + 5, displayData.length - 1);
        const mbmleasePage = displayData[index];
        const entriesPerPage = mbmleasePage.length;
        for (let key = 0; key < entriesPerPage; key += 1) {
            const cvpRecord = mbmleasePage[key];
            let impactedVin = cvpRecord.maybachVin;
            if (impactedVin === undefined || impactedVin === null || impactedVin === 'Not Available') {
                impactedVin = '';
            }
            const endDateSplit = cvpRecord.endDate.split('/');
            const endDate = `${endDateSplit[2]}-${endDateSplit[0]}-${endDateSplit[1]}`;
            this.htmlBuild += `<tr role="row"  class="cvp-dashboard__border_rule">
                <td colspan="1" class="cvp-dashboard__td_padding"><p id="startDate" value="${cvpRecord.startDate}">${cvpRecord.startDate}</p></td>
                <td colspan="1" class="cvp-dashboard__td_padding">${cvpRecord.gvinno}</td>
                <td colspan="1" class="cvp-dashboard__td_padding vehicle-end-date-update__group_input"><input type="date" class="vehicle-end-date-update__control" id="endDate" placeholder="G63 End Date" name="endDate" value="${endDate}" required="false"></td>
                <td colspan="1" class="cvp-dashboard__td_padding"><input type="text" class="vehicle-end-date-update__control" placeholder="BB VIN" id="impactedVin" name="impactedVin" value="${impactedVin}" impact-key=${key}></td>
                <td colspan="1" class="cvp-dashboard__td_padding"><button type="submit" id="updateRecord" class="button button_primary" value="Submit" data-key=${key}>Update</button>
                <p class="vehicle-end-date-update__error-field vehicle-end-date-update__error-field--hidden" id="updateerror">End date can't be older than start date</p>
                <p class="vehicle-end-date-update__error-field vehicle-end-date-update__error-field--hidden" id="updatSameeerror">Start and end dates cannot be same. Please choose different date(s)</p>
                </td>
              </tr>`;
        }
        if ((i + 1) !== 1) {
            htmlpagedisplay += (`<button value="${1}"  class="cvp-dashboard__index--anchor" data-filter-value=${0}>First</button>`);
        }
        if (start !== -1) {
            htmlpagedisplay += (`<button value="${start - 1}"  class="cvp-dashboard__index--anchor" data-filter-value=${prevIndex}>&laquo;</button>`);
        }
        for (i = start; i <= end; i += 1) {
            htmlpagedisplay += (`<button value="${i + 1}"  class="cvp-dashboard__index--anchor" data-filter-value=${i}>${i + 1}</button>`);
        }
        if (start !== end) {
            htmlpagedisplay += (`<button value="${start + 1}"  class="cvp-dashboard__index--anchor" data-filter-value=${nextIndex}>&raquo;</button>`);
        }
        if ((i + 1) !== displayData.length - 1) {
            htmlpagedisplay += (`<button value="${displayData.length - 1}"  class="cvp-dashboard__index--anchor" data-filter-value=${displayData.length - 1}>Last</button>`);
        }
        // for last page
        const pageindex = parseInt(index, 10);
        if (pageindex === (displayData.length - 1)) {
            if (pageindex !== 0) {
                const startentry = (pageindex * displayData[pageindex - 1].length) + 1;
                const endentry = totalentries;
                htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
            } else {
                const startentry = (pageindex * entriesPerPage) + 1;
                const endentry = totalentries;
                htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
            }
        } else { // for other pages
            const startentry = (pageindex * entriesPerPage) + 1;
            const endentry = (pageindex + 1) * entriesPerPage;
            htmlShowing += `<div>Showing ${startentry} to ${endentry} of ${totalentries} entries. </div>`;
        }
        this.resultDisplay.innerHTML = this.htmlBuild;
        paginDisplay.innerHTML = htmlpagedisplay;
        showingDisplay.innerHTML = htmlShowing;
        this.CheckPagination();
        this.htmlBuild = '';
    }

    validateDate(startDate, endDate, ele, secondEle) {
        console.log(this);
        let flag = false;
        const startDateValue = new Date(startDate);
        const endDateValue = new Date(endDate);
        if (startDateValue.getTime() > endDateValue.getTime()) {
            ele.classList.remove('vehicle-end-date-update__error-field--hidden');
            ele.classList.add('nothidden');
            secondEle.classList.add('vehicle-end-date-update__error-field--hidden');
        } else if (startDateValue.getTime() === endDateValue.getTime()) {
            secondEle.classList.remove('vehicle-end-date-update__error-field--hidden');
            secondEle.classList.add('nothidden');
            ele.classList.add('vehicle-end-date-update__error-field--hidden');
        } else {
            ele.classList.add('vehicle-end-date-update__error-field--hidden');
            secondEle.classList.add('vehicle-end-date-update__error-field--hidden');
            flag = true;
        }
        return flag;
    }

    validateBoosterVin(element, boosterVin) {
        console.log(this);
        if (boosterVin === '') {
            element.classList.add('vehicle-end-date-update__control--error');
            element.focus();
            return false;
        }
        element.classList.add('vehicle-end-date-update__control--error');
        return true;
    }
}
