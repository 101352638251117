import DropDownContent from './dropdown-content';

export default (item, emissionModificationCompletionDetail, extendedEmissionsWarrantyDetail, campaignStatus, content) => `
    ${Object.keys(item).map((key, index) => {
        let tableColumnItem = '';
        if (campaignStatus.toLowerCase() !== 'pending' && emissionModificationCompletionDetail !== '' && key === 'EM_COMPLETED') {
            tableColumnItem += '<div class="bluetecupdate-form__table-column">';
            tableColumnItem += '<p class="bluetecupdate-form__column-title">';
            tableColumnItem += `${item[key].label}`;
            tableColumnItem += '<span class="icon-arrow-down-square"></span></p>';
            tableColumnItem += `<div class="bluetecupdate-form__column-info ${campaignStatus.toLowerCase() === 'closed' ? 'bluetecupdate-form__column-info--open' : ''}">`;
            tableColumnItem += '<div class="bluetecupdate-form__column-info-heading">';
            tableColumnItem += `<div class="bluetecupdate-form__column-info-column">
            ${content.parts}</div>`;
            tableColumnItem += `<div class="bluetecupdate-form__column-info-column">
            ${content.replaced}</div></div>`;
            tableColumnItem += `${DropDownContent(emissionModificationCompletionDetail,
                item[key].value)}`;
            tableColumnItem += '</div></div>';
            tableColumnItem += `<div class="bluetecupdate-form__table-column">
                                ${item[key].displayValue}</div>`;
        } else if (campaignStatus.toLowerCase() !== 'pending' && extendedEmissionsWarrantyDetail !== '' && key === 'EXTENDED_EMISSION_WARRANTY' && (item[key].value === 'Yes')) {
            tableColumnItem += '<div class="bluetecupdate-form__table-column">';
            tableColumnItem += '<p class="bluetecupdate-form__column-title">';
            tableColumnItem += `${item[key].label}`;
            tableColumnItem += '<span class="icon-arrow-down-square"></span></p>';
            tableColumnItem += `<p class="bluetecupdate-form__column-info ${campaignStatus.toLowerCase() === 'closed' ? 'bluetecupdate-form__column-info--open' : ''}">`;
            tableColumnItem += `${extendedEmissionsWarrantyDetail}`;
            tableColumnItem += '</p></div>';
            tableColumnItem += `<div class="bluetecupdate-form__table-column">
                                ${item[key].displayValue}</div>`;
        } else if (campaignStatus.toLowerCase() === 'pending') {
            if (index < 2) {
                tableColumnItem += `<div class="bluetecupdate-form__table-column">
                    ${item[key].label}</div>`;
                tableColumnItem += `<div class="bluetecupdate-form__table-column">
                    ${item[key].displayValue}</div>`;
            }
        } else {
            tableColumnItem += `<div class="bluetecupdate-form__table-column">
                                ${item[key].label}</div>`;
            tableColumnItem += `<div class="bluetecupdate-form__table-column">
                                ${item[key].displayValue}</div>`;
        }
        return `
            <div class="bluetecupdate-form__table-row">
                ${tableColumnItem}
            </div>
            `;
    }).join('')}
`;
