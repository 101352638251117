// import { EVENTS } from 'Constants';
import ContactUsUtil from 'modules/ContactUsUtil';

const FIELD_NAME = {
    CORPORATE_NAME: 'corporateName',
    CORPORATE_ADDRESS: 'corporateAddress',
    CORPORATE_PARENT_NAME: 'corporateParentName',
    CITY: 'city',
    CORPORATE_WEBSITE_ADDRESS: 'corporateWebsiteAddress',
    CORPORATE_TAX_ID_NUMBER: 'corporateTaxIdNumber',
    ZIP_CODE: 'zipCode',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    BUSINESS_TITLE: 'businessTitle',
    BUSINESS_PHONE_NUMBER: 'phoneNumber',
    FAX_NUMBER: 'faxNumber',
    DEALER_BUSINESS_EMAIL: 'dealerBusinessEmail',
    COMPANY_OFFICER_BUSINESS_EMAIL: 'companyOfficerBusinessEmail',
    ACCOUNT_NUMBER: 'accountNumber',
    DEALER_NAME: 'dealerName',
    DEALER_CODE: 'dealerCode',
    DEALER_CONTACT_FIRST_NAME: 'dealerContactFirstName',
    DEALER_PHONE_NUMBER: 'dealerPhoneNumber',
    DEALER_CONTACT_LAST_NAME: 'dealerContactLastName',
    CONFIRM_DEALER_BUSINESS_EMAIL: 'confirmDealerBusinessEmail',
    CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL: 'confirmCompanyOfficerBusinessEmail'
};

const CLASSES = {
    ACCORDION_TITLE_BLOCK: 'canApplication__accordion-title-block',
    CAN_APPLICATION_INPUT: 'canApplication__input',
    CAN_APPLICATION_INPUT_ERROR: 'canApplication__input--error',
    CAN_APPLICATION_ACCORDION_LIST: 'canApplication__accordion-list',
    CAN_APPLICATION_SHORT: 'canApplication__form--short',
    CAN_APPLICATION_ACCORDION_ACTIVE: 'canApplication__accordion-list--active'
};

export default class CanApplicationPopupFormValidation {
    constructor(element) {
        this.element = element;
        this.inputFields = null;
        this.updateButton = null;
        this.true = true;
        this.false = false;
        this.form = null;
        this.responseStatus = null;
        this.init();
    }

    /*
        Function :  This is called when the js loads.
    */
    init() {
        this.loadModules();
    }

    /*
        Function : To load other js files within this file for functions
                    and method usage.
    */
    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
    }

    validateFields(fields, form) {
        this.inputFields = fields;
        this.form = form;
        let validStatus;

        for (let i = 0; i < fields.length; i += 1) {
            if (fields[i].name === FIELD_NAME.ZIP_CODE ||
                fields[i].name === FIELD_NAME.DEALER_CODE) {
                validStatus = this.validate(fields[i],
                    this.ContactUsUtil.isValidDealerCode(fields[i].value));
            } else if (fields[i].name === FIELD_NAME.CORPORATE_TAX_ID_NUMBER) {
                validStatus = this.validate(fields[i],
                    this.ContactUsUtil.containsExactDigitsWithHyphen(fields[i].value));
            } else if (fields[i].name === FIELD_NAME.BUSINESS_PHONE_NUMBER ||
                fields[i].name === FIELD_NAME.DEALER_PHONE_NUMBER) {
                validStatus = this.validate(fields[i],
                    this.ContactUsUtil.isValidPhoneNumber(fields[i].value));
            } else if (fields[i].name === FIELD_NAME.FAX_NUMBER ||
                fields[i].name === FIELD_NAME.ACCOUNT_NUMBER) {
                validStatus = this.validate(fields[i],
                    this.ContactUsUtil.containDigitsOnly(fields[i].value));
            } else if (fields[i].name === FIELD_NAME.COMPANY_OFFICER_BUSINESS_EMAIL
                || fields[i].name === FIELD_NAME.CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL
                || fields[i].name === FIELD_NAME.DEALER_BUSINESS_EMAIL
                || fields[i].name === FIELD_NAME.CONFIRM_DEALER_BUSINESS_EMAIL) {
                if (this.validate(fields[i], this.ContactUsUtil.isValidEmail(fields[i].value))) {
                    // check if form is short form.
                    if (!this.form.matches(`.${CLASSES.CAN_APPLICATION_SHORT}`)) {
                        const companyConfirmEmailField = document.querySelector(`#${FIELD_NAME.COMPANY_OFFICER_BUSINESS_EMAIL}`);
                        const dealerConfirmEmailField = document.querySelector(`#${FIELD_NAME.DEALER_BUSINESS_EMAIL}`);
                        if (fields[i].name === FIELD_NAME.CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL &&
                            companyConfirmEmailField) {
                            validStatus = this.validate(fields[i], this.checkConfirmEmail(
                                companyConfirmEmailField, fields[i]));
                        } else if (fields[i].name === FIELD_NAME.CONFIRM_DEALER_BUSINESS_EMAIL
                            && dealerConfirmEmailField) {
                            validStatus = this.validate(fields[i], this.checkConfirmEmail(
                                dealerConfirmEmailField, fields[i]));
                        }
                    } else {
                        validStatus = this.true;
                    }
                } else {
                    validStatus = this.false;
                }
            } else {
                validStatus = this.validate(fields[i],
                    this.ContactUsUtil.containData(fields[i].value));
            }
            if (!validStatus) {
                this.setValidationResponse(this.false);
                break;
            }
        }

        if (validStatus) {
            this.setValidationResponse(this.true);
        }
    }

    validate(element, isValid) {
        if (!isValid) {
            element.classList.add(`${CLASSES.CAN_APPLICATION_INPUT_ERROR}`);
            const camel2Title = camelCase => camelCase
                                .replace(/([A-Z])/g, match => ` ${match}`)
                                .replace(/^./, match => match.toUpperCase());
            if (element.name === FIELD_NAME.CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL
                || element.name === FIELD_NAME.CONFIRM_DEALER_BUSINESS_EMAIL) {
                element.setAttribute('title', 'The email you entered do not match or is invalid');
            } else {
                element.setAttribute('title', `Enter a valid ${camel2Title(element.name)}`);
            }
            return this.false;
        }
        element.classList.remove(`${CLASSES.CAN_APPLICATION_INPUT_ERROR}`);
        element.removeAttribute('title');
        return this.true;
    }

    setValidationResponse(response) {
        this.responseStatus = response;
    }

    getValidationResponse() {
        return this.responseStatus;
    }

    checkConfirmEmail(emailField, confirmEmailField) {
        if (confirmEmailField.value === emailField.value) {
            return this.true;
        }
        return this.false;
    }

    focusInvalidField() {
        let invalidField = this.form.querySelector(`.${CLASSES.CAN_APPLICATION_INPUT_ERROR}`);
        let elementToFocus;

        if (!invalidField && !(this.inputFields[0].value)) {
            invalidField = this.inputFields[0];
        }

        elementToFocus = invalidField.parentElement;

        while (!(elementToFocus.matches(`.${CLASSES.CAN_APPLICATION_ACCORDION_LIST}`))) {
            elementToFocus = elementToFocus.parentElement;
        }

        if (!elementToFocus.matches(`.${CLASSES.CAN_APPLICATION_ACCORDION_ACTIVE}`)) {
            elementToFocus.querySelector(`.${CLASSES.ACCORDION_TITLE_BLOCK}`).click();
        }

        invalidField.focus();
    }
}
