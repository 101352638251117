import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import GetStartedUtil from 'modules/GetStartedUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'getstarted-form',
    SITE_NAME: 'site-name',
    LASTNAME: 'lastName',
    FIRSTNAME: 'firstName',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    ZIP_CODE: 'zipCode',
};

export default class GetStartedRequest {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'getstarted-form';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.GetStartedUtil = new GetStartedUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const capRes = grecaptcha.getResponse();
                if (this.GetStartedUtil.validateAllFields() && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('Inside final if');
                    const siteName = 'getStarted-form';
                    const url = '/bin/microsites/getStarted-form/sendEmail';
                    const lastName = document.getElementById(`${ID.LASTNAME}`).value;
                    const firstName = document.getElementById(`${ID.FIRSTNAME}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                    const zipCode = document.getElementById(`${ID.ZIP_CODE}`).value;

                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('lastName', lastName);
                    dataMap.set('firstName', firstName);
                    dataMap.set('email', email);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('zipCode', zipCode);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.GetStartedUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.GetStartedUtil.displayPopUp('Form submission successful');
                            form.reset();
                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.getStarted_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.getStarted_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.GetStartedUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="fmla-request-form__error-field">This field is required.</span>';
                    this.GetStartedUtil.validateAllFields();
                }
            }
        });
    }
}
