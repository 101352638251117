import { EVENTS } from 'Constants';
import ContactUsUtil from 'modules/ContactUsUtil';
import Ajax from 'modules/Ajax';
import MessageBox from 'modules/fleet-info/MessageBox';

const ID = {
    PAGE_LOADER: 'page-loader',
    FEP_EAB_FORM: 'fep-eab',
    FEP_EAB_SUBMIT_BTN: 'fepEabSubmitBtn',
    FEP: 'fep',
    EAB: 'eab'
};

const CLASSES = {
    CONTACT_FORM_DATA_CONTROL: 'contact-form-data__control',
    CONTACT_FORM_DATA__CONTROL_SUCCESS: 'contact-form-data__control--success',
    OVERLAY_HIDDEN: 'overlay--hidden',
    HIDDEN: 'hidden'

};

const API = {
    SUBMIT_FAP_EAB_FORM: '/bin/microsites/fleet-info/submit-feb-and-eab-form'
};

/**
 * STRING_CONSTANTS
 */
const StringConstants = {
    ERROR: 'error',
    SUCCESS: 'success'
};

export default class FepEab {
    constructor(element) {
        this.element = element;
        this.fepEabSubmitBtn = null;
        this.form = null;
        this.plan = null;
        this.init();
    }

    /*
        Function :  This is called when the js loads.
    */
    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
        this.selectPlan();
    }

    /*
        Function : Caching DOM Elements to be used for opertaion in functions.
    */
    cacheDOMElement() {
        this.pageLoader = document.getElementById(`${ID.PAGE_LOADER}`);
        this.fepEabSubmitBtn = document.querySelector(`#${ID.FEP_EAB_SUBMIT_BTN}`);
        this.form = document.querySelector(`#${ID.FEP_EAB_FORM}`);
    }

    /*
        Function : To load other js files within this file for functions
                    and method usage.
    */
    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
    }

    /*
        Function : To attach event listners to DOM element.
    */
    attachEvents() {
        this.fepEabSubmitBtn.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            const inputfields = document.querySelectorAll(`.${CLASSES.CONTACT_FORM_DATA_CONTROL}`);
            if (this.ContactUsUtil.validateFields(inputfields)) {
                const formData = new FormData();

                if (document.getElementById(`${ID.FEP}`).checked === true) {
                    formData.append('formType', 'FEP');
                } else if (document.getElementById(`${ID.EAB}`).checked === true) {
                    formData.append('formType', 'EAB');
                }

                [].forEach.call(inputfields, (element) => {
                    if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                        element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);

                        if (element.name === 'firstName') {
                            formData.append('firstName', element.value);
                        } else if (element.name === 'lastName') {
                            formData.append('lastName', element.value);
                        } else if (element.name === 'city') {
                            formData.append('city', element.value);
                        } else if (element.name === 'state') {
                            formData.append('state', element.value);
                        } else if (element.name === 'zipCode') {
                            formData.append('zipCode', element.value);
                        } else if (element.name === 'email') {
                            formData.append('emailAddress', element.value);
                        } else if (element.name === 'can') {
                            formData.append('canNumber', element.value);
                        }
                    } else if (element.name === 'additionalEmailAddress') {
                        formData.append('additionalEmailAddress', element.value);
                    } else if (element.name === 'memberId') {
                        formData.append('memberId', element.value);
                    }
                });
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
                this.Ajax.ajaxMultipartPostFn(API.SUBMIT_FAP_EAB_FORM, formData, (success) => {
                    const data = JSON.parse(success);

                    if (data.message) {
                        this.MessageBox.showMessage(StringConstants.SUCCESS, data.message);
                        if (data.message.substring(0, 22) === 'Submission successful.') { this.form.reset(); }
                    } else if (data.error) {
                        this.MessageBox.showMessage(StringConstants.ERROR, data.error);
                    }

                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                    this.selectPlan();
                });
            }
        });
    }

    selectPlan() {
        if (location.hash) {
            this.plan = location.hash.split('#')[1].toLowerCase();

            if (this.plan === 'eab') {
                document.getElementById(`${ID.EAB}`).checked = true;
                document.getElementById(`${ID.FEP}`).checked = false;
            } else {
                document.getElementById(`${ID.FEP}`).checked = false;
            }
        }
    }
}
