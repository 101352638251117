import UnsubscribedForm from './unsubscribe-form';
import ResendForm from './resend-confirmation-form';

export default (item, recordsLength, content) => {
    let link = '';

    if (item.campaignStatus.toLowerCase() === 'open') {
        link += `<p class="bluetecupdate-form__message">${item.openStatusDetail}`;
        link += `<section class="dealer-widget-container" data-load-module="PreferredDealerWidget">
            <div id="preferred-dealer-plugin-container"></div>
            </section>`;
    }

    if (item.showKeepMeUpdated !== false) {
        link += `<button class="button button_primary bluetecupdate-form--keep-me-updated-button" title="${content.subscribeToUpdate}">${content.keepMeUpdated}</button>`;
    } else if (item.showKeepMeUpdated === false && recordsLength > 1) {
        if (!(item.email === undefined || item.email.toLowerCase() === 'null' || item.email === null || item.email === '')) {
            if (item.subscribe) {
                link += `<p class="bluetecupdate-form__message">${item.email} ${content.subscribed}</p>`;
                link += `${UnsubscribedForm(content)}`;
            } else {
                link += `<p class="bluetecupdate-form__message">${content.pendingConfirmation} ${item.email}</p>`;
                link += `${ResendForm(content)}`;
            }
        }
    } else {
        link += `<p class="bluetecupdate-form__message">${content.invalidVin}</p>`;
    }
    return `${link}`;
};
