/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import {
    htmlNode,
    generateUniqueID
} from 'utils';

export default ({ changeLocationLabel, loadMoreLabel, dealerResultsSubHeading, id = generateUniqueID() }) => htmlNode`
    <div class="dealer-results">
        ${dealerResultsSubHeading ? `<h3 class="leads-form__sub-heading">${dealerResultsSubHeading}</h3>` : ''}
        <div data-dealer-heading></div>
        <p class="dealer-results__sub-heading-copy dealer-results__sub-heading-copy">
            <a href="#" class="link link_plain-link" data-change-search-cta role="presentation"
               data-analytics-trigger="change-zip">
                ${changeLocationLabel}
            </a>
        </p>
        <div data-dealer-results-list></div>
        <div class="load-more-container">
        <span class="load-more">${loadMoreLabel}</span>
        </div>
        <span id="error${id}" class="dealer-results__error-message" data-error-message></span>
    </div>
`;

