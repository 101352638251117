import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import UISCreateFormUtil from 'modules/UISCreateFormUtil';

const ID = {
    SUBMITBUTTON: 'UISCreateFormSubmit',
    FILE_BUTTON: 'Submit-fileID',
    GO_TO_REQUEST: 'goToRequest',
    SUB_FILE_BUTTON: 'SubSubmit-fileID',
    TOPIC: 'topicOfUis',
    PROJECTED_SALES: 'projected-Sales-Numbers',
    VEHICLE_TYPE: 'vehicle-Type',
    UPFIT_TYPE: 'upfit-Type',
    GVWR: 'gvwr',
    FILE_ID: 'CreateForm-fileID',
    SUB_FILE_ID: 'CreateFormSub-fileID',
    SAMPLE_VIN: 'sample-Vin',
    MODIFICATION_OVERVIEW: 'modification-overview',
    SPECIFICATIONS: 'specifications',
    MODIFICATION_BASIC_VEHICLE: 'modifications-basic-vehicle',
    MODIFICATION_BODY_SHELL: 'modifications-body-shell',
    MODIFICATION_ENGINE_PERIFERALS: 'modifications-engine-peripherals-drivetrain',
    MODIFICATION_ADDITIONAL_EQUIPMENT: 'modifications-additional-equipment',
    MODIFICATION_ATTACHMENTS: 'modifications-attachments',
    MODIFICATION_INTERIOR: 'modifications-interior',
    GENERAL_UPLOADS: 'general-uploads',
    DISPLAY_RESULT: 'mblease-listing',
    NAME: 'name',
    COMPANY: 'company',
    POSITION: 'position',
    EMAIL: 'email-address',
    PHONE_NUMBER: 'phone-number',
    DATE: 'date',
    GENERAL_INFO: 'general-info',
    REMOVE_FILE: 'Remove',
    SUBREMOVE_FILE: 'AttachmentsRemove',
    CHECK_INPUT1: 'checkinput1',
    CHECK_INPUT2: 'checkinput2',
    CHECK_INPUT3: 'checkinput3',
    CHECK_INPUT4: 'checkinput4',
    CHECK_INPUT5: 'checkinput5',
    CHECK_INPUT6: 'checkinput6',
    CHECK_INPUT7: 'checkinput7',
};

export default class UISCreateForm {
    constructor(element) {
        this.element = element;
        this.formData = {};
        this.data = new FormData();
        this.init();
    }


    init() {
        this.loadModules();
        this.disabledCreateButton();
        this.attachEvents();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'upfitter';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }


    loadModules() {
        this.generalInfo = document.getElementById(`${ID.GENERAL_INFO}`);
        this.modification_overview = document.getElementById(`${ID.MODIFICATION_OVERVIEW}`);
        this.specifications = document.getElementById(`${ID.SPECIFICATIONS}`);
        this.modificationBasicVehicle = document.getElementById(`${ID.MODIFICATION_BASIC_VEHICLE}`);
        this.modificationBodyShell = document.getElementById(`${ID.MODIFICATION_BODY_SHELL}`);
        this.modificationEnginePeriferals = document.getElementById(`${ID.MODIFICATION_ENGINE_PERIFERALS}`);
        this.modificationsAttachments = document.getElementById(`${ID.MODIFICATION_ATTACHMENTS}`);
        this.modificationsAdditionalEquipment = document.getElementById(`${ID.MODIFICATION_ADDITIONAL_EQUIPMENT}`);
        this.modificationsInterior = document.getElementById(`${ID.MODIFICATION_INTERIOR}`);
        this.generalUploads = document.getElementById(`${ID.GENERAL_UPLOADS}`);

        this.UISCreateFormUtil = new UISCreateFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    generalInfoData() {
        const siteName = 'uis-form';
        const url = '/bin/microsites/uis-general-info';
        const data = {
            siteName,
        };

        document.getElementById('generalInfo-inputs').style.display = 'none';
        this.Ajax.ajaxPostFn(url, data, (success) => {
            if (success !== 'false') {
                this.formData.generalInfo = JSON.parse(success);
                this.displayGeneralInfoData();
            } this.loadCaptcha();
            document.getElementById('generalInfo-loader').style.display = 'none';
            document.getElementById('generalInfo-inputs').style.display = 'table';
        });
    }

    disabledCreateButton() {
        console.log(this);
        const siteName = 'upfitter';
        const path = window.location.pathname;
        const segments = path.split('/');
        const last = segments[segments.length - 1];
        const urlValue = last.includes('new-form.html');
        const uisentitlement = '/bin/microsites/uis-entitlement-label';
        this.Ajax.ajaxPostFn(uisentitlement, siteName, (success) => {
            this.generalInfoData();
            const entitlementData = JSON.parse(success);
            if (urlValue && entitlementData === 'EXPERTUPFITTER') {
                document.getElementById('UISCreateFormSubmit').removeAttribute('disabled');
                document.getElementById('Submit-fileID').removeAttribute('disabled');
                document.getElementById('SubSubmit-fileID').removeAttribute('disabled');
                document.getElementById('adminerrormsg').classList.add('check-form__error-field--hidden');
            } else if (urlValue && entitlementData === 'HOMBENGADMIN' || urlValue && entitlementData === 'HOMBPRGADMIN') {
                document.getElementById('UISCreateFormSubmit').setAttribute('disabled', 'disabled');
                document.getElementById('Submit-fileID').setAttribute('disabled', 'disabled');
                document.getElementById('SubSubmit-fileID').setAttribute('disabled', 'disabled');
                document.getElementById('gotodashboard').href =
                    `${document.getElementById('editLinkdashboard').value}`;
                document.getElementById('adminerrormsg').classList.remove('check-form__error-field--hidden');
            }
        });
    }

    attachEvents() {
        this.generalInfo.addEventListener(EVENTS.INPUT, () => {
            const generalInfo = {};
            generalInfo.name = document.getElementById(`${ID.NAME}`).value;
            generalInfo.company = document.getElementById(`${ID.COMPANY}`).value;
            generalInfo.position = document.getElementById(`${ID.POSITION}`).value;
            generalInfo.emailId = document.getElementById(`${ID.EMAIL}`).value;
            generalInfo.phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
            generalInfo.date = document.getElementById(`${ID.DATE}`).value;
            this.formData.generalInfo = generalInfo;
        });

        this.modification_overview.addEventListener(EVENTS.INPUT, () => {
            const modificationOverview = {};
            modificationOverview.topicOfUis = document.getElementById(`${ID.TOPIC}`).value;
            modificationOverview.projectedSalesNumbers = document.getElementById(`${ID.PROJECTED_SALES}`).value;
            modificationOverview.vehicleType = document.getElementById(`${ID.VEHICLE_TYPE}`).value;
            modificationOverview.upfitType = document.getElementById(`${ID.UPFIT_TYPE}`).value;
            modificationOverview.sampleVin = document.getElementById(`${ID.SAMPLE_VIN}`).value;
            modificationOverview.gvwr = document.getElementById(`${ID.GVWR}`).value;
            this.formData.modificationOverview = modificationOverview;
        });

        this.specifications.addEventListener(EVENTS.INPUT, () => {
            const specifications = {};
            const checkboxes = document.querySelectorAll('input[name="specificationsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                specifications[input.value] = textAreaValue;
            });
            this.formData.specifications = specifications;
        });

        this.modificationBasicVehicle.addEventListener(EVENTS.INPUT, () => {
            const modificationBasicVehicle = {};
            const checkboxes = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                modificationBasicVehicle[input.value] = textAreaValue;
            });
            this.formData.modificationBasicVehicle = modificationBasicVehicle;
        });

        this.modificationBodyShell.addEventListener(EVENTS.INPUT, () => {
            const modificationBodyShell = {};
            const checkboxes = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                modificationBodyShell[input.value] = textAreaValue;
            });
            this.formData.modificationBodyShell = modificationBodyShell;
        });

        this.modificationEnginePeriferals.addEventListener(EVENTS.INPUT, () => {
            const modificationEnginePeriferals = {};
            const checkboxes = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                modificationEnginePeriferals[input.value] = textAreaValue;
            });
            this.formData.modificationEnginePeriferals = modificationEnginePeriferals;
        });

        this.modificationsInterior.addEventListener(EVENTS.INPUT, () => {
            const modificationsInterior = {};
            const checkboxes = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                modificationsInterior[input.value] = textAreaValue;
            });
            this.formData.modificationsInterior = modificationsInterior;
        });

        this.modificationsAdditionalEquipment.addEventListener(EVENTS.INPUT, () => {
            let modificationsParentTextArea = {};
            let modificationsChildTextArea = {};
            const modificationsAdditionalEquipment = [];
            let elementValue = '';
            const checkboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                modificationsParentTextArea = {};
                modificationsChildTextArea = {};
                const inputId = input.id;
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                const textAreaValue = textArea.value;
                modificationsParentTextArea[input.value] = textAreaValue;
                const nestedCheckBoxlabel = textArea.nextElementSibling.nextElementSibling;
                if (nestedCheckBoxlabel !== null) {
                    if (nestedCheckBoxlabel.tagName === 'LABEL') {
                        const nestedCheckbox = document.querySelectorAll('input[name="modificationsAdditionalEquipmentChildCheckboxData"]:checked');
                        [].forEach.call(nestedCheckbox, (element) => {
                            const elementId = element.id;
                            if (elementId.includes(inputId)) {
                                elementValue = element.value;
                                modificationsChildTextArea[elementValue] =
                                    element.labels[0].nextElementSibling.firstElementChild.value;
                            }
                        });
                    }
                }
                if (elementValue !== '') {
                    modificationsAdditionalEquipment.push({ modificationsParentTextArea,
                        modificationsChildTextArea });
                } else {
                    modificationsAdditionalEquipment.push({
                        modificationsParentTextArea });
                }
                elementValue = '';
            });
            this.formData.modificationsAdditionalEquipment = modificationsAdditionalEquipment;
        });

        this.modificationsAttachments.addEventListener(EVENTS.INPUT, () => {
            const modificationsAttachments = {};
            const checkboxes = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                modificationsAttachments[input.value] =
                    input.labels[0].nextElementSibling.firstElementChild.value;
            });
            this.formData.modificationsAttachments = modificationsAttachments;
        });


        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const capRes = grecaptcha.getResponse();
                if (this.UISCreateFormUtil.validateAllFields() && capRes.length !== 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById('UIS_form');
                    document.getElementById('uis-create-form-loader').style.display = 'block';
                    document.getElementById('uis-create-form-loader').innerHTML = '<div class="uis-dashboard__loader-submit" id="submit-loader"></div><span class="check-form__error-field">Please wait...</span>';
                    this.data.set('data', JSON.stringify(this.formData));
                    this.data.set('captcha', capRes);
                    this.data.set('option', 'add');
                    const url = '/bin/microsites/uis-form';
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, this.data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.UISCreateFormUtil.displayPopUp('Please verify the captcha response');
                        } else {
                            success = JSON.parse(JSON.parse(success));
                            document.getElementById('uis-create-form-loader').style.display = 'none';
                            if (success !== 0 && success !== 'false' && success.status === 'sent') {
                                const formElement = `<div>
                                    <h5 class="modal-title">Thank you! Your submission is successful. </h5>
                                    <h6 class="modal-title">Your request number is ${success.uisId}. </h6>
                                    </div>`;
                                document.getElementById('goToRequest').href =
                                    `${document.getElementById('editLinkdashboard').value}`;
                                this.UISCreateFormUtil.displayPopUp(formElement);
                                form.reset();
                                const genInfo = this.formData.generalInfo;
                                this.formData = {};
                                this.formData.generalInfo = genInfo;
                                this.displayGeneralInfoData();
                                document.getElementById('fileList').innerHTML = '';
                                document.getElementById('subfileList').innerHTML = '';
                                const addFileButton = document.getElementById('UISCreateFormSubmit');
                                addFileButton.removeAttribute('disabled');
                            } else if (success.status === 'not sent') {
                                const formElement = `<div>
                                    <h5 class="modal-title">Thank you! Your submission is successful. </h5>
                                    <h6 class="modal-title">Your request number is ${success.uisId}. </h6>
                                    <h6 style="font-size: 14px;color: #ef5a39d1;">We are unable to send email notification at this moment. To view your request(s) ,please navigate to dashboard.</h6>
                                    </div>`;
                                document.getElementById('goToRequest').href =
                                    `${document.getElementById('editLinkdashboard').value}`;
                                this.UISCreateFormUtil.displayPopUp(formElement);
                                form.reset();
                                const genInfo = this.formData.generalInfo;
                                this.formData = {};
                                this.formData.generalInfo = genInfo;
                                this.displayGeneralInfoData();
                                document.getElementById('fileList').innerHTML = '';
                                document.getElementById('subfileList').innerHTML = '';
                                const addFileButton = document.getElementById('UISCreateFormSubmit');
                                addFileButton.removeAttribute('disabled');
                            } else {
                                document.getElementById('goToRequestButton').style.display = 'none';
                                const formElement = '<div><h5 class="modal-title">Form submission failed </h5></div>';
                                this.UISCreateFormUtil.displayPopUp(formElement);
                                document.getElementById('subfileList').innerHTML = '';
                                document.getElementById('fileList').innerHTML = '';
                                const addFileButton = document.getElementById('UISCreateFormSubmit');
                                addFileButton.removeAttribute('disabled');
                            }
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="check-form__error-field">This field is required.</span>';
                    this.UISCreateFormUtil.validateAllFields();
                }
            } else if (e.target.matches(`#${ID.FILE_BUTTON}`)) {
                document.getElementById('CreateForm-fileID').click();
            } else if (e.target.matches(`#${ID.SUB_FILE_BUTTON}`)) {
                document.getElementById('CreateFormSub-fileID').click();
            } else if (e.target.matches(`#${ID.SUBREMOVE_FILE}`)) {
                const id = parseInt(e.target.getAttribute('data-ref'), 10);
                const filesId = document.getElementById('SubSubmit-fileID');
                const totalFiles = document.getElementById('subfileList').innerText;
                const fileSplit = totalFiles.split('\n');
                const totalFilesLength = fileSplit.length;
                if (totalFilesLength < 6) {
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                }
                this.fileRemoveEvent('subfileList', `${ID.SUB_FILE_ID}`, id,
                    'interiorFiles', 'AttachmentsRemove');
            } else if (e.target.matches(`#${ID.REMOVE_FILE}`)) {
                const id = parseInt(e.target.getAttribute('data-ref'), 10);
                const filesId = document.getElementById('Submit-fileID');
                const totalFiles = document.getElementById('fileList').innerText;
                const fileSplit = totalFiles.split('\n');
                const totalFilesLength = fileSplit.length;
                if (totalFilesLength < 6) {
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                }
                this.fileRemoveEvent('fileList', `${ID.FILE_ID}`, id,
                    'generalUploadFiles', 'Remove');
            }
        });

        this.generalUploads.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.FILE_ID}`)) {
                e.preventDefault();
                let fileAdd = false;
                const addFileButton = document.getElementById('UISCreateFormSubmit');
                const file = e.target.value;
                const files = document.getElementById(`${ID.FILE_ID}`).files.length;
                const filesId = document.getElementById(`${ID.FILE_ID}`);
                const addFiles = document.getElementById(`${ID.FILE_BUTTON}`);
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    if (ext === 'jpg' || ext === 'JPG' || ext === 'png' || ext === 'PNG' || ext === 'bmp' || ext === 'BMP' || ext === 'gif' || ext === 'GIF' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF') {
                        if (this.UISCreateFormUtil.validateFileSize()) {
                            fileAdd = true;
                            addFileButton.removeAttribute('disabled');
                            document.getElementById('errmsg').innerHTML = '';
                        } else {
                            fileAdd = false;
                        }
                    } else {
                        fileAdd = false;
                        addFileButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('errmsg').innerHTML = `${ext} format is not supported `;
                    }
                    if (fileAdd === true) {
                        this.fileAddEvent('fileList', `${ID.FILE_ID}`,
            'generalUploadFiles', 'Remove');
                    }
                }
            }
        });


        this.modificationsInterior.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.SUB_FILE_ID}`)) {
                e.preventDefault();
                let fileAdd = false;
                const addFileButton = document.getElementById('UISCreateFormSubmit');
                const file = e.target.value;
                const files = document.getElementById(`${ID.SUB_FILE_ID}`).files.length;
                const filesId = document.getElementById(`${ID.SUB_FILE_ID}`);
                const addFiles = document.getElementById(`${ID.SUB_FILE_BUTTON}`);
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    if (ext === 'jpg' || ext === 'JPG' || ext === 'png' || ext === 'PNG' || ext === 'bmp' || ext === 'BMP' || ext === 'gif' || ext === 'GIF' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF') {
                        if (this.UISCreateFormUtil.validateSubFileSize()) {
                            fileAdd = true;
                            addFileButton.removeAttribute('disabled');
                            document.getElementById('errmsgNested').innerHTML = '';
                        } else {
                            fileAdd = false;
                        }
                    } else {
                        fileAdd = false;
                        addFileButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('errmsgNested').innerHTML = `${ext} format is not supported `;
                    }
                    if (fileAdd === true) {
                        this.fileAddEvent('subfileList', `${ID.SUB_FILE_ID}`,
                    'interiorFiles', 'AttachmentsRemove');
                    }
                }
            }
        });
    }

    fileRemoveEvent(fileListId, fileId, id, fileArrayName, removeId) {
        const dt = new DataTransfer();
        const input = document.getElementById(fileId);
        const files = this.data.getAll(fileArrayName);
        for (let i = 0; i < files.length; i += 1) {
            if (id !== i) {
                const file = files[i];
                dt.items.add(file);
            }
        }
        input.files = dt.files;
        this.data.delete(fileArrayName);
        this.fileAddEvent(fileListId, fileId, fileArrayName, removeId);
    }

    fileAddEvent(fileListId, fileId, fileArrayName, removeId) {
        const output = document.getElementById(fileListId);
        const file = document.getElementById(fileId).files;
        if (file !== undefined && file.length !== 0) {
            for (let index = 0; index < file.length; index += 1) {
                this.data.append(fileArrayName, file[index]);
            }
        }
        output.innerHTML = this.displayAttachments(
                this.data.getAll(fileArrayName), removeId);
    }

    displayAttachments(filesList, removeId) {
        let children = '';
        this.lowerSize = 1024;
        const upperSize = 1048576;
        for (let index = 0; index < filesList.length; index += 1) {
            const fileSize = filesList[index].size;
            if (fileSize >= this.lowerSize && fileSize <= upperSize) {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${filesList[index].name}</td>
                        <td class="check-form__fileSize">${fileSize / 1024}KB</td>
                        <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            } else {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${filesList[index].name}</td>
                   <td class="check-form__fileSize" id="fileSize">${fileSize / 1048576}MB</td>
                   <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            }
        }
        return `<table>${children}</table>`;
    }

    displayGeneralInfoData() {
        const emailId = this.formData.generalInfo.emailId;
        const name = this.formData.generalInfo.name;
        const phoneNumber = this.formData.generalInfo.phoneNumber;
        const position = this.formData.generalInfo.position;
        const company = this.formData.generalInfo.company;

        let eve = document.getElementById(`${ID.DATE}`);
        eve.value = this.formData.generalInfo.date;
        eve.readOnly = true;

        if (phoneNumber !== null && phoneNumber !== '') {
            eve = document.getElementById(`${ID.PHONE_NUMBER}`);
            eve.value = phoneNumber;
        }
        if (emailId !== null && emailId !== '') {
            eve = document.getElementById(`${ID.EMAIL}`);
            eve.value = emailId;
        }
        if (name !== null && name !== '') {
            eve = document.getElementById(`${ID.NAME}`);
            eve.value = name;
        }
        if (position !== null && position !== '') {
            eve = document.getElementById(`${ID.POSITION}`);
            eve.value = position;
        }
        if (company !== null && company !== '') {
            eve = document.getElementById(`${ID.COMPANY}`);
            eve.value = company;
        }
    }
}
