import { EVENTS } from 'Constants';

const ID = {
    MODELYEAR: 'modelYear',
    MODELCLASS: 'modelClass',
};

const CLASS = {
    WHATS_NEW__VISIBLE: 'whats-new__visible',
    WHATS_NEW__HIDE: 'whats-new__hide'
};


export default class DogWhatsNew {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        this.prevCheckId = '';
        document.addEventListener(EVENTS.CHANGE, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.MODELYEAR}`) || e.target.matches(`#${ID.MODELCLASS}`)) {
                e.preventDefault();

                const modelYear = document.getElementById(`${ID.MODELYEAR}`).value;
                const modelClass = document.getElementById(`${ID.MODELCLASS}`).value;
                const checkId = `${modelYear}-${modelClass}`;
                console.log(`${this.prevCheckId} ${checkId}`);
                if (modelYear !== 'select' && modelClass !== 'select') {
                    if (this.prevCheckId !== '') {
                        document.getElementById(`${this.prevCheckId}`).classList.remove(`${CLASS.WHATS_NEW__VISIBLE}`);
                        document.getElementById(`${this.prevCheckId}`).classList.add(`${CLASS.WHATS_NEW__HIDE}`);
                    }
                    document.getElementById(`${checkId}`).classList.remove(`${CLASS.WHATS_NEW__HIDE}`);
                    document.getElementById(`${checkId}`).classList.add(`${CLASS.WHATS_NEW__VISIBLE}`);
                    this.prevCheckId = checkId;
                }
            }
        });
    }
}
