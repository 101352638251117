import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import VinRecallUtil from 'modules/VinRecallUtil';

const ID = {
    SUBMITBUTTON: 'btn-recall',
    VIN_RECALL_FORM: 'vin_recall_form',
    RECALL_RESULT: 'recall_result',
    RECALL_ONGOING: 'rc_ongoing',
    RECALL_COMPLETE: 'rc_complete',
    OTHER_ONGOING: 'oth_ongoing',
    OTHER_COMPLETE: 'oth_complete',
};

export default class VinRecall {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.VinRecallUtil = new VinRecallUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const response = grecaptcha.getResponse();
                if (response.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="vin-recall__error-field">This field is required.</span>';
                } else {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    if (this.VinRecallUtil.validateAllFields()) {
                        const form = document.getElementById(`${ID.VIN_RECALL_FORM}`);
                        const inputs = form.elements;

                        const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                        console.log(formToJSONObject(inputs));

                        const siteName = 'takata';
                        const url = '/bin/microsites/vinRecall';
                        const data = {
                            formData: JSON.stringify(formToJSONObject(inputs)),
                            siteName,
                        };

                        this.Ajax.ajaxPostFn(url, data, (success) => {
                            const apiResponse = JSON.parse(success);
                            this.displayDetails(apiResponse);
                        });
                    }
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.RECALL_COMPLETE}`)) {
                e.preventDefault();
                const rC = document.getElementById('rc_complete');
                const rInc = document.getElementById('rc_ongoing');
                const oC = document.getElementById('oth_complete');
                const oInc = document.getElementById('oth_ongoing');
                const rc = document.getElementById('recall_complete');
                const rinc = document.getElementById('recall_incomplete');
                const oc = document.getElementById('other_complete');
                const oinc = document.getElementById('other_incomplete');
                rc.removeAttribute('hidden');
                rC.style.color = 'blue';
                rinc.setAttribute('hidden', '');
                rInc.style.color = 'black';
                oc.setAttribute('hidden', '');
                oC.style.color = 'black';
                oinc.setAttribute('hidden', '');
                oInc.style.color = 'black';
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.OTHER_ONGOING}`)) {
                e.preventDefault();
                const rC = document.getElementById('rc_complete');
                const rInc = document.getElementById('rc_ongoing');
                const oC = document.getElementById('oth_complete');
                const oInc = document.getElementById('oth_ongoing');
                const rc = document.getElementById('recall_complete');
                const rinc = document.getElementById('recall_incomplete');
                const oc = document.getElementById('other_complete');
                const oinc = document.getElementById('other_incomplete');
                rc.setAttribute('hidden', '');
                rC.style.color = 'black';
                rinc.setAttribute('hidden', '');
                rInc.style.color = 'black';
                oc.setAttribute('hidden', '');
                oC.style.color = 'black';
                oinc.removeAttribute('hidden');
                oInc.style.color = 'blue';
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.OTHER_COMPLETE}`)) {
                e.preventDefault();
                const rC = document.getElementById('rc_complete');
                const rInc = document.getElementById('rc_ongoing');
                const oC = document.getElementById('oth_complete');
                const oInc = document.getElementById('oth_ongoing');
                const rc = document.getElementById('recall_complete');
                const rinc = document.getElementById('recall_incomplete');
                const oc = document.getElementById('other_complete');
                const oinc = document.getElementById('other_incomplete');
                rc.setAttribute('hidden', '');
                rC.style.color = 'black';
                rinc.setAttribute('hidden', '');
                rInc.style.color = 'black';
                oc.removeAttribute('hidden');
                oC.style.color = 'blue';
                oinc.setAttribute('hidden', '');
                oInc.style.color = 'black';
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.RECALL_ONGOING}`)) {
                e.preventDefault();
                const rC = document.getElementById('rc_complete');
                const rInc = document.getElementById('rc_ongoing');
                const oC = document.getElementById('oth_complete');
                const oInc = document.getElementById('oth_ongoing');
                const rc = document.getElementById('recall_complete');
                const rinc = document.getElementById('recall_incomplete');
                const oc = document.getElementById('other_complete');
                const oinc = document.getElementById('other_incomplete');
                rc.setAttribute('hidden', '');
                rC.style.color = 'black';
                rinc.removeAttribute('hidden');
                rInc.style.color = 'blue';
                oc.setAttribute('hidden', '');
                oC.style.color = 'black';
                oinc.setAttribute('hidden', '');
                oInc.style.color = 'black';
            }
        });
    }

    displayDetails(apiResponse) {
        console.log(this);
        const vehicleRes = JSON.parse(apiResponse.vehicleResponse);
        const resultDisplay = document.getElementById(`${ID.RECALL_RESULT}`);
        const year = vehicleRes[0].year;
        const model = vehicleRes[0].model;
        const count = Object.keys(apiResponse).length;
        let htmlBuild = '';
        if (count < 2) {
            htmlBuild = `<section class="vin-result" data-load-module="VinRecall">
                <p class="vin-result__recall_model_title">${year} ${model}</p>
                <p class="vin-recall__error-field">Your vehicle is not currently affected by a safety recall.</p>
                </section>
                </div>`;
        } else {
            const recallstr = apiResponse.recallResponse;
            const recallArray = JSON.parse(recallstr);
            let cRecallOngoing = 0;
            let cRecallComplete = 0;
            let cOthOngoing = 0;
            let cOthComplete = 0;
            htmlBuild = `<section class="vin-result" data-load-module="VinRecall">
                <p class="vin-result__recall_model_title">${year} ${model}</p>`;
            for (let i = 0; i < recallArray.length; i += 1) {
                if (recallArray[i]['type-desc'] === 'RECALL' && recallArray[i].status === 'CLOSED') {
                    cRecallComplete += 1;
                } else if (recallArray[i]['type-desc'] === 'RECALL' && recallArray[i].status === 'OPEN') {
                    cRecallOngoing += 1;
                } else if (recallArray[i]['type-desc'] !== 'RECALL' && recallArray[i].status === 'CLOSED') {
                    cOthOngoing += 1;
                } else if (recallArray[i]['type-desc'] !== 'RECALL' && recallArray[i].status === 'OPEN') {
                    cOthComplete += 1;
                }
            }
            if (cRecallOngoing > 0) {
                htmlBuild += '<p class="vin-recall__error-field">Your vehicle has an active safety recall. Scroll down to see exact details and important next steps.</p>';
            }
            htmlBuild += `<div class="vin-result__result_selection_container">
                <table class="vin-result__result_table_selection">
                    <tr>
                        <td colspan='2' class="vin-result__result_table_selection_column"><p class="vin-result__result_table_toggle_text"><b>Safety Recalls</b></p></td>
                        <td colspan='2' class="vin-result__result_table_selection_column"><p class="vin-result__result_table_toggle_text"><b>Other Campaigns</b></p></td>
                    </tr>
                    <tr>
                        <td class="vin-result__result_table_selection_column_right"><p class="vin-result__result_table_toggle_text"><b id="rc_ongoing">On-Going (${cRecallOngoing})</b></p></td>
                        <td class="vin-result__result_table_selection_column_left"><p class="vin-result__result_table_toggle_text"><b id="rc_complete">Completed (${cRecallComplete})</b></p></td>
                        <td class="vin-result__result_table_selection_column_right"><p class="vin-result__result_table_toggle_text"><b id="oth_ongoing">On-Going (${cOthOngoing})</b></p></td>
                        <td class="vin-result__result_table_selection_column_left"><p class="vin-result__result_table_toggle_text"><b id="oth_complete">Completed (${cOthComplete})</b></p></td>
                    </tr>
                </table>
                </div>`;
            htmlBuild += `<div class="vin-result__result_selection_container" id="recall_incomplete">
                <div class="vin-result__recall-result">
                <div class="vin-result__open-recall active">
                <table id="recall-details-table">
                <tbody><tr class="vin-result__heading-row">
                <td class="vin-result__col1"><b>NHTSA Recall Number</b></td>
                <td class="vin-result__col2"><b>Recall Reported</b></td>
                <td class="vin-result__col3"><b>Recall Starts</b></td>
                <td class="vin-result__col4"><b>Status</b></td>
                <td class="vin-result__col5"><b>Component(s)</b></td>
                <td class="vin-result__col6"><b>Recall Description</b></td>
                <td class="vin-result__col7"><b>Remedies</b></td>
                </tr>`;
            for (let j = 0; j < recallArray.length; j += 1) {
                if (recallArray[j]['type-desc'] === 'RECALL' && recallArray[j].status === 'OPEN') {
                    htmlBuild += `<tr class="vin-result__data-row">
               <td>${recallArray[j]['nhtsa-iD']}</td>
               <td>${recallArray[j]['effective-date']}</td>
               <td>${recallArray[j]['start-date']}</td>
               <td>${recallArray[j].status}</td>
               <td>${recallArray[j]['recall-title']}</td>
               <td>
                  <div class="vin-result__summary-title"><b>SUMMARY</b></div>
                  <div class="vin-result__summary-desc">${recallArray[j]['mbusa-desc']}</div>
                  <div class="vin-result__cons-title"><b>CONSEQUENCE</b></div>
                  <div class="vin-result__safety-desc">${recallArray[j]['recall-safety-risk']}</div>
                  <div class="vin-result__letter"><a class="vin-result__cta vin-result__arrow-link-blue" href="/content/dam/microsites/mb-wholesale-parts/pdf/takata-recall/cust-letters/${recallArray[j]['mbusa-iD']}.pdf" target="_blank" rel="noopener noreferrer">View Customer Letter</a></div>
               </td>
               <td>${recallArray[j]['recall-remedy']}</td>
               </tr>`;
                }
            }
            htmlBuild += `</tbody></table>
                             </div>
                             </div>
                             </div>`;
            htmlBuild += `<div class="vin-result__result_selection_container" id="recall_complete" hidden=''>
               <div class="vin-result__recall-result">
               <div class="vin-result__open-recall active">
               <table id="recall-details-table">
               <tbody><tr class="vin-result__heading-row">
               <td class="vin-result__col1"><b>NHTSA Recall Number</b></td>
               <td class="vin-result__col2"><b>Recall Reported</b></td>
               <td class="vin-result__col3"><b>Recall Starts</b></td>
               <td class="vin-result__col4"><b>Status</b></td>
               <td class="vin-result__col5"><b>Component(s)</b></td>
               <td class="vin-result__col6"><b>Recall Description</b></td>
               <td class="vin-result__col7"><b>Remedies</b></td>
               </tr>`;
            for (let k = 0; k < recallArray.length; k += 1) {
                if (recallArray[k]['type-desc'] === 'RECALL' && recallArray[k].status === 'CLOSED') {
                    htmlBuild += `<tr class="vin-result__data-row">
               <td>${recallArray[k]['nhtsa-iD']}</td>
               <td>${recallArray[k]['effective-date']}</td>
               <td>${recallArray[k]['start-date']}</td>
               <td>${recallArray[k].status}</td>
               <td>${recallArray[k]['recall-title']}</td>
               <td>
                  <div class="vin-result__summary-title"><b>SUMMARY</b></div>
                  <div class="vin-result__summary-desc">${recallArray[k]['mbusa-desc']}</div>
                  <div class="vin-result__cons-title"><b>CONSEQUENCE</b></div>
                  <div class="vin-result__safety-desc">${recallArray[k]['recall-safety-risk']}</div>
                  <div class="vin-result__letter"><a class="vin-result__cta vin-result__arrow-link-blue" href="/content/dam/microsites/mb-wholesale-parts/pdf/takata-recall/cust-letters/${recallArray[k]['mbusa-iD']}.pdf" target="_blank" rel="noopener noreferrer">View Customer Letter</a></div>
               </td>
               <td>${recallArray[k]['recall-remedy']}</td>
              </tr>`;
                }
            }
            htmlBuild += `</tbody></table>
               </div>
               </div>
               </div>`;
            htmlBuild += `<div class="vin-result__result_selection_container" id="other_incomplete" hidden=''>
               <div class="vin-result__recall-result">
               <div class="vin-result__open-recall active">
               <table id="recall-details-table">
               <tbody><tr class="vin-result__heading-row">
               <td class="vin-result__col1"><b>MB Number</b></td>
               <td class="vin-result__col2"><b>Campaign Starts</b></td>
               <td class="vin-result__col4"><b>Status</b></td>
               <td class="vin-result__col5"><b>Component(s)</b></td>
               <td class="vin-result__col6"><b>Description</b></td>
               <td class="vin-result__col7"><b>Remedies</b></td>
               </tr>`;
            for (let l = 0; l < recallArray.length; l += 1) {
                if (recallArray[l]['type-desc'] !== 'RECALL' && recallArray[l].status === 'OPEN') {
                    htmlBuild += `<tr class="vin-result__data-row">
               <td>${recallArray[l]['nhtsa-iD']}</td>
               <td>${recallArray[l]['start-date']}</td>
               <td>${recallArray[l].status}</td>
               <td>${recallArray[l]['recall-title']}</td>
               <td>
                  <div class="vin-result__summary-title"><b>SUMMARY</b></div>
                  <div class="vin-result__summary-desc">${recallArray[l]['mbusa-desc']}</div>
                  <div class="vin-result__cons-title"><b>CONSEQUENCE</b></div>
                  <div class="vin-result__safety-desc">${recallArray[l]['recall-safety-risk']}</div>
                  <div class="vin-result__letter"><a class="vin-result__cta vin-result__arrow-link-blue" href="/content/dam/microsites/mb-wholesale-parts/pdf/takata-recall/cust-letters/${recallArray[l]['mbusa-iD']}.pdf" target="_blank" rel="noopener noreferrer">View Customer Letter</a></div>
               </td>
               <td>${recallArray[l]['recall-remedy']}</td>
              </tr>`;
                }
            }
            htmlBuild += `</tbody></table>
               </div>
               </div>
               </div>`;
            htmlBuild += `<div class="vin-result__result_selection_container" id="other_complete" hidden=''>
               <div class="vin-result__recall-result">
               <div class="vin-result__open-recall active">
               <table id="recall-details-table">
               <tbody><tr class="vin-result__heading-row">
               <td class="vin-result__col1"><b>MB Number</b></td>
               <td class="vin-result__col2"><b>Campaign Starts</b></td>
               <td class="vin-result__col4"><b>Status</b></td>
               <td class="vin-result__col5"><b>Component(s)</b></td>
               <td class="vin-result__col6"><b>Description</b></td>
               <td class="vin-result__col7"><b>Remedies</b></td>
               </tr>`;
            for (let m = 0; m < recallArray.length; m += 1) {
                if (recallArray[m]['type-desc'] !== 'RECALL' && recallArray[m].status === 'CLOSED') {
                    htmlBuild += `<tr class="vin-result__data-row">
               <td>${recallArray[m]['nhtsa-iD']}</td>
               <td>${recallArray[m]['start-date']}</td>
               <td>${recallArray[m].status}</td>
               <td>${recallArray[m]['recall-title']}</td>
               <td>
                  <div class="vin-result__summary-title"><b>SUMMARY</b></div>
                  <div class="vin-result__summary-desc">${recallArray[m]['mbusa-desc']}</div>
                  <div class="vin-result__cons-title"><b>CONSEQUENCE</b></div>
                  <div class="vin-result__safety-desc">${recallArray[m]['recall-safety-risk']}</div>
                  <div class="vin-result__letter"><a class="vin-result__cta vin-result__arrow-link-blue" href="/content/dam/microsites/mb-wholesale-parts/pdf/takata-recall/cust-letters/${recallArray[m]['mbusa-iD']}.pdf" target="_blank" rel="noopener noreferrer">View Customer Letter</a></div>
               </td>
               <td>${recallArray[m]['recall-remedy']}</td>
              </tr>`;
                }
            }
            htmlBuild += `</tbody></table>
               </div>
               </div>
               </div>
               </section>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }
}
