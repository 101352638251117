// import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';

export default class ExcelSheetDisplay {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        // this.attachEvents();
        this.displayExcel();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    displayExcel() {
        const href = window.location.href;
        const contentPath = href.substr(href.indexOf('/content')).split('.')[0];
        const url = '/bin/microsites/excelToJson';
        const columns = document.getElementById('columns').value;
        const filename = document.getElementById('filename').value;
        const data = {
            contentPath,
            columns,
            filename,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            // console.log(success);
            const List = JSON.parse(success);
            document.getElementById('loader').style.display = 'block';
            const excelResult = document.getElementById('excel-download-result');
            if (List.error === 'NO_DATA_FOUND') {
                excelResult.innerHTML = '<p class="vin-recall__error-field">NO DATA FOUND</p>';
            } else if (success === false) {
                document.getElementById('excelheader').innerHTML = 'Please refresh the page';
            } else {
                this.displayTable(List);
                document.getElementById('loader').style.display = 'none';
            }
        });
    }

    displayTable(List) {
        console.log(this);
        console.log(List);
        let htmldisplayhead = '';
        let htmldisplaydata = '';
        const Displayheader = document.getElementById('excelheader');
        const Displaycontent = document.getElementById('excel-listing');
        const columns = List[0];
        const columnslength = List[0].length;
        const rows = List[1];
        const rowslength = List[1].length;
        for (let key = 0; key < columnslength; key += 1) {
            const header = columns[key];
            htmldisplayhead += (`<th rowspan="1" class="mb-lease-report__th_padding" >${header}</th>`);
        }
        for (let j = 0; j < rowslength; j += 1) {
            const rowRecord = JSON.parse(rows[j]);
            htmldisplaydata += ('<tr role="row" class="mb-lease-report__border_rule">');
            for (let i = 0; i < columnslength; i += 1) {
                const column = columns[i];
                const value = rowRecord[column];
                htmldisplaydata += (`<td colspan="1" class="mb-lease-report__td_padding">${value}</td>`);
            }
            htmldisplaydata += ('</tr>');
        }

        Displayheader.innerHTML = htmldisplayhead;
        Displaycontent.innerHTML = htmldisplaydata;
    }
}
