import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    CHECK_FORM_CONTROL: 'check-form__control',
    CHECK_FORM_SAMPLE: 'check-form__sample',
    CHECK_FORM_TEXTAREA: 'check-form__textarea',
    CHECK_FORM_CONTROL_ERROR: 'check-form__control--error',
    CHECK_FORM_CONTROL_SUCCESS: 'check-form__control--success',
    CHECK_FORM_SAMPLE_ERROR: 'check-form__sample--error',
    CHECK_FORM_SAMPLE_SUCCESS: 'check-form__sample--success',
    CHECK_FORM_ERROR_FIELD_HIDDEN: 'check-form__error-field--hidden',
    CHECK_FORM_SPECIFICATION_ERROR: 'check-form__specification--error',
    CHECK_FORM_SPECIFICATION_SUCCESS: 'check-form__specification--success',
    CHECK_FORM_SPECIFICATION: 'check-form__specification',
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
    TERMS_AGREE: 'terms-agree',
    FINAL_ELEMENT: 'finalcheck',
    CHECK_AGREE: 'checkagree',
    CHECKYES: 'chkYes',
    SAMPLE_VIN: 'sample-Vin',
    CHECK_GENERAL: 'checkgeneral',
    CHECK_FIELD: 'checkfields',
    TEXTBOX: 'textbox',
    CHECK_INPUT1: 'checkinput1',
    CHECK_INPUT2: 'checkinput2',
    CHECK_INPUT3: 'checkinput3',
    CHECK_INPUT4: 'checkinput4',
    CHECK_INPUT5: 'checkinput5',
    CHECK_INPUT6: 'checkinput6',
    CHECK_INPUT7: 'checkinput7',
    CHOOSE_FILES_ID: 'CreateForm-fileID',
    ADD_FILE_BUTTTON: 'Submit-fileID',
    ADD_SUBFILE_BUTTTON: 'SubSubmit-fileID',
    CHOOSE_SUBFILES_ID: 'CreateFormSub-fileID',
    CHOOSE_FILES_ID_COMMENTS: 'submitNow-fileID',
    FILE_SPAN_BUTTTON_COMMENTS: 'sizeError',
};

export default class UISCreateFormUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.CHECK_FORM_CONTROL}`) || el.target.matches(`.${CLASS.CHECK_FORM_TEXTAREA}`) || el.target.matches(`.${CLASS.CHECK_FORM_SAMPLE}`) || el.target.matches(`.${CLASS.CHECK_FORM_SPECIFICATION}`)) {
                this.validateInput(el.target);
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        const regex = /^(?=.*[^\s])([a-zA-Z0-9.,?;\-\s]{1,})$/;
        return regex.test(elementValue);
    }
    containAlphabetAndDigitonly(elementValue) {
        console.log(this);
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[^\s]{17}$/;
        return regex.test(elementValue);
    }


    /**
     * Check if Input Field contain data or not.
     */
    containDigitsOnly(elementValue) {
        console.log(this);
        const regex = /^[0-9]{1,50}$/;
        return regex.test(elementValue);
    }

    /**
     * Check if Input Field contain data or not.
     */
    containAlphabetsOnly(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(elementValue);
    }
    containPhonenumberOnly(elementValue) {
        console.log(this);
        const regex = /^[0-9]{10}$/;
        return regex.test(elementValue);
    }
    containEmailOnly(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z0-9._-]{1,90}@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        return regex.test(elementValue);
    }
    containDateOnly(elementValue) {
        console.log(this);
        const regex = /^([0][1-9]|[1][0-2])\/([0][1-9]|[1|2][0-9]|[3][0|1])\/([0-9]{4})$/;
        return regex.test(elementValue);
    }


    /**
     * Display Error
     */

    displayError(element) {
        console.log(this);
        const radio = document.getElementById(`${ID.CHECKYES}`);
        const length1 = document.getElementById('upfitterbox1');
        const wid1 = document.getElementById('upfitterbox2');
        const height1 = document.getElementById('upfitterbox3');
        const centerOfGravity1 = document.getElementById('upfitterbox4');
        const frontAxleLoad1 = document.getElementById('upfitterbox5');
        const rearAxleLoad1 = document.getElementById('upfitterbox6');
        const uvwAfterUpfit1 = document.getElementById('upfitterbox7');
        if (element.name === 'sample-Vin' && radio.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SAMPLE_ERROR}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext1' && length1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext2' && wid1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext3' && height1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext4' && centerOfGravity1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext5' && frontAxleLoad1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext6' && rearAxleLoad1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else if (element.name === 'upfittertext7' && uvwAfterUpfit1.checked) {
            element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        } else {
            element.classList.add(`${CLASS.CHECK_FORM_CONTROL_ERROR}`);
            element.classList.remove(`${CLASS.CHECK_FORM_CONTROL_SUCCESS}`);
            if (element.nextElementSibling != null) {
                element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
            }
        }
    }

    displaySampleError(element) {
        console.log(this);
        element.classList.add(`${CLASS.CHECK_FORM_SAMPLE_ERROR}`);
        element.classList.remove(`${CLASS.CHECK_FORM_SAMPLE_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.CHECK_FORM_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }
    displaySampleDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_SAMPLE_SUCCESS}`);
        element.classList.remove(`${CLASS.CHECK_FORM_SAMPLE_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.CHECK_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    hideSampleError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_SAMPLE_ERROR}`);
        element.classList.add(`${CLASS.CHECK_FORM_SAMPLE_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    displaySpecificationError(element) {
        console.log(this);
        element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
        element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    displaySpecificationDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
        element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    hideSpecificationError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.CHECK_FORM_SPECIFICATION_ERROR}`);
        element.classList.add(`${CLASS.CHECK_FORM_SPECIFICATION_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    validateSampleVinFields(el, isdataelementValuevalid) {
        const RadioCheck = document.getElementById(`${ID.CHECKYES}`);
        if (RadioCheck.checked) {
            if (isdataelementValuevalid) {
                this.hideSampleError(el);
            } else {
                this.displaySampleError(el);
            }
        }
    }

    validateSpecification(el, isDataValid) {
        if (isDataValid) {
            this.hideSpecificationError(el);
        } else {
            this.displaySpecificationError(el);
        }
    }

    /**
     * Validate all fields of form.
     */

    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.CHECK_FORM_CONTROL}`);
        const inputSampleField = document.querySelectorAll(`.${CLASS.CHECK_FORM_SAMPLE}`);
        const vf = this.validateFields(inputFields);
        const vsv = this.validateSampleVin(inputSampleField);
        const vlcbf = this.validateLastCheckBoxFields();
        const vsf = this.validateSubmitFields();
        const vtf = this.validateTextAreaFields();
        const validinput = this.validateAllfieldinputs();
        if (vf && vsv && vlcbf && vsf && vtf && validinput) {
            return true;
        }
        return false;
    }

    validateFileSize() {
        console.log(this);
        const files = document.getElementById(`${ID.CHOOSE_FILES_ID}`).files.length;
        const numberOfFiles = document.getElementById(`${ID.CHOOSE_FILES_ID}`).files;
        const totalFiles = document.getElementById('fileList').innerText;
        const fileSplit = totalFiles.split('\n');
        const totalFilesLength = fileSplit.length;
        const filesId = document.getElementById(`${ID.CHOOSE_FILES_ID}`);
        const addFiles = document.getElementById(`${ID.ADD_FILE_BUTTTON}`);
        if (files !== 0) {
            if (files < 6 && totalFilesLength < 5) {
                addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                for (let index = 0; index < files; index += 1) {
                    const fileSize = numberOfFiles[index].size / 1024 / 1024;
                    if (fileSize < 20) {
                        document.getElementById('errmsg').innerHTML = '';
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        return true;
                    }
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.remove('check-form__error-field--hidden');
                    return false;
                }
            } else {
                document.getElementById('errmsg').innerHTML = '';
                filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                addFiles.nextElementSibling.classList.remove('check-form__error-field--hidden');
                return false;
            }
        }
        return false;
    }

    validateSubFileSize() {
        console.log(this);
        const files = document.getElementById(`${ID.CHOOSE_SUBFILES_ID}`).files.length;
        const numberOfFiles = document.getElementById(`${ID.CHOOSE_SUBFILES_ID}`).files;
        const filesId = document.getElementById(`${ID.CHOOSE_SUBFILES_ID}`);
        const addFiles = document.getElementById(`${ID.ADD_SUBFILE_BUTTTON}`);
        const totalFiles = document.getElementById('subfileList').innerText;
        const fileSplit = totalFiles.split('\n');
        const totalFilesLength = fileSplit.length;
        if (files !== 0) {
            if (files < 6 && totalFilesLength < 5) {
                addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                for (let index = 0; index < files; index += 1) {
                    const fileSize = numberOfFiles[index].size / 1024 / 1024;
                    if (fileSize < 20) {
                        document.getElementById('errmsgNested').innerHTML = '';
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        return true;
                    }
                    document.getElementById('errmsgNested').innerHTML = '';
                    filesId.nextElementSibling.classList.remove('check-form__error-field--hidden');
                    return false;
                }
            } else {
                document.getElementById('errmsgNested').innerHTML = '';
                filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                addFiles.nextElementSibling.classList.remove('check-form__error-field--hidden');
                return false;
            }
        }
        return false;
    }

    validateCommentFileSize() {
        console.log(this);
        const files = document.getElementById(`${ID.CHOOSE_FILES_ID_COMMENTS}`).files.length;
        const numberOfFiles = document.getElementById(`${ID.CHOOSE_FILES_ID_COMMENTS}`).files;
        const filesId = document.getElementById(`${ID.CHOOSE_FILES_ID_COMMENTS}`);
        const addFiles = document.getElementById(`${ID.FILE_SPAN_BUTTTON_COMMENTS}`);
        if (files !== 0) {
            if (files < 6) {
                addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                for (let index = 0; index < files; index += 1) {
                    const fileSize = numberOfFiles[index].size / 1024 / 1024;
                    if (fileSize < 20) {
                        document.getElementById('errmsg').innerHTML = '';
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        return true;
                    }
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.remove('check-form__error-field--hidden');
                    return false;
                }
            } else {
                document.getElementById('errmsg').innerHTML = '';
                filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                addFiles.nextElementSibling.classList.remove('check-form__error-field--hidden');
                return false;
            }
        }
        return false;
    }
    validateTextAreaFields() {
        console.log(this);
        const modificationCheck = document.getElementsByClassName('checkbox_Valid');
        for (let i = 0; i < modificationCheck.length; i += 1) {
            if (modificationCheck[i].checked) {
                const textArea = modificationCheck[i].labels[0]
                .nextElementSibling.firstElementChild;
                if (!textArea.value) {
                    textArea.nextElementSibling.classList.remove('check-form__error-field--hidden');
                    return false;
                }
                textArea.nextElementSibling.classList.add('check-form__error-field--hidden');
                return true;
            }
        }
        return true;
    }

    validateLastCheckBoxFields() {
        console.log(this);
        const termsElement = document.getElementById(`${ID.FINAL_ELEMENT}`);
        const termsAgree = document.getElementById(`${ID.TERMS_AGREE}`);
        if (termsElement.checked) {
            termsAgree.classList.add('check-form__error-field--hidden');
            return true;
        }
        termsAgree.classList.remove('check-form__error-field--hidden');
        return false;
    }

    validateAllfieldinputs() {
        console.log(this);
        const checkgeneral = document.getElementById('generalerrcheck');
        const generalinfofields = this.validateGeneralInfoFields();
        const specificationfields = this.validateSpecificationFields();
        const modificationfields = this.validatemodificationfields();
        if (generalinfofields && specificationfields && modificationfields) {
            checkgeneral.classList.add('check-form__error-field--hidden');
            return true;
        }
        checkgeneral.classList.remove('check-form__error-field--hidden');
        return false;
    }


    validateSubmitFields() {
        console.log(this);
        const checkagreed = document.getElementById(`${ID.CHECK_AGREE}`);
        const basicvehicle = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]:checked');
        const bodyshell = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]:checked');
        const engineperipherals = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]:checked');
        const Interior = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]:checked');
        const addlequipment = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]:checked');
        const attachments = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]:checked');
        if (basicvehicle.length >= 1
                || bodyshell.length >= 1 || engineperipherals.length >= 1
                || Interior.length >= 1 || addlequipment.length >= 1
                || attachments.length >= 1) {
            checkagreed.classList.add('check-form__error-field--hidden');
            return true;
        }
        checkagreed.classList.remove('check-form__error-field--hidden');
        return false;
    }

    validateGeneralInfoFields() {
        console.log(this);
        const name = document.getElementById('name').value;
        const company = document.getElementById('company').value;
        const position = document.getElementById('position').value;
        const email = document.getElementById('email-address').value;
        const phonenumber = document.getElementById('phone-number').value;
        const date = document.getElementById('date').value;
        const topic = document.getElementById('topicOfUis').value;
        const number = document.getElementById('projected-Sales-Numbers').value;
        const RadioCheck = document.getElementById(`${ID.CHECKYES}`);
        const samplevininput = document.getElementById('sample-Vin').value;
        const n = this.containData(name);
        const e = this.containEmailOnly(email);
        const c = this.containData(company);
        const p = this.containData(position);
        const phn = this.containPhonenumberOnly(phonenumber);
        const d = this.containDateOnly(date);
        const t = this.containData(topic);
        const num = this.containDigitsOnly(number);
        const samplevin = this.containAlphabetAndDigitonly(samplevininput);
        let valid = 0;
        let validvin = 0;
        if (n && e && c && p && phn && d && t && num) {
            valid = 1;
        } if (RadioCheck.checked === true && samplevin === false) {
            validvin = 1;
        }
        if (valid === 1 && validvin === 0) {
            return true;
        }
        return false;
    }

    validateSpecificationFields() {
        console.log(this);
        const checkinput1 = document.getElementById(`${ID.CHECK_INPUT1}`);
        const checkinput2 = document.getElementById(`${ID.CHECK_INPUT2}`);
        const checkinput3 = document.getElementById(`${ID.CHECK_INPUT3}`);
        const checkinput4 = document.getElementById(`${ID.CHECK_INPUT4}`);
        const checkinput5 = document.getElementById(`${ID.CHECK_INPUT5}`);
        const checkinput6 = document.getElementById(`${ID.CHECK_INPUT6}`);
        const checkinput7 = document.getElementById(`${ID.CHECK_INPUT7}`);
        const length = document.getElementById('upfittertext1');
        const wid = document.getElementById('upfittertext2');
        const height = document.getElementById('upfittertext3');
        const centerOfGravity = document.getElementById('upfittertext4');
        const frontAxleLoad = document.getElementById('upfittertext5');
        const rearAxleLoad = document.getElementById('upfittertext6');
        const uvwAfterUpfit = document.getElementById('upfittertext7');
        const length1 = document.getElementById('upfitterbox1');
        const wid1 = document.getElementById('upfitterbox2');
        const height1 = document.getElementById('upfitterbox3');
        const centerOfGravity1 = document.getElementById('upfitterbox4');
        const frontAxleLoad1 = document.getElementById('upfitterbox5');
        const rearAxleLoad1 = document.getElementById('upfitterbox6');
        const uvwAfterUpfit1 = document.getElementById('upfitterbox7');
        let l = 0;
        if (length1.checked === true && this.containDigitsOnly(length.value) !== true) {
            checkinput1.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (wid1.checked === true && this.containDigitsOnly(wid.value) !== true) {
            checkinput2.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (height1.checked === true &&
                                   this.containDigitsOnly(height.value) !== true) {
            checkinput3.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (centerOfGravity1.checked === true &&
                 this.containDigitsOnly(centerOfGravity.value) !== true) {
            checkinput4.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (frontAxleLoad1.checked === true
                   && this.containDigitsOnly(frontAxleLoad.value) !== true) {
            checkinput5.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (rearAxleLoad1.checked === true
                       && this.containDigitsOnly(rearAxleLoad.value) !== true) {
            checkinput6.classList.remove('check-form__error-field--hidden');
            l = 1;
        } if (uvwAfterUpfit1.checked === true
                      && this.containDigitsOnly(uvwAfterUpfit.value) !== true) {
            checkinput7.classList.remove('check-form__error-field--hidden');
            l = 1;
        }
        if (l === 1) {
            return false;
        }
        return true;
    }

    validatemodificationfields() {
        console.log(this);
        const basicvehicle = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]:checked');
        const bodyshell = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]:checked');
        const engineperipherals = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]:checked');
        const Interior = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]:checked');
        const addlequipment = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]:checked');
        const attachments = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]:checked');
        const upfitter1 = document.getElementById('upfittertext24_1').value;
        const upfitter2 = document.getElementById('upfittertext24_2').value;
        const upfitter3 = document.getElementById('upfittertext24_3').value;
        const upfitter4 = document.getElementById('upfittertext26_1').value;
        const upfitterbox1 = document.getElementById('upfitterbox24_1');
        const upfitterbox2 = document.getElementById('upfitterbox24_2');
        const upfitterbox3 = document.getElementById('upfitterbox24_3');
        const upfitterbox4 = document.getElementById('upfitterbox26_1');
        const text1 = document.getElementById('text24_1');
        const text2 = document.getElementById('text24_2');
        const text3 = document.getElementById('text24_3');
        const text4 = document.getElementById('text26_1');
        const checkboxcheck1 = document.getElementById('upfitterbox24');
        const checkboxcheck2 = document.getElementById('upfitterbox26');
        const checkerr = basicvehicle.length + bodyshell.length + engineperipherals.length +
                Interior.length + addlequipment.length + attachments.length;
        let k = 0;
        let nestedcheck = 0;
        if (basicvehicle.length !== 0) {
            for (let i = 0; i < basicvehicle.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(basicvehicle[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(basicvehicle[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (bodyshell.length !== 0) {
            for (let i = 0; i < bodyshell.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(bodyshell[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(bodyshell[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (engineperipherals.length !== 0) {
            for (let i = 0; i < engineperipherals.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(engineperipherals[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(engineperipherals[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (Interior.length !== 0) {
            for (let i = 0; i < Interior.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(Interior[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(Interior[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (addlequipment.length !== 0) {
            for (let i = 0; i < addlequipment.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(addlequipment[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(addlequipment[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (attachments.length !== 0) {
            for (let i = 0; i < attachments.length; i += 1) {
                const textarea = document.getElementById('upfittertext'.concat(attachments[i].id.slice(-2))).querySelector('textarea').value;
                const geterrmsg = document.getElementById('text'.concat(attachments[i].id.slice(-2))).id;
                const errmsg = document.getElementById(geterrmsg);
                const p = this.containData(textarea);
                if (p === true) {
                    errmsg.classList.add('check-form__error-field--hidden');
                    k += 1;
                } else {
                    errmsg.classList.remove('check-form__error-field--hidden');
                }
            }
        } if (checkboxcheck1.checked) {
            if (upfitterbox1.checked) {
                const p = this.containData(upfitter1);
                if (p === true) {
                    text1.classList.add('check-form__error-field--hidden');
                } else {
                    text1.classList.remove('check-form__error-field--hidden');
                    nestedcheck = 1;
                }
            } if (upfitterbox2.checked) {
                const p = this.containData(upfitter2);
                if (p === true) {
                    text2.classList.add('check-form__error-field--hidden');
                } else {
                    text2.classList.remove('check-form__error-field--hidden');
                    nestedcheck = 1;
                }
            } if (upfitterbox3.checked) {
                const p = this.containData(upfitter3);
                if (p === true) {
                    text3.classList.add('check-form__error-field--hidden');
                } else {
                    text3.classList.remove('check-form__error-field--hidden');
                    nestedcheck = 1;
                }
            }
        } if (checkboxcheck2.checked) {
            if (upfitterbox4.checked) {
                const p = this.containData(upfitter4);
                if (p === true) {
                    text4.classList.add('check-form__error-field--hidden');
                } else {
                    text4.classList.remove('check-form__error-field--hidden');
                    nestedcheck = 1;
                }
            }
        }
        if (k !== checkerr) {
            return false;
        }
        if (nestedcheck !== 0) {
            return false;
        }
        return true;
    }


    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.CHECK_FORM_CONTROL_ERROR}`)) {
                return false;
            }
        }
        return true;
    }

    validateSampleVin(inputSampleField) {
        [].forEach.call(inputSampleField, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputSampleField.length; i += 1) {
            if (inputSampleField[i].matches(`.${CLASS.CHECK_FORM_SAMPLE_ERROR}`)) {
                inputSampleField[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'name') {
            if (el.value) {
                this.validate(el, this.containData(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'company') {
            if (el.value) {
                this.validate(el, this.containData(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'position') {
            if (el.value) {
                this.validate(el, this.containData(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'date') {
            if (el.value) {
                this.validate(el, this.containDateOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'phone-number') {
            if (el.value) {
                this.validate(el, this.containPhonenumberOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'email-address') {
            if (el.value) {
                this.validate(el, this.containEmailOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'topicOfUis') {
            if (el.value) {
                this.validate(el, this.containData(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'projected-Sales-Numbers') {
            if (el.value) {
                this.validate(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'sample-Vin') {
            if (el.value) {
                this.validateSampleVinFields(el, this.containAlphabetAndDigitonly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displaySampleDefault(el);
            }
        } else if (el.name === 'upfittertext1') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext2') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext3') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext4') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext5') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext6') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'upfittertext7') {
            if (el.value) {
                this.validateSpecification(el, this.containDigitsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
