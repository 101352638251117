/**
 * Template for waypoint nav
 * Uses ES6 template literal to composite the markup for a waypoint nav
 */
import { html } from 'utils';
import WaypointNavContentTemplate from './waypoint-nav-content';

/**
 * @param waypoints {Array} the array of waypoint nav items
 */
export default (waypoints = []) => html`
    <ul class="waypoint-nav__container sticky-nav__container">
        ${WaypointNavContentTemplate(waypoints)}
    </ul>
`.trim();
