import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import HomeOfficeSupportEditUtil from 'modules/HomeOfficeSupportEditUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'home-office-support-edit-form',
    SITE_NAME: 'site-name',
    CANCELBUTTON: 'cancelbtn',
    VIEWEDITBUTTON: 'viewEdit',
};

export default class HomeOfficeSupportEdit {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        console.log('inside init');
        this.loadModules();
        this.attachEvents();
        this.getEditRecord();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'homeOfficeSupportEdit';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        console.log('inside load module');
        this.HomeOfficeSupportEditUtil = new HomeOfficeSupportEditUtil(this.element);
        console.log('inside load module');
        this.Ajax = new Ajax(this.element);
    }

    getEditRecord() {
        const param = new URLSearchParams(window.location.search);
        const position = param.get('ref');
        const url = '/bin/microsites/home-office-edit-record';
        const data = {
            position,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            console.log(success);
            this.setFormData(success);
        });
    }

    setFormData(success) {
        console.log(this);
        console.log('Set form data called');
        const param = new URLSearchParams(window.location.search);
        const homeOfficeResponse = JSON.parse(JSON.parse(success));
        document.getElementById('requestNumber').value = homeOfficeResponse.requestNumber;
        document.getElementById('name').value = homeOfficeResponse.name;
        document.getElementById('topic').value = homeOfficeResponse.topic;
        document.getElementById('requestOverview').value = homeOfficeResponse.requestOverview;
        document.getElementById('dateTime').value = homeOfficeResponse.dateTime;
        document.getElementById('smeName').value = homeOfficeResponse.smeName;
        document.getElementById('smeContacted').value = homeOfficeResponse.smeContacted;
        document.getElementById('smeRespond').value = homeOfficeResponse.smeRespond;
        document.getElementById('smeContactedDate').value = homeOfficeResponse.smeContactedDate;
        document.getElementById('smeRespondDate').value = homeOfficeResponse.smeRespondDate;
        document.getElementById('dmName').value = homeOfficeResponse.dmName;
        document.getElementById('dmcontactedSME').value = homeOfficeResponse.dmcontactedSME;
        document.getElementById('smeRespond_first').value = homeOfficeResponse.smeRespond_first;
        document.getElementById('smeRespond_second').value = homeOfficeResponse.smeRespond_second;
        document.getElementById('gmContactedSme').value = homeOfficeResponse.gmContactedSme;
        document.getElementById('gmName').value = homeOfficeResponse.gmName;
        document.getElementById('vpName').value = homeOfficeResponse.vpName;
        document.getElementById('vpContactedSme').value = homeOfficeResponse.vpContactedSme;
        document.getElementById('smeRespond_third').value = homeOfficeResponse.smeRespond_third;
        document.getElementById('dmcontactedSMEDate').value = homeOfficeResponse.dmcontactedSMEDate;
        document.getElementById('smeRespond_firstDate').value = homeOfficeResponse.smeRespond_firstDate;
        document.getElementById('gmContactedSmeDate').value = homeOfficeResponse.gmContactedSmeDate;
        document.getElementById('smeRespondedDate_second').value = homeOfficeResponse.smeRespondedDate_second;
        document.getElementById('vpContactedSmeDate').value = homeOfficeResponse.vpContactedSmeDate;
        document.getElementById('smeRespond_thirdDate').value = homeOfficeResponse.smeRespond_thirdDate;
        if (param.has('mode')) {
            console.log('view if called');
            document.getElementById('form-heading').innerHTML = 'BRC Home Office Support Request Information view';
            document.getElementById('form-subheading').style.display = 'none';
            document.getElementById('requestNumber').setAttribute('disabled', 'disabled');
            document.getElementById('name').setAttribute('disabled', 'disabled');
            document.getElementById('topic').setAttribute('disabled', 'disabled');
            document.getElementById('requestOverview').setAttribute('disabled', 'disabled');
            document.getElementById('dateTime').setAttribute('disabled', 'disabled');
            document.getElementById('smeName').setAttribute('disabled', 'disabled');
            document.getElementById('smeContacted').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond').setAttribute('disabled', 'disabled');
            document.getElementById('smeContactedDate').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespondDate').setAttribute('disabled', 'disabled');
            document.getElementById('dmName').setAttribute('disabled', 'disabled');
            document.getElementById('dmcontactedSME').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond_first').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond_second').setAttribute('disabled', 'disabled');
            document.getElementById('gmContactedSme').setAttribute('disabled', 'disabled');
            document.getElementById('gmName').setAttribute('disabled', 'disabled');
            document.getElementById('vpName').setAttribute('disabled', 'disabled');
            document.getElementById('vpContactedSme').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond_third').setAttribute('disabled', 'disabled');
            document.getElementById('dmcontactedSMEDate').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond_firstDate').setAttribute('disabled', 'disabled');
            document.getElementById('gmContactedSmeDate').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespondedDate_second').setAttribute('disabled', 'disabled');
            document.getElementById('vpContactedSmeDate').setAttribute('disabled', 'disabled');
            document.getElementById('smeRespond_thirdDate').setAttribute('disabled', 'disabled');
            document.getElementById('buttons').style.display = 'none';
            document.getElementById('view-form-buttons').removeAttribute('style');
        } else {
            document.getElementById('form-heading').innerHTML = 'BRC Home Office Support Request Information';
            document.getElementById('form-subheading').removeAttribute('style');
            document.getElementById('requestNumber').setAttribute('disabled', 'disabled');
            document.getElementById('name').setAttribute('disabled', 'disabled');
            document.getElementById('topic').setAttribute('disabled', 'disabled');
            document.getElementById('requestOverview').setAttribute('disabled', 'disabled');
            document.getElementById('dateTime').setAttribute('disabled', 'disabled');
            document.getElementById('smeName').removeAttribute('disabled');
            if (document.getElementById('smeContacted').value === 'Yes') {
                document.getElementById('smeContactedDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeContactedDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond').value === 'Yes') {
                document.getElementById('smeRespondDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespondDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_third').value === 'Yes') {
                document.getElementById('smeRespond_thirdDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespond_thirdDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_first').value === 'Yes') {
                document.getElementById('smeRespond_firstDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespond_firstDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('dmcontactedSME').value === 'Yes') {
                document.getElementById('dmcontactedSMEDate').removeAttribute('disabled');
            } else {
                document.getElementById('dmcontactedSMEDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('gmContactedSme').value === 'Yes') {
                document.getElementById('gmContactedSmeDate').removeAttribute('disabled');
            } else {
                document.getElementById('gmContactedSmeDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_second').value === 'Yes') {
                document.getElementById('smeRespondedDate_second').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespondedDate_second').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('vpContactedSme').value === 'Yes') {
                document.getElementById('vpContactedSmeDate').removeAttribute('disabled');
            } else {
                document.getElementById('vpContactedSmeDate').setAttribute('disabled', 'disabled');
            }
            document.getElementById('dmName').removeAttribute('disabled');
            document.getElementById('gmName').removeAttribute('disabled');
            document.getElementById('vpName').removeAttribute('disabled');
            document.getElementById('buttons').removeAttribute('style');
            document.getElementById('view-form-buttons').style.display = 'none';
            this.loadCaptcha();
        }
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();


                const capRes = grecaptcha.getResponse();
                console.log(capRes);
                if (this.HomeOfficeSupportEditUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    console.log('mbm edit form');
                    const formToJSONObject = elements => [].reduce.call(elements,
                            (data, element) => {
                                data[element.name] = element.value;
                                return data;
                            }, {});

                    console.log(formToJSONObject(inputs));

                    const editUrl = `${window.location.origin}/content/HomeOfficeSupportEditForm.html?ref=`;
                    const siteName = 'homeOffieSupportEdit';
                    const url = '/bin/microsites/home-office-return-edit';
                    const param = new URLSearchParams(window.location.search);
                    const position = param.get('ref');
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                        editUrl,
                        position,
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.HomeOfficeSupportEditUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            form.reset();
                            this.HomeOfficeSupportEditUtil.displayPopUp('Values Updated successfully');

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.HomeOfficeSupportEditUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.CANCELBUTTON}`)) {
                e.preventDefault();
                window.close();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.VIEWEDITBUTTON}`)) {
                e.preventDefault();
                const param = new URLSearchParams(window.location.search);
                const position = param.get('ref');
                const currentUrl = window.location.href;
                if (!currentUrl) return;
                const editUrl = `${currentUrl.split('?')[0]}?ref=${position}`;
                window.location.assign(editUrl);
                // window.location.href = editUrl;
            }
        });
    }
}
