function checkSinColumnHead(key) {
    if (key === 'sin') {
        return '';
    }
    return `<th class="sort" >${key.replace(/([A-Z])/g, ' $1').trim()}</th>`;
}

export default item => `
    ${Object.keys(item).map(key => `
        ${checkSinColumnHead(key)}
        `).join('')}
`;
