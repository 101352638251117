import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import BRCFieldCastFormUtil from 'modules/BRCFieldCastFormUtil';

const ID = {
    SUBMITBUTTON: 'brcFieldCastFormSubmit',
    SITE_NAME: 'site-name',
    NAME: 'brcFieldCastForm-Name',
    EMAIL: 'brcFieldCastForm-Email',
    TOPIC: 'brcFieldCastForm-topic',
    CAST: 'brcFieldCastForm-cast',
    SLIDE_OVERVIEW: 'brcFieldCastForm-slideOverview',
    CORRESPONDENCE: 'brcFieldCastForm-fileID',
    BRC_FIELD_CAST_FORM: 'brcFieldCastForm',
    BRC_DOTS_ERROR_MESSAGE: 'BrcdotsErrorMessage'

};

export default class BRCFieldCastForm {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.BRCFieldCastFormUtil = new BRCFieldCastFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                if (this.BRCFieldCastFormUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.BRC_FIELD_CAST_FORM}`);
                    const inputs = form.elements;
                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const nameBrc = document.getElementById(`${ID.NAME}`).value;
                    const requesterEmail = document.getElementById(`${ID.EMAIL}`).value;
                    const topic = document.getElementById(`${ID.TOPIC}`).value;
                    const cast = document.getElementById(`${ID.CAST}`).value;
                    const slideOverview = document.getElementById(`${ID.SLIDE_OVERVIEW}`).value;
                    const edit = `${window.location.origin}/content/business-resource-center/brcFieldCastSubmissionReport.html`;
                    const url = '/bin/microsites/brc/BRCFieldCastFormSendEmail';

                    const file = document.getElementById(`${ID.CORRESPONDENCE}`).files[0];
                    const fileName = file.name;
                    const dotIndex = fileName.lastIndexOf('.');
                    const nameBeforeExtension = fileName.slice(0,dotIndex);
                    const regex = /\./;

                    const fileData = new FormData();
                    
                    if(regex.test(nameBeforeExtension)) {
                        document.getElementById(`${ID.BRC_DOTS_ERROR_MESSAGE}`).style.display = "block";
                        document.getElementById(`${ID.SUBMITBUTTON}`).setAttribute('disabled');
                    }
                    else {
                        document.getElementById(`${ID.BRC_DOTS_ERROR_MESSAGE}`).style.display = "none";
                        document.getElementById(`${ID.SUBMITBUTTON}`).removeAttribute('disabled');
                        fileData.set('file', file);
                    }

                    fileData.set('siteName', siteName);
                    fileData.set('name', nameBrc);
                    fileData.set('requesterEmail', requesterEmail);
                    fileData.set('topic', topic);
                    fileData.set('cast', cast);
                    fileData.set('slideOverview', slideOverview);
                    fileData.set('edit', edit);

                    this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                        if (success === 'true') {
                            this.BRCFieldCastFormUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.BRCFieldCastFormUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });
    }
}
