import { EVENTS, CLASSES } from 'Constants';
import { ReCAPTCHA } from 'partials/recaptcha';
import Ajax from 'modules/Ajax';

// Util dependencies
import { renderer, tokenReplace } from 'utils';
import BluetecUpdateInfoTemplate from 'templates/bluetec-update-form-info.js';
import PreferredDealer from 'modules/preferred-dealer';


const CLASS = {
    BLUETECUPDATE_FORM__INPUT: 'bluetecupdate-form__input',
    BLUETECUPDATE_FORM_ERROR: 'bluetecupdate-form__input--error',
    BLUETECUPDATE_FORM_SUCCESS: 'bluetecupdate-form__input--success',
    BLUETECUPDATE_FORM__ERROR_FIELD_HIDDEN: 'bluetecupdate-form__error-field--hidden',
    BLUETECUPDATE_FORM__INFO_CONTAINER: 'bluetecupdate-form__information-container',
    BLUETECUPDATE_FORM_RECAPTCHA_CONTAINER_HIDDEN: 'bluetecupdate-form__recaptcha-container--hidden',
    BLUETECUPDATE_FORM__INFO_CONTAINER_HIDDEN: 'bluetecupdate-form__information-container--hidden',
    BLUETECUPDATE_FORM__INFORMATION: 'bluetecupdate-form__information',
    BLUETECUPDATE_FORM_KEEP_ME_UPDATED_BUTTON: 'bluetecupdate-form--keep-me-updated-button',
    BLUETECUPDATE_FORM__MESSAGE: 'bluetecupdate-form__message',
    OVERLAY_HIDDEN: 'overlay--hidden',
    BLUETECUPDATE_FORM_RECAPTCHA_HIDDEN: 'bluetecupdate-form--recaptcha-hidden',
    POPUP_OPEN: 'pop-up--open',
    ICON_CARET_DOWN: 'icon-arrow-down-square',
    BLUETECUPDATE_FORM__COLUMN_INFO_CONT: 'bluetecupdate-form__column-info__container',
    BLUETECUPDATE_FORM__COLUMN_TITLE: 'bluetecupdate-form__column-title',
    BLUETECUPDATE_FORM__COLUMN_INFO_OPEN: 'bluetecupdate-form__column-info--open',
    BLUETECUPDATE_FORM__COLUMN_INFO: 'bluetecupdate-form__column-info',
    BLUETECUPDATE_FORM__DEALER_WIDGET: 'dealer-widget-container',
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
    BLUETECUPDATE_FORM__SUBMITBUTTON: 'bluetecUpdateFormSubmit',
    BLUETECUPDATE_FORM__PERSONAL_INFO__SUBMITBUTTON: 'bluetecUpdateFormPersonalInfoSubmit',
    BLUETECUPDATE_FORM__RESEND_CONFIRMATION_BTN: 'blueTecUpdateResendConfirmationBtn',
    BLUETECUPDATE__UNSUBSCRIBE_BTN: 'blueTecUpdateUnsubscribeBtn',
    SITE_NAME: 'site-name',
    BLUETECUPDATE_FORM: 'bluetecupdate-form',
    BLUETECUPDATE_FORM__PERSONAL_INFO: 'bluetecupdate-form-personal-info',
    RECAPTCHA: 'bluetecupdate-form-recaptcha',
    PERSONAL_INFO_RECAPTCHA: 'bluetecupdate-form-personal-info-recaptcha',
    PAGE_LOADER: 'page-loader',
    BLUETECUPDATE_UNSUBSCRIBE_FORM: 'bluetecupdate-unsubscribe-form',
    BLUETECUPDATE_RESEND_CONFIRMATION_FORM: 'bluetecupdate-resend-confirmation-form'
};

const CONSTANT = {
    recaptchaEmptyError: 'Please confirm you are not a robot.',
    addressLine2: 'addressLine2',
    G_RECAPTCHA_RESPONSE: 'g-recaptcha-response',
    smooth: 'smooth',
    vinNumber: 'vinNumber',
    email: 'email',
    zip: 'zip',
    phone: 'phone',
    firstName: 'firstName',
    lastName: 'lastName',
    city: 'city',
    state: 'state',
    error: 'error',
    country: 'country',
    lang: 'lang',
    brand: 'brand'
};

const URL = {
    BLUETECUPDATE_FORM_VIN_LOOKUP: ' /bin/microsites/projectGreen/vinLookup',
    BLUETECUPDATE_FORM_KEEP_ME_UPDATED: '/bin/microsites/projectGreen/keepMeUpdated',
    BLUETECUPDATE_FORM_UNSUBSCRIBE: '/bin/microsites/projectGreen/unsubscribe',
    BLUETECUPDATE_FORM_UPDATE_SUBSCRIPTION: '/bin/microsites/projectGreen/updateSubscription',
    BLUETECUPDATE_FORM_RESEND_CONFIRMATION: '/bin/microsites/projectGreen/resendConfirmation'
};

const ATTRIBUTE = {
    NAME: 'name',
    HIDDEN: 'hidden',
    DISABLED: 'disabled',
    REQUIRED: 'required'
};

export default class BluetecUpdate {
    constructor(element) {
        this.element = element;
        this.vinLookupRecaptcha = null; // Object to store reCAPTCHA element
        this.keepMeUpdatedRecaptcha = null;
        this.recaptchaContainer = null; // element reCAPTCHA will append to
        this.vinLookupResponse = null;
        this.keepMeUpdatedResponse = null;
        this.infoContainer = null;
        this.vinNumber = null;
        this.loader = null;
        this.queryString = null;
        this.currentUrlParams = null;
        this.content = window.microsites.bluetecUpdate.localization;
        this.init();
    }

    init() {
        this.cacheDomElement();
        this.recaptchaDisplay(this.vinLookupRecaptchaContainer, 'vinlookup');
        this.loadModules();
        this.updateSubscription();
        this.attachEvents();
    }

    cacheDomElement() {
        this.infoContainer = document.querySelector(`.${CLASS.BLUETECUPDATE_FORM__INFO_CONTAINER}`);
        this.vinLookupRecaptchaContainer = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM}']`).querySelector(`#${ID.RECAPTCHA}`);
        this.keepMeUpdatedRecaptchaContainer = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM__PERSONAL_INFO}']`).querySelector(`#${ID.PERSONAL_INFO_RECAPTCHA}`);
        this.personalInfoForm = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM__PERSONAL_INFO}']`);
        this.body = document.querySelector('body');
        this.loader = document.querySelector(`#${ID.PAGE_LOADER}`);
    }

    recaptchaDisplay(recaptchaContainer, formType) {
        if (window.microsites.applicationProperties.isRecaptchaDisabled) {
            recaptchaContainer.parentElement.classList.add(`${CLASS.BLUETECUPDATE_FORM_RECAPTCHA_CONTAINER_HIDDEN}`);
            recaptchaContainer.parentElement.parentElement.classList.add(`${CLASS.BLUETECUPDATE_FORM_RECAPTCHA_HIDDEN}`);
        } else {
            this.createRecaptcha(recaptchaContainer, formType);
            recaptchaContainer.parentElement.nextElementSibling.querySelector("button[type='submit']").setAttribute(ATTRIBUTE.DISABLED, 'disabled');
        }
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    updateSubscription() {
        const params = this.getQueryStringParameters();

        if (params.token && params.token !== undefined) {
            this.loader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
            const data = { token: params.token };
            const url = `${URL.BLUETECUPDATE_FORM_UPDATE_SUBSCRIPTION}?${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}`;
            this.Ajax.ajaxJsonPostFn(url,
                data, (success) => {
                    if (!(success.indexOf(CONSTANT.error) > 0)) {
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(JSON.parse(success).message);
                    } else {
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(JSON.parse(success).error);
                    }
                }
            );
        }
    }

    getQueryStringParameters(url) {
        console.log(this);
        let query = '';
        if (url) {
            if (url.split('?').length > 0) {
                query = url.split('?')[1];
            }
        } else {
            url = window.location.href;
            query = window.location.search.substring(1);
        }
        return (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
            const [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
            return params;
        }, { });
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.BLUETECUPDATE_FORM__INPUT}`) && el.target.getAttribute(`${ATTRIBUTE.NAME}`) === `${CONSTANT.addressLine2}`) {
                if (el.target.value) {
                    el.target.classList.add(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
                } else {
                    el.target.classList.remove(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
                }
            }
            if (el.target.matches(`.${CLASS.BLUETECUPDATE_FORM__INPUT}`)) {
                this.validateInput(el.target);
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BLUETECUPDATE_FORM__SUBMITBUTTON}`)) {
                this.loader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                e.preventDefault();
                if (!(e.target.getAttribute(ATTRIBUTE.DISABLED) === 'disabled')) {
                    this.submitBluetecFormData();
                }
            } else if (e.target.matches(`#${ID.BLUETECUPDATE_FORM__PERSONAL_INFO__SUBMITBUTTON}`)) {
                this.loader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                e.preventDefault();
                if (!(e.target.getAttribute(ATTRIBUTE.DISABLED) === 'disabled')) {
                    this.submitKeepMeUpdatedFormData();
                }
            } else if (e.target.matches(`.${CLASS.BLUETECUPDATE_FORM_KEEP_ME_UPDATED_BUTTON}`)) {
                this.showInfoContainer(e.target);
            } else if (e.target.matches(`#${ID.BLUETECUPDATE__UNSUBSCRIBE_BTN}`)) {
                this.loader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                e.preventDefault();
                this.requestUnsubscribe();
            } else if (e.target.matches(`#${ID.BLUETECUPDATE_FORM__RESEND_CONFIRMATION_BTN}`)) {
                this.loader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                e.preventDefault();
                this.resendConfirmation();
            } else if (e.target.matches(`.${CLASS.ICON_CARET_DOWN}`) || e.target.matches(`.${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO_CONT}`) || e.target.matches(`.${CLASS.BLUETECUPDATE_FORM__COLUMN_TITLE}`)) {
                let contentContainer = '';
                if (e.target.matches(`.${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO_CONT}`)) {
                    contentContainer = e.target;
                } else if (e.target.matches(`.${CLASS.BLUETECUPDATE_FORM__COLUMN_TITLE}`)) {
                    contentContainer = e.target.parentElement;
                } else if (e.target.matches(`.${CLASS.ICON_CARET_DOWN}`)) {
                    contentContainer = e.target.parentElement.parentElement;
                }

                const infoElement = contentContainer.querySelector(`.${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO}`);
                if (infoElement.classList.contains(`${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO_OPEN}`)) {
                    infoElement.classList.remove(`${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO_OPEN}`);
                } else {
                    infoElement.classList.add(`${CLASS.BLUETECUPDATE_FORM__COLUMN_INFO_OPEN}`);
                }
            }
        });
    }

    requestUnsubscribe() {
        const form = document.getElementById(`${ID.BLUETECUPDATE_UNSUBSCRIBE_FORM}`);
        const inputFields = form.elements;
        const data = {
            vin: this.vinLookupResponse.vin,
            email: form.querySelector("input[name='email']").value,
            redirectUrl: window.location.href
        };
        const url = `${URL.BLUETECUPDATE_FORM_UNSUBSCRIBE}?${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}&${CONSTANT.brand}=${this.content.brand}`;
        if (this.validateAllFields(inputFields)) {
            this.Ajax.ajaxJsonPostFn(url,
                data, (success) => {
                    if (!(success.indexOf(CONSTANT.error) > 0)) {
                        form.reset();
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(tokenReplace(this.content.unsubscription,
                            [this.vinLookupResponse.email]));
                    } else {
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(this.content.unmatchEmailMsg);
                    }
                }
            );
        } else {
            this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
        }
    }

    resendConfirmation() {
        const form = document.getElementById(`${ID.BLUETECUPDATE_RESEND_CONFIRMATION_FORM}`);
        const inputFields = form.elements;
        const data = {
            vin: this.vinLookupResponse.vin,
            email: form.querySelector("input[name='email']").value,
            redirectUrl: window.location.href
        };
        if (this.validateAllFields(inputFields)) {
            const url = `${URL.BLUETECUPDATE_FORM_RESEND_CONFIRMATION}?${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}&${CONSTANT.brand}=${this.content.brand}`;
            this.Ajax.ajaxJsonPostFn(url,
                data, (success) => {
                    if (!(success.indexOf(CONSTANT.error) > 0)) {
                        form.reset();
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(tokenReplace(this.content.subscription,
                            [this.vinLookupResponse.email]));
                    } else {
                        this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                        this.displayPopUp(this.content.unmatchEmailMsg);
                    }
                }
            );
        } else {
            this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
        }
    }

    showInfoContainer() {
        this.personalInfoForm.removeAttribute(`${ATTRIBUTE.HIDDEN}`);
        this.personalInfoForm.scrollIntoView({
            behavior: CONSTANT.smooth,
        });
    }

    submitBluetecFormData() {
        const form = document.getElementById(`${ID.BLUETECUPDATE_FORM}`);
        const inputFields = form.elements;
        if (this.validateAllFields(inputFields)) {
            const formData = inputFields.vinNumber.value;
            let url = '';
            if (this.vinLookupRecaptcha) {
                url = `${URL.BLUETECUPDATE_FORM_VIN_LOOKUP}?${CONSTANT.G_RECAPTCHA_RESPONSE}=${this.vinLookupRecaptcha.getValue()}&${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}`;
            } else {
                url = `${URL.BLUETECUPDATE_FORM_VIN_LOOKUP}?${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}`;
            }
            const data = {
                vin: formData
            };

            this.Ajax.ajaxJsonPostFn(url, data, (success) => {
                if (!(success.indexOf(CONSTANT.error) > 0)) {
                    this.vinLookupResponse = JSON.parse(success);
                    form.reset();
                    this.personalInfoForm.setAttribute(`${ATTRIBUTE.HIDDEN}`, '');
                    this.recaptchaDisplay(this.vinLookupRecaptchaContainer, 'vinlookup');
                    this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                    this.displayKeepMeUpdatedForm(this.vinLookupResponse);

                    [].forEach.call(form.elements, (element) => {
                        if (element.matches(`.${CLASS.BLUETECUPDATE_FORM_SUCCESS}`)) {
                            element.classList.remove(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
                        }
                    });
                } else {
                    console.log('I am here');
                    this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                    this.recaptchaDisplay(this.vinLookupRecaptchaContainer, 'vinlookup');
                    this.displayPopUp(this.content.formSubmissionFailed);
                }
            });
        } else {
            this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
        }
    }

    submitKeepMeUpdatedFormData() {
        const form = document.getElementById(`${ID.BLUETECUPDATE_FORM__PERSONAL_INFO}`);
        const inputFields = form.querySelectorAll("input:not([type ='radio'])");
        const phoneTypeValue = form.querySelector('input[checked]').value;
        if (this.validateAllFields(inputFields)) {
            let url = '';
            if (this.keepMeUpdatedRecaptcha) {
                url = `${URL.BLUETECUPDATE_FORM_KEEP_ME_UPDATED}?${CONSTANT.G_RECAPTCHA_RESPONSE}=${this.keepMeUpdatedRecaptcha.getValue()}&${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}&${CONSTANT.country}=${this.content.country}&${CONSTANT.brand}=${this.content.brand}`;
            } else {
                url = `${URL.BLUETECUPDATE_FORM_KEEP_ME_UPDATED}?${CONSTANT.country}=${this.content.country}&${CONSTANT.lang}=${this.content.language}&${CONSTANT.brand}=${this.content.brand}`;
            }
            const formToJSONObject = elements => [].reduce.call(elements,
                (jsonObj, element) => {
                    jsonObj[element.name] = element.value;
                    return jsonObj;
                }, {});
            let dataToSend = {
                vin: this.vinLookupResponse.vin,
                phoneType: phoneTypeValue,
                redirectUrl: window.location.href
            };
            dataToSend = Object.assign(formToJSONObject(inputFields),
            dataToSend);
            this.Ajax.ajaxJsonPostFn(url, dataToSend, (success) => {
                this.keepMeUpdatedResponse = JSON.parse(success);
                if ((!(success.indexOf(CONSTANT.error) > 0)) &&
                this.keepMeUpdatedResponse.Item.status) {
                    form.reset();
                    this.recaptchaDisplay(this.keepMeUpdatedRecaptchaContainer, 'keepmeupdated');
                    [].forEach.call(form.elements, (element) => {
                        if (element.matches(`.${CLASS.BLUETECUPDATE_FORM_SUCCESS}`)) {
                            element.classList.remove(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
                        }
                    });
                    this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                    this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                    this.displayPopUp(tokenReplace(this.content.subscription,
                        [this.keepMeUpdatedResponse.Item.email]));
                    this.displayBluetecUpdateForm(this.keepMeUpdatedResponse);
                } else {
                    this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
                    this.displayPopUp(this.content.formSubmissionFailed);
                    this.recaptchaDisplay(this.keepMeUpdatedRecaptchaContainer, 'keepmeupdated');
                }
            });
        } else {
            this.loader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
        }
    }

    displayKeepMeUpdatedForm(data) {
        this.infoContainer.classList.remove(`${CLASS.BLUETECUPDATE_FORM__INFO_CONTAINER_HIDDEN}`);
        if (data.vin === undefined) {
            data.vin = '';
        }
        if (data.model === undefined) {
            data.model = '';
        }
        if (data.modelYear === undefined) {
            data.modelYear = '';
        }
        const updatedData = this.updateSummaryTableResponse(data);
        document.querySelector(`.${CLASS.BLUETECUPDATE_FORM__INFORMATION}`).innerHTML = `${BluetecUpdateInfoTemplate(updatedData, this.content)}`;
        this.keepMeUpdatedRecaptchaContainer = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM__PERSONAL_INFO}']`).querySelector(`#${ID.PERSONAL_INFO_RECAPTCHA}`);
        this.recaptchaDisplay(this.keepMeUpdatedRecaptchaContainer, 'keepmeupdated');

        const dealerWidgetElement = document.querySelector(`.${CLASS.BLUETECUPDATE_FORM__DEALER_WIDGET}`);
        if (dealerWidgetElement) {
            this.PreferredDealer = new PreferredDealer(dealerWidgetElement);
        }
    }

    /*
        Function to update summary response to add country specific
        labels that is to be displayed in the table.
        @return : response with updated summary.
    */
    updateSummaryTableResponse(data) {
        console.log(this);
        [].forEach.call(Object.keys(data.summary), (key) => {
            if (key === 'EM_APPROVED_BY_EPA_AND_CARB' || key === 'ELIGIBLE_FOR_EM'
                    || key === 'EM_COMPLETED' || key === 'EXTENDED_EMISSION_WARRANTY') {
                data.summary[key] = {
                    label: data.summary[key].label,
                    value: data.summary[key].value,
                    displayValue: data.summary[key].displayValue,
                };
            } else {
                data.summary[key] = {
                    label: key,
                    value: data.summary[key]
                };
            }
        });
        console.log('updateSummaryTableResponse', data);
        return data;
    }

    displayBluetecUpdateForm(data) {
        this.personalInfoForm.setAttribute(`${ATTRIBUTE.HIDDEN}`, '');
        const infoElement = document.querySelector(`.${CLASS.BLUETECUPDATE_FORM__INFORMATION}`);
        document.querySelector(`.${CLASS.BLUETECUPDATE_FORM_KEEP_ME_UPDATED_BUTTON}`).remove();
        const messageField = document.createElement('p');
        messageField.setAttribute('class', `${CLASS.BLUETECUPDATE_FORM__MESSAGE}`);
        messageField.innerHTML = tokenReplace(this.content.subscription, [data.Item.email]);
        infoElement.appendChild(messageField);
        this.recaptchaDisplay(this.vinLookupRecaptchaContainer, 'vinlookup');
    }

    /**
     * @method createRecaptcha
     * @description Instantiates ReCAPTCHA field and renders it
     */
    createRecaptcha(recaptchaContainer, formType) {
        if (formType.toLowerCase() === 'vinlookup') {
            this.vinLookupRecaptcha = new ReCAPTCHA({
                language: this.content.language,
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess
            });
            renderer.insert(this.vinLookupRecaptcha.render(), recaptchaContainer);
        } else if (formType.toLowerCase() === 'keepmeupdated') {
            this.keepMeUpdatedRecaptcha = new ReCAPTCHA({
                language: this.content.language,
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess.bind(this)
            });
            renderer.insert(this.keepMeUpdatedRecaptcha.render(), recaptchaContainer);
        }
    }

    onSuccess(recaptchaContainer) {
        console.log(this);
        const recaptchaId = recaptchaContainer.parentElement.parentElement.getAttribute('id');
        const vinLookupForm = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM}']:not([hidden])`);
        const keepMeUpdatedForm = document.querySelector(`form[id='${ID.BLUETECUPDATE_FORM__PERSONAL_INFO}']:not([hidden])`);
        return new Promise((resolve, reject) => {
            if (recaptchaId === ID.PERSONAL_INFO_RECAPTCHA && keepMeUpdatedForm) {
                keepMeUpdatedForm.querySelector("button[type='submit']").removeAttribute(ATTRIBUTE.DISABLED);
                resolve();
            } else if (recaptchaId === ID.RECAPTCHA && vinLookupForm) {
                vinLookupForm.querySelector("button[type='submit']").removeAttribute(ATTRIBUTE.DISABLED);
                resolve();
            } else {
                reject();
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.BLUETECUPDATE_FORM_ERROR}`);
        element.classList.remove(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.BLUETECUPDATE_FORM__ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log('Hide Error : ', this);
        element.classList.remove(`${CLASS.BLUETECUPDATE_FORM_ERROR}`);
        element.classList.add(`${CLASS.BLUETECUPDATE_FORM_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.BLUETECUPDATE_FORM__ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Vin Number Validation
     */
    isValidVinNumber(vinNumber) {
        console.log(this);
        const regex = /^[a-zA-Z0-9]{17}$/;
        return regex.test(vinNumber);
    }

    /**
     * Email Validation
     */
    isValidEmail(email) {
        console.log(this);
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /**
     * Phone Number Validation
     */
    isValidPhoneNumber(phoneNumber) {
        const type = document.querySelector("input[name='phoneNumber']:checked").value;
        let regex;
        console.log(this);
        if (type === 'phone') {
            regex = /^\d{10}$/;
            return regex.test(phoneNumber);
        }
        regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    }

    /**
     * Zip Code Validation
     */
    isValidZipCode(zip) {
        let regex;
        switch (this.content.country) {
        case 'ca':
            regex = /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
            break;

        default:
            regex = /^\d{5}$/;
            break;
        }
        return regex.test(zip);
    }

    /**
     *
     * @param {String} text
     * function checks if the string 'text' contains alphabets only.
     */
    hasAlphabetsOnly(text) {
        console.log(this);
        const regex = /^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        return regex.test(text);
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields(inputFields) {
        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            if (el.tagName.toLowerCase() === 'input' && el.type !== 'radio') {
                this.validateInput(el);
            }
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.BLUETECUPDATE_FORM_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute(ATTRIBUTE.REQUIRED);
        if (!(el.getAttribute(ATTRIBUTE.REQUIRED) && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === CONSTANT.vinNumber) {
            if (el.value) {
                this.validate(el, this.isValidVinNumber(el.value));
            }
        } else if (el.name === CONSTANT.email) {
            if (el.value) {
                this.validate(el, this.isValidEmail(el.value));
            }
        } else if (el.name === CONSTANT.phone) {
            if (el.value) {
                this.validate(el, this.isValidPhoneNumber(el.value));
            }
        } else if (el.name === CONSTANT.zip) {
            if (el.value) {
                this.validate(el, this.isValidZipCode(el.value));
            }
        } else if (el.name === CONSTANT.firstName ||
            el.name === CONSTANT.lastName ||
            el.name === CONSTANT.city || el.name === CONSTANT.state) {
            if (el.value) {
                this.validate(el, this.hasAlphabetsOnly(el.value));
            }
        }
    }

    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
