import React, { Component } from 'react';
import bulkCanUploadApi from '../api/bulkCanUploadApi';
import FILE_TYPE_CONSTANTS from '../constants/FILE_TYPE_CONSTANTS';

class BulkCanUpoadFormComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            uploadedFile: null,
            isFormValid: false,
            msgToShow: '',
            hasError: false,
            showLoader: false
        };
        this.onUploadChangeHandler = this.onUploadChangeHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setState = this.setState.bind(this);
    }

    onUploadChangeHandler(event) {
        event.preventDefault();
        let newUploadedFile = this.state.uploadedFile;
        if (FILE_TYPE_CONSTANTS.includes(event.target.files[0].type)) {
            newUploadedFile = event.target.files[0];
            this.setState({ uploadedFile: newUploadedFile });
            this.setState({ isFormValid: true });
        }
        event.target.value = null; // for old browsers.
    }
    async onSubmit(event) {
        event.preventDefault();

        const extension = this.state.uploadedFile.name.split('.');
        if (
            extension.length > 0 &&
            (extension[extension.length - 1] === 'xlsx' ||
                extension[extension.length - 1] === 'xls')
        ) {
            const changedFile = new File(
                [this.state.uploadedFile],
                `0.${extension[extension.length - 1]}`,
                {
                    type: this.state.uploadedFile.type,
                }
            );
            const formData = new FormData();
            formData.append('file', changedFile);
            const apiUrl = bulkCanUploadApi.getConfig('apiConfig');
            this.setState({
                showLoader: true,
                hasError: false
            })

            const response = await fetch(
                apiUrl,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            if (response.ok) {
                await response.text().then((message) => {
                    this.setState({
                        msgToShow: message,
                        showLoader: false,
                        hasError: false
                    });
                });
            } else {
                this.setState({
                    msgToShow: 'Error. Please Check logs',
                    showLoader: false,
                    hasError: true
                });
            }
        } else {
            this.setState({
                msgToShow: 'File name should have .xlsx or .xls',
                showLoader: false,
                hasError: true
            });
        }

        this.setState({ uploadedFile: null });
        this.setState({ isFormValid: false });
    }

    render() {
        const headerAlignment = bulkCanUploadApi.getConfig('headingAlignment');
        const minWidth = bulkCanUploadApi.getConfig('minWidth');
        return (
            <div className='bulk-can-upload-container'>
                {this.state.showLoader && (<div className='bulk-can-upload__loader-backdrop'>
                    <div class="bulk-can-upload__loader"></div>
                </div>)}
                <div className='bulk-can-upload-header-container' style={{ 'alignItems': headerAlignment }}>
                    <h1>{bulkCanUploadApi.getConfig('title')}</h1>
                    <p>{bulkCanUploadApi.getConfig('description')}</p>
                </div>
                <div className='bulk-can-upload-content-container'
                    style={{ 'width': minWidth + '%' }}>
                    {this.state.msgToShow === '' ? (
                        <form
                            action=''
                            className='bulk-can-upload-content'
                            onSubmit={this.onSubmit}
                            style={{ 'width': minWidth + '%' }}
                        >
                            <div className='bulk-can-upload-upload-container'>
                                <div className='upload-button-box'>
                                    {this.state.uploadedFile == null ? (
                                        <>
                                            <label
                                                htmlFor='fileUpload'
                                                id='bulk-can-file-upload-btn'
                                                title='Upload Can Registration Document (Must be in .xlsx or .xls format)'
                                            >
                                                Upload
                                            </label>
                                            <label>
                                                {bulkCanUploadApi.getConfig('btnDescription')}
                                            </label>
                                        </>
                                    ) : (
                                        <label
                                            htmlFor='fileUpload'
                                            id='bulk-can-file-upload-btn'
                                        >
                                            {this.state.uploadedFile.name}
                                        </label>
                                    )}
                                </div>
                                <input
                                    type='file'
                                    name=''
                                    id='fileUpload'
                                    style={{ display: 'none' }}
                                    onChange={this.onUploadChangeHandler}
                                    accept={FILE_TYPE_CONSTANTS}
                                />
                                <div className='bulk-can-upload-submit-container'>
                                    <div>
                                        <button
                                            type='submit'
                                            disabled={!this.state.isFormValid}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className='bulk-can-upload-content' style={{ 'width': minWidth + '%' }}>
                            <p id='bulk-upload-message' className={!this.state.hasError ? 'success-message' : 'error-message'}>
                                {this.state.msgToShow}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default BulkCanUpoadFormComponent;
