import { EVENTS } from 'Constants';

import AirbagAvailabilityUtil from 'modules/AirbagAvailabilityUtil';

const ID = {
    STATE: 'state',
    MODEL_YEAR: 'year',
    SUBMISSION: 'airbag-btn',
};

export default class AirbagAvailability {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.loadModules();
        this.populateState();
        this.attachEvents();
    }

    loadModules() {
        this.AirbagAvailabilityUtil = new AirbagAvailabilityUtil(this.element);
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.STATE}`)) {
                e.preventDefault();
                this.populateModelYear();
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.MODEL_YEAR}`)) {
                e.preventDefault();
                this.populateModel();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMISSION}`)) {
                e.preventDefault();
                if (this.AirbagAvailabilityUtil.validateAllFields()) {
                    this.displayResult();
                }
            }
        });
    }

    returnRecallState() {
        console.log(this);
        const recallState = [{
            State: 'AL',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'CA',
            Zone: 'A',
            HAH: 'NO',
            'A or Non-A': 'A'
        }, {
            State: 'FL',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'GA',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'HI',
            Zone: 'A',
            HAH: 'NO',
            'A or Non-A': 'A'
        }, {
            State: 'LA',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'MS',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'PR',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'SC',
            Zone: 'A',
            HAH: 'NO',
            'A or Non-A': 'A'
        }, {
            State: 'TX',
            Zone: 'A',
            HAH: 'YES',
            'A or Non-A': 'A'
        }, {
            State: 'AR',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'AZ',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'DC',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'DE',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'IL',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'IN',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'KS',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'KY',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MD',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MO',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NC',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NE',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NJ',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NM',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NV',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'OH',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'OK',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'PA',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'TN',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'VA',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'WV',
            Zone: 'B',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'AK',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'CO',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'CT',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'IA',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'ID',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MA',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'ME',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MI',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MN',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'MT',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'ND',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NH',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'NY',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'OR',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'RI',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'SD',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'UT',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'VT',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'WA',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'WI',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }, {
            State: 'WY',
            Zone: 'C',
            HAH: 'NO',
            'A or Non-A': 'Non-A'
        }];
        return recallState;
    }

    returnRecallModel() {
        console.log(this);
        const recallModel = [{
            MY: 2005,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2005,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2018',
            'Effective Launch Date': 'Q2-2018',
            'Month Effective Launch Date': 'June 2018'
        }, {
            MY: 2006,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2007,
            Model: 'SLK-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2008,
            Model: 'SLK-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2006,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2018',
            'Effective Launch Date': 'Q2-2018',
            'Month Effective Launch Date': 'June 2018'
        }, {
            MY: 2009,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2009,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2009,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2007,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q1-2018',
            'Effective Launch Date': 'Q1-2018',
            'Month Effective Launch Date': 'March 2018'
        }, {
            MY: 2009,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q3-2018',
            'Effective Launch Date': 'Q3-2018',
            'Month Effective Launch Date': 'August 2018'
        }, {
            MY: 2008,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q3-2018',
            'Effective Launch Date': 'Q3-2018',
            'Month Effective Launch Date': 'August 2018'
        }, {
            MY: 2008,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q3-2018',
            'Effective Launch Date': 'Q3-2018',
            'Month Effective Launch Date': 'August 2018'
        }, {
            MY: 2009,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q3-2018',
            'Effective Launch Date': 'Q3-2018',
            'Month Effective Launch Date': 'August 2018'
        }, {
            MY: 2008,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2008,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 8,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2009,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2008,
            Model: 'SLK-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2018',
            'Effective Launch Date': 'Q2-2018',
            'Month Effective Launch Date': 'June 2018'
        }, {
            MY: 2009,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2009,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2009,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2010,
            Model: 'E-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2010,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2010,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2010,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2010,
            Model: 'E-Coupe',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2010,
            Model: 'GLK-Class',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2011,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2011,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2011,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2009,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'October 2018'
        }, {
            MY: 2011,
            Model: 'E-Cabriolet',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2011,
            Model: 'E-Coupe',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2011,
            Model: 'GLK-Class',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2011,
            Model: 'SLS-Coupe',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2012,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2012,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2012,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2011,
            Model: 'E-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2012,
            Model: 'GLK-Class',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2007,
            Model: 'SLK-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2018',
            'Effective Launch Date': 'Q2-2018',
            'Month Effective Launch Date': 'June 2018'
        }, {
            MY: 2010,
            Model: 'E-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'July 2019'
        }, {
            MY: 2010,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2010,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2010,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2010,
            Model: 'C-Class',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q4-2018',
            'Effective Launch Date': 'Q4-2018',
            'Month Effective Launch Date': 'December 2018'
        }, {
            MY: 2010,
            Model: 'E-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2010,
            Model: 'GLK-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'E-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'July 2019'
        }, {
            MY: 2011,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2011,
            Model: 'ML-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2011,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2010,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'E-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'GLK-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'E-Cabriolet',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'GL-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2012,
            Model: 'R-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2012,
            Model: 'GLK-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2012,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2012,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2010,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2020',
            'Effective Launch Date': 'Q1-2020',
            'Month Effective Launch Date': 'February 2020'
        }, {
            MY: 2012,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2011,
            Model: 'C-Class',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'June 2019'
        }, {
            MY: 2011,
            Model: 'C-Class',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2011,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2010,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2010,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 9,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2012,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2020',
            'Effective Launch Date': 'Q1-2020',
            'Month Effective Launch Date': 'January 2020'
        }, {
            MY: 2012,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 7,
            'Expected Launch Date': 'Q1-2020',
            'Effective Launch Date': 'Q1-2020',
            'Month Effective Launch Date': 'February 2020'
        }, {
            MY: 2013,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 9,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'April 2020'
        }, {
            MY: 2013,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 9,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'April 2020'
        }, {
            MY: 2013,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2011,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2012,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2011,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 9,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'April 2020'
        }, {
            MY: 2012,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2013,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 9,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'April 2020'
        }, {
            MY: 2012,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2013,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2013,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2013,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2012,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q3-2018',
            'Effective Launch Date': 'Q3-2018',
            'Month Effective Launch Date': 'September 2018'
        }, {
            MY: 2012,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2013,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2014,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'March 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Coupe',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Roadster',
            Inflator: 'DAB',
            Zone: 'Non-A',
            'Priority Group': 6,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2015,
            Model: 'SLS-Roadster',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q3-2019',
            'Effective Launch Date': 'Q3-2019',
            'Month Effective Launch Date': 'September 2019'
        }, {
            MY: 2008,
            Model: 'C-Class AMG',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'May 2019'
        }, {
            MY: 2008,
            Model: 'C-Class AMG',
            Inflator: 'DAB, PAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'May 2019'
        }, {
            MY: 2009,
            Model: 'C-Class AMG',
            Inflator: 'DAB, PAB',
            Zone: 'A',
            'Priority Group': 4,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'May 2019'
        }, {
            MY: 2009,
            Model: 'C-Class AMG',
            Inflator: 'DAB, PAB',
            Zone: 'Non-A',
            'Priority Group': 5,
            'Expected Launch Date': 'Q2-2019',
            'Effective Launch Date': 'Q2-2019',
            'Month Effective Launch Date': 'May 2019'
        }, {
            MY: 2014,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2014,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'GLK-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'C-Class',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2015,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q1-2019',
            'Effective Launch Date': 'Q1-2019',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2016,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2016,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2016,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2016,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2017,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2017,
            Model: 'E-Coupe',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2017,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }, {
            MY: 2017,
            Model: 'E-Cabriolet',
            Inflator: 'PAB',
            Zone: 'Non-A',
            'Priority Group': 10,
            'Expected Launch Date': 'Q2-2020',
            'Effective Launch Date': 'Q2-2020',
            'Month Effective Launch Date': 'May 2020'
        }];
        return recallModel;
    }

    populateState() {
        console.log(this);
        const ele = document.getElementById('state');
        const recallState = this.returnRecallState();
        recallState.sort((a, b) => {
            if (a.State < b.State) {
                return -1;
            } else if (a.State > b.State) {
                return 1;
            }
            return 0;
        });
        for (let j = 0; j < recallState.length; j += 1) {
            ele.innerHTML += `<option value="${recallState[j].State}">${recallState[j].State}</option>`;
        }
    }

    getUnique(arr) {
        console.log(this);
        return arr.filter((e, i) => arr.indexOf(e) >= i);
    }

    populateModelYear() {
        console.log(this);
        const result = document.getElementById('airbag_result');
        result.innerHTML = '';
        const ye = document.getElementById('year');
        const mo = document.getElementById('model');
        ye.innerHTML = '<option value="">Select Year</option>';
        mo.innerHTML = '<option value="">Select Model</option>';
        const recallState = this.returnRecallState();
        let zoneSelected = '';
        const stateSelected = document.getElementById('state').value;
        for (let i = 0; i < recallState.length; i += 1) {
            if (stateSelected.localeCompare(recallState[i].State) === 0) {
                zoneSelected = recallState[i]['A or Non-A'];
                break;
            }
        }
        const recallModel = this.returnRecallModel();
        recallModel.sort((a, b) => a.MY - b.MY);
        let recallModelYearArray = [];
        if (zoneSelected != null) {
            for (let j = 0; j < recallModel.length; j += 1) {
                if (zoneSelected.localeCompare(recallModel[j].Zone) === 0) {
                    recallModelYearArray.push(recallModel[j].MY);
                }
            }
        }
        recallModelYearArray = this.getUnique(recallModelYearArray);
        for (let k = 0; k < recallModelYearArray.length; k += 1) {
            ye.innerHTML += `<option value="${recallModelYearArray[k]}">${recallModelYearArray[k]}</option>`;
        }
    }

    populateModel() {
        console.log(this);
        const result = document.getElementById('airbag_result');
        result.innerHTML = '';
        const mo = document.getElementById('model');
        mo.innerHTML = '<option value="">Select Model</option>';
        const recallState = this.returnRecallState();
        const recallModel = this.returnRecallModel();
        recallModel.sort((a, b) => {
            if (a.Model < b.Model) {
                return -1;
            } else if (a.Model > b.Model) {
                return 1;
            }
            return 0;
        });
        let zoneSelected = '';
        const stateSelected = document.getElementById('state').value;
        const modelYearSelected = document.getElementById('year').value;
        for (let i = 0; i < recallState.length; i += 1) {
            if (stateSelected.localeCompare(recallState[i].State) === 0) {
                zoneSelected = recallState[i]['A or Non-A'];
                break;
            }
        }
        let recallModelArray = [];
        if (zoneSelected != null) {
            for (let j = 0; j < recallModel.length; j += 1) {
                let myYear = recallModel[j].MY;
                myYear = myYear.toString();
                if (modelYearSelected === myYear && zoneSelected === recallModel[j].Zone) {
                    recallModelArray.push(recallModel[j].Model);
                }
            }
        }
        recallModelArray = this.getUnique(recallModelArray);
        for (let k = 0; k < recallModelArray.length; k += 1) {
            mo.innerHTML += `<option value="${recallModelArray[k]}">${recallModelArray[k]}</option>`;
        }
    }

    getResult() {
        console.log(this);
        const stateSelected = document.getElementById('state').value;
        const moYrSelected = document.getElementById('year').value;
        const moSelected = document.getElementById('model').value;
        const recallState = this.returnRecallState();
        const recallModel = this.returnRecallModel();
        let zSelected = '';
        for (let i = 0; i < recallState.length; i += 1) {
            if (stateSelected.localeCompare(recallState[i].State) === 0) {
                zSelected = recallState[i]['A or Non-A'];
                break;
            }
        }
        const resultArray = [];
        if (zSelected != null) {
            for (let j = 0; j < recallModel.length; j += 1) {
                let myYear = recallModel[j].MY;
                myYear = myYear.toString();
                const model = recallModel[j].Model;
                const zone = recallModel[j].Zone;
                if (moYrSelected === myYear && zSelected === zone && moSelected === model) {
                    let inf = recallModel[j].Inflator;
                    let infP = '';
                    const avilDate = recallModel[j]['Month Effective Launch Date'];
                    if (inf === 'DAB') {
                        inf = "Driver's Side";
                        resultArray.push(`${inf}/${avilDate}`);
                    } else if (inf === 'PAB') {
                        inf = "Passenger's Side";
                        resultArray.push(`${inf}/${avilDate}`);
                    } else if (inf === 'DAB, PAB') {
                        inf = "Driver's Side";
                        infP = "Passenger's Side";
                        resultArray.push(`${inf}/${avilDate}`);
                        resultArray.push(`${infP}/${avilDate}`);
                    }
                }
            }
        }
        resultArray.sort();
        return resultArray;
    }

    displayResult() {
        console.log(this);
        const result = document.getElementById('airbag_result');
        result.innerHTML = '';
        const stateSelected = document.getElementById('state').value;
        const moYrSelected = document.getElementById('year').value;
        const moSelected = document.getElementById('model').value;
        let resultHTML = `<div class="airbag-availability__result_display">
              <p class="airbag-availability__title">Airbag Availability for: ${moYrSelected} ${moSelected}, ${stateSelected}</p>
              <table class="airbag-availability__result_table">
                    <tr>
                        <td class="airbag-availability__result_column">
                            <p class="airbag-availability__result_column_text">Affected Airbag Position</p>
                        </td>
                        <td class="airbag-availability__result_column">
                            <p class="airbag-availability__result_column_text">Estimated Part Availability Date</p>
                        </td>
                    </tr>`;
        const resultArray = this.getResult();
        for (let i = 0; i < resultArray.length; i += 1) {
            const inflatorArray = resultArray[i].split('/');
            resultHTML += `<tr>
                           <td class="airbag-availability__result_array_column">
                               <p class="airbag-availability__table_text">${inflatorArray[0]}</p>
                            </td>
                           <td class="airbag-availability__result_array_column">
                               <p class="airbag-availability__table_text">${inflatorArray[1]}</p>
                           </td>
                        </tr>`;
        }
        resultHTML += `</table>moYrSelected
                      <p>You will be notified when parts are available at an authorized Mercedes-Benz dealership for your vehicle. If parts are available, please use the dealer locator tool below and schedule and appointment for your <u>FREE</u> repair.</p>
                      </div>`;
        result.innerHTML = resultHTML;
    }
}
