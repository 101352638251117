import formValidationRules from "../constants/formValidationRules";
import fields from "../constants/fields";

/**
 * Method will validates each field on change.
 * @param {Object} newForm // new Form data object.
 * @param {Object} form // Old form data state.
 * @param {Function} setForm // callBack function to set old form data state.
 */
const validateEmailUsForm = (newForm, form, setState) => {
    newForm = newForm || { ...form };
    fields.forEach((field) => {
        const newFormField = newForm[field];
        newFormField.errors = [];
        newFormField.isValid = true;
        formValidationRules[field].forEach((vRule) => {
            if (!vRule.rule(form[field].value)) {
                newFormField.errors.push(vRule.message);
                newFormField.isValid = false;
            }
            newForm[field] = newFormField;
        });
    });
    setState({ emailUsForm: newForm });
};

// Method to get the localization object from window object of browser.
function getConfig(property) {
    return window.microsites.pageData.emailFormData.localization[property];
}

// Method to get ReCaptcha site key
function getReCaptchaSiteKey() {
    return window.microsites.applicationProperties.recaptchaSiteKey;
}

// Method to get USA States.
function getListOfStates() {
    return window.microsites.pageData.US_STATES.split(",");
}
/**
 * Method will format and masks the phone number in (xxx) xxx-xxxx format
 * @param {string} value
 * @returns (xxx) xxx-xxxx
 */
function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}
/**
 * Method will send the form-data through api.
 * @param {Array} uploadFiles
 * @param {Object} form
 * @param {String} reCaptchaToken
 */
async function sendFormData(uploadFiles, form, reCaptchaToken, setState) {
    setState({ isRecaptchCheck: false });
    try {
        const changedFiles = uploadFiles.map((file, index) => {
            const extension = file.name.split(".");
            const modifiedFile = new File(
                [file],
                `${index}.${extension[extension.length - 1]}`,
                {
                    type: file.type,
                }
            );
            return modifiedFile;
        });
        const formData = new FormData();

        fields.forEach((field) => {
            formData.append(field, form[field].value);
        });
        formData.append("g-recaptcha-response", reCaptchaToken);
        /* eslint-disable no-plusplus */
        for (let i = 0; i < changedFiles.length; i++) {
            formData.append(`file${i}`, changedFiles[i]);
        }
        const response = await fetch("/bin/microsites/mailUs", {
            method: "POST",
            body: formData,
        });
        if (!response.ok) {
            if (response.status === 415) {
                const responseRecieved = await response.json();
                setState({
                    isFormSent: {
                        statusCode: responseRecieved.status,
                        message: responseRecieved.message,
                    },
                });
                throw new Error(responseRecieved.message);
            } else {
                setState({
                    isFormSent: {
                        statusCode: 500,
                        message: "Server not reachable",
                    },
                });
                throw new Error("Server not rechable");
            }
        } else if (response.status === 200) {
            const responseRecieved = await response.json();
            setState({
                isFormSent: {
                    statusCode: responseRecieved.status,
                    message: responseRecieved.message,
                },
            });
        }
    } catch (e) {
        console.log(e.message);
    }
}
export default {
    validateEmailUsForm,
    getConfig,
    getReCaptchaSiteKey,
    sendFormData,
    formatPhoneNumber,
    getListOfStates,
};
