import { CLASSES } from 'Constants';

export default class Ajax {
    constructor(element) {
        this.element = element;
        this.http = new XMLHttpRequest();
        this.postAjax = new XMLHttpRequest();
        this.jsonPostAjax = new XMLHttpRequest();
        this.multiPartPostAjax = new XMLHttpRequest();
        this.deleteAjax = new XMLHttpRequest();
        this.xhr = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.cacheDOMElement();
    }

    cacheDOMElement() {
        this.accordionOpenElem = this.element.querySelector(`.${CLASSES.ACCORDION_OPEN}`);
    }

    readTextFile(file, callback) {
        this.http = new XMLHttpRequest();

        this.http.overrideMimeType('application/json');
        this.http.open('GET', file, true);
        this.http.onreadystatechange = () => {
            if (this.http.readyState === 4 && this.http.status === 200) {
                callback(this.http.responseText);
            }

            if (this.http.readyState === 4 && this.http.status !== 200) {
                const errorJSON = { error: `http error ${this.http.status.toString()}` };
                callback(JSON.stringify(errorJSON));
            }
        };
        this.http.send(null);
    }

    getFileResponse(url, callback) {
        this.xhr = new XMLHttpRequest();
        this.xhr.open('GET', url, true);
        this.xhr.responseType = 'blob';
        this.xhr.onreadystatechange = () => {
            if (this.xhr.readyState === 4 && this.xhr.status === 200) {
                let filename = '';
                const disposition = this.xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1])filename = matches[1].replace(/['"]/g, '');
                }
                callback(this.xhr.response, filename);
            }

            if (this.xhr.readyState === 4 && this.xhr.status !== 200) {
                const errorJSON = { error: `http error ${this.xhr.status.toString()}` };
                callback(errorJSON);
            }
        };
        this.xhr.send();
    }

    ajaxPostFn(url, data, success) {
        const params = typeof data === 'string' ? data : Object.keys(data).map(
                k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`
            ).join('&');
        this.postAjax.open('POST', url, true);
        this.postAjax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        this.postAjax.onreadystatechange = () => {
            if (this.postAjax.readyState === 4 && this.postAjax.status === 200) {
                success(this.postAjax.responseText);
            }

            if (this.postAjax.readyState === 4 && this.postAjax.status !== 200) {
                const errorJSON = { error: `http error ${this.http.status.toString()}` };
                success(JSON.stringify(errorJSON));
            }
        };
        this.postAjax.send(params);
    }

    ajaxJsonPostFn(url, data, success) {
        const params = JSON.stringify(data);
        this.jsonPostAjax.open('POST', url, true);
        this.jsonPostAjax.setRequestHeader('Content-type', 'application/json');
        this.jsonPostAjax.onreadystatechange = () => {
            if (this.jsonPostAjax.readyState === 4 && this.jsonPostAjax.status === 200) {
                success(this.jsonPostAjax.responseText);
            }

            if (this.jsonPostAjax.readyState === 4 && this.jsonPostAjax.status !== 200) {
                const errorJSON = { error: `http error ${this.http.status.toString()}` };
                success(JSON.stringify(errorJSON));
            }
        };
        this.jsonPostAjax.send(params);
    }

    ajaxMultipartPostFn(url, data, success) {
        this.multiPartPostAjax.open('POST', url, true);

        this.multiPartPostAjax.onreadystatechange = () => {
            if (this.multiPartPostAjax.readyState === 4 && this.multiPartPostAjax.status === 200) {
                success(this.multiPartPostAjax.responseText);
            }

            if (this.multiPartPostAjax.readyState === 4 && this.multiPartPostAjax.status !== 200) {
                const errorJSON = { error: `http error ${this.http.status.toString()}` };
                success(JSON.stringify(errorJSON));
            }
        };
        this.multiPartPostAjax.send(data);
    }

    ajaxDeleteFn(url, data, success) {
        const params = JSON.stringify(data);
        this.deleteAjax.open('DELETE', url, true);
        this.deleteAjax.onload = () => {
            if (this.deleteAjax.readyState === 4 && this.deleteAjax.status === 200) {
                success(this.deleteAjax.responseText);
            }

            if (this.deleteAjax.readyState === 4 && this.deleteAjax.status !== 200) {
                const errorJSON = { error: `http error ${this.http.status.toString()}` };
                success(JSON.stringify(errorJSON));
            }
        };
        this.deleteAjax.send(params);
    }
}
