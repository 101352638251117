import { EVENTS } from 'Constants';

const CLASSES = {
    FEP_EAB_FILTER: 'fep-eab-filter',
    HIDDEN: 'hidden',
    FORM_MESSAGE: 'form__message',
    FORM_MESSAGE_CONTAINER: 'form__message-container',
    FORM_MESSAGE_ERROR: 'form__message--error',
    FORM_MESSAGE_SUCCESS: 'form__message--success',
    CROSS_ICON: 'icon-cross'
};

const StringConstants = {
    ONE_SEARCH_FIELD_IS_REQUIRED: 'Please fill in at least one search field',
    BLANK: '',
    SUCCESS: 'success',
    ERROR: 'error'
};

export default class MessageBox {
    constructor(element, messageContainer) {
        this.element = element;
        this.messageContainer = messageContainer || null;
        this.messageCloseIcon = null;
        this.init();
    }

     /*
        Function :  This is called when the js loads.
    */
    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    /**
     * Attaching event listners to DOM elements.
     */
    attachEvents() {
        this.messageCloseIcon.addEventListener(EVENTS.CLICK, () => {
            this.getMessageContainer().classList.add(`${CLASSES.HIDDEN}`);
        });
    }

    /**
     * Caching DOM Elements to be used for opertaion in functions.
     */
    cacheDOMElement() {
        this.messageCloseIcon = document.querySelector(`.${CLASSES.CROSS_ICON}`);
    }

    /**
     * Function : To display message along with the display
     *              according to the message type.
     *
     * @params
     * message - error message to be displayed.(default value passed)
     */
    showMessage(type, message = `${StringConstants.ERROR_OCCURED}`) {
        const messageContainer = this.getMessageContainer();
        if (messageContainer != null) {
            messageContainer.querySelector(`.${CLASSES.FORM_MESSAGE}`).innerHTML = message;
            messageContainer.classList.remove(`${CLASSES.HIDDEN}`);
        }

        if (type === `${StringConstants.SUCCESS}`) {
            messageContainer.classList.remove(`${CLASSES.FORM_MESSAGE_ERROR}`);
            messageContainer.classList.add(`${CLASSES.FORM_MESSAGE_SUCCESS}`);
        } else if (type === `${StringConstants.ERROR}`) {
            messageContainer.classList.remove(`${CLASSES.FORM_MESSAGE_SUCCESS}`);
            messageContainer.classList.add(`${CLASSES.FORM_MESSAGE_ERROR}`);
        }
    }

    /*
        Function : To get message container if not passed in constructor.

        @return : message container node.
    */
    getMessageContainer() {
        if (this.messageContainer) {
            return this.messageContainer;
        }
        return document.querySelector(`.${CLASSES.FORM_MESSAGE_CONTAINER}`);
    }

    /**
     * Function : To hide message when records found.
     */
    hideMessage() {
        const messageContainer = this.getMessageContainer();
        if (messageContainer != null) {
            messageContainer.classList.add(`${CLASSES.HIDDEN}`);
        }
    }
}
