import Ajax from 'modules/Ajax';

const CLASSES = {
    DROPDOWN_CONTAINER: 'thumbnail-carousel__DD-container',
    ARTICLE_DATA_CONTAINER: 'article-data',
    ARTICLE_BLOCK_CONTAINER: 'thumbnail-carousel__article-block-container',
    THUMBNAIL_CAROUSEL_LINK: 'thumbnail-carousel__link'
};

export default class StarTunedArticles {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.getYearAPI();
    }

    cacheDOMElement() {
        this.dropDownContainer = document.querySelector(`.${CLASSES.DROPDOWN_CONTAINER}`);
        this.articleDataContainer = document.querySelector(`.${CLASSES.ARTICLE_DATA_CONTAINER}`);
        this.articleBlockContainer = document.querySelector(`.${CLASSES.ARTICLE_BLOCK_CONTAINER}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    getYearAPI() {
        const parameter = document.getElementById('site-name').value;
        const url = `/bin/microsites/get-starTuned-years?siteName=${parameter}`;

        this.Ajax.readTextFile(url, (text) => {
            const data = JSON.parse(text);
            const keys = Object.keys(data);
            let optionList = '';

            [].forEach.call(keys, (k) => {
                optionList += `<option value="${data[k]}">${data[k]}</option>`;
            });

            this.dropDownContainer.innerHTML = optionList;
            this.getArticleData(data[0]);
        });
    }

    getArticleData(selectedValue) {
        const siteName = document.getElementById('site-name').value;
        const url = `/bin/microsites/get-starTuned-articles-data?siteName=${siteName}&year=${selectedValue}`;

        this.Ajax.readTextFile(url, (text) => {
            const data = JSON.parse(text);
            this.createCarousel(data);
        });
    }

    createCarousel(data) {
        let html = '';
        let articleHtml = '';

        // item loop
        [].forEach.call(data, (el, index) => {
            let desc = '';
            let article = '';

            if (el.pdfLinks) {
                [].forEach.call(el.pdfLinks, (elem) => {
                    const pdfData = JSON.parse(elem);

                    const linkTitle = pdfData.linkText;
                    desc += `<li class="thumbnail-carousel__list">${linkTitle}</li>`;
                    article += `<li class="thumbnail-carousel__article-list">
                                    <div class="thumbnail-carousel__article-link-block">
                                        <span class="icon-pdf"></span>
                                        <a target="_blank" rel="noopener noreferrer" href="${pdfData.linkTarget}" class="thumbnail-carousel__article-link">${linkTitle}</a>
                                    </div>
                                    <div class="thumbnail-carousel__article-description">${pdfData.linkDescription}</div>
                                </li>`;
                });
            }

            html += `<div class="item thumbnail-carousel__item-border">
                        <div class="thumbnail-carousel__link" data-index="${index}">
                            <div class="thumbnail-carousel__image-container">
                                <img src="${el.imagePath}" alt="${el.imageAlt}" class="responsive-image" />
                            </div>
                            <div class="thumbnail-carousel__copy-container">
                                <h1 class="thumbnail-carousel__title">${el.month} ${el.year}</h1>
                                <div class="thumbnail-carousel__description">
                                    <ul class="thumbnail-carousel__list-container">${desc}</ul>
                                </div>
                            </div>
                        </div>
                    </div>`;

            articleHtml += `<ul class="thumbnail-carousel__article-block">${article}</ul>`;
        });

        articleHtml += '<span class="icon-exit"></span>';

        this.articleDataContainer.innerHTML = html;
        this.articleBlockContainer.innerHTML = articleHtml;

        this.articleDataContainer.setAttribute('data-load', '');
    }
}
