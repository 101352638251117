/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

export default (id = '', listBoxId = '', { searchInputLabel, disableReset }) => htmlNode`
    <div class="location-search">
        <div class="location-search__form">
            <div class="form__input form__input--inline" 
                 data-search-input-container=""
                 role="combobox"
                 aria-expanded="false"
                 aria-haspopup="listbox"
                 aria-owns="${listBoxId}">
                <input class="form__input-field ${!disableReset ? 'form__input-field--can-reset' : ''}"
                    data-search-input=""
                    data-analytics-trigger="locator-search"
                    id="${id}"
                    required
                    data-required="true"
                    aria-required="true"
                    aria-controls="${listBoxId}"
                    autoComplete="off" />
                <label class="form__input-label" for="${id}">
                    ${searchInputLabel}
                </label>
                <button type="button" tabindex="-1" class="form__input-reset" data-clear-button="" aria-label="Delete">
                    <span class="offscreen">Delete</span>
                </button>
                <span class="form__input-error" data-error-message></span>
            </div>
        </div>
        <div tabindex="0" class="location-search__results" data-search-locations="">
        </div>
    </div>
`;

