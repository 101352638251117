const CLASSES = {
    BUTTONLINKLIST__ITEM: 'button-link-list__item',
    BUTTONLINKLIST__ITEM__ANCHOR: 'button-link-list__item__anchor',
    BUTTONLINKLIST__ACTIVE: 'button-link-list__active'
};

export default class ButtonLinkList {
    constructor(element) {
        this.element = element;
        this.init();
        this.buttonLinkListItems = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }
    cacheDOMElement() {
        this.buttonLinkListItems = document.getElementsByClassName(`${CLASSES.BUTTONLINKLIST__ITEM}`);
        this.anchorListItems = document.getElementsByClassName(`${CLASSES.BUTTONLINKLIST__ITEM__ANCHOR}`);
    }

    attachEvents() {
        const buttonList = this.buttonLinkListItems;
        const lastNameUrl = location.href.split('/').pop().trim().toLowerCase();
        const categoryNameUrl = lastNameUrl.split('.')[0];

        [].forEach.call(buttonList, (el) => {
            const categoryNameButton = el.firstElementChild.innerHTML.trim().toLowerCase();

            el.classList.remove(`${CLASSES.BUTTONLINKLIST__ACTIVE}`);

            if (categoryNameUrl === categoryNameButton) {
                console.log('added active class');
                el.classList.add(`${CLASSES.BUTTONLINKLIST__ACTIVE}`);
            }
        });
    }
}
