import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    PARTS_PRO_LOCATOR__DROP_DOWN: 'parts-pro-locator__drop-down',
    EAI_STATES: 'eai_states',
    PARTS_PRO_LOCATOR__DEALER_LIST_ITEM: 'parts-pro-locator__dealer__list-item',
    PARTS_PRO_LOCATOR_DEALER_LINK_MAP: 'parts-pro-locator__dealer__link--map',
    PARTS_PRO_LOCATOR_DEALER_ADDRESS: 'parts-pro-locator__dealer__address',
    PARTS_PRO_LOCATOR_DEALER_CITY_STATE: 'parts-pro-locator__dealer__city-state'
};
export default class PartsProLocator {
    constructor(element) {
        this.element = element;
        this.init();
        this.dropDownElement = null;
        this.dealerListContainer = null;
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        this.getAllStates();

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.classList.contains(`${CLASSES.PARTS_PRO_LOCATOR__DROP_DOWN}`)) {
                const selectedValue = e.target.value;

                this.getDealerList(selectedValue, e.target);
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.PARTS_PRO_LOCATOR_DEALER_LINK_MAP}`)) {
                this.locateOnMap(e.target);
            }
        });
    }

    locateOnMap(element) {
        console.log(this);
        const detailsContainer = element.parentElement.previousElementSibling;
        const dealerAddress = detailsContainer.querySelector(`.${CLASSES.PARTS_PRO_LOCATOR_DEALER_ADDRESS}`);
        const dealerCityState = detailsContainer.querySelector(`.${CLASSES.PARTS_PRO_LOCATOR_DEALER_CITY_STATE}`);
        const urlAddress = `${dealerAddress.innerHTML} ${dealerCityState.innerHTML}`;
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURI(urlAddress)}`;
        window.open(url, '_blank');
    }

    getAllStates() {
        const url = '/bin/microsites/states';
        this.Ajax.readTextFile(url, (callback) => {
            if (callback.length) {
                this.populateDropDown(callback);
            }
        });
    }

    populateDropDown(stateList) {
        this.dropDownElement = document.querySelectorAll(`.${CLASSES.EAI_STATES}`);
        [].forEach.call(this.dropDownElement, (el) => {
            const stateListJson = JSON.parse(stateList);
            if (el.childElementCount <= 1) {
                [].forEach.call(stateListJson, (element) => {
                    const optionElement = document.createElement('option');
                    optionElement.setAttribute('value', element.code);
                    optionElement.innerHTML = element.name;
                    el.appendChild(optionElement);
                });
            }
        });
    }

    getDealerList(selectedValue, targetElement) {
        const url = `/bin/microsites/parts-pro-locator/dealers?code=${selectedValue}`;
        this.Ajax.readTextFile(url, (callback) => {
            if (callback.length) {
                this.displayDealerList(JSON.parse(callback), targetElement);
            }
        });
    }

    displayDealerList(dealerListJson, targetElement) {
        this.dealerListContainer = targetElement.parentElement.nextElementSibling;
        while (this.dealerListContainer.hasChildNodes()) {
            this.dealerListContainer.removeChild(this.dealerListContainer.lastChild);
        }
        if (dealerListJson.length) {
            [].forEach.call(dealerListJson, (element) => {
                const listItem = document.createElement('div');
                listItem.setAttribute('class', `${CLASSES.PARTS_PRO_LOCATOR__DEALER_LIST_ITEM}`);
                const dealerDetails = `
                    <ul class="parts-pro-locator__dealer__details">
                        <li class="parts-pro-locator__dealer__name">${element.franchises[0].franchise[0].dealerInfo.dealerName}</li>
                        <li class="parts-pro-locator__dealer__address">${element.franchises[0].franchise[0].dealerInfo.addressDetail[0].addressLine1}</li>
                        <li class="parts-pro-locator__dealer__address">${element.franchises[0].franchise[0].dealerInfo.addressDetail[0].addressLine2}</li>
                        <li class="parts-pro-locator__dealer__city-state">${element.franchises[0].franchise[0].dealerInfo.addressDetail[0].city}, ${element.franchises[0].franchise[0].dealerInfo.addressDetail[0].state} ${element.franchises[0].franchise[0].dealerInfo.addressDetail[0].zipcode}</li>
                        <li class="parts-pro-locator__dealer__address">${element.franchises[0].franchise[0].dealerInfo.contactInfo[0].value}</li>
                    </ul>
                    <div class="parts-pro-locator__dealer__link__container">
                        <a href="http://${element.franchises[0].franchise[0].dealerInfo.websiteUrl}" class="parts-pro-locator__dealer__link" target="_blank" rel="external noopener noreferrer">Website</a>
                        <a href="#" class="parts-pro-locator__dealer__link parts-pro-locator__dealer__link--map">Map It</a>
                    </div>
                </div>`;
                listItem.innerHTML = dealerDetails;
                this.dealerListContainer.appendChild(listItem);
            });
        } else if (targetElement.value === 'Select a State') {
            this.dealerListContainer.innerHTML = '';
        } else {
            this.dealerListContainer.innerHTML = 'THERE ARE NO PARTSPRO DEALERS IN THE SELECTED STATE.';
        }
    }
}
