import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const CLASSES = {
    TEXT_INPUT: 'subscribe-form__form-text',
    EMAIL_INPUT: 'subscribe-form__form-email',
    RADIO_INPUT: 'subscribe-form__form-radio',
    PHONE_NUMBER_INPUT: 'subscribe-form__form-phone-number',
    ZIP_CODE_INPUT: 'subscribe-form__form-zip-code',
    RADIO_OPTIONS: 'subscribe-form__form-options',
    SLIDER: 'subscribe-form__form-slider',
    ERROR: 'subscribe-form__form-error-field',
    HIDDEN: 'd-none',
    SUBSCRIBE_FORM: 'subscription-form',
    SUBMIT_BUTTON: 'subscribe-form__btn-submit',
    SELECT_INPUT: 'subscribe-form__form-control-select'
};

const ID = {
    SUBSCRIBE_FORM: 'subscription-form',
    SITE_NAME: 'site-name',
    PRICE_SLIDER: 'price',
    FIT_SLIDER: 'fit',
    QUALITY_SLIDER: 'quality',
    DELIVERY_SLIDER: 'delivery',
    WARRANTY_SLIDER: 'warranty',
    TECH_SUPPORT_SLIDER: 'tech-support',

};

const textInput = document.getElementsByClassName(`${CLASSES.TEXT_INPUT}`);
const selectInput = document.getElementsByClassName(`${CLASSES.SELECT_INPUT}`);
const emailInput = document.getElementsByClassName(`${CLASSES.EMAIL_INPUT}`);
const radioInput = document.getElementsByClassName(`${CLASSES.RADIO_INPUT}`);
const phoneNumberInput = document.getElementsByClassName(`${CLASSES.PHONE_NUMBER_INPUT}`);
const zipCodeInput = document.getElementsByClassName(`${CLASSES.ZIP_CODE_INPUT}`);
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneNumberRegex = /^\d{10}$/;
const zipCodeRegex = /^\d{5}$/;

export default class SubscribeForm {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'mbWholesaleParts';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (e) => {
            if (e.target.matches(`.${CLASSES.TEXT_INPUT}`)) {
                this.validateInput(e.target);
            } else if (e.target.matches(`.${CLASSES.SELECT_INPUT}`)) {
                this.validateInput(e.target);
            } else if (e.target.matches(`.${CLASSES.EMAIL_INPUT}`)) {
                this.validateEmail(e.target);
            } else if (e.target.matches(`.${CLASSES.RADIO_INPUT}`)) {
                this.validateRadio(e.target);
            } else if (e.target.matches(`.${CLASSES.PHONE_NUMBER_INPUT}`)) {
                this.validatePhoneNumber(e.target);
            } else if (e.target.matches(`.${CLASSES.ZIP_CODE_INPUT}`)) {
                this.validateZipCode(e.target);
            } else if (e.target.matches(`.${CLASSES.SLIDER}`)) {
                e.target.setAttribute('value', e.target.value);
            }
        });


        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.SUBMIT_BUTTON}`)) {
                e.preventDefault();

                [].forEach.call(textInput, (input) => {
                    this.validateInput(input);
                });
                [].forEach.call(selectInput, (input) => {
                    this.validateInput(input);
                });
                [].forEach.call(emailInput, (input) => {
                    this.validateEmail(input);
                });
                [].forEach.call(radioInput, (input) => {
                    this.validateRadio(input);
                });
                [].forEach.call(phoneNumberInput, (input) => {
                    this.validatePhoneNumber(input);
                });
                [].forEach.call(zipCodeInput, (input) => {
                    this.validateZipCode(input);
                });
                if (document.querySelector(`.${CLASSES.ERROR}:not(.${CLASSES.HIDDEN})`)) {
                    return;
                }

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.ContactUsUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    this.sendData();
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="subscribe-form__form-error-field">This field is required.</span>';
                }
            }
        });
    }

    validateInput(element) {
        console.log('validateInput: ', this);
        if (element.nextElementSibling && element.nextElementSibling.classList.contains(`${CLASSES.ERROR}`)) {
            if (element.value === '') {
                element.nextElementSibling.classList.remove(`${CLASSES.HIDDEN}`);
                return;
            }
            element.nextElementSibling.classList.add(`${CLASSES.HIDDEN}`);
        } else if (element.parentElement && element.parentElement.nextElementSibling && element.parentElement.nextElementSibling.classList.contains(`${CLASSES.ERROR}`)) {
            if (element.value === '') {
                element.parentElement.nextElementSibling.classList.remove(`${CLASSES.HIDDEN}`);
                return;
            }
            element.parentElement.nextElementSibling.classList.add(`${CLASSES.HIDDEN}`);
        }
    }

    validateEmail(element) {
        console.log('validateEmail: ', this);
        if (element.nextElementSibling) {
            if (element.value === '' || !emailRegex.test(element.value)) {
                element.nextElementSibling.classList.remove(`${CLASSES.HIDDEN}`);
                return;
            }
            element.nextElementSibling.classList.add(`${CLASSES.HIDDEN}`);
        }
    }

    validatePhoneNumber(element) {
        console.log('validatePhoneNumber: ', this);
        if (element.nextElementSibling) {
            if (element.value === '' || !phoneNumberRegex.test(element.value)) {
                element.nextElementSibling.classList.remove(`${CLASSES.HIDDEN}`);
                return;
            }
            element.nextElementSibling.classList.add(`${CLASSES.HIDDEN}`);
        }
    }

    validateZipCode(element) {
        console.log('validateZipCode: ', this);
        if (element.nextElementSibling) {
            if (element.value === '' || !zipCodeRegex.test(element.value)) {
                element.nextElementSibling.classList.remove(`${CLASSES.HIDDEN}`);
                return;
            }
            element.nextElementSibling.classList.add(`${CLASSES.HIDDEN}`);
        }
    }

    validateRadio(element) {
        console.log('validateRadio: ', this);
        if (element.closest(`.${CLASSES.RADIO_OPTIONS}`).getElementsByTagName('span')[0]) {
            if (document.querySelector(`input[name="${element.name}"]:checked`)) {
                element.closest(`.${CLASSES.RADIO_OPTIONS}`).getElementsByTagName('span')[0].classList.add(`${CLASSES.HIDDEN}`);
                return;
            }
            element.closest(`.${CLASSES.RADIO_OPTIONS}`).getElementsByTagName('span')[0].classList.remove(`${CLASSES.HIDDEN}`);
        }
    }

    sendData() {
        const form = document.getElementById(`${ID.SUBSCRIBE_FORM}`);
        const inputs = form.elements;

        const formToJSONObject = elements => [].reduce.call(elements,
            (data, element) => {
                const selectedElement = [].slice.call(document.querySelectorAll(`[name="${element.name}"]`)).filter(e => e.checked);

                if (selectedElement.length > 0) {
                    const radioValue = [];
                    [].forEach.call(selectedElement, (selected) => {
                        radioValue.push(selected.value);
                    });
                    data[element.name] = radioValue;
                    return data;
                }
                data[element.name] = element.value;
                return data;
            }, {});

        const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        const url = '/bin/microsites/wholesaleparts/subscribe/sendEmail';

        const data = {
            formData: JSON.stringify(formToJSONObject(inputs)),
            siteName,
        };
        grecaptcha.reset();
        this.Ajax.ajaxPostFn(url, data, (success) => {
            if (success.includes('Please verify the captcha response')) {
                this.ContactUsUtil.displayPopUp('Please verify the captcha response');
            } else if (success === 'true') {
                this.ContactUsUtil.displayPopUp('Form submission successful');
                form.reset();

                document.getElementById(`${ID.PRICE_SLIDER}`).value = 3;
                document.getElementById(`${ID.FIT_SLIDER}`).value = 3;
                document.getElementById(`${ID.QUALITY_SLIDER}`).value = 3;
                document.getElementById(`${ID.DELIVERY_SLIDER}`).value = 3;
                document.getElementById(`${ID.WARRANTY_SLIDER}`).value = 3;
                document.getElementById(`${ID.TECH_SUPPORT_SLIDER}`).value = 3;

                [].forEach.call(inputs, (element) => {
                    element.classList.add(`${CLASSES.HIDDEN}`);
                });
            } else {
                this.ContactUsUtil.displayPopUp('Form submission failed');
            }
        });
    }
}
