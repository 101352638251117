// Utility dependencies
import {
    storage
} from 'utils';

/**
 * @property instance
 * @description Stores a singleton instance of the UserProfile
 * @type {null|UserProfile}
 */
let instance = null;

/**
 * @property initialProfile
 * @description Stub for the default data of a user profile
 * @type {{preferredDealer: null}}
 */
const initialProfile = {
    preferredDealer: null,
    lastLocation: {}
};

/**
 * @class UserProfile
 * @description Interface for managing the state and data of user profile
 *
 * TODO: update with real data and methods when available
 */
export default class UserProfile {
    /**
     * @constructor
     * @description Creates a singleton instance of a UserProfile
     * @return {null|UserProfile}
     */
    constructor(brand) {
        if (instance) {
            return instance;
        }

        instance = this;
        this.STORAGE_KEYS = {};
        if (brand === 'SP') {
            this.STORAGE_KEYS.USER_PROFILE = 'SP_USER_PROFILE';
        } else if (brand === 'FL') {
            this.STORAGE_KEYS.USER_PROFILE = 'FL_USER_PROFILE';
        } else {
            this.STORAGE_KEYS.USER_PROFILE = 'MB_USER_PROFILE';
        }
        this.data = null;
        this.create();
    }

    /**
     * @method create
     * @description Creates and saves a user profile object to local storage
     */
    create() {
        const now = new Date();
        this.data = storage.localStorage.read(this.STORAGE_KEYS.USER_PROFILE);

        if (!this.data) {
            this.data = initialProfile;

            storage.localStorage.create(
                this.STORAGE_KEYS.USER_PROFILE,
                this.data,
                new Date(now.setDate(now.getDate() + 60))
            );
        }
    }

    /**
     * @method
     * @description Normalizes previously saved preferred dealer objects
     * to conform to code changes prior to the objects being saved.
     * Note: this is needed to ensure that preferred dealers have
     * 'contact', 'isDealership', 'marketCode', 'regionCode'
     * This was an update made in R18.0.1 (Aug 2019) and should be revisited post release to
     * track when this interim fix can be removed
     * @param preferredDealer {Object} Dealer object
     * @return {*}
     */
    normalizePreferredDealer(preferredDealer) {
        const dealerProperties = ['contact', 'isDealership', 'marketCode', 'regionCode'];

        if (dealerProperties.map(Object.hasOwnProperty.bind(preferredDealer))
            .some(value => !value)) {
            console.log('in normalize');
            this.setPreferredDealer(null);
            return null;
        }
        console.log('out normalize');
        return preferredDealer;
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer of the user to the profile
     * @param dealer {Object} Dealer to set as preferred dealer
     */
    setPreferredDealer(dealer) {
        this.data = {
            ...this.data,
            preferredDealer: dealer
        };

        storage.localStorage.update(
            this.STORAGE_KEYS.USER_PROFILE,
            this.data
        );
    }

    /**
     * @method getPreferredDealer
     * @description Retrieves the preferred dealer from the user profile
     * @return {Object}
     */
    getPreferredDealer() {
        return this.data.preferredDealer;
    }

    /**
     * @method setLastLocation
     * @description Sets the last location the user searched to the user profile
     * @param lastLocation {String} to set as last location searched
     */
    setLastLocation(lastLocation) {
        this.data = {
            ...this.data,
            lastLocation
        };

        storage.localStorage.update(
            this.STORAGE_KEYS.USER_PROFILE,
            this.data
        );
    }

    /**
     * @method getLastLocation
     * @description Retieves the last location searched from the user profile
     * @return {String}
     */
    getLastLocation() {
        return this.data.lastLocation || initialProfile.lastLocation;
    }

    /**
     * @method get
     * @description Retrieves the user profile data
     * @return {Object} User profile data object
     */
    get() {
        return this.data;
    }
}

