import React, { Component } from "react";

class TextArea extends Component {
    render() {
        return (
            <div className="email-us-form-area-container email-us-form-util-container">
                <label
                    htmlFor={this.props.name}
                    className={this.props.handlers.getErrorClassForLabels(
                        this.props.name
                    )}
                >{`${this.props.handlers.getConfig(this.props.name)}${
                    this.props.required ? "*" : ""
                }`}</label>
                <textarea
                    name={this.props.name}
                    id={this.props.id}
                    onFocus={this.props.handlers.onFocusHandler}
                    onChange={this.props.handlers.onFieldChangeHandler}
                    onBlur={this.props.handlers.onTouchedHandler}
                    value={this.props.value}
                    className={this.props.handlers.getErrorClassForAreas(
                        this.props.name
                    )}
                    cols="30"
                    rows="50"
                ></textarea>
                {!this.props.isValid && this.props.isTouched ? (
                    <div className="error-label">{this.props.errorContent}</div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

// function TextArea(props) {
//     return (
//         <div className="email-us-form-area-container email-us-form-util-container">
//             <label
//                 htmlFor={props.name}
//                 className={props.handlers.getErrorClassForLabels(props.name)}
//             >{`${props.handlers.getConfig(props.name)}${
//                 props.required ? "*" : ""
//             }`}</label>
//             <textarea
//                 name={props.name}
//                 id={props.id}
//                 onFocus={props.handlers.onFocusHandler}
//                 onChange={props.handlers.onFieldChangeHandler}
//                 onBlur={props.handlers.onTouchedHandler}
//                 value={props.value}
//                 className={props.handlers.getErrorClassForAreas(props.name)}
//                 cols="30"
//                 rows="50"
//             ></textarea>
//             {!props.isValid && props.isTouched ? (
//                 <div className="error-label">{props.errorContent}</div>
//             ) : (
//                 ""
//             )}
//         </div>
//     );
// }
export default TextArea;
