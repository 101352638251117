import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import Table from 'modules/Table';
import ContactUsUtil from 'modules/ContactUsUtil';
import MessageBox from 'modules/fleet-info/MessageBox';

const CLASSES = {
    FEP_EAB_FILTER: 'fep-eab-filter',
    FORM_MESSAGE_CONTAINER: 'form__message-container',
    OVERLAY_HIDDEN: 'overlay--hidden',
    PRINT: 'print',
    HIDDEN: 'hidden'
};

const ID = {
    SEARCH_FILTER: 'searchFilter',
    RESET_SEARCH_FILTER: 'resetSearchFilter',
    PAGE_LOADER: 'page-loader',
    TABLE_CONTAINER: 'table-container'
};

const StringConstants = {
    ONE_SEARCH_FIELD_IS_REQUIRED: 'Please fill in at least one search field',
    BLANK: '',
    ERROR: 'error',
    PRINT: 'Print',
    NO_RECORD_FOUND: 'No Record Found',
    FORM_TYPE: 'fep-eab-filter'
};

const API = {
    FEB_EAB_FILTER: '/bin/microsites/fleet-info/feb-and-eab-filter',
    FLEET_INCENTIVE_PDF: '/bin/microsites/fleet-info/print-incentive-pdf'
};

export default class FepEabFilter {
    constructor(element) {
        this.element = element;
        this.form = null;
        this.messageContainer = null;
        this.resetButton = null;
        this.searchButton = null;
        this.pageLoader = null;
        this.tableSection = null;
        this.init();
    }

    /*
        Function :  This is called when the js loads.
    */
    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
    }

    /**
     * Caching DOM Elements to be used for opertaion in functions.
     */
    cacheDOMElement() {
        this.form = document.querySelector(`.${CLASSES.FEP_EAB_FILTER}`);
        this.resetButton = this.form.querySelector(`#${ID.RESET_SEARCH_FILTER}`);
        this.searchButton = this.form.querySelector(`#${ID.SEARCH_FILTER}`);
        this.inputFields = this.form.querySelectorAll('input, select');
        this.pageLoader = document.querySelector(`#${ID.PAGE_LOADER}`);
        this.messageContainer = document.querySelector(`.${CLASSES.FORM_MESSAGE_CONTAINER}`);
        this.tableContainer = document.querySelector(`#${ID.TABLE_CONTAINER}`);
    }

    /*
        Function : To load other js files within this file for functions
                    and method usage.
    */
    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
        this.ContactUsUtil = new ContactUsUtil(this.element);
    }

    /**
     * Attaching event listners to DOM elements.
     */
    attachEvents() {
        this.resetButton.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            this.clearAllFields();
        });

        this.searchButton.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
            if (this.hasOneNonEmptyField()) {
                this.getSearchResponse();
            } else {
                this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                this.MessageBox.showMessage(StringConstants.ERROR,
                    StringConstants.ONE_SEARCH_FIELD_IS_REQUIRED);
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.PRINT}`)) {
                const controlNumber = e.target.parentElement.querySelector('td.controlNumber').innerHTML;
                const url = `${window.location.origin + API.FLEET_INCENTIVE_PDF}?controlNumber=${controlNumber}`;
                window.open(url, '_blank');
            }
        });
    }

    /*
        Function : To clear all the field values and set border to default.
    */
    clearAllFields() {
        [].forEach.call(this.inputFields, (field) => {
            field.value = StringConstants.BLANK;
        });
    }

    /**
     * Function : To check at least one field is non-empty.
     *
     * @param : fields - All fields of the form.
     * @return : boolean - true/false
     */
    hasOneNonEmptyField() {
        for (let i = 0; i < this.inputFields.length; i += 1) {
            if (this.ContactUsUtil.containData(this.inputFields[i].value)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Function : get response according to the input in fields.
     */
    getSearchResponse() {
        const formData = this.getFormData();

        this.Ajax.ajaxMultipartPostFn(API.FEB_EAB_FILTER, formData, (success) => {
            const data = JSON.parse(success);
            if (!data.error) {
                if (data.length > 0) {
                    this.MessageBox.hideMessage();
                    data.map((el) => {
                        el.print = StringConstants.PRINT;
                        return el;
                    });
                    this.Table = new Table(this.element, data, StringConstants.FORM_TYPE);
                } else {
                    this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                    this.MessageBox.showMessage(StringConstants.ERROR,
                        StringConstants.NO_RECORD_FOUND);
                }
            } else if (data.error) {
                this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                this.MessageBox.showMessage(StringConstants.ERROR, data.error);
            } else {
                this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                this.MessageBox.showMessage(StringConstants.ERROR);
            }

            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
        });
    }

    /*
        Function : To get values for all input fields.
        @return : FormData Object having all the input fields value.
    */
    getFormData() {
        const formData = new FormData();
        [].forEach.call(this.inputFields, (field) => {
            formData.append(field.id, field.value);
        });
        return formData;
    }
}
