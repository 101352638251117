// Platform Dependencies
import { EVENTS } from 'Constants';
import DealerMap from 'partials/dealer/views/DealerMap';

// Local dependencies
import preferredDealerTemplate from './../templates/preferredDealerTemplate';

const ATTRIBUTES = {
    CHANGE_SEARCH: 'data-change-search-cta',
    MAP: 'data-dealer-map',
};

/**
 * Container view for the selected preferred dealer
 */
export default class PreferredDealer {
  /**
   * @constructor
   * @param model {Object} Observable model of the main plugin app to set data to and observe
   * @param preferredDealer {Object} Preferred dealer object
   * @param disableChange {Boolean} Indicator to disable changing the preferred dealer
   * @param showMap {Boolean} Indicator to hide or show the Map
   * @param content {Object} Localized content
   */
    constructor({ model, preferredDealer, disableChange, showMap, content }) {
        this.model = model;

        this.element = preferredDealerTemplate({
            dealer: preferredDealer,
            content,
            disableChange,
            showMap,
        })({ getNode: true });

        this.preferredDealer = preferredDealer;
        this.changeSearchElem = null;
        this.map = null;

        this.changeSearchHandler = this.changeSearchHandler.bind(this);

        this.cacheDOM();
        this.attachEvents();

        if (showMap) {
            this.createMap();
        }
    }

  /**
   * @method destroy
   * @description Detaches events
   */
    destroy() {
        this.detachEvents();

        if (this.dealerMap) {
            this.dealerMap.destroy();
        }
    }

  /**
   * @method cacheDOM
   * @description Caches DOM elements
   */
    cacheDOM() {
        this.changeSearchElem = this.element.querySelector(
      `[${ATTRIBUTES.CHANGE_SEARCH}]`
    );
        this.mapElm = this.element.querySelector(`[${ATTRIBUTES.MAP}]`);
    }

  /**
   * @method attachEvents
   * @description Attaches click event to the change search label element
   */
    attachEvents() {
        this.changeSearchElem.addEventListener(
      EVENTS.CLICK,
      this.changeSearchHandler
    );
    }

  /**
   * @method detachEvents
   * @description Detaches the associated events
   */
    detachEvents() {
        this.changeSearchElem.removeEventListener(
      EVENTS.CLICK,
      this.changeSearchHandler
    );
    }

  /**
   * @method changeSearchHandler
   * @description Click handler for the change search element. Sets the current step back to 1 on
   * the model
   */
    changeSearchHandler(event) {
        event.preventDefault();
        this.model.setCurrentStep(2);
    }

  /**
   * @method createMap
   * @description Instantiates a DealerSearchMap and sets a reference to the instance
   */
    createMap() {
        this.dealerMap = new DealerMap(this.mapElm, this.preferredDealer, true);
    }

  /**
   * @method render
   * @description Returns the element associated
   */
    render() {
        return this.element;
    }
}

