/**
 * @const defaultLocalization
 * @description Default localization labels for Dealer Locator App
 * @type {{label: String}}
 */
const defaultLocalization = {
    find: 'Find',
    searchInputLabel: 'Postal Code or City, Province',
    dealerSearchErrorMessage: 'Invalid Postal Code or City, Province',
    dealerValidationError: 'The dealer is currently unavailable.',
    emptyDealerErrorMessage: 'Please select a dealer to continue.',
    dealerSearchHeading: 'Search for your preferred dealer.',
    dealerResultHeading: 'Select one of the dealers near {0}.',
    closestDealersHeading: 'These are the closest dealers based on your search.',
    preferredDealerHeading: 'This is your preferred dealer.',
    changeDealer: 'Change Dealer',
    changeLocation: 'Change Location',
    serverErrorDescription:
    'We were unable to process your request. Please try again.',
    serverErrorTitle: "We're sorry, an error has occurred.",
    suggestedDealer: 'Suggested',
};

// Deconstruct localization object
const {
  find,
  searchInputLabel,
  dealerSearchErrorMessage,
  dealerValidationError,
  emptyDealerErrorMessage,
  dealerSearchHeading,
  dealerResultHeading,
  dealerResultsSubHeading,
  loadMoreLabel,
  closestDealersHeading,
  preferredDealerHeading,
  changeDealer,
  changeLocation,
  serverErrorDescription,
  serverErrorTitle,
  suggestedDealer,
} = Object.assign(
  defaultLocalization,
  window.microsites.ns('pageData').localization
);

export default {
    find,
    searchInputLabel,
    dealerSearchErrorMessage,
    dealerValidationError,
    emptyDealerErrorMessage,
    dealerSearchHeading,
    dealerResultHeading,
    dealerResultsSubHeading,
    loadMoreLabel,
    closestDealersHeading,
    preferredDealerHeading,
    changeDealer,
    changeLocation,
    serverErrorDescription,
    serverErrorTitle,
    suggestedDealer,
};

