function getRecordDetails(rowData) {
    let recordString = null;
    if (rowData.firstName) {
        recordString = rowData.firstName;
    }

    if (rowData.lastName && recordString) {
        recordString = `${recordString} ${rowData.lastName}`;
    } else if (!recordString && rowData.lastName) {
        recordString = `${rowData.lastName}`;
    }

    if (!rowData.dataFlag && !rowData.hasError) {
        recordString = `No records found for ${rowData.email}`;
    } else if (!rowData.dataFlag && rowData.hasError) {
        recordString = `Error while searching for ${rowData.email}`;
    } else if (rowData.email && recordString) {
        recordString = `${recordString}, ${rowData.email}`;
    } else {
        recordString = `${rowData.email}`;
    }
    return recordString;
}

function getButtonClass(rowData) {
    if (rowData) {
        if (rowData.dataFlag) {
            if (rowData.hasDeleteAccess && rowData?.dataTableNames.length) {
                return 'delete-btn';
            }
            else if (rowData.hasDeleteAccess && !rowData?.dataTableNames.length) {
                return 'delete-btn--disabled';
            }
            return 'delete-btn--disabled';
        } else if (!rowData.dataFlag) {
            return 'hide';
        }
    }
    return 'delete-btn--disabled';
}
/**
 * Template for waypoint nav
 * Uses ES6 template literal to composite the markup for a waypoint nav
 * @param waypoints {Array} the array of waypoint nav items
 */
export default (tableRows = {}) => `
<div class="data-deletion__result-table">
    <div class="data-deletion__result-table__header-container">
        <div class="data-deletion__result-table__header">
            <div>Project</div>
        </div>
        <div class="data-deletion__result-table__header">
            <div>User</div>
        </div>
        <div class="data-deletion__result-table__header">
            <div>Data</div>
        </div>
        <div class="data-deletion__result-table__header">
            <div>Action</div>
        </div>
    </div>
    <div class="data-deletion__result-table__row-container">
    ${Object.keys(tableRows).map(key => `
    <div class="data-deletion__result-table__row">
        <div class="data-deletion__result-table__column">
            <div>${key}</div>
        </div>
        <div class="data-deletion__result-table__column">
            <div>
                ${tableRows[key] ? getRecordDetails(tableRows[key]) : 'No Results Found'}
            </div>
        </div>
        <div class="data-deletion__result-table__column">
        ${tableRows[key]?.dataTableNames.length ?
        `<div>
            ${tableRows[key].dataTableNames.map((item) => `
                <div> ${item} </div>
            `).join('')}
        </div >` : 'No Data Found'}
        </div>
        <div class="data-deletion__result-table__column">
            <button id="${key}" class="button button_primary ${getButtonClass(tableRows[key])}">Delete</button>
            <span class="icon-warning tooltip ${tableRows[key] && tableRows[key].hasError ? '' : 'hide'}">
                <span class="tooltiptext">Error while fetching data for this provider</span>
            </span>
        </div>
    </div >
    `).join('')}
    </div>
</div>`;
