import Ajax from 'modules/Ajax';


export default class AccessRestrictWarranty {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }


    init() {
        this.loadModules();
        // this.labelCheck();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    // labelCheck() {
    //     console.log(this);
    //     document.getElementById('fleet-loader').style.display = '';
    //     const urlServlet = '/bin/microsites/access-restrict-warranty';
    //     const siteName = 'mb-new-warranty';
    //     const accessName = document.getElementsByName('accessData');
    //     const accessData = {
    //         siteName,
    //     };
    //     for (let i = 0; i < accessName.length; i += 1) {
    //         if (accessName[i].getAttribute('data-sortorder') != null) {
    //             accessData[`${i}`] = (accessName[i].getAttribute('data-sortorder'));
    //         }
    //     }
    //     accessData.length = accessName.length;
    //     const siteHostName = window.location.hostname;
    //     if (!this.stringIncludes(siteHostName, 'author')) {
    //         this.Ajax.ajaxPostFn(urlServlet, accessData, (userData) => {
	// 		console.log(userData);
    //             if (userData !== 'null') {
    //                 document.getElementById('fleet-loader').style.display = 'none';
	// 				  const servletData = JSON.parse(userData);
	// 				  const servletValue = JSON.parse(servletData);
    //                 const y = document.getElementsByName('formHeading');
    //                 const z = document.getElementsByName('warranty-tile-feature-content');
    //                 let userType = servletValue['userType'];
    //                 let dealerHomeCode = servletValue['dealerHomeCode'];
    //                 let franchises = servletValue['franchises'];
    //                 let franchisesList = [];
    //                 franchisesList = franchises.split(',');

    //                 for (let ad = 0; ad < accessData.length; ad += 1) {
	// 					console.log(accessData[ad]);
	// 	                if (userType !== "0"){
    //                         z[ad].style.display = 'block';
    //                         console.log(userType, "1");
    //                     } else if ((userType === "0" && accessData[ad] === "0") || (userType === "0" && 
    //                                     (accessData[ad] === "PC" && 
    //                                         (
    //                                             franchisesList.includes('PC') || 
    //                                             franchisesList.includes('MB') ||
    //                                             franchisesList.includes('MY') ||
    //                                             franchisesList.includes('LT')
    //                                         )
    //                                     ) || (accessData[ad] === "SP" && 
    //                                     (
    //                                         franchisesList.includes('SP') || 
    //                                         franchisesList.includes('S2')
    //                                     ))
    //                                 )) {
    //                             z[ad].style.display = 'block';
    //                             console.log(userType, "2");
    //                     } else if (userType === "0" && accessData[ad] !== "0"){
    //                             z[ad].style.display = 'none';
    //                             console.log(userType, "3");
    //                     }  else {
    //                         document.getElementById('fleet-loader').style.display = 'none';
    //                         console.log(userType, "4");
    //                     }
	// 	            }
    //             }  
    //         });
    //     } else {
    //         document.getElementById('fleet-loader').style.display = 'none';
    //         const z = document.getElementsByName('warranty-tile-feature-content');
    //         for (let a = 0; a < z.length; a += 1) {
    //             z[a].style.display = 'block';
    //         }
    //     }
    // }

    // stringIncludes(a, b) {
    //     console.log(this);
    //     return a.indexOf(b) !== -1;
    // }
}
