import { EVENTS } from 'Constants';
import Modal from 'partials/modal';

export default class DogModal {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDomElement();
        this.attachEvents();
    }

    cacheDomElement() {
        this.optionImages = this.element.querySelectorAll('img');
    }

    attachEvents() {
        [].forEach.call(this.optionImages, (element) => {
            element.addEventListener(EVENTS.CLICK, () => {
                const popupImage = document.createElement('img');
                popupImage.src = element.getAttribute('large-img-src') ? element.getAttribute('large-img-src') : element.src;
                popupImage.classList.add('modal__image');

                const modal = new Modal(undefined, {
                    modalContent: popupImage
                });

                modal.open();
            });
        });
    }
}
