/**
 * Template for dog vehicle classes container
 * Uses ES6 template literal to composite the markup for a dog vehicle classes container
 * @param summary {Array} the array of dog vehicle classes container items
 */
import { html } from 'utils';

export default (item = []) => html`
    <div class="vehicle-classes__container">
    <div class='img-container'>
        <a href="${item[1].cta}">
            <img src="${item[1].imagePath}" alt="${item[1].imageAlt}" class="vehicle-classes__image">
        </a>
    </div>
    <p class="vehicle-classes__title">${item[1].name}</p>
    </div>
`.trim();
