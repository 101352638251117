/**
 * Template for a LocationSearch List.
 * Uses ES6 template literal to composite the markup for a LocationSearch List
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying a list of location search options
 * @param listBoxId {String} id for element with role="listbox"
 */
export default listBoxId => htmlNode`
    <div class="location-search__locations" aria-hidden="true">
        <ul class="location-search__list"
            data-location-list=""
            id="${listBoxId}"
            role="listbox">
        </ul>
        <div class="location-search__engine"></div>
    </div>
`;

