import React, { Component } from "react";
class TextField extends Component {
    render() {
        return (
            <div className="email-us-form-util-container email-us-form-field-container">
                <label
                    htmlFor={this.props.name}
                    className={this.props.handlers.getErrorClassForLabels(
                        this.props.name
                    )}
                >{`${this.props.handlers.getConfig(this.props.name)}${
                    this.props.required ? "*" : ""
                }`}</label>
                <input
                    type={this.props.type}
                    name={this.props.name}
                    id={this.props.name}
                    onFocus={this.props.handlers.onFocusHandler}
                    onChange={this.props.handlers.onFieldChangeHandler}
                    onBlur={this.props.handlers.onTouchedHandler}
                    value={this.props.value}
                    className={this.props.handlers.getErrorClassForFields(
                        this.props.name
                    )}
                />
                {!this.props.isValid && this.props.isTouched ? (
                    <div className="error-label">{this.props.errorContent}</div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
// function TextField(props) {
//     return (
//         <div className="email-us-form-util-container email-us-form-field-container">
//             <label
//                 htmlFor={props.name}
//                 className={props.handlers.getErrorClassForLabels(props.name)}
//             >{`${props.handlers.getConfig(props.name)}${
//                 props.required ? "*" : ""
//             }`}</label>
//             <input
//                 type={props.type}
//                 name={props.name}
//                 id={props.name}
//                 onFocus={props.handlers.onFocusHandler}
//                 onChange={props.handlers.onFieldChangeHandler}
//                 onBlur={props.handlers.onTouchedHandler}
//                 value={props.value}
//                 className={props.handlers.getErrorClassForFields(props.name)}
//             />
//             {!props.isValid && props.isTouched ? (
//                 <div className="error-label">{props.errorContent}</div>
//             ) : (
//                 ""
//             )}
//         </div>
//     );
// }
export default TextField;
