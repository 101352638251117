import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import UISCreateFormUtil from 'modules/UISCreateFormUtil';

const ID = {
    SUBMITBUTTON: 'submitNow',
    TOGGLE_DIV: 'toggleDiv',
    TOGGLE_HEADER: 'toggleHeader',
    TOGGLE_CONTENT: 'toggleContent',
    HIDE_SECTION: 'hideSection',
    INQUIRY_NO: 'InquiryNumber',
    UIS_DATE: 'uisDate',
    INQUIRY_STATUS: 'inquiryStatus',
    ASSETS: 'Assets',
    ASSETS_DATE: 'AssetsDate',
    BOLD_BUTTON: 'bold',
    ITALIC_BUTTON: 'italic',
    UNDERLINE_BUTTON: 'underline',
    OUTDENT_BUTTON: 'outdent',
    INDENT_BUTTON: 'indent',
    SITE_NAME: 'site-name',
    FONT_SIZE: 'fontsize',
    FONT_STYLE: 'fontstyle',
    REMOVE_FORMAT: 'removeformat',
    FILES: 'submitNow-fileID',
    SIZE_ERROR: 'sizeError',
    TEXT_AREA: 'richTextArea',
    DISPLAY_SECTION: 'previousComments',
    SUBMIT_DATE: 'SubmitDate',
    COMMENT_ERROR: 'commentErrorMessage',

};

export default class UserCommentBox {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.uisId = new URLSearchParams(window.location.search).get('ref');
        this.url = '/bin/microsites/uis-comments';
        this.fileApi = '/bin/microsites/uis/file-download';
        this.loadModules();
        this.displayComments();
        this.attachEvents();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'upfitter';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha-comment');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.UISCreateFormUtil = new UISCreateFormUtil(this.element);
    }
    displayComments() {
        const option = 'display';
        const uisId = this.uisId;
        const fileData = {
            option,
            uisId
        };
        this.Ajax.ajaxPostFn(this.url, fileData, (success) => {
            const reportList = JSON.parse(JSON.parse(success));

            const displayInqStatus = document.getElementById(`${ID.INQUIRY_STATUS}`);
            const displayInq = document.getElementById(`${ID.INQUIRY_NO}`);
            const displaySubmitDate = document.getElementById(`${ID.SUBMIT_DATE}`);
            const displayUisDate = document.getElementById(`${ID.UIS_DATE}`);
            this.displayAssets = document.getElementById(`${ID.ASSETS}`);
            this.displayAssetsDate = document.getElementById(`${ID.ASSETS_DATE}`);
            this.resultDisplay = document.getElementById(`${ID.DISPLAY_SECTION}`);
            let values = '';
            let assets = '';
            let assetsDateValue = '';
            if (reportList.comments !== undefined) {
                for (let key = 0; key < reportList.comments.length; key += 1) {
                    values += this.generateComments(reportList.comments[key]);
                    if (reportList.comments[key].attachments !== undefined) {
                        const assetsValue = reportList.comments[key].attachments;
                        const assetsDate = reportList.comments[key].date;
                        for (let i = 0; i < assetsValue.length; i += 1) {
                            assets += `<a class="rich-text__filecolor" href ="${this.fileApi}?fileName=${assetsValue[i]}&uisId=${this.uisId}">${assetsValue[i].substr(assetsValue[i].indexOf('_') + 1)}</a><br/>`;
                            assetsDateValue += `${assetsDate}<br/>`;
                        }
                    }
                }

                const InquiryNumber = reportList.inquiryNumber;
                const formSubmitStatus = reportList.status;
                this.resultDisplay.innerHTML = values;

                if (formSubmitStatus === 'Pending') {
                    displayInqStatus.innerHTML = 'This inquiry is Active';
                } else {
                    displayInqStatus.innerHTML = `This inquiry is ${formSubmitStatus}`;
                }

                displaySubmitDate.innerHTML = `${reportList.lastUpdated}`;

                displayUisDate.innerHTML = `Opened ${reportList.Submitteddate}`;
                this.displayAssets.innerHTML = `${assets}`;
                this.displayAssetsDate.innerHTML = `${assetsDateValue}`;
                displayInq.innerHTML = `Inquiry #${InquiryNumber}`;
            } this.loadCaptcha();
        });
    }

    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.TOGGLE_DIV}, #${ID.TOGGLE_HEADER}, #${ID.TOGGLE_CONTENT}`)) {
                e.preventDefault();
                console.log(this);
                const container = document.getElementById(`${ID.HIDE_SECTION}`);
                if (container.style.display === 'none') {
                    container.style.display = 'block';
                } else {
                    container.style.display = 'none';
                }
            }
        });

        document.getElementById('bold').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BOLD_BUTTON}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('bold', false, null);
            }
        });

        document.getElementById('italic').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.ITALIC_BUTTON}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('italic', false, null);
            }
        });

        document.getElementById('underline').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.UNDERLINE_BUTTON}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('underline', false, null);
            }
        });

        document.getElementById('outdent').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.OUTDENT_BUTTON}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('outdent', false, null);
            }
        });

        document.getElementById('indent').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.INDENT_BUTTON}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('indent', false, null);
            }
        });

        document.getElementById('fontsize').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FONT_SIZE}`)) {
                e.preventDefault();
                console.log(this);
                const size = document.getElementById('fontsize').value;
                document.execCommand('formatBlock', false, size);
            }
        });

        document.getElementById('fontstyle').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FONT_STYLE}`)) {
                e.preventDefault();
                console.log(this);
                const style = document.getElementById('fontstyle').value;
                document.execCommand('fontName', false, style);
            }
        });

        document.getElementById('removeformat').addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.REMOVE_FORMAT}`)) {
                e.preventDefault();
                console.log(this);
                document.execCommand('removeFormat', false, '');
            }
        });

        this.element.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.FILES}`)) {
                e.preventDefault();
                const subButton = document.getElementById('submitNow');
                const file = e.target.value;
                const files = document.getElementById(`${ID.FILES}`).files.length;
                const filesId = document.getElementById(`${ID.FILES}`);
                const addFiles = document.getElementById(`${ID.SIZE_ERROR}`);
                document.getElementById('ErrorMessage').innerHTML = '';
                subButton.removeAttribute('disabled');
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    if (ext === 'xlsx' || ext === 'XLSX' || ext === 'jpg' || ext === 'JPG' || ext === 'png' || ext === 'PNG' || ext === 'bmp' || ext === 'BMP' || ext === 'gif' || ext === 'GIF' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF' || ext === 'ppt' || ext === 'pptx') {
                        if (this.UISCreateFormUtil.validateCommentFileSize()) {
                            subButton.removeAttribute('disabled');
                            document.getElementById('ErrorMessage').innerHTML = '';
                        } else {
                            subButton.setAttribute('disabled', 'disabled');
                        }
                    } else {
                        subButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('ErrorMessage').innerHTML = `${ext} format is not supported`;
                    }
                }
            }
        });

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const capRes = grecaptcha.getResponse();
                if (this.ValidateComment() && capRes.length !== 0) {
                    document.getElementById('g-recaptcha-error-comment').innerHTML = '';
                    const subButton = document.getElementById('submitNow');
                    document.getElementById('uis-CommentBox-loader').style.display = 'block';
                    document.getElementById('uis-CommentBox-loader').innerHTML = '<div class="rich-text__loader-submit" id="submit-loader"></div><span class="rich-text__attachWarning">Please wait...</span>';
                    const fileData = new FormData();
                    fileData.set('uisId', this.uisId);
                    fileData.set('option', 'insert');
                    fileData.set('captcha', capRes);
                    fileData.set('comment', document.getElementById(`${ID.TEXT_AREA}`).innerHTML);
                    const textFiles = document.getElementById(`${ID.FILES}`).files.length;
                    if (textFiles !== 0 && textFiles < 6) {
                        for (let index = 0; index < textFiles; index += 1) {
                            fileData.set('attachments', document.getElementById(`${ID.FILES}`.files[index]));
                        }
                    }
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(this.url, fileData, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.UISCreateFormUtil.displayPopUp('Please verify the captcha response');
                        } else if (success.includes('Please provide a proper comment')) {
                            this.UISCreateFormUtil.displayPopUp('Please provide a proper comment');
                        } else {
                            success = JSON.parse(JSON.parse(success));
                            if (success !== 'false' && success.status === 'sent') {
                                this.resultDisplay.innerHTML += this.generateComments(success);
                                document.getElementById(`${ID.TEXT_AREA}`).innerHTML = '';
                                document.getElementById(`${ID.FILES}`).value = '';
                            } else if (success !== 'false' && success.status === 'not sent') {
                                this.resultDisplay.innerHTML += this.generateComments(success);
                                document.getElementById(`${ID.TEXT_AREA}`).innerHTML = '';
                                document.getElementById(`${ID.FILES}`).value = '';
                                const formElement = `<div>
                                    <h5 class="modal-title">Thank you! Your submission is successful. </h5>
                                    <h6 style="font-size: 13px;color: #ef5a39d1;">We are unable to send email notification at this moment.</h6>
                                    </div>`;
                                this.UISCreateFormUtil.displayPopUp(formElement);
                            } else {
                                this.UISCreateFormUtil.displayPopUp('submission failed');
                            }
                        }
                        document.getElementById('uis-CommentBox-loader').style.display = 'none';
                        subButton.removeAttribute('disabled');
                        document.getElementById('goToRequestButton').style.display = 'none';
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error-comment').innerHTML = '<span class="check-form__error-field">This field is required.</span>';
                    this.ValidateComment();
                }
            }
        });
    }

    ValidateComment() {
        console.log(this);
        const ValidateTextArea = document.getElementById(`${ID.TEXT_AREA}`).innerHTML;
        const capRes = grecaptcha.getResponse();
        const errorMessage = document.getElementById(`${ID.COMMENT_ERROR}`);
        if (ValidateTextArea.length === 0 && capRes.length === 0) {
            errorMessage.classList.remove('check-form__error-field--hidden');
            return false;
        } else if (ValidateTextArea.length === 0) {
            document.getElementById('g-recaptcha-error-comment').innerHTML = '';
            errorMessage.classList.remove('check-form__error-field--hidden');
            return false;
        } else if (ValidateTextArea.length !== 0 && capRes.length === 0) {
            document.getElementById('g-recaptcha-error-comment').innerHTML = '<span class="check-form__error-field">This field is required.</span>';
            errorMessage.classList.add('check-form__error-field--hidden');
            return false;
        }
        document.getElementById('g-recaptcha-error-comment').innerHTML = '';
        errorMessage.classList.add('check-form__error-field--hidden');
        return true;
    }

    generateComments(data) {
        let values = `<div class="rich-text__ui-data-item" style="display:flex; border-bottom: 1px solid #c1c7d0;">
                         <div class="rich-text__ui-media-figure">${this.generateInitials(data.userName)}</div>
                            <div class="data-section" style="position: relative; padding: 0px 14px;">
                                <div class="comment-data">${data.comment}</div>
                                <div style="padding-top: 4%;">`;
        if (data.attachments !== undefined) {
            this.displayAssets = document.getElementById(`${ID.ASSETS}`);
            this.displayAssetsDate = document.getElementById(`${ID.ASSETS_DATE}`);
            let assets = '';
            for (let i = 0; i < data.attachments.length; i += 1) {
                values += `<a class="rich-text__filecolor" href ="${this.fileApi}?fileName=${data.attachments[i]}&uisId=${this.uisId}">${data.attachments[i].substr(data.attachments[i].indexOf('_') + 1)}</a><br/>`;
                assets = `<a class="rich-text__filecolor" href ="${this.fileApi}?fileName=${data.attachments[i]}&uisId=${this.uisId}">${data.attachments[i].substr(data.attachments[i].indexOf('_') + 1)}</a><br/>`;
                this.displayAssets.innerHTML += assets;
                this.displayAssetsDate.innerHTML += `${data.date}<br/>`;
            }
        }
        values += `</div>
                    <div class="rich-text__ui-conversation-message-sent">
                        Sent on ${data.date} by ${data.userName}
                    </div></div></div></div>`;
        return values;
    }

    generateInitials(userName) {
        console.log(this);
        let initials = '';
        if (userName !== undefined) {
            const fullName = userName.split(' ');
            initials += fullName[0].substring(0, 1).toUpperCase();
            if (fullName.length > 1) {
                initials += fullName[fullName.length - 1].substring(0, 1).toUpperCase();
            } else {
                initials += initials;
            }
        }
        return initials;
    }
}
