import { EVENTS } from 'Constants';

const ID = {
    FAQ_QUESTION: 'faq-v4__question',
    FAQ_Display_None: 'faq-v4__display-none',
    FAQ_Display_Block: 'faq-v4__display-block',
    FAQ_Style_Plus: 'faq-v4__icon--plus',
    FAQ_Style_Minus: 'faq-v4__icon--minus',
    FAQ_Style_LeftArrow: 'faq-v4__icon--left-arrow',
    FAQ_Panel: 'faq-v4__panel',
};

export default class FAQV2 {
    constructor() {
        this.init();
    }

    init() {
        this.dropdown = null;
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${ID.FAQ_Style_Plus}`)) {
                this.dropdown = e.target.parentElement.parentElement.parentElement.parentElement;
                this.closeDropdown();
                e.target.classList.remove(`${ID.FAQ_Style_Plus}`);
                e.target.classList.add(`${ID.FAQ_Style_Minus}`);
                e.target.parentElement.nextElementSibling
                    .classList.remove(`${ID.FAQ_Display_None}`);
                e.target.parentElement.nextElementSibling.classList.add(`${ID.FAQ_Display_Block}`);
            } else if (e.target.matches(`.${ID.FAQ_Style_Minus}`)) {
                e.target.classList.remove(`${ID.FAQ_Style_Minus}`);
                e.target.classList.add(`${ID.FAQ_Style_Plus}`);
                e.target.parentElement.nextElementSibling
                    .classList.remove(`${ID.FAQ_Display_Block}`);
                e.target.parentElement.nextElementSibling.classList.add(`${ID.FAQ_Display_None}`);
            }
        });
    }

    closeDropdown() {
        this.dropdown = this.dropdown.querySelector(`.${ID.FAQ_Style_Minus}`);
        if (this.dropdown !== null) {
            this.dropdown.classList.remove(`${ID.FAQ_Style_Minus}`);
            this.dropdown.classList.add(`${ID.FAQ_Style_Plus}`);
            this.dropdown.parentElement.nextElementSibling.classList.remove(`${ID.FAQ_Display_Block}`);
            this.dropdown.parentElement.nextElementSibling.classList.add(`${ID.FAQ_Display_None}`);
        }
    }
}
