import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import MessageBox from 'modules/fleet-info/MessageBox';
import ContactUsUtil from 'modules/ContactUsUtil';
import CanApplicationPopupForm from 'modules/CanApplicationPopupForm';
import FleetInfoTable from '../templates/fleet-info-table';
import TrackEventsTable from '../templates/track-events-table';
import ActivateDeactivateForm from '../templates/activate-deactivate-form';


const ID = {
    CAN_SEARCH: 'canSearch',
    CAN_DETAIL_INPUT_FIELDS: 'canDetailInputFields',
    CAN_LIST_INPUT_FIELDS: 'canListInputFields',
    CAN_SEARCH_CLEAR_BUTTON: 'canSearchClearBtn',
    CAN_SEARCH_SUBMIT_BUTTON: 'canSearchSubmitBtn',
    TRACK_EVENTS_BUTTON: 'trackEvents',
    UPDATE_RECORD_BUTTON: 'updateRecord',
    DEACTIVATE_RECORD_BUTTON: 'deactivateRecord',
    ACTIVATE_RECORD_BUTTON: 'activateRecord',
    PAGE_LOADER: 'page-loader',
    ACTIVATE_DEACTIVATE_BTN: 'activateDeactivateBtn',
    ACTIVATE_DEACTIVATE: 'activate-deactivate'
};

const CLASSES = {
    BUTTON_CONTAINER: 'button__container',
    TABLE_SECTION: 'sort-table__table-section',
    TABLE_CONTAINER: 'sort-table__container',
    CAN_SEARCH_AND_UPDATE_CONT: 'can-search-and-update',
    OVERLAY_HIDDEN: 'overlay--hidden',
    FORM_MESSAGE_CONTAINER: 'form__message-container',
    FORM_MESSAGE_ERROR: 'form__message--error',
    FORM_MESSAGE_SUCCESS: 'form__message--success',
    HIDDEN: 'hidden',
    POP_UP: 'pop-up',
    POP_UP_OPEN: 'pop-up--open',
    ACTIVATE_DEACTIVATE_INPUT_FIELD: 'activate-deactivate__input-field',
    FORM_MESSAGE: 'form__message',
    CROSS_ICON: 'icon-cross'
};

const StringConstants = {
    BLANK: '',
    DISABLED: 'disabled',
    UPDATE: 'update',
    ERROR_OCCURED: 'An Error Occured',
    NO_RECORDS_FOUND: 'No Record Found',
    NO_EVENT_FOUND: 'No Event Found',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    ERROR: 'error',
    SUCCESS: 'success',
    FILL_FIELD_ERROR_MSG: 'Please fill out this field',
    ONE_SEARCH_FIELD_IS_REQUIRED: 'Please fill in at least one search field'
};

const ATTRIBUTES = {
    TYPE: 'type',
};

const QueryStringConstants = {
    CAN: 'canNumber'
};

const URL = {
    SEARCH_CAN_APPLICATION: '/bin/microsites/fleet-info/search-can-accounts',
    CAN_TRACK_EVENTS: '/bin/microsites/fleet-info/can-events',
    UPDATE_CAN_STATUS: '/bin/microsites/fleet-info/update_can_status'
};

export default class CanSearchAndUpdate {
    constructor(element) {
        this.element = element;
        this.detailInputFields = null;
        this.listInputField = null;
        this.clearButton = null;
        this.submitButton = null;
        this.trackEventBtn = null;
        this.updateRecordBtn = null;
        this.activateBtn = null;
        this.deactivateBtn = null;
        this.selectedRow = null;
        this.selectedRowData = null;
        this.form = null;
        this.tableData = null;
        this.false = false;
        this.true = true;
        this.radioAll = null;
        this.messageContainer = null;
        this.tableSection = null;
        this.buttonContainer = null;
        this.popupErrorField = null;
        this.messageCloseIcon = null;
        this.init();
    }

    init() {
        this.cacheDomElement();
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
        this.CanApplicationPopupForm = new CanApplicationPopupForm(this.element,
            this.messageContainer, this.tableSection, this.buttonContainer,
            this.updateTableData.bind(this));
    }

    /**
     * Caching DOM Elements to be used for opertaion in functions.
     */
    cacheDomElement() {
        this.detailInputFields = document.querySelectorAll(`#${ID.CAN_DETAIL_INPUT_FIELDS} input`);
        this.listInputField = document.querySelector(`#${ID.CAN_LIST_INPUT_FIELDS} input`);
        this.clearButton = document.querySelector(`#${ID.CAN_SEARCH_CLEAR_BUTTON}`);
        this.submitButton = document.querySelector(`#${ID.CAN_SEARCH_SUBMIT_BUTTON}`);
        this.form = document.querySelector(`#${ID.CAN_SEARCH}`);
        this.trackEventBtn = document.querySelector(`#${ID.TRACK_EVENTS_BUTTON}`);
        this.updateRecordBtn = document.querySelector(`#${ID.UPDATE_RECORD_BUTTON}`);
        this.activateBtn = document.querySelector(`#${ID.ACTIVATE_RECORD_BUTTON}`);
        this.deactivateBtn = document.querySelector(`#${ID.DEACTIVATE_RECORD_BUTTON}`);
        this.canSearchAndUpdateContainer = document.querySelector(`.${CLASSES.CAN_SEARCH_AND_UPDATE_CONT}`);
        this.pageLoader = document.querySelector(`#${ID.PAGE_LOADER}`);
        this.messageContainer = this.canSearchAndUpdateContainer.querySelector(`.${CLASSES.FORM_MESSAGE_CONTAINER}`);
        this.tableSection = document.querySelector(`.${CLASSES.TABLE_SECTION}`);
        this.buttonContainer = document.querySelector(`.${CLASSES.BUTTON_CONTAINER}`);
        this.messageCloseIcon = document.querySelector(`.${CLASSES.CROSS_ICON}`);
    }

    /**
     * Attaching event listners to DOM elements.
     */
    attachEvents() {
        [].forEach.call(this.detailInputFields, (field) => {
            field.addEventListener(EVENTS.INPUT, () => {
                if (field.value) {
                    this.listInputField.setAttribute(`${StringConstants.DISABLED}`, this.true);
                } else if (this.isAllFieldsBlank(this.detailInputFields)) {
                    this.listInputField.removeAttribute(`${StringConstants.DISABLED}`);
                }
            });
        });

        this.listInputField.addEventListener(EVENTS.INPUT, () => {
            if (this.listInputField.value) {
                [].forEach.call(this.detailInputFields, (field) => {
                    field.setAttribute(`${StringConstants.DISABLED}`, this.true);
                });
            } else {
                [].forEach.call(this.detailInputFields, (field) => {
                    field.removeAttribute(`${StringConstants.DISABLED}`);
                });
            }
        });

        this.clearButton.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            this.clearAllInputFields();
        });

        this.submitButton.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
            this.getSearchResponse();
        });

        if (this.trackEventBtn) {
            this.trackEventBtn.addEventListener(EVENTS.CLICK, () => {
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
                this.getSeletedRow();
                this.displayEvents();
            });
        }

        if (this.updateRecordBtn) {
            this.updateRecordBtn.addEventListener(EVENTS.CLICK, () => {
                this.getSeletedRow();
                document.querySelector(`.${CLASSES.POP_UP}`).setAttribute(`${ATTRIBUTES.TYPE}`, `${StringConstants.UPDATE}`);
                this.CanApplicationPopupForm.shortFormValues = this.selectedRowData;
            });
        }

        if (this.deactivateBtn) {
            this.deactivateBtn.addEventListener(EVENTS.CLICK, () => {
                this.getSeletedRow();

                if (!(this.deactivateBtn.getAttribute(`${StringConstants.DISABLED}`))) {
                    this.ContactUsUtil.displayPopUp(`${ActivateDeactivateForm(StringConstants.DEACTIVATE)}`, 'Deactivate Form');
                    this.addEventToPopupBtn(`${StringConstants.DEACTIVATE}`);
                }
            });
        }

        if (this.activateBtn) {
            this.activateBtn.addEventListener(EVENTS.CLICK, () => {
                this.getSeletedRow();

                if (!(this.activateBtn.getAttribute(`${StringConstants.DISABLED}`))) {
                    this.ContactUsUtil.displayPopUp(`${ActivateDeactivateForm(StringConstants.ACTIVATE)}`, 'Activate Form');
                    this.addEventToPopupBtn(`${StringConstants.ACTIVATE}`);
                }
            });
        }

        this.messageCloseIcon.addEventListener(EVENTS.CLICK, () => {
            this.messageContainer.classList.add(`${CLASSES.HIDDEN}`);
        });
    }

    /**
     * Function : get response according to the input in fields.
     */
    getSearchResponse(isUpdatedData) {
        const inputFieldsArray = [].slice.call(this.detailInputFields);
        inputFieldsArray.push(this.listInputField);
        if (this.hasOneNonEmptyField(inputFieldsArray)) {
            this.makeAjaxCall(isUpdatedData);
        } else {
            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            this.hideTableContainer();
            this.MessageBox.showMessage(StringConstants.ERROR,
                StringConstants.ONE_SEARCH_FIELD_IS_REQUIRED);
        }
    }

    /**
     * Function : To update the status of a paticlar record.
     */
    updateRecordStatus(status, formData) {
        formData.set('canNumber', this.selectedRowData.canNumber);
        if (status === `${StringConstants.DEACTIVATE}`) {
            formData.set('canStatus', 'N');
        } else {
            formData.set('canStatus', 'Y');
        }

        this.Ajax.ajaxMultipartPostFn(URL.UPDATE_CAN_STATUS, formData, (success) => {
            const data = JSON.parse(success);
            document.querySelector(`.${CLASSES.POP_UP}`).classList.remove(`${CLASSES.POP_UP_OPEN}`);
            if (!(data.error) && data.canStatusUpdated) {
                this.updateTableData(true);
                this.MessageBox.showMessage(StringConstants.SUCCESS, `Record ${formData.get('canStatus') === 'Y' ? 'Activated' : 'Deactivated'} Successfully`);
            } else if (data.error) {
                this.popupErrorField.innerHTML = data.error;
                this.MessageBox.showMessage(StringConstants.ERROR, data.error);
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            } else {
                this.MessageBox.showMessage(StringConstants.ERROR);
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            }
        });
    }

    /**
     * Function : To add event listeners to activate or
     * deactivate button present in popup form.
     */
    addEventToPopupBtn(status) {
        const formElement = document.querySelector(`#${ID.ACTIVATE_DEACTIVATE}`);
        const commentField = formElement.querySelector(`.${CLASSES.ACTIVATE_DEACTIVATE_INPUT_FIELD}`);
        this.popupErrorField = document.querySelector(`#${ID.ACTIVATE_DEACTIVATE} p`);
        document.querySelector(`#${ID.ACTIVATE_DEACTIVATE_BTN}`).addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            if (this.ContactUsUtil.containData(commentField.value)) {
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
                this.popupErrorField.classList.add(`${CLASSES.HIDDEN}`);
                const formData = new FormData();
                formData.set(commentField.name, commentField.value);
                this.updateRecordStatus(status, formData);
            } else {
                this.popupErrorField.innerHTML = StringConstants.FILL_FIELD_ERROR_MSG;
                this.popupErrorField.classList.remove(`${CLASSES.HIDDEN}`);
            }
        });
    }

    /**
     * Function : To check at least one field is non-empty.
     *
     * @param : fields - All fields of the form.
     * @return : boolean - true/false
     */
    hasOneNonEmptyField(fields) {
        for (let i = 0; i < fields.length; i += 1) {
            if (this.ContactUsUtil.containData(fields[i].value)) {
                return this.true;
            }
        }
        return this.false;
    }

    /**
     * Function : Update table data display on successful
     * response of activate/deactivate/update.
     */
    updateTableData(isUpdatedData) {
        this.getSearchResponse(isUpdatedData);
    }

    /**
     * Function : To get the table row of which radio button is checked.
     */
    getSeletedRow(element) {
        if (element) {
            this.selectedRow = element;
        } else {
            this.selectedRow = this.canSearchAndUpdateContainer.querySelector(`.${CLASSES.TABLE_CONTAINER} input[type=radio]:checked`);
        }
        this.selectedRowData = this.tableData.find(data =>
            data.canNumber === this.selectedRow.value);
    }

    /**
     * function : To check if all the input fields that are present
     * in array are blank.
     * @param : fieldsArray : Array of input fields.
     */
    isAllFieldsBlank(fieldsArray) {
        for (let i = 0; i < fieldsArray.length; i += 1) {
            if (fieldsArray[i].value) {
                return this.false;
            }
        }
        return this.true;
    }

    /**
     * Function : To clear all the input fields that are present in the
     * 'Can Search and Update' form.
     */
    clearAllInputFields() {
        // Clearing detail input fields.
        [].forEach.call(this.detailInputFields, (field) => {
            field.value = StringConstants.BLANK;
            field.removeAttribute(`${StringConstants.DISABLED}`);
        });

        // Focusing on first input element.
        this.detailInputFields[0].focus();

        // Clearing CAN list input field.
        this.listInputField.value = StringConstants.BLANK;
        this.listInputField.removeAttribute(`${StringConstants.DISABLED}`);
    }

    /**
     * Function : To make ajax call to get response to be displayed in table.
     */
    makeAjaxCall(isUpdatedData = false) {
        const formData = new FormData();
        [].forEach.call(this.form.elements, (element) => {
            if (!(element.id === `${ID.CAN_SEARCH_CLEAR_BUTTON}` || element.id === `${ID.CAN_SEARCH_SUBMIT_BUTTON}`)) {
                formData.set(element.name, element.value);
            }
        });
        this.Ajax.ajaxMultipartPostFn(URL.SEARCH_CAN_APPLICATION, formData, (success) => {
            const data = JSON.parse(success);
            if (!(data.error) && data.length > 0) {
                this.tableData = JSON.parse(success);
                this.tableData.forEach((el) => {
                    if (el.fleetSize === null) {
                        el.fleetSize = '-';
                    }
                    if (el.typeOfVehicle === null) {
                        el.typeOfVehicle = '-';
                    }
                });
                this.tableSection.innerHTML = `${FleetInfoTable(this.tableData)}`;
                this.buttonContainer.classList.remove(`${CLASSES.HIDDEN}`);
                if (!isUpdatedData) {
                    this.MessageBox.hideMessage();
                }
                this.addEventToRadio();
                this.getSeletedRow();
                this.updateButtonDisplay();
            } else if (data.error) {
                this.hideTableContainer();
                this.MessageBox.showMessage(StringConstants.ERROR, data.error);
            } else {
                this.hideTableContainer();
                this.MessageBox.showMessage(StringConstants.ERROR, `${StringConstants.NO_RECORDS_FOUND}`);
            }

            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
        });
    }

    /**
     * Function : Added "CHANGE" event listner to radio buttons present in table.
     */
    addEventToRadio() {
        this.radioAll = document.querySelectorAll('input[name=status]');

        this.radioAll.forEach((radio) => {
            radio.addEventListener(EVENTS.CHANGE, () => {
                this.getSeletedRow(radio);
                this.updateButtonDisplay();
            });
        });
    }


    /**
     * Function : To get can events data and display data in form of
     * table in a modal popup.
     */
    displayEvents() {
        const url = `${URL.CAN_TRACK_EVENTS}?${QueryStringConstants.CAN}=${this.selectedRow.value}`;
        document.querySelector(`.${CLASSES.POP_UP}`).removeAttribute(`${ATTRIBUTES.TYPE}`);

        fetch(url)
            .then(response => response.json())
            .then((trackEventData) => {
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                if (trackEventData) {
                    if (trackEventData.error) {
                        this.ContactUsUtil.displayPopUp(trackEventData.error);
                    } else if (trackEventData.length === 0) {
                        this.ContactUsUtil.displayPopUp(`${StringConstants.NO_EVENT_FOUND}`);
                    } else {
                        this.ContactUsUtil.displayPopUp(`${TrackEventsTable(trackEventData)}`, 'Events');
                    }
                } else {
                    this.ContactUsUtil.displayPopUp(`${StringConstants.ERROR_OCCURED}`, 'Events');
                }
            }).catch((error) => {
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                this.ContactUsUtil.displayPopUp(`${error}`, 'Events');
            });
    }

    /**
     * Function : To enable/disable button on the basis of CAN Status.
     */
    updateButtonDisplay() {
        if (this.selectedRowData.canStatus === 'Y') {
            this.activateBtn.setAttribute(`${StringConstants.DISABLED}`, 'true');
            this.deactivateBtn.removeAttribute(`${StringConstants.DISABLED}`);
        } else {
            this.deactivateBtn.setAttribute(`${StringConstants.DISABLED}`, 'true');
            this.activateBtn.removeAttribute(`${StringConstants.DISABLED}`);
        }
    }

    /*
        Function : To hide table container.
    */
    hideTableContainer() {
        this.tableSection.innerHTML = StringConstants.BLANK;
        this.buttonContainer.classList.add(`${CLASSES.HIDDEN}`);
    }
}
