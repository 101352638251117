/**
 * Template for dog vehicle bodystyle explorer
 * Uses ES6 template literal to composite the markup for a dog vehicle bodystyle explorer container
 * @param summary {Array} the array of dog vehicle bodystyle explorer items
 */
import { html } from 'utils';
import rightColumn from './right-content';

export default item => html`
    <div class="canApplication__wrapper">
        <div class="canApplication__left">
            ${item}
        </div>
        <div class="canApplication__right">
            ${rightColumn()}
        </div>
    </div>
`.trim();
