import { EVENTS } from 'Constants';

import Ajax from 'modules/Ajax';
import AlternateLinkListDownloadUtil from 'modules/AlternateLinkListDownloadUtil';


const ID = {
    DOWNLOADALL: 'downloadAll',
    DOWNLOADSELECTED: 'downloadSelected',
};

let tempId = '';

export default class AlternateLinkListDownload {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.AlternateLinkListDownloadUtil = new AlternateLinkListDownloadUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.DOWNLOADSELECTED}`) && (e.target.parentElement.matches('.link-list__btn_column'))) {
                e.preventDefault();
                tempId = document.activeElement.getAttribute('data-getId');
                const downloadItems = document.getElementsByName(`${tempId}`);
                let checkeddownload = '';
                for (let i = 0; i < downloadItems.length; i += 1) {
                    if (downloadItems[i].checked && downloadItems[i].getAttribute('data-sortorder').includes('/content/dam')) {
                        checkeddownload = checkeddownload.concat(downloadItems[i].getAttribute('data-sortorder'), ',');
                    }
                }

                const downloadChkErr = document.getElementById(`${tempId}`);
                let downloadCk = false;
                let finalCk = false;

                for (let i = 0; i < downloadItems.length; i += 1) {
                    if (downloadItems[i].checked) {
                        downloadCk = true;
                        break;
                    }
                }

                if (downloadCk) {
                    downloadChkErr.classList.add('alternate-link-list-download__error-field--hidden');
                } else {
                    downloadChkErr.classList.remove('alternate-link-list-download__error-field--hidden');
                }

                if (downloadCk) {
                    finalCk = true;
                }

                if (finalCk !== false) {
                    const siteName = 'alternate-link-list-download';
                    const url = '/bin/microsites/alternate-link-list/download';
                    const data = {
                        siteName,
                        checkeddownload,
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success !== 'false') {
                            const params = typeof data === 'string' ? data : Object.keys(data).map(
                                k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`
                            ).join('&');
                            this.postAjax.open('POST', url, true);
                            this.postAjax.responseType = 'arraybuffer';
                            this.postAjax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                            this.postAjax.onreadystatechange = () => {
                                if (this.postAjax.readyState === 4 &&
                                    this.postAjax.status === 200) {
                                    let filename = '';
                                    const arrayBuffer = this.postAjax.response;
                                    const byteArray = new Uint8Array(arrayBuffer);
                                    let blob = new Blob([byteArray], { type: 'text/zip' });
                                    if (blob.size > 1) {
                                        if (!document.documentMode && !/Edge/.test(navigator.userAgent)) {
                                            const type = this.postAjax.getResponseHeader('Content-Type');
                                            const disposition = this.postAjax.getResponseHeader('Content-Disposition');
                                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                                const matches = filenameRegex.exec(disposition);
                                                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                                            }
                                            if (typeof File === 'function') {
                                                blob = new File([blob], filename, { type });
                                            }
                                        }
                                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                            window.navigator.msSaveBlob(blob, filename);
                                        } else {
                                            const URL = window.URL || window.webkitURL;
                                            const downloadUrl = URL.createObjectURL(blob);
                                            if (filename) {
                                            // use HTML5 a[download] attribute to specify filename
                                                const a = document.createElement('a');

                                            // safari doesn't support this yet
                                                if (typeof a.download === 'undefined') {
                                                    window.location = downloadUrl;
                                                } else {
                                                    a.href = downloadUrl;
                                                    a.download = filename;
                                                    document.body.appendChild(a);
                                                    a.click();
                                                }
                                            } else {
                                                window.open(downloadUrl, '_blank');
                                            }
                                        }
                                    } else if (downloadChkErr.classList.contains('alternate-link-list-download__error-field--hidden')) {
                                        this.AlternateLinkListDownloadUtil.displayPopUp('No assets available to download');
                                    }
                                }
                                if (this.postAjax.readyState === 4 &&
                                           this.postAjax.status !== 200) {
                                    if (downloadChkErr.classList.contains('alternate-link-list-download__error-field--hidden')) {
                                        this.AlternateLinkListDownloadUtil.displayPopUp('No assets available to download');
                                    }
                                }
                            };
                            this.postAjax.send(params);
                        }
                    });
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.DOWNLOADALL}`) && (e.target.parentElement.matches('.link-list__btn_column'))) {
                e.preventDefault();
                tempId = document.activeElement.getAttribute('data-getId');
                const downloadAssets = document.getElementsByName(`${tempId}`);
                let checkeddownload = '';
                for (let i = 0; i < downloadAssets.length; i += 1) {
                    if (downloadAssets[i].type === 'checkbox') {
                        downloadAssets[i].checked = true;
                    }
                    if (downloadAssets[i].checked && downloadAssets[i].getAttribute('data-sortorder').includes('/content/dam')) {
                        checkeddownload = checkeddownload.concat(downloadAssets[i].getAttribute('data-sortorder'), ',');
                    }
                }
                const downloadChkErr = document.getElementById(`${tempId}`);
                let downloadCk = false;
                let finalCk = false;

                for (let i = 0; i < downloadAssets.length; i += 1) {
                    if (downloadAssets[i].checked) {
                        downloadCk = true;
                        break;
                    }
                }

                if (downloadCk) {
                    downloadChkErr.classList.add('alternate-link-list-download__error-field--hidden');
                } else {
                    downloadChkErr.classList.remove('alternate-link-list-download__error-field--hidden');
                }

                if (downloadCk) {
                    finalCk = true;
                }

                if (finalCk !== false) {
                    const siteName = 'alternate-link-list-download';
                    const url = '/bin/microsites/alternate-link-list/download';
                    const data = {
                        siteName,
                        checkeddownload,
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success !== 'false') {
                            const params = typeof data === 'string' ? data : Object.keys(data).map(
                                k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`
                            ).join('&');
                            this.postAjax.open('POST', url, true);
                            this.postAjax.responseType = 'arraybuffer';
                            this.postAjax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                            this.postAjax.onreadystatechange = () => {
                                if (this.postAjax.readyState === 4 &&
                                    this.postAjax.status === 200) {
                                    let filename = '';
                                    const arrayBuffer = this.postAjax.response;
                                    const byteArray = new Uint8Array(arrayBuffer);
                                    let blob = new Blob([byteArray], { type: 'text/zip' });
                                    if (blob.size > 1) {
                                        if (!document.documentMode && !/Edge/.test(navigator.userAgent)) {
                                            const type = this.postAjax.getResponseHeader('Content-Type');
                                            const disposition = this.postAjax.getResponseHeader('Content-Disposition');
                                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                                const matches = filenameRegex.exec(disposition);
                                                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                                            }
                                            if (typeof File === 'function') {
                                                blob = new File([blob], filename, { type });
                                            }
                                        }
                                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                            window.navigator.msSaveBlob(blob, filename);
                                        } else {
                                            const URL = window.URL || window.webkitURL;
                                            const downloadUrl = URL.createObjectURL(blob);
                                            if (filename) {
                                            // use HTML5 a[download] attribute to specify filename
                                                const a = document.createElement('a');

                                            // safari doesn't support this yet
                                                if (typeof a.download === 'undefined') {
                                                    window.location = downloadUrl;
                                                } else {
                                                    a.href = downloadUrl;
                                                    a.download = filename;
                                                    document.body.appendChild(a);
                                                    a.click();
                                                }
                                            } else {
                                                window.open(downloadUrl, '_blank');
                                            }
                                        }
                                    } else {
                                        this.AlternateLinkListDownloadUtil.displayPopUp('No assets available to download');
                                    }
                                }
                                if (this.postAjax.readyState === 4 &&
                                           this.postAjax.status !== 200) {
                                    if (downloadChkErr.classList.contains('alternate-link-list-download__error-field--hidden')) {
                                        this.AlternateLinkListDownloadUtil.displayPopUp('No assets available to download');
                                    }
                                }
                            };
                            this.postAjax.send(params);
                        }
                    });
                }
            }
        });
    }
}
