/**
 * Template for Activate/ Deactivate Form
 * Uses ES6 template literal to composite the markup for a Activate/ Deactivate Form container
 * @param buttonLabel {String} lavel of button.
 */
import { html } from 'utils';

export default buttonLabel => html`
<form id="activate-deactivate">
    <label for="comment">Comment *</label>
    <textarea class="activate-deactivate__input-field" id="comment" type="text" name="comment" value="" required ></textarea>
    <div class="form-submit__container">
        <button class="button button_primary" id="activateDeactivateBtn">${buttonLabel.replace(buttonLabel.charAt(0), buttonLabel.charAt(0).toUpperCase())}</button>
    </div>
    <p class="form__message-container hidden form__message--error"></p>
</form>
`.trim();
