import React, { Component } from 'react';

import getAllRequestedOrderListing, { changeOrderStatus } from '../api/orderRequestListingAPI';
import OrderRequestCard from '../components/OrderRequestCard';
import config, { content } from '../config';
import ErrorMessageModal from '../components/ErrorMessageModal';


const TAB1 = 1;
const TAB2 = 2;
const TAB3 = 3;
const STATUS = 'awaiting-dealer-acceptance';
const DEALER_REJECTED = 'dealer-rejected';

class OrderRequestListingComponent extends Component {
   
    constructor(properties) {
        super(properties);
        this.state = {
            isShowLoader: true,
            ordersList: [],
            ordersListtab2:[],
            ordersListtab3:[],
            hasError: false,
            activeTab: TAB1
        };

        this.handleOrderStatus = this.handleOrderStatus.bind(this);
        this.fetchAllOrdersList = this.fetchAllOrdersList.bind(this);
        this.filterSortResponse = this.filterSortResponse.bind(this);
        this.filterSortResponsetab2 = this.filterSortResponsetab2.bind(this);
        this.filterSortResponsetab3 = this.filterSortResponsetab3.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount() {
        this.fetchAllOrdersList();
    }

    fetchAllOrdersList() {
        this.setState({ isShowLoader: true });
        try {
            getAllRequestedOrderListing()
                .then((response) => {
                    if (Array.isArray(response)) {
                        const filteredResponse = this.filterSortResponse(response);
                        const filteredResponsetab2 = this.filterSortResponsetab2(response);
                        const filteredResponsetab3 = this.filterSortResponsetab3(response)
                        this.setState({
                            ordersList: filteredResponse,
                            ordersListtab2:filteredResponsetab2,
                            ordersListtab3:filteredResponsetab3,
                            isShowLoader: false
                        });
                        
                    } else {
                        this.setState({
                            hasError: true,
                            isShowLoader: false
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        hasError: true,
                        isShowLoader: false
                    });
                });
        } catch (e) {
            console.log('fetchAllOrdersList_function error:', e);
            this.setState({ hasError: true, isShowLoader: false });
        }
    }

    filterSortResponse(response) {
        const filteredData = response.filter(
            (item) => item.status === STATUS
        );
        filteredData.sort((a, b) => {
            const timestampA = new Date(a.orderDetails.creationDate);
            const timestampB = new Date(b.orderDetails.creationDate);
            return timestampB - timestampA;
        });
        return filteredData;
    }
    filterSortResponsetab2(response) {
        const filteredData = response.filter(
            (item) => item.status !== STATUS && item.status !== DEALER_REJECTED
        );
        filteredData.sort((a, b) => {
            const timestampA = new Date(a.orderDetails.creationDate);
            const timestampB = new Date(b.orderDetails.creationDate);
            return timestampB - timestampA;
        });
        return filteredData;
    }

    filterSortResponsetab3(response) {
        const filteredData = response.filter(
            (item) => item.status === DEALER_REJECTED
            );
        filteredData.sort((a, b) => {
            const timestampA = new Date(a.orderDetails.creationDate);
            const timestampB = new Date(b.orderDetails.creationDate);
            return timestampB - timestampA;
        });
        return filteredData;
    }

    handleOrderStatus(dealerCode, orderRequestId, status, productionDateValue = null) {
        this.setState({ isShowLoader: true, hasError: false });

        return changeOrderStatus({ dealerCode, orderRequestId, status, productionDateValue })
            .then((response) => {
                if (response?.callSuccess) {
                    this.fetchAllOrdersList();
                } else {
                    this.setState({
                        hasError: true,
                        isShowLoader: false
                    });
                }
            })
            .catch((err) => {
                console.log("Error found: ", err)
                this.setState({
                    hasError: true,
                    isShowLoader: false
                });
            });
    }

    toggleTab(tabNumber) {
        this.setState({
            activeTab: tabNumber
        });
    }


    render() {
        const {
            config: {
                title,
                subtitle
            }
        } = this.props;
        const { isShowLoader, hasError, ordersList,ordersListtab2,ordersListtab3,activeTab } = this.state;
        return (
            <div className="order-request-listing wrapper">
                <h1 className="title-hero__title order-request-listing__title">{title}</h1>
                <div
                    className='title-hero__sub-heading order-request-listing__sub-heading'
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
                <div className='tabs-wrapper'>
                        <div className={activeTab === TAB1 ? 'tabs active-tab' : 'tabs'} onClick={() => this.toggleTab(TAB1)}>{content.pending}</div>
                        <div className={activeTab === TAB2 ? 'tabs active-tab' : 'tabs'} onClick={() => this.toggleTab(TAB2)}>{content.accepted}</div>
                        <div className={activeTab === TAB3 ? 'tabs active-tab' : 'tabs'} onClick={() => this.toggleTab(TAB3)}>{content.rejected}</div>
                    </div>
                <div className='tabs-content'></div>
                {isShowLoader ? (
                    <div
                        className="loading-spinner
                        loading-spinner--large
                        loading-spinner--inline order-request-listing__loadspinner-center"
                    />
                )
                    : <div className='order-request-listing__cards'>   
                          
                            {this.state.activeTab == TAB1 ? (
                                 ordersList.length == 0 ? (
                                   
                                    <div className="order-request-listing__no-item">
                                        <p>
                                            {content.noItemFound}
                                        </p>
                                    </div>
                                    
                                ):(
                                    ordersList.map((order, index) => (
                                        <OrderRequestCard
                                            key={order.requestId}
                                            {...order}
                                            buildIndex={index}
                                            changeOrderStatus={this.handleOrderStatus}
                                            handleOrderUri={config.featureConfig.byoBuildPageUri}
        
                                        />
                                        
                                        
                                    ))
                                )
                             ):this.state.activeTab == TAB2 ? (
                                ordersListtab2.length == 0 ? (
                                    
                                    <div className="order-request-listing__no-item">
                                        <p>
                                            {content.noItemFound}
                                        </p>
                                    </div>
                                ):(
                                    ordersListtab2.map((order, index) => (
                                        <OrderRequestCard
                                            key={order.requestId}
                                            {...order}
                                            buildIndex={index}
                                            changeOrderStatus={this.handleOrderStatus}
                                            handleOrderUri={config.featureConfig.byoBuildPageUri}
        
                                        />
                                        
                                        
                                    ))
                                )
                                ):(
                                    ordersListtab3.length == 0 ? (
                                    
                                        <div className="order-request-listing__no-item">
                                            <p>
                                                {content.noItemFound}
                                            </p>
                                        </div>
                                    ):(
                                        ordersListtab3.map((order, index) => (
                                            <OrderRequestCard
                                                key={order.requestId}
                                                {...order}
                                                buildIndex={index}
                                                changeOrderStatus={this.handleOrderStatus}
                                                handleOrderUri={config.featureConfig.byoBuildPageUri}
            
                                            />
                                            
                                            
                                        ))
                                    )
                                )
                            }
                    </div>
                }
                
             
                 {hasError && (
                    <div>
                        <ErrorMessageModal />
                       
                    </div>
                )
                }  
            </div>
        );
    }
}

export default OrderRequestListingComponent;
