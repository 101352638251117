// Partial dependencies
import { Observable } from 'partials/observer';

/**
 * @property defaultState
 * @description The initial state of a LocationSearchModel
 * @type {{locations: null}}
 */
const defaultState = {
    searchLocation: null,
    searchByType: null,
    searchString: null,
    preferredDealer: null,
    currentStep: 1
};

/**
 * @class DealerLocatorModel
 * @description A model for managing the state of the DealerLocator app
 */
export default class DealerLocatorModel extends Observable {
    /**
     * @constructor
     * @description Sets the initial state of the model
     * @param initialState {Object} Initial state to set
     */
    constructor(initialState) {
        initialState = Object.assign(defaultState, initialState);
        super(initialState);
    }

    /**
     * @method setSearchByType
     * @param searchByType {String} Search by type value. Possible values: zip, place
     */
    setSearchByType(searchByType) {
        this.state.searchByType = searchByType;
    }

    /**
     * @method setSearchLocation
     * @description Sets the searchLocation to the model
     * @param searchLocation {String} Postcode location to search dealer from
     */
    setSearchLocation(searchLocation) {
        this.state.searchLocation = searchLocation ? searchLocation.trim() : searchLocation;
    }

    /**
     * @method setSearchString
     * @description Sets the searchString to the model. This attribute is used to populate the
     * searched string in the dealer results template, instead of having to look up using the
     * searchLocation field.
     * @
     */
    setSearchString(searchString) {
        this.state.searchString = searchString;
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer in the model
     */
    setPreferredDealer(dealer) {
        this.state.preferredDealer = dealer;
    }

    /**
     * @method setCurrentStep
     * @description Sets the search step
     * @param step {Number} The current step of the preferred dealer search
     */
    setCurrentStep(step) {
        this.state.currentStep = step;
    }
}

