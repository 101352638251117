// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    noop,
    renderer
} from 'utils';

// Local dependencies
import localListTemplate from './../templates/locationListTemplate';
import locationOptionTemplate from '../templates/locationOptionTemplate';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the LocationList view
 * @type {{LOCATION: string, LOCATION_LIST: string, ARIA_HIDDEN: string}}
 */
const ATTRIBUTES = {
    LOCATION: 'data-location',
    LOCATION_LIST: 'data-location-list',
    ARIA_HIDDEN: 'aria-hidden'
};

/**
 * @class LocationList
 * @description View for managing the state and view logic of searching for a
 * location view a search input and type-ahead
 */
export default class LocationList {
    /**
     * @constructor
     * @description On instantiation sets its properties, method alias and
     * initializes the view by caching its DOM element and attaching events
     * @param listBoxId {String} Id for the element with role="listbox"
     * @param onSelectCallback {Function} Callback method to call when a location is selected
     */
    constructor(onSelectCallback = noop, listBoxId = '') {
        this.element = localListTemplate(listBoxId)({ getNode: true });
        this.onSelectCallback = onSelectCallback;
        this.locationList = null; // ul
        this.locationElms = []; // array of location option list items
        this.locations = null; // locations from model

        // method aliases
        this.onSelect = this.onSelect.bind(this);

        this.cacheDOM();
    }

    /**
     * @method cacheDOM
     * @description Caches a collection of location elements from this.element
     */
    cacheDOM() {
        this.locationList = this.element.querySelector(`[${ATTRIBUTES.LOCATION_LIST}]`);
    }

    /**
     * @method attachEvents
     * @description Iterates the location elements and applies an event listener
     * for when a location is clicked to call `onSelect` with the selected location
     */
    attachEvents() {
        this.locationElms.forEach((locationElm, index) => {
            locationElm.addEventListener(
                EVENTS.CLICK, this.onSelect.bind(this, this.locations[index])
            );
        });
    }

    /**
     * @method detachEvents
     * @description Iterates the location elements and removes the event listener
     * for when a location is clicked
     */
    detachEvents() {
        this.locationElms.forEach((locationElm) => {
            locationElm.removeEventListener(
                EVENTS.CLICK, this.onSelect
            );
        });
    }

    /**
     * @method destroy
     * @description Detaches all events, clears the location and removes the element node
     */
    destroy() {
        this.detachEvents();
        this.locations = null;
        this.element.remove();
    }

    /**
     * @method displayLocations
     * @description Renders a new set of locations and applies the aria-hidden="false" attribute
     * @param locations {Array} Collection of location objects
     */
    displayLocations(locations = null) {
        this.locations = locations;

        this.locations.forEach((location) => {
            const cityName = location.terms[0].value;
            const stateId = location.terms[1].value;
            const option = locationOptionTemplate(location.place_id,
                cityName, stateId)({ getNode: true });
            this.locationElms.push(option);
            renderer.append(option, this.locationList);
        });

        this.element.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'false');
        this.attachEvents();
    }

    /**
     * @method hideLocations
     * @description Removes the locations list items and applies the aria-hidden="true" attribute
     */
    hideLocations() {
        this.detachEvents();
        this.locations = null;
        this.locationElms.forEach((locationElm) => {
            locationElm.remove();
        });
        this.locationElms = [];
        this.element.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'true');
    }

    /**
     * @method onSelect
     * @description Callback method for when a location is selected
     * to apply the `onSelectCallback` with the selected location
     * @param location {Object} The selected location object
     */
    onSelect(location) {
        this.onSelectCallback(location);
    }

    /**
     * @method render
     * @description Fetches and return the LocationList element
     * @return {Element}
     */
    render() {
        return this.element;
    }
}

