
/**
 * API responsible for loading Google API's
 */

/**
 * @property
 * @description Stores the maps api loader promise that resolves once the api has loaded
 */
let mapsAPILoader;

function loadScript(path) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = path;
    script.async = true;
    document.head.appendChild(script);
}

/**
 * @method loadGoogleMaps
 * @description Loads Google Maps API asynchronously
 * @returns {Promise} Promise that resolves when api has loaded
 */
function loadGoogleMaps(lang = 'en') {
    const siteName = document.getElementById('site-name').value;
    const url = '/bin/microsites/googleMapKey';
    this.googleMapApiKey = '';
    this.googleMapChannel = '';
    const formData = new FormData();
    formData.set('siteName', siteName);

    return fetch(url, {
        body: formData,
        method: 'POST',
    }).then(response => response.json()).then((success) => {
        if (success !== 'false') {
            console.log(`Google Map API Key fetched ${JSON.stringify(success)}`);
            this.googleMapApiKey = success.apiKey;
            this.googleMapChannel = success.apiChannel;
        } else {
            console.log('Google Map API Key not fetched');
        }

        const language = document.getElementById('language') || lang;

        if (!mapsAPILoader) {
            mapsAPILoader = new Promise((resolve) => {
                window.mapApiReady = () => resolve();
                const path = `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&v=3&key=${this.googleMapApiKey}&channel=${this.googleMapChannel}&language=${language}&callback=mapApiReady`;
                loadScript(path);
            });
        }
        return mapsAPILoader;
    })
    .catch((error) => {
        console.error('There was error in fetching details : ', error);
    });
}

/**
 * Export public api methods
 */
export default {
    loadGoogleMaps
};

