import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import MessageBox from 'modules/fleet-info/MessageBox';
import Table from 'modules/Table';

const CLASS = {
    HIDDEN: 'hidden',
    DISABLED: 'disabled',
    FORM_MESSAGE_CONTAINER: 'form__message-container',
    FORM_MESSAGE: 'form__message',
    OVERLAY_HIDDEN: 'overlay--hidden',
    FORM_MESSAGE_ERROR: 'form__message--error',
    FORM_MESSAGE_SUCCESS: 'form__message--success',
    DOWNLOAD_REPORT_BTN: 'download-report-btn'
};

const StringConstants = {
    ERROR: 'error',
    NO_RECORD_FOUND: 'No Record Found',
    ERROR_OCCURED: 'An error occured'
};

const ID = {
    CORPORATE_SALES_REPORT: 'corporateSalesReport',
    CORPORATE_SALES_REPORT_SUBMIT_BTN: 'corporateSalesReportSubmitBtn',
    TABLE_CONTAINER: 'table-container',
    PAGE_LOADER: 'page-loader',
};

const Url = {
    corporateSalesReport: '/bin/microsites/fleet-info/customers/sales-report',
    downloadReport: '/bin/microsites/fleet-info/download/sales-report'
};

export default class CorporateSalesReport {
    constructor(element) {
        this.element = element;
        this.formElement = null;
        this.formSubmitBtn = null;
        this.tableContainer = null;
        this.accountStatus = 'Y';
        this.currentDate = null;
        this.init();
    }

    init() {
        this.loadModules();
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.formElement = document.querySelector(`#${ID.CORPORATE_SALES_REPORT}`);
        this.formSubmitBtn = this.formElement.querySelector(`#${ID.CORPORATE_SALES_REPORT_SUBMIT_BTN}`);
        this.messageContainer = document.querySelector(`.${CLASS.FORM_MESSAGE_CONTAINER}`);
        this.pageLoader = document.querySelector(`#${ID.PAGE_LOADER}`);
        this.tableContainer = document.querySelector(`#${ID.TABLE_CONTAINER}`);
        this.downloadReportBtns = document.querySelectorAll(`.${CLASS.DOWNLOAD_REPORT_BTN}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
    }

    attachEvents() {
        document.onreadystatechange = () => {
            if (document.readyState === 'complete' && this.formSubmitBtn) {
                this.formSubmitBtn.removeAttribute('disabled');
            }
        };

        this.formSubmitBtn.addEventListener(EVENTS.CLICK, () => {
            event.preventDefault();
            if (!(this.formSubmitBtn.getAttribute('disabled'))) {
                this.accountStatus = this.formElement.querySelector('input[type=radio]:checked').value;
                this.pageLoader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                this.messageContainer.classList.add(`${CLASS.HIDDEN}`);
                this.getTableData();
            }
        });

        [].forEach.call(this.downloadReportBtns, (btn) => {
            btn.addEventListener(EVENTS.CLICK, () => {
                this.pageLoader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
                let url = null;
                if (btn.id === 'active') {
                    url = `${Url.downloadReport}?accountStatus=Y`;
                } else {
                    url = `${Url.downloadReport}?accountStatus=N`;
                }
                this.Ajax.getFileResponse(url, (data, fileName) => {
                    if (data.error) {
                        console.log('Data Error :::', data.error);
                    } else {
                        this.downloadReport(data, fileName);
                    }
                });
            });
        });
    }

    /*
        Function : created an object url and downloaded the report.
        @param blob : BLOB object that is to be downloaded
               fileName : name of the file that is going to be downloaded
    */
    downloadReport(blob, fileName = 'can-report.xls') {
        if (blob) {
            const reportUrl = URL.createObjectURL(blob);
            const downloadLink = window.document.createElement('a');
            downloadLink.href = reportUrl;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        this.pageLoader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
    }

    // Function to get table data to be displayed in table.
    getTableData() {
        const url = `${Url.corporateSalesReport}?accountStatus=${this.accountStatus}`;
        this.Ajax.readTextFile(url, (success) => {
            const data = JSON.parse(success);
            if (success && !(data.error)) {
                if (data.length) {
                    this.Table = new Table(this.element, data);
                } else {
                    this.tableContainer.classList.add(`${CLASS.HIDDEN}`);
                    this.MessageBox.showMessage(`${StringConstants.ERROR}`, StringConstants.NO_RECORD_FOUND);
                }
            } else {
                this.tableContainer.classList.add(`${CLASS.HIDDEN}`);
                this.MessageBox.showMessage(`${StringConstants.ERROR}`, data.error);
            }

            this.pageLoader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
        });
    }

    /*
        Function : To display message when no records found.

        @params
             message - Text to be displayed.(default value passed)
    */
    showMessage(message = StringConstants.ERROR_OCCURED, type) {
        this.messageContainer.querySelector(`.${CLASS.FORM_MESSAGE}`).innerHTML = message;
        this.messageContainer.classList.remove(`${CLASS.HIDDEN}`);

        if (type === `${StringConstants.SUCCESS}`) {
            this.messageContainer.classList.add(`${CLASS.FORM_MESSAGE_SUCCESS}`);
        } else if (type === `${StringConstants.ERROR}`) {
            this.messageContainer.classList.add(`${CLASS.FORM_MESSAGE_ERROR}`);
        }
    }
}
