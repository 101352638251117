import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import BrcFormUtil from 'modules/BrcFormUtil';

const ID = {
    SUBMITBUTTON: 'brcFormSubmit',
    BRC_FORM_SELECTION: 'formatSelection',
    BRC_FORM: 'brc-form',
    SITE_NAME: 'site-name',
    BRC_FILE: 'brc-file',
    BRC_OPTIONAL_FILE: 'brc-optional-file',
    EMPLOYEE_NAME: 'employeeName',
    CONTACT_INFO: 'contactInfo',
    CUSTOMER_BUSINESS_NAME: 'customerBusinessName',
    CAN: 'canNumber',
    FLEET_MANAGEMENT_COMPANY: 'fleetMangementCompany',
    DEALER_NAME: 'dealerName',
    DEALER_CODE: 'dealerCode',
    DELAER_CONTACT_NAME: 'dealerContactName',
    DELAER_CONTACT_EMAIL: 'dealerContactEmail',
    MODEL: 'model',
    MODEL_YEAR: 'modelYear',
    QUANTITY: 'quantity',
    REQUESTED_PRODUCTION_DATE: 'requestedProductionDate',
    MODEL_COMMENTS: 'modelComments',
    COMMENTS: 'comments',
    ADD_MODEL_FIELD: 'addModelField',
    ADD_MODEL_BUTTON: 'addModelBtn',
    RESET_MODEL_BUTTON: 'resetModelBtn',
    FLEET_CHECKBOX: 'fleetCheckbox',
};

export default class BrcForm {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        this.loadCaptcha();
        this.attachEvents();
    }

    loadModules() {
        this.BrcFormUtil = new BrcFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'nsmicrosites';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.ADD_MODEL_BUTTON}`)) {
                e.preventDefault();
                const addModelField = document.getElementById(`${ID.ADD_MODEL_FIELD}`);
                addModelField.innerHTML += `<div class="contact-form__group">
                 <table>
                     <tr>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelYear" id="modelYear" class="contact-form-data__control" placeholder="Model Year*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model year</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="model" id="model" class="contact-form-data__control" placeholder="Model*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="quantity" id="quantity" class="contact-form-data__control" placeholder="Quantity*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid quantity</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="date" name="requestedProductionDate" id="requestedProductionDate" class="contact-form-data__control" placeholder="Date*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid requested production date</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelComments" id="modelComments" class="contact-form-data__control" required ="false" placeholder="Comments" value="">
                        </td>
                     </tr>
                 </table>
                 </div>`;
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.RESET_MODEL_BUTTON}`)) {
                e.preventDefault();
                const addModelField = document.getElementById(`${ID.ADD_MODEL_FIELD}`);
                addModelField.innerHTML = '';
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.FLEET_CHECKBOX}`)) {
                e.preventDefault();
                const fleetCheckbox = document.getElementById(`${ID.FLEET_CHECKBOX}`);
                const dealerName = document.getElementById(`${ID.DEALER_NAME}`);
                const dealerCode = document.getElementById(`${ID.DEALER_CODE}`);
                const dealerContactName = document.getElementById(`${ID.DELAER_CONTACT_NAME}`);
                const dealerContactEmail = document.getElementById(`${ID.DELAER_CONTACT_EMAIL}`);
                if (fleetCheckbox.checked) {
                    console.log('checked');
                    dealerName.setAttribute('required', 'false');
                    dealerCode.setAttribute('required', 'false');
                    dealerContactName.setAttribute('required', 'false');
                    dealerContactEmail.setAttribute('required', 'false');
                    dealerName.value = 'NA';
                    dealerCode.value = 'NA';
                    dealerContactName.value = 'NA';
                    dealerContactEmail.value = 'NA';
                    dealerName.disabled = true;
                    dealerCode.disabled = true;
                    dealerContactName.disabled = true;
                    dealerContactEmail.disabled = true;
                } else {
                    console.log('unchecked');
                    dealerName.removeAttribute('required');
                    dealerCode.removeAttribute('required');
                    dealerContactName.removeAttribute('required');
                    dealerContactEmail.removeAttribute('required');
                    dealerName.disabled = false;
                    dealerCode.disabled = false;
                    dealerContactName.disabled = false;
                    dealerContactEmail.disabled = false;
                    dealerName.value = '';
                    dealerCode.value = '';
                    dealerContactName.value = '';
                    dealerContactEmail.value = '';
                }
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.BRC_FORM_SELECTION}`)) {
                e.preventDefault();

                const formElemet = document.getElementById(`${ID.BRC_FORM}`);
                const selection = document.getElementById(`${ID.BRC_FORM_SELECTION}`);
                if (selection.value === 'brc-fleet-form') {
                    formElemet.innerHTML = `<fieldset>
                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Requestor</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="employeeName" id="employeeName" class="contact-form-data__control" placeholder="Employee Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid employee name</p>
                        </td>
                        <td>
                             <input type="text" name="contactInfo" id="contactInfo" class="contact-form-data__control" placeholder="Contact Info (phone or email)*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid contact info</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Customer Information</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="canNumber" id="canNumber" class="contact-form-data__control" placeholder="CAN Number*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid CAN number</p>
                        </td>
                        <td>
                             <input type="text" name="customerBusinessName" id="customerBusinessName" class="contact-form-data__control" placeholder="Customer Business Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid customer business name</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr>   
                        <td style="padding-right: 2%;width: 50%;">
                             <input type="text" name="fleetMangementCompany" id="fleetMangementCompany" class="contact-form-data__control" placeholder="Fleet Management Company Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid fleet company name</p>
                        </td>
                        <td></td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Dealer</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="dealerName" id="dealerName" class="contact-form-data__control" placeholder="Dealer Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer name</p>
                        </td>
                        <td>
                            <input type="text" name="dealerCode" id="dealerCode" class="contact-form-data__control" placeholder="Dealer Code*" value="">
                            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer code</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="dealerContactName" id="dealerContactName" class="contact-form-data__control" placeholder="Dealer Contact Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer contact name</p>
                        </td>
                        <td>
                            <input type="text" name="dealerContactEmail" id="dealerContactEmail" class="contact-form-data__control" placeholder="Dealer Contact Email*" value="">
                            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer contact email</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table>
                     <tr><label class="contact-form-data__label">Order</label></tr>
                     <tr>
                          <td><b>Model Year</b></td><td><b>Model</b></td><td><b>Quantity</b></td><td><b>Requested Production Date</b></td><td><b>Comments</b></td>
                     </tr>
                     <tr>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelYear" id="modelYear" class="contact-form-data__control" placeholder="Model Year*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model year</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="model" id="model" class="contact-form-data__control" placeholder="Model*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="quantity" id="quantity" class="contact-form-data__control" placeholder="Quantity*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid quantity</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="date" name="requestedProductionDate" id="requestedProductionDate" class="contact-form-data__control" placeholder="Date*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid requested production date</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelComments" id="modelComments" class="contact-form-data__control" required ="false" placeholder="Comments" value="">
                        </td>
                     </tr>
                 </table>
                 </div>
                 <div id="addModelField"></div>
                 <div class="contact-form-data__group">
                        <button type="button" id="addModelBtn" class="button button_primary">Add Model</button>
                        <button type="button" id="resetModelBtn" class="button button_primary">Reset</button>
                 </div>
                 <div class="contact-form-data__group">
                        <label class="contact-form-data__label">Comments</label>
                        <input type="text" name="comments" id="comments" class="contact-form-data__control" required ="false" placeholder="Please enter comments, if any." value="">
                 </div>
                 <div class="contact-form-data__group">        
                        <div id="google-captcha"></div>
                        <div id="g-recaptcha-error"></div>
                 </div>
                 <div class="contact-form-data__group">
                        <button type="submit" id="brcFormSubmit" class="button button_primary" value="Submit">Submit</button>
                        <button type="reset" class="button button_primary">Reset</button>
                 </div>
                 </fieldset>`;
                } else if (selection.value === 'brc-rental-form') {
                    formElemet.innerHTML = `<fieldset>
                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Requestor</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="employeeName" id="employeeName" class="contact-form-data__control" placeholder="Employee Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid employee name</p>
                        </td>
                        <td>
                             <input type="text" name="contactInfo" id="contactInfo" class="contact-form-data__control" placeholder="Contact Info (phone or email)*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid contact info</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Customer Information</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="canNumber" id="canNumber" class="contact-form-data__control" placeholder="CAN Number*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid CAN number</p>
                        </td>
                        <td>
                             <input type="text" name="customerBusinessName" id="customerBusinessName" class="contact-form-data__control" placeholder="Customer Business Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid customer business name</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                        <label class="contact-form-data__label">Order</label>
                        <label><h6>Please Attach Vehicle Specifications (PDF or Excel)*</h6></label>
                        <input class="contact-form-pdf-data" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="brc-file" type="file" name="brc-file"/>
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please select a file</p>
                 </div>
                 <div class="contact-form-data__group">
                        <input class="contact-form-pdf-data" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required="false" id="brc-optional-file" type="file" name="brc-optional-file"/>
                 </div>
                 <div class="contact-form-data__group">
                        <label class="contact-form-data__label">Comments</label>
                        <input type="text" name="comments" id="comments" class="contact-form-data__control" required ="false" placeholder="Please enter comments, if any." value="">
                 </div>
                 <div class="contact-form-data__group">        
                        <div id="google-captcha"></div>
                        <div id="g-recaptcha-error"></div>
                 </div>
                 <div class="contact-form-data__group">
                        <button type="submit" id="brcFormSubmit" class="button button_primary" value="Submit">Submit</button>
                        <button type="reset" class="button button_primary">Reset</button>
                 </div>
                 </fieldset>`;
                } else if (selection.value === 'brc-corporate-sales-form') {
                    formElemet.innerHTML = `<fieldset>
                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Requestor</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="employeeName" id="employeeName" class="contact-form-data__control" placeholder="Employee Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid employee name</p>
                        </td>
                        <td>
                             <input type="text" name="contactInfo" id="contactInfo" class="contact-form-data__control" placeholder="Contact Info (phone or email)*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid contact info</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Customer Information</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="canNumber" id="canNumber" class="contact-form-data__control" placeholder="CAN Number*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid CAN number</p>
                        </td>
                        <td>
                             <input type="text" name="customerBusinessName" id="customerBusinessName" class="contact-form-data__control" placeholder="Customer Business Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid customer business name</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                             <input type="checkbox" id="fleetCheckbox" name="fleetCheckbox" value="Regional backfill vehicles requested">
                             <label for="fleetCheckbox"><b>Fleet request has been fulfilled; vehicles being requested to backfill inventory</b></label>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr><label class="contact-form-data__label">Dealer</label></tr>
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="dealerName" id="dealerName" class="contact-form-data__control" placeholder="Dealer Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer name</p>
                        </td>
                        <td>
                            <input type="text" name="dealerCode" id="dealerCode" class="contact-form-data__control" placeholder="Dealer Code*" value="">
                            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer code</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table style="width: 100%;">
                     <tr>   
                        <td style="padding-right: 2%;">
                             <input type="text" name="dealerContactName" id="dealerContactName" class="contact-form-data__control" placeholder="Dealer Contact Name*" value="">
                             <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer contact name</p>
                        </td>
                        <td>
                            <input type="text" name="dealerContactEmail" id="dealerContactEmail" class="contact-form-data__control" placeholder="Dealer Contact Email*" value="">
                            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid dealer contact email</p>
                        </td>
                     </tr>
                 </table>
                 </div>

                 <div class="contact-form-data__group">
                 <table>
                     <tr><label class="contact-form-data__label">Order</label></tr>
                     <tr>
                          <td><b>Model Year</b></td><td><b>Model</b></td><td><b>Quantity</b></td><td><b>Requested Production Date</b></td><td><b>Comments</b></td>
                     </tr>
                     <tr>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelYear" id="modelYear" class="contact-form-data__control" placeholder="Model Year*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model year</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="model" id="model" class="contact-form-data__control" placeholder="Model*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid model</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="quantity" id="quantity" class="contact-form-data__control" placeholder="Quantity*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid quantity</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="date" name="requestedProductionDate" id="requestedProductionDate" class="contact-form-data__control" placeholder="Date*" value="">
                        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter valid requested production date</p>
                        </td>
                        <td style="padding-right: 2%;">
                        <input type="text" name="modelComments" id="modelComments" class="contact-form-data__control" required ="false" placeholder="Comments" value="">
                        </td>
                     </tr>
                 </table>
                 </div>
                 <div id="addModelField"></div>
                 <div class="contact-form-data__group">
                        <button type="button" id="addModelBtn" class="button button_primary">Add Model</button>
                        <button type="button" id="resetModelBtn" class="button button_primary">Reset</button>
                 </div>
                 <div class="contact-form-data__group">
                        <label class="contact-form-data__label">Comments</label>
                        <input type="text" name="comments" id="comments" class="contact-form-data__control" required ="false" placeholder="Please enter comments, if any." value="">
                 </div>
                 <div class="contact-form-data__group">        
                        <div id="google-captcha"></div>
                        <div id="g-recaptcha-error"></div>
                 </div>
                 <div class="contact-form-data__group">
                        <button type="submit" id="brcFormSubmit" class="button button_primary" value="Submit">Submit</button>
                        <button type="reset" class="button button_primary">Reset</button>
                 </div>
                 </fieldset>`;
                }
                this.loadCaptcha();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (this.BrcFormUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.BRC_FORM}`);
                    const inputs = form.elements;

                    const selection = document.getElementById(`${ID.BRC_FORM_SELECTION}`).value;
                    const siteName = 'brc-form';
                    const employeeName = document.getElementById(`${ID.EMPLOYEE_NAME}`).value;
                    const contactInfo = document.getElementById(`${ID.CONTACT_INFO}`).value;
                    const customerBusinessName = document.getElementById(`${ID.CUSTOMER_BUSINESS_NAME}`).value;
                    const canNumber = document.getElementById(`${ID.CAN}`).value;
                    const comments = document.getElementById(`${ID.COMMENTS}`).value;


                    const url = '/bin/microsites/brc-form';


                    const fileData = new FormData();

                    fileData.set('siteName', siteName);
                    fileData.set('employeeName', employeeName);
                    fileData.set('contactInfo', contactInfo);
                    fileData.set('customerBusinessName', customerBusinessName);
                    fileData.set('canNumber', canNumber);
                    fileData.set('comments', comments);
                    fileData.set('captcha', captchaResponse);

                    if (selection === 'brc-rental-form') {
                        fileData.set('businessArea', 'Rental');
                        const file = document.getElementById(`${ID.BRC_FILE}`).files[0];
                        fileData.set('file', file);
                        const optionalFile = document.getElementById(`${ID.BRC_OPTIONAL_FILE}`).files[0];
                        fileData.set('optionalFile', optionalFile);
                    }

                    if (selection === 'brc-fleet-form') {
                        const fleetMangementCompany = document.getElementById(`${ID.FLEET_MANAGEMENT_COMPANY}`).value;
                        const dealerName = document.getElementById(`${ID.DEALER_NAME}`).value;
                        const dealerCode = document.getElementById(`${ID.DEALER_CODE}`).value;
                        const dealerContactName = document.getElementById(`${ID.DELAER_CONTACT_NAME}`).value;
                        const dealerContactEmail = document.getElementById(`${ID.DELAER_CONTACT_EMAIL}`).value;
                        const model = document.getElementsByName(`${ID.MODEL}`);
                        const modelYear = document.getElementsByName(`${ID.MODEL_YEAR}`);
                        const quantity = document.getElementsByName(`${ID.QUANTITY}`);
                        const requestedProductionDate = document.getElementsByName(`${ID.REQUESTED_PRODUCTION_DATE}`);
                        const modelComments = document.getElementsByName(`${ID.MODEL_COMMENTS}`);
                        const modelDL = [];
                        const modelYearDL = [];
                        const quantityDL = [];
                        const requestedProductionDateDL = [];
                        const modelCommentsDL = [];

                        for (let i = 0; i < model.length; i += 1) {
                            modelDL.push(model[i].value);
                        }

                        for (let i = 0; i < modelYear.length; i += 1) {
                            modelYearDL.push(modelYear[i].value);
                        }

                        for (let i = 0; i < quantity.length; i += 1) {
                            quantityDL.push(quantity[i].value);
                        }

                        for (let i = 0; i < requestedProductionDate.length; i += 1) {
                            requestedProductionDateDL.push(requestedProductionDate[i].value);
                        }

                        for (let i = 0; i < modelComments.length; i += 1) {
                            if (modelComments[i].value !== '') {
                                modelCommentsDL.push(modelComments[i].value);
                            } else {
                                modelCommentsDL.push('NA');
                            }
                        }
                        fileData.set('model', modelDL);
                        fileData.set('modelYear', modelYearDL);
                        fileData.set('quantity', quantityDL);
                        fileData.set('requestedProductionDate', requestedProductionDateDL);
                        fileData.set('modelComments', modelCommentsDL);
                        fileData.set('businessArea', 'Fleet Management Companies');
                        fileData.set('fleetMangementCompany', fleetMangementCompany);
                        fileData.set('dealerName', dealerName);
                        fileData.set('dealerCode', dealerCode);
                        fileData.set('dealerContactName', dealerContactName);
                        fileData.set('dealerContactEmail', dealerContactEmail);
                    }

                    if (selection === 'brc-corporate-sales-form') {
                        const fleetCheckbox = document.getElementById(`${ID.FLEET_CHECKBOX}`);
                        if (fleetCheckbox.checked) {
                            fileData.set('Regional backfill vehicles requested', 'Yes');
                        } else {
                            fileData.set('Regional backfill vehicles requested', 'No');
                        }
                        const dealerName = document.getElementById(`${ID.DEALER_NAME}`).value;
                        const dealerCode = document.getElementById(`${ID.DEALER_CODE}`).value;
                        const dealerContactName = document.getElementById(`${ID.DELAER_CONTACT_NAME}`).value;
                        const dealerContactEmail = document.getElementById(`${ID.DELAER_CONTACT_EMAIL}`).value;
                        const model = document.getElementsByName(`${ID.MODEL}`);
                        const modelYear = document.getElementsByName(`${ID.MODEL_YEAR}`);
                        const quantity = document.getElementsByName(`${ID.QUANTITY}`);
                        const requestedProductionDate = document.getElementsByName(`${ID.REQUESTED_PRODUCTION_DATE}`);
                        const modelComments = document.getElementsByName(`${ID.MODEL_COMMENTS}`);
                        const modelDL = [];
                        const modelYearDL = [];
                        const quantityDL = [];
                        const requestedProductionDateDL = [];
                        const modelCommentsDL = [];

                        for (let i = 0; i < model.length; i += 1) {
                            modelDL.push(model[i].value);
                        }

                        for (let i = 0; i < modelYear.length; i += 1) {
                            modelYearDL.push(modelYear[i].value);
                        }

                        for (let i = 0; i < quantity.length; i += 1) {
                            quantityDL.push(quantity[i].value);
                        }

                        for (let i = 0; i < requestedProductionDate.length; i += 1) {
                            requestedProductionDateDL.push(requestedProductionDate[i].value);
                        }

                        for (let i = 0; i < modelComments.length; i += 1) {
                            if (modelComments[i].value !== '') {
                                modelCommentsDL.push(modelComments[i].value);
                            } else {
                                modelCommentsDL.push('NA');
                            }
                        }
                        fileData.set('model', modelDL);
                        fileData.set('modelYear', modelYearDL);
                        fileData.set('quantity', quantityDL);
                        fileData.set('requestedProductionDate', requestedProductionDateDL);
                        fileData.set('modelComments', modelCommentsDL);
                        fileData.set('businessArea', 'Corporate Sales');
                        fileData.set('dealerName', dealerName);
                        fileData.set('dealerCode', dealerCode);
                        fileData.set('dealerContactName', dealerContactName);
                        fileData.set('dealerContactEmail', dealerContactEmail);
                    }
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.BrcFormUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.BrcFormUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.BRC_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.BRC_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.BrcFormUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.BrcFormUtil.validateAllFields();
                }
            }
        });
    }
}
