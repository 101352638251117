const ID = {
    WATERMARK: 'component-watermark',
    TEXT: 'data-text',
    FONT: 'data-font',
    SIZE: 'data-fontSize',
    COLOR: 'data-color',
    ORIENTATION: 'data-orientation',
    OPACITY: 'data-opacity',
    POSITION: 'data-position'
};

export default class CompWatermark {
    constructor(element) {
        this.element = element;
        this.applyWatermark();
    }

    applyWatermark() {
        this.watermarkDiv = this.element.getElementsByClassName(`${ID.WATERMARK}`)[0];
        const text = this.watermarkDiv.getAttribute('data-text');
        if (text !== '') {
            const ele = document.createElement('P');
            let color = this.watermarkDiv.getAttribute(`${ID.COLOR}`);
            if (color != null && color.startsWith('rgb')) {
                const c = color.replace(/[^\d,]*/g, '').split(',');
                color = this.convertRGBtoHex(parseInt(c[0], 10),
                    parseInt(c[1], 10), parseInt(c[2], 10));
            }
            ele.style.color = color;
            ele.style.opacity = this.watermarkDiv.getAttribute(`${ID.OPACITY}`);
            const degree = this.watermarkDiv.getAttribute(`${ID.ORIENTATION}`);
            ele.style.transform = `rotate(${degree}deg)`;
            ele.style.fontStyle = this.watermarkDiv.getAttribute(`${ID.FONT}`);
            ele.style.fontSize = `${this.watermarkDiv.getAttribute(`${ID.SIZE}`)}px`;
            ele.innerHTML = text;
            ele.style.position = 'absolute';
            this.watermarkDiv.appendChild(ele);
            const pos = this.watermarkDiv.getAttribute(`${ID.POSITION}`);
            if (pos === 'TOP_RIGHT' || pos === 'BOTTOM_RIGHT') {
                ele.style.left = ele.offsetLeft + this.element.offsetWidth - ele.offsetWidth;
            }
            if (pos === 'CENTER') {
                ele.style.left = ele.offsetLeft
                    + ((this.element.offsetWidth - ele.offsetWidth) / 2);
                ele.style.top = ele.offsetTop
                    + ((this.element.offsetHeight - ele.offsetHeight) / 2);
            }
            if (pos === 'BOTTOM_LEFT' || pos === 'BOTTOM_RIGHT') {
                ele.style.top = ele.offsetTop + this.element.offsetHeight - ele.offsetHeight;
            }
            if (ele.offsetLeft < this.element.offsetLeft) {
                ele.style.left = this.watermarkDiv.style.left;
            }
            if (ele.offsetTop < this.element.offsetTop) {
                ele.style.top = this.watermarkDiv.style.top;
            }
            if (ele.offsetWidth > this.element.offsetWidth) {
                ele.style.width = this.element.offsetWidth;
            }
        }
    }

    colorToHex(color) {
        this.hexadecimal = color.toString(16);
        return this.hexadecimal.length === 1 ? `0${this.hexadecimal}` : this.hexadecimal;
    }

    convertRGBtoHex(red, green, blue) {
        return `#${this.colorToHex(red)}${this.colorToHex(green)}${this.colorToHex(blue)}`;
    }
}
