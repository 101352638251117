import { ATTRIBUTES } from 'Constants';

const CONSTANTS = {

    // CLASSES
    ALPHABETICAL_SEARCH__INDEX__COLUMN: 'alphabetical-search__index__column',
    ALPHABETICAL_SEARCH__ROW: 'alphabetical-search__row',
    ALPHABETICAL_SEARCH__INDEX_DISABLED: 'alphabetical-search__index--disabled'
};

export default class AlphabeticalSearch {
    constructor(element) {
        this.element = element;
        this.init();
        this.indexList = null;
        this.rowList = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.indexList = this.element.getElementsByClassName(`${CONSTANTS.ALPHABETICAL_SEARCH__INDEX__COLUMN}`);
        this.rowList = this.element.getElementsByClassName(`${CONSTANTS.ALPHABETICAL_SEARCH__ROW}`);
    }

    attachEvents() {
        console.log(this);
        const rowListItems = this.rowList;
        const indexListItems = this.indexList;
        let targetChar = `${rowListItems[0].firstElementChild.innerHTML.charAt(0).toUpperCase()}`;
        let targets = targetChar;
        rowListItems[0].id = rowListItems[0].id.concat(`target${targetChar}`);
        [].forEach.call(rowListItems, (el) => {
            const firstChar = el.firstElementChild.innerHTML.charAt(0).toUpperCase();
            if (targetChar !== firstChar) {
                targetChar = firstChar;
                el.id = el.id.concat(`target${targetChar}`);
                targets += targetChar;
            }
        });
        this.initializeIndexReferences(indexListItems, targets);
    }

    initializeIndexReferences(indexList, targets) {
        console.log(this);
        [].forEach.call(indexList, (el) => {
            if (targets.indexOf(el.firstElementChild.innerHTML) > -1) {
                console.log(el.id);
                el.firstElementChild.setAttribute(ATTRIBUTES.HREF, '#'.concat(el.id.concat(`target${el.firstElementChild.innerHTML}`)));
            } else {
                const e = el.getElementsByTagName('a')[0];
                const d = document.createElement('span');
                d.innerHTML = e.innerHTML;
                e.parentNode.replaceChild(d, e);
                el.firstElementChild.classList.add(`${CONSTANTS.ALPHABETICAL_SEARCH__INDEX_DISABLED}`);
            }
        });
    }
}
