import { EVENTS, CLASSES } from 'Constants';

export default class AlertBox {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        console.log(this);
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.classList.contains(`${CLASSES.ALERTBOX__CLOSEICON}`)) {
                e.target.parentNode.parentNode.classList.add(`${CLASSES.ALERTBOX_CLOSE}`);
            }
        });
    }
}
