import { EVENTS } from 'Constants';

const ID = {
    BRC_SEARCH_BUTTON: 'searchbutton',
    SEARCH: 'search',
};

let prevtext = '';

export default class BrcSearch {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BRC_SEARCH_BUTTON}`)) {
                e.preventDefault();
                this.removehighlight();
                this.addhighlight();
            }
        });
    }

    removehighlight() {
        console.log(this);
        if (window.find && window.getSelection) {
            const sel = window.getSelection();
            document.designMode = 'on';
            sel.collapse(document.body, 0);
            while (window.find(prevtext)) {
                document.execCommand('HiliteColor', false, 'transparent');
                sel.collapseToEnd();
            }
            document.designMode = 'off';
            this.scroll();
            this.goTop(prevtext, 0);
        } else if (document.body.createTextRange) {
            const textRange = document.body.createTextRange();
            if (prevtext === '') {
                prevtext = '$';
            }
            while (textRange.findText(prevtext)) {
                textRange.execCommand('BackColor', false, 'transparent');
                textRange.collapse(false);
            }
            this.goTopIERemove();
        }
    }

    addhighlight() {
        console.log(this);
        if (window.find && window.getSelection) {
            const sel = window.getSelection();
            document.designMode = 'on';
            sel.collapse(document.body, 0);
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            while (window.find(searchtext)) {
                document.execCommand('HiliteColor', false, 'yellow');
                sel.collapseToEnd();
            }
            document.designMode = 'off';
            this.scroll();
            this.goTop(searchtext, 1);
        } else if (document.body.createTextRange) {
            const textRange = document.body.createTextRange();
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            while (textRange.findText(searchtext)) {
                textRange.execCommand('BackColor', false, 'yellow');
                textRange.collapse(false);
            }
            this.goTopIE();
        }
    }

    goTop(keyWords, findFirst) {
        console.log(this);
        if (findFirst) {
            window.find(keyWords, 0, 0, 1);
            window.find(keyWords, 0, 0, 1);
        }
    }

    goTopIE() {
        console.log(this);
        if (document.getSelection() && document.body.createTextRange) {
            const sel = document.getSelection();
            let textRange;
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            if (sel.type === 'Text') {
                textRange = sel.createRange();
                textRange.collapse(false);
            } else {
                textRange = document.body.createTextRange();
                textRange.collapse(true);
            }
            if (textRange.findText(searchtext)) {
                textRange.select();
            }
        }
    }

    goTopIERemove() {
        console.log(this);
        if (document.getSelection() && document.body.createTextRange) {
            const sel = document.getSelection();
            let textRange;
            if (prevtext === '') {
                prevtext = '$';
            }
            if (sel.type === 'Text') {
                textRange = sel.createRange();
                textRange.collapse(false);
            } else {
                textRange = document.body.createTextRange();
                textRange.collapse(true);
            }
            if (textRange.findText(prevtext)) {
                textRange.select();
            }
        }
    }

    scroll() {
        console.log(this);
        document.getElementById('content').scrollIntoView({
            behavior: 'smooth'
        });
    }
}
