import { EVENTS } from 'Constants';
import { accordionParser } from 'partials/accordion';

const CLASSES = {
    CLICK_TO_EDIT: 'click-to-edit'
};

export default class ModelSpecification {
    constructor(element) {
        this.element = element;
        this.init();
        this.openPreviewMode();
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.accordionElement = this.element.querySelector('[data-accordion]');
        this.accordion = accordionParser.createAccordion({ element: this.accordionElement });
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.CLICK_TO_EDIT}`)) {
                this.removeCookie('cq-editor-layer.page');
            }
        });
    }

    removeCookie(name) {
        console.log(this);
        document.cookie = `${name}=; value=Edit; path=/`;
    }

    openPreviewMode() {
        const cookieValue = 'cq-editor-layer.page';
        const modeValue = this.getCookie(cookieValue);
        if (modeValue === 'Edit') {
            document.cookie = `${cookieValue}=Preview; path=/`;
        }
    }

    getCookie(cname) {
        console.log(this);
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
