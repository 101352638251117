/**
 * WaypointNav JS module
 */
import { ScrollToSticky } from 'partials/sticky-nav';
import ScrollButton from 'partials/ScrollToButton';
import Waypoint from 'partials/Waypoint';
import WaypointNavTemplate from 'templates/waypoint-nav';
import {
    screen,
    dimensions,
    isStickyElement
} from 'utils';

const CLASSES = {
    WAYPOINT_NAV_CONTAINER: 'waypoint-nav__container',
    WAYPOINT_LINK: 'waypoint-nav__link',
    ACTIVE: 'waypoint-nav__link--active',
    ACTIVE_ANIMATION: 'waypoint-nav__link--active-with-anim'
};

const ATTRIBUTES = {
    WAYPOINT_SECTION: 'data-waypoint',
    WAYPOINT_OFFSET: 'data-waypoint-offset'
};

export default class WaypointNav {
    constructor(element) {
        this.element = element;
        this.waypointSections = null;
        this.waypointLinks = null;
        this.waypointOffset = null;
        this.init();
    }

    /**
     * Init this module
     * Caches DOM, Attaches events, Inits sticky scroll, Inits waypoints
     */
    init() {
        this.cacheDOM();
        this.populateTemplate();
        this.initScrollButtons();
        this.initStickyScroll();
        this.initWaypoints();
    }

    /**
     * Cache DOM
     */
    cacheDOM() {
        this.waypointSections = document.querySelectorAll(`[${ATTRIBUTES.WAYPOINT_SECTION}]`);
        this.waypointOffset = document.querySelectorAll(`[${ATTRIBUTES.WAYPOINT_OFFSET}]`);
    }

    /**
     * Populates the ES6 template literal and inserts it into the DOM
     */
    populateTemplate() {
        let waypointSections = Array.prototype.slice.call(this.waypointSections);

        // create an array with objects containing the waypoint deeplink key and label
        waypointSections = waypointSections.map((waypointSection) => {
            const obj = {};
            obj.waypointLabel = waypointSection.dataset.waypointLabel;
            obj.waypointKey = waypointSection.dataset.waypoint;
            return obj;
        });

        // insert Waypoint Nav template into nav container
        this.element.insertAdjacentHTML('beforeEnd', WaypointNavTemplate(waypointSections));
    }

    /**
     * Initializes scroll to sticky with the waypoint nav
     */
    initStickyScroll() {
        const options = {
            isWaypointNav: true,
            absoluteAtFooter: true
        };

        return new ScrollToSticky(this.element, options);
    }

    /**
     * Initializes waypoints with waypoint nav sections
     */
    initWaypoints() {
        return [].slice.call(this.waypointSections).map((waypointSection) => {
            const waypoint = new Waypoint(waypointSection, {
                callback: this.waypointCallback.bind(this)
            });
            return Object.assign(waypointSection, { waypoint });
        });
    }

    /**
     * Creates ScrollToButtons from the waypointLinks
     * @return {Array}
     */
    initScrollButtons() {
        this.waypointLinks = this.element.querySelectorAll(`.${CLASSES.WAYPOINT_LINK}`);

        return [].slice.call(this.waypointLinks).map(
            waypointLink => new ScrollButton(waypointLink, this.getOffset.bind(this))
        );
    }

    /**
     * Determines if there are any waypointOffset elements that are sticky and if so
     * retrieves the height of the element to provide an offset to the waypoints
     * @return {number}
     */
    getOffset() {
        let offset = 0;

        [].slice.call(this.waypointOffset).forEach((elem) => {
            if (isStickyElement(elem)) {
                offset = dimensions.getHeight(elem);
            }
        });

        return offset;
    }

    /**
     * Callback for waypoint
     * Adds active class to current waypoint's associated nav item
     */
    waypointCallback(waypointSection) {
        const removeActiveClass = (navItem) => {
            navItem.classList.remove(CLASSES.ACTIVE);
            navItem.classList.remove(CLASSES.ACTIVE_ANIMATION);
            return navItem;
        };
        const filterDeepLinkedItem = navItem => (
            navItem.hash.replace('#', '') === waypointSection.dataset.waypoint
        );
        const addActiveClass = (navItem) => {
            if (screen.getState().small || screen.getState().large || navItem.parentElement.querySelector(':hover') !== navItem) {
                navItem.classList.add(CLASSES.ACTIVE_ANIMATION);
            } else {
                navItem.classList.add(CLASSES.ACTIVE);
            }
            return navItem;
        };

        [].slice.call(this.waypointLinks)
            .map(removeActiveClass)
            .filter(filterDeepLinkedItem)
            .map(addActiveClass);
    }
}
