import React, { Component } from 'react';
import { ModalComponent as Modal } from 'partials/modal/react';

class RequestProductionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProductionDate: false
        }

        this.productionDateModal = null;
        this.onCloseModal = this.onCloseModal.bind(this);
        this.setProductionDate = this.setProductionDate.bind(this);
        this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    }

    componentDidMount() {
        this.productionDateModal.open();
    }

    onCloseModal() {
        this.props.closeModal(this.state.selectedProductionDate);
    }

    handleSubmitOrder() {
        this.productionDateModal.close()
    }

    setProductionDate(e) {
        this.setState({
            selectedProductionDate: e.target.value
        })
    }

    render() {
        const {
            content
        } = this.props;

        return (
            <Modal sizeSmall={Modal.SIZES.FULLSCREEN}
                sizeLarge={Modal.SIZES.INSET}
                ref={(modal) => { this.productionDateModal = modal; }}
                beforeClose={this.onCloseModal}
                dataAnalyticContainer="{'blade': 'remediation-modal'}"
            >
                <div className="order-confirmation-modal">
                    <h1 className="title-hero__title order-confirmation-modal__heading">
                        {content.productionDateHeading}
                    </h1>
                    <div className="order-confirmation-modal__production-date-input">
                        <input
                            type="date"
                            className="contact-form-data__control "
                            onChange={this.setProductionDate}
                        />
                    </div>
                    <div className='order-confirmation-modal__action'>
                        <button
                            onClick={this.handleSubmitOrder}
                            disabled={!this.state.selectedProductionDate}
                            className={`button button_primary button--full-width ${!this.state.selectedProductionDate ? 'disabled' : ''}`}
                        >
                            {content.yes}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default RequestProductionModal;
