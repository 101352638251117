import { EVENTS } from 'Constants';

const ID = {
    BRC_SEARCH_BUTTON: 'searchbutton',
    SEARCH: 'search',
    BRC_MOVE_BUTTON: 'movebutton',
    SHOWING: 'showing',
    DISPLAY_RESULT: 'mblease-listing',
    NOT_FOUND_RESULT: 'mblease-listing-not-found-error',
};

let prevtext = '';
let counterPerWord = 0;
let totalCount = 0;
let htmlShowing = '';

export default class FleetInfoCanSearch {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BRC_SEARCH_BUTTON}`)) {
                e.preventDefault();
                counterPerWord = 0;
                totalCount = 0;
                htmlShowing = '';
                this.removehighlight();
                this.addhighlight();
                document.getElementById(`${ID.BRC_MOVE_BUTTON}`).style.display = 'none';
                if (totalCount > 2) {
                    document.getElementById(`${ID.BRC_MOVE_BUTTON}`).style.display = 'inline-block';
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BRC_MOVE_BUTTON}`)) {
                e.preventDefault();
                htmlShowing = '';
                const searchtext = document.getElementById('search').value;
                if (window.find && window.getSelection) {
                    document.getElementById(`${ID.BRC_MOVE_BUTTON}`).style.display = 'none';
                    if (totalCount > 2) {
                        document.getElementById(`${ID.BRC_MOVE_BUTTON}`).style.display = 'inline-block';
                        this.browserNavigator(searchtext, 1);
                    } else {
                        document.getElementById(`${ID.BRC_MOVE_BUTTON}`).style.display = 'inline-block';
                        this.browserNavigatorForStringNotFound(searchtext, 1);
                    }
                } else {
                    this.ieNavigator(searchtext, 1);
                }
            }
        });
    }

    removehighlight() {
        console.log(this);
        if (window.find && window.getSelection) {
            const sel = window.getSelection();
            document.designMode = 'on';
            sel.collapse(document.body, 0);
            while (window.find(prevtext)) {
                document.execCommand('HiliteColor', false, 'transparent');
                sel.collapseToEnd();
            }
            document.designMode = 'off';
            this.scroll();
            this.goTop(prevtext, 0);
        } else if (document.body.createTextRange) {
            const textRange = document.body.createTextRange();
            if (prevtext === '') {
                prevtext = '$^@#&%*(*#&';
            }
            while (textRange.findText(prevtext)) {
                textRange.execCommand('BackColor', false, 'transparent');
                textRange.collapse(false);
            }
            this.goTopIERemove();
        }
    }

    addhighlight() {
        console.log(this);
        if (window.find && window.getSelection) {
            const sel = window.getSelection();
            document.designMode = 'on';
            sel.collapse(document.body, 0);
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            while (self.find(searchtext)) {
                document.execCommand('HiliteColor', false, 'yellow');
                sel.collapseToEnd();
                totalCount = parseInt(totalCount, 10) + 1;
                console.log(`page count:${totalCount}`);
            }
            console.log(`total count:${totalCount}`);
            this.displayDetailsPage();
            document.designMode = 'off';
            this.scroll();
            this.goTop(searchtext, 1);
        } else if (document.body.createTextRange) {
            const textRange = document.body.createTextRange();
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            let count = 0;
            const showingDisplay = document.getElementById(`${ID.SHOWING}`);
            if (searchtext === '') {
                document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
                document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
                htmlShowing += '<div class = "fleet-info-can-search__heading">Please enter a search term</div>';
                showingDisplay.innerHTML = htmlShowing;
            }
            while (textRange.findText(searchtext)) {
                textRange.execCommand('BackColor', false, 'yellow');
                textRange.collapse(false);
                totalCount = parseInt(totalCount, 10) + 1;
                count += 1;
                console.log(`page count:${count}`);
            }
            console.log(`total count:${totalCount}`);
            this.goTopIE();
            this.displayDetailsPageIE();
        }
    }

    goTop(keyWords, findFirst) {
        console.log(this);
        if (findFirst) {
            self.find(keyWords, 0, 0, 1);
            self.find(keyWords, 0, 0, 1);
        }
    }

    browserNavigator(keyWords, findFirst) {
        console.log(this);
        if (findFirst) {
            if (counterPerWord === totalCount - 1) {
                self.find(keyWords, 0, 0, 1);
                self.find(keyWords, 0, 0, 1);
                this.displayDetailsPage(counterPerWord);
            } else {
                self.find(keyWords, 0, 0, 1);
                this.displayDetailsPage(counterPerWord);
            }
        }
    }

    browserNavigatorForStringNotFound(keyWords, findFirst) {
        console.log(this);
        if (findFirst) {
            if (counterPerWord === totalCount - 1) {
                self.find(keyWords, 0, 0, 1);
                self.find(keyWords, 0, 0, 1);
                this.displayDetailsPage(counterPerWord);
            } else {
                self.find(keyWords, 0, 0, 1);
                this.displayDetailsPage(counterPerWord);
            }
        }
    }

    ieNavigator(searchtext, findFirst) {
        console.log(this);
        if (findFirst) {
            if (document.getSelection() && document.body.createTextRange) {
                const sel = document.getSelection();
                let textRange;
                prevtext = searchtext;
                if (sel.type === 'Text') {
                    textRange = sel.createRange();
                    textRange.collapse(false);
                } else {
                    textRange = document.body.createTextRange();
                    textRange.collapse(true);
                }
                if (textRange.findText(searchtext)) {
                    textRange.select();
                }
                this.displayDetailsPageIE(counterPerWord);
            }
        }
    }

    goTopIE() {
        console.log(this);
        if (document.getSelection() && document.body.createTextRange) {
            const sel = document.getSelection();
            let textRange;
            const searchtext = document.getElementById('search').value;
            prevtext = searchtext;
            if (sel.type === 'Text') {
                textRange = sel.createRange();
                textRange.collapse(false);
            } else {
                textRange = document.body.createTextRange();
                textRange.collapse(true);
            }
            if (textRange.findText(searchtext)) {
                textRange.select();
            }
        }
    }

    goTopIERemove() {
        console.log(this);
        if (document.getSelection() && document.body.createTextRange) {
            const sel = document.getSelection();
            let textRange;
            if (prevtext === '') {
                prevtext = '$';
            }
            if (sel.type === 'Text') {
                textRange = sel.createRange();
                textRange.collapse(false);
            } else {
                textRange = document.body.createTextRange();
                textRange.collapse(true);
            }
            if (textRange.findText(prevtext)) {
                textRange.select();
            }
        }
    }

    displayDetailsPage() {
        console.log(this);
        counterPerWord = parseInt(counterPerWord, 10) + 1;
        if (counterPerWord >= totalCount && totalCount > 1) {
            counterPerWord = 1;
        }
        const searchtext = document.getElementById('search').value;
        const showingDisplay = document.getElementById(`${ID.SHOWING}`);
        if (searchtext === '') {
            document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = 'none';
            document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = '';
            htmlShowing += '<div class = "fleet-info-can-search__heading">Please enter a search term</div>';
            showingDisplay.innerHTML = htmlShowing;
        } else if (totalCount === 1) {
            counterPerWord = 0;
            document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
            document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
            htmlShowing += '<div class = "fleet-info-can-search__heading">No matching records found</div>';
            showingDisplay.innerHTML = htmlShowing;
        } else {
            document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
            document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
            htmlShowing += `<div class = "fleet-info-can-search__heading">Showing ${counterPerWord} of ${totalCount - 1} entries.</div>`;
            showingDisplay.innerHTML = htmlShowing;
        }
    }

    displayDetailsPageIE() {
        console.log(this);
        counterPerWord = parseInt(counterPerWord, 10) + 1;
        if (counterPerWord > totalCount && totalCount > 0) {
            counterPerWord = 1;
        }
        if (totalCount === 0) {
            counterPerWord = 0;
            document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
            document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
            htmlShowing += '<div class = "fleet-info-can-search__heading">No matching records found</div>';
            document.getElementById(`${ID.SHOWING}`).innerHTML = htmlShowing;
        } else {
            document.getElementById(`${ID.NOT_FOUND_RESULT}`).style.display = 'none';
            document.getElementById(`${ID.DISPLAY_RESULT}`).style.display = '';
            htmlShowing += `<div class = "fleet-info-can-search__heading">Showing ${counterPerWord} of ${totalCount} entries.</div>`;
            document.getElementById(`${ID.SHOWING}`).innerHTML = htmlShowing;
        }
    }

    scroll() {
        console.log(this);
        document.getElementById('content').scrollIntoView({
            behavior: 'smooth'
        });
    }
}
