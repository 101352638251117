import React, { Component } from 'react';
import { ModalComponent as Modal } from 'partials/modal/react';

class CancelOrderRequestModal extends Component {
    constructor(props) {
        super(props);

        this.modal = null;
        this.onCloseModal = this.onCloseModal.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
    }

    componentDidMount() {
        this.modal.open();
    }

    onCloseModal() {
        this.modal.close();
        this.props.closeModal();
    }

    cancelOrder() {
        const {
            cancelOrder
        } = this.props;

        this.onCloseModal();
        cancelOrder();
    }

    render() {
        const {
            content
        } = this.props;

        return (
            <Modal sizeSmall={Modal.SIZES.FULLSCREEN}
                sizeLarge={Modal.SIZES.INSET}
                ref={(modal) => { this.modal = modal; }}
                beforeClose={this.onCloseModal}
                dataAnalyticContainer="{'blade': 'remediation-modal'}"
            >
                <div className="order-confirmation-modal">
                    <h1 className="title-hero__title order-confirmation-modal__heading">
                        {content.cancelOrderHeading}
                    </h1>

                    <div
                        className='title-hero__sub-heading order-confirmation-modal__sub-heading'
                        dangerouslySetInnerHTML={{ __html: content.cancelOrderSubHeading }}
                    />
                    <div className='order-confirmation-modal__action'>
                        <button
                            onClick={this.cancelOrder}
                            className='button
                            button_primary
                            button--full-width'
                        >
                            {content.yes}
                        </button>
                        <button
                            onClick={this.onCloseModal}
                            className='button button_secondary button--full-width'>
                            {content.no}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CancelOrderRequestModal;
