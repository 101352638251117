import { EVENTS } from 'Constants';

const CLASSES = {
    RANGE: 'range',
    RANGE_VALUE: 'range-value'
};

export default class RangeSlider {
    constructor(element) {
        this.element = element;
        this.rangeElement = null;
        this.rangeValue = null;
        this.constantDigit = 10;
        this.init();
    }

    /*
        Function :  This is called when the js loads.
    */
    init() {
        this.cacheDOMElement();
        this.attachEvents();
        this.addValueToSliders();
    }

    /**
     * Caching DOM Elements to be used for opertaion in functions.
     */
    cacheDOMElement() {
        this.rangeElements = document.querySelectorAll(`.${CLASSES.RANGE}`);
    }

    /*
        Function : To set value in the range bubble.
        @param range : input element of type range.
    */
    setValue(range, rangeValue) {
        const newValue = Number((range.value - range.min) * 100 / (range.max - range.min));
        const newPosition = this.constantDigit - (newValue * 0.2);
        rangeValue.innerHTML = `<span>${range.value}</span>`;
        rangeValue.style.left = `calc(${newValue}% + (${newPosition - 2}px))`;
    }

    addValueToSliders() {
        [].forEach.call(this.rangeElements, (element) => {
            const rangeValue = element.previousElementSibling;
            this.setValue(element, rangeValue);
        });
    }

    /**
     * Attaching event listners to DOM elements.
     */
    attachEvents() {
        [].forEach.call(this.rangeElements, (element) => {
            element.addEventListener(EVENTS.INPUT, () => {
                const rangeValue = element.previousElementSibling;
                this.setValue(element, rangeValue);
            });
        });
    }
}
