export default [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'application/doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/ms-doc',
    'application/excel',
    'application/x-excel',
    'application/x-msexcel',
    'image/jpeg',
];
