import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import MbmLeaseReturnUtil from 'modules/MbmLeaseReturnUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'mbm-lease-return-form',
    SITE_NAME: 'site-name',
};

export default class MbmLeaseReturn {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'mbmLeaseReturn';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.MbmLeaseReturnUtil = new MbmLeaseReturnUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const edit = document.getElementById('editLink').value;
                const editUrl = `${window.location.origin}${edit}?ref=`;

                const gradeCheckBox = document.getElementsByName('gradeCheck');
                const checkedgrade = [];

                for (let i = 0; i < gradeCheckBox.length; i += 1) {
                    if (gradeCheckBox[i].checked) {
                        checkedgrade.push(gradeCheckBox[i].value);
                    }
                }

                const gradeChkErr = document.getElementById('gradeCheckBox');
                let gradeCk = false;
                let finalCk = false;

                for (let i = 0; i < gradeCheckBox.length; i += 1) {
                    if (gradeCheckBox[i].checked) {
                        gradeCk = true;
                        break;
                    }
                }

                if (gradeCk) {
                    gradeChkErr.classList.add('mbm-lease-return-form__error-field--hidden');
                } else {
                    gradeChkErr.classList.remove('mbm-lease-return-form__error-field--hidden');
                }

                if (gradeCk) {
                    finalCk = true;
                }

                const capRes = grecaptcha.getResponse();
                if (this.MbmLeaseReturnUtil.validateAllFields() && finalCk && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    const formToJSONObject = elements => [].reduce.call(elements,
                            (data, element) => {
                                data[element.name] = element.value;
                                return data;
                            }, {});

                    const payoffValue = this.MbmLeaseReturnUtil.removeComma(document.getElementById('payoff').value);
                    const mileageValue = this.MbmLeaseReturnUtil.removeComma(document.getElementById('mileage').value);
                    const priceValue = this.MbmLeaseReturnUtil.removeComma(document.getElementById('price').value);

                    const siteName = 'mbmLeaseReturn';
                    const url = '/bin/microsites/mbm-lease-return';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                        checkedgrade,
                        editUrl,
                        payoffValue,
                        mileageValue,
                        priceValue,
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.MbmLeaseReturnUtil.displayPopUp('Please verify the captcha response');
                        } else if (success !== 'false') {
                            this.MbmLeaseReturnUtil.displayPopUp(`Form submission successful. Reference Number is: ${success}`);
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.MbmLeaseReturnUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="fmla-request-form__error-field">This field is required.</span>';
                    this.MbmLeaseReturnUtil.validateAllFields();
                }
            }
        });
    }
}
