import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    MB_FORM_DATA_CONTROL: 'mb-form-data__control',
    MB_FORM_DATA_CONTROL_ERROR: 'mb-form-data__control--error',
    MB_FORM_DATA_CONTROL_SUCCESS: 'mb-form-data__control--success',
    MB_FORM_DATA_ERROR_FIELD_HIDDEN: 'mb-form-data__error-field--hidden'
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
};
export default class MBFormUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.MB_FORM_DATA_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayExcursionError(element) {
        console.log(this);
        element.classList.add(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        if (element.nextElementSibling.nextElementSibling != null) {
            element.nextElementSibling.nextElementSibling.classList.remove(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayExcursionDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        if (element.nextElementSibling.nextElementSibling != null) {
            element.nextElementSibling.nextElementSibling.classList.add(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    hideExcursionError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MB_FORM_DATA_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.MB_FORM_DATA_CONTROL_SUCCESS}`);
        if (element.nextElementSibling.nextElementSibling != null) {
            element.nextElementSibling.nextElementSibling.classList.add(`${CLASS.MB_FORM_DATA_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Email Validation
     */
    isValidEmail(email) {
        console.log(this);
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /**
     * Phone Number Validation
     */
    isValidPhoneNumber(phoneNumber) {
        console.log(this);
        const regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    }

    isValidDealerCode(dealerCode) {
        console.log(this);
        const regex = /^\d{5}$/;
        return regex.test(dealerCode);
    }

    isValidZipCode(zipCode) {
        console.log(this);
        const regex = /^\d{5}$/;
        return regex.test(zipCode);
    }

    isValidWinnerExcursionValue() {
        console.log(this);
        if (document.getElementById('winnerExcursion').value === 'Golf (18 holes)') {
            return true;
        }
        return false;
    }

    isValidGuestExcursionValue() {
        console.log(this);
        if (document.getElementById('guestExcursion').value === 'Golf (18 holes)') {
            return true;
        }
        return false;
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    validateExcursion(el, isDataValid) {
        if (isDataValid) {
            this.hideExcursionError(el);
        } else {
            this.displayExcursionError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.MB_FORM_DATA_CONTROL}`);

        this.validateFields(inputFields);

        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.MB_FORM_DATA_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            if (el.name === 'winnerGolfDayTeatime') {
                if (document.getElementById('winnerday2excursionselection').value === 'Golf (18 holes)') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'guestGolfDayTeatime') {
                if (document.getElementById('guestday2excursionselection').value === 'Golf (18 holes)') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'winnerday1spaselection') {
                if (document.getElementById('winnerday1excursionselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'winnerday2spaselection') {
                if (document.getElementById('winnerday2excursionselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'winnerday1excursionselection') {
                if (document.getElementById('winnerday1spaselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'winnerday2excursionselection') {
                if (document.getElementById('winnerday2spaselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'guestday1spaselection') {
                if (document.getElementById('guestday1excursionselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'guestday2spaselection') {
                if (document.getElementById('guestday2excursionselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'guestday1excursionselection') {
                if (document.getElementById('guestday1spaselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name === 'guestday2excursionselection') {
                if (document.getElementById('guestday2spaselection').value === '') {
                    this.validate(el, this.containData(el.value));
                }
            } else if (el.name !== 'middleName' && el.name !== 'guestMiddleName' && el.name !== 'foodAllergies' && el.name !== 'specialRequest' && el.name !== 'guestFoodAllergies' && el.name !== 'guestSpecialRequest') {
                this.validate(el, this.containData(el.value));
            }
        }
        if (el.name === 'cellPhone' || el.name === 'guestCellPhone' || el.name === 'dealerPhoneNumber' || el.name === 'dealerPhoneNumber' || el.name === 'emergencyCPhone' || el.name === 'emergencyBPhone' || el.name === 'guestEmergencyBPhone' || el.name === 'guestEmergencyCPhone') {
            if (el.value) {
                this.validate(el, this.isValidPhoneNumber(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'emailAddress' || el.name === 'guestEmailAddress') {
            if (el.value) {
                this.validate(el, this.isValidEmail(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'dealerCode') {
            if (el.value) {
                this.validate(el, this.isValidDealerCode(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'dealerZipcode') {
            if (el.value) {
                this.validate(el, this.isValidZipCode(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
