import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import DseDmsUtil from 'modules/DseDmsUtil';

const ID = {
    SUBMITBUTTON: 'digital-shopping-btn',
    REGISTRATION_FORM: 'shopping_form',
    SITE_NAME: 'site-name',
    TERMS_TRAIN_AGREE: 'terms-train-agree',
    TERMS_CHECKBOX: 'terms',
};

export default class DseDms {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'dse-dms';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.DseDmsUtil = new DseDmsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const captchaResponse = grecaptcha.getResponse();
                const termsElement = document.getElementById(`${ID.TERMS_CHECKBOX}`);
                const termsAgree = document.getElementById(`${ID.TERMS_TRAIN_AGREE}`);
                if (this.DseDmsUtil.validateAllFields() && termsElement.checked === true) {
                    termsAgree.classList.add('digital-shopping__error-field--hidden');
                    if (captchaResponse.length !== 0) {
                        document.getElementById('g-recaptcha-error').innerHTML = '';
                        const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                        const inputs = form.elements;

                        const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                        console.log(formToJSONObject(inputs));

                        const siteName = 'dse-dms';
                        const url = '/bin/microsites/dse-dms';
                        const data = {
                            formData: JSON.stringify(formToJSONObject(inputs)),
                            siteName,
                        };
                        grecaptcha.reset();
                        this.Ajax.ajaxPostFn(url, data, (success) => {
                            if (success.includes('Please verify the captcha response')) {
                                this.DseDmsUtil.displayPopUp('Please verify the captcha response');
                            } else if (success === 'true') {
                                this.DseDmsUtil.displayPopUp('Form submission successful');
                                form.reset();

                                [].forEach.call(inputs, (element) => {
                                    if (element.matches(`.${CLASSES.DIGITAL_SHOPPING__CONTROL_SUCCESS}`)) {
                                        element.classList.remove(`${CLASSES.DIGITAL_SHOPPING__CONTROL_SUCCESS}`);
                                    }
                                });
                            } else {
                                this.DseDmsUtil.displayPopUp('Form submission failed');
                            }
                        });
                    } else if (captchaResponse.length === 0) {
                        document.getElementById('g-recaptcha-error').innerHTML = '<span class="vin-recall__error-field">This field is required.</span>';
                        this.DseDmsUtil.validateAllFields();
                    }
                } else {
                    termsAgree.classList.remove('digital-shopping__error-field--hidden');
                }
            }
        });
    }
}
