/**
 * @const monthNames
 * @memberof formatDate
 * @description Object containing the translated months
 * @type {{
 *     en: Array,
 *     es: Array,
 *     fr: Array
 * }}
 * @property {Array} en An array of months in English.
 * @property {Array} es An array of months in Spanish.
 * @property {Array} fr An array of months in French.
 */
const monthNames = {
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    fr: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
};

/**
 * @method toShortDate
 * @memberof formatDate
 * @description Converts a string date to a specific format per country:
 * US: m/d/yyyy
 * CA: yyyy-mm-dd
 * MX: dd/MM/yyyy
 * @param {string} value String to convert
 * @param {string} [country = 'US'] Country code
 * @param {Boolean} fullYear boolean
 * @returns {string} Formatted date
 */
function toShortDate(value, country = 'US', fullYear = true) {
    country = country.toUpperCase();
    value = value.replace(/-/g, '/');
    const date = new Date(value);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (country === 'MX') {
        // Pad day and month with zeros
        month = `0${month}`.slice(-2);
        day = `0${day}`.slice(-2);

        return `${day}/${month}/${year}`;
    }

    if (country === 'CA') {
        // Pad day and month with zeros
        month = `0${month}`.slice(-2);
        day = `0${day}`.slice(-2);
        return `${year}-${month}-${day}`;
    }

    if(fullYear === false) {
         // Pad day and month with zeros
         month = `0${month}`.slice(-2);
         day = `0${day}`.slice(-2);
         let formatYear = `${year}`.slice(-2);

         return `${month}/${day}/${formatYear}`
    }

    return `${month}/${day}/${year}`;
}

/**
 * @method toFormattedDate
 * @memberof formatDate
 * @description Converts user input to formatted date by entering / and 0 in the correct places.
 * @param {string} dateValue Date string.
 * @returns {string} formatted date with /.
 */
export function toFormattedDate(dateValue) {
    // Fix pre-entered date that does not conform to MM/DD/YYYY
    if (/[\d]+\/[\d]+\/[\d]{4}/.test(dateValue) && dateValue.length < 10) {
        const dateValueSplit = dateValue.split('/');
        return dateValueSplit.map((split, index) => (
            split.length === 1 && (index === 0 || index === 1) ? `0${split}` : split
        )).join('/');
    }

    // check if the user entered / and add 0 if the month or date is less than 10
    if (dateValue.charAt(dateValue.length - 1) === '/' && (dateValue.length === 2 || dateValue.length === 5)) {
        const dateValueSplit = dateValue.split('/');
        dateValue = dateValueSplit.map((split, index) => (
            split.length === 1 && (index === 0 || index === 1) ? `0${split}` : split
        )).join('/');
        return dateValue;
    }

    const dateString = dateValue.toString();
    const hasFirstSeperator = dateString.charAt(2) === '/';
    const hasSecondSeperator = dateString.charAt(5) === '/';
    const dateStr = dateString.replace(/\//g, '');
    const month = dateStr.substring(0, 2);
    const day = dateStr.substring(2, 4);
    const year = dateStr.substring(4);

    return `${month}${(day || hasFirstSeperator) ? '/' : ''}${day}${(year || hasSecondSeperator) ? '/' : ''}${year}`;
}

/**
 * @method toHyphenatedDate
 * @memberof formatDate
 * @description Converts a string date to a specific format
 * @param {string} value String to convert.
 * @returns {string} Formatted date yyyy-mm-dd.
 */
function toHyphenatedDate(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Pad day and month with zeros
    month = `0${month}`.slice(-2);
    day = `0${day}`.slice(-2);

    return `${year}-${month}-${day}`;
}

/**
 * @method toMonthDayYear
 * @memberof formatDate
 * @description Converts a string date to a specific format.
 * @param {Date} value Date to convert.
 * @param {string} [language = 'en'] Language code.
 * @returns {string} Formatted date Month Day, Year.
 */
function toMonthDayYear(value, language = 'en') {
    const date = new Date(value);
    const day = date.getDate();
    const fullMonth = monthNames[language][date.getMonth()];
    const year = date.getFullYear();

    return `${fullMonth} ${day}, ${year}`;
}

/**
 * Utility for formatting a date to a string format
 * @module formatDate
 * @type {{
 *     toFormattedDate: function,
 *     toHyphenatedDate: function,
 *     toMonthDayYear: function,
 *     toShortDate: function
 * }}
 */
export default {
    toFormattedDate,
    toHyphenatedDate,
    toMonthDayYear,
    toShortDate
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d
