import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import EmailOkUtil from 'modules/EmailOkUtil';

const ID = {
    SUBMITBUTTON: 'emailOkFormSubmit',
    MB_EMAIL_OK: 'mb-email-ok',
    SITE_NAME: 'site-name',
    DATEOFREQUEST: 'dateOfRequest',
    NAME: 'name',
    PHONENUMBER: 'phoneNumber',
    EMAIL: 'email',
    REQUESTINGDEPT: 'requestingDept',
    DEPTMANAGER: 'deptManager',
    GENERALMANAGER: 'generalManager',
    APPROVALYES: 'approvalYes',
    APPROVALNO: 'approvalNo',
    TOPICS: 'topics',
    DISTRIBUTION: 'distribution',
    EMAILMESSAGE: 'emailMessage',
    DATESENTTO: 'dateSentTo',
    MESSAGE: 'message',
    COMMENTS: 'comments',
    MESSAGETO: 'messageTo',
};

export default class EmailOk {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'emailOk';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.EmailOkUtil = new EmailOkUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.EmailOkUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById(`${ID.MB_EMAIL_OK}`);
                    const inputs = form.elements;

                    const siteName = 'email-ok';
                    const url = '/bin/microsites/email-ok/sendEmail';

                    const dateOfRequest = document.getElementById(`${ID.DATEOFREQUEST}`).value;
                    const name = document.getElementById(`${ID.NAME}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONENUMBER}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const requestingDept = document.getElementById(`${ID.REQUESTINGDEPT}`).value;
                    const deptManager = document.getElementById(`${ID.DEPTMANAGER}`).value;
                    const generalManager = document.getElementById(`${ID.GENERALMANAGER}`).value;
                    const topics = document.getElementById(`${ID.TOPICS}`).value;
                    const distribution = document.getElementById(`${ID.DISTRIBUTION}`).value;
                    const dateSentTo = document.getElementById(`${ID.DATESENTTO}`).value;
                    const emailMessage = document.getElementById(`${ID.EMAILMESSAGE}`).value;
                    const message = document.getElementById(`${ID.MESSAGE}`).value;
                    const comments = document.getElementById(`${ID.COMMENTS}`).value;

                    const msgTo = document.getElementById(`${ID.MESSAGETO}`);
                    const dlValue = [];
                    const dlText = [];
                    let i;
                    for (i = 0; i < msgTo.options.length; i += 1) {
                        if (msgTo.options[i].selected) {
                            dlValue.push(msgTo.options[i].value);
                            dlText.push(msgTo.options[i].text);
                        }
                    }
                    console.log(dlValue);
                    console.log(dlText);

                    let tempApproval = '';
                    if (document.getElementById(`${ID.APPROVALYES}`).checked) {
                        tempApproval = document.getElementById(`${ID.APPROVALYES}`).value;
                    }

                    if (document.getElementById(`${ID.APPROVALNO}`).checked) {
                        tempApproval = document.getElementById(`${ID.APPROVALNO}`).value;
                    }

                    const approval = tempApproval;

                    const dataMap = new FormData();

                    dataMap.set('dateOfRequest', dateOfRequest);
                    dataMap.set('siteName', siteName);
                    dataMap.set('name', name);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('email', email);
                    dataMap.set('requestingDept', requestingDept);
                    dataMap.set('deptManager', deptManager);
                    dataMap.set('generalManager', generalManager);
                    dataMap.set('approval', approval);
                    dataMap.set('topics', topics);
                    dataMap.set('distribution', distribution);
                    dataMap.set('dateSentTo', dateSentTo);
                    dataMap.set('emailMessage', emailMessage);
                    dataMap.set('message', message);
                    dataMap.set('comments', comments);
                    dataMap.set('messageToValue', dlValue);
                    dataMap.set('messageToText', dlText);
                    dataMap.set('captcha', captchaResponse);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.EmailOkUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.EmailOkUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.EMAILOK_FORM_DATA_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.EMAILOK_FORM_DATA_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.EmailOkUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="email-ok__error-field">This field is required.</span>';
                    this.EmailOkUtil.validateAllFields();
                }
            }
        });
    }
}
