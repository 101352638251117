import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import PensionUtil from 'modules/PensionUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'pension-form',
    SITE_NAME: 'site-name',
    LASTNAME: 'lastName',
    FIRSTNAME: 'firstName',
    DOB: 'dob',
    RETIREMENT_DATE: 'retirementDate',
    LAST_FOUR_SSN: 'lastFourSsn',
    ADDRESS: 'address',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    RETIREMENT_AGE: 'retirementAge',
    MARITAL_STATUS: 'maritalCheck',
    AFFILIATE_STATUS: 'affiliateCheck',
    AFFILIATE_NAME1: 'affiliateName1',
    AFFILIATE_NAME2: 'affiliateName2',
    AFFILIATE_NAME3: 'affiliateName3',
    AFFILIATE_NAME4: 'affiliateName4',
    AFFILIATE_START1: 'fromDate1',
    AFFILIATE_START2: 'fromDate2',
    AFFILIATE_START3: 'fromDate3',
    AFFILIATE_START4: 'fromDate4',
    AFFILIATE_END1: 'toDate1',
    AFFILIATE_END2: 'toDate2',
    AFFILIATE_END3: 'toDate3',
    AFFILIATE_END4: 'toDate4',
    PARTTIME_STATUS: 'partTimeCheck',
    PARTTIME_START_DATE: 'partTimeStartDate',
    PARTTIME_END_DATE: 'partTimeEndDate',
    PERTINENT_DETAILS: 'pertinentDetails',
    SINGLE: 'single',
    MARRIED: 'married',
    SPOUSE_INFO: 'spouseInfo',
    SPOUSE_DOB: 'spouseDOB',
    AFFILIATE_YES: 'affiliateYes',
    AFFILIATE_NO: 'affiliateNo',
    PARTTIME_YES: 'partTimeYes',
    PARTTIME_NO: 'partTimeNo',
};

export default class PensionRequest {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'pension';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.PensionUtil = new PensionUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    maritalFunction() {
        console.log(this);
        const showBlock = document.getElementById(`${ID.SPOUSE_INFO}`);
        const spouseDate = document.getElementById(`${ID.SPOUSE_DOB}`);
        const married = document.getElementById(`${ID.MARRIED}`);
        const single = document.getElementById(`${ID.SINGLE}`);
        if (married.checked === true) {
            spouseDate.setAttribute('required', 'true');
            if (showBlock.style.display === 'none') {
                showBlock.style.display = 'block';
            } else {
                showBlock.style.display = 'none';
            }
        } else if (single.checked === true) {
            spouseDate.setAttribute('required', 'false');
            spouseDate.value = '';
            if (showBlock.style.display === 'block') {
                showBlock.style.display = 'none';
            } else {
                showBlock.style.display = 'none';
            }
        }
    }

    affiliateFunction() {
        console.log(this);
        const affiliateYes = document.getElementById('affiliateYes');
        const affiliateNo = document.getElementById('affiliateNo');
        const affiliateName1 = document.getElementById('affiliateName1');
        const fromDate1 = document.getElementById('fromDate1');
        const toDate1 = document.getElementById('toDate1');
        const affiliateName2 = document.getElementById('affiliateName2');
        const fromDate2 = document.getElementById('fromDate2');
        const toDate2 = document.getElementById('toDate2');
        const affiliateName3 = document.getElementById('affiliateName3');
        const fromDate3 = document.getElementById('fromDate3');
        const toDate3 = document.getElementById('toDate3');
        const affiliateName4 = document.getElementById('affiliateName4');
        const fromDate4 = document.getElementById('fromDate4');
        const toDate4 = document.getElementById('toDate4');
        if (affiliateYes.checked === true) {
            affiliateName1.removeAttribute('disabled');
            fromDate1.removeAttribute('disabled');
            toDate1.removeAttribute('disabled');
            affiliateName2.removeAttribute('disabled');
            fromDate2.removeAttribute('disabled');
            toDate2.removeAttribute('disabled');
            affiliateName3.removeAttribute('disabled');
            fromDate3.removeAttribute('disabled');
            toDate3.removeAttribute('disabled');
            affiliateName4.removeAttribute('disabled');
            fromDate4.removeAttribute('disabled');
            toDate4.removeAttribute('disabled');
            affiliateName1.setAttribute('required', 'true');
            fromDate1.setAttribute('required', 'true');
            toDate1.setAttribute('required', 'true');
        } else if (affiliateNo.checked === true) {
            affiliateName1.setAttribute('disabled', 'disabled');
            fromDate1.setAttribute('disabled', 'disabled');
            toDate1.setAttribute('disabled', 'disabled');
            affiliateName2.setAttribute('disabled', 'disabled');
            fromDate2.setAttribute('disabled', 'disabled');
            toDate2.setAttribute('disabled', 'disabled');
            affiliateName2.setAttribute('disabled', 'disabled');
            affiliateName3.setAttribute('disabled', 'disabled');
            fromDate3.setAttribute('disabled', 'disabled');
            toDate3.setAttribute('disabled', 'disabled');
            affiliateName4.setAttribute('disabled', 'disabled');
            fromDate4.setAttribute('disabled', 'disabled');
            toDate4.setAttribute('disabled', 'disabled');
            affiliateName1.setAttribute('required', 'false');
            fromDate1.setAttribute('required', 'false');
            toDate1.setAttribute('required', 'false');
            affiliateName1.value = '';
            fromDate1.value = '';
            toDate1.value = '';
            affiliateName2.value = '';
            fromDate2.value = '';
            toDate2.value = '';
            affiliateName3.value = '';
            fromDate3.value = '';
            toDate3.value = '';
            affiliateName4.value = '';
            fromDate4.value = '';
            toDate4.value = '';
        }
    }

    partTimeFunction() {
        console.log(this);
        const partTimeYes = document.getElementById('partTimeYes');
        const partTimeNo = document.getElementById('partTimeNo');
        const partTimeStartDate = document.getElementById('partTimeStartDate');
        const partTimeEndDate = document.getElementById('partTimeEndDate');
        if (partTimeYes.checked === true) {
            partTimeStartDate.removeAttribute('disabled');
            partTimeEndDate.removeAttribute('disabled');
            partTimeStartDate.setAttribute('required', 'true');
            partTimeEndDate.setAttribute('required', 'true');
        } else if (partTimeNo.checked === true) {
            partTimeStartDate.setAttribute('disabled', 'disabled');
            partTimeEndDate.setAttribute('disabled', 'disabled');
            partTimeStartDate.setAttribute('required', 'false');
            partTimeEndDate.setAttribute('required', 'false');
            partTimeStartDate.value = '';
            partTimeEndDate.value = '';
            partTimeStartDate.innerHTML = '';
            partTimeEndDate.innerHTML = '';
        }
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.MARRIED}`) || e.target.matches(`#${ID.SINGLE}`)) {
                this.maritalFunction();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.AFFILIATE_YES}`) || e.target.matches(`#${ID.AFFILIATE_NO}`)) {
                this.affiliateFunction();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.PARTTIME_YES}`) || e.target.matches(`#${ID.PARTTIME_NO}`)) {
                this.partTimeFunction();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const maritalCheckBox = document.getElementsByName('maritalCheck');
                const checkedmaritalCheck = [];

                for (let i = 0; i < maritalCheckBox.length; i += 1) {
                    if (maritalCheckBox[i].checked) {
                        checkedmaritalCheck.push(maritalCheckBox[i].value);
                    }
                }

                const affiliateCheckbox = document.getElementsByName('affiliateCheck');
                const checkedaffiliateCheckbox = [];

                for (let i = 0; i < affiliateCheckbox.length; i += 1) {
                    if (affiliateCheckbox[i].checked) {
                        checkedaffiliateCheckbox.push(affiliateCheckbox[i].value);
                    }
                }

                const partTimeCheckbox = document.getElementsByName('partTimeCheck');
                const partTimeChecked = [];

                for (let i = 0; i < partTimeCheckbox.length; i += 1) {
                    if (partTimeCheckbox[i].checked) {
                        partTimeChecked.push(partTimeCheckbox[i].value);
                    }
                }

                const maritalChkErr = document.getElementById('maritalCheckBox');
                const affiliateChkErr = document.getElementById('affiliateCheckbox');
                const parttimeChkErr = document.getElementById('partTimeCheckbox');
                let maritalCk = false;
                let affiliate = false;
                let partTime = false;
                let finalCk = false;

                for (let i = 0; i < maritalCheckBox.length; i += 1) {
                    if (maritalCheckBox[i].checked) {
                        maritalCk = true;
                        break;
                    }
                }

                for (let j = 0; j < affiliateCheckbox.length; j += 1) {
                    if (affiliateCheckbox[j].checked) {
                        affiliate = true;
                        break;
                    }
                }

                for (let j = 0; j < partTimeCheckbox.length; j += 1) {
                    if (partTimeCheckbox[j].checked) {
                        partTime = true;
                        break;
                    }
                }

                if (maritalCk) {
                    maritalChkErr.classList.add('pension-form__error-field--hidden');
                } else {
                    maritalChkErr.classList.remove('pension-form__error-field--hidden');
                }

                if (affiliate) {
                    affiliateChkErr.classList.add('pension-form__error-field--hidden');
                } else {
                    affiliateChkErr.classList.remove('pension-form__error-field--hidden');
                }

                if (partTime) {
                    parttimeChkErr.classList.add('pension-form__error-field--hidden');
                } else {
                    parttimeChkErr.classList.remove('pension-form__error-field--hidden');
                }

                if (maritalCk && affiliate && partTime) {
                    finalCk = true;
                }

                const capRes = grecaptcha.getResponse();
                if (this.PensionUtil.validateAllFields() && finalCk && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('Inside final if');
                    const siteName = 'pension';
                    const url = '/bin/microsites/pension-request/sendEmail';
                    const lastName = document.getElementById(`${ID.LASTNAME}`).value;
                    const firstName = document.getElementById(`${ID.FIRSTNAME}`).value;
                    const dob = document.getElementById(`${ID.DOB}`).value;
                    const retirementDate = document.getElementById(`${ID.RETIREMENT_DATE}`).value;
                    const lastFourSsn = document.getElementById(`${ID.LAST_FOUR_SSN}`).value;
                    const address = document.getElementById(`${ID.ADDRESS}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                    const retirementAge = document.getElementById(`${ID.RETIREMENT_AGE}`).value;
                    const affiliateNameOne = document.getElementById(`${ID.AFFILIATE_NAME1}`).value;
                    const affiliateNameTwo = document.getElementById(`${ID.AFFILIATE_NAME2}`).value;
                    const affiliateNameThree = document.getElementById(`${ID.AFFILIATE_NAME3}`).value;
                    const affiliateNameFour = document.getElementById(`${ID.AFFILIATE_NAME4}`).value;
                    const affiliateFromOne = document.getElementById(`${ID.AFFILIATE_START1}`).value;
                    const affiliateFromTwo = document.getElementById(`${ID.AFFILIATE_START2}`).value;
                    const affiliateFromThree = document.getElementById(`${ID.AFFILIATE_START3}`).value;
                    const affiliateFromFour = document.getElementById(`${ID.AFFILIATE_START4}`).value;
                    const affiliateToOne = document.getElementById(`${ID.AFFILIATE_END1}`).value;
                    const affiliateToTwo = document.getElementById(`${ID.AFFILIATE_END2}`).value;
                    const affiliateToThree = document.getElementById(`${ID.AFFILIATE_END3}`).value;
                    const affiliateToFour = document.getElementById(`${ID.AFFILIATE_END4}`).value;
                    const partTimeFromDate = document.getElementById(`${ID.PARTTIME_START_DATE}`).value;
                    const partTimeToDate = document.getElementById(`${ID.PARTTIME_END_DATE}`).value;
                    const pertinentDetails = document.getElementById(`${ID.PERTINENT_DETAILS}`).value;
                    const spouseDob = document.getElementById(`${ID.SPOUSE_DOB}`).value;

                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('lastName', lastName);
                    dataMap.set('firstName', firstName);
                    dataMap.set('dob', dob);
                    dataMap.set('lastFourSsn', lastFourSsn);
                    dataMap.set('address', address);
                    dataMap.set('email', email);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('retirementAge', retirementAge);
                    dataMap.set('retirementDate', retirementDate);
                    dataMap.set('affiliateNameOne', affiliateNameOne);
                    dataMap.set('affiliateNameTwo', affiliateNameTwo);
                    dataMap.set('affiliateNameThree', affiliateNameThree);
                    dataMap.set('affiliateNameFour', affiliateNameFour);
                    dataMap.set('affiliateFromOne', affiliateFromOne);
                    dataMap.set('affiliateFromTwo', affiliateFromTwo);
                    dataMap.set('affiliateFromThree', ffiliateFromThree);
                    dataMap.set('affiliateFromFour', affiliateFromFour);
                    dataMap.set('affiliateToOne', affiliateToOne);
                    dataMap.set('affiliateToTwo', affiliateToTwo);
                    dataMap.set('affiliateToThree', affiliateToThree);
                    dataMap.set('affiliateToFour', affiliateToFour);
                    dataMap.set('partTimeFromDate', partTimeFromDate);
                    dataMap.set('partTimeToDate', partTimeToDate);
                    dataMap.set('pertinentDetails', pertinentDetails);
                    dataMap.set('checkedmaritalCheck', checkedmaritalCheck);
                    dataMap.set('checkedaffiliateCheckbox', checkedaffiliateCheckbox);
                    dataMap.set('partTimeChecked', partTimeChecked);
                    dataMap.set('spouseDob', spouseDob);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.PensionUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.PensionUtil.displayPopUp('Form submission successful');
                            form.reset();
                            this.maritalFunction();
                            this.affiliateFunction();
                            this.partTimeFunction();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.PENSION_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.PENSION_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.PensionUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="fmla-request-form__error-field">This field is required.</span>';
                    this.PensionUtil.validateAllFields();
                }
            }
        });
    }
}
