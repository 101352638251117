import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
 
const ID = {
    FAVORITESICON: 'favoritesIcon',
    FAVARROW: 'favoritesPopUpArrow',
    FAVPOPUP: 'favoritesPopUp',
    FAVPOPUPCONTENT: 'favPopupContent',
    FAVBUTTON: 'favoritesButton'
};
 
export default class HeaderCustom {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
 
    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
    }
 
    cacheDOMElement() {
        this.favPopUpContainer = document.getElementById(`${ID.FAVPOPUPCONTENT}`);
        this.favorites = document.getElementById(`${ID.FAVPOPUP}`);
        this.favoriteArrow = document.getElementById(`${ID.FAVARROW}`);
    }
 
    loadModules() {
        this.Ajax = new Ajax(this.element);
    }
 
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FAVORITESICON}`)) {
                e.preventDefault();
                this.togglePopup();
            }
        });
 
        // Add event listener to hide the popup when clicking outside of it or on arrow
        document.addEventListener(EVENTS.CLICK, (e) => {
            const isClickInsidePopup = this.favorites.contains(e.target);
            const isClickOnArrow = e.target.matches(`#${ID.FAVARROW}`);
            const isClickOnFavoritesIcon = e.target.matches(`#${ID.FAVORITESICON}`);
            if (!isClickInsidePopup && !isClickOnArrow && !isClickOnFavoritesIcon) {
                this.hidePopup();
            }
        });
    }
 
    togglePopup() {
        const favoriteArrow = document.getElementById(`${ID.FAVARROW}`);
        favoriteArrow.style.display = (favoriteArrow.style.display === 'block') ? 'none' : 'block';
        this.favorites.style.display = (this.favorites.style.display === 'block') ? 'none' : 'block';
        const favoriteButton = document.getElementById(`${ID.FAVBUTTON}`);
        favoriteButton.style.display = (favoriteButton.style.display === 'block');
 
        const parameter = document.getElementById('siteName').value;
        const url = `/bin/microsites/get-favorite?siteName=${parameter}`;
 
        this.Ajax.readTextFile(url, (text) => {
            const data = JSON.parse(text);
            const keys = Object.keys(data);
            let favList = '';
 
            [].forEach.call(keys, (k) => {
                if (k !== 'error') {
                    favList += `<li class="favorites-list__item">
					<a href="${k}.html" class="favorites-list__item-anchor">${data[k]}</a>
					</li>`;
                }
            });
 
            if (keys.length == 0) {
                favList += `<p style="color:black">No favorites available in your profile</p>`;
            }
 
            this.favPopUpContainer.innerHTML = favList;
        });
    }
 
    hidePopup() {
        this.favoriteArrow.style.display = 'none';
        this.favorites.style.display = 'none';
    }
}
