import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const ID = {
    SUBMITBUTTON: 'applyNowFormSubmit',
    SITE_NAME: 'site-name',
    NAME: 'applyNow-Name',
    EMAIL: 'applyNow-Email',
    PROGRAM_TYPE: 'applyNow-programType',
    RESUME: 'applyNow-fileID',
    TECH_CAREER_APPLY_NOW: 'tech-career-apply-now',
    US_CITIZENSHIP: 'applyNow-citizenship'

};

export default class ApplyNow {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'techCareers';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.ContactUsUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById(`${ID.TECH_CAREER_APPLY_NOW}`);
                    const inputs = form.elements;

                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const name = document.getElementById(`${ID.NAME}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const programType = document.getElementById(`${ID.PROGRAM_TYPE}`).value;
                    const usCitizenship = document.getElementById(`${ID.US_CITIZENSHIP}`).value;
                    console.log(usCitizenship);

                    const url = '/bin/microsites/tech-career/apply-now/sendEmail';

                    const file = document.getElementById(`${ID.RESUME}`).files[0];

                    const fileData = new FormData();

                    fileData.set('file', file);
                    fileData.set('siteName', siteName);
                    fileData.set('name', name);
                    fileData.set('email', email);
                    fileData.set('programType', programType);
                    grecaptcha.reset();

                    this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                        if (success === 'true') {
                            this.ContactUsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.ContactUsUtil.validateAllFields();
                }
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.RESUME}`)) {
                e.preventDefault();
                const subButton = document.getElementById('applyNowFormSubmit');
                const file = e.target.value;
                const ext = file.split('.').pop();
                if (ext === 'pdf' || ext === 'PDF' || ext === 'docx' || ext === 'DOCX' || ext === 'doc' || ext === 'DOC') {
                    subButton.removeAttribute('disabled');
                    document.getElementById('fileText').innerHTML = '';
                } else {
                    subButton.setAttribute('disabled', 'disabled');
                    document.getElementById('fileText').innerHTML = `${ext} format is not supported`;
                    setTimeout(() => {
                        document.getElementById('applyNow-fileID').value = '';
                    }, 100);
                }
            } else if (e.target.matches(`#${ID.US_CITIZENSHIP}`)) {
                e.preventDefault();
                const usCitizenship = document.getElementById('applyNow-citizenship').value;
                const siteName = document.getElementById('site-name').value;
                const updateUrl = '/bin/microsites/loggingAnalyticsInfo';
                const updateData = {
                    usCitizenship,
                    siteName
                };
                if (usCitizenship === 'No') {
                    document.getElementById('applyNow-fileID').setAttribute('disabled', 'disabled');
                    document.getElementById('fileText').innerHTML = 'We appreciate you taking the time to apply to MB Drive. Unfortunately, you do not fulfill the program requirements of being legally able to work in the United States with U.S. citizenship or permanent resident documentation. We encourage you to check your regional Mercedes-Benz website for open opportunities or to re-apply in the future to the US office whenever you can fulfill all the program requirements.<br><br> If you have any questions or need more clarifications, please do not hesitate to contact us at techcareers@mbusa.com';
                } else {
                    document.getElementById('applyNow-fileID').removeAttribute('disabled');
                    document.getElementById('fileText').innerHTML = '';
                }
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    console.log(`tc${success}`);
                });
            }
        });
    }
}
