import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import BRCFieldCastFormUtil from 'modules/BRCFieldCastFormUtil';


const ID = {
    SITE_NAME: 'site-name',
    SEARCH: 'search',
    DISPLAY_RESULT: 'mblease-listing',
    NOT_FOUND_RESULT: 'no-data-found',
    PAGIN: 'pagin',
    SHOWING: 'showing',
    POPUP_ID: 'pop-up-id',
    SUBMISSION_STATUS: 'submissionStatus',
    CONTENT: 'content',
    DOWNLOAD: 'download'

};

let displayData = '';
// const dashboardResult = '';
// const index = 0;


export default class BrcFieldCastSubmissionReport {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.ajaxcall();
    }


    loadModules() {
        this.BRCFieldCastFormUtil = new BRCFieldCastFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }


    ajaxcall() {
        document.getElementById('brc-field-cast-report').style.display = 'none';
        const siteName = 'brcFieldCastSubmissionReport';
        const url = '/bin/microsites/field-cast-submission-report';
        const data = {
            siteName,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            setTimeout(() => {
                const reportList = JSON.parse(success);
                if (reportList.error !== 'NO_DATA_FOUND' && success !== false) {
                    const reportListNew = JSON.parse(reportList);
                    displayData = reportListNew;

                    document.getElementById('loader').style.display = 'none';
                    // document.getElementById('mblease-listing-loader').style.display = 'none';
                    document.getElementById('brc-field-cast-report').style.display = 'block';
                    document.getElementById('brc-field-cast-report').style.width = '100%';
                    this.displayDetailsPage(displayData);
                } else {
                    document.getElementById('loader').style.display = 'none';
                    document.getElementById('brc-field-cast-report').style.display = 'block';
                    document.getElementById('brc-field-cast-report').style.width = '100%';
                    const resultDisplay = document.getElementById(`${ID.NOT_FOUND_RESULT}`);
                    resultDisplay.innerHTML = '<p class="brc-field-cast-report__no-record">No records found!</p>';
                }
            }, 5000);
        });
    }

    attachEvents() {
        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.SUBMISSION_STATUS}`)) {
                e.preventDefault();
                const status = e.target.value;
                const fileName = e.target.getAttribute('file-name');
                const siteName = 'brcFieldCastSubmissionReport';
                const updateUrl = '/bin/microsites/brc/field-cast-submission/sendEmail';
                const requesterEmail = e.target.getAttribute('data-ref');
                const d = new Date();
                let month = `${d.getMonth() + 1}`;
                let day = `${d.getDate()}`;
                const year = d.getFullYear();
                if (month.length < 2) { month = `0${month}`; }
                if (day.length < 2) { day = `0${day}`; }
                const statusDate = [month, day, year].join('-');
                const position = e.target.getAttribute('data-position');
                const updateData = {
                    status,
                    position,
                    fileName,
                    requesterEmail,
                    siteName,
                    statusDate
                };
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    console.log('sucess');

                    if (success !== 'false') {
                        if (status === 'Pending') {
                            // console.log('Pending11');
                        } else if (status === 'Approve') {
                            this.BRCFieldCastFormUtil.displayPopUp('Request Approved mail sent to user');
                            // console.log('APPROVED');
                            document.activeElement.setAttribute('disabled', 'disabled');
                        } else {
                            this.BRCFieldCastFormUtil.displayPopUp('Request Denied mail sent to user');
                            // console.log('DENIED');
                            document.activeElement.setAttribute('disabled', 'disabled');
                        }
                    }
                });
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`#${ID.DOWNLOAD}`)) {
                e.preventDefault();
                const position = e.target.getAttribute('data-position');
                const fileName = e.target.innerText;
                console.log(fileName);
                const updateUrl = '/bin/microsites/brc/field-cast-submission/download';
                const siteName = 'brcFieldCastSubmissionReport';
                const updateData = {
                    position,
                    fileName,
                    siteName
                };
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    if (success !== 'false') {
                        const params = typeof updateData === 'string' ? updateData : Object.keys(updateData).map(
                             k => `${encodeURIComponent(k)}=${encodeURIComponent(updateData[k])}`
                         ).join('&');
                        this.postAjax.open('POST', updateUrl, true);
                        this.postAjax.responseType = 'arraybuffer';
                        this.postAjax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        this.postAjax.onreadystatechange = () => {
                            if (this.postAjax.readyState === 4 &&
                                 this.postAjax.status === 200) {
                                let filename = '';
                                const arrayBuffer = this.postAjax.response;
                                const byteArray = new Uint8Array(arrayBuffer);
                                let blob = new Blob([byteArray], { type: 'text/zip' });
                                if (blob.size > 1) {
                                    if (!document.documentMode && !/Edge/.test(navigator.userAgent)) {
                                        const type = this.postAjax.getResponseHeader('Content-Type');
                                        const disposition = this.postAjax.getResponseHeader('Content-Disposition');
                                        if (disposition && disposition.indexOf('attachment') !== -1) {
                                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                            const matches = filenameRegex.exec(disposition);
                                            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                                        }
                                        if (typeof File === 'function') {
                                            blob = new File([blob], filename, { type });
                                        }
                                    }
                                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                        window.navigator.msSaveBlob(blob, filename);
                                    } else {
                                        const URL = window.URL || window.webkitURL;
                                        const downloadUrl = URL.createObjectURL(blob);
                                        if (filename) {
                                         // use HTML5 a[download] attribute to specify filename
                                            const a = document.createElement('a');

                                         // safari doesn't support this yet
                                            if (typeof a.download === 'undefined') {
                                                window.location = downloadUrl;
                                            } else {
                                                a.href = downloadUrl;
                                                a.download = filename;
                                                document.body.appendChild(a);
                                                a.click();
                                            }
                                        } else {
                                            window.open(downloadUrl, '_blank');
                                        }
                                    }
                                } else {
                                    this.BRCFieldCastFormUtil.displayPopUp('No assets available to download');
                                }
                            }
                        };
                        this.postAjax.send(params);
                    }
                });
            }
        });
    }


    displayDetailsPage(data) {
        console.log(this);
        const doubleArray = data;
        let htmlBuild = '';
        const resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
      //  const mbmleasePage = doubleArray[index];
        const entriesPerPage = doubleArray.length;
        for (let key = 0; key < entriesPerPage; key += 1) {
            const fieldCastRecord = JSON.parse(doubleArray[key]);
            const email = fieldCastRecord.requestemail;
            const topic = fieldCastRecord.topic;
            const status = fieldCastRecord.status;
            const fileName = fieldCastRecord.filename;
            const position = fieldCastRecord.position;
            const name = fieldCastRecord.name;
            const statusdate = fieldCastRecord.statusDate;
            // console.log(fieldCastRecord);
            if (fileName === undefined) {
                htmlBuild += `<tr role="row" id="reportRow" class="brc-field-cast-report__border_rule">
                    <td colspan="1" class="brc-field-cast-report__td_padding">${name}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${email}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${topic}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding" >Unavailable</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding"><select name="submissionStatus" id="submissionStatus" data-ref="${email}" data-position="${position}" class="brc-form-report__control" ><option  value="Pending">Pending</option><option  value="Approve">Approve</option><option value="Deny">Deny</option></select></td>
                   <td colspan="1" class="brc-field-cast-report__td_padding">NA</td>
                  </tr>`;
            } else if ((fileName !== undefined)) {
                if (status === 'APPROVED') {
                    htmlBuild += `<tr role="row" id="reportRow" class="brc-field-cast-report__border_rule">
                    <td colspan="1" class="brc-field-cast-report__td_padding">${name}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${email}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${topic}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding" ><a href="${fileName}" name="download" data-position="${position}" id="download">${fileName}</a></td>
                    <td colspan="1" class="brc-field-cast-report__td_padding"><select name="submissionStatus" id="submissionStatus" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="brc-form-report__control" ><option  value="Approve">Approved</option></option><option value="Deny">Deny</option></select></td>
                   <td colspan="1" class="brc-field-cast-report__td_padding">${status} on ${statusdate} </td>
                    </tr>`;
                } else if (status === 'DENIED') {
                    htmlBuild += `<tr role="row" id="reportRow" class="brc-field-cast-report__border_rule">
                    <td colspan="1" class="brc-field-cast-report__td_padding">${name}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${email}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${topic}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding" ><a href="${fileName}" name="download" data-position="${position}" id="download">${fileName}</a></td>
                    <td colspan="1" class="brc-field-cast-report__td_padding"><select name="submissionStatus" id="submissionStatus" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="brc-form-report__control" ><option  value="Deny">Denied</option></option><option value="Approve">Approve</option></select></td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${status} on ${statusdate} </td>
           </tr>`;
                } else {
                    htmlBuild += `<tr role="row" id="reportRow" class="brc-field-cast-report__border_rule">
                    <td colspan="1" class="brc-field-cast-report__td_padding">${name}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${email}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding">${topic}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding" ><a href=# name="download" data-position="${position}" id="download">${fileName}</a></td>
                    <td colspan="1" class="brc-field-cast-report__td_padding"><select name="submissionStatus" id="submissionStatus" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="brc-form-report__control" ><option  value="Pending">Pending</option><option  value="Approve">Approve</option><option value="Deny">Deny</option></select></td>
                   <td colspan="1" class="brc-field-cast-report__td_padding">${status}</td>
                    </tr>`;
                }
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }
}
