import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    HEADER: 'header',
    DOG_ARCHIVE__CONTAINER: 'dog-archive__container',
    DOG_ARCHIVE__YEAR_BLOCK: 'dog-archive__year-block',
    DOG_ARCHIVE__YEAR_LIST: 'dog-archive__year-list',
    DOG_ARCHIVE__YEAR_DROPDOWN: 'dog-archive__year-dropdown',
    DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER: 'dog-archive__year-dropdown-container',
    DOG_ARCHIVE__ITEMS_LIST: 'dog-archive__items-list',
    DOG_ARCHIVE__NAV_TOGGLE: 'dog-archive__nav-toggle',
    DOG_ARCHIVE__ITEM: 'dog-archive__item',
    DOG_ARCHIVE__ITEM_LINK: 'dog-archive__item-link',
    DOG_ARCHIVE__ITEM_ACTIVE: 'dog-archive__item--active'
};

const ID = {
    DOG_ARCHIVE__API: 'dogArchiveAPI'
};

export default class DogArchive {
    constructor(element) {
        this.element = element;
        this.apiURL = '';
        this.init();
        this.self = this.element;
        this.stickyOffset = this.yearDropdown.offsetTop;
        this.headerHeight = this.header.offsetHeight;
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.dogArchiveAPI();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.dogArchiveContainer = document.querySelector(`.${CLASSES.DOG_ARCHIVE__CONTAINER}`);
        this.dogArchiveYearBlock = document.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_BLOCK}`);
        this.dogArvchiveAPIElem = document.getElementById(`${ID.DOG_ARCHIVE__API}`);
        this.dogNavToggle = document.querySelector(`.${CLASSES.DOG_ARCHIVE__NAV_TOGGLE}`);
        this.yearDropdown = document.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN}`);
        this.yearDropdownContainer = document.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER}`);
        this.header = document.querySelector(`.${CLASSES.HEADER}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        this.dogNavToggle.addEventListener(EVENTS.CLICK, () => {
            const currentState = this.self.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER}`).getAttribute('data-page-level-nav-state');
            if (currentState === 'collapsed') {
                this.self.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER}`).setAttribute('data-page-level-nav-state', 'expanded');
            } else if (currentState === 'expanded') {
                this.self.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER}`).setAttribute('data-page-level-nav-state', 'collapsed');
            }
        });

        [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__ITEM}`)).forEach((val) => {
            val.addEventListener(EVENTS.CLICK, () => {
                [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__ITEM}`)).forEach((value) => {
                    value.classList.remove(`${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`);
                });

                val.classList.add(`${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`);

                const hrefVal = val.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEM_LINK}`).getAttribute('href').split('#')[1];
                let scrollTo = 0;

                [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__YEAR_LIST}`)).forEach((value) => {
                    const sectionYear = value.getAttribute('data-year');

                    if (sectionYear === hrefVal) {
                        scrollTo = value.offsetTop + document.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN}`).offsetHeight;
                    }
                });

                window.scroll({
                    top: scrollTo,
                    behavior: 'smooth'
                });

                this.dogArchiveDD();
                document.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_DROPDOWN_CONTAINER}`).setAttribute('data-page-level-nav-state', 'collapsed');
            });
        });

        window.addEventListener('scroll', () => {
            this.updateDD();
            this.stickyDropdown();
        });
    }

    dogArchiveAPI() {
        this.apiValue = this.dogArvchiveAPIElem.value;
        let dogArchiveHtml = '';
        let yearHtml = '';

        const data = JSON.parse(this.apiValue);

        [].forEach.call(data, (el) => {
            let classHTML = '';

            [].forEach.call(el.dogVehicleClasses, (elem) => {
                let model = '';
                const keys = Object.keys(elem.pdfPathMap);
                const values = Object.values(elem.pdfPathMap);

                [].forEach.call(keys, (element, index) => {
                    const path = values[index];

                    model += `<li class="dog-archive__vehicle-model-list">
                                    <a href="${path}" data-value="${element}" target="_blank" rel="noopener" class="link link_secondary dog-archive__link-text">${element}</a>
                                </li>`;
                });

                classHTML += `<li class="dog-archive__vehicle-class-list">
                                    <p class="dog-archive__text dog-archive__sub-title">${elem.className}</p>
                                    <ul class="dog-archive__vehicle-model-block">
                                        ${model}
                                    </ul>
                                </li>`;
            });
            dogArchiveHtml += `<li class="dog-archive__year-list" data-year="${el.year}">
                                    <p class="dog-archive__text dog-archive__title">${el.year}</p>
                                    <ul class="dog-archive__vehicle-class-block">
                                        ${classHTML}
                                    </ul>
                                </li>`;

            yearHtml += `<li class="dog-archive__item">
                            <a href="#${el.year}" class="dog-archive__item-link">
                                <span class="dog-archive__item-link-label">${el.year}</span>
                            </a>
                        </li>`;
        });
        this.element.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEMS_LIST}`).innerHTML = yearHtml;
        this.element.querySelector(`.${CLASSES.DOG_ARCHIVE__YEAR_BLOCK}`).innerHTML = dogArchiveHtml;

        document.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEM}`).classList.add(`${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`);
        this.dogArchiveDD();
    }

    dogArchiveDD() {
        const activeContent = document.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`).textContent.trim();
        this.dogNavToggle.innerHTML = activeContent;
    }

    stickyDropdown() {
        if (window.pageYOffset >= (this.yearDropdown.offsetTop + document.querySelector(`.${CLASSES.HEADER}`).offsetHeight)) {
            this.yearDropdownContainer.classList.add('sticky');
        } else {
            this.yearDropdownContainer.classList.remove('sticky');
        }
    }

    updateDD() {
        let currentSection = '';

        // get current section
        [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__YEAR_LIST}`)).forEach((value) => {
            const listOffsetTop = value.offsetTop;

            if (window.pageYOffset >= listOffsetTop) {
                currentSection = value.getAttribute('data-year');
            }
        });

        // Highlight current section link in dropdown
        [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__ITEM}`)).forEach((value) => {
            const linkValue = value.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEM_LINK}`).getAttribute('href').split('#')[1];

            if (currentSection === linkValue) {
                [].slice.call(document.querySelectorAll(`.${CLASSES.DOG_ARCHIVE__ITEM}`)).forEach((val) => {
                    val.classList.remove(`${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`);
                });

                value.classList.add(`${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`);

                const linkText = document.querySelector(`.${CLASSES.DOG_ARCHIVE__ITEM_ACTIVE}`).textContent.trim();
                this.dogNavToggle.innerHTML = linkText;
            }
        });
    }
}
