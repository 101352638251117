import { EVENTS } from 'Constants';

const CLASSES = {
    ACCORDION_BANNER__LIST: 'accordion-banner__list',
    ACCORDION_BANNER__LIST_ACTIVE: 'accordion-banner__list--active'
};

export default class AccordionBanner {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }
    cacheDOMElement() {
        this.accordionList = document.querySelectorAll(`.${CLASSES.ACCORDION_BANNER__LIST}`);
    }

    attachEvents() {
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.parentNode.classList.contains(`${CLASSES.ACCORDION_BANNER__LIST}`)) {
                this.accordionFn(e);
            }
        });
    }

    accordionFn(e) {
        const currentList = e.target.parentNode;

        [].forEach.call(this.accordionList, (elem) => {
            if (elem === currentList) {
                if (elem.classList.contains(`${CLASSES.ACCORDION_BANNER__LIST_ACTIVE}`)) {
                    currentList.classList.remove(`${CLASSES.ACCORDION_BANNER__LIST_ACTIVE}`);
                } else {
                    currentList.classList.add(`${CLASSES.ACCORDION_BANNER__LIST_ACTIVE}`);
                }
            } else {
                elem.classList.remove(`${CLASSES.ACCORDION_BANNER__LIST_ACTIVE}`);
            }
        });
    }
}
