import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    PAGINATION__INDEX_ANCHOR: 'pagination__index--anchor',
    PAGINATION__CONTENT_HIDDEN: 'pagination__content--hidden',
    PAGINATION__INDEX: 'pagination__index',
    PAGINATION__INDEX_PREVIOUS_ANCHOR: 'pagination__index--previous-anchor',
    PAGINATION__INDEX_NEXT_ANCHOR: 'pagination__index--next-anchor',
    PAGINATION__INDEX_DISABLED: 'pagination__index--disabled',
    PAGINATION__INDEX_ACTIVE: 'pagination__index--active',
    PAGINATION__INDEX_TOP: 'pagination__index--top',
    PAGINATION__INDEX_BOTTOM: 'pagination__index--bottom',
    PAGINATION__CONTENT_ANCHOR: 'pagination__content--anchor',
    PAGINATION__CONTENT__TITLE: 'pagination__content__title',
    PAGINATION__CONTENT__DESCRIPTION: 'pagination__content__description',
    PAGINATION__CONTENT_CONTAINER: 'pagination__content-container',
    PAGINATION__CONTENT: 'pagination__content',
    PAGINATION__CONTENT_NOT_FOUND: 'pagination__content--not-found',
    SEARCH_NAV__BUTTON: 'search-nav__button',
    SEARCH_NAV__BUTTON_DISABLED: 'search-nav__button-disabled',
};

const ID = {
    FORM: 'form',
    SITE_NAME: 'site-name',
    BUTTON: 'search-nav-button',
    LOADER: 'loader',
    SEARCH_NAV__INPUT: 'search-nav-input',
};

export default class Pagination {
    constructor(element) {
        this.element = element;
        this.numberOfLinks = null;
        this.paginationContentAnchorItems = null;
        this.numberOfPerPageLinks = 10;

        this.totalNumberOfPagination = null;
        this.startRangeIndex = 1;
        this.endRangeIndex = 10;
        this.getActivePaginationNumber = 1;
        this.sendActivePaginationNumber = 0;

        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.setSearchNavAttributes();
        this.getEntries();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.http = new XMLHttpRequest();
    }

    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`)) {
                this.sendActivePaginationNumber = this.getActivePaginationNumber;
                this.getEntries();
            } else if (e.target.matches(`.${CLASSES.PAGINATION__INDEX_PREVIOUS_ANCHOR}`)) {
                this.sendActivePaginationNumber = this.getActivePaginationNumber - 2;
                this.getEntries();
            } else if (e.target.matches(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`)) {
                this.sendActivePaginationNumber = parseInt(e.target.innerHTML, 10) - 1;
                this.getEntries();
            }
        });
    }

    setSearchNavAttributes() {
        console.log(this);
        const url = location.href;
        const searchNavInput = document.getElementById(`${ID.SEARCH_NAV__INPUT}`);
        const searchNavForm = document.getElementById(`${ID.FORM}`);
        searchNavInput.value = url.split('=')[1] === undefined ? '' : unescape(url.split('=')[1].replaceAll('+', ' '));
        searchNavForm.action = url.split('?')[0];
    }

    displayPaginationContent(paginationIndex, numberOfPerPageLinks, numberOfLinks,
        paginationContentAnchors) {
        console.log(this);
        [].forEach.call(paginationContentAnchors, (el) => {
            el.classList.add(`${CLASSES.PAGINATION__CONTENT_HIDDEN}`);
        });
        const startIndex = (paginationIndex - 1) * numberOfPerPageLinks;
        let endIndex = paginationIndex * numberOfPerPageLinks;
        if (numberOfLinks <= endIndex) {
            endIndex = numberOfLinks;
        }
        for (let i = startIndex; i < endIndex; i += 1) {
            if (paginationContentAnchors[i].classList.contains(`${CLASSES.PAGINATION__CONTENT_HIDDEN}`)) {
                paginationContentAnchors[i].classList.remove(`${CLASSES.PAGINATION__CONTENT_HIDDEN}`);
            }
        }
    }

    // to create pagination index according to the number of pages required.
    createPagination(paginationIndexContainer, startIndex, endIndex) {
        console.log(this);
        [].forEach.call(paginationIndexContainer, (el) => {
            while (el.hasChildNodes()) {
                el.removeChild(el.lastChild);
            }

            const startAnchor = document.createElement('span');
            startAnchor.setAttribute('class', `${CLASSES.PAGINATION__INDEX_ANCHOR} ${CLASSES.PAGINATION__INDEX_PREVIOUS_ANCHOR}`);
            startAnchor.innerHTML = '&laquo;';
            el.appendChild(startAnchor);
            for (let i = startIndex; i <= endIndex; i += 1) {
                const anchor = document.createElement('span');
                anchor.setAttribute('class', `${CLASSES.PAGINATION__INDEX_ANCHOR}`);
                anchor.innerHTML = i;
                el.appendChild(anchor);
            }
            const endAnchor = document.createElement('span');
            endAnchor.setAttribute('class', `${CLASSES.PAGINATION__INDEX_ANCHOR} ${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`);
            endAnchor.innerHTML = '&raquo;';
            el.appendChild(endAnchor);
        });
    }

    checkIcon(Anchors, targetValue, limitValue) {
        console.log(this);
        [].forEach.call(Anchors, (el) => {
            if (targetValue !== limitValue) {
                el.classList.remove(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
                el.setAttribute('disabled', 'false');
            } else {
                el.classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
                el.setAttribute('disabled', 'true');
            }
        });
    }

    displayCompleteContent(results) {
        console.log(this);
        const paginationContentContainer = document.querySelector(`.${CLASSES.PAGINATION__CONTENT}`);
        while (paginationContentContainer.hasChildNodes()) {
            paginationContentContainer.removeChild(paginationContentContainer.lastChild);
        }

        [].forEach.call(results, (el) => {
            const contentContainer = document.createElement('div');
            contentContainer.setAttribute('class', `${CLASSES.PAGINATION__CONTENT_CONTAINER}`);
            const contentAnchor = document.createElement('a');
            contentAnchor.setAttribute('class', `${CLASSES.PAGINATION__CONTENT_ANCHOR}`);
            const title = document.createElement('h2');
            title.setAttribute('class', `${CLASSES.PAGINATION__CONTENT__TITLE}`);
            const description = document.createElement('div');
            description.setAttribute('class', `${CLASSES.PAGINATION__CONTENT__DESCRIPTION}`);
            title.innerHTML = el.title;
            if (el.summary !== null) {
                description.innerHTML = el.summary.replace(/<br>/gi, '');
            }
            contentAnchor.setAttribute('href', el.url);
            contentContainer.appendChild(title);
            contentContainer.appendChild(description);
            contentAnchor.appendChild(contentContainer);
            paginationContentContainer.appendChild(contentAnchor);
        });
    }

    displayNoResults() {
        console.log(this);
        const paginationContentContainer = document.querySelector(`.${CLASSES.PAGINATION__CONTENT}`);
        paginationContentContainer.classList.add(`${CLASSES.PAGINATION__CONTENT_NOT_FOUND}`);
        paginationContentContainer.innerHTML = 'No Results Found';
    }

    displayBadGatewayError() {
        console.log(this);
        const paginationContentContainer = document.querySelector(`.${CLASSES.PAGINATION__CONTENT}`);
        paginationContentContainer.classList.add(`${CLASSES.PAGINATION__CONTENT_NOT_FOUND}`);
        paginationContentContainer.innerHTML = 'Something went wrong.. Please try again';
    }

    getEntries() {
        const pageUrl = location.href;
        const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        const parameter = pageUrl.split('=')[1].split('+').join('%2B').split('#')[0];
        if (parameter !== undefined && parameter !== '') {
            const parameterUrl = parameter.replace(/%252B/ig, '%2B');
            const url = `/bin/microsites/search?searchString=${parameterUrl}&siteName=${siteName}&startIndex=${this.sendActivePaginationNumber}`;
            this.disableElements();
            this.Ajax.readTextFile(url, (text) => {
     //           this.http.overrideMimeType('application/json');
                this.http.open('GET', url, true);
                this.http.onreadystatechange = () => {
                    if (this.http.readyState === 4 && this.http.status === 200) {
                        const paginationIndexContainer = document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX}`);
                        const data = JSON.parse(text);
                        if (data.error !== undefined && data.error !== '') {
                            setTimeout(this.displayBadGatewayError(), 3000);
                        } else {
                            if (this.numberOfLinks) {
                                if (this.numberOfLinks >= data.resultCount) {
                                    this.numberOfLinks = data.resultCount;
                                }
                            } else {
                                this.numberOfLinks = data.resultCount;
                            }
                            const pageSize = 10;
                            const startIndex = data.startIndex;
                            const paginationContentContainer = document.querySelector(`.${CLASSES.PAGINATION__CONTENT}`);

                            this.getActivePaginationNumber = Math.ceil(startIndex / pageSize);
                            this.totalNumberOfPagination = Math.ceil(this.numberOfLinks / pageSize);

                            if (data.results !== undefined && data.results.length !== 0) {
                                paginationContentContainer.classList.remove(`${CLASSES.PAGINATION__CONTENT_NOT_FOUND}`);
                                this.displayCompleteContent(data.results);
                                if (this.endRangeIndex > this.totalNumberOfPagination) {
                                    this.endRangeIndex = this.totalNumberOfPagination;
                                }
                                this.updatePaginationRange();
                                this.createPagination(paginationIndexContainer,
                                this.startRangeIndex, this.endRangeIndex);
                                this.activePagination();
                                this.checkDisablePagination();
                            } else {
                                setTimeout(this.displayNoResults(), 3000);
                            }
                        }
                    } else if (this.http.readyState === 4 && this.http.status === 502) {
                        setTimeout(this.displayBadGatewayError(), 3000);
                    }
                    this.enableElements();
                };
                this.http.send(null);
            });
        }
    }

    activePagination() {
        const paginationAnchors = document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_ANCHOR}`);
        [].forEach.call(paginationAnchors, (el) => {
            if (parseInt(el.innerHTML, 10) === this.getActivePaginationNumber) {
                el.classList.add(`${CLASSES.PAGINATION__INDEX_ACTIVE}`);
            }
        });
    }

    updatePaginationRange() {
        if (this.getActivePaginationNumber === this.endRangeIndex) {
            if (this.getActivePaginationNumber !== this.totalNumberOfPagination) {
                this.endRangeIndex += 3;
                this.startRangeIndex = this.endRangeIndex - 9;
            }
        } else if (this.getActivePaginationNumber === this.startRangeIndex) {
            if (this.getActivePaginationNumber !== 1) {
                if ((this.startRangeIndex - 3) < 1) {
                    this.startRangeIndex = 1;
                } else {
                    this.startRangeIndex -= 3;
                }
                this.endRangeIndex = this.startRangeIndex + 9;
            }
        }
    }

    checkDisablePagination() {
        if (this.getActivePaginationNumber === this.endRangeIndex) {
            if (this.getActivePaginationNumber === this.totalNumberOfPagination) {
                document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`)[0].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
                document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`)[1].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
            } else {
                document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`)[0].classList.remove(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
                document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_NEXT_ANCHOR}`)[1].classList.remove(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
            }
        } else if (this.getActivePaginationNumber === this.startRangeIndex) {
            document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_PREVIOUS_ANCHOR}`)[0].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
            document.querySelectorAll(`.${CLASSES.PAGINATION__INDEX_PREVIOUS_ANCHOR}`)[1].classList.add(`${CLASSES.PAGINATION__INDEX_DISABLED}`);
        }
    }

    disableElements() {
        console.log(this);
        document.getElementById(`${ID.LOADER}`).style.display = 'block';
        const button = document.getElementById(`${ID.BUTTON}`);
        button.disabled = true;
        button.classList.add(`${CLASSES.SEARCH_NAV__BUTTON_DISABLED}`);
        button.classList.remove(`${CLASSES.SEARCH_NAV__BUTTON}`);
        document.getElementById(`${ID.SEARCH_NAV__INPUT}`).readOnly = true;
    }

    enableElements() {
        console.log(this);
        document.getElementById(`${ID.LOADER}`).style.display = 'none';
        const button = document.getElementById(`${ID.BUTTON}`);
        button.disabled = false;
        button.classList.add(`${CLASSES.SEARCH_NAV__BUTTON}`);
        button.classList.remove(`${CLASSES.SEARCH_NAV__BUTTON_DISABLED}`);
        document.getElementById(`${ID.SEARCH_NAV__INPUT}`).readOnly = false;
    }
}
