import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import MeetingsEventsUtil from 'modules/MeetingsEventsUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'meeting-and-events-form',
    SITE_NAME: 'site-name',
    ATTENDEES: 'attendees',
    BUDGET_PLANYES: 'budgetPlanYes',
    BUDGET_PLANNO: 'budgetPlanNo',
    SPECIAL_REQYES: 'splReqYes',
    SPECIAL_REQNO: 'splReqNo',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    JOB_TITLE: 'jobTitle',
    EMAIL: 'email',
    AREA_CODE: 'areaCode',
    PHONE_NUMBER: 'phoneNumber',
    PURPOSE_OF_MEETING: 'purposeofMeeting',
    LOCATION_FOR_EVENT: 'loc4Event',
    EVENT_START_DATE: 'eventStartDate',
    EVENT_END_DATE: 'eventEndDate',
    OF_ATTENDEES: 'ofAttendees',
    OF_HOTELS: 'ofHotels',
    PLANNED_BUDGET: 'planbudget',
    UNPLANNED_BUDGET: 'unPlanbudget',
    EXPLAIN: 'explain',
    DEPARTMENT: 'department',
};

export default class MeetingsEvents {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'meetingsEvents';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.MeetingsEventsUtil = new MeetingsEventsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const whoWillChk = document.getElementsByName('attendees');

                const checkedAttendeesDL = [];
                for (let i = 0; i < whoWillChk.length; i += 1) {
                    if (whoWillChk[i].checked) {
                        checkedAttendeesDL.push(whoWillChk[i].value);
                    }
                }

                const facilityChk = document.getElementsByName('facilities');


                const checkedFacilityDL = [];
                for (let i = 0; i < facilityChk.length; i += 1) {
                    if (facilityChk[i].checked) {
                        checkedFacilityDL.push(facilityChk[i].value);
                    }
                }

                const evFacilityChk = document.getElementsByName('chooseEventFacilities');

                const checkedEVFacilityDL = [];
                for (let i = 0; i < evFacilityChk.length; i += 1) {
                    if (evFacilityChk[i].checked) {
                        checkedEVFacilityDL.push(evFacilityChk[i].value);
                    }
                }

                const budPlanChk = document.getElementsByName('budgetPlanRadio');
                const whoWillChkErr = document.getElementById('whoWillCheckbox');
                const facilityChkErr = document.getElementById('facilityCheckbox');
                const evFacilityChkErr = document.getElementById('eventFacilityCheckbox');
                const budPlanChkErr = document.getElementById('budgetChk');
                let okayCk = false;
                let facCk = false;
                let evFacCk = false;
                let bgtCK = false;
                let finalCk = false;

                for (let i = 0; i < whoWillChk.length; i += 1) {
                    if (whoWillChk[i].checked) {
                        okayCk = true;
                        break;
                    }
                }

                for (let j = 0; j < facilityChk.length; j += 1) {
                    if (facilityChk[j].checked) {
                        facCk = true;
                        break;
                    }
                }

                for (let k = 0; k < evFacilityChk.length; k += 1) {
                    if (evFacilityChk[k].checked) {
                        evFacCk = true;
                        break;
                    }
                }

                for (let l = 0; l < budPlanChk.length; l += 1) {
                    if (budPlanChk[l].checked) {
                        bgtCK = true;
                        break;
                    }
                }

                if (okayCk) {
                    whoWillChkErr.classList.add('meetings-events-form__error-field--hidden');
                } else {
                    whoWillChkErr.classList.remove('meetings-events-form__error-field--hidden');
                }

                const facilityOth = document.getElementById('otherFacilities');
                if (facCk || facilityOth.value) {
                    checkedFacilityDL.push(facilityOth.value);
                    facilityChkErr.classList.add('meetings-events-form__error-field--hidden');
                    facilityOth.setAttribute('required', 'false');
                    this.MeetingsEventsUtil.displayDefault(facilityOth);
                } else {
                    facilityChkErr.classList.remove('meetings-events-form__error-field--hidden');
                    facilityOth.setAttribute('required', 'true');
                }

                if (evFacCk) {
                    evFacilityChkErr.classList.add('meetings-events-form__error-field--hidden');
                } else {
                    evFacilityChkErr.classList.remove('meetings-events-form__error-field--hidden');
                }

                if (bgtCK) {
                    budPlanChkErr.classList.add('meetings-events-form__error-field--hidden');
                } else {
                    budPlanChkErr.classList.remove('meetings-events-form__error-field--hidden');
                }

                const plnBgt = document.getElementById('planbudget');
                const unPlnBgt = document.getElementById('unPlanbudget');
                const plnBgtYes = document.getElementById('budgetPlanYes');
                const unPlnBgtNo = document.getElementById('budgetPlanNo');
                const plnBgtLbl = document.getElementById('plnBgtLabel');
                const unPlnBgLbl = document.getElementById('unPlnBgtLabel');
                if (plnBgtYes.checked === true) {
                    plnBgt.setAttribute('required', 'true');
                    unPlnBgt.setAttribute('required', 'false');
                    this.MeetingsEventsUtil.displayDefault(unPlnBgt);
                    plnBgtLbl.innerHTML = 'IF PLANNED DURING OP, WHAT IS THE BUDGET ?:<span class="meetings-events-form__asterisk_field"> * </span>';
                    unPlnBgLbl.innerHTML = 'IF NOT PLANNED DURING OP, WHAT IS THE BUDGET ?:';
                } else if (unPlnBgtNo.checked === true) {
                    unPlnBgt.setAttribute('required', 'true');
                    plnBgt.setAttribute('required', 'false');
                    this.MeetingsEventsUtil.displayDefault(plnBgt);
                    plnBgtLbl.innerHTML = 'IF PLANNED DURING OP, WHAT IS THE BUDGET ?:';
                    unPlnBgLbl.innerHTML = 'IF NOT PLANNED DURING OP, WHAT IS THE BUDGET ?:<span class="meetings-events-form__asterisk_field"> * </span>';
                }

                const splReqYes = document.getElementById('splReqYes');
                const splReqNo = document.getElementById('splReqNo');
                const explainReq = document.getElementById('explain');
                const explainLabel = document.getElementById('explainLabel');

                if (splReqYes.checked === true) {
                    explainReq.setAttribute('required', 'true');
                    explainLabel.innerHTML = 'IF YES, PLEASE EXPLAIN:<span class="meetings-events-form__asterisk_field"> * </span>';
                } else if (splReqNo.checked === true) {
                    explainReq.setAttribute('required', 'false');
                    explainLabel.innerHTML = 'IF YES, PLEASE EXPLAIN:';
                    this.MeetingsEventsUtil.displayDefault(explainReq);
                }

                if (okayCk && facCk && evFacCk && bgtCK) {
                    finalCk = true;
                }


                let tempBudgetPlan = '';
                if (document.getElementById(`${ID.BUDGET_PLANYES}`).checked) {
                    tempBudgetPlan = document.getElementById(`${ID.BUDGET_PLANYES}`).value;
                }

                if (document.getElementById(`${ID.BUDGET_PLANNO}`).checked) {
                    tempBudgetPlan = document.getElementById(`${ID.BUDGET_PLANNO}`).value;
                }

                const budgetPlan = tempBudgetPlan;

                let tempSpecialReq = '';
                if (document.getElementById(`${ID.SPECIAL_REQYES}`).checked) {
                    tempSpecialReq = document.getElementById(`${ID.SPECIAL_REQYES}`).value;
                }

                if (document.getElementById(`${ID.SPECIAL_REQNO}`).checked) {
                    tempSpecialReq = document.getElementById(`${ID.SPECIAL_REQNO}`).value;
                }

                const specialReq = tempSpecialReq;
                const capRes = grecaptcha.getResponse();
                if (this.MeetingsEventsUtil.validateAllFields() && finalCk && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;

                    const siteName = 'meetings-events';
                    const url = '/bin/microsites/meetings-events-questionnaire/sendEmail';
                    const firstName = document.getElementById(`${ID.FIRST_NAME}`).value;
                    const lastName = document.getElementById(`${ID.LAST_NAME}`).value;
                    const jobTitle = document.getElementById(`${ID.JOB_TITLE}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const areaCode = document.getElementById(`${ID.AREA_CODE}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                    const purposeOfMeeting = document.getElementById(`${ID.PURPOSE_OF_MEETING}`).value;
                    const locationForEvent = document.getElementById(`${ID.LOCATION_FOR_EVENT}`).value;
                    const eventStartDate = document.getElementById(`${ID.EVENT_START_DATE}`).value;
                    const eventEndDate = document.getElementById(`${ID.EVENT_END_DATE}`).value;
                    const ofAttendees = document.getElementById(`${ID.OF_ATTENDEES}`).value;
                    const ofHotels = document.getElementById(`${ID.OF_HOTELS}`).value;
                    const plannedBudget = document.getElementById(`${ID.PLANNED_BUDGET}`).value;
                    const unPlannedBudget = document.getElementById(`${ID.UNPLANNED_BUDGET}`).value;
                    const explain = document.getElementById(`${ID.EXPLAIN}`).value;
                    const department = document.getElementById(`${ID.DEPARTMENT}`).value;


                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('firstName', firstName);
                    dataMap.set('lastName', lastName);
                    dataMap.set('jobTitle', jobTitle);
                    dataMap.set('email', email);
                    dataMap.set('areaCode', areaCode);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('purposeOfMeeting', purposeOfMeeting);
                    dataMap.set('locationForEvent', locationForEvent);
                    dataMap.set('eventStartDate', eventStartDate);
                    dataMap.set('eventEndDate', eventEndDate);
                    dataMap.set('ofAttendees', ofAttendees);
                    dataMap.set('ofHotels', ofHotels);
                    dataMap.set('plannedBudget', plannedBudget);
                    dataMap.set('unPlannedBudget', unPlannedBudget);
                    dataMap.set('explain', explain);
                    dataMap.set('budgetPlan', budgetPlan);
                    dataMap.set('specialReq', specialReq);
                    dataMap.set('checkedAttendeesDL', checkedAttendeesDL);
                    dataMap.set('checkedFacilityDL', checkedFacilityDL);
                    dataMap.set('checkedEVFacilityDL', checkedEVFacilityDL);
                    dataMap.set('department', department);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.MeetingsEventsUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.MeetingsEventsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.MEETINGS_EVENTS_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.MEETINGS_EVENTS_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.MeetingsEventsUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });
    }
}
