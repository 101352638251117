import Ajax from 'modules/Ajax';

export default class Notification {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.ajaxcall();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    ajaxcall() {
        const siteName = document.getElementById('site-name').value;
        const url = '/bin/microsites/notification';
        const data = {
            siteName,
        };
        this.Ajax.ajaxPostFn(url, data, (success) => {
            if (success !== 'false') {
                console.log(success);
                const notificationResponce = JSON.parse(success);
                const excluded = notificationResponce.excludedSite;
                const custom = notificationResponce.customNotification;
                const defaultText = notificationResponce.defaultNotification;
                if (excluded === 'true') {
                    document.getElementById('main').style.display = 'none';
                } else if (custom !== null) {
                    document.getElementById('notification').innerHTML = `${notificationResponce.customNotification}`;
                } else if (defaultText !== null && defaultText !== '') {
                    document.getElementById('notification').innerHTML = `${notificationResponce.defaultNotification}`;
                } else {
                    document.getElementById('main').style.display = 'none';
                }
            }
        });
    }
}
