import loadingSpinnerTemplate from './../templates/loadingSpinnerTemplate';

/**
 * @const defaultConfig
 * @description Default configuration for view
 * @type {{
 *     isInline: boolean,
 *     isLarge: boolean
 * }}
 */
const defaultConfig = {
    isInline: false,
    isLarge: false
};

/**
 * View responsible for rendering the LoadingSpinner module
 */
export default class LoadingSpinner {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param config {Object} Configuration properties for view
     * @param config.isInline {Boolean} Indicator for whether the view should display inline
     * @param config.isLarge {Boolean} Indicator for whether the spinner should display large
     */
    constructor(config = {}) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.element = loadingSpinnerTemplate(config)({ getNode: true }); // DOM element to render
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.element.remove();
    }

    /**
     * @method render
     * @description Renders the element
     */
    render() {
        return this.element;
    }
}

