/**
 * @constant {Object} CONFIG
 * @type {{
    *     zipCode: {
    *         us: RegExp,
    *         ca: RegExp,
    *         mx: RegExp,
    *     },
    *     HTTP_PROTOCOL: string,
    *     HTTPS_PROTOCOL: string
    * }}
*/
const CONFIG = {
    zipCode: {
        us: /^\d{5}(?:[-\s]\d{4})?$/,
        ca: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        mx: /(^\d{5}$)/
    },
    HTTP_PROTOCOL: 'http://',
    HTTPS_PROTOCOL: 'https://'
};

/**
* Return the country set by microsites pageData configuration
* @returns {string}
*/
function getPageDataCountry() {
    const {
        pageData: {
            country = 'us'
        } = {}
    } = window.microsites || {};

    return country;
}

/**
 * @function isMatchValue
 * @description Validate the provided values match one another
 * @param {compareValue} Comparison value to validate against
 * @param {value} Field input value
 * @returns {boolean}
 */
export function isMatchValue(compareValue, value) {
    return compareValue.toLowerCase() === value.toLowerCase();
}

/**
 * @method isValidAge
 * @description checks if age matches or is older than expected value
 * @param birthday {String} user input birthday to verify
 * @param ageRestriction {Number} number to verify against
 * @param years {Number} optional years to verify (e.g. employmentYears)
 * @returns {boolean}
 */
export function isValidAge(birthday, ageRestriction, years = 0) {
    const restrictionDate = new Date();
    const userBirthday = new Date(birthday);
    restrictionDate.setFullYear(restrictionDate.getFullYear() - ageRestriction - years);
    return restrictionDate.getTime() >= userBirthday.getTime();
}

/**
 * @function isValidSSN
 * @desc Finds 9 digit numbers within groupings, not separated or separated by -,
 * not starting with 000, or 900-999,
 * not containing 00 or 0000 in the middle or at the
 * end of SSN (in compliance with current SSN rules) and
 * not containing all of the same numbers (555-55-5555)
 * @param ssn
 * @returns {boolean}
 */
export function isValidSSN(ssn) {
    return (
        (/\b(?!000)(?!9)[0-9]{3}[-]?(?!00)[0-9]{2}[-]?(?!0000)[0-9]{4}\b/.test(ssn)) &&
        !(/\b((.+).*(\1.*)){3}[-]?(.+).*(\1.*){2}[-]?(.+).*(\1.*){4}\b/.test(ssn))
    );
}

/**
 * @function isValidCASSN
 * @desc Used luhn algorithm to validate SIN
 * @param sin
 * @returns {boolean}
 */
export function isValidCASSN(sin) {
    const sinNumber = sin.replace(/-/g, '');
    const len = sinNumber.length;
    if (len) {
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i -= 1) {
            let d = parseInt(sinNumber.charAt(i), 10);
            if (i % 2 === parity) { d *= 2; }
            if (d > 9) { d -= 9; }
            sum += d;
        }
        const result = sum % 10;
        return result === 0;
    }
    return true;
}

/**
 * @function isValidZip
 * @description Validate the provided zip against the current country's zip regex
 */
export function isValidZip(zip, countryCode) {
    return CONFIG.zipCode[countryCode || getPageDataCountry()].test(zip);
}

/**
 * @function isValidZipStart
 * @description Checks if the zip is the beginning of a valid zip
 * @param {String} zip - ZIP code that needs to be validated
 * @return {Boolean} true if the zip is a valid beginning of zip/postal code
 */
export function isValidZipStart(zip, countryCode) {
    switch (countryCode || getPageDataCountry()) {
    case 'us':
    case 'mx':
        return !isNaN(Number(zip));
    case 'ca':
        zip = zip.replace(/\s/, '');
            // Create an array of regex patterns that match each character for canadian postal code
            // Use the input string to get number of patterns to be used
        return !!zip.match(['[ABCEGHJKLMNPRSTVXY]', '\\d', '[ABCEGHJ-NPRSTV-Z]', '\\d', '[ABCEGHJ-NPRSTV-Z]', '\\d'].slice(0, zip.length).join(''));
    default:
        return true;
    }
}

/**
 * @function isExternalLink
 * @description Validate if uri is an external resource
 * @param uri {string} URI to test for internal/external resource
 * return {boolean}
 */
export function isExternalLink(uri) {
    return uri.indexOf(CONFIG.HTTP_PROTOCOL) === 0 || uri.indexOf(CONFIG.HTTPS_PROTOCOL) === 0;
}

/**
 * @function isPlace
 * @description Validate if str matches 'place, province' pattern
 */
export function isPlace(str) {
    // \u0080 - \u024F matches special characters ' to ɏ
    // needed for french names like L'Île-Perrot, QC
    return /[a-z\u0080-\u024F.|-|'\s]*,\s*[a-z]+/ig.test(str);
}

/**
 * @function isString
 * @param item {*} Item to test if is a string
 * @return {boolean}
 */
export function isString(item) {
    return Object.prototype.toString.call(item) === '[object String]';
}

/**
 * @function isArray
 * @param item {*} Item to test if is an array
 * @return {boolean}
 */
export function isArray(item) {
    return Object.prototype.toString.call(item) === '[object Array]';
}

/**
 * @function isObject
 * @param item {*} Item to test if is an object
 * @return {boolean}
 */
export function isObject(item) {
    return Object.prototype.toString.call(item) === '[object Object]';
}

/**
 * @function isFunction
 * @param item {*} Item to test if is a function
 * @return {boolean}
 */
export function isFunction(item) {
    return Object.prototype.toString.call(item) === '[object Function]';
}

/**
 * @function isNumber
 * @param item {*} Item to test if is a number
 * @return {boolean}
 */
export function isNumber(item) {
    return Object.prototype.toString.call(item) === '[object Number]';
}

/**
 * @function isEmpty
 * @param item {*} Item to test if is empty
 * @return {boolean}
 */
export function isEmpty(item) {
    if (item === null) { return true; }
    if (isArray(item) || isString(item)) { return item.length === 0; }
    let empty = true;

    Object.keys(item).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
            empty = false;
        }
    });

    return empty;
}

/**
 * @method isEmail
 * @param value {string} String value to test email pattern of xxxx@x.x
 * @returns {boolean} Indicator for whether the string is a valid email address
 */
export function isEmail(value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    if (value === '' || value === null) {
        return true;
    }

    return pattern.test(value);
}

/**
 * @function isMinValue
 * @param {String|Number} minValue - Min Allowed Value
 * @param {String|Number} value - Value to check against
 * @returns {Boolean} True if value >= minValue, and both are numbers
 */
export function isMinValue(minValue, value) {
    const min = parseFloat(minValue);
    const val = parseFloat(value);
    return !(isNaN(min) || isNaN(val)) && min <= val;
}

/**
 * @function isMaxValue
 * @param {String|Number} maxValue - Max Allowed Value
 * @param {String|Number} value - Value to check against
 * @returns {Boolean} True if value <= maxValue, and both are numbers
 */
export function isMaxValue(maxValue, value) {
    const max = parseFloat(maxValue);
    const val = parseFloat(value);
    return !(isNaN(max) || isNaN(val)) && max >= val;
}

/**
 * @function isMaxLength
 * @param length {Number} The length to test the string value
 * @param value {string} String value to test length
 * @returns {boolean}
 */
export function isMaxLength(length, value) {
    return value.toString().length <= length;
}

/**
 * @function isMinLength
 * @param length {Number} The length to test the string value
 * @param value {string} String value to test length
 * @returns {boolean}
 */
export function isMinLength(length, value) {
    return value.toString().length >= length;
}

/**
 * @function isPhoneNumber
 * @param value {string} String value used to test if phone number or not
 * @description The pattern used to match rejects if the passed value has alphabets, special
 * characters or has less than 10 or more than 13 digits (excluding the optional + at beginning)
 * @returns {boolean}
 */
export function isPhoneNumber(value) {
    return /^\+{0,1}\d{10,13}$/i.test(value);
}

/**
 * @method isFormattedPhoneNumber
 * @description Test that a value matches a formatted phone number
 * 415-555-1234 || 650-555-2345 || (416)555-3456 || 202 555 4567
 * 4035555678 || 1 416 555 9292
 * @param value {String} Value to test
 * @return {boolean}
 */
export function isFormattedPhoneNumber(value) {
    return /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/.test(value);
}

/**
 * @method isPhoneAllSameCharacter
 * @desc Test if a value has all matching characters
 * example: used on unformatted phone number (222) 222-2222 || (555) 555-5555
 * @param value {String} value to test
 * @return {boolean}
 */
export function isPhoneAllSameCharacter(value) {
    const comparableValue = value.replace(/[^0-9]+/ig, '');
    return /^(.)\1*$/.test(comparableValue);
}

/**
 * @method isCharacterPresent
 * @desc Tests a string for the presence of a list of characters
 * @param characters {Array} array of characters to validate
 * @param value {String} value to test
 * @return {boolean}
 */
export function isCharacterPresent(characters, value) {
    return characters.some(character => value.indexOf(character) >= 0);
}

/**
* @function objectsAreEqual

* @desc Tests if provided objects are the same
* @param args {Array} objects to be compared
* @returns {boolean}
*/
export function objectsAreEqual(...args) {
    let i;
    let l;
    let leftChain;
    let rightChain;

    function compare2Objects(x, y) {
        let p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (Object.prototype.isPrototypeOf.call(x, y) ||
            Object.prototype.isPrototypeOf.call(y, x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        /* eslint-disable no-restricted-syntax */
        for (p in y) {
            /* eslint-enable */
            if (Object.prototype.hasOwnProperty.call(y, p) !==
                Object.prototype.hasOwnProperty.call(x, p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        /* eslint-disable no-restricted-syntax */
        /* eslint-disable guard-for-in */
        for (p in x) {
            /* eslint-enable */
            if (Object.prototype.hasOwnProperty.call(y, p) !==
                Object.prototype.hasOwnProperty.call(x, p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof (x[p])) {
            case 'object':
            case 'function':
                leftChain.push(x);
                rightChain.push(y);

                if (!compare2Objects(x[p], y[p])) {
                    return false;
                }

                leftChain.pop();
                rightChain.pop();
                break;
            default:
                if (x[p] !== y[p]) {
                    return false;
                }
                break;
            }
        }

        return true;
    }

    if (args.length < 1) {
        return true; // Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = args.length; i < l; i += 1) {
        leftChain = []; // Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(args[0], args[i])) {
            return false;
        }
    }

    return true;
}

/**
 * @method hasInputBlacklistCharacters
 * @description Test that at value has blacklisted characters <>\ for input fields
 * @param value {String} Value to test
 * @return {boolean}
 */
export function hasInputBlacklistCharacters(value) {
    return /[\\<>]/.test(value);
}

/**
 * @method isValidDate
 * @description Test that a date is formatted correctly for
 * locale and is a valid date according to JS Date class
 * @param value {String} Value to test
 * @param config {Object} contains locale field and optional min and max fields
 * @return {boolean}
 */
export function isValidDate(value, config = { country: 'us' }) {
    const isFormattedForLocale = config.country.toLowerCase() === 'us' ? /[0-1]\d\/[0-3]\d\/[0-9]{4}/.test(value) : /[0-3]\d\/[0-1]\d\/[0-9]{4}/.test(value);

    if (/[/-]/.test(value) && config.country !== 'us') {
        const valueSplit = value.split(/[/-]/);
        const zeroIndex = valueSplit.shift();
        value = valueSplit.splice(1, 0, zeroIndex).join('/');
    }

    return isFormattedForLocale && new Date(value).toString() !== 'Invalid Date';
}

/**
 * @method isInDateRange
 * @description Test that a date is within a provided range
 * @param value {String} Value to test
 * @param config {Object} optional min and max fields
 * @return {boolean}
 */
export function isInDateRange(value, config = {}) {
    let isLessThanMax = true;
    let isGreaterThanMin = true;
    const dateValue = new Date(value);
    if (config.minDate) {
        const minDate = new Date(config.minDate);
        isGreaterThanMin = dateValue > minDate;
    }
    if (config.maxDate) {
        const maxDate = new Date(config.maxDate);
        isLessThanMax = dateValue < maxDate;
    }
    return isLessThanMax && isGreaterThanMin;
}

/**
 * @function isValidFileType
 * @description Validates that the file is of one of the MIME types
 * @param {File} file - File object to be validated
 * @param {string[]} acceptedFileTypes - An array of mime types that is used for validation rules
 * @returns {boolean} True if valid
 */
export function isValidFileType(file, acceptedFileTypes) {
    return Array.isArray(acceptedFileTypes) && file && acceptedFileTypes.indexOf(file.type) !== -1;
}

/**
 * @function isValidFileSize
 * @description Validates that the file size is within the max limit
 * @param {File} file - File object to be validated
 * @returns {boolean} True if valid
 */
export function isValidFileSize(file, maxFileSize) {
    return !isNaN(maxFileSize) && file && file.size < maxFileSize;
}

/**
* A public API that provides utilities for validating user's input
* @module validate
* @property {Function} hasInputBlacklistCharacters
* @property {Function} isArray
* @property {Function} isCharacterPresent
* @property {Function} isEmail
* @property {Function} isEmpty
* @property {Function} isFormattedPhoneNumber
* @property {Function} isFunction
* @property {Function} isInDateRange
* @property {Function} isMatchValue
* @property {Function} isMaxLength
* @property {Function} isMaxValue
* @property {Function} isMinValue
* @property {Function} isMinLength
* @property {Function} isNumber
* @property {Function} isPhoneAllSameCharacter
* @property {Function} isPhoneNumber
* @property {Function} isPlace
* @property {Function} isString
* @property {Function} isValidDate
* @property {Function} isValidAge
* @property {Function} isValidFileType
* @property {Function} isValidFileSize
* @property {Function} isValidCASSN
* @property {Function} isValidSSN
* @property {Function} isValidZip
* @property {Function} isValidZipStart
* @property {Function} objectsAreEqual
*/
export default {
    hasInputBlacklistCharacters,
    isArray,
    isCharacterPresent,
    isEmail,
    isEmpty,
    isExternalLink,
    isFormattedPhoneNumber,
    isFunction,
    isInDateRange,
    isMatchValue,
    isMaxLength,
    isMaxValue,
    isMinValue,
    isMinLength,
    isNumber,
    isObject,
    isPhoneAllSameCharacter,
    isPhoneNumber,
    isPlace,
    isString,
    isValidDate,
    isValidAge,
    isValidFileType,
    isValidFileSize,
    isValidCASSN,
    isValidSSN,
    isValidZip,
    isValidZipStart,
    objectsAreEqual
};

