/**
 * Template for waypoint nav
 * Uses ES6 template literal to composite the markup for a waypoint nav
 * @param waypoints {Array} the array of waypoint nav items
 */
export default (project = {}) => `
    <div class="data-deletion__modal-content__container">
        <div class="data-deletion__modal-content__text">
            <p>Are you sure, you want to delete ${project} records?</p>
        </div>
        <div class="data-deletion__modal-content__btns">
            <button id="yes"class="button button_primary">Yes</button>
            <button id="no"class="button button_primary">No</button>
        </div>
    </div>`;
