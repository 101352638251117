/**
 * Module with utility and helper functions for Google Maps module
 */

const METER_RATIO = 1609.344;

/**
 * @method getShortestDistance
 * @description Sorts an array and returns the lowest value
 * @param array [Array] Collection of numbers
 * @returns {Number} The lowest number in the array
 */
function getShortestDistance(array) {
    const sorted = array.sort((a, b) => (a > b));

    return sorted[0];
}

/**
 * @method milesToMeters
 * @description Converts mile units to meter units
 * @param miles {number} The number unit to convert
 * @returns {number} Meters
 */
function milesToMeters(miles) {
    return miles * METER_RATIO;
}

/**
 * @method metersToMiles
 * @description Converts meters units to mile units
 * @param meters {number} The number unit to convert
 * @returns {number} Miles
 */
function metersToMiles(meters) {
    return meters / METER_RATIO;
}

/**
 * @method getDistance
 * @description Retrieves the distance/radius of the visible boundaries from a google maps object
 * @param map {object} Google Map object
 * @returns {number} The radial distance in miles
 */
function getDistance(map) {
    const computeDistanceBetween = google.maps.geometry.spherical.computeDistanceBetween;

    // Get the map coordinates and bounds
    const bounds = map.getBounds();
    const center = bounds.getCenter();

    // Get the N, E, and NE coordinates of the map based on the bounds
    const ne = bounds.getNorthEast();
    const north = new google.maps.LatLng(ne.lat(), center.lng());
    const east = new google.maps.LatLng(center.lat(), ne.lng());

    // Calculate the N, E and NE radius in meters
    const radiusCollection = [
        computeDistanceBetween(center, north), // N
        computeDistanceBetween(center, east), // E
        computeDistanceBetween(center, ne) // NE
    ];

    // Get the shortest radius
    const radius = getShortestDistance(radiusCollection);

    return Math.floor(metersToMiles(radius));
}

/**
 * @function fromLatLngToPoint
 * @description Converts a Lat/Lng to Pixel distance from top left of map container element
 * @refer: https://stackoverflow.com/a/12026134
 * @param {google.maps.LatLng} Co-ordinates for which pixel distance should be calculated
 * @param {google.maps.Map} Map instance on which the co-ordinate should be translated to pixel
 * @param {google.maps} Map API object
 * @returns {google.maps.Point} Point object which has x/y values for distance from top left corner
 */
function fromLatLngToPoint(latLng, mapInstance, googleMapsAPI) {
    // Scale is calculated based on
    // https://developers.google.com/maps/documentation/javascript/coordinates#pixel-coordinates
    const scale = 2 ** mapInstance.getZoom();
    const mapBounds = mapInstance.getBounds();
    const mapProjection = mapInstance.getProjection();

    // Google API doesn't provide function to get north west
    // Calculate is using North from NE and South from SW
    const nw = new googleMapsAPI.LatLng(mapBounds.getNorthEast().lat(),
                mapBounds.getSouthWest().lng());
    const worldCoordinateNW = mapProjection.fromLatLngToPoint(nw);
    const worldCoordinate = mapProjection.fromLatLngToPoint(latLng);

    return new googleMapsAPI.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale));
}

/**
 * Export public methods
 */
export default {
    getDistance,
    milesToMeters,
    fromLatLngToPoint
};

