import { html } from 'utils';

function getColumn(classValue, formType) {
    if (formType === 'fep-eab-filter' && (classValue === 'stateCode' || classValue === 'city' || classValue === 'companyName' || classValue === 'zip' || classValue === 'vppType')) {
        return '';
    }
    return `<td class="${classValue}"></td>`;
}

export default (columnsClassList = [], formType) => html`
<tr>${columnsClassList.map(classValue => `
${getColumn(classValue, formType)}`).join('')}</tr>`;
