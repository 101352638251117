import DogHeader from 'modules/DogHeader';
import FAQV2 from 'modules/FAQV2';
import Tab from 'modules/Tab';

export default class Dog {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.loadModules();
    }

    loadModules() {
        this.DogHeader = new DogHeader(this.element);
        this.FAQV2 = new FAQV2(this.element);
        this.Tab = new Tab(this.element);
    }
}
