import { EVENTS } from 'Constants';

export default class Print {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        console.log(this);
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.id === 'print') {
                window.print();
            }
        });
    }
}
