import { EVENTS } from 'Constants';

import Ajax from 'modules/Ajax';

const ID = {
    SUBMITBUTTON: 'submit-btn',
    REGISTRATION_FORM: 'dealer-lookup-form',
    DEALER_CODE: 'dealerCode',
    DEALER_STATE: 'dealerState',
    FRANCHISE: 'franchise',
    ERROR_LINE: 'errorLine',
    RESULT_DEALER_LOOKUP: 'result-dealer-lookup',
    DEALER_LOOKUP_ERROR_FIELD_HIDDEN: 'dealer-lookup__error-field--hidden',
    DOWNLOAD_EXCEL: 'downloadExcel',
};

export default class DealerLookup {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                document.getElementById('loader').style.display = 'block';
                document.getElementById('result-dealer-lookup').style.display = 'none';
                const dealerCode = document.getElementById(`${ID.DEALER_CODE}`);
                const dealerState = document.getElementById(`${ID.DEALER_STATE}`);
                const franchise = document.getElementById(`${ID.FRANCHISE}`);
                const errorLine = document.getElementById(`${ID.ERROR_LINE}`);
                if ((this.isValidDealerCodeAndState(dealerCode.value, dealerState.value,
                     franchise.value))) {
                    errorLine.classList.add(`${ID.DEALER_LOOKUP_ERROR_FIELD_HIDDEN}`);
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;

                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const siteName = 'dealer-lookup';
                    const url = '/bin/microsites/dealer-lookup';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        setTimeout(() => {
                            document.getElementById('loader').style.display = 'none';
                            document.getElementById('result-dealer-lookup').style.display = 'block';
                            const apiResponse = JSON.parse(success);
                            this.displayResult(apiResponse);
                            document.getElementById('downloadExcel').removeAttribute('disabled');
                        }, 500);
                    });
                } else {
                    errorLine.classList.remove(`${ID.DEALER_LOOKUP_ERROR_FIELD_HIDDEN}`);
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.DOWNLOAD_EXCEL}`)) {
                e.preventDefault();

                let excelName = '';
                const table = document.getElementById('result-table-data');
                const code = document.getElementById('dealerCode').value;
                const state = document.getElementById('dealerState');
                const franchise = document.getElementById('franchise');

                const stateVal = state.value;
                const typeVal = franchise.value;

                let dealerState = state.options[state.selectedIndex].text;
                let dealerType = franchise.options[franchise.selectedIndex].text;


                if (dealerState === 'Select State') {
                    dealerState = '';
                }

                if (dealerType === 'Select Dealer Type') {
                    dealerType = '';
                }

                if (code !== '' || stateVal !== '' || typeVal !== '') {
                    excelName = `${code}${dealerState}${dealerType}_dealerLookup.csv`;
                }
                /* Declaring array variable */
                const rows = [];
                // iterate through rows of table
                for (let i = 0; i < table.rows.length; i += 2) {
                // rows would be accessed using the "row" variable assigned in the for loop
                // Get each cell value/column from the row
                // Adding "\x1F" for values strating with 0, adding "\t" for phone no.
                    const column1 = `\x1F${table.rows[i].cells[0].innerText}`;
                    const column2 = `\x1F${table.rows[i].cells[1].innerText}`;
                    const column3 = `\x1F${table.rows[i].cells[2].innerText}`;
                    const column4 = `\x1F${table.rows[i].cells[3].innerText}`;
                    const column5 = `\x1F${table.rows[i].cells[4].innerText}`;
                    const column6 = `\x1F${table.rows[i].cells[5].innerText}`;
                    const column7 = `\x1F${table.rows[i].cells[6].innerText}`;
                    const column8 = `\x1F${table.rows[i].cells[7].innerText}\t`;


                /* add a new records in the array, adding '"'+ for treating values with comma */
                    rows.push(
                        [
                            `"${column1}"`,
                            `"${column2}"`,
                            `"${column3}"`,
                            `"${column4}"`,
                            `"${column5}"`,
                            `"${column6}"`,
                            `"${column7}"`,
                            `"${column8}"`
                        ]
                );
                }
                let csvContent = '';
                 /* add the column delimiter as comma(,)
                  * and each row splitted by new line character (\n) */
                rows.forEach((rowArray) => {
                    csvContent += `${rowArray}\r\n`;
                });


                const universalBOM = '\uFEFF';
                const a = window.document.createElement('a');
                a.setAttribute('href', `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`);
                a.setAttribute('download', excelName);
                window.document.body.appendChild(a);
                a.click();
            }
        });
    }

    isValidDealerCodeAndState(dealerCode, dealerState, franchise) {
        console.log(this);
        if (dealerCode !== '') {
            const regex = /^\d{5}$/;
            return regex.test(dealerCode);
        } else if (dealerState !== '') {
            return true;
        } else if (franchise !== '') {
            return true;
        }

        return false;
    }

    displayResult(apiResponse) {
        console.log(this);
        const dealerRes = JSON.parse(apiResponse.dealerResponse);
        console.log(dealerRes);
        const resultDisplay = document.getElementById(`${ID.RESULT_DEALER_LOOKUP}`);
        let htmlResult = `<table id="result-table-data" class="dealer-lookup__result-table">
        <colgroup>
        <col style="width: 5%;" />
        <col style="width: 5%;" />
        <col style="width: 5%;" />
        <col style="width: 20%;" />
        <col style="width: 20%;" />
        <col style="width: 20%;" />
        <col style="width: 10%;" />
        <col style="width: 8%;" />
        </colgroup>
        <tr>
        <td class="dealer-lookup__result-table-padding"><b>Region</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Market</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Dealer</b></td>
        <td class="dealer-lookup__result-table-padding"><b>DBA Name</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Dealer Name</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Address</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Town</b></td>
        <td class="dealer-lookup__result-table-padding"><b>Phone</b></td>
        </tr>
        <tr>
        <td colspan="8"><hr class="dealer-lookup__line_separator"></td>
        </tr>`;
        const dealerArr = dealerRes.dealers.dealer;
        if (dealerArr.hasOwnProperty.length && dealerArr.length === undefined) {
            const regionCode = dealerArr.regionCode;
            const marketCode = dealerArr.marketCode;
            const dealerCode = dealerArr.dealerCode;
            const dealerName = dealerArr.dealerName;
            const dbaName = dealerArr.dbaName;
            const address1 = dealerArr.addressLine1;
            const state = dealerArr.state;
            const zip = dealerArr.zip;
            const town = dealerArr.city;
            const phone = dealerArr.phone;
            htmlResult += `<tr>
            <td class="dealer-lookup__result-table-padding">${regionCode}</td>
            <td class="dealer-lookup__result-table-padding">${marketCode}</td>
            <td class="dealer-lookup__result-table-padding">${dealerCode}</td>
            <td class="dealer-lookup__result-table-padding">${dbaName}</td>
            <td class="dealer-lookup__result-table-padding">${dealerName}</td>
            <td class="dealer-lookup__result-table-padding">${address1}, ${state}-${zip}.</td>
            <td class="dealer-lookup__result-table-padding">${town}</td>
            <td class="dealer-lookup__result-table-padding">${phone}</td>
            </tr>
            <tr>
            <td colspan="8"><hr></td>
            </tr>`;
        } else {
            for (let i = 0; i < dealerArr.length; i += 1) {
                const regionCode = dealerArr[i].regionCode;
                const marketCode = dealerArr[i].marketCode;
                const dealerCode = dealerArr[i].dealerCode;
                const dealerName = dealerArr[i].dealerName;
                const dbaName = dealerArr[i].dbaName;
                const address1 = dealerArr[i].addressLine1;
                const state = dealerArr[i].state;
                const zip = dealerArr[i].zip;
                const town = dealerArr[i].city;
                const phone = dealerArr[i].phone;
                htmlResult += `<tr>
                <td class="dealer-lookup__result-table-padding">${regionCode}</td>
                <td class="dealer-lookup__result-table-padding">${marketCode}</td>
                <td class="dealer-lookup__result-table-padding">${dealerCode}</td>
                <td class="dealer-lookup__result-table-padding">${dbaName}</td>
                <td class="dealer-lookup__result-table-padding">${dealerName}</td>
                <td class="dealer-lookup__result-table-padding">${address1}, ${state}-${zip}.</td>
                <td class="dealer-lookup__result-table-padding">${town}</td>
                <td class="dealer-lookup__result-table-padding">${phone}</td>
                </tr>
                <tr>
                <td colspan="8"><hr></td>
                </tr>`;
            }
        }
        htmlResult += '</table>';
        resultDisplay.innerHTML = htmlResult;
    }
}
