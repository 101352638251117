import { EVENTS, ATTRIBUTES } from 'Constants';
import Ajax from 'modules/Ajax';

const ID = {
    ADDFEEDBACK: 'addFeedback',
    POPUPFAV: 'pop-up-fav',
    POPUPFEEDBACK: 'pop-up-feedbackInput',
    FEEDBACKINPUT: 'feedbackInput',
    FEEDBACKSUBMIT: 'feedbackSubmit',
    FEEDBACKINCLUDE: 'feedbackInclude'
};

const CLASSES = {
    POPUP: 'pop-up',
    POPUP_OPEN: 'pop-up--open',
    POPUP_CONTENT_BODY: 'pop-up__content-body',
    POPUP__CONTENT_CLOSE: 'pop-up__content--close'
};

export default class WarrantyFeedback {
    constructor(element) {
        this.Ajax = new Ajax(element);
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.favPopup = document.getElementById(`${ID.POPUPFAV}`);
        this.feedbackPopup = document.getElementById(`${ID.POPUPFEEDBACK}`);
        this.closeButtonList = document.getElementsByClassName(`${CLASSES.POPUP__CONTENT_CLOSE}`);
        this.modalPopUpList = document.getElementsByClassName(`${CLASSES.POPUP}`);
        this.feedbackButton = document.getElementById(`${ID.FEEDBACKINCLUDE}`);
        this.feedbackSubmitButton = document.getElementById(`${ID.FEEDBACKSUBMIT}`);
    }

    attachEvents() {
        this.feedbackButton.addEventListener(EVENTS.CLICK, () => {
            this.feedbackPopup.classList.add(`${CLASSES.POPUP_OPEN}`);
        });

        this.feedbackSubmitButton.addEventListener(EVENTS.CLICK, () => {
            this.sendFeedbackFn();
        });

        [].forEach.call(this.closeButtonList, (el) => {
            el.addEventListener(EVENTS.CLICK, () => {
                [].forEach.call(this.modalPopUpList, (element) => {
                    element.classList.remove(`${CLASSES.POPUP_OPEN}`);
                });
            });
        });
    }

    sendFeedbackFn() {
        const pagePath = document.getElementById(`${ATTRIBUTES.CURRENTPAGEPATH}`).value;
        const siteName = document.getElementById(`${ATTRIBUTES.SITENAME}`).value;
        const feedback = document.getElementById(`${ID.FEEDBACKINPUT}`).value;
        const url = '/bin/microsites/warranty/add-feedback';
        const data = {
            siteName,
            pagePath,
            feedback
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            this.feedbackPopup.classList.remove(`${CLASSES.POPUP_OPEN}`);
            if (success === 'true') {
                this.makePopup('Feedback submitted successfully');
            } else {
                this.makePopup('Feedback submission failed');
            }
        });
    }

    makePopup(success) {
        this.favPopup.getElementsByClassName(`${CLASSES.POPUP_CONTENT_BODY}`)[0].innerHTML = success;
        this.favPopup.classList.add('pop-up--open');
    }
}
