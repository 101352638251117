import React, { useState, useRef, Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import emailUsFormApi from "../api/emailUsFormApi";
import initialEmailUsForm from "../constants/initialEmailUsForm";
import TextField from "./TextField";
import LoaderSpinnerReact from "../../commons/components/LoaderSpinnerReact";
import FILE_TYPE_CONSTANTS from "../constants/FILE_TYPE_CONSTANTS";
import TextArea from "./TextArea";
import UserFeedbackModal from "./UserFeedbackModal";


class EmailUsFormComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            emailUsForm: initialEmailUsForm,
            uploadFiles: [],
            reCaptchaToken: "",
            isRecaptchCheck: false,
            isFileUploadSizeOk: true,
            isFormSent: { statusCode: false, message: "" },
            isLoading: false,
            isFormValid: false,
        };

        this.captchaRef = React.createRef(null);
        this.newIsFormValid = this.newIsFormValid;

        this.getErrorClassForFields = this.getErrorClassForFields.bind(this);
        this.getErrorClassForAreas = this.getErrorClassForAreas.bind(this);
        this.getErrorClassForLabels = this.getErrorClassForLabels.bind(this);
        this.getErrorClassForSelectLabels =
            this.getErrorClassForSelectLabels.bind(this);
        this.getErrorClassForSelect = this.getErrorClassForSelect.bind(this);
        this.setFieldValueToForm = this.setFieldValueToForm.bind(this);
        this.onTouchedHandler = this.onTouchedHandler.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.onFieldChangeHandler = this.onFieldChangeHandler.bind(this);
        this.onUploadChangeHandler = this.onUploadChangeHandler.bind(this);
        this.onUploadDeleteHandler = this.onUploadDeleteHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.recaptchaChangeHandler = this.recaptchaChangeHandler.bind(this);
        this.recaptchaExpireHandler = this.recaptchaExpireHandler.bind(this);
        this.setState = this.setState.bind(this);
        this.modal = null;

        // All the handler function in one object.
        this.handlers = {
            onFocusHandler: this.onFocusHandler,
            onFieldChangeHandler: this.onFieldChangeHandler,
            getErrorClassForLabels: this.getErrorClassForLabels,
            getErrorClassForFields: this.getErrorClassForFields,
            getErrorClassForAreas: this.getErrorClassForAreas,
            onTouchedHandler: this.onTouchedHandler,
            getConfig: emailUsFormApi.getConfig,
        };

        this.responseLabel =
            this.state.isFormSent.statusCode === 200
                ? this.state.isFormSent.message
                : this.state.isFormSent.statusCode === 415
                    ? this.state.isFormSent.message
                    : this.state.isFormSent.statusCode === 500
                        ? this.state.isFormSent.message
                        : "";
    }

    componentDidUpdate(prevProp, prevState) {
        // Checks "Is email us form valid or not?"

        this.newIsFormValid =
            this.state.emailUsForm.city.isValid &&
            this.state.emailUsForm.email.isValid &&
            this.state.emailUsForm.address.isValid &&
            this.state.emailUsForm.firstName.isValid &&
            this.state.emailUsForm.inquiryType.isValid &&
            this.state.emailUsForm.lastName.isValid &&
            this.state.emailUsForm.phone.isValid &&
            this.state.emailUsForm.zipCode.isValid &&
            this.state.emailUsForm.state.isValid &&
            (this.state.emailUsForm.vin.value ? this.state.emailUsForm.mileage.isValid : true) &&
            this.state.isRecaptchCheck;

        if (prevState.isFormValid != this.newIsFormValid) {
            this.setState({
                isFormValid: this.newIsFormValid,
            });
        }
    }
    /**
     *
     * @param {string} fieldName
     * @returns {string} error class for fields
     */
    getErrorClassForFields(fieldName) {
        const field = this.state.emailUsForm[fieldName];
        return field.isTouched && !field.isValid
            ? "onInput utl-border-red"
            : "onInput utl-border-gray";
    }

    /**
     *
     * @param {string} fieldName
     * @returns {string} error class for fields
     */
    getErrorClassForAreas(fieldName) {
        const field = this.state.emailUsForm[fieldName];
        return field.isTouched && !field.isValid
            ? "onTextArea onInput utl-border-red"
            : "onTextArea onInput utl-border-gray";
    }

    /**
     *
     * @param {string} fieldName
     * @returns {string} error class for labels
     */
    getErrorClassForLabels(fieldName) {
        const field = this.state.emailUsForm[fieldName];
        /* eslint-disable no-nested-ternary */
        return field.isFocused && !field.isTouched
            ? "onLabelUp onLabel"
            : field.isTouched && !field.isValid && field.isFocused
                ? "onErrorLabel onLabel"
                : field.isTouched &&
                    !field.isValid &&
                    !field.isFocused &&
                    field.value.trim() !== ""
                    ? "onErrorLabel onLabel"
                    : field.isTouched &&
                        !field.isValid &&
                        !field.isFocused &&
                        field.value.trim() === ""
                        ? "onErrorLabelDown onErrorLabel onLabel"
                        : !field.isFocused && field.isTouched && field.isValid
                            ? "onLabelUpValid onLabel"
                            : field.isFocused && field.isTouched && field.isValid
                                ? "onLabelUpValid onLabel"
                                : "onLabel onLabelDown";
    }

    /**
     *
     * @param {string} fieldName
     * @returns {string} error class for selector labels
     */
    getErrorClassForSelectLabels(fieldName) {
        const field = this.state.emailUsForm[fieldName];
        return field.isTouched && !field.isValid ? "onErrorLabel" : "onLabel";
    }

    /**
     *
     * @param {string} fieldName
     * @returns {string} error class for selector tag
     */
    getErrorClassForSelect(fieldName) {
        const field = this.state.emailUsForm[fieldName];
        return field.isTouched && !field.isValid
            ? "onSelectTouched onSelect"
            : "onSelect";
    }

    /**
     * Utility method to set the field values to emailUsForm state.
     * @param {string} fieldName
     * @param {string | number} fieldValue
     */
    setFieldValueToForm(fieldName, fieldValue) {
        const setState = this.setState.bind(this);
        const newEmailUsForm = { ...this.state.emailUsForm };
        newEmailUsForm[fieldName].value = fieldValue;
        emailUsFormApi.validateEmailUsForm(
            newEmailUsForm,
            this.state.emailUsForm,
            setState
        );
    }

    /**
     * Method will set the isTouched to true and isFocused to false when textfield gets blured.
     * @param {*} event
     */
    onTouchedHandler(event) {
        const emailUsFormField = {
            ...this.state.emailUsForm[event.target.name],
            isTouched: true,
            isFocused: false,
        };
        const { name } = event.target;
        this.setState((prevState) => {
            return {
                emailUsForm: {
                    ...prevState.emailUsForm,
                    [name]: emailUsFormField,
                },
            };
        });
    }

    /**
     * Method will set the isFocused to true when textfield will get focus.
     * @param {*} event
     */
    onFocusHandler(event) {
        const emailUsFormField = {
            ...this.state.emailUsForm[event.target.name],
            isFocused: true,
        };
        const { name } = event.target;
        this.setState((prevState) => {
            return {
                emailUsForm: {
                    ...prevState.emailUsForm,
                    [name]: emailUsFormField,
                },
            };
        });
    }

    /**
     * Method will triggered on specified textfield when it's value will get change.
     * @param {*} event
     */
    onFieldChangeHandler(event) {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === "firstName" || name === "lastName") {
            if (/^[a-zA-Z ]{0,80}$/g.test(value)) {
                this.setFieldValueToForm(name, value);
            }
        } else if (name === "vin") {
            if (/^[a-zA-Z0-9]{0,17}$/g.test(value)) {
                this.setFieldValueToForm(name, value);
            }
            if (!Boolean(value.length)) {
                this.setState((prevState) => {
                    return {
                        emailUsForm: {
                            ...prevState.emailUsForm,
                            ['mileage']: initialEmailUsForm.mileage,
                        },
                    };
                });
            }
        } else if (name === "zipCode") {
            if (/^[0-9]{0,5}$/g.test(value)) {
                this.setFieldValueToForm(name, value);
            }
        } else if (name === "mileage") {
            if (/^[0-9]{0,6}$/g.test(value)) {
                this.setFieldValueToForm(name, value);
            }
        } else if (name === "phone") {
            const formattedPhoneNumber =
                emailUsFormApi.formatPhoneNumber(value);
            this.setFieldValueToForm(name, formattedPhoneNumber);
        } else if (name === "email") {
            if (value.length <= 40) {
                this.setFieldValueToForm(name, value);
            }
        } else if (name === "commentBody") {
            if (value.length <= 1500) {
                this.setFieldValueToForm(name, value);
            }
        } else {
            this.setFieldValueToForm(name, value);
        }
    }

    /**
     * Method will triggered on file's upload. And updates the uploadFiles state hook.
     * @param {*} event
     */
    onUploadChangeHandler(event) {
        event.preventDefault();
        const newUploadFiles = [...this.state.uploadFiles];
        /* eslint-disable no-plusplus */
        for (let i = 0; i < event.target.files.length; i++) {
            if (FILE_TYPE_CONSTANTS.includes(event.target.files[i].type)) {
                if (event.target.files[i].size < 25 * 1e6) {
                    newUploadFiles.push(event.target.files[i]);
                    this.setState({ isFileUploadSizeOk: true });
                } else {
                    this.setState({ isFileUploadSizeOk: false });
                }
            } else {
                //
            }
        }
        this.setState({ uploadFiles: newUploadFiles });
        event.target.value = null; // for old browsers.
    }

    /**
     * Method will delete the file from uploadFiles state hook from specific index.
     * @param {*} event
     * @param {number} fileIndex
     */
    onUploadDeleteHandler(event, fileIndex) {
        event.preventDefault();
        const newUploadFiles = [...this.state.uploadFiles];
        newUploadFiles.splice(fileIndex, 1);
        this.setState({ uploadFiles: newUploadFiles });
    }

    /**
     * Method will reset captaRef, CaptchaCheck, uploadFiles, emailUsForm state hooks.
     * And captures the form filled data and will send via api
     * @function onSubmit
     * @param {*} event
     */
    async onSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true });
        await emailUsFormApi.sendFormData(
            this.state.uploadFiles,
            this.state.emailUsForm,
            this.state.reCaptchaToken,
            this.setState
        );
        this.setState({ isLoading: false });
        this.captchaRef.current.reset();
        this.setState({ isRecaptchCheck: false });
        this.setState({ uploadFiles: [] });
        this.setState({ emailUsForm: initialEmailUsForm });
        this.setState({ isFileUploadSizeOk: true });
        this.setState({ isFormValid: false });
        this.modal.openModel();
    }

    recaptchaChangeHandler() {
        const captchaToken = this.captchaRef.current.getValue();
        this.setState({ isRecaptchCheck: true });
        this.setState({
            reCaptchaToken: captchaToken,
        });
    }

    recaptchaExpireHandler() {
        const captchaToken = this.captchaRef.current.getValue();
        this.captchaRef.current.reset();
        this.setState({ reCaptchaToken: captchaToken });
        this.setState({ isRecaptchCheck: false });
    }
    render() {
        return (
            <div className="email-us-form-container">
                <div className="email-us-form-header-container">
                    <h1>{emailUsFormApi.getConfig("heading")}</h1>
                    <p>{emailUsFormApi.getConfig("subHeading")}</p>
                </div>
                <div className="email-us-form-content-container">
                    <form
                        action=""
                        className="email-us-form-content"
                        onSubmit={this.onSubmit}
                    >
                        <div className="email-us-form-util-container email-us-form-field-container">
                            <label
                                htmlFor="inquiryType"
                                className={this.getErrorClassForSelectLabels(
                                    "inquiryType"
                                )}
                            >{`${emailUsFormApi.getConfig(
                                "inquiryType"
                            )}*`}</label>
                            <select
                                name="inquiryType"
                                id="inquiryType"
                                onChange={(event) =>
                                    this.onFieldChangeHandler(event)
                                }
                                onFocus={(event) => this.onFocusHandler(event)}
                                onBlurCapture={(event) =>
                                    this.onTouchedHandler(event)
                                }
                                className={this.getErrorClassForSelect(
                                    "inquiryType"
                                )}
                                value={this.state.emailUsForm.inquiryType.value}
                            >
                                <option value="">
                                    Please select inquiry type
                                </option>
                                <option value="Roadside">Roadside</option>
                                <option value="General/Customer Care">
                                    General/Customer Care
                                </option>
                                <option value="Mercedes Me Connect">
                                    Mercedes Me Connect
                                </option>
                            </select>
                        </div>
                        <TextField
                            name="firstName"
                            type="text"
                            value={this.state.emailUsForm.firstName.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.firstName.isValid}
                            isTouched={
                                this.state.emailUsForm.firstName.isTouched
                            }
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .firstNameError
                            }
                            required={true}
                        />
                        <TextField
                            name="lastName"
                            type="text"
                            value={this.state.emailUsForm.lastName.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.lastName.isValid}
                            isTouched={
                                this.state.emailUsForm.lastName.isTouched
                            }
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .lastNameError
                            }
                            required={true}
                        />

                        <TextField
                            name="email"
                            type="email"
                            value={this.state.emailUsForm.email.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.email.isValid}
                            isTouched={this.state.emailUsForm.email.isTouched}
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .emailError
                            }
                            required={true}
                        />
                        <TextField
                            name="phone"
                            type="tel"
                            value={this.state.emailUsForm.phone.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.phone.isValid}
                            isTouched={this.state.emailUsForm.phone.isTouched}
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .phoneError
                            }
                            required={true}
                        />
                        <TextField
                            name="address"
                            type="text"
                            value={this.state.emailUsForm.address.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.address.isValid}
                            isTouched={this.state.emailUsForm.address.isTouched}
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .addressError
                            }
                            required={true}
                        />
                        <TextField
                            name="city"
                            type="text"
                            value={this.state.emailUsForm.city.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.city.isValid}
                            isTouched={this.state.emailUsForm.city.isTouched}
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels")
                                    .cityError
                            }
                            required={true}
                        />
                        <div className="email-us-form-util-container email-us-form-field-container">
                            <label
                                htmlFor="state"
                                className={this.getErrorClassForSelectLabels(
                                    "state"
                                )}
                            >{`${emailUsFormApi.getConfig("state")}*`}</label>
                            <select
                                name="state"
                                id="state"
                                onChange={this.onFieldChangeHandler}
                                onFocus={this.onFocusHandler}
                                onBlurCapture={this.onTouchedHandler}
                                className={this.getErrorClassForSelect("state")}
                                value={this.state.emailUsForm.state.value}
                            >
                                <option value="">
                                    Please select your state
                                </option>
                                {emailUsFormApi
                                    .getListOfStates()
                                    .map((state) => (
                                        <option
                                            key={state}
                                            value={state.toLowerCase()}
                                        >
                                            {state}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <TextField
                            name="zipCode"
                            type="number"
                            value={this.state.emailUsForm.zipCode.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.zipCode.isValid}
                            isTouched={this.state.emailUsForm.zipCode.isTouched}
                            errorContent={
                                emailUsFormApi.getConfig("errorLabels").zipError
                            }
                            required={true}
                        />
                        <TextField
                            name="vin"
                            type="text"
                            value={this.state.emailUsForm.vin.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.vin.isValid}
                            isTouched={this.state.emailUsForm.vin.isTouched}
                            errorContent=""
                            required={false}
                        />
                        {
                            Boolean(this.state.emailUsForm.vin.value) && <TextField
                                name="mileage"
                                type="number"
                                value={this.state.emailUsForm.mileage.value}
                                handlers={this.handlers}
                                isValid={this.state.emailUsForm.mileage.isValid}
                                isTouched={this.state.emailUsForm.mileage.isTouched}
                                errorContent={
                                    emailUsFormApi.getConfig("errorLabels").mileageError
                                }
                                required={Boolean(this.state.emailUsForm.vin.value.length)}
                            />
                        }

                        <TextArea
                            name="commentBody"
                            type="text"
                            value={this.state.emailUsForm.commentBody.value}
                            handlers={this.handlers}
                            isValid={this.state.emailUsForm.commentBody.isValid}
                            isTouched={
                                this.state.emailUsForm.commentBody.isTouched
                            }
                            errorContent=""
                            required={false}
                        />
                        <div className="email-us-form-upload-container">
                            <label
                                htmlFor="fileUpload"
                                id="email-us-file-attachment"
                                className="onLabel"
                            >
                                {emailUsFormApi.getConfig("attachment")}
                            </label>
                            {this.state.uploadFiles.map((uploadFile, index) => (
                                <div key={index} className="utl-upload">
                                    <div className="uploaded-file-container">
                                        <span>
                                            {uploadFile.name.length < 18
                                                ? uploadFile.name
                                                : `${uploadFile.name.substring(
                                                    0,
                                                    20
                                                )}... .${uploadFile.name.split(
                                                    "."
                                                )[
                                                uploadFile.name.split(
                                                    "."
                                                ).length - 1
                                                ]
                                                }`}
                                        </span>
                                        <div
                                            className="deleteUpload"
                                            onClick={(event) =>
                                                this.onUploadDeleteHandler(
                                                    event,
                                                    index
                                                )
                                            }
                                        >
                                            X
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="upload-button-box">
                                <label
                                    htmlFor="fileUpload"
                                    id="email-us-file-upload-btn"
                                    title="Upload Registration/Lease Documents (Must be in .pdf or .doc format)"
                                >
                                    Upload
                                </label>
                                <label>
                                    {emailUsFormApi.getConfig(
                                        "uploadFileDescription"
                                    )}
                                </label>
                            </div>
                            <input
                                type="file"
                                name=""
                                id="fileUpload"
                                multiple
                                style={{ display: "none" }}
                                onChange={this.onUploadChangeHandler}
                                accept={FILE_TYPE_CONSTANTS}
                            />
                            {this.state.isFileUploadSizeOk ? (
                                ""
                            ) : (
                                <label className="onErrorLabel">
                                    Upload file should be less than 25MB.
                                </label>
                            )}
                        </div>
                        <div className="email-us-form-submit-container button-with-captcha">
                            <ReCAPTCHA
                                ref={this.captchaRef}
                                sitekey={emailUsFormApi.getReCaptchaSiteKey()}
                                onChange={this.recaptchaChangeHandler}
                                onExpired={this.recaptchaExpireHandler}
                            />
                            <div>
                                <button
                                    type="submit"
                                    disabled={!this.state.isFormValid}
                                >
                                    {this.state.isLoading ? (
                                        <LoaderSpinnerReact
                                            height="20px"
                                            border="5px"
                                        />
                                    ) : (
                                        emailUsFormApi.getConfig("submit")
                                    )}
                                </button>
                            </div>
                        </div>
                        {this.responseLabel ? (
                            <div
                                className={
                                    this.state.isFormSent.statusCode === 200
                                        ? "request-label request-black"
                                        : "request-label request-red"
                                }
                            >
                                <p>
                                    {this.responseLabel ||
                                        "Something went wrong"}
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </form>
                </div>

                {this.state.isFormSent.statusCode === 200 ?
                    <UserFeedbackModal
                        heading={emailUsFormApi.getConfig("userFeedbackModal").successHeading}
                        message={emailUsFormApi.getConfig("userFeedbackModal").successMessage}
                        okButton={emailUsFormApi.getConfig("userFeedbackModal").okButton}
                        ref={(modal) => { this.modal = modal; }} />
                    :
                    <UserFeedbackModal
                        heading={emailUsFormApi.getConfig("userFeedbackModal").failureHeading}
                        message={emailUsFormApi.getConfig("userFeedbackModal").failureMessage}
                        okButton={emailUsFormApi.getConfig("userFeedbackModal").okButton}
                        ref={(modal) => { this.modal = modal; }} />
                }

            </div>
        );
    }
}

export default EmailUsFormComponent;
