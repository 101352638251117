import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';

const LINK_LIST_V2_CLASS = {
    ACCORDION__LIST: 'accordion__link-list-v2',
    FAVORITE: 'favorite-item__link-list-v2',
    NOT_SELECTED: 'link-list__icon-gray-color',
    SELECTED: 'link-list__icon-list',
    LOADER: 'custom-loader',
    STAR_ICON: 'icon-rate-star-button'
};


export default class LinkListV2 {
    constructor(element) {
        this.element = element;
        this.isFavIconEnabled = this.element.getElementsByClassName('favorite-item__link-list-v2');
        this.init();
        // this.accordionItem = null;
        // this.accordionPanel = null;
    }

    init() {
        // this.cacheDOMElement();
        this.attachEvents();
        this.loadModules();
        // const linkedListItemCollection = document.getElementsByClassName('favorite-item__link-list-v2');
        this.isFavIconEnabled.length && this.getFavAPI();
    }
    // cacheDOMElement() { 
    //     this.accordionItem = document.getElementsByClassName(`${CLASSES.ACCORDION__LIST}`);
    // }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        // const accordionItems = this.accordionItem;
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.parentNode && e.target.parentNode.classList.contains(`${LINK_LIST_V2_CLASS.ACCORDION__LIST}`)) {
                const flag = e.target.parentNode.classList.contains(`${CLASSES.ACCORDION__ACTIVE}`);
                // [].forEach.call(accordionItems, (element) => {
                //     element.classList.remove(`${CLASSES.ACCORDION__ACTIVE}`);
                // });

                if (!flag) {
                    e.target.parentNode.classList.add(`${CLASSES.ACCORDION__ACTIVE}`);
                } else {
                    e.target.parentNode.classList.remove(`${CLASSES.ACCORDION__ACTIVE}`);
                }
            }

            if (e.target && e.target.classList.contains(`${LINK_LIST_V2_CLASS.FAVORITE}`)) {
                const favItem = e.target.classList.contains(`${LINK_LIST_V2_CLASS.NOT_SELECTED}`);

                const parentElement = e.target.parentNode;
                const listItem = parentElement.querySelector('a');
                const listItemHref = listItem.href;
                 
                const pathStarting = "/content";
                const pathEnding = ".html";

                let indexOfPathStarting = listItemHref.indexOf(pathStarting);
                let indexOfPathEnding = listItemHref.indexOf(pathEnding);

                if (indexOfPathStarting === -1) {
                    indexOfPathStarting = 0;
                }
                if (indexOfPathEnding === -1) {
                    indexOfPathEnding = listItemHref.length;
                }

                const linkItemPathname = listItemHref.slice(indexOfPathStarting, indexOfPathEnding);
                const listItemText = listItem.querySelector('.link-list__item-text').innerText;

                if (favItem) {
                    this.favHandlerAPI(e.target, listItemText, decodeURI(linkItemPathname), 'add');
                    e.target.classList.replace(`${LINK_LIST_V2_CLASS.NOT_SELECTED}`, `${LINK_LIST_V2_CLASS.SELECTED}`);

                } else {
                    this.favHandlerAPI(e.target, listItemText, decodeURI(linkItemPathname), 'remove');
                    e.target.classList.replace(`${LINK_LIST_V2_CLASS.SELECTED}`, `${LINK_LIST_V2_CLASS.NOT_SELECTED}`);
                }
            }
        });
    }

    getFavAPI() {
        const parameter = document.getElementById("site-name").value;
        const url = `/bin/microsites/my-favourites?siteName=${parameter}&option=display`;

        const linkedListItemCollection = document.getElementsByClassName('favorite-item__link-list-v2');

        Array.from(linkedListItemCollection).forEach((element) => {
            element.classList.replace(`${LINK_LIST_V2_CLASS.STAR_ICON}`, `${LINK_LIST_V2_CLASS.LOADER}`);
        });

        this.Ajax.readTextFile(url, (text) => {
            const favouriteMarkedItems = JSON.parse(text);


            Array.from(linkedListItemCollection).forEach((element, index) => {
                const linkListAnchorTag = element.nextElementSibling;

                const listItemHref = linkListAnchorTag.href;
                 
                const pathStarting = "/content";
                const pathEnding = ".html";
                let indexOfPathStarting = listItemHref.indexOf(pathStarting);
                let indexOfPathEnding = listItemHref.indexOf(pathEnding);

                if (indexOfPathStarting === -1) {
                    indexOfPathStarting = 0;
                }
                if (indexOfPathEnding === -1) {
                    indexOfPathEnding = listItemHref.length;
                }
                const linkItemPathname = listItemHref.slice(indexOfPathStarting, indexOfPathEnding);

                for (const link in favouriteMarkedItems) {
                    if (decodeURI(linkItemPathname) === decodeURI(link)) {
                        element.classList.remove(`${LINK_LIST_V2_CLASS.LOADER}`);
                        element.classList.add(`${LINK_LIST_V2_CLASS.STAR_ICON}`);
                        element.classList.replace(`${LINK_LIST_V2_CLASS.NOT_SELECTED}`, `${LINK_LIST_V2_CLASS.SELECTED}`);
                    }
                }

                element.classList.replace(`${LINK_LIST_V2_CLASS.LOADER}`, `${LINK_LIST_V2_CLASS.STAR_ICON}`);
            });

        });
    }

    favHandlerAPI(element, linktext, listItemHref, action) {
        const parameter = document.getElementById("site-name").value;
        let url = `/bin/microsites/my-favourites?siteName=${parameter}&option=${action}&col1text=${linktext}&col3link=${listItemHref}`;

        element.classList.replace(`${LINK_LIST_V2_CLASS.STAR_ICON}`, `${LINK_LIST_V2_CLASS.LOADER}`);

        let bodyData = ''

        if (action === 'remove') {
            bodyData = {
                siteName: parameter,
                pagesPath: [listItemHref]
            };
            url = `/bin/microsites/my-favourites?option=${action}`;
        }

        this.Ajax.ajaxJsonPostFn(url, bodyData, (text) => {
            element.classList.replace(`${LINK_LIST_V2_CLASS.LOADER}`, `${LINK_LIST_V2_CLASS.STAR_ICON}`);
            const data = JSON.parse(text);
        });
    }

}
