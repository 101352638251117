import { EVENTS, ATTRIBUTES } from 'Constants';
import Ajax from 'modules/Ajax';

const ID = {
    ADDFAVORITE: 'addFavorite',
    POPUPFAV: 'pop-up-fav'
};

const CLASSES = {
    POPUP: 'pop-up',
    POPUP_OPEN: 'pop-up--open',
    POPUP_CONTENT_BODY: 'pop-up__content-body',
    POPUP__CONTENT_CLOSE: 'pop-up__content--close'
};

export default class AddFavorites {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
        this.closeButtonList = null;
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.favPopup = document.getElementById(`${ID.POPUPFAV}`);
        this.closeButtonList = document.getElementsByClassName(`${CLASSES.POPUP__CONTENT_CLOSE}`);
        this.modalPopUpList = document.getElementsByClassName(`${CLASSES.POPUP}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        const modalPopUpListItem = this.modalPopUpList;

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.ADDFAVORITE}`)) {
                this.addFavoriteFn();
            }
        });

        [].forEach.call(this.closeButtonList, (el) => {
            el.addEventListener(EVENTS.CLICK, () => {
                [].forEach.call(modalPopUpListItem, (element) => {
                    element.classList.remove(`${CLASSES.POPUP_OPEN}`);
                });
            });
        });
    }

    addFavoriteFn() {
        const pagePath = document.getElementById(`${ATTRIBUTES.CURRENTPAGEPATH}`).value;
        const siteName = document.getElementById(`${ATTRIBUTES.SITENAME}`).value;
        const url = '/bin/microsites/add-favorite';
        const data = {
            siteName,
            pagePath,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            this.makePopup(success.replace(/['"]+/g, ''));
        });
    }

    makePopup(success) {
        this.favPopup.getElementsByClassName(`${CLASSES.POPUP_CONTENT_BODY}`)[0].innerHTML = success;
        this.favPopup.classList.add('pop-up--open');
    }
}
