import { EVENTS } from 'Constants';

import Ajax from 'modules/Ajax';
import EmployeePurchaseUtil from 'modules/EmployeePurchaseUtil';

const ID = {
    SUBMITBUTTON: 'vin-decode-submit',
    REGISTRATION_FORM: 'vin-decoder-form',
    PRINT: 'print',
};

export default class VinDecoder {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'vin-decoder-form';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.EmployeePurchaseUtil = new EmployeePurchaseUtil(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const vin = document.getElementById('vin').value;
                const vinResult = document.getElementById('vin-decoder-result');
                const capRes = grecaptcha.getResponse();
                console.log(capRes);
                if (vin !== '' && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const url = '/bin/microsites/mb-collision-center/vin';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                    };
                    vinResult.innerHTML = '<h4>Please wait...</h4>';
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.EmployeePurchaseUtil.displayPopUp('Please verify the captcha response');
                        } else if (JSON.parse(success).error !== 'There was an error creating the JSON response') {
                            if (JSON.parse(success).error !== 'NO_DATA_FOUND') {
                                document.getElementsByClassName('icon-mb icon-dark-printer')[0].style.visibility = 'visible';
                                form.reset();
                                this.displayResult(success);
                            } else {
                                document.getElementsByClassName('icon-mb icon-dark-printer')[0].style.visibility = 'hidden';
                                vinResult.innerHTML = '<p class="vin-recall__error-field">No Data Found For This Vehicle</p>';
                            }
                        } else {
                            document.getElementsByClassName('icon-mb icon-dark-printer')[0].style.visibility = 'hidden';
                            vinResult.innerHTML = '<p class="vin-recall__error-field">Please enter a valid VIN !</p>';
                        }

                        grecaptcha.reset();
                    });
                } else if (capRes.length === 0 && vin !== '') {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="vin-recall__error-field">This field is required.</span>';
                    vinResult.innerHTML = '';
                } else {
                    document.getElementsByClassName('icon-mb icon-dark-printer')[0].style.visibility = 'hidden';
                    vinResult.innerHTML = '<p class="vin-recall__error-field">VIN Number cannot be empty</p>';
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`#${ID.PRINT}`)) {
                e.preventDefault();
                const printElement = document.getElementById('vin-decoder-result');
                const printWindow = window.open('', 'PRINT');
                printWindow.document.write(document.documentElement.innerHTML);
                setTimeout(() => { // Needed for large documents
                    printWindow.document.body.style.margin = '0 0';
                    printWindow.document.body.innerHTML = printElement.outerHTML;
                    printWindow.document.close(); // necessary for IE >= 10
                    printWindow.focus(); // necessary for IE >= 10*/
                    printWindow.print();
                    printWindow.close();
                }, 1000);
            }
        });
    }

    displayResult(success) {
        console.log(this);
        const apiResponse = JSON.parse(success);
        const vehicleResponse = JSON.parse(apiResponse);
        const data = vehicleResponse.vehicle;
        const packagesInfo = vehicleResponse.packages;
        const packagesInfoDetails = vehicleResponse.packageComponents;
        const vinResult = document.getElementById('vin-decoder-result');
        let html = '';
        let standardOptions = [];
        const paidOptions = [];
        html += '<div class="vin__container-fluid" id="table"><div class="vin__custom-padding">';
        if (data !== undefined) {
            const baseMsrp = data.baseMSRP !== undefined ? this.addCommas(data.baseMSRP) : '';
            html += `<div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><table class="vin__padding-table-columns"><tr><td><h5><b>${data.modelYear} ${data.modelName} </b></h5></td><td><h5><b>Price:</b> $ ${baseMsrp}</h5></td><td><h5><b>VIN:</b> ${data.vin}</h5></td><td><h5><b>PO#:</b></h5></td></tr></table></div></div>`;
        } else if (data === undefined && vehicleResponse !== undefined) {
            html += `<div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><table class="vin__padding-table-columns"><tr><td><h5><b>${vehicleResponse.year} ${vehicleResponse.model} </b></h5></td><td><h5><b>VIN:</b> ${vehicleResponse.vin}</h5></td><td><h5><b>PO#:</b></h5></td></tr></table></div></div>`;
        }
        html += `<div class="vin__row vin__row-custom-border" id="row-custom-border"><div class="vin__col-md-6"><table class="vin__padding-table-columns"><tr><td><h6><b>Dealer Code:</b></h6></td><td><p>${vehicleResponse.dealerCode}</p></td></tr><tr><td><h6><b>Region:</b></h6></td><td><p>${vehicleResponse.regionCode}<b></td></tr><tr><td><h6><b>VPC:</b></h6></td><td><p><b>${vehicleResponse.vpcName}</b></p></td></tr></table></div><div class="vin__col-md-6"><div class="vin__row"><div class="vin__col-md-6"><h6><b>Dealer Address:</b></h6></div><div class="vin__col-md-6"><p><b>${vehicleResponse.dealerName}</b></p><p><b>${vehicleResponse.addressLine1}</b></p><p><b>${vehicleResponse.city}, ${vehicleResponse.state} ${vehicleResponse.zip}.</b></p></div></div></div></div>`;
        if (data !== undefined) {
            html += '<div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><h5><b>Standard Accessories</b></h5></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-6"><table class="vin__padding-table-columns"> <thead> <tr> <td><h6><b>Code</b></h6></td> <td><h6><b>Description</b></h6></td> </tr> </thead> <tbody>';
            if (parseInt(data.interiorColorMSRP, 10) === 0) {
                html += `<tr><td>${data.interiorColor}</td><td>${data.interiorColorName}</td></tr>`;
            }

            if (parseInt(data.exteriorColorMSRP, 10) === 0) {
                html += `<tr><td>${data.exteriorColor}</td><td>${data.exteriorColorName}</td></tr>`;
            }
            if (data.options !== undefined) {
                const allOptions = data.options.concat(data.trim);
                for (let i = 0; i < allOptions.length; i += 1) {
                    const value = allOptions[i];
                    if (value !== undefined) {
                        if (parseInt(value.msrp, 10) === 0) {
                            standardOptions.push(allOptions[i]);
                        } else {
                            paidOptions.push(allOptions[i]);
                        }
                    }
                }
            }
            standardOptions = standardOptions.concat(vehicleResponse.standardOptions);
            standardOptions = standardOptions.concat(vehicleResponse.optionCodes);
            console.log(`Standard Options:${standardOptions.length}`);
            console.log(`Paid Options:${paidOptions.length}`);
            const optionsArr = standardOptions;
            if (optionsArr.length > 0) {
                for (let i = 0; i < Math.round(optionsArr.length / 2); i += 1) {
                    const value = optionsArr[i];
                    if (value.type === '0') {
                        html += `<tr><td>${value.code}</td><td>${value.description}</td></tr>`;
                    }
                }
            }


            html += '</tbody></table></div><div class="vin__col-md-6"><table class="vin__padding-table-columns"> <thead> <tr> <td><h6><b>Code</b></h6></td> <td><h6><b>Description</b></h6></td> </tr> </thead> <tbody>';

            if (optionsArr.length > 0) {
                const regArrStart = Math.round(optionsArr.length / 2);
                for (let i = regArrStart; i < optionsArr.length; i += 1) {
                    const value = optionsArr[i];
                    if (value.type === '0') {
                        html += `<tr><td>${value.code}</td><td>${value.description}</td></tr>`;
                    }
                }
            }

            html += '<tr> <td></td> <td></td> </tr><tr> <td></td> <td></td> </tr> </tbody></table></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><h5><b>Options</b></h5></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><table class="vin__custom-padding-table-columns">';
            let subTotalPrice = data.baseMSRP !== undefined ? parseInt(data.baseMSRP, 10) : '';
            console.log(subTotalPrice);
            if (data.packages !== undefined && data.packages !== null
                    && data.packages.length !== 0) {
                console.log(`Packages:${data.packages.length}`);
                for (let i = 0; i < data.packages.length; i += 1) {
                    const value = data.packages[i];
                    if (subTotalPrice !== '') {
                        subTotalPrice += parseInt(value.msrp, 10);
                    }
                    const msrp = this.addCommas(value.msrp);
                    html += `<tr><td><b>${value.code}</b></td><td><b>${value.description}</b></td><td><b>$ ${msrp}</b></td></tr>`;
                    const pkgOptions = value.options;
                    for (let j = 0; j < pkgOptions.length; j += 1) {
                        const pkgValue = pkgOptions[j];
                        html += `<tr><td></td><td>${pkgValue.code}  ${pkgValue.description}</td></tr>`;
                    }
                }
            } else if (packagesInfo !== undefined && packagesInfo !== null
                    && packagesInfo.length !== 0) {
                console.log(`Packages Info:${packagesInfo.length}`);
                for (let i = 0; i < packagesInfo.length; i += 1) {
                    const value = packagesInfo[i];
                    html += `<tr><td><b>${value.code}</b></td><td><b>${value.description}</b></td></tr>`;
                    for (let j = 0; j < packagesInfoDetails.length; j += 1) {
                        if (packagesInfoDetails[j].code === value.code) {
                            const components = packagesInfoDetails[j].components;
                            for (let k = 0; k < components.length; k += 1) {
                                const pkgValue = components[k];
                                html += `<tr><td></td><td>${pkgValue.code}  ${pkgValue.description}</td></tr>`;
                            }
                        }
                    }
                }
            } else {
                html += '<tr><td colspan="2"><h6><b>No Packages For This Vehicle</b></h6></td></tr>';
                html += '<tr><td></td><td></td></tr>';
                html += '<tr><td></td><td></td></tr>';
            }

            if (paidOptions.length > 0) {
                for (let i = 0; i < paidOptions.length; i += 1) {
                    const value = paidOptions[i];
                    if (subTotalPrice !== '') {
                        subTotalPrice += parseInt(value.msrp, 10);
                    }
                    const msrp = this.addCommas(value.msrp);
                    html += `<tr><td><b>${value.code}</b></td><td><b>${value.description}</b></td><td><b>$ ${msrp}</b></td></tr>`;
                }
            }

            if (data.interiorColorMSRP !== undefined
                    && parseInt(data.interiorColorMSRP, 10) !== 0) {
                if (subTotalPrice !== '') {
                    subTotalPrice += parseInt(data.interiorColorMSRP, 10);
                }
                const interiorColorMSRP = this.addCommas(data.interiorColorMSRP);
                html += `<tr><td><b>${data.interiorColor}</b></td><td><b>${data.interiorColorName}</b></td><td><b>$ ${interiorColorMSRP}</b></td></tr>`;
            }

            if (data.exteriorColorMSRP !== undefined
                    && parseInt(data.exteriorColorMSRP, 10) !== 0) {
                if (subTotalPrice !== '') {
                    subTotalPrice += parseInt(data.exteriorColorMSRP, 10);
                }
                const exteriorColorMSRP = this.addCommas(data.exteriorColorMSRP);
                html += `<tr><td><b>${data.exteriorColor}</b></td><td><b>${data.exteriorColorName}</b></td><td><b>$ ${exteriorColorMSRP}</b></td></tr>`;
            }

            const delFee = data.destinationDelivery !== undefined ? this.addCommas(data.destinationDelivery) : '';
            const subTotal = data.subTotal !== undefined ? this.addCommas(data.subTotal) : '';
            let totalMsrp = data.total !== undefined ? this.addCommas(data.total) : '';
            if (totalMsrp === '0') {
                totalMsrp = '';
            }
            html += '<tr> <td></td> <td></td> </tr><tr> <td></td> <td></td> </tr> </tbody></table></div></div>';
            html += `<div class="vin__row vin__row-custom-border" id="row-custom-border"><table style ="width : 100%;"><tr style ="text-align: left;"><td style="width: 50%;"><h6><b>SUBTOTAL</b></h6></td><td style="width: 50%; text-align: right;"><h6><b>$ ${subTotal}</b></h6></td></tr><tr style ="text-align: left;"><td style="width: 50%;"><h6>DESTINATION & DELIVERY</h6></td><td style="width: 50%; text-align: right;"><h6>$ ${delFee}</h6></td></tr><tr style ="text-align: left;"><td style="width: 50%;"><h6><b>TOTAL</b></h6></td><td style="width: 50%; text-align: right;"><h6><b>$ ${totalMsrp}</b></h6></td></tr></table></div>`;
        }
        if (data === undefined && vehicleResponse.standardOptions !== undefined) {
            html += '<div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><h5><b>Standard Accessories</b></h5></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-6"><table class="vin__padding-table-columns"> <thead> <tr> <td><h6><b>Code</b></h6></td> <td><h6><b>Description</b></h6></td> </tr> </thead> <tbody>';
            if (vehicleResponse.standardOptions !== undefined) {
                const allOptions = vehicleResponse.standardOptions.concat(vehicleResponse.trim);
                for (let i = 0; i < allOptions.length; i += 1) {
                    const value = allOptions[i];
                    if (value !== undefined) {
                        paidOptions.push(allOptions[i]);
                    }
                }
            }
            standardOptions = standardOptions.concat(vehicleResponse.standardOptions);
            standardOptions = standardOptions.concat(vehicleResponse.regularAccessories);
            console.log(`Standard Options:${standardOptions.length}`);
            const optionsArr = standardOptions;
            if (optionsArr.length > 0) {
                for (let i = 0; i < Math.round(optionsArr.length / 2); i += 1) {
                    const value = optionsArr[i];
                    if (value.type === '0') {
                        html += `<tr><td>${value.code}</td><td>${value.description}</td></tr>`;
                    }
                }
            }
            html += '</tbody></table></div><div class="vin__col-md-6"><table class="vin__padding-table-columns"> <thead> <tr> <td><h6><b>Code</b></h6></td> <td><h6><b>Description</b></h6></td> </tr> </thead> <tbody>';
            if (optionsArr.length > 0) {
                const regArrStart = Math.round(optionsArr.length / 2);
                for (let i = regArrStart; i < optionsArr.length; i += 1) {
                    const value = optionsArr[i];
                    if (value.type === '0') {
                        html += `<tr><td>${value.code}</td><td>${value.description}</td></tr>`;
                    }
                }
            }
            html += '<tr> <td></td> <td></td> </tr><tr> <td></td> <td></td> </tr> </tbody></table></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><h5><b>Options</b></h5></div></div><div class="vin__row vin__row-custom-padding" id="row-custom-padding"><div class="vin__col-md-12"><table class="vin__custom-padding-table-columns">';
            if (vehicleResponse.packages !== undefined && vehicleResponse.packages !== null
                    && vehicleResponse.packages.length !== 0) {
                console.log(`Packages:${vehicleResponse.packages.length}`);
                for (let i = 0; i < vehicleResponse.packages.length; i += 1) {
                    const value = vehicleResponse.packages[i];
                    html += `<tr><td><b>${value.code}</b></td><td><b>${value.description}</b></td></tr>`;
                }
                html += '<tr><td></td></tr><tr> <td></td> <td></td> </tr><td colspan="2"><br><br><br><h6><b>No Pricing information available for this Vehicle</b></h6></td></tr>';
            } else {
                html += '<tr><td colspan="2"><h6><b>No Packages For This Vehicle</b></h6></td></tr>';
                html += '<tr><td></td><td></td></tr>';
                html += '<tr><td></td><td></td></tr>';
            }
            html += '<tr> <td></td> <td></td> </tr><tr> <td></td> <td></td> </tr> </tbody></table></div></div>';
        }
        vinResult.innerHTML = html;
    }

    addCommas(x) {
        console.log(this);
        const parts = x.split('.');
        parts[0] = parts[0].replace(/,/g, '');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
}
