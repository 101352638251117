import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import CorpSocialRequestUtil from 'modules/CorpSocialRequestUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'corp-social-form',
    SITE_NAME: 'site-name',
    DEALERSHIP_NAME: 'dealershipName',
    ADDRESS: 'address',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zipcode',
    ORGANIZER_NAME: 'organizerName',
    ORGANIZER_EMAIL: 'email',
    ORGANIZER_PHONE_NUMBER: 'phoneNumber',
    EVENT_START_DATE: 'eventStartDate',
    PARTICIPANT_COUNT: 'participantsCount',
    INTERESTED_HOSTING_EVENT: 'interestedHostingEvent',
};

export default class CorpSocialRequest {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'corp-social';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.CorpSocialRequestUtil = new CorpSocialRequestUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const interestedHostingEventCheckbox = document.getElementsByName('interestedHostingEvent');


                const interestedHostingEventDL = [];
                for (let i = 0; i < interestedHostingEventCheckbox.length; i += 1) {
                    if (interestedHostingEventCheckbox[i].checked) {
                        interestedHostingEventDL.push(interestedHostingEventCheckbox[i].value);
                    }
                }

                const interestedHostingEventErr = document.getElementById('interestedHostingEventCheckbox');
                let interestedHostingEvent = false;

                for (let j = 0; j < interestedHostingEventCheckbox.length; j += 1) {
                    if (interestedHostingEventCheckbox[j].checked) {
                        interestedHostingEvent = true;
                        break;
                    }
                }


                if (interestedHostingEvent) {
                    interestedHostingEventErr.classList.add('corp-social-form__error-field--hidden');
                } else {
                    interestedHostingEventErr.classList.remove('corp-social-form__error-field--hidden');
                }

                const capRes = grecaptcha.getResponse();
                if (this.CorpSocialRequestUtil.validateAllFields() && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('Inside final if');
                    const siteName = 'corp-social';
                    const url = '/bin/microsites/corp-social/sendEmail';
                    const dealershipName = document.getElementById(`${ID.DEALERSHIP_NAME}`).value;
                    const address = document.getElementById(`${ID.ADDRESS}`).value;
                    const city = document.getElementById(`${ID.CITY}`).value;
                    const state = document.getElementById(`${ID.STATE}`).value;
                    const zip = document.getElementById(`${ID.ZIP}`).value;
                    const organizerName = document.getElementById(`${ID.ORGANIZER_NAME}`).value;
                    const email = document.getElementById(`${ID.ORGANIZER_EMAIL}`).value;
                    const phoneNumber = document.getElementById(`${ID.ORGANIZER_PHONE_NUMBER}`).value;
                    const eventStartDate = document.getElementById(`${ID.EVENT_START_DATE}`).value;
                    const participantsCount = document.getElementById(`${ID.PARTICIPANT_COUNT}`).value;

                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('dealershipName', dealershipName);
                    dataMap.set('address', address);
                    dataMap.set('city', city);
                    dataMap.set('state', state);
                    dataMap.set('zip', zip);
                    dataMap.set('organizerName', organizerName);
                    dataMap.set('email', email);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('eventStartDate', eventStartDate);
                    dataMap.set('participantsCount', participantsCount);
                    dataMap.set('interestedHostingEvent', interestedHostingEvent);
                    dataMap.set('checkedinterestedHostingEventDL', interestedHostingEventDL);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.CorpSocialRequestUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.CorpSocialRequestUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CORP_SOCIAL_REQUEST_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CORP_SOCIAL_REQUEST_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.CorpSocialRequestUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="corp-social-form__error-field">This field is required.</span>';
                    this.CorpSocialRequestUtil.validateAllFields();
                }
            }
        });
    }
}
