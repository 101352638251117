import { EVENTS } from 'Constants';

const ID = {
    FAQ_QUESTION: 'faq-question',
    FAQ_HEADING: 'faqv3-heading',
};

export default class FAQV2 {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        // do not remove the followiing line as it will result in a js lint error
        // which will affect new updates to js bundles
        // if console.log needs to be removed, add any code that references "this" -pMa
        console.log('attachEvents: ', this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FAQ_QUESTION}`)) {
                if (e.target.classList.contains('faq-v2__icon--down-arrow-key')) {
                    e.target.classList.toggle('faq-v2__accordion_active_icon--down-arrow-key');
                } else if (e.target.classList.contains('faq-v2__icon--plus')) {
                    e.target.classList.toggle('faq-v2__accordion_active_icon--plus');
                } else if (e.target.classList.contains('faq-v2__icon--right-arrow-key')) {
                    e.target.classList.toggle('faq-v2__accordion_active_icon--right-arrow-key');
                }
                const answer = e.target.nextElementSibling;
                if (answer.style.display === 'block') {
                    answer.style.display = 'none';
                } else {
                    answer.style.display = 'block';
                }
            }
        });
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FAQ_HEADING}`)) {
                const faqHeadingEle = e.target.nextElementSibling;
                if (faqHeadingEle.style.display === 'none') {
                    faqHeadingEle.style.display = 'block';
                } else {
                    faqHeadingEle.style.display = 'none';
                }
                if (e.target.classList.contains('faq-v2__icon--plus')) {
                    e.target.classList.toggle('faq-v2__accordion_active_icon--plus');
                }
            }
        });
    }
}
