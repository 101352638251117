/**
 * Utility for formatting a number to a string format
 */

/**
 * @method toCommaSeparated
 * @description Converts a number to a commas separated string (eg. 10000 => 10,000)
 * @param number {Number} The number to format
 * @returns {String} Formatted number to comma separated
 */
export function toCommaSeparated(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @method toNumber
 * @description Converts a formatted number string to a Number
 * @param value {String} String to convert
 * @param country {String} Country code to convert to
 * @param language {String} Language code to convert to
 * @return {Number} Unformatted number
 */
export function toNumber(value, country = 'US', language = 'en') {
    const countryCode = country.toUpperCase();
    const langCode = language.toUpperCase();
    let stringVal = typeof value === 'string' ? value : value.toString();

    if (countryCode === 'CA' && langCode === 'FR') {
        stringVal = stringVal.replace(',', '.');
    }

    const isNegative = stringVal.indexOf('-') === 0;
    let cleanVal = stringVal.replace(/[^0-9.]/g, '');
    cleanVal = !Number.isNaN(Number(cleanVal)) && cleanVal ? parseFloat(cleanVal) : cleanVal;

    return isNegative ? -Math.abs(cleanVal) : cleanVal;
}

/**
 * @method toStringNumber
 * @description Converts a number to a string number taking into account locale
 * @param number {Number} Number to convert
 * @param country {String} Country to convert to
 * @param language {String} Language to convert to
 * @param maxDecimalDigits {Number} Max number of decimal digits to display
 * @param minDecimalDigits {Number} Min number of decimal digits to display
 * @return {String} Formatted string number
 */
export function toStringNumber(number, country = 'US', language = 'en', maxDecimalDigits = 2, minDecimalDigits = 0) {
    const countryCode = country.toUpperCase();

    return number.toLocaleString(
        `${language}-${countryCode}`,
        {
            minimumFractionDigits: minDecimalDigits,
            maximumFractionDigits: maxDecimalDigits
        }
    );
}

/**
 * @method toFormattedStringNumber
 * @description Accepts an unformatted string number (e.g: 1234.56789) and
 * transforms to a formatted string number (e.g.: 1,234.56)
 * @param value {String} String to convert
 * @param country {String} Country to convert to
 * @param language {String} Language to convert to
 * @param maxDecimalDigits {Number} Max number of decimal digits to display
 * @param minDecimalDigits {Number} Min number of decimal digits to display
 * @return {String}
 */
function toFormattedStringNumber(value, country = 'US', language = 'en', maxDecimalDigits = 2, minDecimalDigits = 0) {
    return toStringNumber(
           toNumber(value, country, language)
           , country, language, maxDecimalDigits, minDecimalDigits);
}

/**
 * @method toPhone
 * @description Converts a number to phone number format (eg. (555) 555-5555
 * Note: this currently ONLY formats numbers in US format. If the util is
 * needed for CA || MX this will need to be enhanced
 * @param number {Number} The number to format
 * @returns {String} Formatted number to comma separated
 */
export function toPhone(number) {
    const convertedNumber = number.toString();
    const numberStr = convertedNumber.includes('-') ? convertedNumber.replace(/-/g, '').toString() : convertedNumber;
    const formattedNumber = toNumber(numberStr.replace(/^1+/, '')).toString();
    const areaCode = formattedNumber.length > 3 ? `(${formattedNumber.substr(0, 3)})` : formattedNumber.substr(0, 3);
    const localCode = formattedNumber.substr(3, 3);
    const lineNumber = formattedNumber.length >= 7 ? `-${formattedNumber.substr(6, 4)}` : formattedNumber.substr(6, 4);

    return (`${areaCode} ${localCode}${lineNumber}`).trim();
}

/**
 * @method toPercentage
 * @description Converts a number to a percentage string with a max of two decimal points
 * @param number {Number} Number to convert
 * @param country {String} Country code to convert to
 * @param language {String} Language code to convert to
 * @param minimumFractionDigits {Number} specifying minimum fraction digits
 * @param maximumFractionDigits {Number} specifying maximum fraction digits
 * @return {String} Formatted percentage
 */
export function toPercentage(number, country = 'US', language = 'en', minimumFractionDigits = 0, maximumFractionDigits = 2) {
    country = country.toUpperCase();

    return `${number.toLocaleString(
        `${language}-${country}`,
        {
            style: 'percent',
            minimumFractionDigits: `${minimumFractionDigits}`,
            maximumFractionDigits: `${maximumFractionDigits}`,
        }
    )}`;
}

/**
 * @method trimDecimal
 * @description Trims a decimal number to a maximum decimal length
 * eg: trimDecimal("1.456", 2) => 1.45
 * @param number {Number} Number to trim
 * @param maxLength {Number} Maximum decimal length
 * @return {number}
 */
export function trimDecimal(number, maxLength = 0) {
    const pattern = new RegExp(`^-?\\d+(?:\\.\\d{0,${maxLength}})?`);
    return window.Number(
        number.toString().match(pattern)[0]
    );
}

/**
 * @method toCurrency
 * @description Converts a number to a currency string with a max of two decimal points
 * @param number {Number} Number to convert
 * @param country {String} Country currency to convert to
 * @param language {String} Language currency to convert to
 * @param currencyCode {String} Currency code
 * @param maxDecimalDigits {Number} Max number of decimal digits to display
 * @param minDecimalDigits {Number} Min number of decimal digits to display
 * @return {String} Formatted currency
 */
export function toCurrency(number, country = 'US', language = 'en', currencyCode = 'USD', maxDecimalDigits = 2, minDecimalDigits = 0) {
    const countryCode = country.toUpperCase();

    // map of countryCode keys with currency modifier values
    const currencyModifier = {
        MX: 'MXN'
    };

    const currency = number.toLocaleString(
        `${language}-${countryCode}`,
        {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: minDecimalDigits,
            maximumFractionDigits: maxDecimalDigits
        }
    );

    const modifier = currencyModifier[countryCode] || '';

    return (`${currency} ${modifier}`).trim();
}

/**
 * @method formatSSN
 * @desc Converts a value to the proper US SSN format XXX-XX-XXXX
 * @param value {Number} Number to format as formatted SSN string
 * @returns {string}
 */
export function toSSN(number) {
    let ssnValue = number.toString();
    ssnValue = ssnValue.replace(/[^\d|^*]/g, '').slice(0, 9);

    if (ssnValue.length > 3 && ssnValue.length < 6) {
        return ssnValue.replace(/([\d|*]{3})([\d|*])/g, '$1-$2');
    }
    return ssnValue.replace(/([\d|*]{3})([\d|*]{2})([\d|*])/g, '$1-$2-$3');
}

/**
 * @method formatCanadaSSN
 * @desc Converts a value to the proper CA SSN format XXX-XXX-XXX
 * @param value {Number} Number to format as formatted SSN string
 * @returns {string}
 */
export function toCASSN(number) {
    let ssnValue = number.toString();
    ssnValue = ssnValue.replace(/[^\d|^*]/g, '').slice(0, 9);

    if (ssnValue.length > 3 && ssnValue.length < 6) {
        return ssnValue.replace(/([\d|*]{3})([\d|*])/g, '$1-$2');
    }
    return ssnValue.replace(/([\d|*]{3})([\d|*]{3})([\d|*])/g, '$1-$2-$3');
}

/**
 * @method toSSNMask
 * @desc Masks all but last 4 characters of input
 * @param value {Number} input to mask
 * @returns {string}
 */
export function toSSNMask(value, fullMask = false) {
    let ssnValue = value.toString();
    let ssnMask = '';
    ssnValue = ssnValue.replace(/[^\d|^*]/g, '').slice(0, 9);

    for (let i = 0; i < ssnValue.length; i += 1) {
        if (i < 5 || fullMask) {
            ssnMask += '*';
        } else {
            ssnMask += ssnValue.charAt(i);
        }
    }

    return toSSN(ssnMask);
}

/**
 * @method toCASSNMask
 * @desc Masks all but last 3 characters of input
 * @param value {Number} input to mask
 * @returns {string}
 */
export function toCASSNMask(value, fullMask = false) {
    let ssnValue = value.toString();
    let ssnMask = '';
    ssnValue = ssnValue.replace(/[^\d|^*]/g, '').slice(0, 9);

    for (let i = 0; i < ssnValue.length; i += 1) {
        if (i < 6 || fullMask) {
            ssnMask += '*';
        } else {
            ssnMask += ssnValue.charAt(i);
        }
    }

    return toCASSN(ssnMask);
}

/**
 * @method toTaxID
 * @desc Converts a value to the proper US SSN format XXX-XX-XXXX
 * @param value {Number} Number to format as formatted SSN string
 * @returns {string}
 */
export function toTaxID(number) {
    let taxID = number.toString();
    taxID = taxID.replace(/[^\d|^*]/g, '');

    return taxID.replace(/([\d|*]{2})([\d|*]{1,7})/g, '$1-$2');
}

/**
 * Export the public api
 */
export default {
    toCommaSeparated,
    toPhone,
    toPercentage,
    toCurrency,
    toNumber,
    toSSN,
    toCASSN,
    toSSNMask,
    toCASSNMask,
    toTaxID,
    toStringNumber,
    trimDecimal,
    toFormattedStringNumber
};

