import { EVENTS } from 'Constants';

const ID = {
    SHOW_MORE: 'showMore',
};

export default class TakataLearnMore {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target.matches(`#${ID.SHOW_MORE}`)) {
                e.preventDefault();
                const el = document.getElementById('showText');
                if (el.getAttribute('hidden') === '') {
                    el.removeAttribute('hidden');
                } else {
                    el.setAttribute('hidden', '');
                }
            }
        });
    }
}
