/**
* Template for the carousel navigation indicators or dots
* Uses ES6 template literal to composite the markup for a navigation component
* for the hero carousel
*/
import { html } from 'utils';


let dotGrey = '';
dotGrey = window.location.href.indexOf('/content/mb-warranty-internal/home.html') > -1 ? 'carousel-nav__indicator-dot-grey' : 'carousel-nav__indicator-dot';

export default index => html`
<li>
<a class="${dotGrey}" data-index="${index}"></a>
</li>
`.trim();
