import { EVENTS } from 'Constants';

const CLASSES = {
    PRINTALL_CONTAINER: 'printAll-container',
    PAGEPATH_CONTAINER: 'pagePath-container'
};

export default class PrintAll {
    constructor(element) {
        this.element = element;
        this.init();
        this.req = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
        this.pagePath();
    }

    cacheDOMElement() {
        this.printAllContainer = document.getElementsByClassName(`${CLASSES.PRINTALL_CONTAINER}`);
        this.pagePathContainer = document.getElementsByClassName(`${CLASSES.PAGEPATH_CONTAINER}`);
        this.pagePathContainerList = this.pagePathContainer[0].querySelectorAll('li');
    }

    attachEvents() {
        console.log(this);
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.id === 'printAll') {
                // const content = document.querySelector('.page-content');
                const col9 = document.querySelector('.col-9');

                [].forEach.call(col9.children, (el) => {
                    el.style.display = 'none';
                });

                document.getElementById('printAll-container').style.display = 'block';

                window.print();

                [].forEach.call(col9.children, (el) => {
                    el.style.display = 'block';
                });

                document.getElementById('printAll-container').removeAttribute('style');

                [].forEach.call(col9.children, (el) => {
                    el.removeAttribute('style');
                });

                [].forEach.call(col9.querySelectorAll('.pop-up'), (el) => {
                    el.removeAttribute('style');
                });
            }
        });
    }

    pagePath() {
        const pagePath = [];
        let path = '';
        let pathContent = '';

        [].forEach.call(this.pagePathContainerList, (el) => {
            path = `${el.getElementsByTagName('input')[0].value}?wcmmode=disabled`;
            pagePath.push(path);

            pathContent += this.load(path);
        });

        this.printAllContainer[0].innerHTML = pathContent;

        if (this.printAllContainer[0].querySelectorAll('.alphabetical-search__table-container').length) {
            const duplicateIDElemArray = this.printAllContainer[0].querySelectorAll('.alphabetical-search__table-container')[1].getElementsByTagName('tr');

            [].forEach.call(duplicateIDElemArray, (elem) => {
                elem.removeAttribute('class');
            });
        }
    }

    load(url) {
        this.req = new XMLHttpRequest();
        this.req.open('GET', url, false);
        this.req.send(null);

        return this.req.responseText;
    }
}
