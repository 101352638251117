import { EVENTS, CLASSES } from 'Constants';

export default class Tab {
    constructor(element) {
        this.element = element;
        this.init();
        this.tabList = null;
        this.tabContainer = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.tabList = document.querySelectorAll(`.${CLASSES.TAB_LIST}`);
        this.tabContent = document.querySelectorAll(`.${CLASSES.TAB_CONTENT}`);
        this.activeTab = document.querySelectorAll(`.${CLASSES.TAB_CONTENT_ACTIVE}`);
    }

    attachEvents() {
        [].forEach.call(this.tabList, (el) => {
            el.addEventListener(EVENTS.CLICK, (e) => {
                if (e.target.matches(`.${CLASSES.TAB_LINKS}`) || e.target.matches(`.${CLASSES.TAB_LIST}`)) {
                    let target = '';
                    if (e.target.matches(`.${CLASSES.TAB_LINKS}`)) {
                        target = e.target.parentNode;
                    } else if (e.target.matches(`.${CLASSES.TAB_LIST}`)) {
                        target = e.target;
                    }
                    const sectionElement = this.getComponentSection(e);
                    this.tabList = sectionElement.querySelectorAll(`.${CLASSES.TAB_LIST}`);
                    this.tabContainer = sectionElement.querySelectorAll(`.${CLASSES.TAB_CONTENT}`);
                    this.tabShow(target);
                }
            });
        });
    }

    tabShow(target) {
        const children = target.parentNode.children;
        let tabIndex = 1;
        let tabContentIndex = 0;

        // Update the tab index value
        [].forEach.call(children, (elem, index) => {
            if (elem === target) {
                tabIndex = index + 1;
            }
        });


        // Active tabs
        [].forEach.call(this.tabList, (el) => {
            el.classList.remove(`${CLASSES.TAB_LIST_ACTIVE}`);
        });
        target.classList.add(`${CLASSES.TAB_LIST_ACTIVE}`);

        // Active tabs content
        [].forEach.call(this.tabContainer, (elem) => {
            tabContentIndex = parseInt(elem.getAttribute('data-tab').split('-')[1], 10);

            if (tabIndex === tabContentIndex) {
                [].forEach.call(this.tabContainer, (el) => {
                    el.classList.remove(`${CLASSES.TAB_CONTENT_ACTIVE}`);
                });
                elem.classList.add(`${CLASSES.TAB_CONTENT_ACTIVE}`);
            }
        });
    }

    getComponentSection(element) {
        console.log(this);
        let outerMostParent = element.target;
        while (outerMostParent.parentNode.nodeName !== 'SECTION') {
            outerMostParent = outerMostParent.parentNode;
        }
        return outerMostParent.parentNode;
    }
}
