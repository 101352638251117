import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import CANApplicationFormTemplate from 'templates/can-application-form-template';
import ContactUsUtil from 'modules/ContactUsUtil';
import CanApplicationPopupFormValidation from 'modules/CanApplicationPopupFormValidation';
import MessageBox from 'modules/fleet-info/MessageBox';

/**
 * CLASSES
 */
const CLASSES = {
    CAN_APPLICATION_LEFT: 'canApplication__left',
    ACCORDION_TITLE_BLOCK: 'canApplication__accordion-title-block',
    ACCORDION_TITLE: 'canApplication__accordion-title',
    ACCORDION_PANEL: 'canApplication__accordion-panel',
    ACCORDION_ACTIVE: 'canApplication__accordion-list--active',
    CAN_APPLICATION_FORM: 'canApplication__form',
    CAN_APPLICATION_SHORT: 'canApplication__form--short',
    CAN_APPLICATION_INPUT: 'canApplication__input',
    POP_UP_OPEN: 'pop-up--open',
    OVERLAY_HIDDEN: 'overlay--hidden',
    CAN_APPLICATION_UPDATE_ERROR: 'canApplication__update-error',
    HIDDEN: 'hidden',
    CAN_APPLICATION__ACCORDION_LIST: 'canApplication__accordion-list'
};

/**
 * IDs
 */
const ID = {
    VIEW_CAN_APPLICATION: 'viewCanApplication',
    MANUFACTURER_CAN_NO: 'manufacturarCanNo',
    MANUFACTURER_CAN_YES: 'manufacturarCanYes',
    DEALER_INFO_NO: 'dealerInfoNo',
    DEALER_INFO_YES: 'dealerInfoYes',
    UPDATE_RECORD: 'updateRecord',
    CAN: 'can',
    CORPORATE_NAME: 'corporateName',
    CORPORATE_ADDRESS: 'corporateAddress',
    BUSINESS_TYPE: 'businessType',
    CORPORATE_PARENT_NAME: 'corporateParentName',
    CITY: 'city',
    CORPORATE_WEBSITE_ADDRESS: 'corporateWebsiteAddress',
    CORPORATE_TAX_ID_NUMBER: 'corporateTaxIDNumber',
    STATE: 'state',
    ZIP: 'zip',
    VEHICLE_TYPE: 'typeOfVehicle',
    FLEETSIZE: 'fleetSize',
    BUSINESS_TITLE: 'businessTitle',
    BUSINESS_PHONE_NUMBER: 'businessPhoneNumber',
    BUSINESS_FAX_NUMBER: 'businessFaxNumber',
    BUSINESS_FIRSTNAME: 'businessFirstName',
    COMPANY_OFFICER_BUSINESS_EMAIL: 'companyOfficerBusinessEmail',
    CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL: 'confirmCompanyOfficerBusinessEmail',
    BUSINESS_LASTNAME: 'businessLastName',
    BUSINESS_COMMENTS: 'businessComments',
    BUSINESS_UPDATED_BY: 'businessUpdatedBy',
    OVERLAY_DEALER_NAME: 'overlayDealerName',
    MANUFACTURER: 'manufacturer',
    MANUFACTURER_ACCOUNT_NUMBER: 'manuAccountNumber',
    COMPANY: 'company',
    DEALER_CODE: 'dealerCode',
    DEALER_BUSINESS_EMAIL: 'dealerBusinessEmail',
    CONFIRM_DEALER_BUSINESS_EMAIL: 'confirmDealerBusinessEmail',
    DEALER_FIRSTNAME: 'dealerFirstName',
    DEALER_PHONE_NUMBER: 'dealerPhoneNumber',
    DEALER_LASTNAME: 'dealerLastName',
    TITLE_COMPANY_OFFICER: 'titleCompanyOfficer',
    NAME_COMPANY_OFFICER: 'nameCompanyOfficer',
    DATE: 'date',
    AGREEMENT_SIGNED: 'agreementSigned',
    AGREEMENT_SIGNED_COPY: 'agreementSignedCopy',
    EMAIL_DEALER: 'emailDealer',
    APPROVE: 'approve',
    REJECT: 'reject',
    UPDATE: 'update',
    PRINT: 'printForm',
    POP_UP: 'pop-up-id',
    PAGE_LOADER: 'page-loader',
    ATTACHED_FILENAME: 'attachedFileName'
};

/**
 * APIs
 */
const API = {
    GET_CAN_APPLICATION_SIN: '/bin/microsites/fleet-info/get-can-application-sin',
    UPDATE_CAN_DETAILS: '/bin/microsites/fleet-info/update_can_details',
    UPDATE_CAN_ENROLLMENT_DETAILS: '/bin/microsites/fleet-info/update_can_enrollment_details',
    REJECT_CAN_APPLICATION: '/bin/microsites/fleet-info/reject-can-application',
    APPROVE_CAN_APPLICATION: '/bin/microsites/fleet-info/approve-can-application'
};

/**
 * Attributes
 */
const ATTRS = {
    IS_INSTRUCTION_CONFIRM: 'instrucConfirm'
};

/**
 * Fleet Program module
 */
export default class CanApplicationPopupForm {
    constructor(
        element,
        messageContainer,
        tableSection,
        buttonContainer,
        updateTableData) {
        this.element = element;
        this.tableSection = tableSection;
        this.buttonContainer = buttonContainer;
        this.messageContainer = messageContainer;
        this.updateTableDataCallback = updateTableData;
        this.postAjax = new XMLHttpRequest();
        this.FEPList = [];
        this.shortFormValues = null;
        this.getEnrollmentDetailsResponse = null;
        this.shortForm = null;
        this.form = null;
        this.sin = null;
        this.pageLoader = null;
        this.init();
    }

    /**
     * Init method
     */
    init() {
        this.cacheDOM();
        this.loadModules();
        this.attachEvents();
    }

    /**
     * loadModules method
     */
    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
    }

    /**
     * Cache DOM
     */
    cacheDOM() {
        this.canApplicationLeft = document.querySelector(`.${CLASSES.CAN_APPLICATION_LEFT}`);
        this.pageLoader = document.querySelector(`#${ID.PAGE_LOADER}`);
    }

    /**
     * Attach Events
     */
    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.VIEW_CAN_APPLICATION}`) || e.target.matches(`#${ID.UPDATE_RECORD}`)) {
                const data = this.canApplicationLeft.innerHTML;
                this.ContactUsUtil.displayPopUp(`${CANApplicationFormTemplate(data)}`, 'Update Record Form');
                this.CanApplicationPopupFormValidation = new CanApplicationPopupFormValidation(
                    this.element);
                if (window.microsites.pageData.isShortForm) {
                    document.querySelector(`.${CLASSES.CAN_APPLICATION_FORM}`).classList.add(`${CLASSES.CAN_APPLICATION_SHORT}`);
                }

                if (e.target.matches(`#${ID.VIEW_CAN_APPLICATION}`)) {
                    this.form = document.querySelector(`.${CLASSES.CAN_APPLICATION_FORM}`);
                    this.sin = e.target.parentNode.parentNode.firstElementChild.innerHTML;
                    const url = `${API.GET_CAN_APPLICATION_SIN}?sin=${this.sin}`;
                    fetch(url)
                        .then(response => response.json())
                        .then((data1) => {
                            this.getEnrollmentDetailsResponse = data1;

                            const enrollmentDate = new Date(data1.account.enrollmentDate);
                            const date = `${(enrollmentDate.getDate() > 9) ? enrollmentDate.getDate() : (`0${enrollmentDate.getDate()}`)}`;
                            const month = `${(enrollmentDate.getMonth() > 8) ? (enrollmentDate.getMonth() + 1) : (`0${enrollmentDate.getMonth() + 1}`)}`;
                            const year = `${enrollmentDate.getFullYear()}`;
                            const dateFormat = `${year}-${month}-${date}`;

                            if (data1.account.emailDealer) {
                                document.getElementById(`${ID.DEALER_INFO_YES}`).checked = true;
                            } else {
                                document.getElementById(`${ID.DEALER_INFO_NO}`).checked = true;
                            }

                            this.dealerRequired();

                            if ((data1.account.manufacturer !== null)
                                || (data1.account.otherAccountNumber !== null)
                                || (data1.account.fleetManagementCompany !== null)) {
                                document.getElementById(`${ID.MANUFACTURER_CAN_YES}`).checked = true;
                            } else {
                                document.getElementById(`${ID.MANUFACTURER_CAN_NO}`).checked = true;
                            }

                            this.manufacturerRequired();

                            if (data1.attachedFileName !== null) {
                                this.canApplicationAccordionList = document.querySelectorAll(`.${CLASSES.CAN_APPLICATION__ACCORDION_LIST}`);
                                this.canApplicationAccordionList.forEach((accordion) => {
                                    if (accordion.classList.contains('hide')) {
                                        accordion.classList.remove('hide');
                                        accordion.nextElementSibling.querySelector('.canApplication__sno').innerHTML = '7';
                                        accordion.nextElementSibling.nextElementSibling.querySelector('.canApplication__sno').innerHTML = '8';

                                        document.getElementById(`${ID.ATTACHED_FILENAME}`).value = data1.attachedFileName;
                                    }
                                });
                            }

                            document.getElementById(`${ID.BUSINESS_COMMENTS}`).removeAttribute('required');

                            this.formRequiredFields = this.form.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[required], select[required], input[type="radio"][required], input[type="checkbox"][required]`);
                            this.approveFormRequiredFields = this.form.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[approved], select[approved]`);
                            this.allFormFields = this.form.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}, select, input[type="radio"], input[type="checkbox"]`);

                            // Prefilling the CAN Application enrollment form
                            // Company Information
                            document.getElementById(`${ID.CORPORATE_NAME}`).value = data1.account.corporateName;
                            document.getElementById(`${ID.CORPORATE_ADDRESS}`).value = data1.account.corporateAddress;
                            document.getElementById(`${ID.BUSINESS_TYPE}`).value = data1.account.typeOfBusiness;
                            document.getElementById(`${ID.CORPORATE_PARENT_NAME}`).value = data1.account.corporateParentName;
                            document.getElementById(`${ID.CITY}`).value = data1.account.city;
                            document.getElementById(`${ID.CORPORATE_WEBSITE_ADDRESS}`).value = data1.account.corporateWebsiteAddress;
                            document.getElementById(`${ID.CORPORATE_TAX_ID_NUMBER}`).value = data1.account.corporateTaxIdNumber;

                            if (data1.account.corporateTaxIdNumber.length > 2 && !data1.account.corporateTaxIdNumber.includes('-')) {
                                const finalVal = `${data1.account.corporateTaxIdNumber.substr(0, 2)}-${data1.account.corporateTaxIdNumber.substr(2)}`;
                                document.getElementById(`${ID.CORPORATE_TAX_ID_NUMBER}`).value = finalVal;
                            }
                            document.getElementById(`${ID.STATE}`).value = data1.account.state;
                            document.getElementById(`${ID.ZIP}`).value = data1.account.zip;

                            // Company Officer
                            document.getElementById(`${ID.BUSINESS_TITLE}`).value = data1.officers[0].title;
                            document.getElementById(`${ID.BUSINESS_PHONE_NUMBER}`).value = data1.officers[0].phones[0].number;
                            document.getElementById(`${ID.BUSINESS_FAX_NUMBER}`).value = data1.officers[0].phones[1].number;
                            document.getElementById(`${ID.BUSINESS_FIRSTNAME}`).value = data1.officers[0].firstName;
                            document.getElementById(`${ID.BUSINESS_LASTNAME}`).value = data1.officers[0].lastName;
                            document.getElementById(`${ID.COMPANY_OFFICER_BUSINESS_EMAIL}`).value = data1.officers[0].email;
                            document.getElementById(`${ID.CONFIRM_COMPANY_OFFICER_BUSINESS_EMAIL}`).value = data1.officers[0].email;

                            // Manufacturer
                            document.getElementById(`${ID.MANUFACTURER}`).value = data1.account.manufacturer;
                            document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).value = data1.account.otherAccountNumber;
                            document.getElementById(`${ID.COMPANY}`).value = data1.account.fleetManagementCompany;

                            // Dealer information
                            document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).value = data1.account.dealerName;
                            document.getElementById(`${ID.DEALER_CODE}`).value = data1.account.dealerCode;
                            document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).value = data1.account.dealerEmailAddress;
                            document.getElementById(`${ID.CONFIRM_DEALER_BUSINESS_EMAIL}`).value = data1.account.dealerEmailAddress;
                            document.getElementById(`${ID.DEALER_FIRSTNAME}`).value = data1.account.dealerContactFirstName;
                            document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).value = data1.account.dealerPhoneNumber;
                            document.getElementById(`${ID.DEALER_LASTNAME}`).value = data1.account.dealerContactLastName;

                            // Instructions for Corporate Account Registration
                            if (data1.supporingDocumentMode === 'UPLOAD') {
                                document.querySelectorAll(`[name="${ATTRS.IS_INSTRUCTION_CONFIRM}"]`)[0].checked = true;
                            } else {
                                document.querySelectorAll(`[name="${ATTRS.IS_INSTRUCTION_CONFIRM}"]`)[1].checked = true;
                            }

                            // Electronic Signature
                            data1.account.corporateTitle = data1.officers[0].title;
                            document.getElementById(`${ID.TITLE_COMPANY_OFFICER}`).value = data1.account.corporateTitle;
                            document.getElementById(`${ID.NAME_COMPANY_OFFICER}`).value = `${data1.account.firstName} ${data1.account.lastName}`;
                            document.getElementById(`${ID.DATE}`).value = dateFormat;
                            document.getElementById(`${ID.AGREEMENT_SIGNED}`).checked = data1.account.agreementSigned;
                            document.getElementById(`${ID.AGREEMENT_SIGNED_COPY}`).checked = data1.account.agreementSigned;

                            // Visibility of form buttons according to API response
                            switch (data1.account.status) {
                            case 'APP':
                                document.getElementById(`${ID.APPROVE}`).classList.add('disabled');
                                document.getElementById(`${ID.REJECT}`).classList.add('disabled');
                                document.getElementById(`${ID.UPDATE}`).classList.remove('disabled');
                                break;
                            case 'REJ':
                                document.getElementById(`${ID.APPROVE}`).classList.add('disabled');
                                document.getElementById(`${ID.REJECT}`).classList.add('disabled');
                                document.getElementById(`${ID.UPDATE}`).classList.remove('disabled');
                                break;
                            case 'PND':
                                document.getElementById(`${ID.APPROVE}`).classList.remove('disabled');
                                document.getElementById(`${ID.REJECT}`).classList.remove('disabled');
                                document.getElementById(`${ID.UPDATE}`).classList.remove('disabled');
                                break;
                            default:
                                document.getElementById(`${ID.APPROVE}`).classList.add('disabled');
                                document.getElementById(`${ID.REJECT}`).classList.add('disabled');
                                document.getElementById(`${ID.UPDATE}`).classList.add('disabled');
                                break;
                            }
                        });
                } else if (e.target.matches(`#${ID.UPDATE_RECORD}`)) {
                    this.shortForm = document.querySelector(`.${CLASSES.CAN_APPLICATION_SHORT}`);
                    this.shortFormRequiredFields = this.shortForm.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[short-form][required], select[short-form][required]`);
                    this.allShortFormFields = this.shortForm.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[short-form], select[short-form]`);
                    const canApplicationForm = document.querySelector(`.${CLASSES.CAN_APPLICATION_FORM}`);
                    canApplicationForm.querySelector(`#${ID.CAN}`).innerHTML = this.shortFormValues.canNumber;
                    canApplicationForm.querySelector(`#${ID.CORPORATE_NAME}`).value = this.shortFormValues.corporateName;
                    canApplicationForm.querySelector(`#${ID.CORPORATE_ADDRESS}`).value = this.shortFormValues.address;
                    canApplicationForm.querySelector(`#${ID.CITY}`).value = this.shortFormValues.city;
                    canApplicationForm.querySelector(`#${ID.STATE}`).value = this.shortFormValues.state;
                    canApplicationForm.querySelector(`#${ID.ZIP}`).value = this.shortFormValues.zip;
                    canApplicationForm.querySelector(`#${ID.VEHICLE_TYPE}`).value = this.shortFormValues.typeOfVehicle ? this.shortFormValues.typeOfVehicle : '';
                    canApplicationForm.querySelector(`#${ID.FLEETSIZE}`).value = this.shortFormValues.fleetSize ? this.shortFormValues.fleetSize : '';
                    canApplicationForm.querySelector(`#${ID.BUSINESS_PHONE_NUMBER}`).value = this.shortFormValues.workNumber;
                    canApplicationForm.querySelector(`#${ID.BUSINESS_FAX_NUMBER}`).value = this.shortFormValues.fax;
                    canApplicationForm.querySelector(`#${ID.BUSINESS_FIRSTNAME}`).value = this.shortFormValues.officerFirstName;
                    canApplicationForm.querySelector(`#${ID.COMPANY_OFFICER_BUSINESS_EMAIL}`).value = this.shortFormValues.email;
                    canApplicationForm.querySelector(`#${ID.BUSINESS_LASTNAME}`).value = this.shortFormValues.officerLastName;
                }
            } else if (e.target.matches(`.${CLASSES.ACCORDION_TITLE_BLOCK}`) || e.target.matches(`.${CLASSES.ACCORDION_TITLE}`)) {
                let title = '';
                if (e.target.matches(`.${CLASSES.ACCORDION_TITLE}`)) {
                    title = e.target.parentNode;
                } else {
                    title = e.target;
                }

                let flag = true;

                if (title.parentNode.classList.contains(`${CLASSES.ACCORDION_ACTIVE}`)) {
                    flag = false;
                } else {
                    flag = true;
                }

                this.accordionTitle = document.querySelectorAll(`.${CLASSES.ACCORDION_TITLE_BLOCK}`);

                this.accordionTitle.forEach((title1) => {
                    title1.parentNode.classList.remove(`${CLASSES.ACCORDION_ACTIVE}`);
                });

                if (!flag) {
                    title.parentNode.classList.remove(`${CLASSES.ACCORDION_ACTIVE}`);
                } else {
                    title.parentNode.classList.add(`${CLASSES.ACCORDION_ACTIVE}`);
                }
            } else if (e.target.matches(`#${ID.MANUFACTURER_CAN_NO}`)) {
                this.manufacturerRequired();
            } else if (e.target.matches(`#${ID.MANUFACTURER_CAN_YES}`)) {
                this.manufacturerRequired();
            } else if (e.target.matches(`#${ID.DEALER_INFO_NO}`)) {
                this.dealerRequired();
            } else if (e.target.matches(`#${ID.DEALER_INFO_YES}`)) {
                this.dealerRequired();
            } else if (e.target.matches(`#${ID.UPDATE}`) && (this.shortForm !== null)) {
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
                this.CanApplicationPopupFormValidation.validateFields(
                    this.shortFormRequiredFields, this.shortForm);
                if (this.CanApplicationPopupFormValidation.getValidationResponse()) {
                    this.updateRecordDetails();
                } else {
                    this.CanApplicationPopupFormValidation.focusInvalidField();
                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                }
            } else if (e.target.matches(`#${ID.UPDATE}`)) {
                this.CanApplicationPopupFormValidation.validateFields(
                    this.formRequiredFields, this.form);
                if (this.CanApplicationPopupFormValidation.getValidationResponse()) {
                    this.updateEnrollmentDetails();
                } else {
                    this.CanApplicationPopupFormValidation.focusInvalidField();
                }
            } else if (e.target.matches(`#${ID.REJECT}`)) {
                const formData = new FormData();
                formData.set('submissionId', this.sin);
                formData.set('reasonCode', '007');

                this.Ajax.ajaxMultipartPostFn(API.REJECT_CAN_APPLICATION, formData, (success) => {
                    const data = JSON.parse(success);

                    if (data.action === 'REJECTED') {
                        document.querySelector('.pop-up__content--close').click();
                        this.MessageBox.showMessage('success', 'Form rejected successfully.');
                        this.updateTableDataCallback(true);
                    } else {
                        document.querySelector('.pop-up__content--close').click();
                        this.MessageBox.showMessage('error', data.action);
                    }
                });
            } else if (e.target.matches(`#${ID.APPROVE}`)) {
                this.CanApplicationPopupFormValidation.validateFields(
                    this.approveFormRequiredFields, this.form);
                if (this.CanApplicationPopupFormValidation.getValidationResponse()) {
                    this.approveEnrollmentDetails();
                } else {
                    this.CanApplicationPopupFormValidation.focusInvalidField();
                }
            } else if (e.target.matches(`#${ID.PRINT}`)) {
                document.body.classList.add('print-form');
                window.print();
                document.body.classList.remove('print-form');
            }
        });
        // On Change event listener
        document.addEventListener(EVENTS.KEYUP, (e) => {
            if (e.target.matches(`#${ID.CORPORATE_TAX_ID_NUMBER}`)) {
                if (e.target.value.length > 2 && !e.target.value.includes('-')) {
                    const finalVal = `${e.target.value.substr(0, 2)}-${e.target.value.substr(2)}`;
                    document.getElementById(`${ID.CORPORATE_TAX_ID_NUMBER}`).value = finalVal;
                }
            }
        });
    }

    /*
        Function : To update a complete record.
    */
    updateRecordDetails() {
        const formData = new FormData();
        formData.set('canNumber', this.shortForm.querySelector(`#${ID.CAN}`).innerHTML);
        [].forEach.call(this.allShortFormFields, (field) => {
            if (field.name === 'corporateName') {
                formData.set('companyName', field.value);
            } else if (field.name === 'corporateAddress') {
                formData.set('address', field.value);
            } else if (field.name === 'zipCode') {
                formData.set('zip', field.value);
            } else if (field.name === 'phoneNumber') {
                formData.set('phone', field.value);
            } else if (field.name === 'faxNumber') {
                formData.set('fax', field.value);
            } else if (field.name === 'typeOfVehicle') {
                formData.set('typeOfVehicle', field.value);
            } else if (field.name === 'fleetSize') {
                formData.set('fleetSize', field.value);
            } else if (field.name === 'companyOfficerBusinessEmail') {
                formData.set('email', field.value);
            } else {
                formData.set(field.name, field.value);
            }
        });

        this.Ajax.ajaxMultipartPostFn(API.UPDATE_CAN_DETAILS, formData, (success) => {
            const errorField = document.querySelector(`.${CLASSES.CAN_APPLICATION_UPDATE_ERROR}`);
            const data = JSON.parse(success);
            if (!(data.error) && data.canDataUpdated) {
                document.querySelector(`#${ID.POP_UP}`).classList.remove(`${CLASSES.POP_UP_OPEN}`);
                this.MessageBox.showMessage('success', 'Record Updated Successfully');
                this.updateTableDataCallback(true);
            } else if (data.error) {
                errorField.innerHTML = 'An Error Occured!';
                errorField.classList.remove(`${CLASSES.HIDDEN}`);
            } else {
                document.querySelector(`#${ID.POP_UP}`).classList.remove(`${CLASSES.POP_UP_OPEN}`);
                this.MessageBox.showMessage('error');
            }
        });
    }

    /**
     * Function: Update the Can Application enrollment form (Long Form)
     */
    updateEnrollmentDetails() {
        const data = this.getEnrollmentDetailsResponse;
        const url = API.UPDATE_CAN_ENROLLMENT_DETAILS;

        data.account.corporateName = document.getElementById(`${ID.CORPORATE_NAME}`).value;
        data.account.corporateAddress = document.getElementById(`${ID.CORPORATE_ADDRESS}`).value;
        data.account.typeOfBusiness = document.getElementById(`${ID.BUSINESS_TYPE}`).value;
        data.account.corporateParentName = document.getElementById(`${ID.CORPORATE_PARENT_NAME}`).value;
        data.account.city = document.getElementById(`${ID.CITY}`).value;
        data.account.corporateWebsiteAddress = document.getElementById(`${ID.CORPORATE_WEBSITE_ADDRESS}`).value;
        data.account.corporateTaxIdNumber = document.getElementById(`${ID.CORPORATE_TAX_ID_NUMBER}`).value;
        data.account.state = document.getElementById(`${ID.STATE}`).value;
        data.account.zip = document.getElementById(`${ID.ZIP}`).value;

        // Company Officer
        data.officers[0].phones[0].number = document.getElementById(`${ID.BUSINESS_PHONE_NUMBER}`).value;
        data.officers[0].title = document.getElementById(`${ID.BUSINESS_TITLE}`).value;
        data.officers[0].phones[1].number = document.getElementById(`${ID.BUSINESS_FAX_NUMBER}`).value;
        data.officers[0].firstName = document.getElementById(`${ID.BUSINESS_FIRSTNAME}`).value;
        data.officers[0].lastName = document.getElementById(`${ID.BUSINESS_LASTNAME}`).value;
        data.officers[0].email = document.getElementById(`${ID.COMPANY_OFFICER_BUSINESS_EMAIL}`).value;

        // Manufacturer
        data.account.manufacturer = document.getElementById(`${ID.MANUFACTURER}`).value;
        data.account.otherAccountNumber = document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).value;
        data.account.fleetManagementCompany = document.getElementById(`${ID.COMPANY}`).value;

        // Dealer information
        data.account.dealerName = document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).value;
        data.account.dealerCode = document.getElementById(`${ID.DEALER_CODE}`).value;
        data.account.dealerEmailAddress = document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).value;
        data.account.dealerContactFirstName = document.getElementById(`${ID.DEALER_FIRSTNAME}`).value;
        data.account.dealerPhoneNumber = document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).value;
        data.account.dealerContactLastName = document.getElementById(`${ID.DEALER_LASTNAME}`).value;

        // Instructions for Corporate Account Registration
        // document.querySelectorAll(`[name="${ATTRS.
        // IS_INSTRUCTION_CONFIRM}"]`)[data1.account.registrationOption - 1]
        // .checked = true;

        // Electronic Signature
        data.account.corporateTitle = document.getElementById(`${ID.TITLE_COMPANY_OFFICER}`).value;
        data.account.firstName = document.getElementById(`${ID.NAME_COMPANY_OFFICER}`).value;
        data.account.enrollmentDate = document.getElementById(`${ID.DATE}`).value;
        data.account.agreementSigned = document.getElementById(`${ID.AGREEMENT_SIGNED}`).checked;

        delete data.supporingDocumentMode;
        delete data.attachedFileName;

        this.Ajax.ajaxJsonPostFn(url, data, (success) => {
            const errorField = document.querySelector(`.${CLASSES.CAN_APPLICATION_UPDATE_ERROR}`);
            const response = JSON.parse(success);

            this.pageLoader.classList.add(`${CLASSES.HIDDEN}`);

            if (!(response.error) && (response.canEnrollmentDataUpdated === 'UPDATED')) {
                errorField.classList.remove(`${CLASSES.HIDDEN}`);
                document.querySelector(`#${ID.POP_UP}`).classList.remove(`${CLASSES.POP_UP_OPEN}`);
                this.MessageBox.showMessage('success', 'Record Updated Successfully');
                this.updateTableDataCallback(true);
            } else if (response.error) {
                errorField.innerHTML = response.error;
                errorField.classList.remove(`${CLASSES.HIDDEN}`);
            } else {
                document.querySelector(`#${ID.POP_UP}`).classList.remove(`${CLASSES.POP_UP_OPEN}`);
                this.MessageBox.showMessage('error', response);
            }
        });
    }

    manufacturerRequired() {
        console.log(this);

        if (document.getElementById(`${ID.MANUFACTURER_CAN_YES}`).checked) {
            document.getElementById(`${ID.MANUFACTURER}`).setAttribute('required', 'required');
            document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).setAttribute('required', 'required');
            document.getElementById(`${ID.COMPANY}`).setAttribute('required', 'required');

            document.getElementById(`${ID.MANUFACTURER}`).removeAttribute('disabled');
            document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).removeAttribute('disabled');
            document.getElementById(`${ID.COMPANY}`).removeAttribute('disabled');
        } else {
            document.getElementById(`${ID.MANUFACTURER}`).removeAttribute('required');
            document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).removeAttribute('required');
            document.getElementById(`${ID.COMPANY}`).removeAttribute('required');

            document.getElementById(`${ID.MANUFACTURER}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.MANUFACTURER_ACCOUNT_NUMBER}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.COMPANY}`).setAttribute('disabled', 'disabled');
        }

        this.formRequiredFields = this.form.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[required], select[required], input[type="radio"][required], input[type="checkbox"][required]`);
    }

    dealerRequired() {
        console.log(this);

        if (document.getElementById(`${ID.DEALER_INFO_YES}`).checked) {
            document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).setAttribute('required', 'required');
            document.getElementById(`${ID.DEALER_CODE}`).setAttribute('required', 'required');
            document.getElementById(`${ID.DEALER_CODE}`).setAttribute('approved', 'approved');
            document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).setAttribute('required', 'required');
            document.getElementById(`${ID.CONFIRM_DEALER_BUSINESS_EMAIL}`).setAttribute('required', 'required');
            document.getElementById(`${ID.DEALER_FIRSTNAME}`).setAttribute('required', 'required');
            document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).setAttribute('required', 'required');
            document.getElementById(`${ID.DEALER_LASTNAME}`).setAttribute('required', 'required');

            document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).removeAttribute('disabled');
            document.getElementById(`${ID.DEALER_CODE}`).removeAttribute('disabled');
            document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).removeAttribute('disabled');
            document.getElementById(`${ID.CONFIRM_DEALER_BUSINESS_EMAIL}`).removeAttribute('disabled');
            document.getElementById(`${ID.DEALER_FIRSTNAME}`).removeAttribute('disabled');
            document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).removeAttribute('disabled');
            document.getElementById(`${ID.DEALER_LASTNAME}`).removeAttribute('disabled');
        } else {
            document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).removeAttribute('required');
            document.getElementById(`${ID.DEALER_CODE}`).removeAttribute('required');
            document.getElementById(`${ID.DEALER_CODE}`).removeAttribute('approved');
            document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).removeAttribute('required');
            document.getElementById(`${ID.CONFIRM_DEALER_BUSINESS_EMAIL}`).removeAttribute('required');
            document.getElementById(`${ID.DEALER_FIRSTNAME}`).removeAttribute('required');
            document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).removeAttribute('required');
            document.getElementById(`${ID.DEALER_LASTNAME}`).removeAttribute('required');

            document.getElementById(`${ID.OVERLAY_DEALER_NAME}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.DEALER_CODE}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.DEALER_BUSINESS_EMAIL}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.CONFIRM_DEALER_BUSINESS_EMAIL}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.DEALER_FIRSTNAME}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.DEALER_PHONE_NUMBER}`).setAttribute('disabled', 'disabled');
            document.getElementById(`${ID.DEALER_LASTNAME}`).setAttribute('disabled', 'disabled');
        }

        this.formRequiredFields = this.form.querySelectorAll(`.${CLASSES.CAN_APPLICATION_INPUT}[required], select[required], input[type="radio"][required], input[type="checkbox"][required]`);
    }

    approveEnrollmentDetails() {
        const formData = new FormData();

        formData.set('enrollmentId', this.sin);
        formData.set('corporateName', document.getElementById(`${ID.CORPORATE_NAME}`).value);
        formData.set('corporateAddress', document.getElementById(`${ID.CORPORATE_ADDRESS}`).value);
        formData.set('city', document.getElementById(`${ID.CITY}`).value);
        formData.set('state', document.getElementById(`${ID.STATE}`).value);
        formData.set('zip', document.getElementById(`${ID.ZIP}`).value);
        formData.set('firstName', document.getElementById(`${ID.BUSINESS_FIRSTNAME}`).value);
        formData.set('lastName', document.getElementById(`${ID.BUSINESS_LASTNAME}`).value);
        formData.set('businessPhoneNumber', document.getElementById(`${ID.BUSINESS_PHONE_NUMBER}`).value);
        formData.set('businessEmailAddress', document.getElementById(`${ID.COMPANY_OFFICER_BUSINESS_EMAIL}`).value);
        formData.set('businessFaxNumber', document.getElementById(`${ID.BUSINESS_FAX_NUMBER}`).value);
        formData.set('dealerCode', document.getElementById(`${ID.DEALER_CODE}`).value);

        this.Ajax.ajaxMultipartPostFn(API.APPROVE_CAN_APPLICATION, formData, (success) => {
            const data = JSON.parse(success);
            if (data.action.toLowerCase() === 'approved') {
                document.querySelector('.pop-up__content--close').click();
                this.MessageBox.showMessage('success', data.action);
                this.updateTableDataCallback(true);
            } else {
                document.querySelector('.pop-up__content--close').click();
                this.MessageBox.showMessage('error', data.error);
            }
        });
    }
}
