// Constant dependencies
import { CUSTOM_EVENTS } from 'Constants';

import { renderer, customEventDispatcher, generateUniqueID } from 'utils';

import { Observer } from 'partials/observer';
import { UserProfile } from 'partials/profile';
import { DealerSearchBar } from 'partials/dealer-search-bar';
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { googleLocationsApi } from 'partials/google-maps';

import config from './config';
import content from './config/content';
import DealerResults from './views/DealerResults';
import PreferredDealer from './views/PreferredDealer';
import DealerLocatorModel from './models/DealerLocatorModel';

const IDS = {
    DEALER_SEARCH_BAR: 'preferred-dealer-plugin-container',
};

const CLASSES = {
    PREFERRED_DEALER_SEARCH_BAR: 'preferred-dealer-plugin--search-bar',
    PREFERRED_DEALER_RESULTS_VIEW: 'preferred-dealer-plugin--results-view',
    PREFERRED_DEALER_DEALER_VIEW: 'preferred-dealer-plugin--dealer-view',
};

/**
 * App class which is responsible for preferred dealer plugin
 */
export default class PreferredDealerWidget extends Observer {
  /**
   * @static BUTTON_STYLES
   * @description Available button styles for the dealer search button
   * e.g. EXTERIOR || INTERIOR
   * @type {{LINK: string, PRIMARY: string, SECONDARY: string}}
   */
    static BUTTON_STYLES = {
        LINK: 'link',
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
    };

  /**
   * @constructor
   * @description Sets up the default values and calls initialization methods
   * @param settings {Object} Allows overwrite config parameters
   */
    constructor(settings = {}) {
        super();

        this.domElement = document.getElementById(IDS.DEALER_SEARCH_BAR);
        this.config = { ...config, ...settings };
        this.content = { ...content, ...settings.content };
        this.profile = new UserProfile(this.config.brand);
        this.model = new DealerLocatorModel(
      Object.assign(this.profile.getLastLocation(), {
          preferredDealer: this.profile.getPreferredDealer(),
      })
    );
        this.id = generateUniqueID();
        this.searchBar = null;
        this.preferredDealerView = null;
        this.coupledInstance = false;
        this.valid = true;

        this.onDealerSelectCallback = this.onDealerSelectCallback.bind(this);
        this.onPreferredDealerValidate = this.onPreferredDealerValidate.bind(this);
        this.setSearchFromLocation = this.setSearchFromLocation.bind(this);
        this.createView = this.createView.bind(this);
        this.getInstance = this.getInstance.bind(this);

        this.attachEvents();
        this.dispatchInited();
        this.init();
    }

  /**
   * @method attacheEvents
   * @description Attaches events like model listeners
   */
    attachEvents() {
        this.model.attach(this);

        customEventDispatcher.addEventListener(
      CUSTOM_EVENTS.PREFERRED_DEALER_VALIDATE,
      this.onPreferredDealerValidate
    );

        customEventDispatcher.addEventListener(
      CUSTOM_EVENTS.PREFERRED_DEALER_GET_INSTANCE,
      this.getInstance
    );
    }

  /**
   * @method detachEvents
   * @description Detaches events like model listeners
   */
    detachEvents() {
        this.model.detach(this);

        customEventDispatcher.removeEventListener(
      CUSTOM_EVENTS.PREFERRED_DEALER_VALIDATE,
      this.onPreferredDealerValidate
    );

        customEventDispatcher.removeEventListener(
      CUSTOM_EVENTS.PREFERRED_DEALER_GET_INSTANCE,
      this.getInstance
    );
    }

  /**
   * @method onUpdate
   * @description Callback method when model is updated
   */
    onUpdate(prevState, nextState) {
        const { searchLocation, searchByType } = this.model.state;

    // This state is valid when the user searches for a location.
    // The search bar is destroyed, and a new instance of results is created
        if (
      prevState.searchLocation !== nextState.searchLocation &&
      nextState.searchLocation
    ) {
            if (this.searchBar) {
                this.searchBar.destroy();
            }

            this.profile.setLastLocation({ searchLocation, searchByType });
            this.createResultsView();
        }

        if (prevState.currentStep !== nextState.currentStep) {
            switch (nextState.currentStep) {
        // This state is valid when the user clicks on "Change Location" to change search
        // The results view is destroyed, and a new instance of search bar is created
            case 1:
                if (this.resultsView) {
                    this.resultsView.destroy();
                }

                if (this.preferredDealerView) {
                    this.destroyPreferredDealerView();
                }

                this.createSearchBar(false);
                break;
        // This state is valid when the user clicks on "Change Dealer" to change dealer
        // The preferred dealer view is destroyed, and a new instance of results is created
            case 2:
                if (this.preferredDealerView) {
                    this.destroyPreferredDealerView();
                }

                this.createResultsView();
                break;
            default:
            }
        }

    // This state is valid when the user clicks on a dealer from the dealer results list
    // to select as preferred dealer. A new instance of preferred dealer view is created
        if (
      nextState.preferredDealer &&
      prevState.preferredDealer !== nextState.preferredDealer
    ) {
            this.createPreferredDealerView(nextState.preferredDealer);
            this.dispatchPreferredDealerUpdated(nextState.preferredDealer);
        }
    }

  /**
   * @method init
   * @description Initializes the corresponding view based on state of model
   * Gets the city/region based on searchLocation and searchByType.
   * If the model has preferredDealer the final view is loaded. If not the search bar view is
   * loaded.
   */
    init() {
        const { preferredDealer, searchLocation, searchByType } = this.model.state;

        this.setSearchFromLocation(searchLocation, searchByType)
      .then(this.createView.bind(this, preferredDealer))
      .catch(e => console.error(`Error init of App, ${e}`));
    }

  /**
   * @method setSearchFromLocation
   * @description Sets searchString based on searchLocation and searchType
   * @param {String} searchLocation - Value of searchLocation, potentially from model
   * @param {String} searchByType - Value of searchByType, potentially from model
   * @returns {Promise} Promise which resolves when searchString is set with
   */
    setSearchFromLocation(searchLocation, searchByType) {
        return Promise.resolve()
      .then(() => {
        // If searchLocation is available and searchByType is place, use google reverse
        // lookup to get location, and return formatted city, region
          if (searchLocation && searchByType === 'place') {
              return googleLocationsApi
            .getCityStateForPlace(searchLocation)
            .then(location => `${location.city}, ${location.region}`);
          }

        // Any other cases, i.e. if searchLocation is not available or if searchByType is
        // zip, use searchLocation
          return searchLocation;
      })
      .then(this.model.setSearchString.bind(this.model))
      .catch(e => console.error(`Error setting searchString, ${e}`));
    }

  /**
   * @method createView
   * @description Creates the appropriate view based on value of preferredDealer
   * @param {Object} preferredDealer - The preferredDealer object
   */
    createView(preferredDealer) {
        if (this.config.presetDealerId) {
            this.createPresetDealerView();
        } else if (preferredDealer) {
            this.createPreferredDealerView(preferredDealer);
            this.dispatchPreferredDealerUpdated(preferredDealer);
        } else {
            this.createSearchBar();
        }
    }

  /**
   * @method createSearchBar
   * @description Creates the Search Bar instance and renders it to DOM element
   */
    createSearchBar(autoSubmitLocation = true) {
        this.model.setCurrentStep(1);
        this.searchBar = new DealerSearchBar({
            autoSubmitLocation,
            buttonStyle: this.getButtonStyle(this.config.dealerSearchBar.buttonStyle),
            country: this.config.country,
            ctaLabel: this.content.find,
            dealerSearchErrorMessage: this.content.dealerSearchErrorMessage,
            defaultLocation: {
                searchLocation: this.model.state.searchLocation,
                searchByType: this.model.state.searchByType,
                searchType: this.model.state.searchType,
            },
            onSubmit: this.setSearch.bind(this),
            searchInputLabel: this.content.searchInputLabel,
            sectionHeading: this.content.dealerSearchHeading,
            theme: DealerSearchBar.THEMES.PREFERRED_DEALER,
        });
        renderer.insert(this.searchBar.render(), this.domElement);
        this.domElement.classList.add(CLASSES.PREFERRED_DEALER_SEARCH_BAR);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_RESULTS_VIEW);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_DEALER_VIEW);
    }

  /**
   * @method createResultsView
   * @description Creates Results view
   */
    createResultsView() {
        this.model.setCurrentStep(2);
        this.resultsView = new DealerResults({
            model: this.model,
            country: this.config.country,
            brand: this.config.brand,
            language: this.config.language,
            onDealerSelectCallback: this.onDealerSelectCallback,
            content: this.content,
        });

        renderer.insert(this.resultsView.render(), this.domElement);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_SEARCH_BAR);
        this.domElement.classList.add(CLASSES.PREFERRED_DEALER_RESULTS_VIEW);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_DEALER_VIEW);
    }

  /**
   * @method createPreferredDealerView
   * @description Creates the PreferredDealer instance and renders it to DOM element
   * @param preferredDealer {Object} Preferred dealer object to display
   * @param disableChange {Boolean} Indicator to disable changing the dealer
   */
    createPreferredDealerView(preferredDealer, disableChange) {
        this.model.setCurrentStep(3);
        this.preferredDealerView = new PreferredDealer({
            model: this.model,
            preferredDealer,
            disableChange,
            showMap: this.config.showMap,
            content: this.content,
        });

        renderer.insert(this.preferredDealerView.render(), this.domElement);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_SEARCH_BAR);
        this.domElement.classList.remove(CLASSES.PREFERRED_DEALER_RESULTS_VIEW);
        this.domElement.classList.add(CLASSES.PREFERRED_DEALER_DEALER_VIEW);
    }

  /**
   * @method createPresetDealerView
   * @description Fetches the preset dealer by id, and on success creates
   * a preferred dealer view and dispatches the preferred dealer update event
   */
    createPresetDealerView() {
        dealerLocatorApi
      .getDealer({
          country: this.config.country,
          id: this.config.presetDealerId,
          language: this.config.language,
          type: 'dealers',
      })
      .then((dealer) => {
          this.createPreferredDealerView(dealer, true);
          this.dispatchPreferredDealerUpdated(dealer);
      })
      .catch(() => {
          this.createSearchBar();
      });
    }

  /**
   * @method setSearch
   * @description Sets the selected search location
   * @param location {String} Location postal code
   */
    setSearch(location) {
        const searchLocation = location.searchLocation;
    // Explicitly set model to null, so that if/when search location hasn't changed when user
    // click Find CTA, there is a change in model to cause the `onUpdate` to be called
        this.model.setSearchLocation(null);

        this.model.setSearchByType(location.searchByType);
        this.model.setSearchString(location.searchString);
    // Set model to value required.
        this.model.setSearchLocation(searchLocation);
    }

  /**
   * @method dispatchPreferredDealerUpdated
   * @description dispatches custom event when preferred dealer has been set or unset
   * @param preferredDealer {Object} Preferred Dealer
   */
    dispatchPreferredDealerUpdated(preferredDealer) {
        const { searchString, searchByType } = this.model.state;

        customEventDispatcher.dispatchEvent(
      customEventDispatcher.createCustomEvent(
        CUSTOM_EVENTS.PREFERRED_DEALER_UPDATED,
          {
              detail: {
                  preferredDealerName: preferredDealer ? preferredDealer.name : '',
                  dealerId: preferredDealer ? preferredDealer.dealerId : '',
                  searchByType,
                  searchString,
              },
          }
      )
    );
    }

  /**
   * @method onDealerSelectCallback
   * @description Callback method when dealer is selected
   */
    onDealerSelectCallback(dealer) {
        console.log(this.config);
        if (this.config.onDealerSelectValidate) {
            this.config
        .onDealerSelectValidate(dealer.dealerId, dealer.country)
        .then((isValid) => {
            if (isValid) {
                this.profile.setPreferredDealer(dealer);
                this.model.setPreferredDealer(dealer);
            } else {
                this.resultsView.showErrorMessageOnDealer(dealer.dealerId);
            }
        });
        } else {
            console.log('dealer', dealer);
            this.profile.setPreferredDealer(dealer);
            this.model.setPreferredDealer(dealer);
        }
    }

  /**
   * @method destroyPreferredDealerView
   * @description Destroy the preferred dealer view, emit event to update dealer as unset
   * and set model preferredDealer as null
   */
    destroyPreferredDealerView() {
        this.preferredDealerView.destroy();
        this.dispatchPreferredDealerUpdated(null);
        this.model.setPreferredDealer(null);
    }

  /**
   * @method destroy
   * @description Destroy views
   */
    destroy() {
        this.detachEvents();

        if (this.searchBar) {
            this.searchBar.destroy();
        }

        if (this.resultsView) {
            this.resultsView.destroy();
        }

        if (this.preferredDealerView) {
            this.destroyPreferredDealerView();
        }
    }

  /**
   * @method onPreferredDealerValidate
   * @description Event listener for custom event
   */
    onPreferredDealerValidate() {
        this.validate();
    }

  /**
   * @method validate
   * @description If the preferredDealer is not set show an error message
   */
    validate() {
        const { preferredDealer, currentStep } = this.model.state;
        let valid = true;

        if (!preferredDealer) {
            valid = false;
            if (currentStep === 1) {
                this.searchBar.showError(this.content.emptyDealerErrorMessage, false);
            } else {
                this.resultsView.showErrorMessage();
            }
        }
        this.valid = valid;
    }

  /**
   * @method isValid
   * @description Returns validity state
   * @returns {Boolean} True if preferred dealer is selected
   */
    isValid() {
        return this.valid;
    }

  /**
   * @method set focus
   * @description gives DOM element focus
   */
    set focus(focus = true) {
        const { currentStep } = this.model.state;
        if (focus) {
            if (currentStep === 1 && this.searchBar) {
                this.searchBar.focus = true;
            } else if (currentStep === 2 && this.resultsView) {
                this.resultsView.focus = true;
            }
        }
    }

  /**
   * @method getButtonStyle
   * @description Return the style of the dealer search button.
   * @param type {String} The type of the button;
   */
    getButtonStyle(type) {
        console.log(this);
        if (type === PreferredDealerWidget.BUTTON_STYLES.SECONDARY) {
            return 'button button_secondary_alt';
        } else if (type === PreferredDealerWidget.BUTTON_STYLES.LINK) {
            return 'link link_primary';
        }

        return 'button button_primary';
    }

  /**
   * @method dispatchInited
   * @description dispatched custom event when plugin is initialized
   */
    dispatchInited() {
        customEventDispatcher.dispatchEvent(
      customEventDispatcher.createCustomEvent(
        CUSTOM_EVENTS.PREFERRED_DEALER_INITED,
          {
              detail: {
                  instance: this,
              },
          }
      )
    );
    }

  /**
   * @method getInstance
   * @description Method for getting instance of PreferredDealer Class
   */
    getInstance() {
        customEventDispatcher.dispatchEvent(
      customEventDispatcher.createCustomEvent(
        CUSTOM_EVENTS.PREFERRED_DEALER_SET_INSTANCE,
          {
              detail: {
                  instance: this,
              },
          }
      )
    );
    }

  /**
   * @method setInstanceMode
   * @description sets instance mode for plugin
   */
    setInstanceMode(coupledInstance) {
        this.coupledInstance = coupledInstance;
    }
}

