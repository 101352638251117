import { EVENTS, CLASSES } from 'Constants';

const ID = {
    CONTENT: 'content'
};

export default class UISModalPopUp {
    constructor(element) {
        this.element = element;
        this.init();
        this.modalPopUp = null;
        this.modalOpenButton = null;
        this.closeButtonList = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.closeButtonList = document.getElementsByClassName(`${CLASSES.POPUP__CONTENT_CLOSE}`);
        this.modalPopUpList = document.getElementsByClassName(`${CLASSES.POPUP}`);
    }

    attachEvents() {
        const modalPopUpListItem = this.modalPopUpList;

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.classList.contains(`${CLASSES.MODAL__BUTTON}`)) {
                const popUpElement = e.target.parentNode.nextElementSibling;
                popUpElement.classList.add(`${CLASSES.POPUP_OPEN}`);
            }
        });

        [].forEach.call(this.closeButtonList, (el) => {
            el.addEventListener(EVENTS.CLICK, () => {
                [].forEach.call(modalPopUpListItem, (element) => {
                    this.removeTransform();
                    this.stopVideo();
                    element.classList.remove(`${CLASSES.POPUP_OPEN}`);
                });
            });
        });
    }

    stopVideo() {
        console.log(this);
        const iframe = document.querySelector('iframe');
        const video = document.querySelector('video');

        if (iframe) {
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }

        if (video) {
            video.pause();
        }
    }

    removeTransform() {
        console.log(this);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.remove(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.remove(`${CLASSES.HEADER_POSITION}`);
        }
    }
}
