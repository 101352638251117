import Ajax from 'modules/Ajax';


export default class BrcRestrict {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }


    init() {
        this.loadModules();
        this.labelCheck();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
    }


    labelCheck() {
        console.log(this);
        const urlServlet = '/bin/microsites/BRC-role-restriction';
        const siteName = 'brc-info';
        const accessName = document.getElementsByName('accessData');
        const accessData = {
            siteName,
        };
        for (let i = 0; i < accessName.length; i += 1) {
            if (accessName[i].getAttribute('data-sortorder') != null) {
                accessData[`${i}`] = (accessName[i].getAttribute('data-sortorder'));
            }
        }
        accessData.length = accessName.length;
        const siteHostName = window.location.hostname;
        if (!this.stringIncludes(siteHostName, 'author')) {
            this.Ajax.ajaxPostFn(urlServlet, accessData, (success) => {
                const servletData = JSON.parse(success);
                const z = document.getElementsByName('fleet-tile-feature-content');
                for (let a = 0; a < z.length; a += 1) {
                    if (servletData[a] === true) {
                        z[a].style.display = 'block';
                    }
                }
            });
        } else {
            const z = document.getElementsByName('fleet-tile-feature-content');
            for (let a = 0; a < z.length; a += 1) {
                z[a].style.display = 'block';
            }
        }
    }

    stringIncludes(a, b) {
        console.log(this);
        return a.indexOf(b) !== -1;
    }
}
