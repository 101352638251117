import Ajax from 'modules/Ajax';

const API = {
    CHECK_ENTITLMENT_LABEL: '/bin/microsites/check-fleet-admin-access'
};

const CLASS = {
    ADMIN_MENU: 'top-nav__item--category',
    ADMIN_MENU_HIDDEN: 'top-nav__item--hidden'
};

export default class FleetEntitlementLabel {
    constructor(element) {
        this.element = element;
        this.entitlmentLabelValue = null;
        this.siteName = null;
        this.adminMenu = null;
        this.button = null;
        this.init();
    }

    init() {
        this.loadModules();
        this.cacheDOMElement();
        this.checkAdminMenuDisplay();
    }

    /*
        Function : to load other js files.
    */
    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    /*
        Function : to cache DOM element, that are to be used later.
    */
    cacheDOMElement() {
        this.entitlmentLabelValue = window.microsites.applicationProperties.entitlmentLabel;
        this.siteName = window.microsites.applicationProperties.siteName;
    }

    /*
        Function : To check whether to display admin menu or not,
                    according to the API response.
    */
    checkAdminMenuDisplay() {
        this.Ajax.readTextFile(API.CHECK_ENTITLMENT_LABEL, (text) => {
            const data = JSON.parse(text);
            this.adminMenu = document.querySelector(`.${CLASS.ADMIN_MENU}`);
            if (data === false || data.error) {
                this.adminMenu.remove();
            } else {
                this.adminMenu.classList.remove(`${CLASS.ADMIN_MENU_HIDDEN}`);
            }
        });
    }
}
