// import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    BUTTONMATRIX__ITEM: 'button-matrix__item',
    BUTTONMATRIX__HIDE: 'button-matrix__hide',
    BUTTONMATRIX__CONTAINER: 'button-matrix__container'
};

export default class ModalPopUp {
    constructor(element) {
        this.element = element;
        this.siteName = null;
        this.index = 0;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.buttonMatrix();
        this.buttonMatrixAPI();
    }

    cacheDOMElement() {
        this.buttonMatrixItem = document.querySelectorAll(`.${CLASSES.BUTTONMATRIX__ITEM}`);
        this.buttonMatrixContainer = document.querySelector(`.${CLASSES.BUTTONMATRIX__CONTAINER}`);
        this.siteName = window.microsites.applicationProperties.siteName;
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    buttonMatrix() {
        [].forEach.call(this.buttonMatrixItem, (e) => {
            const entitleValue = e.getElementsByTagName('input')[0].value;

            if (entitleValue) {
                e.classList.add(`${CLASSES.BUTTONMATRIX__HIDE}`);
            }
        });
    }

    buttonMatrixAPI() {
        if (this.index < this.buttonMatrixItem.length) {
            const entitleValue = this.buttonMatrixItem[this.index].getElementsByTagName('input')[0].value;

            if (entitleValue !== '') {
                const url = `/bin/microsites/check-entitlement-label?entitlementLabel=${entitleValue}`;

                this.Ajax.readTextFile(url, (text) => {
                    const data = JSON.parse(text);

                    if (data === false || data.error) {
                        this.buttonMatrixItem[this.index].remove();
                    } else {
                        this.buttonMatrixItem[this.index].classList.remove(`${CLASSES.BUTTONMATRIX__HIDE}`);
                    }

                    this.index += 1;
                    this.buttonMatrixAPI();
                });
            } else {
                this.index += 1;
                this.buttonMatrixAPI();
            }
        }
    }
}
