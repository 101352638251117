/**
 * Template for waypoint nav
 * Uses ES6 template literal to composite the markup for a waypoint nav
 * @param waypoints {Array} the array of waypoint nav items
 */
export default (waypoints = []) => `
    ${waypoints.map(waypoint => `
        <li>
            <a class="waypoint-nav__link" href="#${waypoint.waypointKey}">
                <span class="waypoint-nav__label">${waypoint.waypointLabel}</span><span class="waypoint-nav__dot"></span>
            </a>
        </li>
    `).join('')}`;
