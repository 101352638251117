import { EVENTS } from 'Constants';
import Modal from 'partials/modal';
import DataDeletionTableTemplate from '../templates/data-deletion/table';
import DataDeletionModalContentTemplate from '../templates/data-deletion/modal-content';

const ID = {
    SEARCH: 'search',
    PAGE_LOADER: 'page-loader',
    YES: 'yes',
    NO: 'no'
};

const CLASSES = {
    ERROR: 'error',
    FORM_INPUT: 'form__input',
    TABLE_CONTAINER: 'data-deletion__result-table-container',
    LOADER_HIDE: 'overlay--hidden',
    DELETE_BTN: 'delete-btn',
    MSG_CONTAINER: 'data-deletion__msg-container',
    HIDE: 'hide',
    ALERT_SUCCESS: 'alert-box--success',
    ALERT_DANGER: 'alert-box--danger'
};

const STATUS = {
    SUCCESS: 'success',
    DANGER: 'danger'
};
const URL = {
    search: '/bin/microsites/secure/dataDeletion/getDataStatus',
    delete: '/bin/microsites/secure/dataDeletion/deleteData'
};

export default class DataDeletion {
    constructor(element) {
        this.element = element;
        this.tableRecords = null;
        this.modal = null;
        this.init();
        this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    /**
     * @method init
     * @description Method called on load of data deletion js
     */
    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    /**
     * @method cacheDOMElement
     * @description Caches the DOM elements in variables.
     */
    cacheDOMElement() {
        this.searchBtn = this.element.querySelector(`#${ID.SEARCH}`);
        this.emailInput = this.element.querySelector(`.${CLASSES.FORM_INPUT} input`);
        this.tableRowContainer = this.element.querySelector(`.${CLASSES.TABLE_CONTAINER}`);
        this.loader = document.querySelector(`#${ID.PAGE_LOADER}`);
        this.msgContainer = this.element.querySelector(`.${CLASSES.MSG_CONTAINER}`);
    }

    /**
     * @method attachEvents
     * @description Events attached to DOM elements.
     */
    attachEvents() {
        this.searchBtn.addEventListener(EVENTS.CLICK, () => {
            if (this.emailInput.value && this.isValidEmail(this.emailInput.value)) {
                this.emailInput.parentElement.classList.remove(`${CLASSES.ERROR}`);
                this.addLoader();
                this.emailValue = this.emailInput.value;
                this.fetchTableData();
            } else {
                this.emailInput.parentElement.classList.add(`${CLASSES.ERROR}`);
            }
        });

        this.emailInput.addEventListener(EVENTS.BLUR, () => {
            if (this.emailInput.value && this.isValidEmail(this.emailInput.value)) {
                this.emailInput.parentElement.classList.remove(`${CLASSES.ERROR}`);
            } else {
                this.emailInput.parentElement.classList.add(`${CLASSES.ERROR}`);
            }
        });
    }

    /**
     * @method isValidEmail
     * @description Validate email value passed using email regex.
     * @param email email value to be validated.
     */
    isValidEmail(email) {
        return this.emailRegex.test(email);
    }

    /**
     * @method fetchTableData
     * @description Fetching data deletion status details to be displayed in the table.
     */
    fetchTableData() {
        fetch(URL.search, {
            body: JSON.stringify({ email: this.emailValue }),
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin'
            },
            method: 'POST',
        }).then(response => response.json()).then((data) => {
            if (data && !data.error) {
                if (Object.keys(data).length) {
                    this.hideAlertMsg();
                    this.tableRecords = data;
                    this.tableRowContainer.innerHTML = DataDeletionTableTemplate(data);
                    this.emailInput.value = '';
                } else {
                    this.tableRowContainer.innerHTML = '';
                    this.showAlertMsg('Access not authorized.', STATUS.DANGER);
                }
            } else if (data.error) {
                this.tableRowContainer.innerHTML = '';
                this.showAlertMsg(data.error, STATUS.DANGER);
            } else {
                this.tableRowContainer.innerHTML = '';
                this.showAlertMsg('Error accessing details.', STATUS.DANGER);
            }
            this.removeLoader();
            return data;
        }).then((data) => {
            if (!data.error) {
                this.addDeleteBtnEventListner();
            }
        })
        .catch((error) => {
            this.tableRowContainer.innerHTML = '';
            this.showAlertMsg(error, STATUS.DANGER);
            console.error('There was error in fetching details : ', error);
            this.removeLoader();
        });
    }

    addDeleteBtnEventListner() {
        this.deleteBtns = document.querySelectorAll(`.${CLASSES.DELETE_BTN}`);
        [].forEach.call(this.deleteBtns, (btn) => {
            btn.addEventListener(EVENTS.CLICK, () => {
                this.openModal(btn.id);
            });
        });
    }

    /**
     * @method openModal
     * @description Opens an Overlay to get a confirmation for deletion of records.
     * @param providerId Provider ID for which records are to be deleted.
     */
    openModal(providerId) {
        this.modal = new Modal(undefined, {
            modalContent: DataDeletionModalContentTemplate(providerId),
            sizeSmall: Modal.SIZES.INSET,
            sizeLarge: Modal.SIZES.DIALOG,
            callbacks: {
                beforeClose: () => {
                    // clean up and remove the modal on close
                    this.modal.destroy();
                    this.modal = null;
                },
                afterOpen: this.afterOpen.bind(this, providerId)
            }
        });

        this.modal.open();
    }

    /**
     * @method afterOpen
     * @description callback method called after modal opens and used to
     * cache buttons present in modal content and add listeners to them.
     * @param providerId Provider ID for which records are to be deleted.
     */
    afterOpen(providerId) {
        const yesBtn = document.querySelector(`#${ID.YES}`);
        const noBtn = document.querySelector(`#${ID.NO}`);

        yesBtn.addEventListener(EVENTS.CLICK, () => {
            this.modal.close();
            this.deleteProjectRecord(providerId);
        });

        noBtn.addEventListener(EVENTS.CLICK, () => {
            this.modal.close();
        });
    }

    /**
     * @method deleteProjectRecord
     * @description Method to call API to delete record for particular provider ID.
     * @param providerId Provider ID for which records are to be deleted.
     */
    deleteProjectRecord(providerId) {
        this.addLoader();

        fetch(URL.delete, {
            body: JSON.stringify({ email: this.emailValue, provider: providerId }),
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin'
            },
            method: 'POST',
        }).then(response => response.json()).then((data) => {
            if (data && !data.error) {
                const provId = Object.keys(data)[0];
                if (data[provId]) {
                    this.showAlertMsg('Data has been deleted successfully', STATUS.SUCCESS);
                } else {
                    this.showAlertMsg('Error occured while deleting data', STATUS.DANGER);
                }
                const validateProvId = provId;
                if (!validateProvId) {
                    return data;
                }
                this.tableRecords[provId] = data[provId] ? null : this.tableRecords[provId];
                this.tableRowContainer.innerHTML = DataDeletionTableTemplate(this.tableRecords);
            } else if (data && data.error) {
                this.showAlertMsg(data.error, STATUS.DANGER);
            } else {
                this.showAlertMsg('There was error in json response', STATUS.DANGER);
            }
            return data;
        }).then((data) => {
            this.removeLoader();
            if (data && !data.error) {
                this.addDeleteBtnEventListner();
            }
        })
        .catch((error) => {
            this.showAlertMsg(error, STATUS.DANGER);
            console.error('There is an error in fetching details', error);
            this.removeLoader();
        });
    }

    /**
     * @method addLoader
     * @description Adds page loader to the page.
     */
    addLoader() {
        this.loader.classList.remove(CLASSES.LOADER_HIDE);
    }

    /**
     * @method removeLoader
     * @description Removes page loader from page.
     */
    removeLoader() {
        this.loader.classList.add(CLASSES.LOADER_HIDE);
    }

    /**
     * @method showAlertMsg
     * @description Show error messages coming from api response.
     */
    showAlertMsg(message, status) {
        if (status === STATUS.SUCCESS &&
            this.msgContainer.classList.contains(CLASSES.ALERT_DANGER)) {
            this.msgContainer.classList.remove(CLASSES.ALERT_DANGER);
            this.msgContainer.classList.add(CLASSES.ALERT_SUCCESS);
        } else if (status === STATUS.DANGER &&
            this.msgContainer.classList.contains(CLASSES.ALERT_SUCCESS)) {
            this.msgContainer.classList.remove(CLASSES.ALERT_SUCCESS);
            this.msgContainer.classList.add(CLASSES.ALERT_DANGER);
        }
        this.msgContainer.classList.remove(CLASSES.HIDE);
        this.msgContainer.firstElementChild.innerText = message;
    }

    /**
     * @method hideAlertMsg
     * @description Hide error messages coming from api response.
     */
    hideAlertMsg() {
        this.msgContainer.classList.add(CLASSES.HIDE);
        this.msgContainer.firstElementChild.innerHTML = '';
    }
}
