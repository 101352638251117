import { EVENTS } from 'Constants';
import { accordionParser } from 'partials/accordion';

const ATTRIBUTES = {
    ACCORDION_BTN: 'data-accordion-button',
    ACCORDION_ITEMS: 'data-accordion-item'
};

export default class DogAccordion {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.accordionElement = document.querySelectorAll('[data-accordion]');
        [...this.accordionElement].forEach(accordion =>
            accordionParser.createAccordion({ element: accordion })
        );
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`[${ATTRIBUTES.ACCORDION_BTN}]`)) {
                const parentAccordion = this.getClosest(e.target, '.accordion__item');
                if (parentAccordion.previousElementSibling) {
                    parentAccordion.previousElementSibling.scrollIntoView(true);
                }
            }
        });
    }

    getClosest(elem, selector) {
        console.log('getClosest: ', this);
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    }
}
