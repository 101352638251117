/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

export default ({ ctaLabel, buttonStyle, sectionHeading }) => htmlNode`
    <form onSubmit="return false;">
        <h3 class="preferred-dealer-plugin__sub-heading">${sectionHeading}</h3>
        <div class="dealer-search-bar">
            <div class="dealer-search-bar__location-search" data-location-search=""></div>
            <div class="dealer-search-bar__cta">
                <button type="button" class="${buttonStyle}" id="blueteccontactdealersearch" data-cta-button="" data-analytics-trigger="contact-dealer-search">
                    ${ctaLabel}
                </button>
            </div>
        </div>
    </form>
`;

