const defaultConfig = {
    brand: null,
    country: window.microsites.ns('pageData').country,
    dealerSearchBar: {
        buttonStyle: 'button button_secondary_alt',
    },
    distanceUnit: 'Mile',
    distanceUnitAbbr: 'mi',
    language: window.microsites.ns('pageData').language,
    presetDealerId: null,
    showMap: true,
};

const {
  brand,
  country,
  dealerSearchBar: { buttonStyle },
  distanceUnit,
  distanceUnitAbbr,
  language,
  presetDealerId,
  showMap,
} = Object.assign(
  defaultConfig,
  window.microsites.ns('pageData', 'preferredDealerPlugin').config
);
// Configuration options
export default {
    brand,
    country,
    dealerSearchBar: {
        buttonStyle,
    },
    distanceUnit,
    distanceUnitAbbr,
    language,
    presetDealerId,
    showMap,
};

