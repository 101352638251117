import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';


const ID = {
    DISPLAY_RESULT: 'uis-dashboard-listing',
    POPUP_ID: 'pop-up-id',
    VIEWBUTTON: 'viewbutton',
    CONTENT: 'content',
    UIS_FILTER_STATUS: 'uisfilter_status',
    UIS_DASHBOARD: 'UIS-Dashboard',
    TOTAL_COUNTS: 'total_count',
    REVIEW_COUNTS: 'review_count',
    DENY_COUNTS: 'deny_count',
    CANCEL_COUNTS: 'cancel_count',
    PENDING_COUNTS: 'pending_count',
};
const CLASSES = {
    SORT__DATE: 'brc-sites-report__sortDate',
};

let displayData = '';

export default class UISDashboard {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.ascending = false;
        this.htmlBuild = '';
        this.resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
        this.loadModules();
        this.ajaxcall();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
    }


    ajaxcall() {
        document.getElementById('UIS-Dashboard').style.display = 'none';
        const siteName = 'uis-dashboard';
        const option = 'complete';
        const userType = 'admin';
        const url = '/bin/microsites/uis/dashboard';
        const data = {
            siteName,
            option,
            userType
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            displayData = JSON.parse(JSON.parse(success));
            const CountAllEntries = document.getElementById(`${ID.TOTAL_COUNTS}`);
            CountAllEntries.innerHTML = `Total Request(s): <strong>${displayData.length}</strong>`;
            const CountReviewEntries = document.getElementById(`${ID.REVIEW_COUNTS}`);
            const CountDenyEntries = document.getElementById(`${ID.DENY_COUNTS}`);
            const CountCancelEntries = document.getElementById(`${ID.CANCEL_COUNTS}`);
            const CountPendingEntries = document.getElementById(`${ID.PENDING_COUNTS}`);

            let ReviewArray = 0;
            let DenyArray = 0;
            let CancelArray = 0;
            let PendingArray = 0;

            for (let key = 0; key < displayData.length; key += 1) {
                if (displayData[key].status === 'Reviewed') {
                    ReviewArray += 1;
                } else if (displayData[key].status === 'Denied') {
                    DenyArray += 1;
                } else if (displayData[key].status === 'Canceled') {
                    CancelArray += 1;
                } else if (displayData[key].status === 'Pending') {
                    PendingArray += 1;
                }
            }
            CountReviewEntries.innerHTML = `Reviewed request(s): <strong>${ReviewArray}</strong>`;
            CountDenyEntries.innerHTML = `Denied request(s): <strong>${DenyArray}</strong>`;
            CountCancelEntries.innerHTML = `Canceled request(s): <strong>${CancelArray}</strong>`;
            CountPendingEntries.innerHTML = `Pending request(s): <strong>${PendingArray}</strong>`;

            document.getElementById('loader').style.display = 'none';
            document.getElementById('UIS-Dashboard').style.display = 'block';
            document.getElementById('UIS-Dashboard').style.width = '100%';
            this.displayDetailsPage();
        });

        this.element.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.UIS_FILTER_STATUS}`)) {
                e.preventDefault();

                const selection = document.getElementById(`${ID.UIS_FILTER_STATUS}`);
                if (selection.value === 'Pending') {
                    this.statusFilter(selection.value);
                } else if (selection.value === 'Denied') {
                    this.statusFilter(selection.value);
                } else if (selection.value === 'Reviewed') {
                    this.statusFilter(selection.value);
                } else if (selection.value === 'Canceled') {
                    this.statusFilter(selection.value);
                } else {
                    this.displayDetailsPage();
                }
            }
        });


        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target && e.target.matches(`.${ID.VIEWBUTTON}`)) {
                e.preventDefault();
                const uisIdNum = e.target.getAttribute('data-ref');
                const edit = document.getElementById('editLink').value;
                const editUrl = `${edit}?ref=${uisIdNum}&mode=view`;
                window.open(editUrl);
            } else if (e.target && e.target.matches(`.${CLASSES.SORT__DATE}`)) {
                e.preventDefault();
                const tbody = document.querySelector('#reportTable tbody');
                const rows = [].slice.call(tbody.querySelectorAll('tr'));
                rows.sort((a, b) => {
                    const first = new Date(a.cells[6].getAttribute('data-date'));
                    const second = new Date(b.cells[6].getAttribute('data-date'));

                    if (this.ascending) {
                        if (first > second) return 1;
                        return -1;
                    }
                    if (first > second) return -1;
                    return 1;
                });

                rows.forEach((v) => {
                    tbody.appendChild(v);
                });
                this.ascending = !this.ascending;
            }
        });
    }

    displayDetailsPage() {
        this.htmlBuild = '';
        for (let key = 0; key < displayData.length; key += 1) {
            const uisRecord = displayData[key];
            this.htmlBuild += `<tr role="row"  class="uis-dashboard__border_rule">
                <td colspan="1" class="uis-dashboard__td_padding"><a href="#" title="View/Edit Record" class="viewbutton" target="_blank" rel="noopener noreferrer" data-ref=${uisRecord.uisId}>${uisRecord.uisId}</a></td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.upfitter}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.topic}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.person}</td>
                <td colspan="1" class="uis-dashboard__td_padding" >${uisRecord.position}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.status}</td>
                <td colspan="1" class="uis-dashboard__td_padding" data-date="${uisRecord.statusDate}">${uisRecord.statusDate.split(' ')[0]}</td>
              </tr>`;
        }
        this.resultDisplay.innerHTML = this.htmlBuild;
        this.htmlBuild = '';
    }

    statusFilter(selection) {
        this.htmlBuild = '';
        for (let key = 0; key < displayData.length; key += 1) {
            const uisRecord = displayData[key];
            if (uisRecord.status === selection) {
                this.htmlBuild += `<tr role="row" class="uis-dashboard__border_rule">
                <td colspan="1" class="uis-dashboard__td_padding"><a href="#"title="View/Edit Record" class="viewbutton" target="_blank" rel="noopener noreferrer" data-ref=${uisRecord.uisId}>${uisRecord.uisId}</a></td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.upfitter}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.topic}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.person}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.position}</td>
                <td colspan="1" class="uis-dashboard__td_padding">${uisRecord.status}</td>
                <td colspan="1" class="uis-dashboard__td_padding" data-date="${uisRecord.statusDate}">${uisRecord.statusDate.split(' ')[0]}</td>
              </tr>`;
            }
        }
        this.resultDisplay.innerHTML = this.htmlBuild;
        this.htmlBuild = '';
    }
}
