import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import { ReCAPTCHA } from 'partials/recaptcha';
import ContactUsUtil from 'modules/ContactUsUtil';
import RangeSlider from 'modules/RangeSlider';

// Util dependencies
import { renderer } from 'utils';

const ID = {
    QUIZ_CONTAINER__FORM_RECAPTCHA: 'quiz-container-form-recaptcha',
    SUBMIT: 'submit',
    PAGE_LOADER: 'page-loader',
    SITE_NAME: 'site-name',
    SURVEY_NAME: 'survey-name',
    RESUBMIT: 'resubmit',
    FORM_RESUBMIT_RECAPTCHA: 'form-resubmit-recaptcha'
};

const CLASSES = {
    HIDE: 'hide',
    DISABLED: 'disabled',
    QUESTION_SECTION: 'question__section',
    QUESTION_BLOCK: 'question__block',
    QUIZ_CONTAINER: 'quiz-container',
    QUIZ_CONTAINER__FORM: 'quiz-container__form',
    PREV_QUESTION: 'prev_question',
    NEXT_QUESTION: 'next_question',
    ERROR_CONTAINER: 'question__error-container',
    QUESTION__SUBMIT_BUTTON_CONTAINER: 'question__submit-button-container',
    SUCCESS__MESSAGE_CONTAINER: 'success__message-container',
    FAILURE__MESSAGE_CONTAINER: 'failure__message-container',
    OVERLAY_HIDDEN: 'overlay--hidden',
    SUCCESS_BUTTON_CONTAINER: 'success__button-container',
    FORM_ERROR_FIELD_HIDDEN: 'contact-form-data__error-field--hidden',
    FORM_DATA_SUCCESS: 'contact-form-data__control--success',
    FORM_DATA_ERROR: 'contact-form-data__control--error'

};

const ELEM = {
    INPUT: 'input',
    TEXTAREA: 'textarea'
};

const CONSTANT = {
    recaptchaEmptyError: 'Please confirm you are not a robot.',
    G_RECAPTCHA_RESPONSE: 'g-recaptcha-response',
    questionGroupText: 'Thank you for answering the questions.'

};

const API = {
    PRE_ASSESSMENT: '/bin/microsites/quiz/custom-microsites-survey',
};

export default class QuizComponentMicrosites {
    constructor(element) {
        this.element = element;
        this.quizContainerRecaptcha = null; // Object to store quiz component reCAPTCHA element
        this.formResubmitRecaptcha = null;  // Object to store form resubmit reCAPTCHA element
        this.formResubmitRecaptchaContainer = null;
        this.assessment = null;
        this.inputs = null;
        this.questionCount = 0;
        this.resubmitButton = null;
        this.setFalse = false;
        this.init();
    }

    init() {
        this.loadModules();
        this.cacheDOMElement();
        this.lastNextButton.innerHTML = 'Done';
        this.lastNextButton.setAttribute('isLast', true);
        this.recaptchaDisplay(this.quizContainerRecaptchaContainer, 'questionContainer');
        this.attachEvents();
        this.hideQuestions();
        this.displayQuestions();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.RangeSlider = new RangeSlider(this.element);
        this.ContactUsUtil = new ContactUsUtil(this.element);
    }

    cacheDOMElement() {
        this.questionContainer = document.querySelector(`.${CLASSES.QUIZ_CONTAINER}`);
        this.questionContainerForm = document.querySelector(`.${CLASSES.QUIZ_CONTAINER__FORM}`);
        this.questionSection = document.querySelector(`.${CLASSES.QUESTION_SECTION}`);
        this.questionBlock = document.querySelectorAll(`.${CLASSES.QUESTION_BLOCK}`);
        this.quizContainerRecaptchaContainer = document.querySelector(`#${ID.QUIZ_CONTAINER__FORM_RECAPTCHA}`);
        this.questionSubmitButtonContainer = document.querySelector(`.${CLASSES.QUESTION__SUBMIT_BUTTON_CONTAINER}`);
        this.submitButton = document.getElementById(`${ID.SUBMIT}`);
        this.successMessageContainer = document.querySelector(`.${CLASSES.SUCCESS__MESSAGE_CONTAINER}`);
        this.failureMessageContainer = document.querySelector(`.${CLASSES.FAILURE__MESSAGE_CONTAINER}`);
        this.pageLoader = document.getElementById(`${ID.PAGE_LOADER}`);
        this.radioAll = document.querySelectorAll('input[type=radio]');
        this.checkboxAll = document.querySelectorAll('input[type=checkbox]');
        this.textareaAll = document.getElementsByTagName('textarea');
        this.siteName = document.getElementById(`${ID.SITE_NAME}`);
        this.surveyName = document.getElementById(`${ID.SURVEY_NAME}`);
        this.questionPhraseContainer = this.questionContainerForm
                                        .parentElement.previousElementSibling;
        this.questionGroupElems = document.querySelectorAll('.question-group');
        this.lastNextButton = this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question');
    }

    displayQuestions() {
        const data = {
            siteName: `${this.siteName.value}`,
            surveyName: `${this.surveyName.value}`,
            userResponse: []
        };
        this.assessment = data;
        this.showQuestions();
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.PREV_QUESTION}`)) {
                const answers = this.questionValue(e.target).answerArr;
                let groupId = '';
                let qid = '';
                let questionText = '';

                e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = '';

                if (answers.length) {
                    groupId = parseInt(e.target.parentElement.parentElement.parentElement.parentElement.querySelector('[data-groupId]').value, 10);
                    qid = parseInt(e.target.parentElement.parentElement.getAttribute('data-id'), 10);
                    questionText = document.getElementsByClassName('question__label')[qid - 1].innerText;

                    if (e.target.parentElement.parentElement.parentElement
                        .previousElementSibling.tagName === 'DIV') {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        e.target.parentElement.parentElement.parentElement.previousElementSibling.children[0].classList.remove(`${CLASSES.HIDE}`);
                    } else if (e.target.parentElement.parentElement.parentElement.parentElement
                        .previousElementSibling.lastElementChild.children[0]) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        e.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].classList.remove(`${CLASSES.HIDE}`);
                    }

                    this.updateResponse(answers, groupId, qid, questionText);
                    this.showSubmit(e.target.getAttribute('isLast'));
                } else {
                    e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
                }
            } else if (e.target.matches(`.${CLASSES.NEXT_QUESTION}`)) {
                const answers = this.questionValue(e.target).answerArr;
                let groupId = '';
                let qid = '';
                let questionText = '';

                e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = '';

                if (answers.length) {
                    groupId = parseInt(e.target.parentElement.parentElement.parentElement.parentElement.querySelector('[data-groupId]').value, 10);
                    qid = parseInt(e.target.parentElement.parentElement.getAttribute('data-id'), 10);
                    questionText = document.getElementsByClassName('question__label')[qid - 1].innerText;

                    if (e.target.parentElement.parentElement.parentElement
                                .nextElementSibling) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        e.target.parentElement.parentElement.parentElement.nextElementSibling.children[0].classList.remove(`${CLASSES.HIDE}`);
                    } else if (e.target.parentElement.parentElement.parentElement.parentElement
                        .nextElementSibling.children[2] &&
                        e.target.parentElement.parentElement.parentElement
                        .parentElement.nextElementSibling.children[2].children[0]) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        e.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling.children[2].children[0].classList.remove(`${CLASSES.HIDE}`);
                    }

                    this.updateResponse(answers, groupId, qid, questionText);
                    this.showSubmit(e.target.getAttribute('isLast'));
                } else {
                    e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
                }
            }
        });

        this.submitButton.addEventListener(EVENTS.CLICK, (e) => {
            const answers = this.questionValue(e.target, 'submit').answerArr;
            let questionText = '';

            if (answers.length) {
                const groupId = parseInt(document.querySelector('[data-groupcount]').value, 10);
                const qid = parseInt(document.querySelector(`[data-groupid='${groupId}']`).previousElementSibling.value, 10);
                questionText = document.getElementsByClassName('question__label')[qid - 1].innerText;

                this.updateResponse(answers, groupId, qid, questionText);
                e.target.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].classList.add(`${CLASSES.HIDE}`);
                this.questionSubmitButtonContainer.classList.add(`${CLASSES.HIDE}`);
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);

                this.makeAjaxCall(this.quizContainerRecaptcha);
            } else {
                e.target.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
            }
        });

        window.addEventListener(EVENTS.BEFORE_UNLOAD, (e) => {
            // Cancel the event as stated by the standard.
            e.preventDefault();
            // Chrome requires returnValue to be set.
            e.returnValue = '';
        });

        [].forEach.call(this.radioAll, (radio) => {
            radio.addEventListener(EVENTS.CLICK, (e) => {
                e.target.parentElement.parentElement.nextElementSibling.innerHTML = '';
            });
        });

        [].forEach.call(this.checkboxAll, (checkbox) => {
            checkbox.addEventListener(EVENTS.CLICK, (e) => {
                e.target.parentElement.parentElement.nextElementSibling.innerHTML = '';
                this.updateCheckboxOptions(e.target);
            });
        });

        // textarea component is not compulsory to  fill

//        [].forEach.call(this.textareaAll, (textarea) => {
//            textarea.addEventListener(EVENTS.INPUT, (e) => {
//                e.target.parentElement.parentElement.nextElementSibling.innerHTML = '';
//            });
//        });
    }

    updateCheckboxOptions(selectedOption) {
        const checkboxOptions = selectedOption.parentElement.parentElement.querySelectorAll("input[type='checkbox']");
        if (selectedOption.value === '') {
            [].forEach.call(checkboxOptions, (option) => {
                if (option !== selectedOption) {
                    option.checked = this.setFalse;
                }
            });
        } else {
            const noneOfTheseField = selectedOption.parentElement.parentElement.querySelector("input[type='checkbox'][name='noneOfThese']");
            noneOfTheseField.checked = this.setFalse;
        }
    }

    makeAjaxCall(recaptchaElement) {
        let url = '';
        const siteName = this.siteName.value;
        const api = API.PRE_ASSESSMENT;
        if (recaptchaElement) {
            url = `${api}?siteName=${siteName}`;
        }
        this.Ajax.ajaxMultipartPostFn(url, JSON.stringify(this.assessment), (success) => {
            const data = JSON.parse(success);
            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
//            this.questionPhraseContainer.classList.add(`${CLASSES.HIDE}`);

            if (data === true) {
                this.successMessageContainer.classList.remove(`${CLASSES.HIDE}`);
                this.successMessageContainer.querySelector(`.${CLASSES.SUCCESS_BUTTON_CONTAINER}`).classList.add(CLASSES.HIDE);
            } else if (data === false) {
                this.failureMessageContainer.classList.remove(`${CLASSES.HIDE}`);

                if (!this.successMessageContainer.classList.contains(`${CLASSES.HIDE}`)) {
                    this.successMessageContainer.classList.add(`${CLASSES.HIDE}`);
                }
            } else {
                this.failureMessageContainer.classList.remove(`${CLASSES.HIDE}`);
                this.failureMessageContainer.innerHTML = `<div>
                    <p><b>An Error Occured : Internal Server Error</b></p>
                    <div id="form-resubmit-recaptcha-container" class="quiz-container-form__input-group quiz-container__recaptcha-container">
                        <div id="form-resubmit-recaptcha" class="quiz-container__recaptcha"></div>
                    </div>
                    <div>
                        <button type="submit" id="resubmit" class="button button_primary submit" value="Resubmit">Resubmit</button>
                    </div>
                </div>`;
                this.renderResubmitRecaptcha();
                this.addResubmitEventListener();
            }
        });
    }

    renderResubmitRecaptcha() {
        this.formResubmitRecaptchaContainer = document.querySelector(`#${ID.FORM_RESUBMIT_RECAPTCHA}`);
        this.recaptchaDisplay(this.formResubmitRecaptchaContainer);
    }

    addResubmitEventListener() {
        this.resubmitButton = this.failureMessageContainer.querySelector(`#${ID.RESUBMIT}`);
        this.resubmitButton.addEventListener(EVENTS.CLICK, () => {
            this.makeAjaxCall(this.formResubmitRecaptcha);
        });
    }

    questionValue(target, button) {
        const answerArr = [];
        if (!button) {
            this.inputs = target.parentElement.parentElement.querySelectorAll(`${ELEM.INPUT}`);
            this.textareas = target.parentElement.previousElementSibling.previousElementSibling.firstElementChild.querySelectorAll(`${ELEM.TEXTAREA}`);
        } else {
            this.inputs = target.parentElement.parentElement.previousElementSibling.lastElementChild.querySelectorAll(`${ELEM.INPUT}`);
            this.textareas = target.parentElement.parentElement.previousElementSibling.lastElementChild.querySelectorAll(`${ELEM.TEXTAREA}`);
        }

        if (this.textareas.length > 0) {
            this.textareas.forEach((textarea) => {
                const textValue = textarea.value;
                answerArr.push(textValue);
            });
        }

        if (this.inputs.length > 1) {
            this.inputs.forEach((input) => {
                if (input.checked === true) {
                    answerArr.push(input.value);
                }
            });
        } else if (this.inputs.length === 1) {
            const rangeValue = this.inputs[0].value;
            answerArr.push(rangeValue);
        }
        return {
            answerArr
        };
    }

    showQuestions() {
        this.questionContainerForm.classList.add('hide');
        this.questionContainerForm.nextElementSibling.querySelector(`.${CLASSES.QUESTION_BLOCK}`).classList.remove('hide');
    }

    hideQuestions() {
        [].forEach.call(this.questionBlock, (question) => {
            question.classList.add(`${CLASSES.HIDE}`);
        });
    }

    recaptchaDisplay(recaptchaContainer) {
        if (window.microsites.applicationProperties.isRecaptchaDisabled) {
            recaptchaContainer.parentElement.classList.add(`${CLASSES.HIDE}`);
            recaptchaContainer.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
        } else {
            this.createRecaptcha(recaptchaContainer);
            recaptchaContainer.parentElement.nextElementSibling.querySelector("button[type='submit']").classList.add(`${CLASSES.DISABLED}`);
        }
    }

    /**
     * @method createRecaptcha
     * @description Instantiates ReCAPTCHA field and renders it
     */
    createRecaptcha(recaptchaContainer) {
        if (recaptchaContainer.getAttribute('id') === ID.FORM_RESUBMIT_RECAPTCHA) {
            this.formResubmitRecaptcha = new ReCAPTCHA({
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess.bind(this, 'resubmit')
            });
            renderer.insert(this.formResubmitRecaptcha.render(), recaptchaContainer);
        } else if (recaptchaContainer.getAttribute('id') === ID.QUIZ_CONTAINER__FORM_RECAPTCHA) {
            this.quizContainerRecaptcha = new ReCAPTCHA({
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess.bind(this, 'submit')
            });
            renderer.insert(this.quizContainerRecaptcha.render(), recaptchaContainer);
        }
    }

    onSuccess(recaptchaType) {
        return new Promise((resolve) => {
            if (recaptchaType === 'submit') {
                document.getElementById(`${ID.SUBMIT}`).classList.remove(`${CLASSES.DISABLED}`);
            } else if (recaptchaType === 'resubmit') {
                this.resubmitButton.classList.remove(`${CLASSES.DISABLED}`);
            }
            resolve();
        });
    }

    updateResponse(answers, groupId, qid, questionText) {
        if (!this.assessment.userResponse.length) {
            const localData = {
                qid,
                questionText,
                answers
            };
            const questionModel = {
                groupId,
                questions: []
            };
            questionModel.questions.push(localData);
            this.assessment.userResponse.push(questionModel);
        } else {
            const localData = {
                qid,
                questionText,
                answers
            };
            let flag = false;

            [].forEach.call(this.assessment.userResponse, (modal) => {
                if (modal.groupId === groupId) {
                    flag = true;
                    modal.questions[qid - 1] = localData;
                }
            });

            if (flag === false) {
                const questionModel = {
                    groupId,
                    questions: []
                };

                questionModel.questions.push(localData);
                this.assessment.userResponse.push(questionModel);
            }
        }
    }

    showSubmit(isLast) {
        if (isLast) {
            this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question').classList.add('disabled');
            this.questionSubmitButtonContainer.classList.remove(`${CLASSES.HIDE}`);
        } else {
            this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question').classList.remove('disabled');

            if (!this.questionSubmitButtonContainer.classList.contains('hide')) {
                this.questionSubmitButtonContainer.classList.add(`${CLASSES.HIDE}`);
            }
        }
    }
}
