import FilterByCategory from './FilterByCategory';

const ID = {
    SITE_NAME: 'site-name'
};

export default class LatestNews {
    constructor(element) {
        this.element = element;
        this.init();
    }
    init() {
        this.setUrl();
    }

    setUrl() {
        const siteName = document.querySelector(`#${ID.SITE_NAME}`).value;
        const url = `/bin/microsites/collision-centers/getNews?siteName=${siteName}`;
        this.loadModules();
        this.FilterByCategory.setUrl(url, 'getNews');
    }

    loadModules() {
        this.FilterByCategory = new FilterByCategory(this.element);
    }
}
