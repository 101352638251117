// import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import { screen } from 'utils';
import DogVehicleClassesContainerTemplate from 'templates/dog-vehicle-classes-container';

const CLASSES = {
    VEHICLE_CLASSES: 'vehicle-classes'
};

export default class DogVehicleClassesContainer {
    constructor(element) {
        this.element = element;
        this.country = window.microsites.pageData.country;
        this.language = window.microsites.pageData.language;
        this.init();
    }

    init() {
        this.loadModules();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.url = `/bin/microsites/editorial/dog/vehicle/classes?country=${this.country}&lang=${this.language}`;
        this.loadJSON();
    }

    loadJSON() {
        this.Ajax.readTextFile(this.url, (data) => {
            if (data) {
                // Success!
                const jsonData = Object.entries(JSON.parse(data));
                document.querySelector(`.${CLASSES.VEHICLE_CLASSES}`).innerHTML = '';
                [].forEach.call(jsonData, (item) => {
                    if (screen.getState().small) {
                        item[1].imagePath = item[1].imageSmall;
                    }
                    document.querySelector(`.${CLASSES.VEHICLE_CLASSES}`).innerHTML += DogVehicleClassesContainerTemplate(item);
                });
            } else {
                console.log('Error! ');
            }
        });
    }
}
