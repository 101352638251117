import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import UISCreateFormUtil from 'modules/UISCreateFormUtil';

const CLASS = {
    CHECK_FORM_CONTROL: 'check-form__control',
    CHECK_FORM_DATA_CONTROL: 'check-form__data-control',
    CONTACT_FORM_PDF_DATA: 'contact-form-pdf-data',
    CHECK_FORM_SAMPLE: 'check-form__sample',
    CHECK_FORM_TEXTAREA: 'check-form__textarea',
    CHECK_FORM_CONTROL_ERROR: 'check-form__control--error',
    CHECK_FORM_CONTROL_SUCCESS: 'check-form__control--success',
    CHECK_FORM_SAMPLE_ERROR: 'check-form__sample--error',
    CHECK_FORM_SAMPLE_SUCCESS: 'check-form__sample--success',
    CHECK_FORM_ERROR_FIELD_HIDDEN: 'check-form__error-field--hidden',
    CHECK_FORM_SPECIFICATION: 'check-form__specification',
};

const ID = {
    VIEWEDITBUTTON: 'edit-form',
    UPDATEBUTTON: 'UISEditFormUpdate',
    FILE_BUTTON: 'Submit-fileID',
    GO_TO_REQUEST: 'goToRequest',
    SUB_FILE_BUTTON: 'SubSubmit-fileID',
    TOPIC: 'topicOfUis',
    PROJECTED_SALES: 'projected-Sales-Numbers',
    VEHICLE_TYPE: 'vehicle-Type',
    UPFIT_TYPE: 'upfit-Type',
    GVWR: 'gvwr',
    FILE_ID: 'CreateForm-fileID',
    SUB_FILE_ID: 'CreateFormSub-fileID',
    SAMPLE_VIN: 'sample-Vin',
    MODIFICATION_OVERVIEW: 'modification-overview',
    SPECIFICATIONS: 'specifications',
    MODIFICATION_BASIC_VEHICLE: 'modifications-basic-vehicle',
    MODIFICATION_BODY_SHELL: 'modifications-body-shell',
    MODIFICATION_ENGINE_PERIFERALS: 'modifications-engine-peripherals-drivetrain',
    MODIFICATION_ADDITIONAL_EQUIPMENT: 'modifications-additional-equipment',
    MODIFICATION_ATTACHMENTS: 'modifications-attachments',
    MODIFICATION_INTERIOR: 'modifications-interior',
    GENERAL_UPLOADS: 'general-uploads',
    DISPLAY_RESULT: 'mblease-listing',
    NAME: 'name',
    COMPANY: 'company',
    POSITION: 'position',
    EMAIL: 'email-address',
    PHONE_NUMBER: 'phone-number',
    DATE: 'date',
    GENERAL_INFO: 'general-info',
    REMOVE_FILE: 'Remove',
    SUBREMOVE_FILE: 'AttachmentsRemove',
};


let changeFormStatus = false;
let entitlementData = '';
let successResponse = '';

export default class UISEditForm {
    constructor(element) {
        this.element = element;
        this.formData = {};
        this.data = new FormData();
        this.init();
    }

    init() {
        this.loadModules();
        this.disabledWarning();
        this.attachEvents();
        this.fileApi = '/bin/microsites/uis/file-download';
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'upfitter';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.generalInfo = document.getElementById(`${ID.GENERAL_INFO}`);
        this.modification_overview = document.getElementById(`${ID.MODIFICATION_OVERVIEW}`);
        this.specifications = document.getElementById(`${ID.SPECIFICATIONS}`);
        this.modificationBasicVehicle = document.getElementById(`${ID.MODIFICATION_BASIC_VEHICLE}`);
        this.modificationBodyShell = document.getElementById(`${ID.MODIFICATION_BODY_SHELL}`);
        this.modificationEnginePeriferals = document.getElementById(`${ID.MODIFICATION_ENGINE_PERIFERALS}`);
        this.modificationsAttachments = document.getElementById(`${ID.MODIFICATION_ATTACHMENTS}`);
        this.modificationsAdditionalEquipment = document.getElementById(`${ID.MODIFICATION_ADDITIONAL_EQUIPMENT}`);
        this.modificationsInterior = document.getElementById(`${ID.MODIFICATION_INTERIOR}`);
        this.generalUploads = document.getElementById(`${ID.GENERAL_UPLOADS}`);

        this.UISCreateFormUtil = new UISCreateFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }


    ajaxcall() {
        this.position = new URLSearchParams(window.location.search).get('ref');
        const url = '/bin/microsites/uis-form';
        const option = 'display';
        const data = this.position;
        const filedata = {
            option,
            data
        };

        this.Ajax.ajaxPostFn(url, filedata, (success) => {
            successResponse = JSON.parse(success);
            if ((JSON.parse(success)) === 'UnAuthorised Access') {
                document.getElementById('loader').style.display = 'none';
                document.getElementById('warning-component').style.display = 'none';
                document.getElementById('UIS_form').style.display = 'none';
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('richtext-cmtBoxContainer').style.display = 'none';
                document.getElementById('displayData').style.display = 'none';
            } else {
                this.formData = JSON.parse(successResponse);
                document.getElementById('loader').style.display = 'none';
                document.getElementById('UIS_form').style.display = 'block';
                document.getElementById('UIS_form').style.width = '100%';

                this.display();
            }
        });
    }

    disabledWarning() {
        console.log(this);
        const siteName = 'upfitter';
        const uisentitlement = '/bin/microsites/uis-entitlement-label';
        this.Ajax.ajaxPostFn(uisentitlement, siteName, (success) => {
            entitlementData = JSON.parse(success);
            this.ajaxcall();
        });
    }

    display() {
        this.position = new URLSearchParams(window.location.search).get('ref');
        const data = this.position;
        const param = new URLSearchParams(window.location.search);
        const generalInfo = this.formData.generalInfo;
        const modificationOverview = this.formData.modificationOverview;
        const specifications = this.formData.specifications;
        const modificationsInterior = this.formData.modificationsInterior;
        const interiorfileupload = this.formData.interiorFiles;
        if (interiorfileupload !== undefined) {
            for (let i = 0; i < interiorfileupload.length; i += 1) {
                document.getElementById('interioruploadingfiles').innerText += 
                `<a href ="${this.fileApi}?fileName=${interiorfileupload[i]}&uisId=${data}">${interiorfileupload[i].substr(interiorfileupload[i].indexOf('_') + 1)}</a><br>`;
            }
        }
        const modificationBodyShell = this.formData.modificationBodyShell;
        const modificationEnginePeriferals = this.formData.modificationEnginePeriferals;
        const modificationBasicVehicle = this.formData.modificationBasicVehicle;
        const modificationsAttachments = this.formData.modificationsAttachments;
        const modificationsAdditionalEquipment = this.formData.modificationsAdditionalEquipment;
        const uploadfile = this.formData.generalUploadFiles;
        if (uploadfile !== undefined) {
            for (let i = 0; i < uploadfile.length; i += 1) {
                document.getElementById('uploadingfiles').innerText += `<a href ="${this.fileApi}?fileName=${uploadfile[i]}&uisId=${data}">${uploadfile[i].substr(uploadfile[i].indexOf('_') + 1)}</a><br>`;
            }
        }
        document.getElementById('gvwr').value = modificationOverview.gvwr;
        document.getElementById('upfit-Type').value = modificationOverview.upfitType;
        if (modificationOverview.sampleVin !== '') {
            document.getElementById('chkYes').checked = 'true';
            document.getElementById('dvtext').style.display = 'block';
            document.getElementById('sample-Vin').value = modificationOverview.sampleVin;
        } else {
            document.getElementById('bCheck').checked = 'true';
            document.getElementById('dvtext').style.display = 'none';
        }
        document.getElementById('company').value = generalInfo.company;
        document.getElementById('name').value = generalInfo.name;
        document.getElementById('position').value = generalInfo.position;
        document.getElementById('email-address').value = generalInfo.emailId;
        document.getElementById('phone-number').value = generalInfo.phoneNumber;
        document.getElementById('date').value = generalInfo.date;
        document.getElementById('topicOfUis').value = modificationOverview.topicOfUis;
        document.getElementById('projected-Sales-Numbers').value = modificationOverview.projectedSalesNumbers;
        document.getElementById('vehicle-Type').value = modificationOverview.vehicleType;
        document.getElementById('finalcheck').checked = 'true';

        if (param.has('mode')) {
            console.log('view if called');
            document.getElementById('gvwr').setAttribute('disabled', 'disabled');
            document.getElementById('upfit-Type').setAttribute('disabled', 'disabled');
            document.getElementById('chkYes').setAttribute('disabled', 'disabled');
            document.getElementById('bCheck').setAttribute('disabled', 'disabled');
            document.getElementById('sample-Vin').setAttribute('disabled', 'disabled');
            document.getElementById('company').setAttribute('disabled', 'disabled');
            document.getElementById('vehicle-Type').setAttribute('disabled', 'disabled');
            document.getElementById('projected-Sales-Numbers').setAttribute('disabled', 'disabled');
            document.getElementById('topicOfUis').setAttribute('disabled', 'disabled');
            document.getElementById('phone-number').setAttribute('disabled', 'disabled');
            document.getElementById('email-address').setAttribute('disabled', 'disabled');
            document.getElementById('position').setAttribute('disabled', 'disabled');
            document.getElementById('name').setAttribute('disabled', 'disabled');
            document.getElementById('date').setAttribute('disabled', 'disabled');
            document.getElementById('finalcheck').setAttribute('disabled', 'disabled');
            document.getElementById('Submit-fileID').setAttribute('disabled', 'disabled');
            const filebutton = document.getElementById('Submit-fileID');
            filebutton.innerHTML = `<button class="uis-buttons__update-button check-form__fileButton1"> Add file</button>
              `;
            document.getElementById('SubSubmit-fileID').setAttribute('disabled', 'disabled');
            const interiorfilebutton = document.getElementById('SubSubmit-fileID');
            interiorfilebutton.innerHTML = `<button class="uis-buttons__update-button check-form__fileButton2"> Add file</button>
              `;

            document.getElementById('UISEditFormUpdate').setAttribute('disabled', 'disabled');
            const updateButton = document.getElementById('UISEditFormUpdate');
            updateButton.innerHTML = `<button class="uis-buttons__update-button">Update UIS Request</button>
              `;


            if (specifications !== undefined) {
                Object.keys(specifications).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="specificationsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = specifications[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="specificationsCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }
            if (modificationsInterior !== undefined) {
                Object.keys(modificationsInterior).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = modificationsInterior[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }
            if (modificationBodyShell !== undefined) {
                Object.keys(modificationBodyShell).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = modificationBodyShell[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }

            if (modificationEnginePeriferals !== undefined) {
                Object.keys(modificationEnginePeriferals).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.disabled = 'true';
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = modificationEnginePeriferals[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }
            if (modificationBasicVehicle !== undefined) {
                Object.keys(modificationBasicVehicle).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = modificationBasicVehicle[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }
            if (modificationsAttachments !== undefined) {
                Object.keys(modificationsAttachments).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        input.disabled = 'true';
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                   .firstElementChild.value = modificationsAttachments[key];
                            input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                        }
                    });
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }

            if (modificationsAdditionalEquipment !== undefined) {
                Object.keys(modificationsAdditionalEquipment).forEach((key) => {
                    const keyValue = parseInt(key, 10);
                    const checkboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]');
                    const modificationValue = modificationsAdditionalEquipment[keyValue]
                    .modificationsParentTextArea;
                    [].forEach.call(checkboxes, (input) => {
                        Object.keys(modificationValue).forEach((keyString) => {
                            input.disabled = 'true';
                            if (input.value === keyString) {
                                input.checked = 'true';
                                input.labels[0].nextElementSibling.style.display = 'block';
                                input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationValue[keyString];
                                input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                            }
                        });
                    });
                    const modificationNestedValue = modificationsAdditionalEquipment[keyValue]
                    .modificationsChildTextArea;
                    if (modificationNestedValue !== undefined) {
                        const nestedcheckboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentChildCheckboxData"]');
                        [].forEach.call(nestedcheckboxes, (input) => {
                            Object.keys(modificationNestedValue).forEach((keyString) => {
                                input.disabled = 'true';
                                if (input.value === keyString) {
                                    input.checked = 'true';
                                    input.labels[0].nextElementSibling.firstElementChild.style.display = 'block';
                                    input.labels[0].nextElementSibling
                            .firstElementChild.value = modificationNestedValue[keyString];
                                    input.labels[0].nextElementSibling.firstElementChild.disabled = 'true';
                                }
                            });
                        });
                    } else {
                        const nestedcheckboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentChildCheckboxData"]');
                        [].forEach.call(nestedcheckboxes, (input) => {
                            input.disabled = 'true';
                        });
                    }
                });
            } else {
                const checkboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]');
                [].forEach.call(checkboxes, (input) => {
                    input.disabled = 'true';
                });
            }
        } else {
            console.log('edit if called');
            if (entitlementData === 'EXPERTUPFITTER' && successResponse !== 'UnAuthorised Access') {
                document.getElementById('warning-component').style.display = 'block';
                document.getElementById('reviewed').style.display = 'none';
                document.getElementById('CancelButton').style.display = 'none';
                document.getElementById('CompleteReviewButton').style.display = 'none';
                document.getElementById('DenyButton').style.display = 'none';
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('displayData').style.display = 'none';
                document.getElementById('richtext-cmtBoxContainer').style.display = 'none';
            } else if ((entitlementData === 'HOMBENGADMIN') || (entitlementData === 'HOMBPRGADMIN') || (entitlementData === 'EXPERTUPFITTER' && successResponse === 'UnAuthorised Access')) {
                document.getElementById('warning-component').style.display = 'none';
                document.getElementById('reviewed').style.display = 'none';
                document.getElementById('CancelButton').style.display = 'none';
                document.getElementById('CompleteReviewButton').style.display = 'none';
                document.getElementById('DenyButton').style.display = 'none';
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('displayData').style.display = 'none';
                document.getElementById('richtext-cmtBoxContainer').style.display = 'none';
            }
            document.getElementById('uisButtonComponent').style.display = 'none';
            document.getElementById('gvwr').removeAttribute('disabled');
            document.getElementById('upfit-Type').removeAttribute('disabled');
            document.getElementById('chkYes').removeAttribute('disabled');
            document.getElementById('bCheck').removeAttribute('disabled');
            document.getElementById('sample-Vin').removeAttribute('disabled');
            document.getElementById('company').removeAttribute('disabled');
            document.getElementById('vehicle-Type').removeAttribute('disabled');
            document.getElementById('projected-Sales-Numbers').removeAttribute('disabled');
            document.getElementById('topicOfUis').removeAttribute('disabled');
            document.getElementById('phone-number').removeAttribute('disabled');
            document.getElementById('email-address').removeAttribute('disabled');
            document.getElementById('position').removeAttribute('disabled');
            document.getElementById('name').removeAttribute('disabled');
            document.getElementById('date').removeAttribute('disabled');
            document.getElementById('finalcheck').removeAttribute('disabled');
            document.getElementById('Submit-fileID').removeAttribute('disabled');
            const filebutton = document.getElementById('Submit-fileID');
            filebutton.innerHTML = `
            <button type="button" id="Submit-fileID" class="button button_primary check-form__fileButton1"> Add file</button>`;
            document.getElementById('SubSubmit-fileID').removeAttribute('disabled');
            const interiorfilebutton = document.getElementById('SubSubmit-fileID');
            interiorfilebutton.innerHTML = `
            <button type="button" id="SubSubmit-fileID" class="button button_primary check-form__fileButton2"> Add file</button>`;
            document.getElementById('UISEditFormUpdate').removeAttribute('disabled');
            const updateButton = document.getElementById('UISEditFormUpdate');
            updateButton.innerHTML = `
            <button type="submit" id="UISEditFormUpdate" value="1" class="button button_primary">Update UIS Request </button>`;


            if (specifications !== undefined) {
                Object.keys(specifications).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="specificationsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.removeAttribute('disabled');
                            input.labels[0].nextElementSibling
                            .firstElementChild.removeAttribute('disabled');
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = specifications[key];
                        }
                    });
                });
            }

            if (modificationsInterior !== undefined) {
                Object.keys(modificationsInterior).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]');

                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationsInterior[key];
                        }
                    });
                });
            }
            if (modificationBodyShell !== undefined) {
                Object.keys(modificationBodyShell).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationBodyShell[key];
                        }
                    });
                });
            }

            if (modificationEnginePeriferals !== undefined) {
                Object.keys(modificationEnginePeriferals).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationEnginePeriferals[key];
                        }
                    });
                });
            }
            if (modificationBasicVehicle !== undefined) {
                Object.keys(modificationBasicVehicle).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationBasicVehicle[key];
                        }
                    });
                });
            }
            if (modificationsAttachments !== undefined) {
                Object.keys(modificationsAttachments).forEach((key) => {
                    const checkboxes = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]');
                    [].forEach.call(checkboxes, (input) => {
                        if (input.value === key) {
                            input.checked = 'true';
                            input.labels[0].nextElementSibling.style.display = 'block';
                            input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationsAttachments[key];
                        }
                    });
                });
            }
            if (modificationsAdditionalEquipment !== undefined) {
                Object.keys(modificationsAdditionalEquipment).forEach((key) => {
                    const keyValue = parseInt(key, 10);
                    const checkboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]');
                    const modificationValue = modificationsAdditionalEquipment[keyValue]
                    .modificationsParentTextArea;
                    [].forEach.call(checkboxes, (input) => {
                        Object.keys(modificationValue).forEach((keyString) => {
                            if (input.value === keyString) {
                                input.checked = 'true';
                                input.labels[0].nextElementSibling.style.display = 'block';
                                input.labels[0].nextElementSibling
                        .firstElementChild.value = modificationValue[keyString];
                            }
                        });
                    });
                    const modificationNestedValue = modificationsAdditionalEquipment[keyValue]
                    .modificationsChildTextArea;
                    if (modificationNestedValue !== undefined) {
                        const nestedcheckboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentChildCheckboxData"]');
                        [].forEach.call(nestedcheckboxes, (input) => {
                            Object.keys(modificationNestedValue).forEach((keyString) => {
                                if (input.value === keyString) {
                                    input.checked = 'true';
                                    input.labels[0].nextElementSibling.firstElementChild.style.display = 'block';
                                    input.labels[0].nextElementSibling
                            .firstElementChild.value = modificationNestedValue[keyString];
                                }
                            });
                        });
                    }
                });
            }
            this.loadCaptcha();
        }
    }

    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.VIEWEDITBUTTON}`)) {
                e.preventDefault();

                const currentUrl = window.location.href;
                window.location.assign(`${currentUrl.split('?')[0]}?ref=${this.position}`);
                // window.location.href = `${currentUrl.split('?')[0]}?ref=${this.position}`;
                document.getElementById('uisButtonComponent').style.display = 'none';
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('displayData').style.display = 'none';
                document.getElementById('richtext-cmtBoxContainer').style.display = 'none';
            }
        });


        this.generalInfo.addEventListener(EVENTS.INPUT, () => {
            const generalInfo = {};
            generalInfo.name = document.getElementById(`${ID.NAME}`).value;
            generalInfo.company = document.getElementById(`${ID.COMPANY}`).value;
            generalInfo.position = document.getElementById(`${ID.POSITION}`).value;
            generalInfo.emailId = document.getElementById(`${ID.EMAIL}`).value;
            generalInfo.phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
            generalInfo.date = document.getElementById(`${ID.DATE}`).value;
            this.formData.generalInfo = generalInfo;
        });

        this.modification_overview.addEventListener(EVENTS.INPUT, () => {
            const modificationOverview = {};
            modificationOverview.topicOfUis = document.getElementById(`${ID.TOPIC}`).value;
            modificationOverview.projectedSalesNumbers = document.getElementById(`${ID.PROJECTED_SALES}`).value;
            modificationOverview.vehicleType = document.getElementById(`${ID.VEHICLE_TYPE}`).value;
            modificationOverview.upfitType = document.getElementById(`${ID.UPFIT_TYPE}`).value;
            modificationOverview.sampleVin = document.getElementById(`${ID.SAMPLE_VIN}`).value;
            modificationOverview.gvwr = document.getElementById(`${ID.GVWR}`).value;
            this.formData.modificationOverview = modificationOverview;
        });

        this.specifications.addEventListener(EVENTS.INPUT, () => {
            const specifications = {};
            const checkboxes = document.querySelectorAll('input[name="specificationsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                specifications[input.value] = textArea.value;
            });
            this.formData.specifications = specifications;
        });

        this.modificationBasicVehicle.addEventListener(EVENTS.INPUT, () => {
            const modificationBasicVehicle = {};
            const checkboxes = document.querySelectorAll('input[name="modificationBasicVehicleCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationBasicVehicle[input.value] = textArea.value;
            });
            this.formData.modificationBasicVehicle = modificationBasicVehicle;
        });

        this.modificationBodyShell.addEventListener(EVENTS.INPUT, () => {
            const modificationBodyShell = {};
            const checkboxes = document.querySelectorAll('input[name="modificationBodyShellCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationBodyShell[input.value] = textArea.value;
            });
            this.formData.modificationBodyShell = modificationBodyShell;
        });

        this.modificationEnginePeriferals.addEventListener(EVENTS.INPUT, () => {
            const modificationEnginePeriferals = {};
            const checkboxes = document.querySelectorAll('input[name="modificationEnginePeriferalsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationEnginePeriferals[input.value] = textArea.value;
            });
            this.formData.modificationEnginePeriferals = modificationEnginePeriferals;
        });

        this.modificationsInterior.addEventListener(EVENTS.INPUT, () => {
            const modificationsInterior = {};
            const checkboxes = document.querySelectorAll('input[name="modificationsInteriorCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationsInterior[input.value] = textArea.value;
            });
            this.formData.modificationsInterior = modificationsInterior;
        });

        this.modificationsAdditionalEquipment.addEventListener(EVENTS.INPUT, () => {
            let modificationsParentTextArea = {};
            let modificationsChildTextArea = {};
            const modificationsAdditionalEquipment = [];
            let elementValue = '';
            const checkboxes = document.querySelectorAll('input[name="modificationsAdditionalEquipmentCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                modificationsParentTextArea = {};
                modificationsChildTextArea = {};
                const inputId = input.id;
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationsParentTextArea[input.value] = textArea.value;
                const nestedCheckBoxlabel = textArea.nextElementSibling.nextElementSibling;
                if (nestedCheckBoxlabel !== null) {
                    if (nestedCheckBoxlabel.tagName === 'LABEL') {
                        const nestedCheckbox = document.querySelectorAll('input[name="modificationsAdditionalEquipmentChildCheckboxData"]:checked');
                        [].forEach.call(nestedCheckbox, (element) => {
                            const elementId = element.id;
                            if (elementId.includes(inputId)) {
                                elementValue = element.value;
                                modificationsChildTextArea[elementValue] =
                                    element.labels[0].nextElementSibling.firstElementChild.value;
                            }
                        });
                    }
                }
                if (elementValue !== '') {
                    modificationsAdditionalEquipment.push({ modificationsParentTextArea,
                        modificationsChildTextArea });
                } else {
                    modificationsAdditionalEquipment.push({
                        modificationsParentTextArea });
                }
                elementValue = '';
            });
            this.formData.modificationsAdditionalEquipment = modificationsAdditionalEquipment;
        });

        this.modificationsAttachments.addEventListener(EVENTS.INPUT, () => {
            const modificationsAttachments = {};
            const checkboxes = document.querySelectorAll('input[name="modificationsAttachmentsCheckboxData"]:checked');
            [].forEach.call(checkboxes, (input) => {
                const textArea = input.labels[0].nextElementSibling.firstElementChild;
                modificationsAttachments[input.value] = textArea.value;
            });
            this.formData.modificationsAttachments = modificationsAttachments;
        });


        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.CHECK_FORM_CONTROL}`) || el.target.matches(`.${CLASS.CHECK_FORM_TEXTAREA}`)
                || el.target.matches(`.${CLASS.CHECK_FORM_SAMPLE}`) || el.target.matches(`.${CLASS.CHECK_FORM_DATA_CONTROL}`)
                || el.target.matches(`.${CLASS.CONTACT_FORM_PDF_DATA}`) || el.target.matches(`.${CLASS.CHECK_FORM_SPECIFICATION}`)) {
                changeFormStatus = true;
            }
        });


        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.UPDATEBUTTON}`)) {
                e.preventDefault();
                if (changeFormStatus === true) {
                    document.getElementById('formChanged').classList.add(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
                    const capRes = grecaptcha.getResponse(1);
                    if (this.UISCreateFormUtil.validateAllFields() && capRes.length !== 0) {
                        document.getElementById('g-recaptcha-error').innerHTML = '';
                        document.getElementById('uis-edit-form-loader').style.display = 'block';
                        document.getElementById('uis-edit-form-loader').innerHTML = '<div class="uis-dashboard__loader-submit" id="submit-loader"></div><span class="check-form__error-field">Please wait...</span>';
                        this.data.set('data', JSON.stringify(this.formData));
                        this.data.set('option', 'update');
                        this.data.set('captcha', capRes);
                        this.data.set('position', this.position);
                        const url = '/bin/microsites/uis-form';
                        grecaptcha.reset();
                        this.Ajax.ajaxMultipartPostFn(url, this.data, (success) => {
                            if (success.includes('Please verify the captcha response')) {
                                this.UISCreateFormUtil.displayPopUp('Please verify the captcha response');
                            } else {
                                success = JSON.parse(JSON.parse(success));
                                document.getElementById('uis-edit-form-loader').style.display = 'none';
                                changeFormStatus = false;
                                if (success !== 0 && success !== 'false' && success.status === 'sent') {
                                    const formElement = `<div>
                                    <h5 class="modal-title">Thank you! Your request update was successful. </h5>
                                    <h6 class="modal-title">Your request number is ${success.uisId}. </h6>
                                    </div>`;
                                    document.getElementById('goToRequest').href =
                                    `${document.getElementById('editLinkdashboard').value}`;
                                    this.UISCreateFormUtil.displayPopUp(formElement);
                                } else if (success.status === 'not sent') {
                                    const formElement = `<div>
                                     <h5 class="modal-title">Thank you! Your request update was successful. </h5>
                                     <h6 class="modal-title">Your request number is ${success.uisId}. </h6>
                                     <h6 style="font-size: 14px;color: #ef5a39d1;">We are unable to send email notification at this moment. To view your request(s) ,please navigate to dashboard</h6>
                                     </div>`;
                                    document.getElementById('goToRequest').href =
                                     `${document.getElementById('editLinkdashboard').value}`;
                                    this.UISCreateFormUtil.displayPopUp(formElement);
                                } else {
                                    document.getElementById('goToRequestButton').style.display = 'none';
                                    const formElement = `<div>
                                     <h5 class="modal-title">Form submission failed </h5></div>`;
                                    this.UISCreateFormUtil.displayPopUp(formElement);
                                }
                            }
                        });
                    } else if (capRes.length === 0) {
                        document.getElementById('g-recaptcha-error').innerHTML = '<span class="check-form__error-field">This field is required.</span>';
                        this.UISCreateFormUtil.validateAllFields();
                    }
                } else {
                    document.getElementById('formChanged').classList.remove(`${CLASS.CHECK_FORM_ERROR_FIELD_HIDDEN}`);
                }
            } else if (e.target.matches(`#${ID.FILE_BUTTON}`)) {
                document.getElementById('CreateForm-fileID').click();
            } else if (e.target.matches(`#${ID.SUB_FILE_BUTTON}`)) {
                document.getElementById('CreateFormSub-fileID').click();
            } else if (e.target && e.target.matches(`#${ID.GO_TO_REQUEST}`)) {
                e.preventDefault();
                const editUrl = `${document.getElementById('editLinkdashboard').value}`;
                window.open(editUrl);
            } else if (e.target.matches(`#${ID.REMOVE_FILE}`)) {
                const id = parseInt(e.target.getAttribute('data-ref'), 10);
                const filesId = document.getElementById('Submit-fileID');
                const totalFiles = document.getElementById('fileList').innerText;
                const fileSplit = totalFiles.split('\n');
                const totalFilesLength = fileSplit.length;
                if (totalFilesLength < 6) {
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                }
                this.fileRemoveEvent('fileList', `${ID.FILE_ID}`, id,
                    'generalUploadFiles', 'Remove');
            } else if (e.target.matches(`#${ID.SUBREMOVE_FILE}`)) {
                const id = parseInt(e.target.getAttribute('data-ref'), 10);
                const filesId = document.getElementById('SubSubmit-fileID');
                const totalFiles = document.getElementById('subfileList').innerText;
                const fileSplit = totalFiles.split('\n');
                const totalFilesLength = fileSplit.length;
                if (totalFilesLength < 6) {
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                }
                this.fileRemoveEvent('subfileList', `${ID.SUB_FILE_ID}`, id,
                    'interiorFiles', 'AttachmentsRemove');
            }
        });

        this.generalUploads.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.FILE_ID}`)) {
                e.preventDefault();
                let fileAdd = false;
                const addFileButton = document.getElementById('UISEditFormUpdate');
                const file = e.target.value;
                const files = document.getElementById(`${ID.FILE_ID}`).files.length;
                const filesId = document.getElementById(`${ID.FILE_ID}`);
                const addFiles = document.getElementById(`${ID.FILE_BUTTON}`);
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    if (ext === 'jpg' || ext === 'JPG' || ext === 'png' || ext === 'PNG' || ext === 'bmp' || ext === 'BMP' || ext === 'gif' || ext === 'GIF' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF') {
                        if (this.UISCreateFormUtil.validateFileSize()) {
                            fileAdd = true;
                            addFileButton.removeAttribute('disabled');
                            document.getElementById('errmsg').innerHTML = '';
                        } else {
                            fileAdd = false;
                        }
                    } else {
                        fileAdd = false;
                        addFileButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('errmsg').innerHTML = `${ext} format is not supported `;
                    }
                    if (fileAdd === true) {
                        this.fileAddEvent('fileList', `${ID.FILE_ID}`,
            'generalUploadFiles', 'Remove');
                    }
                }
            }
        });

        this.modificationsInterior.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.SUB_FILE_ID}`)) {
                let fileAdd = false;
                const addFileButton = document.getElementById('UISEditFormUpdate');
                const file = e.target.value;
                const files = document.getElementById(`${ID.SUB_FILE_ID}`).files.length;
                const filesId = document.getElementById(`${ID.SUB_FILE_ID}`);
                const addFiles = document.getElementById(`${ID.SUB_FILE_BUTTON}`);
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    if (ext === 'jpg' || ext === 'JPG' || ext === 'png' || ext === 'PNG' || ext === 'bmp' || ext === 'BMP' || ext === 'gif' || ext === 'GIF' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF') {
                        if (this.UISCreateFormUtil.validateSubFileSize()) {
                            fileAdd = true;
                            addFileButton.removeAttribute('disabled');
                            document.getElementById('errmsgNested').innerHTML = '';
                        } else {
                            fileAdd = false;
                        }
                    } else {
                        fileAdd = false;
                        addFileButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('errmsgNested').innerHTML = `${ext} format is not supported `;
                    }
                    if (fileAdd === true) {
                        this.fileAddEvent('subfileList', `${ID.SUB_FILE_ID}`,
                    'interiorFiles', 'AttachmentsRemove');
                    }
                }
            }
        });
    }

    fileRemoveEvent(fileListId, fileId, id, fileArrayName, removeId) {
        const dt = new DataTransfer();
        const input = document.getElementById(fileId);
        const files = this.data.getAll(fileArrayName);
        for (let i = 0; i < files.length; i += 1) {
            if (id !== i) {
                const file = files[i];
                dt.items.add(file);
            }
        }
        input.files = dt.files;
        this.data.delete(fileArrayName);
        this.fileAddEvent(fileListId, fileId, fileArrayName, removeId);
    }

    fileAddEvent(fileListId, fileId, fileArrayName, removeId) {
        const output = document.getElementById(fileListId);
        const file = document.getElementById(fileId).files;
        if (file !== undefined && file.length !== 0) {
            for (let index = 0; index < file.length; index += 1) {
                this.data.set(fileArrayName, file[index]);
            }
        }
        output.innerHTML = this.displayAttachments(
                this.data.getAll(fileArrayName), removeId);
    }

    displayAttachments(filesList, removeId) {
        let children = '';
        this.lowerSize = 1024;
        const upperSize = 1048576;
        for (let index = 0; index < filesList.length; index += 1) {
            const fileSize = filesList[index].size;
            if (fileSize >= this.lowerSize && fileSize <= upperSize) {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${filesList[index].name}</td>
                        <td class="check-form__fileSize">${fileSize / 1024}KB</td>
                        <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            } else {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${filesList[index].name}</td>
                   <td class="check-form__fileSize" id="fileSize">${fileSize / 1048576}MB</td>
                   <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            }
        }
        return `<table>${children}</table>`;
    }
}
