import { CLASSES, EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import headerDropdownTemplate from 'templates/header-drop-down';
import Header from 'modules/Header';

export default class DogHeader {
    constructor(element) {
        this.element = element;
        this.country = window.microsites.pageData.country;
        this.language = window.microsites.pageData.language;
        this.init();
    }

    init() {
        this.loadModules();
        this.cacheDomElement();
        this.attachEvents();
    }

    loadModules() {
        this.Header = new Header(this.element);
        this.Ajax = new Ajax(this.element);
    }

    cacheDomElement() {
        this.menuLinks = document.querySelectorAll(`.${CLASSES.CUSTOM_TOP_NAV__ITEM_MENU}`);
        this.hasClasses = false;
        this.classesLink = '';
        this.classesMenu = '';
        [].forEach.call(this.menuLinks, (el) => {
            const childLink = el.querySelector(`.${CLASSES.CUSTOM_TOP_NAV__LINK}`);
            if (childLink.innerHTML.toUpperCase().trim() === 'CLASSES') {
                this.classesLink = childLink;
                this.classesMenu = el;
            }
        });
    }

    attachEvents() {
        this.getVehicleClasses();
        document.addEventListener(EVENTS.CLICK, (e) => {
            // Check for Classes button(if dropdown button present) to display dropdown.
            if (e.target.matches(`.${CLASSES.CUSTOM_TOP_NAV__LINK_PARENT}`)) {
                if (this.hasClasses) {
                    e.preventDefault();
                }
                this.Header.changeSubMenuState(e.target, this.menuLinks);
            }
        });
    }

    // Function : To call API and get JSON of classes.
    getVehicleClasses() {
        const url = `/bin/microsites/editorial/dog/vehicle/classes?country=${this.country}&lang=${this.language}`;
        this.Ajax.readTextFile(url, (callback) => {
            if (callback.length > 2) {
                this.populateClasses(callback);
                this.classesLink.classList.add(`${CLASSES.CUSTOM_TOP_NAV__LINK_PARENT}`);
                this.hasClasses = true;
            } else {
                this.classesLink.classList.remove(`${CLASSES.CUSTOM_TOP_NAV__LINK_PARENT}`);
            }
        });
    }

    // Function : to populate dropdown of 'Classes' option in menu in header.
    populateClasses(data) {
        const jsonData = Object.entries(JSON.parse(data));
        let vehicleClasses = '';
        const vehicleClassesContainer = document.createElement('ul');
        vehicleClassesContainer.setAttribute('class', `${CLASSES.CUSTOM_TOP_NAV__SUB_MENU_LIST}`);
        [].forEach.call(jsonData, (item) => {
            vehicleClasses += headerDropdownTemplate(item);
        });
        vehicleClassesContainer.innerHTML = vehicleClasses;
        this.classesMenu.appendChild(vehicleClassesContainer);
    }
}
