import Ajax from 'modules/Ajax';
import { EVENTS } from 'Constants';
import DeleteRecordUtil from 'modules/DeleteRecordUtil';

const ID = {
    SUBMITBUTTON: 'displayRecordButton',
    DELETEBUTTON: 'deleteButton',
    FROM: 'from',
    TO: 'to',
    DELETE_RECORD_FROM_TABLE: 'delete-record-from-table',
    DB_TABLE_NAME: 'db-table-name',
    DYNAMO_TABLE_DATA_DISPLAY: 'dynamo-table-data-display'
};

export default class DeleteRecord {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'nsmicrosites';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.DeleteRecordUtil = new DeleteRecordUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.DeleteRecordUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const featureName = document.getElementById(`${ID.DB_TABLE_NAME}`).value;
                    const date1 = document.getElementById('from-date').value;
//                    const dateFormate1 = fromDate.split('-');
//                    const date1 = `${dateFormate1[2]}-${dateFormate1[1]}-${dateFormate1[0]}`;
//                    console.log(date1);
                    const date2 = document.getElementById('to-date').value;
//                    const dateFormate2 = toDate.split('-');
//                    const date2 = `${dateFormate2[2]}-${dateFormate2[1]}-${dateFormate2[0]}`;
//                    console.log(date2);
                    const option = 'display';
                    const url = '/bin/microsites/delete-record';
                    const formData = {
                        option,
                        date1,
                        date2,
                        featureName,
                    };

                    this.Ajax.ajaxPostFn(url, formData, (success) => {
                        console.log(success);
                        if (success !== 'false') {
                            const servletData = JSON.parse(success);
                            console.log(servletData.length);
                            if (featureName === 'DigitalShoppingExperience') {
                                this.displayDSEResult(servletData);
                            } else if (featureName === 'CacSuggestion') {
                                this.displayCACResult(servletData);
                            } else if (featureName === 'BRCHomeOffice') {
                                this.displayBRCHOResult(servletData);
                            } else if (featureName === 'MBCVin') {
                                this.displayMBCVINResult(servletData);
                            } else if (featureName === 'MBMLease') {
                                this.displayMBMLeaseResult(servletData);
                            }
                        } else {
                            this.DeleteRecordUtil.displayPopUp('Failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.DeleteRecordUtil.validateAllFields();
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.DELETEBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.DeleteRecordUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
//                    const form = document.getElementById(`${ID.DELETE_RECORD_FROM_TABLE}`);
                    const featureName = document.getElementById(`${ID.DB_TABLE_NAME}`).value;
                    const date1 = document.getElementById('from-date').value;
//                    const dateFormate1 = fromDate.split('-');
//                    const date1 = `${dateFormate1[2]}-${dateFormate1[1]}-${dateFormate1[0]}`;
//                    console.log(date1);
                    const date2 = document.getElementById('to-date').value;
//                    const dateFormate2 = toDate.split('-');
//                    const date2 = `${dateFormate2[2]}-${dateFormate2[1]}-${dateFormate2[0]}`;
//                    console.log(date2);
                    const option = 'delete';
                    const url = '/bin/microsites/delete-record';
                    const formData = {
                        option,
                        date1,
                        date2,
                        featureName,
                    };

                    this.Ajax.ajaxPostFn(url, formData, (success) => {
                        console.log(success);
                        if (success !== 'false') {
                            this.DeleteRecordUtil.displayPopUp('Data Deleted Successfully');
                            setTimeout(() => {
                                window.location.reload();
                            }, 5000);
                        } else {
                            this.DeleteRecordUtil.displayPopUp('Failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.DeleteRecordUtil.validateAllFields();
                }
            }
        });
    }

    displayDSEResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DYNAMO_TABLE_DATA_DISPLAY}`);
        let htmlBuild = '';
        if (data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                const formData = JSON.parse(data[i]);
                const date = formData.dateTime;
                const dealer = formData.dealerName;
                const dCode = formData.dealerCode;
                const phone = formData.userPhone;
                const directorName = formData.directorName_fi;
                const email = formData.userEmail;
                const name = formData.userName;
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                    <td colspan="1" class="brc-sites-report__td_padding">${date}</td>
                    <td colspan="2" class="brc-sites-report__td_padding">${email}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${dCode}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${phone}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${directorName}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${dealer}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${name}</td>
                    </tr>`;
            }
        } else {
            htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding brc-sites-report__danger">Data not available!!</td>
                </tr>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayCACResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DYNAMO_TABLE_DATA_DISPLAY}`);
        let htmlBuild = '';
        if (data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                const formData = JSON.parse(data[i]);
                const date = formData.dateTime;
                const commentId = formData.commentId;
                const comment = formData.comment;
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                        <td colspan="1" class="brc-sites-report__td_padding">${date}</td>
                        <td colspan="1" class="brc-sites-report__td_padding">${commentId}</td>
                        <td colspan="1" class="brc-sites-report__td_padding">${comment}</td>
                        </tr>`;
            }
        } else {
            htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding brc-sites-report__danger">Data not available!!</td>
                </tr>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayBRCHOResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DYNAMO_TABLE_DATA_DISPLAY}`);
        let htmlBuild = '';
        if (data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                const formData = JSON.parse(data[i]);
                const dateTime = formData.dateTime;
                const requestNumber = formData.requestNumber;
                const name = formData.name;
                const requestOverview = formData.requestOverview;
                const topic = formData.topic;
//                const department = formData.department;
//                const userId = formData.userId;
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                    <td colspan="1" class="brc-sites-report__td_padding">${dateTime}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${requestNumber}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${name}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${requestOverview}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${topic}</td>
                    </tr>`;
            }
        } else {
            htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding brc-sites-report__danger">Data not available!!</td>
                </tr>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayMBCVINResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DYNAMO_TABLE_DATA_DISPLAY}`);
        let htmlBuild = '';
        if (data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                const formData = JSON.parse(data[i]);
                const date = formData.dateTime;
                const firstName = formData.firstName;
                const lastName = formData.lastName;
                const phone = formData.phoneNumber;
                const city = formData.city;
                const vin = formData.vin;
                const userId = formData.userId;
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                    <td colspan="1" class="brc-sites-report__td_padding">${date}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${firstName}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${lastName}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${userId}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${phone}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${city}</td>
                    <td colspan="2" class="brc-sites-report__td_padding">${vin}</td>
                    </tr>`;
            }
        } else {
            htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding brc-sites-report__danger">Data not available!!</td>
                </tr>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayMBMLeaseResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DYNAMO_TABLE_DATA_DISPLAY}`);
        let htmlBuild = '';
        if (data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                const formData = JSON.parse(data[i]);
                const date = formData.creationDate;
                const mbmNumber = formData.mbmLeaseNumber;
                const action = formData.action;
                const custName = formData.custLastName;
                const vin = formData.vin;
                const Place = formData.location;
                const type = formData.type;
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                    <td colspan="1" class="brc-sites-report__td_padding">${date}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${mbmNumber}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${action}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${custName}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${Place}</td>
                    <td colspan="1" class="brc-sites-report__td_padding">${type}</td>
                    <td colspan="2" class="brc-sites-report__td_padding">${vin}</td>
                    </tr>`;
            }
        } else {
            htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding brc-sites-report__danger">Data not available!!</td>
                </tr>`;
        }
        resultDisplay.innerHTML = htmlBuild;
    }
}
