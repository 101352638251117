import Accordion from 'modules/Accordion';
import AlternateLinkListDownload from 'modules/AlternateLinkListDownload';
import FAQV2 from 'modules/FAQV2';
import FAQV4 from 'modules/FAQV4';
import Tab from 'modules/Tab';

export default class Microsites {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.loadModules();
    }

    loadModules() {
        this.Accordion = new Accordion(this.element);
        this.AlternateLinkListDownload = new AlternateLinkListDownload(this.element);
        this.FAQV2 = new FAQV2(this.element);
        this.FAQV4 = new FAQV4();
        this.Tab = new Tab(this.element);
    }
}
