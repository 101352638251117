/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Results List container
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
    <ul class="dealer-results__list">
    </ul>
`;

