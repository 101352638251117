import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import NCUFieldCastFormUtil from 'modules/NCUFieldCastFormUtil';

const ID = {
    NAME: 'ncuFieldCastForm-name',
    EMAIL: 'ncuFieldCastForm-email',
    DEPARTMENT: 'ncuFieldCastForm-department',
    NCU_TITLE: 'ncuFieldCastForm-title',
    NCU_BODY: 'ncuFieldCastForm-description',
    EXP_DATE: 'ncuFieldCastForm-expirationDate',
    ATTACHMENT: 'ncuFieldCastForm-fileID',
    SUBMITBUTTON: 'ncuFieldCastFormSubmit',
    NCU_FIELD_CAST_FORM: 'ncuFieldCastForm',
    SITE_NAME: 'site-name',
    FILE_BUTTON: 'Submit-fileID',
    REMOVE_FILE: 'Remove',
    DOTS_ERROR_MESSAGE: 'dotsErrorMessage'
};

export default class NCUFieldCastForm {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.fileData = new FormData();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = document.getElementById(`${ID.SITE_NAME}`).value;
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha-ncu');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.NCUFieldCastFormUtil = new NCUFieldCastFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const captchaResponse = grecaptcha.getResponse();
                if (this.NCUFieldCastFormUtil.validateAllFields() && captchaResponse.length !== 0) {
                    document.getElementById('ncu-loader').style.display = 'block';
                    document.getElementById('ncu-loader').innerHTML = '<div class="ncu-form__loader-submit" id="submit-loader"></div><span class="ncu-form__loader-message">Please wait...</span>';
                    document.getElementById('g-recaptcha-error-ncu').innerHTML = '';
                    const form = document.getElementById(`${ID.NCU_FIELD_CAST_FORM}`);
                    const inputs = form.elements;
                    const name = document.getElementById(`${ID.NAME}`).value;
                    const requesterEmail = document.getElementById(`${ID.EMAIL}`).value;
                    const department = document.getElementById(`${ID.DEPARTMENT}`).value;
                    const title = document.getElementById(`${ID.NCU_TITLE}`).value;
                    const body = document.getElementById(`${ID.NCU_BODY}`).value;
                    const expdate = document.getElementById(`${ID.EXP_DATE}`).value;
                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;

                    const edit = `${window.location.origin}/content/business-resource-center/ncu-submission-report.html`;
                    const url = '/bin/microsites/NCUFieldCastFormSendEmail';

                    const file = document.getElementById(`${ID.ATTACHMENT}`).files.length;

                    this.fileData.set('siteName', siteName);
                    this.fileData.set('name', name);
                    this.fileData.set('requesterEmail', requesterEmail);
                    this.fileData.set('topic', department);
                    this.fileData.set('title', title);
                    this.fileData.set('body', body);
                    this.fileData.set('expirationDate', expdate);
                    this.fileData.set('captcha', captchaResponse);
                    this.fileData.set('edit', edit);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, this.fileData, (success) => {
                        if (success === 'true') {
                            this.NCUFieldCastFormUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.NCU_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.NCU_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.NCUFieldCastFormUtil.displayPopUp('Form submission failed');
                        }
                        document.getElementById('ncu-loader').style.display = 'none';
                    });
                } else if (!this.NCUFieldCastFormUtil.validateAllFields()
                    && captchaResponse.length !== 0) {
                    document.getElementById('g-recaptcha-error-ncu').innerHTML = '';
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error-ncu').innerHTML = '<span class="check-form__error-field">This field is required.</span>';
                }
            }
        });

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FILE_BUTTON}`)) {
                document.getElementById('ncuFieldCastForm-fileID').click();
            } else if (e.target.matches(`#${ID.REMOVE_FILE}`)) {
                const id = parseInt(e.target.getAttribute('data-ref'), 10);
                const filesId = document.getElementById('Submit-fileID');
                const totalFiles = document.getElementById('fileList').innerText;
                const fileSplit = totalFiles.split('\n');
                const totalFilesLength = fileSplit.length;
                if (totalFilesLength < 6) {
                    document.getElementById('errmsg').innerHTML = '';
                    filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                }
                this.fileRemoveEvent('fileList', 'ncuFieldCastForm-fileID', id,
                    'attachments', 'Remove');
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.ATTACHMENT}`)) {
                e.preventDefault();
                let fileAdd = false;
                const addFileButton = document.getElementById('ncuFieldCastFormSubmit');
                const file = e.target.value;
                const files = document.getElementById(`${ID.ATTACHMENT}`).files.length;
                const filesId = document.getElementById(`${ID.ATTACHMENT}`);
                const addFiles = document.getElementById(`${ID.FILE_BUTTON}`);
                if (files !== 0) {
                    const ext = file.split('.').pop();
                    const dotIndex = file.lastIndexOf('.');
                    const nameBeforeExtension = file.slice(0,dotIndex);
                    const regex = /\./;

                    if (ext === 'xlsx' || ext === 'XLSX' || ext === 'doc' || ext === 'DOC' || ext === 'docx' || ext === 'DOCX' || ext === 'pdf' || ext === 'PDF') {
                        if (this.NCUFieldCastFormUtil.validateFileSize()) {
                            if(regex.test(nameBeforeExtension)) {
                                document.getElementById(`${ID.DOTS_ERROR_MESSAGE}`).style.display = "block";
                            }
                            else {
                                document.getElementById(`${ID.DOTS_ERROR_MESSAGE}`).style.display = "none";
                                fileAdd = true;
                                addFileButton.removeAttribute('disabled');
                                document.getElementById('errmsg').innerHTML = '';
                            }
                        } else {
                            fileAdd = false;
                        }
                    } else {
                        fileAdd = false;
                        addFileButton.setAttribute('disabled', 'disabled');
                        filesId.nextElementSibling.classList.add('check-form__error-field--hidden');
                        addFiles.nextElementSibling.classList.add('check-form__error-field--hidden');
                        document.getElementById('errmsg').innerHTML = `${ext} format is not supported `;
                    }
                    if (fileAdd === true) {
                        this.fileAddEvent('fileList', 'ncuFieldCastForm-fileID', 'attachments', 'Remove');
                    }
                }
            }
        });
    }

    fileAddEvent(fileListId, fileId, fileArrayName, removeId) {
        const output = document.getElementById(fileListId);
        const file = document.getElementById(fileId).files;
        if (file !== undefined && file.length !== 0) {
            for (let index = 0; index < file.length; index += 1) {
                this.fileData.append(fileArrayName, file[index]);
            }
        }
        output.innerHTML = this.displayAttachments(
           this.fileData.getAll(fileArrayName), removeId);
    }

    fileRemoveEvent(fileListId, fileId, id, fileArrayName, removeId) {
        const dt = new DataTransfer();
        const input = document.getElementById(fileId);
        const files = this.fileData.getAll(fileArrayName);
        for (let i = 0; i < files.length; i += 1) {
            if (id !== i) {
                const file = files[i];
                dt.items.add(file);
            }
        }
        input.files = dt.files;
        this.fileData.delete(fileArrayName);
        this.fileAddEvent(fileListId, fileId, fileArrayName, removeId);
    }

    displayAttachments(ncuFilesList, removeId) {
        let children = '';
        this.lowerSize = 1024;
        const upperSize = 1048576;
        for (let index = 0; index < ncuFilesList.length; index += 1) {
            const fileSize = ncuFilesList[index].size;
            if (fileSize >= this.lowerSize && fileSize <= upperSize) {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${ncuFilesList[index].name}</td>
                        <td class="check-form__fileSize">${fileSize / 1024}KB</td>
                        <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            } else {
                children += `<tr class="check-form__fileList"><td class="check-form__fileName">${ncuFilesList[index].name}</td>
                   <td class="check-form__fileSize" id="fileSize">${fileSize / 1048576}MB</td>
                   <td class="check-form__fileButton"><button class="check-form__button" id="${removeId}" data-ref="${index}">Remove</button></td></tr>`;
            }
        }
        return `<table>${children}</table>`;
    }
}
