import Ajax from 'modules/Ajax';


export default class AccessRestrict {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        // this.init();
        document.getElementById('fleet-loader').style.display = 'none';
    }


    init() {
        this.loadModules();
        this.labelCheck();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    labelCheck() {
        console.log(this);
        document.getElementById('fleet-loader').style.display = '';
        const urlServlet = '/bin/microsites/new-check-fleet-admin-access';
        const siteName = 'fleet-info';
        const accessName = document.getElementsByName('accessData');
        const accessData = {
            siteName,
        };
        for (let i = 0; i < accessName.length; i += 1) {
            if (accessName[i].getAttribute('data-sortorder') != null) {
                accessData[`${i}`] = (accessName[i].getAttribute('data-sortorder'));
            }
        }
        accessData.length = accessName.length;
        const siteHostName = window.location.hostname;
        if (!this.stringIncludes(siteHostName, 'author')) {
            this.Ajax.ajaxPostFn(urlServlet, accessData, (success) => {
                if (success !== 'false') {
                    document.getElementById('fleet-loader').style.display = 'none';
                    const servletData = JSON.parse(success);
                    const y = document.getElementsByName('formHeading');
                    const z = document.getElementsByName('fleet-tile-feature-content');
                    for (let a = 0; a < y.length; a += 1) {
                        for (let entitlement = 0;
                        entitlement < servletData.length; entitlement += 1) {
                            if (servletData[entitlement] === 'FLEETADMIN') {
                                z[a].style.display = 'block';
                            } else if (servletData[entitlement] === accessData[a]) {
                                z[a].style.display = 'block';
                            } else if (servletData[entitlement] === 'PEPANDAFFINITY' ||
                                    servletData[entitlement] === 'AFFINITY' ||
                                    servletData[entitlement] === 'PEP' ||
                                    servletData[entitlement] === 'CANSEARCH' ||
                                    servletData[entitlement] === 'FLEETPROGRAMADMIN' ||
                                    servletData[entitlement] === 'CORPORATESALES') {
                                z[0].style.display = 'block';
                            }
                        }
                    }
                } else {
                    document.getElementById('fleet-loader').style.display = 'none';
                }
            });
        } else {
            document.getElementById('fleet-loader').style.display = 'none';
            const z = document.getElementsByName('fleet-tile-feature-content');
            for (let a = 0; a < z.length; a += 1) {
                z[a].style.display = 'block';
            }
        }
    }

    stringIncludes(a, b) {
        console.log(this);
        return a.indexOf(b) !== -1;
    }
}
