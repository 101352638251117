const validationRules = {
    required: val => val !== null && val !== undefined && val.trim() !== '',
    email: (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g; //eslint-disable-line
        return emailRegex.test(String(email).toLowerCase());
    },
    phone: phone => phone.trim().length === 14,
    zipCode: zip => /^[0-9]*$/g.test(zip),
    commentBody: comment => comment.length <= 1500,
    mileage: mileage => /^[0-9]*$/g.test(mileage),
};

export default {
    inquiryType: [
        {
            rule: validationRules.required,
            message: 'Please select inquiry type',
        },
    ],
    commentBody: [
        {
            rule: val => true,
            message: '',
        },
        {
            rule: validationRules.commentBody,
            message: 'Please Enter Less than 1500 characters.',
        },
    ],
    firstName: [
        {
            rule: validationRules.required,
            message: 'Please enter your first name',
        },
    ],
    lastName: [
        {
            rule: validationRules.required,
            message: 'Please enter your last name',
        },
    ],
    phone: [
        {
            rule: validationRules.required,
            message: 'Please enter you phone number',
        },
        {
            rule: validationRules.phone,
            message: 'Please enter 10 number digit phone number',
        },
    ],
    email: [
        { rule: validationRules.required, message: 'Email is required' },
        { rule: validationRules.email, message: 'Email is invalid' },
    ],
    address: [
        {
            rule: validationRules.required,
            message: 'Please enter your last name',
        },
    ],
    city: [
        {
            rule: validationRules.required,
            message: 'Please enter your city',
        },
    ],
    state: [
        {
            rule: validationRules.required,
            message: 'Please enter your state',
        },
    ],
    zipCode: [
        {
            rule: validationRules.required,
            message: 'Please enter zip code',
        },
        {
            rule: validationRules.zipCode,
            message: 'Please enter zip code',
        },
    ],
    vin: [
        {
            rule: val => true,
            message: 'Please select vin',
        },
    ],
    mileage: [
        {
            rule: validationRules.required,
            message: 'Please enter the mileage',
        },
        {
            rule: validationRules.mileage,
            message: 'Please enter the mileage',
        },
    ],
};
