import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import EQReadinessUtil from 'modules/EQReadinessUtil';

const ID = {
    SUBMITBUTTON: 'eqreadinesssubmit-btn',
    REGISTRATION_FORM: 'registration_form',
    SITE_NAME: 'site-name',
    VENDORNAME: 'vendorName',
    INSTALLATIONSTARTDATE: 'installationStartDate',
    INSTALLATIONENDDATE: 'installationEndDate',
    COSTESTIMATE: 'costEstimate',
    EQFILE: 'eq-file',
    DEALERCODE: 'dealerCode',
    DEALERNAME: 'dealerName',
    USERNAME: 'userName',
    USEREMAIL: 'userEmail',
    USERPHONE: 'userPhone',
    USERLEVEL: 'userLevel',
    TRAINING_CHECKBOX: 'training',
    TERMS_CHECKBOX: 'terms',
    TERMS_TRAIN_AGREE: 'terms-train-agree',
};

export default class EQReadinessProgram {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'case';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.EQReadinessUtil = new EQReadinessUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const trainEle = document.getElementById(`${ID.TRAINING_CHECKBOX}`);
                const termsEle = document.getElementById(`${ID.TERMS_CHECKBOX}`);
                if (trainEle.checked === true && termsEle.checked === true) {
                    const termsAgree = document.getElementById(`${ID.TERMS_TRAIN_AGREE}`);
                    termsAgree.classList.add('eq-form-data__error-field--hidden');
                    const captchaResponse = grecaptcha.getResponse();
                    if (captchaResponse.length !== 0 && this.EQReadinessUtil.validateAllFields()) {
                        document.getElementById('g-recaptcha-error').innerHTML = '';
                        const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                        const inputs = form.elements;

                        const siteName = 'case';
                        const url = '/bin/microsites/case/eqreadiness/sendEmail';

                        const dealerCode = document.getElementById(`${ID.DEALERCODE}`).value;
                        const dealerName = document.getElementById(`${ID.DEALERNAME}`).value;
                        const userName = document.getElementById(`${ID.USERNAME}`).value;
                        const userEmail = document.getElementById(`${ID.USEREMAIL}`).value;
                        const userPhone = document.getElementById(`${ID.USERPHONE}`).value;
                        const userLevel = document.getElementById(`${ID.USERLEVEL}`).value;
                        const vendorName = document.getElementById(`${ID.VENDORNAME}`).value;
                        const installationStartDate = document.getElementById(`${ID.INSTALLATIONSTARTDATE}`).value;
                        const installationEndDate = document.getElementById(`${ID.INSTALLATIONENDDATE}`).value;
                        const costEstimate = document.getElementById(`${ID.COSTESTIMATE}`).value;
                        const file = document.getElementById(`${ID.EQFILE}`).files[0];

                        const fileData = new FormData();

                        fileData.set('file', file);
                        fileData.set('siteName', siteName);
                        fileData.set('dealerCode', dealerCode);
                        fileData.set('dealerName', dealerName);
                        fileData.set('userName', userName);
                        fileData.set('userEmail', userEmail);
                        fileData.set('userPhone', userPhone);
                        fileData.set('userLevel', userLevel);
                        fileData.set('vendorName', vendorName);
                        fileData.set('installationStartDate', installationStartDate);
                        fileData.set('installationEndDate', installationEndDate);
                        fileData.set('costEstimate', costEstimate);
                        fileData.set('captcha', captchaResponse);
                        grecaptcha.reset();
                        this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                            if (success.includes('Please verify the captcha response')) {
                                this.EQReadinessUtil.displayPopUp('Please verify the captcha response');
                            } else if (success === 'true') {
                                this.EQReadinessUtil.displayPopUp('Form submission successful');
                                form.reset();

                                [].forEach.call(inputs, (element) => {
                                    if (element.matches(`.${CLASSES.EQ_FORM_DATA_CONTROL_SUCCESS}`)) {
                                        element.classList.remove(`${CLASSES.EQ_FORM_DATA_CONTROL_SUCCESS}`);
                                    }
                                });
                            } else {
                                this.EQReadinessUtil.displayPopUp('Form submission failed');
                            }
                        });
                    } else if (captchaResponse.length === 0) {
                        document.getElementById('g-recaptcha-error').innerHTML = '<span class="eq-form-data__error-field">This field is required.</span>';
                        this.EQReadinessUtil.validateAllFields();
                    }
                } else {
                    const termsAgree = document.getElementById(`${ID.TERMS_TRAIN_AGREE}`);
                    termsAgree.classList.remove('eq-form-data__error-field--hidden');
                }
            }
        });
    }
}
