import React from 'react';
import { render } from 'react-dom';
import OrderRequestListingComponent from './order-request-listing/react';
import config from './order-request-listing/config';

export default class OrderRequestListing {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        render(<OrderRequestListingComponent config={config}/>, this.element);
    }
}
