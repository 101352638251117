import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import { ReCAPTCHA } from 'partials/recaptcha';
import ContactUsUtil from 'modules/ContactUsUtil';
import RangeSlider from 'modules/RangeSlider';

// Util dependencies
import { renderer } from 'utils';

const ID = {
    GET_STARTED: 'getStarted',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    YEAR_OF_SERVICE: 'yearOfService',
    ZIP: 'zip',
    QUIZ_CONTAINER__FORM_RECAPTCHA: 'quiz-container-form-recaptcha',
    SUBMIT: 'submit',
    PAGE_LOADER: 'page-loader',
    SITE_NAME: 'site-name',
    RESUBMIT: 'resubmit',
    FORM_RESUBMIT_RECAPTCHA: 'form-resubmit-recaptcha',
    PROG_BAR: 'ProgressBar'
};

const CLASSES = {
    HIDE: 'hide',
    DISABLED: 'disabled',
    QUESTION_SECTION: 'question__section',
    QUESTION_BLOCK: 'question__block',
    QUIZ_CONTAINER: 'quiz-container',
    QUIZ_CONTAINER__FORM: 'quiz-container__form',
    PREV_QUESTION: 'prev_question',
    NEXT_QUESTION: 'next_question',
    ERROR_CONTAINER: 'question__error-container',
    QUESTION__SUBMIT_BUTTON_CONTAINER: 'question__submit-button-container',
    SUCCESS__MESSAGE_CONTAINER: 'success__message-container',
    FAILURE__MESSAGE_CONTAINER: 'failure__message-container',
    OVERLAY_HIDDEN: 'overlay--hidden',
    SUCCESS_BUTTON_CONTAINER: 'success__button-container',
    FORM_ERROR_FIELD_HIDDEN: 'contact-form-data__error-field--hidden',
    FORM_DATA_SUCCESS: 'contact-form-data__control--success',
    FORM_DATA_ERROR: 'contact-form-data__control--error'

};

const ELEM = {
    INPUT: 'input'
};

const CONSTANT = {
    recaptchaEmptyError: 'Please confirm you are not a robot.',
    G_RECAPTCHA_RESPONSE: 'g-recaptcha-response',
    questionGroupText: 'Thank you for providing us your information. In the following series of questions, please respond by choosing which phrase or answer best describes you.',
    questionTitle: 'Want to be a Mercedes Benz Technician? MB TRAC is your place to start.'
};

const API = {
    PRE_ASSESSMENT: '/bin/microsites/quiz/pre-assessment',
    TECH_CAREER_ASSESSMENT: '/bin/microsites/techcareer/assessment',
    GOOGLE_MAP: 'https://maps.googleapis.com/maps/api/geocode/json'
};

export default class QuizComponent {
    constructor(element) {
        this.element = element;
        this.quizContainerRecaptcha = null; // Object to store quiz component reCAPTCHA element
        this.formResubmitRecaptcha = null; // Object to store form resubmit reCAPTCHA element
        this.formResubmitRecaptchaContainer = null;
        this.assessment = null;
        this.inputs = null;
        this.questionCount = 0;
        this.resubmitButton = null;
        this.isValidZip = false;
        this.setFalse = false;
        this.init();
    }

    init() {
        this.loadModules();
        this.cacheDOMElement();
        this.lastNextButton.innerHTML = 'Done';
        this.lastNextButton.setAttribute('isLast', true);
        this.initializeGoogleMapKey();
        this.recaptchaDisplay(this.quizContainerRecaptchaContainer, 'questionContainer');
        this.attachEvents();
        this.hideQuestions();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.RangeSlider = new RangeSlider(this.element);
        this.ContactUsUtil = new ContactUsUtil(this.element);
    }

    cacheDOMElement() {
        this.ProgressBar = document.getElementById(`${ID.PROG_BAR}`);
        this.questionContainer = document.querySelector(`.${CLASSES.QUIZ_CONTAINER}`);
        this.questionContainerForm = document.querySelector(`.${CLASSES.QUIZ_CONTAINER__FORM}`);
        this.getStarted = document.getElementById(`${ID.GET_STARTED}`);
        this.questionSection = document.querySelector(`.${CLASSES.QUESTION_SECTION}`);
        this.questionBlock = document.querySelectorAll(`.${CLASSES.QUESTION_BLOCK}`);
        this.quizContainerRecaptchaContainer = document.querySelector(`#${ID.QUIZ_CONTAINER__FORM_RECAPTCHA}`);
        this.questionSubmitButtonContainer = document.querySelector(`.${CLASSES.QUESTION__SUBMIT_BUTTON_CONTAINER}`);
        this.submitButton = document.getElementById(`${ID.SUBMIT}`);
        this.successMessageContainer = document.querySelector(`.${CLASSES.SUCCESS__MESSAGE_CONTAINER}`);
        this.failureMessageContainer = document.querySelector(`.${CLASSES.FAILURE__MESSAGE_CONTAINER}`);
        this.pageLoader = document.getElementById(`${ID.PAGE_LOADER}`);
        this.radioAll = document.querySelectorAll('input[type=radio]');
        this.checkboxAll = document.querySelectorAll('input[type=checkbox]');
        this.siteName = document.getElementById(`${ID.SITE_NAME}`);
        this.zipCodeField = document.querySelector(`#${ID.ZIP}`);
        this.questionPhraseContainer = this.questionContainerForm
                                        .parentElement.previousElementSibling;
        this.questionTitleText = this.questionContainerForm
                                   .parentElement.previousElementSibling.previousElementSibling;
        this.questionGroupElems = document.querySelectorAll('.question-group');
        this.questionGroupElemsCount = this.questionGroupElems[0].children.length +
                                       this.questionGroupElems[1].children.length;
        this.lastNextButton = this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question');
        this.googleMapApiKey = '';
        this.count = 0;
    }

    attachEvents() {
        this.getStarted.addEventListener(EVENTS.CLICK, (e) => {
            e.preventDefault();
            if (this.isValidZip && this.ContactUsUtil.validateAllFields()) {
                const firstName = document.getElementById(`${ID.FIRST_NAME}`).value;
                const lastName = document.getElementById(`${ID.LAST_NAME}`).value;
                const email = document.getElementById(`${ID.EMAIL}`).value;
                const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                const zip = document.getElementById(`${ID.ZIP}`).value;

                const data = {
                    userInfo: {
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        zip
                    },
                    userResponse: []
                };
                this.assessment = data;
                this.questionPhraseContainer.querySelector('p').innerHTML = CONSTANT.questionGroupText;
                this.questionTitleText.querySelector('h2').innerHTML = CONSTANT.questionTitle;
                this.showQuestions();
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.PREV_QUESTION}`)) {
                const answers = this.questionValue(e.target).answerArr;
                let groupId = '';
                let qid = '';

                e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = '';

                if (answers.length) {
                    groupId = parseInt(e.target.parentElement.parentElement.parentElement.parentElement.querySelector('[data-groupId]').value, 10);
                    qid = parseInt(e.target.parentElement.parentElement.getAttribute('data-id'), 10);

                    if (e.target.parentElement.parentElement.parentElement
                        .previousElementSibling.tagName === 'DIV') {
                        if (this.count === (this.questionGroupElemsCount - 4)) {
                            document.getElementById(`${this.count}`).classList.remove('quiz-container__btnTest');
                            document.getElementById(`${this.count - 1}`).classList.remove('quiz-container__btnTest');
                            this.count -= 1;
                        }
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        document.getElementById(`${this.count}`).classList.remove('quiz-container__btnTest');
                        this.count -= 1;
                        e.target.parentElement.parentElement.parentElement.previousElementSibling.children[0].classList.remove(`${CLASSES.HIDE}`);
                    } else if (e.target.parentElement.parentElement.parentElement.parentElement
                        .previousElementSibling.lastElementChild.children[0]) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        document.getElementById(`${this.count}`).classList.remove('quiz-container__btnTest');
                        this.count -= 1;
                        e.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].classList.remove(`${CLASSES.HIDE}`);
                    }

                    this.updateResponse(answers, groupId, qid);
                    this.showSubmit(e.target.getAttribute('isLast'));
                } else {
                    e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
                }
            } else if (e.target.matches(`.${CLASSES.NEXT_QUESTION}`)) {
                const answers = this.questionValue(e.target).answerArr;
                let groupId = '';
                let qid = '';

                e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = '';

                if (answers.length) {
                    groupId = parseInt(e.target.parentElement.parentElement.parentElement.parentElement.querySelector('[data-groupId]').value, 10);
                    qid = parseInt(e.target.parentElement.parentElement.getAttribute('data-id'), 10);

                    if (e.target.parentElement.parentElement.parentElement
                                .nextElementSibling) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        e.target.parentElement.parentElement.parentElement.nextElementSibling.children[0].classList.remove(`${CLASSES.HIDE}`);
                        document.getElementById(`${this.count += 1}`).classList.add('quiz-container__btnTest');
                    } else if (e.target.parentElement.parentElement.parentElement.parentElement
                        .nextElementSibling.children[2] &&
                        e.target.parentElement.parentElement.parentElement
                        .parentElement.nextElementSibling.children[2].children[0]) {
                        e.target.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
                        document.getElementById(`${this.count += 1}`).classList.add('quiz-container__btnTest');
                        e.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling.children[2].children[0].classList.remove(`${CLASSES.HIDE}`);
                    }

                    this.updateResponse(answers, groupId, qid);
                    this.showSubmit(e.target.getAttribute('isLast'));
                } else {
                    e.target.parentElement.parentElement.querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
                }
            }
        });

        this.submitButton.addEventListener(EVENTS.CLICK, (e) => {
            const answers = this.questionValue(e.target, 'submit').answerArr;

            if (answers.length) {
                const groupId = parseInt(document.querySelector('[data-groupcount]').value, 10);
                const qid = parseInt(document.querySelector(`[data-groupid='${groupId}']`).previousElementSibling.value, 10);

                this.updateResponse(answers, groupId, qid);
                e.target.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].classList.add(`${CLASSES.HIDE}`);
                this.questionSubmitButtonContainer.classList.add(`${CLASSES.HIDE}`);
                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);

                this.makeAjaxCall(this.quizContainerRecaptcha);
            } else {
                e.target.parentElement.parentElement.previousElementSibling.lastElementChild.children[0].querySelector(`.${CLASSES.ERROR_CONTAINER}`).innerHTML = 'Please provide an answer.';
            }
        });

        window.addEventListener(EVENTS.BEFORE_UNLOAD, (e) => {
            // Cancel the event as stated by the standard.
            e.preventDefault();
            // Chrome requires returnValue to be set.
            e.returnValue = '';
        });

        [].forEach.call(this.radioAll, (radio) => {
            radio.addEventListener(EVENTS.CLICK, (e) => {
                e.target.parentElement.parentElement.nextElementSibling.innerHTML = '';
            });
        });

        [].forEach.call(this.checkboxAll, (checkbox) => {
            checkbox.addEventListener(EVENTS.CLICK, (e) => {
                e.target.parentElement.parentElement.nextElementSibling.innerHTML = '';
                this.updateCheckboxOptions(e.target);
            });
        });

        this.zipCodeField.addEventListener(EVENTS.INPUT, (e) => {
            if (e.target.value.length === 5) {
                this.checkZipValidity();
            }
        });
    }

    updateCheckboxOptions(selectedOption) {
        const checkboxOptions = selectedOption.parentElement.parentElement.querySelectorAll("input[type='checkbox']");
        if (selectedOption.value === '') {
            [].forEach.call(checkboxOptions, (option) => {
                if (option !== selectedOption) {
                    option.checked = this.setFalse;
                }
            });
        } else {
            const noneOfTheseField = selectedOption.parentElement.parentElement.querySelector("input[type='checkbox'][name='noneOfThese']");
            noneOfTheseField.checked = this.setFalse;
        }
    }

    checkZipValidity() {
        if (this.googleMapApiKey === '') {
            console.log('Map API Key is not available');
        }
        const url = `${API.GOOGLE_MAP}?address=${this.zipCodeField.value}&key=${this.googleMapApiKey}`;
        this.Ajax.readTextFile(url, (success) => {
            const data = JSON.parse(success);
            if (data.status.trim().toUpperCase() === 'OK') {
                this.zipCodeField.classList.remove(CLASSES.FORM_DATA_ERROR);
                this.zipCodeField.classList.add(CLASSES.FORM_DATA_SUCCESS);
                this.zipCodeField.nextElementSibling.classList.add(CLASSES
                    .FORM_ERROR_FIELD_HIDDEN);
                this.isValidZip = true;
            } else {
                this.zipCodeField.classList.remove(CLASSES.FORM_DATA_SUCCESS);
                this.zipCodeField.classList.add(CLASSES.FORM_DATA_ERROR);
                this.zipCodeField.nextElementSibling.classList.remove(CLASSES
                    .FORM_ERROR_FIELD_HIDDEN);
            }
        });
    }

    makeAjaxCall(recaptchaElement) {
        let url = '';
        const siteName = this.siteName.value;
        const api = this.siteName.value === 'tech-careers' ? API.TECH_CAREER_ASSESSMENT : API.PRE_ASSESSMENT;
        if (recaptchaElement) {
            url = `${api}?${CONSTANT.G_RECAPTCHA_RESPONSE}=${recaptchaElement.getValue()}&siteName=${siteName}`;
        } else {
            url = `${api}?siteName=${siteName}`;
        }
        this.Ajax.ajaxMultipartPostFn(url, JSON.stringify(this.assessment), (success) => {
            const data = JSON.parse(success);
            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            this.questionPhraseContainer.previousElementSibling.classList.add(CLASSES.HIDE);
            this.questionPhraseContainer.classList.add(CLASSES.HIDE);

            if (data === true || data.assessment) {
                this.successMessageContainer.classList.remove(`${CLASSES.HIDE}`);
                if (data.assessment) {
                    const responseDataContainer = `<div class="quiz-container__assessment-details">
                    <p><b><a class="button button_primary quiz-container__assessment-link" href='${data.assessment.assessmentUrl}' target="_blank" rel="noopener">Continue to Assessment</a></b></p>
                    </div>`;
                    this.successMessageContainer.innerHTML = this.successMessageContainer.innerHTML
                     + responseDataContainer;
                    this.successMessageContainer.querySelector(`.${CLASSES.SUCCESS_BUTTON_CONTAINER}`).classList.add(CLASSES.HIDE);
                }
                if (!this.failureMessageContainer.classList.contains(`${CLASSES.HIDE}`)) {
                    this.failureMessageContainer.classList.add(`${CLASSES.HIDE}`);
                }
            } else if (data === false) {
                this.failureMessageContainer.classList.remove(`${CLASSES.HIDE}`);

                if (!this.successMessageContainer.classList.contains(`${CLASSES.HIDE}`)) {
                    this.successMessageContainer.classList.add(`${CLASSES.HIDE}`);
                }
            } else {
                this.failureMessageContainer.classList.remove(`${CLASSES.HIDE}`);
                this.failureMessageContainer.innerHTML = `<div>
                    <p><b>An Error Occured : Internal Server Error</b></p>
                    <div id="form-resubmit-recaptcha-container" class="quiz-container-form__input-group quiz-container__recaptcha-container">
                        <div id="form-resubmit-recaptcha" class="quiz-container__recaptcha"></div>
                    </div>
                    <div>
                        <button type="submit" id="resubmit" class="button button_primary submit" value="Resubmit">Resubmit</button>
                    </div>
                </div>`;
                this.renderResubmitRecaptcha();
                this.addResubmitEventListener();
            }
            if (document.querySelector("a[href='#lets-get-started']")) {
                if (document.querySelector('.sticky-nav__nav-toggle')) {
                    document.querySelector('.sticky-nav__nav-toggle').click();
                }
                document.querySelector("a[href='#lets-get-started']").click();
            }
        });
    }

    renderResubmitRecaptcha() {
        this.formResubmitRecaptchaContainer = document.querySelector(`#${ID.FORM_RESUBMIT_RECAPTCHA}`);
        this.recaptchaDisplay(this.formResubmitRecaptchaContainer);
    }

    addResubmitEventListener() {
        this.resubmitButton = this.failureMessageContainer.querySelector(`#${ID.RESUBMIT}`);
        this.resubmitButton.addEventListener(EVENTS.CLICK, () => {
            this.makeAjaxCall(this.formResubmitRecaptcha);
        });
    }

    questionValue(target, button) {
        const answerArr = [];

        if (!button) {
            this.inputs = Array.prototype.slice.call(target.parentElement.parentElement.querySelectorAll(`${ELEM.INPUT}`), 0);
        } else {
            this.inputs = Array.prototype.slice.call(target.parentElement.parentElement.previousElementSibling.lastElementChild.querySelectorAll(`${ELEM.INPUT}`), 0);
        }

        if (this.inputs.length > 1) {
            this.inputs.forEach((input) => {
                if (input.checked === true) {
                    answerArr.push(input.value);
                }
            });
        } else {
            const rangeValue = this.inputs[0].value;
            answerArr.push(rangeValue);
        }

        return {
            answerArr
        };
    }

    showQuestions() {
        this.questionContainerForm.classList.add('hide');
        this.questionContainerForm.nextElementSibling.querySelector(`.${CLASSES.QUESTION_BLOCK}`).classList.remove('hide');
        const count = this.questionGroupElemsCount - 4;
        for (let i = 1; i <= count; i += 1) {
            this.ProgressBar.innerHTML += `<span id = "${i}" class ="quiz-container__btn"></span>`;
        }
    }

    hideQuestions() {
        [].forEach.call(this.questionBlock, (question) => {
            question.classList.add(`${CLASSES.HIDE}`);
        });
    }

    recaptchaDisplay(recaptchaContainer) {
        if (window.microsites.applicationProperties.isRecaptchaDisabled) {
            recaptchaContainer.parentElement.classList.add(`${CLASSES.HIDE}`);
            recaptchaContainer.parentElement.parentElement.classList.add(`${CLASSES.HIDE}`);
        } else {
            this.createRecaptcha(recaptchaContainer);
            recaptchaContainer.parentElement.nextElementSibling.querySelector("button[type='submit']").classList.add(`${CLASSES.DISABLED}`);
        }
    }

    /**
     * @method createRecaptcha
     * @description Instantiates ReCAPTCHA field and renders it
     */
    createRecaptcha(recaptchaContainer) {
        if (recaptchaContainer.getAttribute('id') === ID.FORM_RESUBMIT_RECAPTCHA) {
            this.formResubmitRecaptcha = new ReCAPTCHA({
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess.bind(this, 'resubmit')
            });
            renderer.insert(this.formResubmitRecaptcha.render(), recaptchaContainer);
        } else if (recaptchaContainer.getAttribute('id') === ID.QUIZ_CONTAINER__FORM_RECAPTCHA) {
            this.quizContainerRecaptcha = new ReCAPTCHA({
                errorMessage: CONSTANT.recaptchaEmptyError,
                onSuccess: this.onSuccess.bind(this, 'submit')
            });
            renderer.insert(this.quizContainerRecaptcha.render(), recaptchaContainer);
        }
    }

    onSuccess(recaptchaType) {
        return new Promise((resolve) => {
            if (recaptchaType === 'submit') {
                document.getElementById(`${ID.SUBMIT}`).classList.remove(`${CLASSES.DISABLED}`);
            } else if (recaptchaType === 'resubmit') {
                this.resubmitButton.classList.remove(`${CLASSES.DISABLED}`);
            }
            resolve();
        });
    }

    updateResponse(answers, groupId, qid) {
        if (!this.assessment.userResponse.length) {
            const localData = {
                qid,
                answers
            };
            const questionModel = {
                groupId,
                questions: []
            };
            questionModel.questions.push(localData);
            this.assessment.userResponse.push(questionModel);
        } else {
            const localData = {
                qid,
                answers
            };
            let flag = false;

            [].forEach.call(this.assessment.userResponse, (modal) => {
                if (modal.groupId === groupId) {
                    flag = true;
                    modal.questions[qid - 1] = localData;
                }
            });

            if (flag === false) {
                const questionModel = {
                    groupId,
                    questions: []
                };

                questionModel.questions.push(localData);
                this.assessment.userResponse.push(questionModel);
            }
        }
    }

    showSubmit(isLast) {
        if (isLast) {
            document.getElementById(`${this.count += 1}`).classList.add('quiz-container__btnTest');
            this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question').classList.add('disabled');
            this.questionSubmitButtonContainer.classList.remove(`${CLASSES.HIDE}`);
        } else {
            this.questionGroupElems[this.questionGroupElems.length - 1].lastElementChild.querySelector('.next_question').classList.remove('disabled');

            if (!this.questionSubmitButtonContainer.classList.contains('hide')) {
                this.questionSubmitButtonContainer.classList.add(`${CLASSES.HIDE}`);
            }
        }
    }

    initializeGoogleMapKey() {
        // Initializing googleMapApiKey
        const siteName = this.siteName.value;
        const data1 = {
            siteName,
        };
        this.Ajax.ajaxPostFn('/bin/microsites/googleMapKey', data1, (success) => {
            if (success !== 'false') {
                console.log(`Google Map API Key fetched ${success}`);
                const data = JSON.parse(success);
                this.googleMapApiKey = data.apiKey;
            } else {
                console.log('Google Map API Key not fetched');
            }
        });
    }
}
