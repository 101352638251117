import { CLASSES } from 'Constants';

const CLASS = {
    CONTACT_FORM_CONTROL: 'contact-form__control',
    CONTACT_FORM_CONTROL_ERROR: 'contact-form__control--error',
    CONTACT_FORM_CONTROL_SUCCESS: 'contact-form__control--success',
    CONTACT_FORM_ERROR_FIELD: 'contact-form-data__error-field',
    CONTACT_FORM_ERROR_FIELD_HIDDEN: 'contact-form-data__error-field--hidden',
};

const ID = {
    POPUP_ID_DENY: 'pop-up-id-deny',
    POPUP_ID_COMP: 'pop-up-id-Review',
    POPUP_ID_CANCEL: 'pop-up-id-cancel',
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
    TEXT_AREA: 'requestOverview',
    FILES: 'submit-fileID',
    EMPTYFILE: 'emptyfile',
    FILETEXT: 'fileText',
    DENYFILES: 'DenyFilesText',
    DENYEMPTYFILE: 'denyemptyfile',
    DENYFILETEXT: 'denyfileText',

};

export default class UISButtonsUtil {
    constructor(element) {
        this.element = element;
    }

    init() {
        this.attachEvents();
    }


    validateAllFields() {
        const vf = this.validateFields();
        const files = this.validateFiles();
        if (vf && files) {
            return true;
        }
        return false;
    }

    denyvalidateAllFields() {
        const denyvf = this.denyvalidateFields();
        const denyfiles = this.denyvalidateFiles();
        if (denyvf && denyfiles) {
            return true;
        }
        return false;
    }

    validateFiles() {
        console.log(this);
        const filedata = document.getElementById(`${ID.FILES}`);
        const emptytext = document.getElementById(`${ID.EMPTYFILE}`);
        if (filedata.files.length === 0) {
            filedata.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            filedata.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            emptytext.classList.remove('contact-form-data__error-field--hidden');
            return false;
        }
        filedata.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
        emptytext.classList.add('contact-form-data__error-field--hidden');
        filedata.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
        return true;
    }

    denyvalidateFiles() {
        console.log(this);
        const denyfiledata = document.getElementById(`${ID.DENYFILES}`);
        const denyemptytext = document.getElementById(`${ID.DENYEMPTYFILE}`);
        if (denyfiledata.files.length === 0) {
            denyfiledata.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denyfiledata.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denyemptytext.classList.remove('contact-form-data__error-field--hidden');
            return false;
        }
        denyfiledata.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
        denyemptytext.classList.add('contact-form-data__error-field--hidden');
        denyfiledata.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
        return true;
    }


    validateFields() {
        console.log(this);
        const completetextareavalue = document.getElementById('requestOverview').value;
        const completetextarea = document.getElementById('requestOverview');
        const errmsg = document.getElementById('textmsgdata');
        const regex = /^(?=.*[^\s])([a-zA-Z0-9.,?;\-\s]{1,500})$/;
        if (regex.test(completetextareavalue)) {
            completetextarea.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            errmsg.classList.add('contact-form-data__error-field--hidden');
            return true;
        }
        completetextarea.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
        errmsg.classList.remove('contact-form-data__error-field--hidden');
        completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
        return false;
    }

    denyvalidateFields() {
        console.log(this);
        const denytextareavalue = document.getElementById('denyComment').value;
        const denytextarea = document.getElementById('denyComment');
        const denyerrmsg = document.getElementById('denyerr');
        const regex = /^(?=.*[^\s])([a-zA-Z0-9.,?;\-\s]{1,500})$/;
        if (regex.test(denytextareavalue)) {
            denytextarea.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denyerrmsg.classList.add('contact-form-data__error-field--hidden');
            return true;
        }
        denytextarea.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
        denyerrmsg.classList.remove('contact-form-data__error-field--hidden');
        denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
        return false;
    }


    displayPopUp() {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID_DENY}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }

    displayPopUpReview() {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID_COMP}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }

    displayPopUpCancel() {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID_CANCEL}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }

    displayPopUperr(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID_COMP}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        document.getElementById('complete-popup').style.display = 'none';
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }

        popUp.querySelector('h3').innerHTML = '&nbsp;';

        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }


    displayPopUpDeny(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID_DENY}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }

        popUp.querySelector('h3').innerHTML = '&nbsp;';

        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
