import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ApplyNowBrcUtil from 'modules/ApplyNowBrcUtil';

const ID = {
    SUBMITBUTTON: 'applyNowBrcFormSubmit',
    SITE_NAME: 'site-name',
    NAME: 'applyNow-Name-brc',
    EMAIL: 'applyNow-Email-brc',
    TOPIC: 'applyNow-topic-brc',
    OVERVIEW: 'requestOverview',
    CORRESPONDENCE: 'applyNow-brc-fileID',
    APPLY_NOW_BRC: 'apply-now-brc'

};

export default class ApplyNowBrc {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.ApplyNowBrcUtil = new ApplyNowBrcUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                if (this.ApplyNowBrcUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.APPLY_NOW_BRC}`);
                    const inputs = form.elements;
                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const nameBrc = document.getElementById(`${ID.NAME}`).value;
                    const requesterEmail = document.getElementById(`${ID.EMAIL}`).value;
                    const topic = document.getElementById(`${ID.TOPIC}`).value;
                    const requestOverview = document.getElementById(`${ID.OVERVIEW}`).value;

                    const url = '/bin/microsites/brc/sendEmail';

                    const file = document.getElementById(`${ID.CORRESPONDENCE}`).files[0];

                    const fileData = new FormData();

                    fileData.set('file', file);
                    fileData.set('siteName', siteName);
                    fileData.set('name', nameBrc);
                    fileData.set('requesterEmail', requesterEmail);
                    fileData.set('topic', topic);
                    fileData.set('requestOverview', requestOverview);

                    this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                        if (success === 'true') {
                            this.ApplyNowBrcUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ApplyNowBrcUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });
    }
}
