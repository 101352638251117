/**
 * Template for dog vehicle bodystyle explorer
 * Uses ES6 template literal to composite the markup for a dog vehicle bodystyle explorer container
 * @param summary {Array} the array of dog vehicle bodystyle explorer items
 */
import { html } from 'utils';
import ModelYearsTemplate from './model-years';

export default (item = []) => html`
    <div class="vehicle-bodystyle__block">
        <div class="vehicle-bodystyle__heading">
            <p class="vehicle-bodystyle__title">${item.bodystyle}</p>
        </div>

        <div>
            <img class="vehicle-bodystyle__image" src="${item.image}" alt="Image Alt" />
        </div>
        <div class="vehicle-bodystyle__list-block">
            <p class="vehicle-bodystyle__list-title">${item.code}</p>
            <ul class="vehicle-bodystyle__list">
                ${ModelYearsTemplate(item.modelYears)}
            </ul>
        </div>
    </div>
`.trim();
