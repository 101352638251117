import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import VehicleEndDateUpdateUtil from 'modules/VehicleEndDateUpdateUtil';

const ID = {
    SUBMITBUTTON: 'GSubmit',
    DEALERCODE: 'dealerCode',
    VIN: 'gvinno',
    MAYBACH_VIN: 'maybachVin',
    STARTDATE: 'startDate',
    ENDDATE: 'endDate',
    VEHICLE_END_DATE_UPDATE: 'Vehicle-end-date-update',
    SITE_NAME: 'site-name'

};

export default class StopDrive {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        // newScript.src = 'https://www.google.com/recaptcha/api.js';
        const recaptchaURL = window.microsites.pageData.recaptcha.recaptchaURL || '';
        newScript.src = recaptchaURL;
        const urlServlet = '/bin/microsites/captcha';
        const formName = document.getElementById(`${ID.SITE_NAME}`).value;
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.VehicleEndDateUpdateUtil = new VehicleEndDateUpdateUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const capRes = grecaptcha.getResponse();
                if (this.VehicleEndDateUpdateUtil.validateAllFields() && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.VEHICLE_END_DATE_UPDATE}`);
                    const inputs = form.elements;
                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            if (element.name === 'startDate'|| element.name === 'endDate') {
                                const elementVlaue = this.arrangeDate(element.value);
                                data[element.name] = elementVlaue;
                            } else {
                                data[element.name] = element.value;
                            }
                            return data;
                        }, {});

                    const url = '/bin/microsites/stopDrive';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                        option: 'add',
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.VehicleEndDateUpdateUtil.displayPopUp('Please verify the captcha response');
                        } else if (success.includes('Record Added Successfully')) {
                            this.VehicleEndDateUpdateUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.VehicleEndDateUpdateUtil.displayPopUp(success);
                        }
                    });
                }
            }
        });
    }

    arrangeDate(value) {
        console.log(this);
        const dateValue = value.split('-');
        const dateFinalValue = `${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`;
        return dateFinalValue;
    }
}
