export default {
    BODY: 'body',
    HEADER: 'header',
    WRAPPER: 'wrapper',
    CUSTOM_WEBSITES: 'custom-websites',
    dog: 'dog',
    HEADER_CLICK_BLOCKER: 'header-click-blocker',
    MENU_TOGGLE__LOGO_IMG: 'menu-toggle__logo-img',
    MENU_TOGGLE: 'menu-toggle',
    HEADER_POSITION: 'header--position',
    UN_TRANSFORM: 'un-transform',
    DROPDOWN__CONTENT: 'dropdown__content',
    DROPDOWN__BUTTON: 'dropdown__button',
    DROPDOWN__STARICON: 'dropdown__star-icon',
    DROPDOWN__CARETICON: 'dropdown__caret-icon',
    DROPDOWN__PRINTICON: 'dropdown__print-icon',
    DROPDOWN__SHOW: 'dropdown-show',
    DROPDOWN__ACTIVE: 'dropdown-active',
    FILTEREDLIST__SUBLIST_BLOCK: 'filtered-list__sublist-block',
    SITEMAP_JSON: 'sitemap__json',
    SITEMAP_CONTAINER: 'sitemap__container',
    SUBNAV__SEARCH: 'sub-nav__search',
    SUBNAV__SEARCH_OPEN: 'sub-nav__search--open',
    SUBNAV__ICON_SEARCH: 'sub-nav__icon-search',
    SUBNAV__FORM_INPUT: 'sub-nav__form-input',
    SUBNAV__FORM_BTN: 'sub-nav__form-btn',
    TOPNAV__LINK_MENU: 'top-nav__link_menu',
    TOPNAV__LABEL: 'top-nav__label',
    TOPNAV__LINK_CATEGORY: 'top-nav__link_category',
    TOPNAV__DROPDOWN: 'top-nav__drop-down',
    TOPNAV__DROPDOWN_OPEN: 'top-nav__drop-down--open',
    TOPNAV__DROPDOWN_SINGLE: 'top-nav__drop-down--single',
    TOPNAV__HAMBURGER: 'top-nav__hamburger',
    TOPNAV__HAMBURGER_LINE: 'top-nav__hamburger-line',
    TOPNAV__LINK_PARENT: 'top-nav__link_parent',
    POPUP__CONTENT_CLOSE: 'pop-up__content--close',
    POPUP_ID: 'pop-up-id',
    POPUP: 'pop-up',
    MODAL__BUTTON: 'modal__button',
    POPUP_OPEN: 'pop-up--open',
    ACCORDION__LIST: 'accordion__list',
    ACCORDION__ACTIVE: 'accordion__active',
    ALERTBOX__CLOSEICON: 'alert-box__close-icon',
    ALERTBOX_CLOSE: 'alert-box--close',
    CUSTOM_TOP_NAV__LINK_PARENT: 'custom-top-nav__link_parent',
    CUSTOM_TOP_NAV__LINK: 'custom-top-nav__link',
    CUSTOM_SUB_NAV__LOGOUT: 'custom-sub-nav__logout',
    CUSTOM_TOP_NAV__LEVEL: 'custom-top-nav__level',
    CUSTOM_SUB_NAV__LEVEL_RIGHT: 'custom-sub-nav__level--right',
    CUSTOM_TOP_NAV__LEVEL_LOGOUT_ONLY: 'custom-top-nav__level--logout-only',
    CUSTOM_TOP_NAV__LEVEL_SEARCH_ONLY: 'custom-top-nav__level--search-only',
    CUSTOM_TOP_NAV__LEVEL_FULL_WIDTH: 'custom-top-nav__level--full-width',
    CUSTOM_TOP_NAV__LINK_MENU: 'custom-top-nav__link_menu',
    CUSTOM_TOP_NAV__ITEM_MENU: 'custom-top-nav__item--menu',
    CUSTOM_TOP_NAV__SUB_MENU_LIST: 'custom-top-nav__sub-menu-list',
    CUSTOM_TOP_NAV__CONTAINER: 'custom-top-nav__container',
    HIDE_CONTAINER: 'hide-container',
    MENU_TOGGLE__HANBURGER_LINE: 'menu-toggle__hamburger-line',
    MENU_TOGGLE__HAMBURGER: 'menu-toggle__hamburger',
    MENU_TOGGLE__CTA: 'menu-toggle__cta',
    FIXEDHERO: 'fixed-hero',
    FIXEDHERO__IMAGE_CONTAINER: 'fixed-hero__image-container',
    FIXEDHERO__PLAY_BTN: 'fixed-hero__play-btn',
    TAB_LIST: 'tab__list',
    TAB_LINKS: 'tab__links',
    TAB_CONTENT: 'tab__content',
    TAB_LIST_ACTIVE: 'tab__list--active',
    TAB_CONTENT_ACTIVE: 'tab__content--active',
    TABBED_MODULE_ONLY_CONTENT: 'tabbed-module--only-content',
    TABBED_MODULE_ONLY_MEDIA: 'tabbed-module--only-media',
    DROP_DOWN_CONTAINER: 'contact-form-dropdown',
    CONTACT_FORM_DATA_CONTAINER: 'contact-form-data',
    CONTACT_FORM_DATA__CONTROL_SUCCESS: 'contact-form-data__control--success',
    POPUP__CONTENT_BODY: 'pop-up__content-body',
    POPUP__CONTENT_OPEN: 'pop-up--open',
    EQ_FORM_DATA_CONTROL_SUCCESS: 'eq-form-data__control--success',
    MB_FORM_DATA_CONTROL_SUCCESS: 'mb-form-data__control--success'
};
