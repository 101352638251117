import Ajax from 'modules/Ajax';

const CLASSES = {
    LINK_LIST__ITEM: 'link-list__item',
    LINK_LIST__ITEM_LINK: 'link-list__item-link',
    LINK_LIST__CONTAINER: 'link-list__container',
    LINK_LIST__LOADER: 'link-list__loader',
    HIDDEN: 'hidden',
};

const LABEL = {
    ADD: 'add',
    REMOVE: 'remove'
};

export default class PrependAscUrl {
    constructor(element) {
        this.element = element;
        this.urlToPrepend = '';
        this.linkListItems = null;
        this.loaderElement = null;
        this.init();
    }

    init() {
        this.cacheDomElement();
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    cacheDomElement() {
        this.linkListItems = document.querySelectorAll(`.${CLASSES.LINK_LIST__ITEM_LINK}[href*="/content/asset-share-commons"]`);
        this.linkContainers = document.querySelectorAll(`.${CLASSES.LINK_LIST__CONTAINER}`);
        this.prependUrlLinks = document.querySelectorAll("a[href*='/content/asset-share-commons']:not([class*='link-list__item-link'])");
    }

    attachEvents() {
        this.toggleHide(`${LABEL.ADD}`);
        this.getUrl();
    }

    getUrl() {
        if (sessionStorage.getItem('ascurl')) {
            this.urlToPrepend = sessionStorage.getItem('ascurl');
            this.toggleHide(`${LABEL.REMOVE}`);
        } else {
            this.getUrlFromServer();
            this.getAssetUser();
        }
    }

    getUrlFromServer() {
        const url = '/bin/microsites/mercedes-market/get-asset-share-common-url';
        this.Ajax.readTextFile(url, (callback) => {
            if (!JSON.parse(callback).error) {
                this.urlToPrepend = callback.split('"')[1];
                sessionStorage.setItem('ascurl', `${this.urlToPrepend}`);
            }
            this.toggleHide(`${LABEL.REMOVE}`);
        });
    }

    getAssetUser() {
        const url = '/bin/microsites/mercedes-market/get-asset-share-user';
        this.Ajax.ajaxPostFn(url, (success) => {
            if (success === 'true') {
                sessionStorage.setItem('assets-user', 'added');
                this.toggleHide(`${LABEL.REMOVE}`);
            }
        });
    }
    toggleHide(status) {
        if (status === `${LABEL.REMOVE}`) {
            [].forEach.call(this.linkListItems, (item) => {
                this.prependUrl(item);
                item.parentNode.classList.remove(`${CLASSES.HIDDEN}`);
            });

            // links other than link list items
            [].forEach.call(this.prependUrlLinks, (item) => {
                this.prependUrl(item);
                item.classList.remove(`${CLASSES.HIDDEN}`);
            });

            /*  Removing loader from each container,
                since all the links in particular container are displayed.
            */
            [].forEach.call(this.linkContainers, (element) => {
                if (element.querySelector(`.${CLASSES.LINK_LIST__LOADER}`)) {
                    this.toggleLoader(element, `${LABEL.REMOVE}`);
                }
            });
        } else if (status === `${LABEL.ADD}`) {
            [].forEach.call(this.linkListItems, (item) => {
                item.parentNode.classList.add(`${CLASSES.HIDDEN}`);
            });

            [].forEach.call(this.prependUrlLinks, (item) => {
                item.classList.add(`${CLASSES.HIDDEN}`);
            });

            /*  Adding loader on each link list container if all the links
                inside particular container contains #!MP,
                since all the links will be hidden in these containers.
            */
            [].forEach.call(this.linkContainers, (element) => {
                if (!element.querySelector(`.${CLASSES.LINK_LIST__ITEM_LINK}:not([href*="/content/asset-share-commons"])`)) {
                    this.toggleLoader(element, `${LABEL.ADD}`);
                }
            });
        }
    }

    prependUrl(link) {
        const linkRef = link.getAttribute('href');
        const url = this.urlToPrepend.concat(linkRef);
        link.setAttribute('href', `${url}`);
        link.setAttribute('target', '_blank');
        link.classList.remove(`${CLASSES.HIDDEN}`);
    }

    toggleLoader(container, status) {
        if (status === `${LABEL.ADD}`) {
            this.loaderElement = document.createElement('li');
            this.loaderElement.setAttribute('class', `${CLASSES.LINK_LIST__LOADER}`);
            container.appendChild(this.loaderElement);
        } else if (status === `${LABEL.REMOVE}`) {
            container.removeChild(container.querySelector(`.${CLASSES.LINK_LIST__LOADER}`));
        }
    }
}
