import Ajax from 'modules/Ajax';
import { EVENTS } from 'Constants';
import BrcSitesReportUtil from 'modules/BrcSitesReportUtil';

const ID = {
    BRC_SITES_REPORT_LISTING: 'brc-sites-listing',
    BRC_SITES_LOADER: 'brcSitesLoader',
    BRC_REPORT_TABLE: 'reportTable',
    FILTERBUTTON: 'filterBtn',
    BRC_SITES_CONTROL_ERROR_FIELD_HIDDEN: 'brc-sites-report__error-field--hidden',
    FROM: 'from',
    TO: 'to',
    ERROR_LINE: 'errorLine',
};

const CLASSES = {
    SORT__DATE: 'brc-sites-report__sortDate',
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const date = new Date();
const brcLoader = document.getElementById(`${ID.BRC_SITES_LOADER}`);
let dbData = '';
let ascending = false;

export default class BrcSitesReport {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.loadReport();
        this.attachEvents();
    }

    loadModules() {
        this.BrcSitesReportUtil = new BrcSitesReportUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    loadReport() {
        console.log(this);
        const url = '/bin/microsites/brc/sites-report';
        const siteName = 'brc-sites-report';
        const data = {
            siteName,
        };

        brcLoader.style.display = 'block';
        this.Ajax.ajaxPostFn(url, data, (success) => {
            if (success !== 'false') {
                brcLoader.style.display = 'none';
                const servletData = JSON.parse(success);
                dbData = servletData;
                this.displaySitesResult(servletData);
            } else {
                brcLoader.style.display = 'none';
            }
        });
    }

    displaySitesResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.BRC_SITES_REPORT_LISTING}`);
        let htmlBuild = '';
        const monthYear = `${months[date.getMonth()]}-${date.getFullYear()}`;
        document.getElementById('siteVisits').innerHTML = `Site Visits<br>(${monthYear})`;
        for (let i = 0; i < data.length; i += 1) {
            const formData = JSON.parse(data[i]);
            if (formData[monthYear] !== undefined) {
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding">${formData.userId}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.firstName}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.lastName}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.department}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.userType}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.date}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData[monthYear]}</td>`;
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayFilterResult(data, from, to) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.BRC_SITES_REPORT_LISTING}`);
        document.getElementById('siteVisits').innerHTML = `Site Visits<br>(${from}-${to})`;
        let htmlBuild = '';
        const arr = [];
        const date1 = from.replace('-', ' ');
        const date2 = to.replace('-', ' ');
        const datFrom = new Date(`1 ${date1}`);
        const datTo = new Date(`1 ${date2}`);
        const fromYear = datFrom.getFullYear();
        const toYear = datTo.getFullYear();
        const diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();
        for (let j = datFrom.getMonth(); j <= diffYear; j += 1) {
            arr.push(`${months[j % 12]}-${Math.floor(fromYear + (j / 12))}`);
        }
        for (let i = 0; i < data.length; i += 1) {
            const formData = JSON.parse(data[i]);
            let siteVisitCount = 0;
            for (let m = 0; m < arr.length; m += 1) {
                if (formData[arr[m]] !== undefined) {
                    siteVisitCount += parseInt(formData[arr[m]], 10);
                }
            }
            console.log(formData);
            if (siteVisitCount !== 0) {
                htmlBuild += `<tr role="row" id="reportRow" class="brc-sites-report__border_rule">
                <td colspan="1" class="brc-sites-report__td_padding">${formData.userId}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.firstName}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.lastName}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.department}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.userType}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${formData.date}</td>
                <td colspan="1" class="brc-sites-report__td_padding">${siteVisitCount}</td>`;
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target && e.target.matches(`#${ID.FILTERBUTTON}`)) {
                e.preventDefault();
                if (this.BrcSitesReportUtil.validateAllFields()) {
                    const fromInput = document.getElementById('from').value;
                    const monthFrom = fromInput.split('-')[1];
                    const yearFrom = fromInput.split('-')[0];
                    const fromDate = new Date();
                    fromDate.setMonth(monthFrom - 1);
                    fromDate.setYear(yearFrom);
                    const from = `${months[fromDate.getMonth()]}-${fromDate.getFullYear()}`;

                    const toInput = document.getElementById('to').value;
                    const monthTo = toInput.split('-')[1];
                    const yearTo = toInput.split('-')[0];
                    const toDate = new Date();
                    toDate.setMonth(monthTo - 1);
                    toDate.setYear(yearTo);
                    const to = `${months[toDate.getMonth()]}-${toDate.getFullYear()}`;
                    this.displayFilterResult(dbData, from, to);
                } else {
                    this.BrcSitesReportUtil.validateAllFields();
                }
            }

            if (e.target && e.target.matches(`.${CLASSES.SORT__DATE}`)) {
                e.preventDefault();
                const tbody = document.querySelector('#reportTable tbody');
                const rows = [].slice.call(tbody.querySelectorAll('tr'));
                rows.sort((a, b) => {
                    const first = this.convertDate(a.cells[5].innerHTML);
                    const second = this.convertDate(b.cells[5].innerHTML);

                    if (ascending) {
                        if (first > second) return 1;
                        if (first < second) return -1;
                    } else {
                        if (first > second) return -1;
                        if (first < second) return 1;
                    }
                    return 0;
                });

                rows.forEach((v) => {
                    tbody.appendChild(v);
                });
                if (ascending) {
                    ascending = false;
                } else {
                    ascending = true;
                }
            }
        });
    }

    convertDate(d) {
        console.log(this);
        const p = d.split('-');
        return +(p[2] + p[0] + p[1]);
    }
}
