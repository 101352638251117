import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CLASSES = {
    FAVORITES_LIST__ITEM_CHECKBOX: 'favorites-list__item-checkbox',
    FAVORITES_LIST__SELECT_ALL_CHECKBOX: 'favorites-list__select-all-checkbox',
    FAVORITES_LIST__CONTAINER: 'favorites-list__container'
};

const ID = {
    SELECT_ALL: 'select-all'

};

const SITE_NAME = {
    MB_NEW_WARRENTY: 'mb-new-warranty'
}

export default class FavoritesList {
    constructor(element) {
        this.element = element;
        this.flag = 0;
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
        this.getFavAPI();
    }

    cacheDOMElement() {
        this.checkboxes = document.getElementsByClassName(`${CLASSES.FAVORITES_LIST__ITEM_CHECKBOX}`);
        this.favContainer = document.querySelector(`.${CLASSES.FAVORITES_LIST__CONTAINER}`);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.classList.contains(`${CLASSES.FAVORITES_LIST__SELECT_ALL_CHECKBOX}`)) {
                this.selectAll(e);
            } else if (e.target.classList.contains(`${CLASSES.FAVORITES_LIST__ITEM_CHECKBOX}`)) {
                this.itemCheck();
            }
        });
    }

    selectAll(e) {
        [].forEach.call(this.checkboxes, (el) => {
            el.checked = e.target.checked;
        });

        this.flag = 0;
    }

    itemCheck() {
        const selectAllCheckBox = document.getElementById(`${ID.SELECT_ALL}`);

        for (let j = 0; j < this.checkboxes.length; j += 1) {
            if (this.checkboxes[j].checked) {
                this.flag += 1;
            }
        }

        if (selectAllCheckBox.checked) {
            selectAllCheckBox.checked = false;
            event.target.checked = false;
        } else if (this.flag === this.checkboxes.length) {
            selectAllCheckBox.checked = true;
        }

        this.flag = 0;
    }

    getFavAPI() {
        const parameter = document.getElementById('siteName').value;

        if (parameter === SITE_NAME.MB_NEW_WARRENTY) {
            const url = `/bin/microsites/my-favourites?siteName=${parameter}&option=display`;
            this.Ajax.readTextFile(url, (text) => {
                const data = JSON.parse(text);
                const keys = Object.keys(data);
                let favList = '';

                [].forEach.call(keys, (k) => {
                    if (k !== 'error' && k !== 'date' && k !== 'currentUser') {
                        if (k === 'pageMap') {
                            const pageMap = data[k];
                            for (let key in pageMap) {
                                favList += `<li class="favorites-list__item">
                                    <input type="checkbox" class="favorites-list__item-checkbox" value="${pageMap[key]}">
                                    <a href="${key}.html" class="favorites-list__item-anchor">${pageMap[key]}</a>
                                </li>`;
                            }
                        } else {
                            favList += `<li class="favorites-list__item">
                                        <input type="checkbox" class="favorites-list__item-checkbox" value="${data[k]}">
                                        <a href="${k}.html" class="favorites-list__item-anchor">${data[k]}</a>
                                    </li>`;
                        }
                    } else {
                        const selectAllCheckBox = document.getElementById(`${ID.SELECT_ALL}`);
                        selectAllCheckBox.checked = false;
                    }
                });

                this.favContainer.innerHTML = favList;
            });
        } else {

            const url = `/bin/microsites/get-favorite?siteName=${parameter}`;

            this.Ajax.readTextFile(url, (text) => {
                const data = JSON.parse(text);
                const keys = Object.keys(data);
                let favList = '';

                [].forEach.call(keys, (k) => {
                    if (k !== 'error') {
                        favList += `<li class="favorites-list__item">
                                    <input type="checkbox" class="favorites-list__item-checkbox" value="${k}">
                                    <a href="${k}.html" class="favorites-list__item-anchor">${data[k]}</a>
                                </li>`;
                    } else {
                        const selectAllCheckBox = document.getElementById(`${ID.SELECT_ALL}`);
                        selectAllCheckBox.checked = false;
                    }
                });

                this.favContainer.innerHTML = favList;
            });
        }

    }
}
