import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    INSTRUCTOR_FORM_CONTROL: 'instructor-form__control',
    INSTRUCTOR_FORM_CONTROL_ERROR: 'instructor-form__control--error',
    INSTRUCTOR_FORM_CONTROL_SUCCESS: 'instructor-form__control--success',
    INSTRUCTOR_FORM_CONTROL_ERROR_FIELD_HIDDEN: 'instructor-form__error-field--hidden',
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content'
};
export default class InstructorUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.INSTRUCTOR_FORM_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    containAlphabetsOnly(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(elementValue);
    }

    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.INSTRUCTOR_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.INSTRUCTOR_FORM_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.INSTRUCTOR_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Email Validation
     */
    isValidEmail(email) {
        console.log(this);
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /**
     * Phone Number Validation
     */
    isValidPhoneNumber(phoneNumber) {
        console.log(this);
        const regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.INSTRUCTOR_FORM_CONTROL}`);

        this.validateFields(inputFields);

        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.INSTRUCTOR_FORM_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'email') {
            if (el.value) {
                this.validate(el, this.isValidEmail(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'phoneNumber') {
            if (el.value) {
                this.validate(el, this.isValidPhoneNumber(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'firstName' || el.name === 'lastName') {
            if (el.value) {
                this.validate(el, this.containAlphabetsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
