import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const ID = {
    SUBMITBUTTON: 'contactFormSubmitBtn',
    CONTACT_US: 'contact-us',
    SITE_NAME: 'site-name',
};
const OPTIONS = {
    VEHICLE: 'vehicle',
    COLLISION: 'collision',
    INSURENCE: 'insurance'

};

export default class ContactUsMBCollisionCenter {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'mbCollisionCenters';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    cacheDOMElement() {
        this.dropDownContainer = document.querySelector(`.${CLASSES.DROP_DOWN_CONTAINER}`);
        this.formContainer = document.querySelector(`.${CLASSES.CONTACT_FORM_DATA_CONTAINER}`);
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        let selectedValue;

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.classList.contains(`${CLASSES.DROP_DOWN_CONTAINER}`)) {
                selectedValue = this.dropDownContainer.value;
                this.getForm(selectedValue);
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const captchaResponse = grecaptcha.getResponse();
                if (this.ContactUsUtil.validateAllFields() && captchaResponse.length !== 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById(`${ID.CONTACT_US}`);
                    const inputs = form.elements;

                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const url = '/bin/microsites/mb-collision-center/contact-us/sendEmail';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                        value: selectedValue
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success === 'true') {
                            this.ContactUsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.ContactUsUtil.validateAllFields();
                }
            }
        });
    }


    /**
     * Get Form according to the value selected in drop down.
     */
    getForm(selectedValue) {
        const vehicleForm = `<form action=""id ="contact-us" method="post">
    <fieldset>
        <legend class="contact-form-data__heading">Vehicle Owner Form</legend>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Name *</label>
            <input type="text" name="name" class="contact-form-data__control" placeholder="Vehicle Owner Name" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter Vehicle Owner Name.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Vehicle Model *</label>
            <input type="text" name="vehicleModel" class="contact-form-data__control" placeholder="Vehicle Model" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter Vehicle Model.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Vin# *</label>
            <input type="text" name="vin" class="contact-form-data__control" placeholder="Vin#" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter Vin number.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Your E-mail Address *</label>
            <input type="text" name="requesterEmail" class="contact-form-data__control" placeholder="Your E-mail Address" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter valid email address.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Message *</label>
            <textarea name="message" class="contact-form-data__control" placeholder="Message" rows="4"></textarea>
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter your Question/suggestion.</p>
        </div>
        <div class="contact-form-data__group">        
        <div id="google-captcha"></div>
        <div id="g-recaptcha-error"></div>
        </div>
        <div class="contact-form-data__group">
            <button type="submit" id="contactFormSubmitBtn" class="button button_primary" value="Submit">Submit</button>
            <button type="reset" class="button button_primary">Reset</button>
        </div>
    </fieldset>
</form>`;

        const collisionCenterForm = `<form action=""id ="contact-us" method="post">
    <fieldset>
        <legend class="contact-form-data__heading">Collision Center Form</legend>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Facility name *</label>
            <input type="text" name="facilityName" class="contact-form-data__control" placeholder="Facility Name" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter Facility Name.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Contact Person *</label>
            <input type="text" name="contactPerson" class="contact-form-data__control" placeholder="Contact Person" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please contact person.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Your Phone Number *</label>
            <input type="text" name="phoneNumber" class="contact-form-data__control" placeholder="Your Phone Number" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter 10-digit phone number. No spaces, -, or () necessary.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Your E-mail Address *</label>
            <input type="text" name="requesterEmail" class="contact-form-data__control" placeholder="Your E-mail Address" value="">
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter valid email address.</p>
        </div>
        <div class="contact-form-data__group">
            <label class="contact-form-data__label">Message *</label>
            <textarea name="message" class="contact-form-data__control" placeholder="Message" rows="4"></textarea>
            <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter your Question/suggestion.</p>
        </div>
        <div class="contact-form-data__group">
        <div id="google-captcha"></div>
        <div id="g-recaptcha-error"></div>
        </div>
        <div class="contact-form-data__group">
            <button type="submit" id="contactFormSubmitBtn" class="button button_primary" value="Submit">Submit</button>
            <button type="reset" class="button button_primary">Reset</button>
        </div>
    </fieldset>
</form>`;

        const insuranceProfessionalForm = `<form action=""id ="contact-us" method="post">
    <fieldset>

    <legend class="contact-form-data__heading">Insurance Professional Form</legend>
    <div class="contact-form-data__group">
        <label class="contact-form-data__label">Name *</label>
        <input type="text" name="name" class="contact-form-data__control" placeholder="Name" value="">
        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter Name.</p>
    </div>
    <div class="contact-form-data__group">
        <label class="contact-form-data__label">Carrier *</label>
        <input type="text" name="carrier" class="contact-form-data__control" placeholder="Carrier" value="">
        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter Carrier.</p>
    </div>
    <div class="contact-form-data__group">
        <label class="contact-form-data__label">Your Phone Number *</label>
        <input type="text" name="phoneNumber" class="contact-form-data__control" placeholder="Your Phone Number" value="">
        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter 10-digit phone number. No spaces, -, or () necessary.</p>
    </div>
    <div class="contact-form-data__group">
        <label class="contact-form-data__label">Your E-mail Address *</label>
        <input type="text" name="requesterEmail" class="contact-form-data__control" placeholder="Your E-mail Address" value="">
        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Enter valid email address.</p>
    </div>
    <div class="contact-form-data__group">
        <label class="contact-form-data__label">Message *</label>
        <textarea name="message" class="contact-form-data__control" placeholder="Message" rows="4"></textarea>
        <p class="contact-form-data__error-field contact-form-data__error-field--hidden">Please enter your Question/suggestion.</p>
    </div>
<div class="contact-form-data__group">
<div id="google-captcha"></div>
<div id="g-recaptcha-error"></div>
</div>
    <div class="contact-form-data__group">
        <button type="submit" id="contactFormSubmitBtn" class="button button_primary" value="Submit">Submit</button>
        <button type="reset" class="button button_primary">Reset</button>
    </div>
    </fieldset>
</form>`;
        if (selectedValue === OPTIONS.VEHICLE) {
            this.formContainer.innerHTML = vehicleForm;
        } else if (selectedValue === OPTIONS.COLLISION) {
            this.formContainer.innerHTML = collisionCenterForm;
        } else if (selectedValue === OPTIONS.INSURENCE) {
            this.formContainer.innerHTML = insuranceProfessionalForm;
        } else {
            this.formContainer.innerHTML = '';
        }
        this.loadCaptcha();
    }
}
