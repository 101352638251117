import { EVENTS, CLASSES } from 'Constants';

export default class Accordion {
    constructor(element) {
        this.element = element;
        this.init();
        this.accordionItem = null;
        this.accordionPanel = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }
    cacheDOMElement() {
        this.accordionItem = document.getElementsByClassName(`${CLASSES.ACCORDION__LIST}`);
    }

    attachEvents() {
        const accordionItems = this.accordionItem;
        window.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.parentNode && e.target.parentNode.classList.contains(`${CLASSES.ACCORDION__LIST}`)) {
                const flag = e.target.parentNode.classList.contains(`${CLASSES.ACCORDION__ACTIVE}`);
                [].forEach.call(accordionItems, (element) => {
                    element.classList.remove(`${CLASSES.ACCORDION__ACTIVE}`);
                });
                if (!flag) {
                    e.target.parentNode.classList.add(`${CLASSES.ACCORDION__ACTIVE}`);
                }
            }
        });
    }
}
