import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import ContactUsAutohausUtil from 'modules/ContactUsAutohausUtil';

const ID = {
    SUBMITBUTTON: 'autohausContactFormSubmit',
    REGISTRATION_FORM: 'autohaus-contact-us',
    SITE_NAME: 'site-name',
    NAME: 'name',
    TITLE: 'title',
    DEALER_CODE: 'dealerCode',
    REQUESTER_EMAIL: 'requesterEmail',
    PHONE_NUMBER: 'phoneNumber',
    MESSAGE: 'message',


};

export default class ContactUsAutohaus {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        let formName = null;
        if (window.location.hostname.includes('autohaus')) {
            formName = 'autohaus';
        } else if (window.location.hostname.includes('nsmicrosites')) {
            formName = 'nsmicrosites';
        }
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.ContactUsAutohausUtil = new ContactUsAutohausUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const captchaResponse = grecaptcha.getResponse();
                if (this.ContactUsAutohausUtil.validateAllFields()
                && captchaResponse.length !== 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const name = document.getElementById(`${ID.NAME}`).value;
                    const title = document.getElementById(`${ID.TITLE}`).value;
                    const dealerCode = document.getElementById(`${ID.DEALER_CODE}`).value;
                    const requesterEmail = document.getElementById(`${ID.REQUESTER_EMAIL}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                    const message = document.getElementById(`${ID.MESSAGE}`).value;
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const url = '/bin/microsites/contact-us';
                    const dataMap = new FormData();
                    dataMap.set('siteName', siteName);
                    dataMap.set('name', name);
                    dataMap.set('title', title);
                    dataMap.set('dealerCode', dealerCode);
                    dataMap.set('requesterEmail', requesterEmail);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('message', message);
                    dataMap.set('form', form);
                    dataMap.set('captcha', captchaResponse);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.ContactUsAutohausUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.ContactUsAutohausUtil.displayPopUp('Form submission successful');
                            form.reset();
                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsAutohausUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form__error-field">This field is required.</span>';
                    this.ContactUsUtil.validateAllFields();
                }
            }
        });
    }
}
