import { EVENTS, CLASSES } from 'Constants';

const ID = {
    CONTENT: 'content'
};

export default class NCURemoveModalPopUp {
    constructor(element) {
        this.element = element;
        this.init();
        this.modalPopUp = null;
        this.modalOpenButton = null;
        this.closeButtonList = null;
    }

    init() {
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.closeButtonList = document.getElementsByClassName(`${CLASSES.POPUP__CONTENT_CLOSE}`);
        this.modalPopUpList = document.getElementsByClassName(`${CLASSES.POPUP}`);
    }

    attachEvents() {
        const modalPopUpListItem = this.modalPopUpList;

        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.classList.contains(`${CLASSES.MODAL__BUTTON}`)) {
                const popUpElement = e.target.parentNode.nextElementSibling;
                popUpElement.classList.add(`${CLASSES.POPUP_OPEN}`);
            }
        });

        [].forEach.call(this.closeButtonList, (el) => {
            el.addEventListener(EVENTS.CLICK, () => {
                [].forEach.call(modalPopUpListItem, (element) => {
                    element.classList.remove(`${CLASSES.POPUP_OPEN}`);
                });
            });
        });
    }
}
