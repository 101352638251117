import React from 'react';
import { render } from 'react-dom';
import EmailUsFormComponent from '../partials/EmailUsForm/components/EmailUsFormComponent';

export default class EmailUsForm {
    constructor(element) {
        this.element = element;
        this.init();
    }
    init() {
        render(<EmailUsFormComponent />, this.element);
    }
}
