import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const ID = {
    SUBMITBUTTON: 'dogContactFormSubmit',
    DOG_CONTACT_US: 'dog-contact-us',
    SITE_NAME: 'site-name',
};

export default class ContactUsDOG {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.country = window.microsites.pageData.country;
        this.language = window.microsites.pageData.language;
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                if (this.ContactUsUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.DOG_CONTACT_US}`);
                    const inputs = form.elements;

                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const url = `/bin/microsites/dog/ask-question/sendEmail?country=${this.country}&lang=${this.language}`;
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success === 'true') {
                            this.ContactUsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });
    }
}
