import React, { Component } from 'react';
import { ModalComponent as Modal } from 'partials/modal/react';

class UserFeedbackModal extends Component {
    constructor(props) {
        super(props);

        this.modal = null;
        this.closeModal = this.closeModal.bind(this);
        
    }

    openModel() {
        this.modal.open();
    }

    closeModal() {
        this.modal.close();
       
    }

   render() {
        const {
            heading,message,okButton
        } = this.props;

        return (
            <Modal sizeSmall={Modal.SIZES.FULLSCREEN}
                sizeLarge={Modal.SIZES.DIALOG}
                ref={(modal) => { this.modal = modal; }}
                
            >
                <div className="order-confirmation-modal">
                    <h1 className="title-hero__title order-confirmation-modal__heading">
                        {heading}
                    </h1>

                    <div
                        className='title-hero__sub-heading order-confirmation-modal__sub-heading'
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                    <div className='order-confirmation-modal__action'>
                        <button
                            onClick={this.closeModal}
                            className='button
                            button_primary
                            button--full-width'>
                            {okButton}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default UserFeedbackModal;
