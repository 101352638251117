import { html } from 'utils';

function checkColumn(item, key) {
    if (key === 'enrollmentDate') {
        const date = new Date(item[key]);
        return `<td>${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}</td>`;
    }
    return `<td>${item[key]}</td>`;
}

export default (items = []) => html`
${items.map((item, index) => `
<tr>
    <td>
    <input id="${index}" type="radio" name="status" value="${item.canNumber}" ${index === 0 ? 'checked' : ''}><label for="active"></label>
    </td>${Object.keys(item).map(key => `
${checkColumn(item, key)}`).join('')}</tr>`).join('')}`;
