import { EVENTS } from 'Constants';

const CLASS = {
    AIRBAG_AVAILABILITY_CONTROL: 'airbag-availability__control',
    AIRBAG_AVAILABILITY_CONTROL_ERROR: 'airbag-availability__control--error',
    AIRBAG_AVAILABILITY_CONTROL_SUCCESS: 'airbag-availability__control--success',
    AIRBAG_AVAILABILITY_ERROR_FIELD_HIDDEN: 'airbag-availability__error-field--hidden'
};

export default class AirbagAvailabilityUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.AIRBAG_AVAILABILITY_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.AIRBAG_AVAILABILITY_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.AIRBAG_AVAILABILITY_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.AIRBAG_AVAILABILITY_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.AIRBAG_AVAILABILITY_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.AIRBAG_AVAILABILITY_CONTROL}`);

        this.validateFields(inputFields);

        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.AIRBAG_AVAILABILITY_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
    }
}
