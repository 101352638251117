/**
 * Template for dog vehicle bodystyle explorer
 * Uses ES6 template literal to composite the markup for a dog vehicle bodystyle explorer container
 * @param summary {Array} the array of dog vehicle bodystyle explorer items
 */
import { html } from 'utils';
import TableColumnData from './table-column';
import KeepMeUpdated from './keep-me-updated';

const CONSTANTS = {
    PASSENGER_CAR_DOMAIN: 'mbusa',
    VANS_CAR_DOMAIN: 'mbvans',
    FL_CAR_DOMAIN: 'freightlinersprinterusa',
    VANS_CAR_DOMAIN_CA: 'mercedes-benz-vans',
    PASSENGER_CAR_DOMAIN_CA: 'mercedes-benz'
};

function siteDomainCheck(item, siteDomain, redirectSiteDomain, content) {
    if (item.brand.toLowerCase() === 'null') {
        item.brand = 'Mercedes-Benz';
    }

    if (siteDomain !== redirectSiteDomain) {
        return `
            <div class="bluetecupdate-form__redirect-link-container">
                <p class="bluetecupdate-form__message">${content.redirectMessage} <a class="bluetecupdate-form__redirect-link" href = "${window.location.href.replace(siteDomain, redirectSiteDomain)}" target = "_self">${content.clickHere}</a></p>
            </div>`;
    }

    return `<div class="bluetecupdate-form__table-container">
                    <div class="bluetecupdate-form__table">
                        <div class="bluetecupdate-form__table-heading-row">
                            <div class="bluetecupdate-form__table-heading">${item.vin}&nbsp;${item.modelYear}&nbsp;${item.brand}&nbsp;${item.model}</div>
                            <div class="bluetecupdate-form__table-heading">${content.status}</div>
                        </div>
                        ${TableColumnData(item.summary, item.emissionModificationCompletionDetail, item.extendedEmissionsWarrantyDetail, item.campaignStatus, content)}
                    </div>
                </div>
                ${KeepMeUpdated(item, Object.keys(item.summary).length, content)}`;
}

function brandCheck(item, content) {
    let currentDomain = '';
    if (window.location.host.indexOf(`${CONSTANTS.PASSENGER_CAR_DOMAIN}`) > 0) {
        currentDomain = `${CONSTANTS.PASSENGER_CAR_DOMAIN}`;
    }

    if (window.location.host.indexOf(`${CONSTANTS.VANS_CAR_DOMAIN}`) > 0) {
        currentDomain = `${CONSTANTS.VANS_CAR_DOMAIN}`;
    }

    if (window.location.host.indexOf(`${CONSTANTS.FL_CAR_DOMAIN}`) > 0) {
        currentDomain = `${CONSTANTS.FL_CAR_DOMAIN}`;
    }

    if (window.location.host.indexOf(`${CONSTANTS.VANS_CAR_DOMAIN_CA}`) > 0) {
        currentDomain = `${CONSTANTS.VANS_CAR_DOMAIN_CA}`;
    } else if (window.location.host.indexOf(`${CONSTANTS.PASSENGER_CAR_DOMAIN_CA}`) > 0) {
        currentDomain = `${CONSTANTS.PASSENGER_CAR_DOMAIN_CA}`;
    }

    if (item.brand) {
        if (content.country.toLowerCase() === 'us') {
            if (item.brand.toLowerCase() === 'null') {
                return siteDomainCheck(item, currentDomain, `${CONSTANTS.PASSENGER_CAR_DOMAIN}`, content);
            }

            if (item.brand.toLowerCase() === 'freightliner') {
                return siteDomainCheck(item, currentDomain, `${CONSTANTS.FL_CAR_DOMAIN}`, content);
            }

            if (item.brand.toLowerCase() === 'mercedes-benz') {
                return siteDomainCheck(item, currentDomain, `${CONSTANTS.VANS_CAR_DOMAIN}`, content);
            }
        } else {
            if (item.brand.toLowerCase() === 'null') {
                return siteDomainCheck(item, currentDomain, `${CONSTANTS.PASSENGER_CAR_DOMAIN_CA}`, content);
            }
            return siteDomainCheck(item, currentDomain, `${CONSTANTS.VANS_CAR_DOMAIN_CA}`, content);
        }
    }

    return `
            <div class="bluetecupdate-form__redirect-link-container">
                <p class="bluetecupdate-form__message">${content.invalidVin}</p>
            </div>`;
}

export default (item, content) => html`
    ${brandCheck(item, content)}
`.trim();
