/**
 * Template for dog vehicle bodystyle explorer
 * Uses ES6 template literal to composite the markup for a dog vehicle bodystyle explorer container
 * @param summary {Array} the array of dog vehicle bodystyle explorer items
 */
import { html } from 'utils';
import TableHead from './table-head';
import TableRow from './table-row';

export default item => html`
    <table>
        <caption>CAN Action History</caption>
        <thead>
            <tr>
                ${TableHead(item[0])}
            </tr>
        </thead>
        <tbody class="list">
            ${TableRow(item)}
        </tbody>
    </table>
`.trim();
