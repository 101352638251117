import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const ID = {
    SUBMITBUTTON: 'mbmLeaseFormDataMigSubmit',
    SITE_NAME: 'site-name',
    LEASEREPORT: 'mbmLease-fileID',
    MBM_LEASE_DATA_MIGRATION: 'mbm-lease-form'
};

export default class MbmLeaseDataMigration {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }
    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                if (this.ContactUsUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.MBM_LEASE_DATA_MIGRATION}`);
                    const inputs = form.elements;

                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;

                    const url = '/bin/microsites/mbm-lease-migration';

                    const file = document.getElementById(`${ID.LEASEREPORT}`).files[0];

                    const fileData = new FormData();

                    fileData.set('file', file);
                    fileData.set('siteName', siteName);

                    this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                        if (success === 'true') {
                            this.ContactUsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsUtil.displayPopUp('Form submission failed');
                        }
                    });
                }
            }
        });
    }
}
