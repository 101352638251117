import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import EmpAuthRequestUtil from 'modules/EmpAuthRequestUtil';
const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'emp-auth-form',
    SITE_NAME: 'site-name',
    EMPLOYEE_NAME: 'employeeName',
    EMAIL: 'email',
    TERMS_TRAIN_AGREE: 'terms-train-agree',
    TERMS_CHECKBOX: 'terms',
    SIGN_NAME: 'signName',
    SIGNED_DATE: 'signedDate',
};

export default class EmpAuthRequest {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'emp-auth';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.EmpAuthRequestUtil = new EmpAuthRequestUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const capRes = grecaptcha.getResponse();
                const termsAgree = document.getElementById(`${ID.TERMS_TRAIN_AGREE}`);
                const termsElement = document.getElementById(`${ID.TERMS_CHECKBOX}`);
                if (this.EmpAuthRequestUtil.validateAllFields() && capRes.length !== 0
                    && termsElement.checked === true) {
                    termsAgree.classList.add('emp-auth-form__error-field--hidden');
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('Inside final if');
                    const siteName = 'empAuth';
                    const url = '/bin/microsites/emp-auth/sendEmail';
                    const employeeName = document.getElementById(`${ID.EMPLOYEE_NAME}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const signname = document.getElementById(`${ID.SIGN_NAME}`).value;
                    const signeddate = document.getElementById(`${ID.SIGNED_DATE}`).value;


                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('employeeName', employeeName);
                    dataMap.set('email', email);
                    dataMap.set('signname', signname);
                    dataMap.set('signeddate', signeddate);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.EmpAuthRequestUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.EmpAuthRequestUtil.displayPopUp('Form submission successful');
                            form.reset();
                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.EMP_AUTH_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.EMP_AUTH_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.EmpAuthRequestUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="emp-auth-form__error-field">This field is required.</span>';
                    this.EmpAuthRequestUtil.validateAllFields();
                } else {
                    termsAgree.classList.remove('emp-auth-form__error-field--hidden');
                }
            }
        });
    }
}
