import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import NCUFieldCastFormUtil from 'modules/NCUFieldCastFormUtil';


const ID = {
    SITE_NAME: 'site-name',
    SEARCH: 'search',
    DISPLAY_RESULT: 'mblease-listing',
    NOT_FOUND_RESULT: 'no-data-found',
    PAGIN: 'pagin',
    SHOWING: 'showing',
    POPUP_ID: 'pop-up-id',
    SUBMISSION_STATUS: 'submissionStatus',
    CONTENT: 'content',
    DOWNLOAD: 'download',
    COMMENTSUBMIT: 'commentSubmit',
    COMMENTTEXT: 'commentText',
    DELETEENTRY: 'deleteEntry'
};

let displayData = '';
// const dashboardResult = '';
// const index = 0;


export default class NCUSubmissionReport {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.ajaxcall();
    }


    loadModules() {
        this.NCUFieldCastFormUtil = new NCUFieldCastFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }


    ajaxcall() {
        document.getElementById('ncu-submission-report').style.display = 'none';
        const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        const url = '/bin/microsites/ncu-submission-report';
        const data = {
            siteName,
        };

        this.Ajax.ajaxPostFn(url, data, (success) => {
            setTimeout(() => {
                const reportList = JSON.parse(success);
                if (reportList.error !== 'NO_DATA_FOUND' && success !== false) {
                    const reportListNew = JSON.parse(reportList);
                    displayData = reportListNew;

                    document.getElementById('loader').style.display = 'none';
                    // document.getElementById('mblease-listing-loader').style.display = 'none';
                    document.getElementById('ncu-submission-report').style.display = 'block';
                    document.getElementById('ncu-submission-report').style.width = '100%';
                    this.displayDetailsPage(displayData);
                } else {
                    document.getElementById('loader').style.display = 'none';
                    document.getElementById('ncu-submission-report').style.display = 'block';
                    document.getElementById('ncu-submission-report').style.width = '100%';
                    const resultDisplay = document.getElementById(`${ID.NOT_FOUND_RESULT}`);
                    resultDisplay.innerHTML = '<p class="ncu-submission-report__no-record">No records found!</p>';
                }
            }, 5000);
        });
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.COMMENTSUBMIT}`)) {
                e.preventDefault();
                const position = e.target.getAttribute('data-position');
                const status = document.getElementById(`${ID.SUBMISSION_STATUS}${position}`).value;
                const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                const updateUrl = '/bin/microsites/ncu/field-cast-submission/sendEmail';
                const requesterEmail = e.target.getAttribute('data-ref');
                const commenttext = document.getElementById(`${ID.COMMENTTEXT}${position}`).value;
                const d = new Date();
                let month = `${d.getMonth() + 1}`;
                let day = `${d.getDate()}`;
                const year = d.getFullYear();
                if (month.length < 2) { month = `0${month}`; }
                if (day.length < 2) { day = `0${day}`; }
                const statusDate = [month, day, year].join('-');
                const updateData = {
                    status,
                    position,
                    requesterEmail,
                    siteName,
                    statusDate,
                    commenttext
                };
                document.getElementById(`${ID.COMMENTSUBMIT}`).disabled = true;
                document.getElementById('loader').style.display = 'block';
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    document.getElementById('loader').style.display = 'none';
                    if (success !== 'false') {
                        if (status === 'Pending') {
                            this.NCUFieldCastFormUtil.displayPopUp('Request pending email sent to user');
                        } else if (status === 'Approve') {
                            this.NCUFieldCastFormUtil.displayPopUp('Request approved email sent to user');
                           // document.activeElement.setAttribute('disabled', 'disabled');
                        } else {
                            this.NCUFieldCastFormUtil.displayPopUp('Request denied email sent to user');
                           // document.activeElement.setAttribute('disabled', 'disabled');
                        }
                    }
                    document.getElementById(`${ID.COMMENTSUBMIT}`).disabled = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                });
            }
        });
        
        document.addEventListener(EVENTS.CLICK, (e) => {
             if (e.target.matches(`#${ID.DELETEENTRY}`)) {
                 const position = e.target.getAttribute('data-position');
                 const updateUrl = '/bin/microsites/ncu-delete-entry';
                 console.log(position);
                 const deleteData = {
                      position,
                 };
                 
                 this.NCUFieldCastFormUtil.displayPopUpRemove('Are you sure you want to delete this entry?');
                 
                 document.getElementById('RemoveEntry').addEventListener(EVENTS.CLICK, (e) => { 
                 
                     this.Ajax.ajaxPostFn(updateUrl, deleteData, (success) => {
                        if (success === 'true') {
                            window.location.reload();
                        }

                     });
                 });
             }
        });


        document.addEventListener(EVENTS.CLICK, (e) => {
            console.log(this);
            if (e.target && e.target.matches(`#${ID.DOWNLOAD}`)) {
                e.preventDefault();
                const position = e.target.getAttribute('data-position');
                const fileName = e.target.innerText;
                const updateUrl = '/bin/microsites/ncu/field-cast-submission/download';
                const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                const updateData = {
                    position,
                    fileName,
                    siteName
                };
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    if (success !== 'false') {
                        const params = typeof updateData === 'string' ? updateData : Object.keys(updateData).map(
                             k => `${encodeURIComponent(k)}=${encodeURIComponent(updateData[k])}`
                         ).join('&');
                        this.postAjax.open('POST', updateUrl, true);
                        this.postAjax.responseType = 'arraybuffer';
                        this.postAjax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        this.postAjax.onreadystatechange = () => {
                            if (this.postAjax.readyState === 4 &&
                                 this.postAjax.status === 200) {
                                let filename = '';
                                const arrayBuffer = this.postAjax.response;
                                const byteArray = new Uint8Array(arrayBuffer);
                                let blob = new Blob([byteArray], { type: 'text/zip' });
                                if (blob.size > 1) {
                                    if (!document.documentMode && !/Edge/.test(navigator.userAgent)) {
                                        const type = this.postAjax.getResponseHeader('Content-Type');
                                        const disposition = this.postAjax.getResponseHeader('Content-Disposition');
                                        if (disposition && disposition.indexOf('attachment') !== -1) {
                                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                            const matches = filenameRegex.exec(disposition);
                                            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                                        }
                                        if (typeof File === 'function') {
                                            blob = new File([blob], filename, { type });
                                        }
                                    }
                                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                        window.navigator.msSaveBlob(blob, filename);
                                    } else {
                                        const URL = window.URL || window.webkitURL;
                                        const downloadUrl = URL.createObjectURL(blob);
                                        if (filename) {
                                         // use HTML5 a[download] attribute to specify filename
                                            const a = document.createElement('a');

                                         // safari doesn't support this yet
                                            if (typeof a.download === 'undefined') {
                                                window.location = downloadUrl;
                                            } else {
                                                a.href = downloadUrl;
                                                a.download = filename;
                                                document.body.appendChild(a);
                                                a.click();
                                            }
                                        } else {
                                            window.open(downloadUrl, '_blank');
                                        }
                                    }
                                } else {
                                    this.NCUFieldCastFormUtil.displayPopUp('No assets available to download');
                                }
                            }
                        };
                        this.postAjax.send(params);
                    }
                });
            }
        });
    }


    displayDetailsPage(data) {
        console.log(this);
        const doubleArray = data;
        let htmlBuild = '';
        const resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
        const entriesPerPage = doubleArray.length;
        for (let key = 0; key < entriesPerPage; key += 1) {
            const fieldCastRecord = JSON.parse(doubleArray[key]);
            const email = fieldCastRecord.requesterEmail;
            const topic = fieldCastRecord.topic;
            const status = fieldCastRecord.status;
            const fileName = fieldCastRecord.filename;
            const position = fieldCastRecord.position;
            const name = fieldCastRecord.name;
            const title = fieldCastRecord.title;
            const body = fieldCastRecord.body;
            const expDate = fieldCastRecord.expirationDate;
            let commentText = fieldCastRecord.commenttext;
            if (commentText === undefined) {
                commentText = 'NA';
            }
            if (fileName === undefined) {
                htmlBuild += `<tr role="row" id="reportRow" class="ncu-submission-report__border_rule">
                    <td colspan="1" class="ncu-submission-report__td_padding">${name}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${email}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${topic}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${title}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${body}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${expDate}</td>
                    <td colspan="1" class="brc-field-cast-report__td_padding" >Unavailable</td>
                    <td colspan="1" class="ncu-submission-report__td_padding"><select name="submissionStatus" id="submissionStatus${position}" data-ref="${email}" data-position="${position}" class="ncu-submission-report__control" style="cursor: pointer;"><option value="Pending">Pending</option><option value="Approve">Approve</option><option value="Deny">Deny</option></select></td>
                    <td colspan="1" class="ncu-submission-report__td_padding"><input type="text" id="commentText${position}" placeholder="Add Comment" style="width: -webkit-fill-available;text-align: center;"></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="submit" id="commentSubmit"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Submit" style="margin: inherit;cursor: pointer;">Submit</button></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${commentText}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="button" id="deleteEntry"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Remove" style="background-color: #37556c; cursor: pointer; margin: inherit;">Remove</button></td>
                  </tr>`;
            } else if ((fileName !== undefined)) {
                if (status === 'APPROVED') {
                    htmlBuild += `<tr role="row" id="reportRow" class="ncu-submission-report__border_rule">
                    <td colspan="1" class="ncu-submission-report__td_padding">${name}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${email}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${topic}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${title}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${body}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${expDate}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >`;
                    for (let i = 0; i < fieldCastRecord.filename.length; i += 1) {
                        htmlBuild +=
                        `<a href="${fieldCastRecord.filename[i]}" name="download" data-position="${position}" id="download">${fieldCastRecord.filename[i].substr(fieldCastRecord.filename[i].indexOf('_') + 1)}</a>`;
                    }
                    htmlBuild += `</td><td colspan="1" class="ncu-submission-report__td_padding"><select name="submissionStatus" id="submissionStatus${position}" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="ncu-submission-report__control" style="cursor: pointer;" ><option value="Pending">Pending</option><option selected value="Approve">Approved</option><option value="Deny">Deny</option></select></td>
                    <td colspan="1" class="ncu-submission-report__td_padding"><input type="text" id="commentText${position}" placeholder="Add Comment" style="width: -webkit-fill-available;text-align: center;"></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="submit" id="commentSubmit"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Submit" style="margin: inherit;cursor: pointer;">Submit</button></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${commentText}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="button" id="deleteEntry"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Remove" style="margin: inherit; background-color: #37556c; cursor: pointer;">Remove</button></td>
                    </tr>`;
                } else if (status === 'DENIED') {
                    htmlBuild += `<tr role="row" id="reportRow" class="ncu-submission-report__border_rule">
                    <td colspan="1" class="ncu-submission-report__td_padding">${name}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${email}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${topic}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${title}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${body}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${expDate}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >`;
                    for (let i = 0; i < fieldCastRecord.filename.length; i += 1) {
                        htmlBuild +=
                        `<a href="${fieldCastRecord.filename[i]}" name="download" data-position="${position}" id="download">${fieldCastRecord.filename[i].substr(fieldCastRecord.filename[i].indexOf('_') + 1)}</a>`;
                    }
                    htmlBuild += `</td><td colspan="1" class="ncu-submission-report__td_padding"><select name="submissionStatus" id="submissionStatus${position}" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="ncu-submission-report__control" style="cursor: pointer;"><option value="Pending">Pending</option><option value="Approve">Approve</option><option selected value="Deny">Denied</option></select></td>
                    <td colspan="1" class="ncu-submission-report__td_padding"><input type="text" id="commentText${position}" placeholder="Add Comment" style="width: -webkit-fill-available;text-align: center;"></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="submit" id="commentSubmit"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Submit" style="margin: inherit;cursor: pointer;">Submit</button></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${commentText}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="button" id="deleteEntry"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Remove" style="margin: inherit; background-color: #37556c; cursor: pointer;">Remove</button></td>
           </tr>`;
                } else {
                    htmlBuild += `<tr role="row" id="reportRow" class="ncu-submission-report__border_rule">
                    <td colspan="1" class="ncu-submission-report__td_padding">${name}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${email}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${topic}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${title}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${body}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding">${expDate}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >`;
                    for (let i = 0; i < fieldCastRecord.filename.length; i += 1) {
                        htmlBuild +=
                        `<a href=# name="download" data-position="${position}" id="download">${fieldCastRecord.filename[i].substr(fieldCastRecord.filename[i].indexOf('_') + 1)}</a>`;
                    }
                    htmlBuild += `</td><td colspan="1" class="ncu-submission-report__td_padding"><select name="submissionStatus" id="submissionStatus${position}" data-ref="${email}" data-position="${position}" file-name="${fileName}"class="ncu-submission-report__control" style="cursor: pointer;" ><option selected value="Pending">Pending</option><option value="Approve">Approve</option><option value="Deny">Deny</option></select></td>
                    <td colspan="1" class="ncu-submission-report__td_padding"><input type="text" id="commentText${position}" placeholder="Add Comment" style="width: -webkit-fill-available;text-align: center;"></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="submit" id="commentSubmit"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Submit" style="margin: inherit;cursor: pointer;">Submit</button></td>
                    <td colspan="1" class="ncu-submission-report__td_padding" >${commentText}</td>
                    <td colspan="1" class="ncu-submission-report__td_padding" ><button type="button" id="deleteEntry"  data-ref="${email}" data-position="${position}" file-name="${fileName}" class="button button_primary" value="Remove" style="margin: inherit;background-color: #37556c;cursor: pointer;">Remove</button></td>
                    </tr>`;
                }
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }
}
