export default {
    inquiryType: {
        value: '',
        isTouched: false,
        isValid: false,
        errors: [],
    },
    commentBody: {
        value: '',
        isTouched: false,
        isValid: true,
        errors: [],
    },
    firstName: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    lastName: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    phone: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    email: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    address: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    city: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    state: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    zipCode: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
    vin: { value: '', isValid: true, isTouched: false, isFocused: false },
    mileage: {
        value: '',
        isTouched: false,
        isFocused: false,
        isValid: false,
        errors: [],
    },
};
