import Ajax from 'modules/Ajax';

export default class AddWorkflowUser {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
        this.callAjax = false;
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.addUserToGroup();
    }

    cacheDOMElement() {
        this.elementList = document.getElementsByTagName('a');
        for (let a = 0; a < this.elementList.length; a += 1) {
            if (this.elementList[a].href.includes('/bin/microsites/brc/watermarkPdf')) {
                this.callAjax = true;
                break;
            }
        }
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    addUserToGroup() {
        if (this.callAjax) {
            const servletUrl = '/bin/microsites/mercedes-market/get-asset-share-user';
            const feature = 'watermark';
            const data = {
                feature,
            };
            this.Ajax.ajaxPostFn(servletUrl, data, (success) => {
                if (success === 'true') {
                    console.log('User added to the Watermark workflow group');
                } else {
                    console.log('User not added to the group');
                }
            });
        }
    }
}
