import { EVENTS } from 'Constants';
import FilterByCategory from './FilterByCategory';

const ID = {
    SITE_NAME: 'site-name'
};

const CLASSES = {
    CATEGORY_FILTER_ITEM: 'category--filters-list-item',
    VEHICLE_MODEL__DROPDOWN__TITLE: 'vehicle-model__dropdown__title',
    ICON_CARET_DROPDOWN_SMALL: 'icon-caret-down-small',
    VEHICLE_MODEL__DROPDOWN: 'vehicle-model__dropdown',
    VEHICLE_MODEL__DROPDOWN_OPEN: 'vehicle-model__dropdown--open',
    VEHICLE_MODEL__DROPDOWN_MENU: 'vehicle-model__dropdown__menu'
};

export default class VehicleModel {
    constructor(element) {
        this.element = element;
        this.dropDownMenuList = null;
        this.init();
    }
    init() {
        this.setUrl();
        this.attachEvents();
    }

    setUrl() {
        const siteName = document.querySelector(`#${ID.SITE_NAME}`).value;
        const url = `/bin/microsites/collision-centers/getVehicles?siteName=${siteName}`;
        this.loadModules();
        this.FilterByCategory.setUrl(url, 'getVehicles');
    }

    loadModules() {
        this.FilterByCategory = new FilterByCategory(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASSES.VEHICLE_MODEL__DROPDOWN__TITLE}`) || e.target.matches(`.${CLASSES.ICON_CARET_DROPDOWN_SMALL}`)) {
                const dropdownContainer = e.target.parentElement;
                console.log(dropdownContainer);
                if (e.target.parentElement.matches(`.${CLASSES.VEHICLE_MODEL__DROPDOWN}`)) {
                    this.toggleDropDown(e.target.parentElement);
                } else if (e.target.parentElement.parentElement.matches(`.${CLASSES.VEHICLE_MODEL__DROPDOWN}`)) {
                    this.toggleDropDown(e.target.parentElement.parentElement);
                }
            } else if (!(e.target.matches('.vehicle-model__dropdown__link')) && !(e.target.parentElement.matches('.vehicle-model__dropdown__link'))) {
                this.hideAllDropDown();
            }
        });
    }

    toggleDropDown(dropDown) {
        const menu = dropDown.querySelector(`.${CLASSES.VEHICLE_MODEL__DROPDOWN_MENU}`);
        if (menu) {
            if (menu.matches(`.${CLASSES.VEHICLE_MODEL__DROPDOWN_OPEN}`)) {
                menu.classList.remove(`${CLASSES.VEHICLE_MODEL__DROPDOWN_OPEN}`);
            } else {
                this.hideAllDropDown();
                menu.classList.add(`${CLASSES.VEHICLE_MODEL__DROPDOWN_OPEN}`);
            }
        }
    }

    hideAllDropDown() {
        this.dropDownMenuList = document.querySelectorAll(`.${CLASSES.VEHICLE_MODEL__DROPDOWN_MENU}`);
        [].forEach.call(this.dropDownMenuList, (element) => {
            element.classList.remove(`${CLASSES.VEHICLE_MODEL__DROPDOWN_OPEN}`);
        });
    }
}
