import { EVENTS } from 'Constants';
import 'smoothscroll-anchor-polyfill/dist';
import smoothscroll from 'smoothscroll-polyfill/dist/smoothscroll';
import ESAPI from 'node-esapi';

const CLASSES = {
    ACTIVE: 'page-sticky-nav__item--active',
    OPEN: 'page-sticky-nav__item--open',
    NAV: 'page-sticky-nav__container',
    NAV_OPEN: 'page-sticky-nav__open',
    NAV_BUTTON: 'page-sticky-nav__button',
    STICKY: 'page-sticky',
    NAV_ITEMS: 'page-sticky-nav__item',
    NAV_LINK: 'page-sticky-nav__item-link',
    NAV_LIST: 'page-sticky-nav__items-list',
    DROPDOWN: 'page-sticky-nav__dropdown',
    STYLING_DROPDOWN: 'page-sticky-nav__styling--drop-down',
    STYLING_DROPDOWN_ICON: 'page-sticky-nav__styling--icon-drop-down',
    STYLING_DROPDOWN_ICON_UPARROW: 'page-sticky-nav__styling--icon-drop-down_upArrow',
    DISPLAY_BLOCK: 'page-sticky-nav__styling--display-block',
    DISPLAY_NONE: 'page-sticky-nav__styling--display-none',
    DROPDOWN_MENU: 'page-sticky-nav__dropdown-menu',
    DROPDOWN_MENU_ITEM: 'page-sticky-nav__dropdown-item',
    SECTION_CONTAINER: 'page-sticky-nav__section-container',
    MOBILE_ACTIVE: 'active'
};

const ID = {
    NAV_BTN: 'page-sticky-nav__button'
};

export default class PageStickyNav {
    constructor(element) {
        this.element = element;
        this.isScrolling = '';
        this.init();
    }

    init() {
        smoothscroll.polyfill();
        this.cacheDOMElement();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.sectionId = [];
        this.navItems = document.querySelectorAll(`.${CLASSES.NAV_ITEMS}`);
        this.dropdownItems = document.querySelectorAll(`.${CLASSES.DROPDOWN}`);
        this.currentDropDown = null;
        this.sections = document.querySelectorAll(`.${CLASSES.SECTION_CONTAINER}`);
        for (let i = 0; i < this.sections.length; i += 1) {
            this.sectionId.push(this.sections[i].id);
        }
        this.element.querySelector(`.${CLASSES.NAV_LIST}`).children[0].classList.add(`${CLASSES.ACTIVE}`);
        this.navHeight = Math.round(document.querySelector(`.${CLASSES.NAV}`).offsetHeight / 100) * 100;
        this.selectedElement = '';
        document.querySelector('html').classList.add('smooth-scroll');
    }

    attachEvents() {
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                const parentDiv = document.querySelector('.page-sticky-nav__wrapper');
                const ulElement = document.querySelector('.page-sticky-nav__items-list');
                const buttonElement = document.createElement('button');
                buttonElement.id = 'page-sticky-nav__button';
                buttonElement.classList.add(`${CLASSES.NAV_BUTTON}`);
                buttonElement.innerHTML = ulElement.firstElementChild.firstElementChild.innerText;
                parentDiv.insertBefore(buttonElement, ulElement);
                if (location.hash) {
                    document.querySelector(`[href="${location.hash}"]`).click();
                }

                const stylingDropdownItems = document.querySelectorAll(`.${CLASSES.STYLING_DROPDOWN}`);
                [].forEach.call(stylingDropdownItems, (dropdownItem) => {
                    const nextElementSibling = dropdownItem.parentElement.nextElementSibling;
                    if (dropdownItem.previousElementSibling === null) {
                        dropdownItem.parentElement.parentElement.style.cssText = 'padding-top :0%';
                    }
                    if (nextElementSibling !== null) {
                        nextElementSibling.classList.remove(`${CLASSES.DISPLAY_BLOCK}`);
                        nextElementSibling.classList.add(`${CLASSES.DISPLAY_NONE}`);
                        nextElementSibling.style.cssText = 'margin :3%';
                    }
                });
            }
        };

        window.addEventListener(EVENTS.SCROLL, () => {
            if (window.pageYOffset <= this.element.top) {
                document.querySelector(`.${CLASSES.NAV}`).classList.remove(`${CLASSES.STICKY}`);
            } else {
                document.querySelector(`.${CLASSES.NAV}`).classList.add(`${CLASSES.STICKY}`);
            }

            if (document.querySelector(`.${CLASSES.OPEN}`)) {
                document.querySelector(`.${CLASSES.OPEN}`).classList.remove(`${CLASSES.OPEN}`);
            }

            clearTimeout(this.isScrolling);

            this.isScrolling = setTimeout(() => {
                this.scrollStop();
            }, 66);

            for (let i = 0; i < this.sections.length; i += 1) {
                const section = this.sections[i];
                if ((Math.ceil(section.getBoundingClientRect().top) +
                    section.offsetHeight) >= this.navHeight) {
                    if (document.querySelector(`.${CLASSES.ACTIVE}`)) {
                        document.querySelector(`.${CLASSES.ACTIVE}`).classList.remove(`${CLASSES.ACTIVE}`);
                    }
                    const selectedElement = document.querySelector(`.${CLASSES.NAV_LINK}[href="#${section.getAttribute('id')}"]`);

                    // change mobile button text
                    if (document.querySelector(`#${ID.NAV_BTN}`) && selectedElement) {
                        document.querySelector(`#${ID.NAV_BTN}`).innerHTML = selectedElement.innerHTML;
                    }

                    // adding data-href to selected element
                    if (selectedElement) {
                        selectedElement.setAttribute('data-href', selectedElement.getAttribute('href'));

                        if (!selectedElement.parentElement.parentElement.parentElement.classList.contains(`${CLASSES.DROPDOWN}`)) {
                            selectedElement.parentElement.classList.add(`${CLASSES.ACTIVE}`);
                            // Resetting dropdown text to initial
                            this.resetDropdownText();
                        } else {
                            selectedElement.parentElement.parentElement.parentElement.classList.add(`${CLASSES.ACTIVE}`);
                            // Change dropdown text
                            this.selectedElement = selectedElement;
                            this.changeDropdownText();
                        }
                    }
                    return;
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.NAV_BTN}`)) {
                e.target.classList.toggle(`${CLASSES.MOBILE_ACTIVE}`);
                if (window.pageYOffset <= this.element.top) {
                    document.querySelector(`.${CLASSES.NAV}`).classList.toggle(`${CLASSES.STICKY}`);
                } else {
                    document.querySelector(`.${CLASSES.NAV}`).classList.add(`${CLASSES.STICKY}`);
                }
                document.querySelector('body').classList.toggle(`${CLASSES.NAV_OPEN}`);
            }

            if (e.target.matches(`.${CLASSES.NAV_LINK}`)) {
                if (document.querySelector(`#${ID.NAV_BTN}`).classList.contains(`${CLASSES.MOBILE_ACTIVE}`)) {
                    if (!e.target.parentElement.classList.contains(`${CLASSES.DROPDOWN}`)) {
                        document.querySelector(`.${CLASSES.NAV}`).classList.remove(`${CLASSES.STICKY}`);
                        document.querySelector('body').classList.remove(`${CLASSES.NAV_OPEN}`);
                        document.querySelector(`#${ID.NAV_BTN}`).classList.remove(`${CLASSES.MOBILE_ACTIVE}`);
                    }
                }

                if (!e.target.parentElement.classList.contains(`${CLASSES.DROPDOWN_MENU_ITEM}`)) {
                    [].forEach.call(this.navItems, (navItem) => {
                        navItem.classList.remove(`${CLASSES.ACTIVE}`);
                    });
                    e.target.parentElement.classList.add(`${CLASSES.ACTIVE}`);
                }

                // Scroll to section
                if (!e.target.parentElement.classList.contains(`${CLASSES.DROPDOWN}`)) {
                    const selectedId = e.target.getAttribute('href');

                    document.querySelector(selectedId).scrollIntoView(true);

                    // sticky nav
                    if (!document.querySelector(`.${CLASSES.NAV}`).classList.contains(`${CLASSES.STICKY}`)) {
                        document.querySelector(`.${CLASSES.NAV}`).classList.add(`${CLASSES.STICKY}`);
                    }

                    if (document.querySelector(`.${CLASSES.DROPDOWN}`)) {
                        document.querySelector(`.${CLASSES.DROPDOWN}`).classList.remove(`${CLASSES.OPEN}`);
                    }
                } else {
                    e.preventDefault();
                    [].forEach.call(this.dropdownItems, (dropdownItem) => {
                        if (dropdownItem !== e.target.parentElement) {
                            dropdownItem.classList.remove(`${CLASSES.OPEN}`);
                        }
                    });
                    e.target.parentElement.classList.toggle(`${CLASSES.OPEN}`);
                }
            } else if (document.querySelector(`.${CLASSES.DROPDOWN}`)) {
                document.querySelector(`.${CLASSES.DROPDOWN}`).classList.remove(`${CLASSES.OPEN}`);
            }

            if (e.target.matches(`.${CLASSES.STYLING_DROPDOWN_ICON}`)) {
                const parentSibling = e.target.parentElement.nextElementSibling;
                const selectedId = e.target.parentElement.parentElement.id;
                this.closeDropdownSection();
                e.target.classList.remove(`${CLASSES.STYLING_DROPDOWN_ICON}`);
                e.target.classList.add(`${CLASSES.STYLING_DROPDOWN_ICON_UPARROW}`);
                parentSibling.classList.add(`${CLASSES.DISPLAY_BLOCK}`);
                parentSibling.classList.remove(`${CLASSES.DISPLAY_NONE}`);
                document.getElementById(selectedId).scrollIntoView(true);
                this.currentDropDown = e.target;
            } else if (e.target.matches(`.${CLASSES.STYLING_DROPDOWN_ICON_UPARROW}`)) {
                this.closeDropdownSection();
                this.currentDropDown = null;
            }
        });
    }

    closeDropdownSection() {
        if (this.currentDropDown !== null) {
            const sibling = this.currentDropDown.parentElement.nextElementSibling;
            sibling.classList.remove(`${CLASSES.DISPLAY_BLOCK}`);
            sibling.classList.add(`${CLASSES.DISPLAY_NONE}`);
            this.currentDropDown.classList.remove(`${CLASSES.STYLING_DROPDOWN_ICON_UPARROW}`);
            this.currentDropDown.classList.add(`${CLASSES.STYLING_DROPDOWN_ICON}`);
        }
    }

    changeDropdownText() {
        this.selectedElement.parentElement.parentElement.previousElementSibling.text =
        this.selectedElement.text;
        this.selectedElement.parentElement.parentElement.previousElementSibling.setAttribute('data-href', this.selectedElement.getAttribute('href'));
        this.selectedElement.parentElement.parentElement.parentElement.classList.remove(`${CLASSES.OPEN}`);
    }

    resetDropdownText() {
        [].forEach.call(this.dropdownItems, (dropdownItem) => {
            dropdownItem.children[0].text = dropdownItem.children[0].getAttribute('data-text');
            dropdownItem.children[0].setAttribute('data-href', '');
        });
    }

    scrollStop() {
        if (document.querySelector(`.${CLASSES.ACTIVE}`)) {
            let activeElement = document.querySelector(`.${CLASSES.ACTIVE}`).children[0].getAttribute('href');
            let url;
            if (location.hash) {
                const keyIndex = this.sectionId.indexOf(`${ESAPI.encoder().encodeForURL(location.hash).split('%23')[1]}`);
                if (keyIndex >= 0) {
                    url = `#${this.sectionId[keyIndex]}`;
                }

                // check if page bottom reached
                if (window.scrollY + window.innerHeight === document.body.scrollHeight) {
                    if (location.hash !== activeElement) {
                        activeElement = document.querySelector(`.${CLASSES.NAV_LINK}[href = '${url}']`);
                        const section = document.querySelector(`${url}`);
                        if (!activeElement.parentElement.parentElement.classList.contains(`${CLASSES.DROPDOWN_MENU}`)) {
                            if ((Math.ceil(section.getBoundingClientRect().top) +
                            section.offsetHeight) >= this.navHeight) {
                                document.querySelector(`.${CLASSES.ACTIVE}`).classList.remove(`${CLASSES.ACTIVE}`);
                                activeElement.parentElement.classList.add(`${CLASSES.ACTIVE}`);

                                // change mobile button text
                                if (document.querySelector(`#${ID.NAV_BTN}`) && activeElement) {
                                    document.querySelector(`#${ID.NAV_BTN}`).innerHTML = activeElement.innerHTML;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
