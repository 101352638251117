import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';
import CanApplicationPopupForm from 'modules/CanApplicationPopupForm';
import MessageBox from 'modules/fleet-info/MessageBox';
import Table from 'modules/Table';


/**
 * CLASSES
 */
const CLASSES = {
    CAN_APPLICATION_LEFT: 'canApplication__left',
    FORM_MESSAGE_CONTAINER: 'form__message-container',
    FORM_MESSAGE_ERROR: 'form__message--error',
    FORM_MESSAGE_SUCCESS: 'form__message--success',
    OVERLAY_HIDDEN: 'overlay--hidden',
    HIDDEN: 'hidden'
};

/**
 * IDs
 */
const ID = {
    PAGE_LOADER: 'page-loader',
    ACCOUNT_NUMBER_DD: 'accountNumberDD',
    FLEET_CUSTOMER_DD: 'fleetCustomersDD',
    FLEET_EMPLOYEE_PROGRAM_DD: 'fleetEmployeeProgramDD',
    COMPANY_NAME: 'companyName',
    CAN: 'can',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    ACCOUNT_NUMBER_CONTAINER: 'accountNumberContainer',
    COMPANY_NAME_CONTAINER: 'companyNameContainer',
    CAN_CONTAINER: 'canContainer',
    STATUS_CONTAINER: 'statusContainer',
    ADD_FEP: 'addFEP',
    REMOVE_FEP: 'removeFEP',
    SEARCH_RECORDS: 'searchRecords',
    CLEAR_RECORDS: 'clearRecords',
    SIN: 'sin',
    CAN_COMPANY_NAME: 'canCompanyName',
    OFFICER_FIRST_NAME: 'officerFirstName',
    OFFICER_LAST_NAME: 'officerLastName',
    DEALER_NAME: 'dealerName',
    VIEW_CAN_APPLICATION: 'viewCanApplication',
    TABLE_CONTAINER: 'table-container'
};

/**
 * APIs
 */
const API = {
    FLEET_CUSTOMERS: '/bin/microsites/fleet-info/can_account_names',
    FLEET_EMPLOYEE_PROGRAM_MEMBERS: '/bin/microsites/fleet-info/get-employee-program-members',
    FLEET_EMPLOYEE_PROGRAM_ADD: '/bin/microsites/fleet-info/add-to-employee-program',
    FLEET_EMPLOYEE_PROGRAM_REMOVE: '/bin/microsites/fleet-info/remove-from-employee-program',
    REGISTER_COMPANY: '/bin/microsites/fleet-info/register_company',
    CAN_APPLICATIONS: '/bin/microsites/fleet-info/can-applications',
    SPECIFIC_CAN_ACCOUNT_NAME: '/bin/microsites/fleet-info/specific_can_account_name'
};

/**
 * ELEMENTS
 */
const ELEMS = {
    INPUT: 'input'
};

/**
 * STRING_CONSTANTS
 */
const StringConstants = {
    BLANK: '',
    DISABLED: 'disabled',
    UPDATE: 'update',
    ERROR_OCCURED: 'An Error Occured',
    NO_RECORDS_FOUND: 'No Record Found',
    NO_EVENT_FOUND: 'No Event Found',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    ERROR: 'error',
    SUCCESS: 'success',
    FILL_FIELD_ERROR_MSG: 'Please fill out this field',
    ONE_SEARCH_FIELD_IS_REQUIRED: 'Please fill in at least one search field',
    CAN_APPLICATIONS: 'can-applications'
};

/**
 * ATTRIBUTES
 */
const ATTRS = {
    NAME: 'name',
    ID: 'id'
};

/**
 * Fleet Program module
 */
export default class FleetInfo {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.customersList = null;
        this.init();
    }

    /**
     * Init method
     */
    init() {
        this.cacheDOM();
        this.loadModules();
        this.attachEvents();
        this.fleetProgram();
    }

    /**
     * loadModules method
     */
    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.MessageBox = new MessageBox(this.element, this.messageContainer);
        this.CanApplicationPopupForm = new CanApplicationPopupForm(this.element,
            this.messageContainer, this.tableSection, this.buttonContainer,
            this.searchRecords1.bind(this));
    }

    /**
     * Cache DOM
     */
    cacheDOM() {
        this.pageLoader = document.getElementById(`${ID.PAGE_LOADER}`);
        this.messageContainer = document.querySelector(`.${CLASSES.FORM_MESSAGE_CONTAINER}`);

        this.accountNumberDD = document.getElementById(`${ID.ACCOUNT_NUMBER_DD}`);
        this.fleetCustomerDD = document.getElementById(`${ID.FLEET_CUSTOMER_DD}`);
        this.fleetEmployeeProgramDD = document.getElementById(`${ID.FLEET_EMPLOYEE_PROGRAM_DD}`);
        this.companyName = document.getElementById(`${ID.COMPANY_NAME}`);
        this.can = document.getElementById(`${ID.CAN}`);
        this.active = document.getElementById(`${ID.ACTIVE}`);
        this.inactive = document.getElementById(`${ID.INACTIVE}`);

        this.accountNumberContainer = document.getElementById(`${ID.ACCOUNT_NUMBER_CONTAINER}`);
        this.companyNameContainer = document.getElementById(`${ID.COMPANY_NAME_CONTAINER}`);
        this.canContainer = document.getElementById(`${ID.CAN_CONTAINER}`);
        this.statusContainer = document.getElementById(`${ID.STATUS_CONTAINER}`);
        this.radioAll = this.statusContainer.querySelectorAll(`${ELEMS.INPUT}[${ATTRS.NAME}=status]`);

        this.addFEP = document.getElementById(`${ID.ADD_FEP}`);
        this.removeFEP = document.getElementById(`${ID.REMOVE_FEP}`);
        this.searchRecords = document.getElementById(`${ID.SEARCH_RECORDS}`);
        this.clearRecords = document.getElementById(`${ID.CLEAR_RECORDS}`);
        this.sin = document.getElementById(`${ID.SIN}`);
        this.canCompanyName = document.getElementById(`${ID.CAN_COMPANY_NAME}`);
        this.officerFirstName = document.getElementById(`${ID.OFFICER_FIRST_NAME}`);
        this.officerLastName = document.getElementById(`${ID.OFFICER_LAST_NAME}`);
        this.dealerName = document.getElementById(`${ID.DEALER_NAME}`);

        this.manufacturarCanNo = document.getElementById(`${ID.MANUFACTURER_CAN_NO}`);
        this.manufacturarCanYes = document.getElementById(`${ID.MANUFACTURER_CAN_YES}`);

        this.tableContainer = document.querySelector(`#${ID.TABLE_CONTAINER}`);
    }

    /**
     * Attach Events
     */
    attachEvents() {
        this.accountNumberDD.addEventListener(EVENTS.CHANGE, (e) => {
            const opt = e.target.options[e.target.selectedIndex];
            let companyName = opt.getAttribute('data-corporateName');
            let can = opt.getAttribute('data-accountNumber');
            let status = opt.getAttribute('data-canStatus');
            const url = `${API.SPECIFIC_CAN_ACCOUNT_NAME}?can=${can}`;

            fetch(url)
                .then(this.handleErrors)
                .then(response => response.json())
                .then((data) => {
                    companyName = data.corporateName;
                    can = data.accountNumber;
                    status = data.canStatus;

                    this.MessageBox.hideMessage();
                    if (opt.value === 'Please Select') {
                        this.companyNameContainer.classList.add('hide');
                        this.canContainer.classList.add('hide');
                        this.statusContainer.classList.add('hide');
                    } else {
                        this.companyNameContainer.classList.remove('hide');
                        this.canContainer.classList.remove('hide');
                        this.statusContainer.classList.remove('hide');
                    }

                    this.companyName.innerHTML = companyName;
                    this.can.innerHTML = can;

                    if (status === 'Y') {
                        this.active.checked = true;
                        this.inactive.checked = false;
                    } else if (status === 'N') {
                        this.active.checked = false;
                        this.inactive.checked = true;
                    } else {
                        this.active.checked = false;
                        this.inactive.checked = false;
                    }
                })
                .catch((error) => {
                    this.MessageBox.showMessage(StringConstants.ERROR, error);
                });
        });

        this.radioAll.forEach((radio) => {
            radio.addEventListener(EVENTS.CHANGE, (e) => {
                let status = e.target.getAttribute(`${ATTRS.ID}`);
                const CANumber = this.can.innerHTML;
                let url = '';
                const fileData = new FormData();

                if (status === 'active') {
                    status = 'Y';
                    url = `${API.FLEET_EMPLOYEE_PROGRAM_ADD}`;
                } else if (status === 'inactive') {
                    status = 'N';
                    url = `${API.FLEET_EMPLOYEE_PROGRAM_REMOVE}`;
                }

                fileData.set('can', CANumber);

                this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
                this.MessageBox.hideMessage();
                this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                    const data = JSON.parse(success);

                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);

                    if (data.fepStatusUpdated === 'true') {
                        this.MessageBox.showMessage(StringConstants.SUCCESS, 'Status updated successfully.');
                        if (status === 'Y') {
                            this.updateAPIData(CANumber, 'Y');
                            this.fleetProgram();
                        } else if (status === 'N') {
                            this.updateAPIData(CANumber, 'N');
                            this.fleetProgram();
                        }
                    } else if (data.error) {
                        this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                        this.MessageBox.showMessage(StringConstants.ERROR, data.error);
                    }
                });
            });
        });

        this.addFEP.addEventListener(EVENTS.CLICK, () => {
            const url = `${API.FLEET_EMPLOYEE_PROGRAM_ADD}`;
            const fileData = new FormData();
            const options = this.fleetCustomerDD.options[this.fleetCustomerDD.selectedIndex];
            const optCAN = options.getAttribute('data-accountNumber');

            fileData.set('can', optCAN);

            this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
            this.MessageBox.hideMessage();
            this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                const data = JSON.parse(success);
                if (data.fepStatusUpdated === 'true') {
                    this.MessageBox.showMessage(StringConstants.SUCCESS, 'Status updated successfully.');
                    this.updateAPIData(optCAN, 'Y');
                    this.initFleetProgram();
                    this.fleetProgram();
                } else if (data.error) {
                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                    this.MessageBox.showMessage(StringConstants.ERROR, data.error);
                }
            });
        });

        this.removeFEP.addEventListener(EVENTS.CLICK, () => {
            const url = `${API.FLEET_EMPLOYEE_PROGRAM_REMOVE}`;
            const fileData = new FormData();
            const options = this.fleetEmployeeProgramDD
                .options[this.fleetEmployeeProgramDD.selectedIndex];
            const optCAN = options.getAttribute('data-accountNumber');

            fileData.set('can', optCAN);

            this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
            this.MessageBox.hideMessage();
            this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
                this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                const data = JSON.parse(success);

                if (data.fepStatusUpdated === 'true') {
                    this.MessageBox.showMessage(StringConstants.SUCCESS, 'Status updated successfully.');
                    this.updateAPIData(optCAN, 'N');
                    this.initFleetProgram();
                    this.fleetProgram();
                } else if (data.error) {
                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                    this.MessageBox.showMessage(StringConstants.ERROR, data.error);
                }
            });
        });

        this.searchRecords.addEventListener(EVENTS.CLICK, (e) => {
            e.preventDefault();
            this.searchRecords1();
        });

        this.clearRecords.addEventListener(EVENTS.CLICK, () => {
            this.sin.value = '';
            this.canCompanyName.value = '';
            this.officerFirstName.value = '';
            this.officerLastName.value = '';
            this.dealerName.value = '';
        });
    }

    initFleetProgram() {
        this.companyNameContainer.classList.add('hide');
        this.canContainer.classList.add('hide');
        this.statusContainer.classList.add('hide');
        this.accountNumberDD.value = 'Please Select';
    }

    /**
     * Fleet Employee Tab operations
     */
    fleetProgram() {
        const url = `${API.FLEET_CUSTOMERS}`;

        // this.accountNumberDD.innerHTML = '';
        this.fleetCustomerDD.innerHTML = '';
        this.fleetEmployeeProgramDD.innerHTML = '';

        // show the loader
        this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);

        if (this.customersList === null) {
            fetch(url)
                .then(this.handleErrors)
                .then(response => response.json())
                .then((data) => {
                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);

                    let errorMessage = data.Error;
                    if (!errorMessage) {
                        errorMessage = data.error;
                    }
                    if (errorMessage) {
                        this.MessageBox.showMessage(StringConstants.ERROR, errorMessage);
                    } else {
                        data.forEach((elem) => {
                            this.customersList = data;
                            this.appendOptions(elem);
                        });

                        setTimeout(() => {
                            this.fleetEmployeeProgram();
                        }, 100);

                        this.MessageBox.hideMessage();
                    }
                })
                .catch((error) => {
                    this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
                    this.MessageBox.showMessage(StringConstants.ERROR, error);
                });
        } else {
            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            this.customersList.forEach((elem) => {
                this.appendOptions(elem);
            });

            setTimeout(() => {
                this.fleetEmployeeProgram();
            }, 100);
        }
    }

    /**
     * Added Fleet Employee Program dropdown values
     */
    fleetEmployeeProgram() {
        this.customersList.forEach((elem) => {
            if (elem.fepIndicator === 'Y') {
                const corporateName = elem.corporateName;
                const fepIndicator = elem.fepIndicator;
                const accountNumber = elem.accountNumber;

                // create new option element
                const opt = document.createElement('option');
                // create text node to add to option element (opt)
                opt.appendChild(document.createTextNode(corporateName));
                // set value property of opt
                opt.value = accountNumber;
                opt.setAttribute('data-corporateName', corporateName);
                opt.setAttribute('data-accountNumber', accountNumber);
                opt.setAttribute('data-fepIndicator', fepIndicator);

                // add opt to Fleet Customers Dropdown
                this.fleetEmployeeProgramDD.appendChild(opt);
            }
        });
    }

    updateAPIData(accountNumber, status) {
        this.customersList.forEach((elem, index) => {
            if (elem.accountNumber === accountNumber) {
                this.customersList[index].fepIndicator = status;
            }
        });
    }

    /**
     * Added dropdown values from the API responses
     */
    appendOptions(elem) {
        const corporateName = elem.corporateName;
        const fepIndicator = elem.fepIndicator;
        const accountNumber = elem.accountNumber;

        // get reference to select element
        const sel = this.accountNumberDD;
        // create new option element
        const opt = document.createElement('option');
        // create text node to add to option element (opt)
        opt.appendChild(document.createTextNode(corporateName));
        // set value property of opt
        opt.value = corporateName;
        opt.setAttribute('data-corporateName', corporateName);
        opt.setAttribute('data-accountNumber', accountNumber);
        opt.setAttribute('data-fepIndicator', fepIndicator);

        // clone the option element
        const cln = opt.cloneNode(true);

        // add opt to Corporate Account Number Dropdown
        sel.appendChild(opt);

        if (elem.fepIndicator === 'Y') {
            cln.setAttribute('disabled', '');
        }

        // add opt to 'Fleet Customers' Dropdown
        this.fleetCustomerDD.appendChild(cln);
    }

    /**
     * Handle errors of API responses
     */
    handleErrors(response) {
        if (!response.ok) {
            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
            throw Error(response.statusText);
        }
        return response;
    }

    /**
     * Function : To display message when no records found.
     * @params
     * message - error message to be displayed.(default value passed)
     */
    showMessage(type, message = `${StringConstants.ERROR_OCCURED}`) {
        this.messageContainer = document.querySelector(`.${CLASSES.FORM_MESSAGE_CONTAINER}`);

        if (this.messageContainer != null) {
            this.messageContainer.innerHTML = message;
            this.messageContainer.classList.remove(`${CLASSES.HIDDEN}`);
        }

        if (type === `${StringConstants.SUCCESS}`) {
            this.messageContainer.classList.add(`${CLASSES.FORM_MESSAGE_SUCCESS}`);
        } else if (type === `${StringConstants.ERROR}`) {
            this.messageContainer.classList.add(`${CLASSES.FORM_MESSAGE_ERROR}`);
        }
    }

    /**
     * Function : To hide message when records found.
     */
    hideMessage() {
        if (this.messageContainer != null) {
            this.messageContainer.classList.add(`${CLASSES.HIDDEN}`);
            this.messageContainer.classList.remove(`${CLASSES.FORM_MESSAGE_SUCCESS}`);
            this.messageContainer.classList.remove(`${CLASSES.FORM_MESSAGE_ERROR}`);
        }
    }

    /**
     * Function : Search Records of the can application.
     */
    searchRecords1(type) {
        const sin = document.getElementById(`${ID.SIN}`).value;
        const canCompanyName = document.getElementById(`${ID.CAN_COMPANY_NAME}`).value;
        const officerFirstName = document.getElementById(`${ID.OFFICER_FIRST_NAME}`).value;
        const officerLastName = document.getElementById(`${ID.OFFICER_LAST_NAME}`).value;
        const dealerName = document.getElementById(`${ID.DEALER_NAME}`).value;
        const fileData = new FormData();
        const url = `${API.CAN_APPLICATIONS}`;

        fileData.set('sin', sin);
        fileData.set('companyName', canCompanyName);
        fileData.set('officerFirstName', officerFirstName);
        fileData.set('officerLastName', officerLastName);
        fileData.set('dealerName', dealerName);
        // show the loader
        this.pageLoader.classList.remove(`${CLASSES.OVERLAY_HIDDEN}`);
        this.Ajax.ajaxMultipartPostFn(url, fileData, (success) => {
            const data = JSON.parse(success);

            if (data.length) {
                this.tableContainer.classList.remove(`${CLASSES.HIDDEN}`);
            } else {
                this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                this.MessageBox.showMessage(StringConstants.ERROR, 'No Record Found');
            }
            this.tableContainer.innerHTML = '';

            if (!(data.error)) {
                data.map((el) => {
                    el.view = '<span id="viewCanApplication" class="icon-search"></span>';
                    return el;
                });
                this.Table = new Table(this.element, data, `${StringConstants.CAN_APPLICATIONS}`);

                if (type === undefined && data.length) {
                    this.MessageBox.hideMessage();
                }
            } else {
                this.tableContainer.classList.add(`${CLASSES.HIDDEN}`);
                this.MessageBox.showMessage(StringConstants.ERROR, data.error);
            }

            this.pageLoader.classList.add(`${CLASSES.OVERLAY_HIDDEN}`);
        });
    }
}
