import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import List from '../../node_modules/list.js';
import ListPagination from '../../node_modules/list.pagination.js/dist/list.pagination';
import SortableTable from '../templates/sortable-table';
import SortableTableRow from '../templates/sortable-table/table-row';

const CLASS = {
    SORT_ICON: 'icon',
    SORT_ICON_COLUMN: 'sort',
    PAGINATION_LAYOUT: 'pagination-layout',
    PAGINATION_PREV: 'pagination-prev',
    PAGINATION_NEXT: 'pagination-next',
    PAGINATION_WRAP: 'pagination-wrap',
    PAGINATION_INFO: 'pagination-info',
    ACTIVE: 'active',
    HIDDEN: 'hidden',
    DISABLED: 'disabled',
    OVERLAY_HIDDEN: 'overlay--hidden',
};

const ID = {
    TABLE_CONTAINER: 'table-container',
    RECORD_COUNT: 'recordCount',
    EXPORT_TO_EXCEL: 'exportToExcel',
    PAGE_LOADER: 'page-loader',
};

const Url = {
    downloadReport: '/bin/microsites/fleet-info/download/fepEab-report'
};

export default class Table {
    constructor(element, dataString, formType) {
        this.element = element;
        this.tableContainer = null;
        this.sortIconElement = null;
        this.nextPaginationBtn = null;
        this.prevPaginationBtn = null;
        this.paginationContainer = null;
        this.tableData = null;
        this.tableColumnHeadList = null;
        this.downloadReportBtns = null;
        this.perPage = 10;
        this.dataList = null;
        this.formType = formType;
        this.init();
        this.createTable(dataString);
    }

    init() {
        this.loadModules();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    // Function to add table structure to html .
    createTable(data) {
        this.tableData = data;
        this.tableContainer = document.querySelector(`#${ID.TABLE_CONTAINER}`);
        this.tableContainer.innerHTML = `${SortableTable(this.tableData, this.formType)}`;
        this.tableContainer.classList.remove(`${CLASS.HIDDEN}`);
        this.cacheTableElement();
        this.attachTableEvents();
    }

    cacheTableElement() {
        this.tableSortIcons = document.querySelectorAll(`.${CLASS.SORT_ICON}`);
        this.prevPaginationBtn = document.querySelector(`.${CLASS.PAGINATION_PREV}`);
        this.nextPaginationBtn = document.querySelector(`.${CLASS.PAGINATION_NEXT}`);
        this.paginationContainer = document.querySelector(`.${CLASS.PAGINATION_WRAP}`);
        this.downloadReportBtns = document.querySelector(`#${ID.EXPORT_TO_EXCEL}`);
        this.pageLoader = document.querySelector(`#${ID.PAGE_LOADER}`);
    }

    attachTableEvents() {
        this.tableColumnHeadList = Object.keys(this.tableData[0]);
        this.addDataToTable();
        this.checkPagination();

        // updating active element on next button of pagination click.
        if (this.nextPaginationBtn) {
            this.nextPaginationBtn.addEventListener(EVENTS.CLICK, () => {
                event.preventDefault();
                const activePaginationItem = document.querySelector(`.${CLASS.PAGINATION_LAYOUT} .${CLASS.ACTIVE}`);
                if (activePaginationItem && activePaginationItem.nextElementSibling) {
                    activePaginationItem.nextElementSibling.click();
                }
            });
        }

        // updating active element on previous button of pagination click.
        if (this.prevPaginationBtn) {
            this.prevPaginationBtn.addEventListener(EVENTS.CLICK, () => {
                event.preventDefault();
                const activePaginationItem = document.querySelector(`.${CLASS.PAGINATION_LAYOUT} .${CLASS.ACTIVE}`);
                if (activePaginationItem && activePaginationItem.previousElementSibling) {
                    activePaginationItem.previousElementSibling.click();
                }
            });
        }

        // Updating sort icon, displayed in table head columns.
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`.${CLASS.SORT_ICON}`)) {
                this.sortIconElement = e.target;
            } else if (e.target.matches(`.${CLASS.SORT_ICON_COLUMN}`)) {
                this.sortIconElement = e.target.querySelector(`.${CLASS.SORT_ICON}`);
            }
        });

        // Showing records according to value shown in "show entries" drop down.
        document.querySelector(`#${ID.RECORD_COUNT}`).addEventListener(EVENTS.CHANGE, () => {
            const entriesCount = document.querySelector(`#${ID.RECORD_COUNT}`).value;
            this.dataList.page = entriesCount;
            this.perPage = entriesCount;
            this.dataList.update();
            this.checkPagination();
        });

/*        document.querySelector(`#${ID.EXPORT_TO_EXCEL}`).addEventListener(EVENTS.CLICK, () => {
            const btn = document.querySelector(`#${ID.EXPORT_TO_EXCEL}`);
            btn.style.backgroundColor = '#1a5283';
        });
*/
        document.querySelector(`#${ID.EXPORT_TO_EXCEL}`).addEventListener(EVENTS.CLICK, () => {
            const btn = document.querySelector(`#${ID.EXPORT_TO_EXCEL}`);
            btn.style.backgroundColor = '#1a5283';
            this.pageLoader.classList.remove(`${CLASS.OVERLAY_HIDDEN}`);
            let url = null;
            url = `${Url.downloadReport}`;
            this.Ajax.getFileResponse(url, (data, fileName) => {
                if (data.error) {
                    console.log('Data Error :::', data.error);
                } else {
                    this.downloadReport(data, fileName);
                }
            });
            btn.style.backgroundColor = '#176DB7';
        });
    }

    downloadReport(blob, fileName = 'FepEabreport.xls') {
        console.log(this);
        if (blob) {
            const reportUrl = URL.createObjectURL(blob);
            const downloadLink = window.document.createElement('a');
            downloadLink.href = reportUrl;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        this.pageLoader.classList.add(`${CLASS.OVERLAY_HIDDEN}`);
    }

    // Function to add data to table body.
    addDataToTable() {
        const options = {
            valueNames: this.tableColumnHeadList,
            page: this.perPage,
            plugins: [
                ListPagination({
                    paginationClass: `${CLASS.PAGINATION_LAYOUT}`,
                    left: 2,
                    right: 2
                })
            ],
            item: `${SortableTableRow(this.tableColumnHeadList, this.formType)}`.replace(/(\r\n|\n|\r)/gm, '')
        };

        // Adding table to table container.
        this.dataList = new List(`${ID.TABLE_CONTAINER}`, options, this.tableData);
        if (document.querySelector('th[data-sort]')) { document.querySelector('th[data-sort]').click(); }
    }

    // Pagination functionality.
    checkPagination() {
        if (this.dataList.items.length > this.perPage) {
            if (document.querySelector(`.${CLASS.PAGINATION_INFO}`)) { document.querySelector(`.${CLASS.PAGINATION_INFO}`).innerHTML = `Showing ${this.dataList.i} to ${this.dataList.i - 1 + this.dataList.visibleItems.length} of ${this.dataList.items.length}`; }
            this.paginationContainer.classList.remove(`${CLASS.HIDDEN}`);
            this.dataList.on('updated', (list) => {
                this.addPagination(list);
            });
        } else {
            this.paginationContainer.classList.add(`${CLASS.HIDDEN}`);
        }
    }

    addPagination(list) {
        const isFirst = list.i === 1;
        const isLast = list.i > list.matchingItems.length - list.page;

        // make the Prev and Nex buttons disabled on first and last pages accordingly
        if (this.prevPaginationBtn.classList.contains(`${CLASS.DISABLED}`)) {
            this.prevPaginationBtn.classList.remove(`${CLASS.DISABLED}`);
        }
        if (this.nextPaginationBtn.classList.contains(`${CLASS.DISABLED}`)) {
            this.nextPaginationBtn.classList.remove(`${CLASS.DISABLED}`);
        }
        if (isFirst) {
            this.prevPaginationBtn.classList.add(`${CLASS.DISABLED}`);
        }
        if (isLast) {
            this.nextPaginationBtn.classList.add(`${CLASS.DISABLED}`);
        }
        document.querySelector(`.${CLASS.PAGINATION_INFO}`).innerHTML = `Showing ${this.dataList.i} to ${this.dataList.i - 1 + this.dataList.visibleItems.length} of ${this.dataList.items.length}`;
    }
}
