import {
  serializeObject as serialize,
  generateUniqueID as uniqueId,
  formatNumber,
} from 'utils';

// Partial dependencies
import { googleLocationsApi } from 'partials/google-maps';

// Constant dependencies
import constants from './../config/constants';

/**
 * @property directionsApi
 * @description Google directions link root endpoint
 */
const directionsApi = constants.googleDirectionsApi;

/**
 * @description Dealer Locator web service root endpoints
 */
const { dealerLocatorService } = window.microsites.applicationProperties;

/**
 * @constant TYPES
 * @description Location types key values
 * @type {{dealers: string, collisionCenters: string}}
 */
const TYPES = {
    dealers: 'dealers',
};

/**
 * @function
 * @name getPhoneNumber
 * @description return first phone number from data
 * @param {Array} contactArr array of contact info
 * @return {String} phone number
 */
function getPhoneNumber(contactArr) {
    let number = '';
    contactArr.forEach((item) => {
        if (item.type === 'phone') {
            number = item.value;
        }
    });
    return number;
}

/**
 * @function
 * @name getServiceUrl
 * @description return service url from data
 * @param {Array} activitiesArr array of activities objects
 * @return {String} url
 */
function getServiceUrl(dealerItem) {
    const activitiesArr = dealerItem.activities;
    let url = 'NO_SCHEDULE_SERVICE_URL';
    if (activitiesArr) {
        activitiesArr.forEach((item) => {
            if (item.name === 'service') {
                url =
          (item.url &&
            (item.url.indexOf('://') !== -1 ? item.url : `//${item.url}`)) ||
          '';
            }
        });
    }
    return url;
}

/**
 * @function
 * @name createDirectionsLink
 * @description return a google maps direction link
 * @param {Object} dealerItem/collisionCenters object
 * @return {String} url
 */
function createDirectionsLink(item) {
    const url = encodeURI(
    `${directionsApi}/${item.name}+` +
      `${item.address[0].line1}+` +
      `${item.address[0].city}+` +
      `${item.address[0].state}+` +
      `${item.address[0].zip}`
  );
    return url;
}

/**
 * @function
 * @name getDistance
 * @description Constructs the distance string
 * @param {Object} address - Address object from dealer api response
 * for each dealer
 * @param {String} language - Language context used to format the distance
 * @param {String} distAbbr - Abbreviation for the distance unit for our stub data
 * @return {String} Distance+Distance unit
 */
function getDistance(address, language, distAbbr) {
    const distance = address.location.dist;
    if (distance) {
        const dist = `${parseFloat(distance).toFixed(1)}`;
        const distUnitAbbr =
      window.microsites.pageData.dealerLocator.config.distanceUnitAbbr;
        const distUnit = distAbbr || distUnitAbbr || address.location.distunit;
        if (language === 'en') {
            return `${dist} ${distUnit}`;
        } else if (language === 'fr') {
            const distFr = dist.replace(/\./, ',');
            return `${distFr} ${distUnit}`;
        } else if (language === 'es') {
            return `${dist} ${distUnit}`;
        }
    }

    return '';
}

/**
 * @method formatDealer
 * @description format the dealers object
 * NOTE: if a new attribute with object or array value is added,
 * update {@link deepCloneDealer} with appropriate cloning methods
 * @param {Array} dealerItem - dealers object
 * @param {String} language - language from config
 * @param {String} distAbbr - Abbreviation for the distance unit for our stub data
 * @return {Object} Formatted Dealer object
 */
function formatDealer(dealerItem, language, distAbbr) {
    const dealerAddress = dealerItem.address[0];

    return Object.assign(
    {},
        {
            id: uniqueId(),
            dealerId: dealerItem.id,
            name: dealerItem.name,
            address: dealerAddress && dealerAddress.line1,
            city: dealerAddress && dealerAddress.city,
            state: dealerAddress && dealerAddress.state.toUpperCase(),
            zip: dealerAddress && dealerAddress.zip,
            country: dealerAddress && dealerAddress.country,
            distance: getDistance(dealerItem.address[0], language, distAbbr),
            location: {
                lat: dealerAddress && dealerAddress.location.lat,
                lng: dealerAddress && dealerAddress.location.lng,
            },
            phone: dealerItem.contact && getPhoneNumber(dealerItem.contact),
            contact: dealerItem.contact,
            phoneFormatted:
        dealerItem.contact &&
        formatNumber.toPhone(getPhoneNumber(dealerItem.contact)),
            scheduleServiceUrl: getServiceUrl(dealerItem),
            url:
        (dealerItem.url &&
          (dealerItem.url.indexOf('://') !== -1
            ? dealerItem.url
            : `//${dealerItem.url}`)) ||
        '',
            isAmg: dealerItem.badges
        ? dealerItem.badges.indexOf('amg') !== -1
        : false,
            isAmgElite: dealerItem.badges
        ? dealerItem.badges.indexOf('amgelite') !== -1
        : false,
            directionsLink: createDirectionsLink(dealerItem),
            badges: dealerItem.badges,
            isDealership: dealerItem.type === 'DEALER',
            marketCode: dealerItem.marketCode,
            regionCode: dealerItem.regionCode,
        }
  );
}

/**
 * @function deepCloneDealer
 * @description This function makes deep clone of the dealer
 * object generated in {@link formatDealer}.
 * The following properties need deep clone: badges, contact, location
 * @param {Object} dealer - Dealer object with same structure as in {@link formatDealer}
 * @returns {Object} A cloned object
 */
function deepCloneDealer(dealer) {
    return Object.assign({}, dealer, {
        badges: Object.values(dealer.badges),
        contact: Object.values(dealer.contact).map(c => Object.assign({}, c)),
        location: Object.assign({}, dealer.location),
    });
}

/**
 * @method formatDealers
 * @description format the dealers array
 * @param {Array} dealersArr - dealers array
 * @param {String} language - language from config
 */
function formatDealers(dealersArr, language) {
    return dealersArr.map(dealerItem => formatDealer(dealerItem, language));
}

/**
 * @method parseResponse
 * @description parse Response from fetch
 * @param {Object} data - data returned from fetch
 * @param {String} language - language from config
 */
function parseResponse(data, language) {
    let list = null;
    if (data.dealers && data.dealers.length > 0) {
        list = formatDealers(data.dealers, language);
    }
    return list;
}

/**
 * @method parseResponseAlt
 * @description parse Response from fetch
 * @param {Object} data - data returned from fetch
 * @param {String} country - country from config
 * @param {String} language - language from config
 */
function parseResponseAlt(data, country, language) {
    const dealersObject = {
        results: [],
    };

    if (data.lcp && data.lcp.name) {
        dealersObject.lcpHeader = data.lcp.name;
    }

    if (data.status.code !== 200) {
        if (data.status.code.toString().charAt(0) === '4') {
            throw new Error('CLIENT_ERROR');
        } else if (data.status.code.toString().charAt(0) === '5') {
            throw new Error('SERVER_ERROR');
        }
    }

    if (data.results && data.results.length > 0) {
        dealersObject.results = data.results.map((dealerItem) => {
            const formattedDealer = formatDealer(dealerItem, language);
            return formattedDealer;
        });
    // Condition to stop adding aoi dealers if we are appending
        if (data.aoiDealer && data.requestParameters.start === 0) {
            const aoi = {
                ...formatDealer(data.aoiDealer, language),
                isAOIDealer: true,
                isDealership: true,
            };

            dealersObject.results.unshift(aoi);
        }
    }

    dealersObject.totalCount = data.totalCount;

    return dealersObject;
}

/**
 * @method getLocations
 * @description Fetches a list of search location groups
 * based on country (e.g. states/provinces)
 * @param country {String} Country to retrieve locations from
 * @param {String} language - language from config
 */
function getLocations(country, language) {
    const endpoint = `${dealerLocatorService}/${country}/states?lang=${language}`;

    return fetch(endpoint).then(response => response.json());
}

/**
 * @method fetchDealers
 * @description Fetches dealers according to endpoint
 * @param {String} endpoint - dealer service endpoint
 */
function fetchDealers(endpoint) {
    return fetch(endpoint).then(response => response.json());
}

/**
 * @method getDealersData
 * @description Resolves promise for fetching dealers and list of dealer ids who have inventory
 * @param {String} dealersEndpoint - dealer service endpoint
 * @param {Boolean} isNew - flag to differentiate between new and cpo
 * @return object containing dealers info and array of dealers with inventory
 */
function getDealersData(dealersEndpoint) {
    const dealerServices = [fetchDealers(dealersEndpoint)];

    return Promise.all(dealerServices).then(results => ({
        ...results[0],
    }));
}

/**
 * @method getDealersByLocation
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.zip - Zip code
 * @param {String} opts.lat - Latitude
 * @param {String} opts.lng - Longitude
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.country - Country code
 * @param {String} opts.type - Type of search, dealers or cc
 * @param {String} opts.language - the language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getDealersByLocation({
  zip,
  lat,
  lng,
  radius = 25,
  country,
  type,
  language,
  limit,
} = {}) {
    const dealersEndpoint = `${dealerLocatorService}/${country}/${type}${serialize(
    { zip, lat, lng, radius, limit }
  )}`;

    return getDealersData(dealersEndpoint).then(data =>
    parseResponse(data, language)
  );
}

/**
 * @method getDealersByLocationAlt
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.zip - Zip code
 * @param {String} opts.lat - Latitude
 * @param {String} opts.lng - Longitude
 * @param {String} opts.country - Country code
 * @param {String} opts.language - the language from config
 * @param {Number} opts.start - Index to start query at
 * @param {Number} opts.count - number of result items to show for query from start
 * @param {String} opts.filter - type of dealer to filter through
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 *                               dealers with inventory
 */

function getDealersByLocationAlt({
  brand,
  zip,
  lat,
  lng,
  country,
  language,
  start,
  count,
  state,
} = {}) {
    const dealersEndpoint = `${dealerLocatorService}${serialize(
        { brand, zip, lat, lng, start, count, state, country, lang: language }
        )}`;
    return getDealersData(dealersEndpoint).then(data =>
    parseResponseAlt(data, country, language)
  );
}

/**
 * @method getDealersByLCP
 * @description Gets a list of dealers based on lcp code
 * @param {String} country - Country code
 * @param {String} language - the language from config
 * @param {String} code - LCP code
 */
function getDealersByLCP(country, language, code) {
    const endpoint = `${dealerLocatorService}/${country}/search?lcp=${code}`;

    return getDealersData(endpoint).then(data =>
    parseResponseAlt(data, country, language)
  );
}

/**
 * @method getByState
 * @description Gets a collection of dealer/collision centers
 * based on state/province and country location
 * @param country {String} Country code
 * @param state {String} State/Province code
 * @param type {String} Type of locations to retrieve (dealer or collision center)
 * @param {String} language - language from config
 */
function getByState(country, state, type, language) {
    const endpoint = `${dealerLocatorService}/${country}/${type}?state=${state}`;

    return getDealersData(endpoint).then(data => parseResponse(data, language));
}

/**
 * @method getOptions
 * @description Builds out the options object
 * Gets the request params needed for dealer search
 * If the current searchByType is zip, resolves to zip
 * If the current searchByType is place, makes google api call to get lat/lng
 * @param {Object} opts - Options for service call
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.searchType - 'dealer' or 'collisionCenter'
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getOptions({
  brand,
  radius,
  searchType,
  country,
  language,
  limit,
  searchByType,
  searchLocation,
  start,
  count,
  filter,
  state,
} = {}) {
    const opts = {
        brand,
        radius,
        type: searchType,
        country,
        language,
        limit,
        start,
        count,
        filter,
        state
    };

    if (searchByType === 'zip') {
        opts.zip = searchLocation;
    } else if (searchByType === 'place') {
        return googleLocationsApi.getClosestPlaceFullInfo(searchLocation)
        .then((place) => {
            opts.lat = place.geometry.location.lat();
            opts.lng = place.geometry.location.lng();
            const postalCodes = place.address_components.filter(address =>
                address.types.indexOf('postal_code') !== -1
            );

            if (postalCodes && postalCodes[0]) {
                opts.zip = postalCodes[0].long_name;
            }
            return opts;
        });
    }

    return Promise.resolve(opts);
}

/**
 * @method getDealer
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.id - Dealer Id to retrieve
 * @param {String} opts.country - Country code
 * @param {String} opts.type - Type of search, dealers or cc
 * @param {String} opts.language - the language from config
 */
function getDealer({ id, country, language, type } = {}) {
    const endpoint = `${dealerLocatorService}/${country}/${type}${serialize({
        id,
    })}`;

    return fetch(endpoint)
    .then(response => response.json())
    .then(data => parseResponse(data, language))
    .then((dealers) => {
        if (dealers && dealers.length) {
            return dealers[0];
        }

        throw new Error(`No dealers found with the id ${id}`);
    });
}

/**
 * @method getDealers
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.searchType - 'dealers' or 'cc'
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {String} opts.filterBy - Optional FILTER_TYPES value
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getDealers({
  radius = 'all',
  searchType = TYPES.dealers,
  country = 'us',
  language = 'en',
  limit,
  searchByType,
  searchLocation,
  isNew = true,
} = {}) {
    return getOptions({
        radius,
        searchType,
        country,
        language,
        limit,
        searchByType,
        searchLocation,
        isNew,
    })
    .then(getDealersByLocation)
    .then(dealers => dealers);
}

/**
 * @method getDealersAlt
 * @description Alternative method to grab dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {String} opts.filterBy - Optional FILTER_TYPES value
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 * @param {String} opts.start - starting number to start querying items from
 * @param {String} opts.count - number to result items to show per query
 */
function getDealersAlt({
  brand,
  country = 'us',
  language = 'en',
  searchByType,
  searchLocation,
  filterBy = null,
  isNew = true,
  start,
  count,
  state,
} = {}) {
    return getOptions({
        brand,
        country,
        language,
        searchByType,
        searchLocation,
        filterBy,
        isNew,
        start,
        count,
        state,
    })
    .then(getDealersByLocationAlt)
    .then((dealersObject) => {
      // filter dealers if filterBy was set
        if (dealersObject.results && filterBy) {
            return {
                results: dealersObject.results,
                totalCount: dealersObject.totalCount,
            };
        }

        return dealersObject;
    });
}

/**
 * Export public api methods
 */
export default {
    TYPES,
    formatDealer,
    deepCloneDealer,
    getLocations,
    getByState,
    getDealers,
    getDealer,
    getDealersAlt,
    getDealersByLCP,
};
