import React from 'react';
import { render } from 'react-dom';
import BulkCanUpoadFormComponent from '../../partials/BulkCanUploadForm/components/BulkCanUploadForm';

export default class BulkCanUploadForm {
    constructor(element) {
        this.element = element;
        this.init();
    }
    init() {
        render(<BulkCanUpoadFormComponent />, this.element);
    }
}
