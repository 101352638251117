/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

export default ({ ctaLabel, buttonStyle }) => htmlNode`
    <div class="dealer-search-bar">
        <div class="dealer-search-bar__location-search" data-location-search=""></div>
        <div class="dealer-search-bar__cta">
            <button class="${buttonStyle}" data-cta-button="" data-analytics-trigger="cta">
                ${ctaLabel}
            </button>
        </div>
    </div>
`;

