import { html } from 'utils';

export default () => html`
<form class="canApplication__form" action="" method="post" data-load-module="CanApplication">
    <div class="canApplication__body">
        <ul class="canApplication__accordion-container">
            <li class="canApplication__accordion-list canApplication__accordion-list--active">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">1. Company Information</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block canApplication__accNumber">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Corporate Account Number</label>
                        <label class="canApplication__label" id="can" for=""></label>
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Corporate Name</label>
                        <input id="corporateName" class="canApplication__input" type="text" name="corporateName" short-form approved required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Corporate Address</label>
                        <input id="corporateAddress" class="canApplication__input" type="text" name="corporateAddress" short-form approved required value="">
                    </div>
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for=""><span class="astrick">*</span>Type of Business</label>
                        <select class="canApplication__select" name="" id="businessType" required>
                            <option value="">--Select--</option>
                            <option cssclass="optionWrap" value="72" title="Accommodation and Food Services">Accommodation and Food Services</option>
                            <option cssclass="optionWrap" value="56" title="Administrative and Support Services">Administrative and Support Services</option>
                            <option cssclass="optionWrap" value="11" title="Agricultural, Forestry and Fishing">Agricultural, Forestry and Fishing</option>
                            <option cssclass="optionWrap" value="71" title="Arts, Recreation and Entertainment">Arts, Recreation and Entertainment</option>
                            <option cssclass="optionWrap" value="23" title="Construction" selected="">Construction</option>
                            <option cssclass="optionWrap" value="61" title="Education Services">Education Services</option>
                            <option cssclass="optionWrap" value="52" title="Finance and Insurance">Finance and Insurance</option>
                            <option cssclass="optionWrap" value="62" title="Health Care and Social Assistance">Health Care and Social Assistance</option>
                            <option cssclass="optionWrap" value="51" title="Information">Information</option>
                            <option cssclass="optionWrap" value="55" title="Management of Companies">Management of Companies</option>
                            <option cssclass="optionWrap" value="31" title="Manufacturing">Manufacturing</option>
                            <option cssclass="optionWrap" value="21" title="Mining, Oil and Gas">Mining, Oil and Gas</option>
                            <option cssclass="optionWrap" value="81" title="Other Services (except Public)">Other Services (except Public)</option>
                            <option cssclass="optionWrap" value="49" title="Postal, Courier, Message and Warehousing">Postal, Courier, Message and Warehousing</option>
                            <option cssclass="optionWrap" value="54" title="Professional, Scientific, Technical and Legal">Professional, Scientific, Technical and Legal</option>
                            <option cssclass="optionWrap" value="92" title="Public Administration">Public Administration</option>
                            <option cssclass="optionWrap" value="53" title="Real Estate, Rental and Leasing">Real Estate, Rental and Leasing</option>
                            <option cssclass="optionWrap" value="44" title="Retail Trade">Retail Trade</option>
                            <option cssclass="optionWrap" value="48" title="Transportation">Transportation</option>
                            <option cssclass="optionWrap" value="22" title="Utilities">Utilities</option>
                            <option cssclass="optionWrap" value="42" title="Wholesale Trade">Wholesale Trade</option>
                        </select>
                    </div>
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for="">Corporate Parent Name</label>
                        <input id="corporateParentName" class="canApplication__input" type="text" name="corporateParentName" value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>City</label>
                        <input id="city" class="canApplication__input" type="text" name="city" short-form required approved value="">
                    </div>
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for="">Corporate Website Address</label>
                        <input id="corporateWebsiteAddress" class="canApplication__input" type="text" name="corporateWebsiteAddress" value="">
                    </div>
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Corporate Tax ID Number</label>
                        <input id="corporateTaxIDNumber" class="canApplication__input" type="text" name="corporateTaxIdNumber" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>State</label>
                        <select class="canApplication__select" name="state" approved required short-form id="state">
                            <option value="">--select--</option>
                            <option value="AA">AA</option>
                            <option value="AE">AE</option>
                            <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AR">AR</option>
                            <option value="AS">AS</option>
                            <option value="AZ">AZ</option>
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>
                            <option value="FL">FL</option>
                            <option value="FM">FM</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>
                            <option value="MH">MH</option>
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MO">MO</option>
                            <option value="MP">MP</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NE">NE</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY" selected="">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="PW">PW</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>
                        </select>
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Zip Code</label>
                        <input id="zip" class="canApplication__input" type="text" name="zipCode" short-form approved required value="">
                    </div>
                    <div class="canApplication__block canApplication__typeOfVehicle">
                        <label class="canApplication__label" for="">Type of Vehicle</label>
                        <select class="canApplication__select" name="typeOfVehicle" short-form id="typeOfVehicle">
                            <option value="PC">Passenger Cars</option>
                            <option value="VAN">Vans</option>
                            <option value="PCV">Passenger Cars and Vans</option>
                        </select>
                    </div>
                    <div class="canApplication__block canApplication__fleetSize">
                    <label class="canApplication__label" for="">Fleet Size</label>
                    <select class="canApplication__select" name="fleetSize" short-form id="fleetSize">
                        <option value="STRATEGIC">Strategic</option>
                        <option value="MEDIUM">Medium</option>
                    </select>
                </div>
                </div>
            </li>
            <li class="canApplication__accordion-list">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">2. Company Officer</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Corporate/Business Title</label>
                        <input id="businessTitle" class="canApplication__input" type="text" name="businessTitle" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Business Phone Number</label>
                        <input id="businessPhoneNumber" class="canApplication__input" type="number" short-form name="phoneNumber" required approved value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="astrick"></span>Business Fax Number</label>
                        <input id="businessFaxNumber" class="canApplication__input" type="number" name="faxNumber" short-form value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>First Name</label>
                        <input id="businessFirstName" class="canApplication__input" type="text" name="firstName" short-form approved required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for="companyOfficerBusinessEmail"><span class="canApplication__astrick">*</span>Business Email Address<span class="canApplication__addInfo">(No Personal Emal Address)</span></label>
                        <input id="companyOfficerBusinessEmail" class="canApplication__input" type="text" name="companyOfficerBusinessEmail" short-form approved required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Last Name</label>
                        <input id="businessLastName" class="canApplication__input" type="text" required short-form approved name="lastName" value="">
                    </div>
                    <div class="canApplication__block short">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Confirm Email Address<span class="canApplication__addInfo">(No Personal Emal Address)</span></label>
                        <input class="canApplication__input" id="confirmCompanyOfficerBusinessEmail" type="text" name="confirmCompanyOfficerBusinessEmail" required value="">
                    </div>
                    <div class="canApplication__block canApplication__comments">
                        <label class="canApplication__label" for="">Comments</label>
                        <textarea id="businessComments" name="comment" class="canApplication__input" short-form required></textarea>
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">3. Other Manufacturer's Corporate Account Number </div>
                    <div class="canApplication__accordion-confirmation">
                        <div class="canApplication__accordion-confirmation-block">
                            <input id="manufacturarCanYes" type="radio" name="manufacturarCan" value="">
                            <label for="manufacturarCanYes">Yes</label>
                        </div>
                        <div class="canApplication__accordion-confirmation-block">
                            <input id="manufacturarCanNo" type="radio" name="manufacturarCan" value="">
                            <label for="manufacturarCanNo">No</label>
                        </div>
                    </div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Manufacturer</label>
                        <select class="canApplication__select" name="manufacturer" id="manufacturer">
                            <option value="">--select--</option>
                            <option value="2">ACURA</option>
                            <option value="4">ASTON MARTIN</option>
                            <option value="5">AUDI</option>
                            <option value="6">BENTLEY</option>
                            <option value="8">BMW</option>
                            <option value="10">BUICK</option>
                            <option value="11">CADILLAC</option>
                            <option value="13">CHEVROLET</option>
                            <option value="14">CHRYSLER</option>
                            <option value="20">DODGE</option>
                            <option value="22">FERRARI</option>
                            <option value="24">FORD</option>
                            <option value="80">GMC</option>
                            <option value="27">HONDA</option>
                            <option value="81">HUMMER</option>
                            <option value="28">HYUNDAI</option>
                            <option value="29">INFINITI</option>
                            <option value="31">JAGUAR</option>
                            <option value="82">JEEP</option>
                            <option value="33">KIA</option>
                            <option value="34">LAMBORGHINI</option>
                            <option value="83">LAND ROVER</option>
                            <option value="36">LEXUS</option>
                            <option value="38">LINCOLN</option>
                            <option value="39">LOTUS</option>
                            <option value="42">MAYBACH</option>
                            <option value="43">MAZDA</option>
                            <option value="44">MERCEDES-BENZ</option>
                            <option value="45">MERCURY</option>
                            <option value="47">MINI</option>
                            <option value="48">MITSUBISHI</option>
                            <option value="50">NISSAN</option>
                            <option value="57">PONTIAC</option>
                            <option value="58">PORSCHE</option>
                            <option value="62">ROLLS ROYCE</option>
                            <option value="63">SAAB</option>
                            <option value="66">SMARTCAR</option>
                            <option value="69">SUBARU</option>
                            <option value="70">SUZUKI</option>
                            <option value="72">TOYOTA</option>
                            <option value="74">VOLKSWAGEN</option>
                            <option value="75">VOLVO</option>
                        </select>
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Account Number</label>
                        <input id="manuAccountNumber" class="canApplication__input" type="number" name="accountNumber" value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Fleet Management Company</label>
                        <select class="canApplication__select" name="company" id="company">
                            <option value="">--select--</option>
                            <option value="004006">ARI</option>
                            <option value="007947">ARI</option>
                            <option value="004008">Bbl</option>
                            <option value="004009">Donlen Corp</option>
                            <option value="014285">Ellis Brooks Leasing</option>
                            <option value="009088">Emkay Lease Company</option>
                            <option value="015487">Enterprise Fleet Management</option>
                            <option value="004005">General Electric Company</option>
                            <option value="004007">LeasePlan USA</option>
                            <option value="007615">LeasePlan USA</option>
                            <option value="018086">Mbusa Fleet</option>
                            <option value="018868">Merchants Leasing</option>
                            <option value="007332">Mike Albert Leasing Inc</option>
                            <option value="004011">Motorlease</option>
                            <option value="004002">PHH</option>
                            <option value="018950">Union Leasing Trust</option>
                            <option value="004003">Wheels Ltd</option>
                        </select>
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">4. Dealer Information</div>
                    <div class="canApplication__accordion-confirmation">
                        <div class="canApplication__accordion-confirmation-block">
                            <input id="dealerInfoYes" type="radio" name="dealerInfo" value="">
                            <label for="dealerInfoYes">Yes</label>
                        </div>
                        <div class="canApplication__accordion-confirmation-block">
                            <input id="dealerInfoNo" type="radio" name="dealerInfo" value="">
                            <label for="dealerInfoNo">No</label>
                        </div>
                    </div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Dealer Name</label>
                        <input id="overlayDealerName" class="canApplication__input" type="text" name="dealerName" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Dealer Code</label>
                        <input id="dealerCode" class="canApplication__input" type="number" name="dealerCode" required approved value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Business Email Address</label>
                        <input id="dealerBusinessEmail" class="canApplication__input" type="email" name="dealerBusinessEmail" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Dealer Contact First Name</label>
                        <input id="dealerFirstName" class="canApplication__input" type="text" name="dealerContactFirstName" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Dealer Phone Number</label>
                        <input id="dealerPhoneNumber" class="canApplication__input" type="text" name="dealerPhoneNumber" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Confirm Email Address</label>
                        <input class="canApplication__input" id="confirmDealerBusinessEmail" type="text" name="confirmDealerBusinessEmail" required value="">
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Dealer Contact Last Name</label>
                        <input id="dealerLastName" class="canApplication__input" type="text" name="dealerContactLastName" required value="">
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">5. Instructions for Corporate Account Registration</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__instructions-block">
                        <pre class="canApplication__instructions">
To qualify as a registered corporate account, a company must meet the following criteria:
1) Operation of 5 or more vehicles (any make or model) registered in the company name.
2) Hard copy vehicle registrations must be provided after completing this form. Registrations in individual names, titles, or insurance cards are not accepted. Registrations must be for motor vehicles only; no trailers, boats, motorcycles, machinery, etc.
3) For leased vehicles, in addition to the registrations in the leasing company's name, a Buyer's Order or Lease Agreement or Master Insurance Policy listing the VINs to the company applying for the CAN.
Please use this portion of the form to attach proof of registration for 5 company-owned/leased vehicles. Please email proof of registration for 3 company-owned/leased vehicles to Fleetoperations@mbusa.com. Review of applications will begin when all supporting documents have been received.
<b>Note: Review of applications will begin when all supporting documents have been received. Please allow up to two business days for processing.</b>
                        </pre>
                        <div class="canApplication__instructions-confirmation-block">
                            <div class="canApplication__instructions-confirmation">
                                <input id="uploadRegistration" type="radio" name="instrucConfirm" value="" disabled>
                                <label for="uploadRegistration">Upload Registrations/Lease Documents</label>
                            </div>
                            <div class="canApplication__instructions-confirmation">
                                <input id="emailVehicleReg" type="radio" name="instrucConfirm" value="" disabled>
                                <label for="emailVehicleReg">I prefer to email my 3 vehicle registrations and/or lease documentation to: Fleetoperations@mbusa.com.<br>
                                    <b>Review of your application can only begin after all supporting materials have been received.</b>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short hide">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title">6. Upload Registrations/Lease Documents</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block canApplication__block--full">
                        <label class="canApplication__label" for="">Uploaded Registrations/Lease documents file : </label>
                        <input id="attachedFileName" class="canApplication__input" type="text" name="attachedFileName" value="" disabled>
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title"><span class="canApplication__sno">6</span>. Account Application Agreement.</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__instructions-block">
                        <pre class="canApplication__instructions">
By submission of this Qualified Corporate Account Enrollment Form, the undersigned hereby requests enrollment as a Registered Corporate Account and agrees to comply with the Official Program Rules with respect to any Mercedes-Benz incentive benefits as outlined in Official Program Rules. All information provided in this application is subject to verification through proprietary and third party sources including but not limited to Known Export and U.S./EU Sanction Lists. We acknowledge that this enrollment form will be continued from year-to-year and, as a condition of continued enrollment as a Fleet Program Qualified Corporate Account, we agree to comply with the Official Rules of the Programs, including applicable limitations and restrictions, established from time-to-time.
1. Mercedes-Benz USA, LLC reserves the right at its sole discretion to change program rules and guidelines and fleet incentives without notice, to verify accuracy of account data, to decline an application or to terminate a Corporate Account Number or Executive Allowance Control Number at any time on a case by case basis. All program transactions are subject to audit and chargeback to the dealer.
2. Purchaser hereby agrees that any information provided to or obtained by Dealer or MBUSA in confirmation of the Purchaser's participation in the MBUSA Fleet Program (including any and all personal information), is voluntarily provided by Purchaser and Purchaser hereby consents to MBUSA and Dealer's retaining said information for record and audit purposes and to the sharing of said information to affiliates and agencies for purposes of marketing Mercedes-Benz products and services.
3. Dealer and customer acknowledge and agree that MBUSA's issuance of a CAN or control number or approval under the MBUSA Fleet Program does not constitute an approval of or review by MBUSA of any transaction effected through the Program. It remains the Dealer's and Purchaser's responsibility to determine and comply with all Program qualifications of any transaction including compliance with use, export and resale restrictions.
4. Sale subject to any new car sales DDR Policy and Export Policy. Vehicles are intended for use in the USA and are subject to the limitations of the Export Policy. Fleet programs are not eligible for use with Brokers. Fleet incentive must be used at the time of purchase/lease.
                        </pre>
                    </div>
                </div>
            </li>
            <li class="canApplication__accordion-list short">
                <div class="canApplication__accordion-title-block">
                    <div class="canApplication__accordion-title"><span class="canApplication__sno">7</span>. Electronic Signature.</div>
                </div>
                <div class="canApplication__accordion-panel">
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Title of Company Officer</label>
                        <input id="titleCompanyOfficer" class="canApplication__input" type="text" name="" value="" required>
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Name of Company Officer</label>
                        <input id="nameCompanyOfficer" class="canApplication__input" type="text" name="" value="" required disabled>
                    </div>
                    <div class="canApplication__block">
                        <label class="canApplication__label" for=""><span class="canApplication__astrick">*</span>Date</label>
                        <input id="date" class="canApplication__input" type="date" name="" value="" required disabled>
                    </div>
                    <div class="canApplication__instructions-block">
                        <pre class="canApplication__instructions">
1. I confirm that the information is complete, and accurate to the best of my knowledge and by submitting the application I have reviewed the Account Application Agreement and consent to the information being used for enrollment in the Mercedes-Benz Fleet Program.
2. Please email 3 company-owned/leased vehicle registration cards to: Fleetoperations@mbusa.com. For leased vehicles, Buyer's Order, Lease Agreement, or insurance company list must also be emailed.
<b>Note: Review of your application will begin only after all supporting documents have been received. Please allow up to two business days for processing.</b>
                        </pre>
                    </div>
                    <div class="canApplication__instructions-confirmation-block">
                        <div class="canApplication__instructions-confirmation">
                            <input id="agreementSigned" type="checkbox" name="agree1" value="">
                            <label for="agreementSigned"><span class="canApplication__astrick" >*</span>(Electronic Signature of Company Officer) Yes, I agree.</label>
                        </div>
                        <div class="canApplication__instructions-confirmation">
                            <label for="emailDealer">By checking this second box.<input id="agreementSignedCopy" type="checkbox" name="agree2" value=""> I also agree and consent for a copy of th e approval/rejection email response to be sent to the above mentioned Mercedes-Benz cerfified dealership regarding this Corporate Account Number (CAN) Enrollment Application.</label>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="canApplication__footer">
        <button type="button" id="printForm" class="button button_primary" value="Print Form">Print Form</button>
        <button type="button" id="approve" class="button button_primary" value="Approve">Approve</button>
        <button type="button" id="reject" class="button button_primary" value="Reject">Reject</button>
        <button type="button" id="update" class="button button_primary" value="Update">Update</button>
    </div>

    <p class="canApplication__update-error hidden form__message--error"></p>
</form>
`;
