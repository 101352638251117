import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    HOME_OFFICE_SUPPORT_FORM_CONTROL: 'home-office-support-edit-form',
    MBM_LEASE_RETURN_FORM_CONTROL_ERROR: 'mbm-lease-return-form__control--error',
    MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS: 'mbm-lease-return-form__control--success',
    MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN: 'mbm-lease-return-form__error-field--hidden',
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
    SMECONTACTED: 'smeContacted',
    SMERESPOND: 'smeRespond',
    SMERESPOND_FIRST: 'smeRespond_first',
    DMCONTACTEDSME: 'dmcontactedSME',
    GMCONTACTEDSME: 'gmContactedSme',
    SMERESPOND_SECOND: 'smeRespond_second',
    VPCONTACTED_SME: 'vpContactedSme',
    SMERESPOND_THIRD: 'smeRespond_third',


};
export default class HomeOfficeSupportEditUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.HOME_OFFICE_SUPPORT_FORM_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
        document.addEventListener(EVENTS.CHANGE, (el) => {
            if (el.target.matches(`#${ID.SMECONTACTED}`) || el.target.matches(`#${ID.VPCONTACTED_SME}`) || el.target.matches(`#${ID.SMERESPOND_THIRD}`) || el.target.matches(`#${ID.SMERESPOND}`) || el.target.matches(`#${ID.SMERESPOND_FIRST}`) || el.target.matches(`#${ID.DMCONTACTEDSME}`) || el.target.matches(`#${ID.GMCONTACTEDSME}`) || el.target.matches(`#${ID.SMERESPOND_SECOND}`)) {
                this.enableDate(el.target);
            }
        });
    }

    enableDate(el) {
        console.log(this);
        if ((el.name === 'smeContacted') || (el.name === 'smeRespond') || (el.name === 'smeRespond_third') || (el.name === 'smeRespond_first') || (el.name === 'dmcontactedSME') || (el.name === 'gmContactedSme') || (el.name === 'smeRespond_second') || (el.name === 'vpContactedSme')) {
            if (document.getElementById('smeContacted').value === 'Yes') {
                document.getElementById('smeContactedDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeContactedDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond').value === 'Yes') {
                document.getElementById('smeRespondDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespondDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_third').value === 'Yes') {
                document.getElementById('smeRespond_thirdDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespond_thirdDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_first').value === 'Yes') {
                document.getElementById('smeRespond_firstDate').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespond_firstDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('dmcontactedSME').value === 'Yes') {
                document.getElementById('dmcontactedSMEDate').removeAttribute('disabled');
            } else {
                document.getElementById('dmcontactedSMEDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('gmContactedSme').value === 'Yes') {
                document.getElementById('gmContactedSmeDate').removeAttribute('disabled');
            } else {
                document.getElementById('gmContactedSmeDate').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('smeRespond_second').value === 'Yes') {
                document.getElementById('smeRespondedDate_second').removeAttribute('disabled');
            } else {
                document.getElementById('smeRespondedDate_second').setAttribute('disabled', 'disabled');
            }
            if (document.getElementById('vpContactedSme').value === 'Yes') {
                document.getElementById('vpContactedSmeDate').removeAttribute('disabled');
            } else {
                document.getElementById('vpContactedSmeDate').setAttribute('disabled', 'disabled');
            }
        }
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    containAlphabetsOnly(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(elementValue);
    }

    containDigitsDecimalOnly(elementValue) {
        console.log(this);
        const regex = /^(?=.*[1-9])\d*(?:\.\d{1,2})*$/;
        return regex.test(elementValue);
    }
    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Year Validation
     */
    isValidYear(year) {
        console.log(this);
        const regex = /^\d{4}$/;
        return regex.test(year) && year > 1800;
    }

    /**
     * VIN Validation
     */
    isValidVin(vin) {
        console.log(this);
        const regex = /^[A-Za-z0-9]{17}$/;
        return regex.test(vin);
    }

    /**
     * Price Validation
     */
    validPrice(price) {
        console.log(this);
        const regex = /^\$?\d+(,\d{3})*\.?[0-9]?[0-9]?$/;
        return regex.test(price);
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.HOME_OFFICE_SUPPORT_FORM_CONTROL}`);

        this.validateFields(inputFields);

        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'year') {
            if (el.value) {
                this.validate(el, this.isValidYear(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'custLastName' || el.name === 'color') {
            if (el.value) {
                this.validate(el, this.containAlphabetsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'vin') {
            if (el.value) {
                this.validate(el, this.isValidVin(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }


    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
