import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import UISButtonsUtil from 'modules/UISButtonsUtil';

const CLASS = {
    CONTACT_FORM_CONTROL: 'contact-form__control',
    CONTACT_FORM_CONTROL_ERROR: 'contact-form__control--error',
    CONTACT_FORM_CONTROL_SUCCESS: 'contact-form__control--success',
    CONTACT_FORM_ERROR_FIELD: 'contact-form-data__error-field',
    CONTACT_FORM_ERROR_FIELD_HIDDEN: 'contact-form-data__error-field--hidden',
};


const ID = {
    SUBMITBUTTON: 'CompleteReviewButton',
    DENYBUTTON: 'DenyButton',
    DENYPOPUP: 'deny-popup',
    COMPLETEPOPUP: 'complete-popup',
    CANCELBUTTON: 'CancelButton',
    CANCELPOPUP: 'cancel-popup',
    DISPLAY_REVIEWED: 'reviewed',
    DISPLAY_DENIED: 'denied',
    DISPLAY_CANCELED: 'canceled',
    DISPLAY_RESULT: 'displayData',
    UIS_COMPLETE: 'uis_complete',
    FILES: 'submit-fileID',
    EMPTYFILE: 'emptyfile',
    TEXT_AREA: 'requestOverview',
    DENYFILES: 'DenyFilesText',
    COMMENT: 'denyComment',
    COMPLETE_CLOSEBUTTON: 'completeclosebutton',
    DENY_CLOSEBUTTON: 'denyclosebutton',

};

const formElement = '';
let entitlementData = '';
let topicOfUis = '';
let creationDate = '';

export default class UISButtons {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.uisId = new URLSearchParams(window.location.search).get('ref');
        this.url = '/bin/microsites/uis/status-update';
        this.uisFormUrl = '/bin/microsites/uis-form';
        this.loadModules();
        this.getUISRequest();
        this.attachEvents();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.UISButtonsUtil = new UISButtonsUtil(this.element);
        const option = 'display';
        const data = this.uisId;
        const filedata = {
            option,
            data
        };
        this.Ajax.ajaxPostFn(this.uisFormUrl, filedata, (success) => {
            if ((JSON.parse(success)) === 'UnAuthorised Access') {
                document.getElementById('loader').style.display = 'none';
                document.getElementById('warning-component').style.display = 'none';
                document.getElementById('UIS_form').style.display = 'none';
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('richtext-cmtBoxContainer').style.display = 'none';
                document.getElementById('displayData').style.display = 'none';
                document.getElementById('gotodashboard').href =
                    `${document.getElementById('editLinkdashboard').value}`;
                document.getElementById('adminerrormsg').classList.remove('check-form__error-field--hidden');
            } else if (success !== 'false') {
                this.formData = JSON.parse(JSON.parse(success));
                const status = this.formData.status;
                topicOfUis = this.formData.modificationOverview.topicOfUis;
                creationDate = this.formData.generalInfo.date;
                this.usiEntitlement(status);
                if (status === 'Reviewed' || status === 'Denied' || status === 'Canceled') {
                    document.getElementById('clickHereToEdit').style.display = 'none';
                    document.getElementById(`${ID.DENYBUTTON}`).style.display = 'none';
                    document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'none';
                    document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'none';
                    document.getElementById('uisButtonComponent').style.display = 'block';
                    const reviewDisplay = document.getElementById(`${ID.DISPLAY_REVIEWED}`);
                    reviewDisplay.innerHTML = `<div class="uis-buttons__review"><button type="button" 
                    data-dismiss="modal"><p class="uis-buttons__btn--primary uis-buttons__btn">${status}</p></button>
                    </div>`;
                }
            }
        });
    }

    usiEntitlement(uisStatus) {
        console.log(this);
        const siteName = 'upfitter';
        const uisentitlement = '/bin/microsites/uis-entitlement-label';
        this.Ajax.ajaxPostFn(uisentitlement, siteName, (success) => {
            entitlementData = JSON.parse(success);
            if ((uisStatus === 'Pending' && entitlementData === 'EXPERTUPFITTER')) {
                document.getElementById(`${ID.DENYBUTTON}`).style.display = 'none';
                document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'none';
                document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'none';
                document.getElementById('uisButtonComponent').style.display = 'block';
                const reviewDisplay = document.getElementById(`${ID.DISPLAY_REVIEWED}`);
                reviewDisplay.innerHTML = `<div class="uis-buttons__review"><button type="button" 
                    data-dismiss="modal"><p class="uis-buttons__btn--primary uis-buttons__btn">${uisStatus}</p></button>
                    </div>`;
            } else if ((uisStatus === 'Pending' && entitlementData === 'HOMBENGADMIN') || (uisStatus === 'Pending' && entitlementData === 'HOMBPRGADMIN')) {
                document.getElementById(`${ID.DENYBUTTON}`).style.display = 'block';
                document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'block';
                document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'block';
                document.getElementById('uisButtonComponent').style.display = 'block';
            }
        });
    }

    getUISRequest() {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DISPLAY_RESULT}`);
        const Validate = this.uisIdValidate(this.uisId);
        if (Validate) {
            resultDisplay.innerText = `<h4>UIS Request #${this.uisId}</h4>`;
        }
    }


    uisIdValidate(elementValue) {
        console.log(this);
        const regex = /^[0-9]{1,16}$/;
        return regex.test(elementValue);
    }


    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (e) => {
            if (e.target.matches(`#${ID.TEXT_AREA}`)) {
                e.preventDefault();
                const subButton = document.getElementById('complete-popup');
                const area = document.getElementById('requestOverview').value;
                const k = document.getElementById('requestOverview');
                const regex = /^(?=.*[^\s])([a-zA-Z0-9.,?;\-\s]{1,500})$/;
                const errmsgarea = document.getElementById('textmsgdata');
                if (regex.test(area)) {
                    subButton.removeAttribute('disabled');
                    errmsgarea.classList.add('contact-form-data__error-field--hidden');
                    k.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
                    k.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
                } else {
                    subButton.setAttribute('disabled', 'disabled');
                    k.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
                    errmsgarea.classList.remove('contact-form-data__error-field--hidden');
                    k.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
                }
            }
        });

        document.addEventListener(EVENTS.INPUT, (e) => {
            if (e.target.matches(`#${ID.COMMENT}`)) {
                e.preventDefault();
                const subButton = document.getElementById('deny-popup');
                const area = document.getElementById('denyComment').value;
                const k = document.getElementById('denyComment');
                const regex = /^(?=.*[^\s])([a-zA-Z0-9.,?;\-\s]{1,500})$/;
                const errmsgarea = document.getElementById('denyerr');
                if (regex.test(area)) {
                    subButton.removeAttribute('disabled');
                    errmsgarea.classList.add('contact-form-data__error-field--hidden');
                    k.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
                    k.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
                } else {
                    subButton.setAttribute('disabled', 'disabled');
                    k.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
                    errmsgarea.classList.remove('contact-form-data__error-field--hidden');
                    k.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
                }
            }
        });
        document.getElementById('completeclosebutton').addEventListener(EVENTS.CLICK, () => {
            const completefile = document.getElementById('submit-fileID');
            const completetextarea = document.getElementById('requestOverview');
            const completetextareaerr = document.getElementById('textmsgdata');
            const completefilerr = document.getElementById('emptyfile');
            completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            completefile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completefile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            completetextareaerr.classList.add('contact-form-data__error-field--hidden');
            completefilerr.classList.add('contact-form-data__error-field--hidden');
            completetextarea.value = '';
            completefile.value = '';
            document.getElementById('fileText').innerHTML = '';
        });

        document.getElementById('denyclosebutton').addEventListener(EVENTS.CLICK, () => {
            const denyfile = document.getElementById('DenyFilesText');
            const denytextarea = document.getElementById('denyComment');
            const denytextareaerr = document.getElementById('denyerr');
            const denyfileerr = document.getElementById('denyemptyfile');
            denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denyfile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denyfile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denytextareaerr.classList.add('contact-form-data__error-field--hidden');
            denyfileerr.classList.add('contact-form-data__error-field--hidden');
            denyfile.value = '';
            denytextarea.value = '';
            document.getElementById('denyfileText').innerHTML = '';
        });

        document.getElementById('crosscompleteclosebutton').addEventListener(EVENTS.CLICK, () => {
            const completefile = document.getElementById('submit-fileID');
            const completetextarea = document.getElementById('requestOverview');
            const completetextareaerr = document.getElementById('textmsgdata');
            const completefilerr = document.getElementById('emptyfile');
            completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completetextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            completefile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completefile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            completetextareaerr.classList.add('contact-form-data__error-field--hidden');
            completefilerr.classList.add('contact-form-data__error-field--hidden');
            completetextarea.value = '';
            completefile.value = '';
            document.getElementById('fileText').innerHTML = '';
        });

        document.getElementById('crossdenyclosebutton').addEventListener(EVENTS.CLICK, () => {
            const denyfile = document.getElementById('DenyFilesText');
            const denytextarea = document.getElementById('denyComment');
            const denytextareaerr = document.getElementById('denyerr');
            const denyfileerr = document.getElementById('denyemptyfile');
            denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denytextarea.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denyfile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denyfile.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denytextareaerr.classList.add('contact-form-data__error-field--hidden');
            denyfileerr.classList.add('contact-form-data__error-field--hidden');
            denyfile.value = '';
            denytextarea.value = '';
            document.getElementById('denyfileText').innerHTML = '';
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                console.log(this);
                this.UISButtonsUtil.displayPopUpReview(formElement);
            } else if (e.target.matches(`#${ID.CANCELBUTTON}`)) {
                e.preventDefault();
                console.log(this);
                this.UISButtonsUtil.displayPopUpCancel(formElement);
            } else if (e.target.matches(`#${ID.DENYBUTTON}`)) {
                e.preventDefault();
                console.log(this);
                this.UISButtonsUtil.displayPopUp(formElement);
            } else if (e.target.matches(`#${ID.DENYPOPUP}`)) {
                e.preventDefault();
                if (this.UISButtonsUtil.denyvalidateAllFields()) {
                    document.getElementById('clickHereToEdit').style.display = 'none';
                    document.getElementById('uis-denyloader').innerHTML = '<div class="uis-dashboard__loader-submit" id="submit-loader"></div><span class="check-form__error-field">Please wait...</span>';
                    const fileData = new FormData();
                    fileData.set('uisId', this.uisId);
                    fileData.set('status', 'Denied');
                    fileData.set('comment', document.getElementById(`${ID.COMMENT}`).value);
                    fileData.set('emailAddress', document.getElementById('email-address').value);
                    fileData.set('topicOfUis', topicOfUis);
                    fileData.set('name', document.getElementById('name').value);
                    fileData.set('creationDate', creationDate);
                    const denyFiles = document.getElementById(`${ID.DENYFILES}`).files.length;
                    for (let index = 0; index < denyFiles; index += 1) {
                        fileData.set('attachments', document.getElementById(`${ID.DENYFILES}`).files[index]);
                    }

                    this.Ajax.ajaxMultipartPostFn(this.url, fileData, (success) => {
                        document.getElementById('uis-denyloader').style.display = 'none';
                        document.getElementById('pop-up-id-deny').style.display = 'none';
                        if (success !== 'false') {
                            document.getElementById('deny-popup').style.display = 'none';
                            document.getElementById(`${ID.DENYBUTTON}`).style.display = 'none';
                            document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'none';
                            document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'none';
                            const denyDisplay = document.getElementById(`${ID.DISPLAY_DENIED}`);
                            denyDisplay.innerHTML = `<div class="uis-buttons__deny"><button type="button" 
                        data-dismiss="modal"><p class="uis-buttons__btn--primary uis-buttons__btn">Denied</p></button>
                        </div>`;
                        } else {
                            this.UISButtonsUtil.displayPopUperr('Submission failed');
                        }
                    });
                }
            } else if (e.target.matches(`#${ID.COMPLETEPOPUP}`)) {
                e.preventDefault();
                if (this.UISButtonsUtil.validateAllFields()) {
                    document.getElementById('clickHereToEdit').style.display = 'none';
                    document.getElementById('uis-reviewloader').innerHTML = '<div class="uis-dashboard__loader-submit" id="submit-loader"></div><span class="check-form__error-field">Please wait...</span>';
                    const fileData = new FormData();
                    fileData.set('uisId', this.uisId);
                    fileData.set('status', 'Reviewed');
                    fileData.set('comment', document.getElementById(`${ID.TEXT_AREA}`).value);
                    fileData.set('emailAddress', document.getElementById('email-address').value);
                    fileData.set('name', document.getElementById('name').value);
                    fileData.set('topicOfUis', topicOfUis);
                    fileData.set('creationDate', creationDate);
                    const reviewFiles = document.getElementById(`${ID.FILES}`).files.length;
                    for (let index = 0; index < reviewFiles; index += 1) {
                        fileData.set('attachments', document.getElementById(`${ID.FILES}`).files[index]);
                    }
                    this.Ajax.ajaxMultipartPostFn(this.url, fileData, (success) => {
                        document.getElementById('uis-reviewloader').style.display = 'none';
                        document.getElementById('pop-up-id-Review').style.display = 'none';
                        if (success !== 'false') {
                            document.getElementById('complete-popup').style.display = 'none';
                            document.getElementById(`${ID.DENYBUTTON}`).style.display = 'none';
                            document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'none';
                            document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'none';
                            const reviewDisplay = document.getElementById(`${ID.DISPLAY_REVIEWED}`);
                            reviewDisplay.innerHTML = `<div class="uis-buttons__review"><button type="button" 
                               data-dismiss="modal"><p class="uis-buttons__btn--primary uis-buttons__btn">Reviewed</p></button>
                               </div>`;
                        } else {
                            this.UISButtonsUtil.displayPopUperr('Submission failed');
                        }
                    });
                }
            } else if (e.target.matches(`#${ID.CANCELPOPUP}`)) {
                e.preventDefault();
                document.getElementById('clickHereToEdit').style.display = 'none';
                document.getElementById('uis-cancel-loader').innerHTML = '<div class="uis-dashboard__loader-submit" id="submit-loader"></div><span class="check-form__error-field">Please wait...</span>';
                const fileData = new FormData();
                fileData.set('uisId', this.uisId);
                fileData.set('status', 'Canceled');
                fileData.set('emailAddress', document.getElementById('email-address').value);
                fileData.set('topicOfUis', topicOfUis);
                fileData.set('creationDate', creationDate);
                fileData.set('name', document.getElementById('name').value);
                this.Ajax.ajaxMultipartPostFn(this.url, fileData, (success) => {
                    document.getElementById('uis-cancel-loader').style.display = 'none';
                    document.getElementById('pop-up-id-cancel').style.display = 'none';
                    if (success !== 'false') {
                        document.getElementById(`${ID.DENYBUTTON}`).style.display = 'none';
                        document.getElementById(`${ID.CANCELBUTTON}`).style.display = 'none';
                        document.getElementById(`${ID.SUBMITBUTTON}`).style.display = 'none';
                        const cancelDisplay = document.getElementById(`${ID.DISPLAY_CANCELED}`);
                        cancelDisplay.innerHTML = `<div class="uis-buttons__deny"><button type="button"
                         data-dismiss="modal"><p class="uis-buttons__btn--primary uis-buttons__btn">Canceled</p></button>
                        </div>`;
                    } else {
                        this.UISButtonsUtil.displayPopUperr('Submission failed');
                    }
                });
            }
        });
    }
}
document.addEventListener(EVENTS.CHANGE, (e) => {
    if (e.target.matches(`#${ID.FILES}`)) {
        e.preventDefault();
        const subButton = document.getElementById('complete-popup');
        const emptytext = document.getElementById(`${ID.EMPTYFILE}`);
        const completefileid = document.getElementById('submit-fileID');
        const file = e.target.value;
        const ext = file.split('.').pop();
        if (ext === 'pdf' || ext === 'docx' || ext === 'doc' || ext === 'jpeg' || ext === 'jpg'
             || ext === 'png' || ext === 'PDF' || ext === 'DOCX' || ext === 'DOC' || ext === 'JPEG' || ext === 'JPG' || ext === 'PNG') {
            subButton.removeAttribute('disabled');
            emptytext.classList.add('contact-form-data__error-field--hidden');
            completefileid.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            completefileid.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            document.getElementById('fileText').innerHTML = '';
        } else {
            subButton.setAttribute('disabled', 'disabled');
            emptytext.classList.add('contact-form-data__error-field--hidden');
            completefileid.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            completefileid.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            document.getElementById('fileText').innerHTML = `${ext} format is not supported`;
            setTimeout(() => {
                document.getElementById('submit-fileID').value = '';
            }, 100);
        }
    }
});
document.addEventListener(EVENTS.CHANGE, (e) => {
    if (e.target.matches(`#${ID.DENYFILES}`)) {
        e.preventDefault();
        const subButton = document.getElementById('deny-popup');
        const denyemptytext = document.getElementById('denyemptyfile');
        const denyfileid = document.getElementById('DenyFilesText');
        const file = e.target.value;
        const ext = file.split('.').pop();
        if (ext === 'pdf' || ext === 'docx' || ext === 'doc' || ext === 'jpeg' || ext === 'jpg'
             || ext === 'png' || ext === 'PDF' || ext === 'DOCX' || ext === 'DOC' || ext === 'JPEG' || ext === 'JPG' || ext === 'PNG') {
            subButton.removeAttribute('disabled');
            denyemptytext.classList.add('contact-form-data__error-field--hidden');
            denyfileid.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            denyfileid.classList.add(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            document.getElementById('denyfileText').innerHTML = '';
        } else {
            subButton.setAttribute('disabled', 'disabled');
            denyemptytext.classList.add('contact-form-data__error-field--hidden');
            denyfileid.classList.remove(`${CLASS.CONTACT_FORM_CONTROL_SUCCESS}`);
            denyfileid.classList.add(`${CLASS.CONTACT_FORM_CONTROL_ERROR}`);
            document.getElementById('denyfileText').innerHTML = `${ext} format is not supported`;
            setTimeout(() => {
                document.getElementById('DenyFilesText').value = '';
            }, 100);
        }
    }
});

