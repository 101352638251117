// Import all required modules
import BackToTop from 'partials/BackToTop';
import Header from 'modules/Header';
import { StickyNav } from 'partials/sticky-nav';
import TabContainer from 'partials/TabContainer';
import ButtonLinkList from 'modules/ButtonLinkList';
import FilteredList from 'modules/FilteredList';
import DropDown from 'modules/DropDown';
import ModalPopUp from 'modules/ModalPopUp';
import SiteMap from 'modules/SiteMap';
import HeroCarousel from 'modules/HeroCarousel';
import AlertBox from 'modules/AlertBox';
import Microsites from 'modules/Microsites';
import AddFavorites from 'modules/AddFavorites';
import RemoveFavorites from 'modules/RemoveFavorites';
import FavoritesList from 'modules/FavoritesList';
import AlphabeticalSearch from 'modules/AlphabeticalSearch';
import FleetInfoCanSearch from 'modules/FleetInfoCanSearch';
import BrcRestrict from 'modules/BrcRestrict';
import Print from 'modules/Print';
import PrintAll from 'modules/PrintAll';
import ButtonMatrix from 'modules/ButtonMatrix';
import Pagination from 'modules/Pagination';
import LinkListV2 from 'modules/LinkListV2';
import AccordionBanner from 'modules/AccordionBanner';
import Footer from 'modules/Footer';
import StarTunedArticles from 'modules/StarTunedArticles';
import ContactUsMBCollisionCenter from 'modules/ContactUsMBCollisionCenter';
import ContactUsTechCareer from 'modules/ContactUsTechCareer';
import PartsProLocator from 'modules/PartsProLocator';
import WaypointNav from 'modules/WaypointNav';
import ApplyNow from 'modules/ApplyNow';
import BrcSearch from 'modules/BrcSearch';
import ContactUsMBWholesaleParts from 'modules/ContactUsMBWholesaleParts';
import SubscribeForm from 'modules/SubscribeForm';
import LatestNews from 'modules/LatestNews';
import VehicleModel from 'modules/VehicleModel';
import Dog from 'modules/DOG';
import DogVehicleClassesContainer from 'modules/DogVehicleClassesContainer';
import DogVehicleBodystyleExplorer from 'modules/DogVehicleBodystyleExplorer';
import DogVehicleBodystyleExplorerAMG from 'modules/DogVehicleBodystyleExplorerAMG';
import ModelSpecification from 'modules/ModelSpecification';
import DogArchive from 'modules/DogArchive';
import DogStickyNav from 'modules/DogStickyNav';
import DogWidget from 'modules/DogWidget';
import DogRevisions from 'modules/DogRevisions';
import DogAccordion from 'modules/DogAccordion';
import MediaText from 'modules/MediaText';
import Gallery from 'modules/Gallery';
import ContactUsDOG from 'modules/ContactUsDOG';
import DOGModal from 'modules/DOGModal';
import PrependUrl from 'modules/PrependUrl';
import EQReadinessProgram from 'modules/EQReadinessProgram';
import MBRegistrationForm from 'modules/MBRegistrationForm';
import EmployeePurchase from 'modules/EmployeePurchase';
import DigitalShopping from 'modules/DigitalShopping';
import DseDms from 'modules/DseDms';
import BluetecUpdate from 'modules/BluetecUpdate';
import StyleGuideReCAPTCHA from 'modules/StyleGuideReCAPTCHA';
import ConcurEnroll from 'modules/ConcurEnroll';
import AirbagAvailability from 'modules/AirbagAvailability';
import VinRecall from 'modules/VinRecall';
import TakataLearnMore from 'modules/TakataLearnMore';
import EmailOk from 'modules/EmailOk';
import MeetingsEvents from 'modules/MeetingsEvents';
import DealerLookup from 'modules/DealerLookup';
import ContactUsAutohaus from 'modules/ContactUsAutohaus';
import CacSuggestion from 'modules/CacSuggestion';
import FAQV2 from 'modules/FAQV2';
import FAQV4 from 'modules/FAQV4';
import StarPartsDynamic from 'modules/StarPartsDynamic';
import CorporateSalesReport from 'modules/CorporateSalesReport';
import Table from 'modules/Table';
import FleetInfo from 'modules/FleetInfo';
import CanSearchAndUpdate from 'modules/CanSearchAndUpdate';
import FleetEntitlementLabel from 'modules/FleetEntitlementLabel';
import FepEab from 'modules/FepEab';
import CanApplicationPopupFormValidation from 'modules/CanApplicationPopupFormValidation';
import FepEabFilter from 'modules/FepEabFilter';
import QuizComponent from 'modules/QuizComponent';
import RangeSlider from 'modules/RangeSlider';
import FmlaRequest from 'modules/FmlaRequest';
import PrependAscUrl from 'modules/PrependAscUrl';
import PplRequest from 'modules/PplRequest';
import EmpAuthRequest from 'modules/EmpAuthRequest';
import PensionRequest from 'modules/PensionRequest';
import PreferredDealerWidget from 'modules/preferred-dealer';
import QuizComponentMicrosites from 'modules/QuizComponentMicrosites';
import MbmLeaseReturn from 'modules/MbmLeaseReturn';
import MbmLeaseReport from 'modules/MbmLeaseReport';
import MbmLeaseReturnEdit from 'modules/MbmLeaseReturnEdit';
import MbmModalPopUp from 'modules/MbmModalPopUp';
import MbmLeaseDataMigration from 'modules/MbmLeaseDataMigration';
import VinDecoder from 'modules/VinDecoder';
import ApplyNowBrc from 'modules/ApplyNowBrc';
import Notification from 'modules/Notification';
import BrcForm from 'modules/BrcForm';
import AccessRestrict from 'modules/AccessRestrict';
import BrcFormReport from 'modules/BrcFormReport';
import HomeOfficeSupportRequest from 'modules/HomeOfficeSupportRequest';
import HomeOfficeSupportEdit from 'modules/HomeOfficeSupportEdit';
import ExcelSheetDisplay from 'modules/ExcelSheetDisplay';
import InstructorRequest from 'modules/InstructorRequest';
import GetStartedRequest from 'modules/GetStartedRequest';
import BrcFavourite from 'modules/BrcFavourite';
import GetBrcFavourite from 'modules/GetBrcFavourite';
import BrcSitesReport from 'modules/BrcSitesReport';
import DiscoverTalentRequest from 'modules/DiscoverTalentRequest';
import AlternateLinkListDownload from 'modules/AlternateLinkListDownload';
import DogWhatsNew from 'modules/DogWhatsNew';
import AddWorkflowUser from 'modules/AddWorkflowUser';
import DeleteRecord from 'modules/DeleteRecord';
import DataDeletion from 'modules/DataDeletion';
import BRCFieldCastForm from 'modules/BRCFieldCastForm';
import BrcFieldCastSubmissionReport from 'modules/BrcFieldCastSubmissionReport';
import CompWatermark from 'modules/CompWatermark';
import PageStickyNav from 'modules/PageStickyNav';
import CorpSocialRequest from 'modules/CorpSocialRequest';
import UISDashboard from 'modules/UISDashboard';
import UISCreateForm from 'modules/UISCreateForm';
import ConfirmButton from 'modules/ConfirmButton';
import UserCommentBox from 'modules/UserCommentBox';
import UISButtons from 'modules/UISButtons';
import UisNavigation from 'modules/UisNavigation';
import UISReadOnlyScreen from 'modules/UISReadOnlyScreen';
import UISEditForm from 'modules/UISEditForm';
import UISModalPopUp from 'modules/UISModalPopUp';
import NCURemoveModalPopUp from 'modules/NCURemoveModalPopUp';
import StopDrive from 'modules/StopDrive';
import StopdriveDashboard from 'modules/StopdriveDashboard';
import VehicleEndDateUpdate from 'modules/VehicleEndDateUpdate';
import CVPDashboard from 'modules/CVPDashboard';
import NCUFieldCastForm from 'modules/NCUFieldCastForm';
import NCUSubmissionReport from 'modules/NCUSubmissionReport';
import GcButton from 'modules/GcButton';
import VideoPopUp from 'modules/VideoPopUp';
import OrderRequestListing from 'modules/OrderRequestListing';
import EmailUsForm from '../../../modules/EmailUsForm';
import BulkCanUploadForm from '../../../modules/fleet-info/BulkCanUploadForm';
import WarrantyFeedback from 'modules/WarrantyFeedback';
import AccessRestrictWarranty from 'modules/AccessRestrictWarranty';
import HeaderCustom from 'modules/HeaderCustom';
import TitleAnimation from 'modules/TitleAnimation';
import MediaIconTitleAnimation from 'modules/MediaIconTitleAnimation';

// Export reference to all modules in an object
export default {
    Microsites,
    BackToTop,
    Header,
    StickyNav,
    TabContainer,
    Footer,
    ButtonLinkList,
    FilteredList,
    DropDown,
    SiteMap,
    ModalPopUp,
    HeroCarousel,
    AlertBox,
    AddFavorites,
    FavoritesList,
    RemoveFavorites,
    AlphabeticalSearch,
    Print,
    PrintAll,
    ButtonMatrix,
    Pagination,
    LinkListV2,
    AccordionBanner,
    StarTunedArticles,
    ContactUsMBCollisionCenter,
    PartsProLocator,
    ContactUsTechCareer,
    WaypointNav,
    ApplyNow,
    ContactUsMBWholesaleParts,
    SubscribeForm,
    LatestNews,
    VehicleModel,
    Dog,
    DogVehicleClassesContainer,
    DogVehicleBodystyleExplorer,
    DogVehicleBodystyleExplorerAMG,
    ModelSpecification,
    DogArchive,
    PrependUrl,
    DogStickyNav,
    DogWidget,
    DogRevisions,
    DogAccordion,
    MediaText,
    Gallery,
    ContactUsDOG,
    DOGModal,
    EQReadinessProgram,
    MBRegistrationForm,
    EmployeePurchase,
    DigitalShopping,
    DseDms,
    BluetecUpdate,
    StyleGuideReCAPTCHA,
    ConcurEnroll,
    AirbagAvailability,
    VinRecall,
    TakataLearnMore,
    EmailOk,
    MeetingsEvents,
    DealerLookup,
    ContactUsAutohaus,
    CacSuggestion,
    FAQV2,
    FAQV4,
    StarPartsDynamic,
    Table,
    CorporateSalesReport,
    FleetInfo,
    CanSearchAndUpdate,
    FleetEntitlementLabel,
    FepEab,
    CanApplicationPopupFormValidation,
    FepEabFilter,
    QuizComponent,
    RangeSlider,
    FmlaRequest,
    PrependAscUrl,
    PplRequest,
    EmpAuthRequest,
    PensionRequest,
    QuizComponentMicrosites,
    MbmLeaseReturn,
    MbmLeaseReport,
    MbmLeaseReturnEdit,
    MbmModalPopUp,
    MbmLeaseDataMigration,
    VinDecoder,
    PreferredDealerWidget,
    ApplyNowBrc,
    Notification,
    BrcForm,
    BrcSearch,
    AccessRestrict,
    BrcFormReport,
    HomeOfficeSupportRequest,
    HomeOfficeSupportEdit,
    ExcelSheetDisplay,
    InstructorRequest,
    GetStartedRequest,
    BrcFavourite,
    GetBrcFavourite,
    BrcSitesReport,
    FleetInfoCanSearch,
    BrcRestrict,
    DiscoverTalentRequest,
    AlternateLinkListDownload,
    DogWhatsNew,
    AddWorkflowUser,
    DeleteRecord,
    DataDeletion,
    BRCFieldCastForm,
    BrcFieldCastSubmissionReport,
    CompWatermark,
    PageStickyNav,
    CorpSocialRequest,
    UISDashboard,
    UISCreateForm,
    ConfirmButton,
    UserCommentBox,
    UISButtons,
    UisNavigation,
    UISEditForm,
    UISReadOnlyScreen,
    UISModalPopUp,
    VehicleEndDateUpdate,
    CVPDashboard,
	GcButton,
	VideoPopUp,
    NCUFieldCastForm,
    NCUSubmissionReport,
    OrderRequestListing,
    EmailUsForm,
    WarrantyFeedback,
	AccessRestrictWarranty,
    NCURemoveModalPopUp,
    StopDrive,
    BulkCanUploadForm,
    StopdriveDashboard,
    HeaderCustom,
    TitleAnimation,
    MediaIconTitleAnimation
};
