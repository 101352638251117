import { EVENTS, CLASSES } from 'Constants';
import Ajax from 'modules/Ajax';
import ContactUsUtil from 'modules/ContactUsUtil';

const ID = {
    SUBMITBUTTON: 'mbWholesalePartsContactFormSubmit',
    MB_WHOLESALE_PARTS_CONTACT_US: 'mb-wholesale-parts-contact-us',
    SITE_NAME: 'site-name',
};

export default class ContactUsMBWholesaleParts {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'mbWholesaleParts';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.ContactUsUtil = new ContactUsUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (this.ContactUsUtil.validateAllFields() && captchaResponse.length !== 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById(`${ID.MB_WHOLESALE_PARTS_CONTACT_US}`);
                    const inputs = form.elements;
                    const formToJSONObject = elements => [].reduce.call(elements,
                                (data, element) => {
                                    data[element.name] = element.value;
                                    return data;
                                }, {});

                    console.log(formToJSONObject(inputs));

                    const siteName = document.getElementById(`${ID.SITE_NAME}`).value;
                    const url = '/bin/microsites/mb-wholesaleparts/contact-us/sendEmail';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.ContactUsUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.ContactUsUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONTACT_FORM_DATA__CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ContactUsUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="contact-form-data__error-field">This field is required.</span>';
                    this.ContactUsUtil.validateAllFields();
                }
            }
        });
    }
}
