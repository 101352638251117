
export default (partNameList = [], emissionModificationCompletionStatus) => `
    ${partNameList.map((partName) => {
        let classValue = '';
        if (emissionModificationCompletionStatus === 'Yes') {
            classValue = 'icon-check';
        } else {
            classValue = 'icon-cross';
        }
        return `
        <div class="bluetecupdate-form__column-info-row">
            <div class="bluetecupdate-form__column-info-column">
                ${partName}
            </div>
            <div class="bluetecupdate-form__column-info-column">
                <span class="bluetecupdate-form__column-info-icon ${classValue}"></span>
            </div>
        </div>
        `;
    }).join('')}
`;
