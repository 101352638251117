import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import GetStartedUtil from 'modules/GetStartedUtil';


const ID = {
    SUBMITBUTTON: 'ConfirmButtonButton'

};

// const formElement = '';

export default class ConfirmButton {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.GetStartedUtil = new GetStartedUtil(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                console.log(this);
                const formElement = `  <div>
            <h5 class="modal-title">Thank you, submission is successful. </h5>
            <h6 class="modal-title">Your request number is ***. </h6>
        </div>`;

                this.GetStartedUtil.displayPopUp(formElement);
            }
        });
    }
}
