/**
 * @const defaultLocalization
 * @description Default localization labels for "More Info" window
 * buildThisVehicle: The label for the "Build" cta.
 * download: The label for the "Download" cta.
 * @type {{buildThisVehicle: String, downlod: String}}
 */
const defaultLocalization = {
    buildThisVehicle: 'Build This Vehicle',
    download: 'Download',
    galleryFilters: {
        all: 'All',
        interior: 'Interior',
        exterior: 'Exterior',
        media: 'Media'
    }
};

// Deconstruct localization object
const {
    buildThisVehicle,
    download,
    galleryFilters
} = defaultLocalization;

/**
 * @const MEDIA_TYPES
 * @description Enum with constant strings for media types of gallery
 */
const MEDIA_TYPES = {
    ALL: 'all',
    MEDIA: 'media',
    IMAGE: 'image'
};

/**
 * @function composeCTAs
 * @description Based on the gallery item data build the available CTAs
 * @param {Object} galleryItem
 * @return {Array} An array of objects with the following structure:
 * {
 *   type: 'link',
 *   endpoint: byoCTA,
 *   target: '_self',
 *   rel: 'noopener',
 *   className: '',
 *   label: buildThisVehicle
 * }
 */
function composeCTAs(galleryItem) {
    // Empty array to build CTAs into
    const CTAs = [];
    const defaultCTA = {
        type: 'link',
        endpoint: '',
        target: '_self',
        rel: 'noopener',
        className: '',
        label: ''
    };

    // Deconstruct gallery object
    const {
        media: {
            byoCTA,
            wallpaperImage
        }
    } = galleryItem;

    // Add BYO CTA
    if (byoCTA) {
        CTAs.push({
            ...defaultCTA,
            ...{ endpoint: byoCTA, label: buildThisVehicle, dataAnalyticsTrigger: 'cta-build' }
        });
    }

    // Add wallpaper CTA
    if (wallpaperImage) {
        CTAs.push({
            ...defaultCTA,
            ...{ endpoint: wallpaperImage, target: '_blank', rel: 'noopener', className: 'download', label: download, type: 'download' }
        });
    }

    return CTAs;
}

/**
 * @function composeMediaObject
 * @description Based on the gallery item data build the media property
 * @param {Object} galleryItem
 * @return {Object} Media property of new gallery item with following structure:
 * {
 *   thumbnailImage: "",
 *   imgAltText: "",
 *   imgLarge: "",
 *   imgSmall: null,
 *   overlayImage: "",
 *   html5video: null,
 *   youtube: ""
 * }
 */
function composeMediaObject(galleryItem) {
    // IIFE which accepts galleryItem as param
    // The param is deconstructed and the function returns
    // an object with just the necessary values
    return (({
        media: { thumbnailImage, overlayImage },
        multimediaAsset: { imgAltText, imgLarge, imgSmall, youtube }
    }) => (
        {
            thumbnailImage,
            overlayImage,
            imgSmall,
            imgLarge,
            imgAltText,
            youtube
        }
    ))(galleryItem);
}

/**
 * @function composeDetailsObject
 * @description Based on the gallery item data build the details property
 * @param {Object} galleryItem
 * @return {Object} Details property of new gallery item with following structure:
 * {
 *   CTAs: [],
 *   modelTitle: "",
 *   modelPath: "",
 *   captionHeading: "",
 *   captionDescription: ""
 * }
 */
function composeDetailsObject(galleryItem) {
    // IIFE which accepts galleryItem as param
    // The param is deconstructed and the function returns
    // an object with just the necessary values
    return (({
        media: { modelTitle, modelPath, captionHeading, captionDescription }
    }) => (
        {
            modelTitle,
            modelPath,
            captionHeading,
            captionDescription,
            CTAs: composeCTAs(galleryItem)
        }
    ))(galleryItem);
}

/**
 * @function composeGalleryItem
 * @description Transforms galleryItem into an object with media and details sub-objects
 * @param {Object} galleryItem
 * @return {Object} Object with following structure
 * {
 *    galleryCategory: "exterior",
 *    mediaType: "youtube",
 *    media: {
 *        thumbnailImage: "",
 *        imgAltText: "",
 *        imgLarge: "",
 *        imgSmall: null,
 *        overlayImage: "",
 *        html5video: null,
 *        youtube: ""
 *    },
 *    details: {
 *        modelTitle: "",
 *        modelPath: "",
 *        captionHeading: "",
 *        captionDescription: "",
 *        CTAs: []
 *    }
 * }
 */
function composeGalleryItem(galleryItem) {
    return {
        galleryCategory: galleryItem.galleryCategory,
        mediaType: galleryItem.mediaType,
        media: composeMediaObject(galleryItem),
        details: composeDetailsObject(galleryItem)
    };
}


/**
 * @function getFilters
 * @description Gets the list of filters applicable for the gallery data
 * @param {Objet} galleryData Galleyr Data
 * @return {Array} An array of objects, each object { label: "All", value: "all" }
 */
function getFilters(galleryData) {
    const getMediaFilter =
        galleryData
            .map(e => e.galleryItems)
            .flatten()
            .filter(item => item.mediaType !== MEDIA_TYPES.IMAGE)
            .map(() => MEDIA_TYPES.MEDIA)
            .unique();

    return [MEDIA_TYPES.ALL]
         // Gets unique category for each gallery item
        .concat(galleryData.map(item => item.galleryCategory).unique())
        .concat(getMediaFilter)
         // Gets translations of filter for current site
        .map(filter => ({
            label: galleryFilters[filter],
            value: filter
        }));
}

/**
 * @function normalizeData
 * @description Normalizes data for each gallery item object. It currently does the following:
 * 1. Modifies the galleryItem to an object with media and details sub-objects
 * 2. Adds more info CTAs to gallery items
 */
function normalizeData(galleryData) {
    const filters = getFilters(galleryData);
    const data = galleryData.map((galleryRow) => {
        galleryRow.galleryItems = galleryRow.galleryItems.map(composeGalleryItem);

        return galleryRow;
    });

    return {
        filters,
        data
    };
}

/**
 * @method getData
 * @description Fetches a collection of gallery data from an object on the window
 * @return {Promise}
 */
function getData({ endpoint = '' } = {}) {
    if (endpoint) {
        return fetch(endpoint, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(normalizeData);
    }
    return Promise.reject('Gallery endpoint not defined');
}

/**
 * @method parseOnlyMedia
 * @description takes an array of gallery groups and parses into a new one
 * that only contains galleryItems of media type different than image
 * @param data {Array} data to filter from
 * @returns {Array} array of parsed gallery groups
 */
function parseOnlyMedia(data) {
    const defaultMediaGroup = {
        type: 'gallery-tile-double',
        galleryCategory: '',
        galleryItems: []
    };

    const getAllNonImageMediaItems = (items, group) => (
        items.concat(
            group.galleryItems.filter(item => item.mediaType !== MEDIA_TYPES.IMAGE)
        )
    );

    const convertMediaItemsToMediaGroups = (groups, mediaItem, index) => {
        if (index % 2 === 0) {
            const mediaGroup = Object.assign({}, defaultMediaGroup);
            mediaGroup.galleryItems.push(mediaItem);
            groups.push(mediaGroup);
        } else {
            groups[groups.length - 1].galleryItems.push(mediaItem);
        }
        return groups;
    };

    return data
        .reduce(getAllNonImageMediaItems, [])
        .reduce(convertMediaItemsToMediaGroups, []);
}

/**
 * @method filterData
 * @description Filters down the gallery groups by category or media
 * @param data {Array} data to filter from
 * @param category {string} category value
 * @param media {boolean} true if should filter by media type (return the
 * items that are not images)
 * @returns {Array} array of filtered gallery groups
 */
function filterData(data, category = 'all') {
    if (category === MEDIA_TYPES.MEDIA) {
        return parseOnlyMedia(data);
    }

    if (category === 'all') {
        return data;
    }

    const filterByCategory = galleryGroup => (
        galleryGroup.galleryCategory === category
    );

    return data.filter(filterByCategory);
}


// export public api
export default {
    getData,
    filterData
};
