import React, { Component } from 'react';
import { ModalComponent as Modal } from 'partials/modal/react';
import { content } from './../config';

class ErrorMessageModal extends Component {
    constructor(props) {
        super(props);

        this.modal = null;
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    componentDidMount() {
        this.modal.open();
    }

    onCloseModal() {
        this.modal.close();
    }

    render() {
        return (
            <Modal sizeSmall={Modal.SIZES.FULLSCREEN}
                sizeLarge={Modal.SIZES.INSET}
                ref={(modal) => { this.modal = modal; }}
                beforeClose={this.onCloseModal}
                dataAnalyticContainer="{'blade': 'remediation-modal'}"
            >
                <div className="order-confirmation-modal" style={{ padding: '1.5rem' }}>
                    <h2 className="title-hero__title order-confirmation-modal__heading" style={{ margin: 0 }}>
                        {content.somethingWentWrong}
                    </h2>
                </div>
            </Modal>
        );
    }
}

export default ErrorMessageModal;
