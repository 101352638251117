import React, { Component } from 'react';
import {
    formatNumber,
    noop,
    formatDate
} from 'utils';

import { content, pageDataObject } from '../config';
import CancelOrderRequestModal from './CancelOrderRequestModal';
import RequestProductionDateModal from './RequestProductionModal';

const toCurrency = formatNumber.toCurrency || noop;

const SWT_ORDER_SUBMITTED = 'swt-order-submitted';
const DEALER_REJECTED = 'dealer-rejected';


class OrderRequestCard extends Component {
    constructor(properties) {
        super(properties);

        this.state = {
            status: this.props.status,
            isShowCancelConfirmation: false,
            isShowProductionDateModal: false
        };

        this.changeStatus = this.changeStatus.bind(this);
        this.updateOrderStatus = this.updateOrderStatus.bind(this);
        this.formatOrderRequestId = this.formatOrderRequestId.bind(this);
        this.showCancelOrderRequestModal = this.showCancelOrderRequestModal.bind(this);
        this.showRequestProductionDateModal = this.showRequestProductionDateModal.bind(this);
        this.hideRequestProductionDateModal = this.hideRequestProductionDateModal.bind(this);
    }


    formatPrice(price = 0) {
        const {
            language,
            country,
            currency
        } = pageDataObject;
        return toCurrency(price, country, language, currency);
    }

    formatOrderRequestId(id) {
        const lastSixDigitOfId = id.substr(id.length - 6)
        return `Order ID: ***${lastSixDigitOfId}`
    }


    disableLink(event) {
        event.preventDefault();
    }

    changeStatus(status) {
        const {
            dealerCode,
            changeOrderStatus,
            requestId
        } = this.props;

        changeOrderStatus(dealerCode, requestId, status);
    }

    updateOrderStatus(productionDateValue = null) {
        const {
            dealerCode,
            changeOrderStatus,
            requestId
        } = this.props;
        changeOrderStatus(dealerCode, requestId, SWT_ORDER_SUBMITTED, productionDateValue);
    }

    showCancelOrderRequestModal() {
        this.setState({
            isShowCancelConfirmation: !this.state.isShowCancelConfirmation
        });
    }

    showRequestProductionDateModal() {
        this.setState({
            isShowProductionDateModal: true
        });
    }

    hideRequestProductionDateModal(productionDateValue = null) {
        this.setState({
            isShowProductionDateModal: false
        }, () => {
            if (productionDateValue) {
                this.updateOrderStatus(productionDateValue)
            }
        });
    }

    render() {
        const {
            orderDetails: {
                buildHistory,
                creationDate,
                dealerDetails,
                user,
                sam: {
                    name: samUserName = ''
                }
            },
            requestId
        } = this.props;

        const distributedQuantity = dealerDetails[0].distributedQuantity;

        const {
            status
        } = this.state;

        const buildHistoryArray = buildHistory.length;
        const buildJSON = buildHistory[buildHistoryArray - 1];

        let features = buildJSON.components;
        if (Array.isArray(buildJSON.components)) {
            features = (buildJSON.components).map(includePart => includePart.name).toString() || [];
        }

        const isDisableCancelButton = status === SWT_ORDER_SUBMITTED || status === DEALER_REJECTED;

        return (
            <div className="order-request-card" >
                <div className="saved-build-card-ap saved-build-card-ap--main ">
                    <div className="inventory-favorites-card-ap__wrapper">
                        <div
                            aria-hidden="false"
                            className="inventory-favorites-card-ap__card-body">
                            <div className="vehicle-grid-card">
                                <div className="vehicle-grid-card__body">
                                    <p className='order-request-card__status'>{`${content.status}: ${status}`}</p>
                                    <div className="vehicle-grid-card__hero vehicle-grid-card__hero--full-carousel">
                                        <div className="vehicle-grid-visuals">
                                            <div className="vehicle-grid-visuals__mask ">
                                                <div className="vehicle-grid-visuals__masked-item">
                                                    <div className="picture-component">
                                                        <img alt=""
                                                            className="responsive-image"
                                                            src={buildJSON.imgUrl} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="vehicle-grid-visuals__labels">
                                                <p className="vehicle-grid-visuals__image-label">
                                                    {buildJSON.exteriorColor}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="vehicle-grid-highlights">
                                            <div className="vehicle-grid-highlights__info-title">

                                                <h2 id=""
                                                    className="vehicle-grid-highlights__info-title--model-title"
                                                    aria-label="">
                                                    {buildJSON.vehicleModel}
                                                </h2>
                                            </div>
                                            <div className="vehicle-grid-highlights__info-cost--wrapper">
                                                <div className='vehicle-grid-card__center-text'>
                                                    <p className="vehicle-grid-highlights__info-cost--label">
                                                        {content.quantity}
                                                    </p>
                                                    <span className="vehicle-grid-highlights__info-cost">
                                                        {distributedQuantity}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vehicle-grid-features ">
                                            <div className="vehicle-grid-features__info ">
                                                <ul className="vehicle-grid-features__info--list">
                                                    {/* <li className="vehicle-grid-features__info-feature">
                                                        <span className="vehicle-grid-features__info-feature--description">
                                                            {requestId}
                                                        </span>
                                                    </li> */}
                                                    <li className="vehicle-grid-features__info-feature">
                                                        <div>
                                                            <p className="vehicle-grid-highlights__info-cost--label">
                                                                {user.companyName}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    {samUserName && (<li className="vehicle-grid-features__info-feature">
                                                        <div>
                                                            <p className="vehicle-grid-highlights__info-cost--label">
                                                                KAM: {samUserName}
                                                            </p>
                                                        </div>
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vehicle-grid-footer">
                                    <div className="vehicle-grid-footer__ctas vehicle-grid-footer__ctas--full-width">
                                        {!isDisableCancelButton ? <div>
                                            <div className="vehicle-grid-footer__cta">
                                                {
                                                    <button
                                                        onClick={() =>
                                                            this.showRequestProductionDateModal()
                                                        }
                                                        className={`vehicle-grid-footer__cta--button
                                                button button_primary button--full-width`}
                                                    >
                                                        {content.acceptOrder}
                                                    </button>
                                                }
                                            </div>
                                            <div className="vehicle-grid-footer__cta">
                                                <button
                                                    onClick={() =>
                                                        this.showCancelOrderRequestModal()
                                                    }
                                                    className='button order-confirmation-modal__action__button-reject button--full-width'>
                                                    {content.reject}
                                                </button>

                                            </div>
                                        </div>
                                            : <div className="vehicle-grid-footer__cta">
                                                <button
                                                    onClick={null}
                                                    className={`vehicle-grid-footer__cta--button
                                            button button_primary button--full-width disabled`}
                                                >
                                                    {content.noChange}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="vehicle-grid-footer__info">
                                        <span className="vehicle-grid-features__info-feature--description">
                                            {this.formatOrderRequestId(requestId)}
                                        </span>
                                        <span className="vehicle-grid-features__info-feature--description">
                                            {`${content.saved} `}{formatDate.toShortDate(
                                                creationDate
                                                || new Date(), 'US', false)
                                            }
                                        </span>
                                    </div>
                                    {
                                        this.state.isShowCancelConfirmation && (
                                            <CancelOrderRequestModal
                                                closeModal={this.showCancelOrderRequestModal}
                                                cancelOrder={
                                                    () =>
                                                        this.changeStatus(DEALER_REJECTED)
                                                }
                                                content={content}
                                            />
                                        )
                                    }
                                    {
                                        this.state.isShowProductionDateModal && (
                                            <RequestProductionDateModal
                                                closeModal={this.hideRequestProductionDateModal}
                                                content={content}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default OrderRequestCard;
