import { EVENTS } from 'Constants';

const ID = {
    HEADING: 'starparts-dynamic-heading',
};

export default class StarPartsDynamic {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.HEADING}`)) {
                if (e.target.classList.contains('star-parts-dynamic__icon--right-arrow-key')) {
                    e.target.classList.toggle('star-parts-dynamic__accordion_active_icon--right-arrow-key');
                }
                this.collapseOtherAccordion(e);
            }
        });
    }

    collapseOtherAccordion(e) {
        console.log(this);
        e.target.classList.remove('starparts-dynamic-heading');
        const ele = document.getElementsByClassName('starparts-dynamic-heading');
        for (let i = 0; i < ele.length; i += 1) {
            if (ele[i].classList.contains('star-parts-dynamic__accordion_active_icon--right-arrow-key')) {
                ele[i].classList.remove('star-parts-dynamic__accordion_active_icon--right-arrow-key');
            }
        }
        e.target.classList.add('starparts-dynamic-heading');
    }
}
