import config from '../config';

export default function getAllRequestedOrderListing() {
    const {
        featureConfig: {
            orderRequestListingUri
        }
    } = config;

    return fetch(orderRequestListingUri)
        .then(response => response.json())
        .then((response) => {
            if (response.statusCode === 200) {
                return response.payload;
            }
            return response;
        })
        .catch(err => err);
}


export function changeOrderStatus({ dealerCode, orderRequestId, status, productionDateValue = null}) {
    const {
        featureConfig: {
            orderRequestListingUri
        }
    } = config;

    let manageOrderRequestStatusUrl = `${orderRequestListingUri}?dealerCode=${dealerCode}&orderRequestId=${orderRequestId}&status=${status}`;
    if (productionDateValue) {
        manageOrderRequestStatusUrl = `${manageOrderRequestStatusUrl}&productionDate=${productionDateValue}`
    }

    const requestOptions = {
        method: 'PUT',
    };

    return fetch(manageOrderRequestStatusUrl, requestOptions)
        .then(response => response.json())
        .then((response) => {
            if (response.statusCode === 200) {
                if (response.payload && !response.payload.callSuccess) {
                    return Promise.reject();
                }
                return response.payload;
            }
            return Promise.reject(response);
        })
        .catch(err => Promise.reject(err));
}
