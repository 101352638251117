import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import PplRequestUtil from 'modules/PplRequestUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'ppl-request-form',
    SITE_NAME: 'site-name',
    EMPLOYEE_FIRSTNAME: 'firstName',
    EMPLOYEE_LASTNAME: 'lastName',
    EMPLOYEE_ID: 'employeeId',
    BORNDATE: 'bornDate',
    LEAVE_START_DATE: 'leaveStartDate',
    LEAVE_END_DATE: 'leaveEndDate',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    ADDRESS: 'address',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zipcode',
    SIGN_NAME: 'signName',
    SIGNED_DATE: 'signedDate',
};

export default class PplRequest {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'ppl';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.PplRequestUtil = new PplRequestUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();
                const capRes = grecaptcha.getResponse();
                if (this.PplRequestUtil.validateAllFields() && capRes.length !== 0) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;
                    console.log('Inside final if');
                    const siteName = 'ppl';
                    const url = '/bin/microsites/ppl-request/sendEmail';
                    const employeeFirstName = document.getElementById(`${ID.EMPLOYEE_FIRSTNAME}`).value;
                    const employeeLastName = document.getElementById(`${ID.EMPLOYEE_LASTNAME}`).value;
                    const employeeId = document.getElementById(`${ID.EMPLOYEE_ID}`).value;
                    const leaveStartDate = document.getElementById(`${ID.LEAVE_START_DATE}`).value;
                    const leaveEndDate = document.getElementById(`${ID.LEAVE_END_DATE}`).value;
                    const email = document.getElementById(`${ID.EMAIL}`).value;
                    const phoneNumber = document.getElementById(`${ID.PHONE_NUMBER}`).value;
                    const address = document.getElementById(`${ID.ADDRESS}`).value;
                    const city = document.getElementById(`${ID.CITY}`).value;
                    const state = document.getElementById(`${ID.STATE}`).value;
                    const zip = document.getElementById(`${ID.ZIP}`).value;
                    const signname = document.getElementById(`${ID.SIGN_NAME}`).value;
                    const signeddate = document.getElementById(`${ID.SIGNED_DATE}`).value;
                    const borndate = document.getElementById(`${ID.BORNDATE}`).value;

                    const dataMap = new FormData();

                    dataMap.set('siteName', siteName);
                    dataMap.set('employeeFirstName', employeeFirstName);
                    dataMap.set('employeeLastName', employeeLastName);
                    dataMap.set('employeeId', employeeId);
                    dataMap.set('leaveStartDate', leaveStartDate);
                    dataMap.set('leaveEndDate', leaveEndDate);
                    dataMap.set('email', email);
                    dataMap.set('phoneNumber', phoneNumber);
                    dataMap.set('address', address);
                    dataMap.set('city', city);
                    dataMap.set('state', state);
                    dataMap.set('zip', zip);
                    dataMap.set('signname', signname);
                    dataMap.set('signeddate', signeddate);
                    dataMap.set('bornDate', borndate);
                    dataMap.set('captcha', capRes);
                    grecaptcha.reset();
                    this.Ajax.ajaxMultipartPostFn(url, dataMap, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.PplRequestUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.PplRequestUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.PPL_REQUEST_FORM_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.PPL_REQUEST_FORM_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.PplRequestUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (capRes.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="ppl-request-form__error-field">This field is required.</span>';
                    this.PplRequestUtil.validateAllFields();
                }
            }
        });
    }
}
