import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    MBM_LEASE_RETURN_FORM_CONTROL: 'mbm-lease-return-form__control',
    MBM_LEASE_RETURN_FORM_CONTROL_ERROR: 'mbm-lease-return-form__control--error',
    MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS: 'mbm-lease-return-form__control--success',
    MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN: 'mbm-lease-return-form__error-field--hidden',
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content',
    PAYOFF: 'payoff',
    PRICE: 'price',
    MILEAGE: 'mileage',
};
export default class MbmLeaseReturnUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.MBM_LEASE_RETURN_FORM_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
        document.addEventListener(EVENTS.KEYUP, (el) => {
            if (el.target.matches(`#${ID.PAYOFF}`) || el.target.matches(`#${ID.PRICE}`) || el.target.matches(`#${ID.MILEAGE}`)) {
                this.insertComma(el.target);
            }
        });
        document.addEventListener(EVENTS.KEYUP, (el) => {
            if (el.target.matches(`#${ID.PAYOFF}`) || el.target.matches(`#${ID.PRICE}`) || el.target.matches(`#${ID.MILEAGE}`)) {
                this.validatePriceInput(el.target);
            }
        });
    }

    insertComma(el) {
        if (el.name === 'payoff') {
            document.getElementById('payoff').value = this.addCommas(el.value);
        }
        if (el.name === 'price') {
            document.getElementById('price').value = this.addCommas(el.value);
        }
        if (el.name === 'mileage') {
            document.getElementById('mileage').value = this.addCommas(el.value);
        }
    }

    addCommas(x) {
        console.log(this);
        const parts = x.toString().split('.');
        parts[0] = parts[0].replace(/,/g, '');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    removeComma(commaValue) {
        console.log(this);
        const partVal = commaValue.toString().split('.');
        partVal[0] = partVal[0].replace(/,/g, '');
        return partVal.join('.');
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    containAlphabetsOnly(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z\s]*$/;
        return regex.test(elementValue);
    }

    containDigitsDecimalOnly(elementValue) {
        console.log(this);
        const regex = /^(?=.*[1-9])\d*(?:\.\d{1,2})*$/;
        return regex.test(elementValue);
    }

    containAlphaNumeric(elementValue) {
        console.log(this);
        const regex = /^[a-zA-Z0-9]*$/;
        return regex.test(elementValue);
    }

    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Year Validation
     */
    isValidYear(year) {
        console.log(this);
        const regex = /^\d{4}$/;
        return regex.test(year);
    }

    /**
     * Vin Validation
     */
    isValidVin(vin) {
        console.log(this);
        const regex = /^[a-zA-Z0-9]{17}$/;
        return regex.test(vin);
    }

    validPrice(price) {
        console.log(this);
        const regex = /^\$?\d+(,\d{3})*\.?[0-9]?[0-9]?$/;
        return regex.test(price);
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.MBM_LEASE_RETURN_FORM_CONTROL}`);

        this.validateFields(inputFields);

        if (this.validateFields(inputFields) === true) {
            return true;
        }
        return false;
    }

    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.MBM_LEASE_RETURN_FORM_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'year') {
            if (el.value) {
                this.validate(el, this.isValidYear(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'custLastName' || el.name === 'color') {
            if (el.value) {
                this.validate(el, this.containAlphabetsOnly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'vin') {
            if (el.value) {
                this.validate(el, this.isValidVin(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'mbmLeaseNumber' || el.name === 'model') {
            if (el.value) {
                this.validate(el, this.containAlphaNumeric(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    validatePriceInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'payoff' || el.name === 'price' || el.name === 'mileage') {
            if (el.value) {
                this.validate(el, this.validPrice(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    displayPopUp(message) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        popUp.querySelector('h3').innerHTML = '&nbsp;';
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
