/**
 * Template for a Detailed Info Window
 * Uses ES6 template literal to composite the markup for the module that
 * renders the detailed info in google map
 * @param {Object} mapItem - An object that has the following structure
 * {
 *     name,
 *     address,
 *     city,
 *     state,
 *     zip,
 *     phone,
 *     distance, (optional)
 *     directionsLink
 * }
 * @param {Object} content - An object that has the following structure
 * {
 *     distance,
 *     getDirections
 * }
 */
import { htmlNode } from 'utils';
import { isExternalLink } from 'utils/validate';

export default (mapItem, content, options) => htmlNode `
    <div class="google-map__detailed-info-window" data-analytics-container="dl-map-info">
        ${mapItem.name ? `<h3 class="google-map__detailed-info-window-title">${mapItem.name}</h3>` : ''}
        ${mapItem.city || mapItem.state || mapItem.zip || mapItem.address || mapItem.phone ? `<div class="google-map__detailed-info-window-address">
            ${mapItem.address ? `<span class="google-map__detailed-info-window-address-1">${mapItem.address}</span>` : ''}
            ${mapItem.city || mapItem.state || mapItem.zip ? `<span class="google-map__detailed-info-window-address-2">${mapItem.city ? `${mapItem.city}` : ''}${mapItem.state ? `, ${mapItem.state}` : ''}${mapItem.zip ? `, ${mapItem.zip}` : ''}</span>` : ''}
            ${mapItem.phone ? `${(!options.isOnDisplay && !options.isDealer && !options.isWheelstand) ? `<a href="tel:${mapItem.phone}" class="google-map__detailed-info-window-phone-number" data-analytics-trigger="call-dealer">${mapItem.phoneFormatted}</a>` : `<span class="google-map__detailed-info-window-phone-number">${mapItem.phoneFormatted}<span></span>`}` : ''}
        </div>` : ''}
        ${mapItem.distance ? `<span class="google-map__detailed-info-window-distance">${content.distance}: ${mapItem.distance}</span>` : ''}
        ${mapItem.locDesc ? `<br/><span class="google-map__detailed-info-window-description">${mapItem.locDesc}</span>` : ''}
        ${(!options.isOnDisplay && !options.isDealer && !options.isWheelstand) ?
        `${content && mapItem.directionsLink && content.getDirections ? `<a class="link link_bold google-map__detailed-info-window-directions-cta" href="${mapItem.directionsLink}" ${isExternalLink(mapItem.directionsLink) ? ' target="_blank"' : ''} rel="noopener" data-analytics-trigger="cta">${content.getDirections}</a>` : ''}
        ${mapItem.getDirections && mapItem.directionsLink ? `<a class="link link_bold google-map__detailed-info-window-directions-cta" href="${mapItem.directionsLink}" ${isExternalLink(mapItem.directionsLink) ? ' target="_blank"' : ''} rel="noopener" data-analytics-trigger="cta">${mapItem.getDirections}</a>` : ''}`
        : ''}
    </div>
`;

