export default [
    'inquiryType',
    'commentBody',
    'firstName',
    'lastName',
    'phone',
    'email',
    'address',
    'city',
    'state',
    'zipCode',
    'vin',
    'mileage'
];
