import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import MBFormUtil from 'modules/MBFormUtil';

const ID = {
    SUBMITBUTTON: 'mbevent_form_submit',
    REGISTRATION_FORM: 'regnForm',
    SITE_NAME: 'mb-event',
    WINNERDAY1OPT: 'winnerday1opt',
    WINNERDAY2OPT: 'winnerday2opt',
    WINNERDAY1SPASELECTION: 'winnerday1spaselection',
    WINNERDAY1EXCURSIONSELECTION: 'winnerday1excursionselection',
    WINNERDAY2SPASELECTION: 'winnerday2spaselection',
    WINNERDAY2EXCURSIONSELECTION: 'winnerday2excursionselection',
    WINNERGOLFHANDICAP: 'winnergolfhandicap',
    WINNERGOLF: 'winnergolf',
    WINNERRENTALREQUIRED: 'winnerrentalrequired',
    WINNERGOLFLEFTRIGHTHANDED: 'winnergolfleftrighthanded',
    GUESTDAY1OPT: 'guestday1opt',
    GUESTDAY2OPT: 'guestday2opt',
    GUESTDAY1SPASELECTION: 'guestday1spaselection',
    GUESTDAY1EXCURSIONSELECTION: 'guestday1excursionselection',
    GUESTDAY2SPASELECTION: 'guestday2spaselection',
    GUESTDAY2EXCURSIONSELECTION: 'guestday2excursionselection',
    GUESTGOLFHANDICAP: 'guestgolfhandicap',
    GUESTGOLF: 'guestgolf',
    GUESTRENTALREQUIRED: 'guestrentalrequired',
    GUESTGOLFLEFTRIGHTHANDED: 'guestgolfleftrighthanded',
    CHKWINNERRENTALS: 'winnerRentals',
    CHKGUESTRENTALS: 'guestRentals',
    HDNRADIOSELECTIONWINNER: 'hdnradioselectionwinner',
    LEFTHANDEDWINNER: 'leftHandedwinner',
    RIGHTHANDEDWINNER: 'rightHandedwinner',
    HDNRADIOSELECTIONGUEST: 'hdnradioselectionguest',
    LEFTHANDEDGUEST: 'leftHandedguest',
    RIGHTHANDEDGUEST: 'rightHandedguest',
};

export default class MBRegistrationForm {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.MBFormUtil = new MBFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.WINNERDAY1OPT}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.WINNERDAY1OPT}`).value.toLowerCase() === 'spa') {
                    document.getElementById(`${ID.WINNERDAY1SPASELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERDAY1SPASELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.WINNERDAY1EXCURSIONSELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERDAY1EXCURSIONSELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.WINNERDAY1EXCURSIONSELECTION}`));
                } else {
                    document.getElementById(`${ID.WINNERDAY1EXCURSIONSELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERDAY1EXCURSIONSELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.WINNERDAY1SPASELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERDAY1SPASELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.WINNERDAY1SPASELECTION}`));
                }
            }
            if (e.target.matches(`#${ID.WINNERDAY2OPT}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.WINNERDAY2OPT}`).value.toLowerCase() === 'spa') {
                    document.getElementById(`${ID.WINNERDAY2SPASELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERDAY2SPASELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`));
                    document.getElementById(`${ID.WINNERGOLFHANDICAP}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERGOLF}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERRENTALREQUIRED}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERGOLFLEFTRIGHTHANDED}`).setAttribute('hidden', '');
                } else {
                    document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.WINNERDAY2SPASELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERDAY2SPASELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.WINNERDAY2SPASELECTION}`));
                }
            }
            if (e.target.matches(`#${ID.WINNERDAY2EXCURSIONSELECTION}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.WINNERDAY2EXCURSIONSELECTION}`).value === 'Golf (18 holes)') {
                    document.getElementById(`${ID.WINNERGOLFHANDICAP}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERGOLF}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERRENTALREQUIRED}`).removeAttribute('hidden');
                    document.getElementById(`${ID.WINNERGOLFLEFTRIGHTHANDED}`).removeAttribute('hidden');
                } else {
                    document.getElementById(`${ID.WINNERGOLFHANDICAP}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERGOLF}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERRENTALREQUIRED}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.WINNERGOLFLEFTRIGHTHANDED}`).setAttribute('hidden', '');
                }
            }
        });
        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.GUESTDAY1OPT}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.GUESTDAY1OPT}`).value.toLowerCase() === 'spa') {
                    document.getElementById(`${ID.GUESTDAY1SPASELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTDAY1SPASELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.GUESTDAY1EXCURSIONSELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTDAY1EXCURSIONSELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.GUESTDAY1EXCURSIONSELECTION}`));
                } else {
                    document.getElementById(`${ID.GUESTDAY1EXCURSIONSELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTDAY1EXCURSIONSELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.GUESTDAY1SPASELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTDAY1SPASELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.GUESTDAY1SPASELECTION}`));
                }
            }
            if (e.target.matches(`#${ID.GUESTDAY2OPT}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.GUESTDAY2OPT}`).value.toLowerCase() === 'spa') {
                    document.getElementById(`${ID.GUESTDAY2SPASELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTDAY2SPASELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`));
                    document.getElementById(`${ID.GUESTGOLFHANDICAP}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTGOLF}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTRENTALREQUIRED}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTGOLFLEFTRIGHTHANDED}`).setAttribute('hidden', '');
                } else {
                    document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`).setAttribute('required', 'true');
                    document.getElementById(`${ID.GUESTDAY2SPASELECTION}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTDAY2SPASELECTION}`).selectedIndex = 0;
                    this.MBFormUtil.displayDefault(document.getElementById(`${ID.GUESTDAY2SPASELECTION}`));
                }
            }
            if (e.target.matches(`#${ID.GUESTDAY2EXCURSIONSELECTION}`)) {
                e.preventDefault();
                if (document.getElementById(`${ID.GUESTDAY2EXCURSIONSELECTION}`).value === 'Golf (18 holes)') {
                    document.getElementById(`${ID.GUESTGOLFHANDICAP}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTGOLF}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTRENTALREQUIRED}`).removeAttribute('hidden');
                    document.getElementById(`${ID.GUESTGOLFLEFTRIGHTHANDED}`).removeAttribute('hidden');
                } else {
                    document.getElementById(`${ID.GUESTGOLFHANDICAP}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTGOLF}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTRENTALREQUIRED}`).setAttribute('hidden', '');
                    document.getElementById(`${ID.GUESTGOLFLEFTRIGHTHANDED}`).setAttribute('hidden', '');
                }
            }
        });
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.CHKWINNERRENTALS}`)) {
                if (document.getElementById(`${ID.CHKWINNERRENTALS}`).checked) {
                    document.getElementById(`${ID.CHKWINNERRENTALS}`).value = 'Yes';
                } else {
                    document.getElementById(`${ID.CHKWINNERRENTALS}`).value = 'No';
                }
            }
            if (e.target.matches(`#${ID.CHKGUESTRENTALS}`)) {
                if (document.getElementById(`${ID.CHKGUESTRENTALS}`).checked) {
                    document.getElementById(`${ID.CHKGUESTRENTALS}`).value = 'Yes';
                } else {
                    document.getElementById(`${ID.CHKGUESTRENTALS}`).value = 'No';
                }
            }
            if (e.target.matches(`#${ID.LEFTHANDEDWINNER}`) || e.target.matches(`#${ID.RIGHTHANDEDWINNER}`)) {
                document.getElementById(`${ID.HDNRADIOSELECTIONWINNER}`).value = document.querySelector('input[name="winnerPrefer"]:checked').value;
            }
            if (e.target.matches(`#${ID.LEFTHANDEDGUEST}`) || e.target.matches(`#${ID.RIGHTHANDEDGUEST}`)) {
                document.getElementById(`${ID.HDNRADIOSELECTIONGUEST}`).value = document.querySelector('input[name="guestPrefer"]:checked').value;
            }
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                if (this.MBFormUtil.validateAllFields()) {
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;

                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const siteName = 'mb-event';
                    const url = '/bin/microsites/mbEventRegistration';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                    };

                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success === 'true') {
                            this.MBFormUtil.displayPopUp('Registration Successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.MB_FORM_DATA_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.MB_FORM_DATA_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.MBFormUtil.displayPopUp('Registration Failed');
                        }
                    });
                }
            }
        });
    }
}
