import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const CONSTANTS = {
    FAVOURITES_ROW: 'brc-favorite-list__border_rule',
    REMOVE_ICON: 'alphabetical-search__star-red',
    STAR_RED: 'brc-favourites__star-red',
    STAR_BLANK: 'brc-favourites__star-icon'
};

const ID = {
    SITE_NAME: 'site-name',
    DISPLAY_FAVORITE_LIST: 'brcFav-listing',
    FAVOURITE_ERROR: 'favouriteError'
};

export default class GetBrcFavourite {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        this.url = '/bin/microsites/brc-favourites';
        this.loadModules();
        this.displayFavourite();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.classList.contains(`${CONSTANTS.REMOVE_ICON}`)) {
                e.preventDefault();
                const col1text = e.target.getAttribute('data-title');
                const col3link = e.target.getAttribute('data-href');
                const formData = {
                    col1text,
                    col3link,
                };
                formData.siteName = this.siteName;
                formData.option = 'remove';
                this.Ajax.ajaxPostFn(this.url, formData, (success) => {
                    if (success === 'true') {
                        let favId = e.target.parentElement.id;
                        if (favId !== '') {
                            favId = favId.substring(3);
                            const ele = document.getElementById(favId);
                            const element = ele.firstElementChild.firstElementChild;
                            element.classList.remove(`${CONSTANTS.STAR_RED}`);
                            element.classList.add(`${CONSTANTS.STAR_BLANK}`);
                        }
                        e.target.parentElement.remove();
                        this.emptyFavouriteCheck();
                    }
                });
            }
        });
    }

    displayFavourite() {
        const data = {};
        data.siteName = this.siteName;
        data.option = 'display';
        this.Ajax.ajaxPostFn(this.url, data, (success) => {
            this.displayFavouriteContent(JSON.parse(success));
        });
    }
    displayFavouriteContent(List) {
        this.displayFav = JSON.parse(List);
        const arr = Object.keys(this.displayFav);
        if (arr.length > 2) {
            const arr1 = Object.values(this.displayFav);
            const resultDisplay = document.getElementById(`${ID.DISPLAY_FAVORITE_LIST}`);
            let htmlBuild = '';
            this.favouritesData = {};
            this.favouritesData.length = arr.length - 2;
            let ind = 0;
            for (let key = 0; key < arr.length; key += 1) {
                const FavouriteKey = arr[key];
                const FavouriteKey1 = arr1[key];
                if (FavouriteKey !== 'currentUser' && FavouriteKey !== 'date') {
                    this.favouritesData[FavouriteKey] = ind;
                    ind += 1;
                    htmlBuild += `<tr class="brc-favorite-list__border_rule"><td input class ="alphabetical-search__star-red" type="checkbox" data-href="${FavouriteKey1}" data-title="${FavouriteKey}"></td><td class="brc-favorite-list__td_padding"><a href="${FavouriteKey1}" target="_blank" rel="noopener noreferrer">${FavouriteKey}</a></td></tr>`;
                }
            }
            resultDisplay.innerHTML = htmlBuild;
            this.labelCheck();
        } else {
            document.getElementById(`${ID.FAVOURITE_ERROR}`).style.display = 'block';
        }
    }

    labelCheck() {
        const unchecked = document.getElementsByName('fav');
        const favList = this.element.getElementsByClassName(`${CONSTANTS.FAVOURITES_ROW}`);
        for (let key = 0; key < unchecked.length && this.favouritesData.length > 0; key += 1) {
            const headingText = unchecked[key].getAttribute('data-col1Text');
            const headingLink = unchecked[key].getAttribute('data-col3Link');
            if (headingLink === this.displayFav[`${headingText}`]) {
                unchecked[key].classList.add(`${CONSTANTS.STAR_RED}`);
                unchecked[key].classList.remove(`${CONSTANTS.STAR_BLANK}`);
                const index = this.favouritesData[`${headingText}`];
                delete this.favouritesData[`${headingText}`];
                delete this.displayFav[`${headingText}`];
                this.favouritesData.length -= 1;
                favList[index].id = `fav${unchecked[key].parentElement.parentElement.id}`;
            }
        }
        /*
        if (this.favouritesData.length > 0) {
            delete this.favouritesData.length;
            const indexValues = Object.values(this.favouritesData);
            for (let ind = 0; ind < indexValues.length; ind += 1) {
                const i = indexValues[ind];
                const text = favList[i].firstElementChild.getAttribute('data-title');
                const link = favList[i].firstElementChild.getAttribute('data-href');
                const formData = {};
                formData.siteName = this.siteName;
                formData.col1text = text;
                formData.col3link = link;
                formData.option = 'remove';
                indexValues[ind] = favList[i];
                this.Ajax.ajaxPostFn(this.url, formData, (success) => {
                    console.log(success);
                });
            }
            for (let ind = 0; ind < indexValues.length; ind += 1) {
                indexValues[ind].remove();
            }
            this.emptyFavouriteCheck();
        }
        */
    }

    emptyFavouriteCheck() {
        if (this.element.getElementsByClassName(`${CONSTANTS.FAVOURITES_ROW}`).length === 0) {
            document.getElementById(`${ID.FAVOURITE_ERROR}`).style.display = 'block';
        }
    }
}
