// Partial dependencies
import { ReCAPTCHA } from 'partials/recaptcha';

/**
 * @class StyleGuideReCAPTCHA
 * @description Creates an ReCAPTCHA in an element on the Style Guide page
 * @param {Node} element
 */
export default class StyleGuideReCAPTCHA {
    /**
     * @method constructor
     * @description Instantiates the StyleGuideReCAPTCHA
     * @param {Object} element
     */
    constructor(element) {
        this.element = element;
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.reCAPTCHAElement = new ReCAPTCHA({
            theme: this.element.getAttribute('data-theme'),
            size: this.element.getAttribute('data-size'),
        });

        this.element.appendChild(this.reCAPTCHAElement.render());
    }
}
