// Constant dependencies
import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { screen, validate } from 'utils';

// Local dependencies
import googleLoaderApi from './../api/googleLoaderApi';
import utils from './../api/googleMapUtils';
import defaultMapConfig from './../config/defaultMapConfig';
import detailedInfoWindowTemplate from './../templates/detailedInfoWindowTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    TRAFFIC_TOGGLE: 'google-map__traffic-toggle',
    TRAFFIC_TOGGLE_TEXT: 'google-map__traffic-text',
    GM_STYLE: 'gm-style',
    MAP_BUTTON: 'google-map__button'
};

/**
 * @const LABELS
 * @description Collection of constant labels for elements in the module
 */
const LABELS = {
    TRAFFIC: 'Traffic',
    HIDE_TRAFFIC: 'Hide Traffic',
    SHOW_TRAFFIC: 'Show Traffic'
};

/**
 * @const ELEMENTS
 * @description Collection of constants for DOM elements
 */
const ELEMENTS = {
    DIV: 'div'
};

/**
 * @class GoogleMap
 * @description View for managing the display and logic of a Google Map from the
 * Google Maps api
 */
export default class GoogleMap {
    /**
     * @static CUSTOM_CONTROL_POSITIONS
     * @description Map that has values for custom control positions
     */
    static CUSTOM_CONTROL_POSITIONS = {
        TOP_CENTER: 'TOP_CENTER',
        TOP_LEFT: 'TOP_LEFT',
        TOP_RIGHT: 'TOP_RIGHT',
        LEFT_TOP: 'LEFT_TOP',
        LEFT_CENTER: 'LEFT_CENTER',
        LEFT_BOTTOM: 'LEFT_BOTTOM',
        RIGHT_TOP: 'RIGHT_TOP',
        RIGHT_CENTER: 'RIGHT_CENTER',
        RIGHT_BOTTOM: 'RIGHT_BOTTOM',
        BOTTOM_LEFT: 'BOTTOM_LEFT',
        BOTTOM_CENTER: 'BOTTOM_CENTER',
        BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    };

    /**
     * @constructor
     * @description On instantiation, sets properties, loads the google maps api,
     * and calls the method to create a map
     * @param element {Node} Element to render the map to
     * @param config {Object} Google Maps configuration object
     * (see configurable values from https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapOptions)
     * @param variantOptions {Object} Variant options object
     * @param customControls {Array} Array of objects that hold the control values
     * of position, label, onClick
     * @param infoWindowOptions {Object} options object,
     *        passed to infoWindow and detailedInfoWindow upon creation
     * (We may want to separate these out into separate objects should future need arise)
     * (see configurable values from https://developers.google.com/maps/documentation/javascript/3.exp/reference#InfoWindowOptions)
     * @param onMapCreatedCallback {Function} Optional callback for when map created is complete
     * @param markerClickCallback {Function} Optional callback for when a pin marker is clicked,
     * it overrides the default behavior
     * @param {Function} customMarkerBoundsCallback - Optional callback to be executed
     * after fitBounds() have been executed
     * @param {Function} onLoadCallback - Optional callback to be executed after map loaded
     */
    constructor({
        element,
        config = {},
        content = {},
        variantOptions = {},
        infoWindowOptions = {},
        customControls = [],
        onMapCreatedCallback,
        markerClickCallback,
        customMarkerBoundsCallback,
        onLoadCallback
    } = {}) {
        this.element = element;
        this.maps = null;
        this.map = null;
        this.trafficLayer = null;
        this.trafficStatus = false; // boolean to display/hide traffic layer
        this.trafficControlText = null;
        this.config = {
            ...defaultMapConfig,
            ...config
        };
        this.markers = [];
        this.isPreferred = false;
        this.isDestroyed = false;
        this.bounds = null;
        this.infoWindow = null;
        this.infoWindowOptions = infoWindowOptions;
        this.customControls = customControls;
        this.detailedInfoWindow = null;
        this.detailedInfoWindowListener = null;
        this.content = content;
        this.isTilesLoaded = false;
        this.onMapCreatedCallback = onMapCreatedCallback;
        this.markerClickCallback = markerClickCallback;
        this.customMarkerBoundsCallback = customMarkerBoundsCallback;
        this.variantOptions = variantOptions;
        this.onLoadCallback = onLoadCallback;
        this.currentScreenState = screen.getState();
        this.onScreenResize = this.onScreenResize.bind(this);
        this.enableKeyboardPan = this.enableKeyboardPan.bind(this);

        googleLoaderApi.loadGoogleMaps(this.config.language)
            .then(this.createMap.bind(this))
            .then(this.attachEvents.bind(this));
    }

    /**
     * @method attachEvents
     * @description Attaches event listener for screen resize
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize, 150);
        window.addEventListener(EVENTS.FOCUS, this.enableKeyboardPan, true);
    }

    /**
     * @method enableKeyboardPan
     * @description Enable google map pan while focus only
     */
    enableKeyboardPan() {
        if (document.activeElement.parentNode.classList.contains(`${CLASSES.GM_STYLE}`)) {
            this.map.set('keyboardShortcuts', true);
        } else {
            this.map.set('keyboardShortcuts', false);
        }
    }

    /**
     * @method detachEvents
     * @description Remove listeners
     */
    detachEvents() {
        screen.removeResizeListener(this.onScreenResize);
        window.removeEventListener(EVENTS.FOCUS, this.enableKeyboardPan, true);
    }

    /**
     * @method destroyMarkers
     * @description Clears all the markers on the map and resets the LatLngBounds
     */
    destroyMarkers() {
        this.markers.forEach((marker) => {
            if (marker) {
                marker.setMap(null);
            }
        });

        this.markers = [];
        if (this.maps) {
            this.bounds = new this.maps.LatLngBounds();
        }
    }

    destroyMarker(markerID) {
        const marker = this.markers.find(markerItem => (markerItem.info.id === markerID));

        if (marker) {
            const index = this.markers.indexOf(marker);

            if (index !== -1) {
                this.markers[index].setMap(null);
                this.markers.splice(index, 1);
            }
        }
    }

    /**
     * @method markerMouseOverHandler
     * @description Mouse over handler for marker
     * This sets the content needed for the info window, and opens it
     * @param {google.maps.Marker} marker - Marker object for which the infoWindow should be shown
     */
    markerMouseOverHandler(marker) {
        if (!marker.info.isDetailedInfoWindowOpen) {
            this.infoWindow.setContent(`${marker.info.name}`);
            if (!validate.isEmpty(this.infoWindowOptions)) {
                this.infoWindow.setOptions(this.infoWindowOptions);
            }
            this.infoWindow.open(this.map, marker);
        }
    }

    /**
     * @method markerMouseOverHandlerById
     * @description Mouse over handler for marker
     * @param {String} markerID - ID of the marker which should be hovered
     */
    markerMouseOverHandlerById(markerID) {
        const selectedMarker = this.markers.find(marker => marker.info.id === markerID);

        if (selectedMarker) {
            this.markerMouseOverHandler(selectedMarker);
        }
    }

    /**
     * @method markerMouseOutHandler
     * @description Mouse out handler for marker
     * This empties the content needed for the info window, and closes it
     */
    markerMouseOutHandler() {
        this.infoWindow.setContent('');
        this.infoWindow.close();
    }

    /**
     * @method markerClickHandler
     * @description Click handler for marker
     * If a markerClickCallback was provided executes it, otherwise does the default behavior:
     * closes both infoWindow and detailedInfoWindow, sets the content needed for
     * detailedInfoWindow using the template, opens it, and centers map to the marker's position
     * @param {google.maps.Marker} marker - Marker object for which the infoWindow should be shown
     */
    markerClickHandler(marker) {
        if (this.markerClickCallback && typeof this.markerClickCallback === 'function') {
            this.markerClickCallback(marker.info);
        } else {
            this.detailedInfoWindow.close();
            this.infoWindow.close();
            this.detailedInfoWindow.setContent(
                detailedInfoWindowTemplate(
                    marker.info, this.content, this.variantOptions
                )({ getNode: true })
            );
            if (!validate.isEmpty(this.infoWindowOptions)) {
                this.detailedInfoWindow.setOptions(this.infoWindowOptions);
            }
            this.detailedInfoWindow.open(this.map, marker);
            marker.info.isDetailedInfoWindowOpen = true;
            this.detailedInfoWindow.associatedKey = marker.info.id;
            this.centerMap(marker.getPosition());
        }
    }

    /**
     * @method detailedInfoWindowCloseHandler
     * @description closeclick handler for detailed info window
     * Finds the marker that is associated with the info window (by using the associatedKey
     * attribute), and sets flag to false. Also sets the associated marker to null.
     */
    detailedInfoWindowCloseHandler() {
        this.markers.find(marker =>
            marker.info.id === this.detailedInfoWindow.associatedKey
        ).info.isDetailedInfoWindowOpen = false;
        this.detailedInfoWindow.associatedKey = null;
    }

    /**
     * @method forceInfoWindowClose
     * @description forces this.infoWindow and this.detailedInfoWindow to close
     * Helpful when the Google Map is within an element whose display is switching to/from none
     */
    forceInfoWindowClose() {
        this.detailedInfoWindow.close();
        this.infoWindow.close();
        this.markers.forEach((marker) => {
            if (marker && marker.info.isDetailedInfoWindowOpen) {
                marker.info.isDetailedInfoWindowOpen = false;
            }
        });
        this.detailedInfoWindow.associatedKey = null;
    }

    /**
     * @method triggerResize
     * @description triggers a resize event on the Google Maps API
     */
    triggerResize() {
        this.maps.event.trigger(this.map, EVENTS.RESIZE);
    }

    /**
     * @method addMarkers
     * @description For a given list of items, markers are created on the map. The map is also set
     * to zoom to contain all the items. Each item must have a location object with the properties
     * lat and lng
     * @param {Array} items - array of items to map
     * @param {Boolean} isPreferred - is it the preferredDealer
     */
    addMarkers(items, isPreferred) {
        if (items && items.length) {
            items.forEach((item) => {
                const marker = new this.maps.Marker({
                    position: {
                        lat: parseFloat(item.location.lat),
                        lng: parseFloat(item.location.lng)
                    },
                    map: this.map,
                    title: `${item.name}`,
                    animation: this.maps.Animation.DROP,
                    icon: typeof item.icon === 'object' ? {
                        ...item.icon,
                        scaledSize: new this.maps.Size(30, 36)
                    } : item.icon,
                    info: item,
                    label: item.label ? {
                        color: '#FFF',
                        text: item.label.toString(),
                        fontSize: '12px',
                        fontWeight: 'bold'
                    } : ''
                });

                if (this.config.enableHoverInteractions) {
                    marker.addListener(EVENTS.MOUSEOVER,
                                       this.markerMouseOverHandler.bind(this, marker));
                    marker.addListener(EVENTS.MOUSEOUT,
                                       this.markerMouseOutHandler.bind(this, marker));
                }

                if (item.address) {
                    marker.addListener(EVENTS.CLICK, this.markerClickHandler.bind(this, marker));
                }

                this.bounds.extend(marker.getPosition());
                this.markers.push(marker);
            });

            this.isPreferred = isPreferred;
            this.fitBounds();
        } else {
            this.centerMap(this.config.center, true);
        }
    }

    /**
     * @method createCustomControls
     * @description Iterates through customControl array of objects
     * and returns a button for each one.
     * For each control in the customcontrols array it creates
     * a new control div. It maps the position to the correct value and appends the set HTML element
     */
    createCustomControls() {
        const controls = this.customControls;

        controls.forEach((control) => {
            const controlObj = document.createElement('button');
            controlObj.classList.add(CLASSES.MAP_BUTTON);
            controlObj.appendChild(control.label);
            controlObj.addEventListener(EVENTS.CLICK, control.onClick);

            this.map.controls[this.maps.ControlPosition[control.position]].push(controlObj);
        });
    }

    /**
     * @method centerMap
     * @description Sets the center (and zoom level) of the map
     * @param {LatLng} center - Google Maps LatLng object
     * @param {Boolean} [setZoom] - Optional Set zoom level of Google Maps object
     */
    centerMap(center, setZoom) {
        center = center || this.config.center;
        this.map.setCenter(center);
        if (setZoom) {
            this.map.setZoom(this.config.zoom);
        }
    }

    /**
     * @method fitBounds
     * @description Sets the bounding box of the map object and updates
     * zoom level if needed
     */
    fitBounds() {
        this.map.fitBounds(this.bounds);

        if (this.markers.length === 1) {
            if (this.isPreferred) {
                // fitBounds() happens asynchronously, so wait for a bounds_changed
                // to set zoom out for one pin scenario
                this.maps.event.addListenerOnce(this.map, 'bounds_changed', () =>
                    this.map.setZoom(12)
                );
                this.maps.event.addListenerOnce(this.map, 'idle', () =>
                    this.map.setZoom(12)
                );
            } else {
                // zoom out from street-level for one pin scenario
                this.map.setZoom(15);
            }
        }

        if (this.detailedInfoWindow.associatedKey) {
            const selectedMarker = this.markers.find(
                marker => marker.info.id === this.detailedInfoWindow.associatedKey
            );
            this.centerMap(selectedMarker.getPosition());
        }

        if (this.config.leftOffset && !this.currentScreenState.small && this.config.enabledLarge) {
            if (this.isTilesLoaded) {
                this.offsetMap();
            } else {
                this.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
                    this.isTilesLoaded = true;
                    this.offsetMap();
                });
            }
        }
    }

    /**
     * @method offsetMap
     * @description Offsets the markers to fit outside the left offset.
     * This is done by recursively calculating if the left most marker
     * is within the offset, and panning the map by half the required
     * offset between left most and right most marker. If left most and
     * right most marker doesn't fit, zoom out, and offset again
     * @refer https://stackoverflow.com/a/27357715
     */
    offsetMap() {
        const bounds = this.map.getBounds();

        if (!this.bounds.isEmpty()) {
            // Top right point
            const topRightPoint = utils.fromLatLngToPoint(bounds.getNorthEast(),
                                    this.map, this.maps).x;

            // Get pixel position of leftmost and rightmost points
            const leftMost = utils.fromLatLngToPoint(this.bounds.getSouthWest(),
                                this.map, this.maps).x;
            const rightMost = utils.fromLatLngToPoint(this.bounds.getNorthEast(),
                                this.map, this.maps).x;

            // Calculate left and right offsets
            const leftOffset = this.config.leftOffset - leftMost;
            const rightOffset = topRightPoint - rightMost - 30;

            // Only if left offset is needed
            if (leftOffset >= 0) {
                if (leftOffset < rightOffset) {
                    const mapOffset = Math.round((rightOffset - leftOffset) / 2);

                    // Pan the map by the offset calculated on the x axis
                    this.map.panBy(-mapOffset, 0);

                    // Get the new left point after pan
                    const newLeftPoint = utils.fromLatLngToPoint(this.bounds.getSouthWest(),
                                            this.map, this.maps).x;

                    if (newLeftPoint <= this.config.leftOffset) {
                        // Leftmost point is still under the overlay
                        // Offset map again
                        this.offsetMap(this.map, this.bounds);
                    }
                } else {
                    // Cannot offset map at this zoom level otherwise both
                    // leftmost and rightmost points will not fit
                    // Zoom out and offset map again
                    this.map.setZoom(this.map.getZoom() - 1);
                    this.offsetMap(this.map, this.bounds);
                }
            }
        }
    }

    /**
     * @method createMap
     * @description Instantiates and renders a map from the Google Map api
     */
    createMap() {
        if (this.isDestroyed) {
            return;
        }
        this.maps = window.google.maps;
        this.map = new this.maps.Map(this.element, this.config);
        this.bounds = new this.maps.LatLngBounds();
        this.infoWindow = new this.maps.InfoWindow();
        this.detailedInfoWindow = new this.maps.InfoWindow();
        this.detailedInfoWindowListener = this.detailedInfoWindow.addListener(
            CUSTOM_EVENTS.CLOSE_CLICK, this.detailedInfoWindowCloseHandler.bind(this));

        this.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
            this.isTilesLoaded = true;
            if (this.onLoadCallback) {
                this.onLoadCallback();
            }
        });

        if (this.config.trafficLayerControl) {
            this.createTrafficToggle();
        }

        if (this.onMapCreatedCallback && typeof this.onMapCreatedCallback === 'function') {
            this.onMapCreatedCallback();
        }

        if (this.customControls.length > 0) {
            this.createCustomControls();
        }
    }

    /**
     * @method destroyMap
     * @description removes the Google Maps instance
     */
    destroyMap() {
        this.detachEvents();
        if (this.map) {
            this.map = null;
            this.detailedInfoWindowListener.remove();
        }
        this.isDestroyed = true;
    }

    /**
     * @method createTrafficToggle
     * @description Adds a toggle element to show/hide the Traffic Layer
     */
    createTrafficToggle() {
        // Set div for the toggle control
        const trafficControl = document.createElement(ELEMENTS.DIV);
        trafficControl.className = CLASSES.TRAFFIC_TOGGLE;

        // Set control interior text
        this.trafficControlText = document.createElement(ELEMENTS.DIV);
        this.trafficControlText.className = CLASSES.TRAFFIC_TOGGLE_TEXT;
        this.trafficControlText.innerHTML = LABELS.TRAFFIC;
        this.trafficControlText.title = LABELS.SHOW_TRAFFIC;
        trafficControl.appendChild(this.trafficControlText);

        this.trafficLayer = new this.maps.TrafficLayer();
        this.map.controls[this.maps.ControlPosition.TOP_RIGHT].push(trafficControl);

        // Setup click event listener
        this.maps.event.addDomListener(
            trafficControl, EVENTS.CLICK, this.toggleTrafficLayer.bind(this)
        );
    }

    /**
     * @method toggleTrafficLayer
     * @description Callback handler to toggle the traffic layer
     */
    toggleTrafficLayer() {
        if (!this.trafficStatus) {
            this.trafficStatus = true;
            this.trafficLayer.setMap(this.map);
            this.trafficControlText.title = LABELS.HIDE_TRAFFIC;
        } else {
            this.trafficStatus = false;
            this.trafficLayer.setMap(null);
            this.trafficControlText.title = LABELS.SHOW_TRAFFIC;
        }
    }

    /**
     * @method openInfoWindow
     * @description Opens the InfoWindow associated to the pin with passed in ID
     * @param {String} markerID - ID of the marker for which the info window should be open
     */
    openInfoWindow(markerID) {
        const selectedMarker = this.markers.find(marker => marker.info.id === markerID);
        if (selectedMarker) {
            this.maps.event.trigger(selectedMarker, EVENTS.CLICK);
        }
    }

    /**
     * @method onScreenResize
     * @description Handles screen resize events; re-centers map
     */
    onScreenResize(screenState) {
        if (screenState.fullscreen) {
            this.currentScreenState = screenState;
            this.fitBounds();
            return true;
        } else if (validate.objectsAreEqual(screenState, this.currentScreenState)) {
            this.currentScreenState = screenState;
            return false;
        }

        this.currentScreenState = screenState;
        this.fitBounds();
        return true;
    }
}

