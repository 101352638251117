import { EVENTS, CLASSES } from 'Constants';

import Ajax from 'modules/Ajax';
import ConcurEnrollUtil from 'modules/ConcurEnrollUtil';

const ID = {
    SUBMITBUTTON: 'submitBtn',
    REGISTRATION_FORM: 'concur-enrollment-form',
    SITE_NAME: 'site-name',
};

export default class ConcurEnroll {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
        this.loadCaptcha();
    }

    loadCaptcha() {
        console.log(this);
        const newScript = document.createElement('script');
        newScript.src = 'https://www.google.com/recaptcha/api.js';
        const urlServlet = '/bin/microsites/captcha';
        const formName = 'concur';
        const captchaData = {
            formName,
        };
        const captchaEle = document.getElementById('google-captcha');
        this.Ajax.ajaxPostFn(urlServlet, captchaData, (success) => {
            if (success !== 'false') {
                document.head.appendChild(newScript);
                captchaEle.innerHTML = `<div class="g-recaptcha" style="display: inline-block;" data-sitekey=${success}></div>`;
            }
        });
    }

    loadModules() {
        this.ConcurEnrollUtil = new ConcurEnrollUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.SUBMITBUTTON}`)) {
                e.preventDefault();

                const captchaResponse = grecaptcha.getResponse();
                if (captchaResponse.length !== 0 && this.ConcurEnrollUtil.validateAllFields()) {
                    document.getElementById('g-recaptcha-error').innerHTML = '';
                    const form = document.getElementById(`${ID.REGISTRATION_FORM}`);
                    const inputs = form.elements;

                    const formToJSONObject = elements => [].reduce.call(elements,
                        (data, element) => {
                            data[element.name] = element.value;
                            return data;
                        }, {});

                    const siteName = 'concur';
                    const url = '/bin/microsites/concur';
                    const data = {
                        formData: JSON.stringify(formToJSONObject(inputs)),
                        siteName,
                    };
                    grecaptcha.reset();
                    this.Ajax.ajaxPostFn(url, data, (success) => {
                        if (success.includes('Please verify the captcha response')) {
                            this.ConcurEnrollUtil.displayPopUp('Please verify the captcha response');
                        } else if (success === 'true') {
                            this.ConcurEnrollUtil.displayPopUp('Form submission successful');
                            form.reset();

                            [].forEach.call(inputs, (element) => {
                                if (element.matches(`.${CLASSES.CONCUR_ENROLL_CONTROL_SUCCESS}`)) {
                                    element.classList.remove(`${CLASSES.CONCUR_ENROLL_CONTROL_SUCCESS}`);
                                }
                            });
                        } else {
                            this.ConcurEnrollUtil.displayPopUp('Form submission failed');
                        }
                    });
                } else if (captchaResponse.length === 0) {
                    document.getElementById('g-recaptcha-error').innerHTML = '<span class="vin-recall__error-field">This field is required.</span>';
                    this.ConcurEnrollUtil.validateAllFields();
                }
            }
        });
    }
}
