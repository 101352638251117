import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import BrcFormUtil from 'modules/BrcFormUtil';

const CLASS = {
    BRC_POPUP_HEADER: 'pop-up__content-header',
};

const ID = {
    BUSINESS_AREA_SELECTION: 'businessArea',
    BRC_RENTAL_REPORT_LISTING: 'brc-form-listing-rental',
    BRC_FLEET_REPORT_LISTING: 'brc-form-listing-fleet',
    BRC_CORPORATE_SALES_REPORT_LISTING: 'brc-form-listing-corporate-sales',
    BRC_LOADER: 'brcLoader',
    BRC_SUBMISSION_STATUS: 'submissionStatus',
    BRC_REPORT_RENTAL_TABLE: 'reportTableRental',
    BRC_REPORT_FLEET_TABLE: 'reportTableFleet',
    BRC_REPORT_CORPORATE_TABLE: 'reportTableCorporateSales',
    BRC_SEARCH_STRING: 'brcSearchString',
    BRC_SEARCH_BTN: 'brcSearchBtn',
};

export default class BrcFormReport {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.loadReport();
        this.attachEvents();
    }

    loadModules() {
        this.BrcFormUtil = new BrcFormUtil(this.element);
        this.Ajax = new Ajax(this.element);
    }

    loadReport() {
        console.log(this);
        const url = '/bin/microsites/brc/data';
        const businessArea = document.getElementById(`${ID.BUSINESS_AREA_SELECTION}`).value;
        const data = {
            businessArea,
        };

        const brcLoader = document.getElementById(`${ID.BRC_LOADER}`);
        brcLoader.style.display = 'block';
        this.Ajax.ajaxPostFn(url, data, (success) => {
            if (success !== 'false') {
                brcLoader.style.display = 'none';
                const servletData = JSON.parse(success);
                if (businessArea === 'Rental') {
                    this.displayRentalResult(servletData);
                } else if (businessArea === 'Fleet Management Companies') {
                    this.displayFleetResult(servletData);
                } else {
                    this.displayCorporateSalesResult(servletData);
                }
            } else {
                brcLoader.style.display = 'none';
            }
        });
    }

    attachEvents() {
        console.log(this);
        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.BRC_SUBMISSION_STATUS}`)) {
                e.preventDefault();
                const position = e.target.getAttribute('data-ref');
                const status = e.target.value;
                const updateUrl = '/bin/microsites/brc/status';
                const updateData = {
                    position,
                    status,
                };

                const brcLoader = document.getElementById(`${ID.BRC_LOADER}`);
                brcLoader.style.display = 'block';
                this.Ajax.ajaxPostFn(updateUrl, updateData, (success) => {
                    if (success === 'true') {
                        brcLoader.style.display = 'none';
                        this.BrcFormUtil.displayPopUp('Status Updated');
                        document.querySelector(`.${CLASS.BRC_POPUP_HEADER}`).style.backgroundColor = '#3276b1';
                    } else {
                        brcLoader.style.display = 'none';
                        this.BrcFormUtil.displayPopUp('Status Update Failed');
                        document.querySelector(`.${CLASS.BRC_POPUP_HEADER}`).style.backgroundColor = '#3276b1';
                    }
                });
            }
        });

        document.addEventListener(EVENTS.CHANGE, (e) => {
            if (e.target.matches(`#${ID.BUSINESS_AREA_SELECTION}`)) {
                e.preventDefault();
                const businessArea = document.getElementById(`${ID.BUSINESS_AREA_SELECTION}`).value;
                if (businessArea === 'Fleet Management Companies') {
                    document.getElementById(`${ID.BRC_REPORT_RENTAL_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_CORPORATE_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_FLEET_TABLE}`).removeAttribute('style');
                    this.loadReport();
                } else if (businessArea === 'Corporate Sales') {
                    document.getElementById(`${ID.BRC_REPORT_RENTAL_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_FLEET_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_CORPORATE_TABLE}`).removeAttribute('style');
                    this.loadReport();
                } else {
                    document.getElementById(`${ID.BRC_REPORT_CORPORATE_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_FLEET_TABLE}`).style.display = 'none';
                    document.getElementById(`${ID.BRC_REPORT_RENTAL_TABLE}`).removeAttribute('style');
                    this.loadReport();
                }
            }
        });

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.BRC_SEARCH_BTN}`)) {
                e.preventDefault();
                const businessArea = document.getElementById(`${ID.BUSINESS_AREA_SELECTION}`).value;
                const searchString = document.getElementById(`${ID.BRC_SEARCH_STRING}`).value;
                const searchUrl = '/bin/microsites/brc/data/search';
                const searchData = {
                    businessArea,
                    searchString,
                };
                const brcLoader = document.getElementById(`${ID.BRC_LOADER}`);
                brcLoader.style.display = 'block';
                this.Ajax.ajaxPostFn(searchUrl, searchData, (success) => {
                    if (success !== 'false') {
                        brcLoader.style.display = 'none';
                        const searchedData = JSON.parse(success);
                        if (businessArea === 'Rental') {
                            this.displayRentalResult(searchedData);
                        } else if (businessArea === 'Fleet Management Companies') {
                            this.displayFleetResult(searchedData);
                        } else {
                            this.displayCorporateSalesResult(searchedData);
                        }
                    } else {
                        brcLoader.style.display = 'none';
                    }
                });
            }
        });
    }

    displayRentalResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.BRC_RENTAL_REPORT_LISTING}`);
        let htmlBuild = '';
        for (let i = 0; i < data.length; i += 1) {
            const formData = JSON.parse(data[i]);
            let submissionDate = formData.submissionDate;
            if (submissionDate !== undefined) {
                submissionDate = submissionDate.replace(/_/g, ':');
            } else {
                submissionDate = 'NA';
            }

            htmlBuild += `<tr role="row" id="reportRow" class="brc-form-report__border_rule">
            <td colspan="1" class="brc-form-report__td_padding">${formData.employeeName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.contactInfo}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.canNumber}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.customerBusinessName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.comments}</td>
            <td colspan="1" class="brc-form-report__td_padding">${submissionDate}</td>`;

            let statusArr = ['Submitted', 'Pending', 'Completed'];
            if (formData.adminAccess === 'true') {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                     <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                     data-ref="${formData.position}" disabled=${formData.adminAccess}>`;
            } else {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                      <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                      data-ref="${formData.position}">`;
            }
            if (formData.status !== undefined) {
                statusArr = statusArr.filter(e => e !== formData.status);
                htmlBuild += `
                      <option value="${formData.status}" selected>${formData.status}</option>
                      <option value="${statusArr[0]}">${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                   </select>
                   </td>
                   </tr>`;
            } else {
                htmlBuild += `
                      <option value="${statusArr[0]}" selected>${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                      <option value="${statusArr[2]}">${statusArr[2]}</option>
                   </select>
                   </td>
                   </tr>`;
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayCorporateSalesResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.BRC_CORPORATE_SALES_REPORT_LISTING}`);
        let htmlBuild = '';
        for (let i = 0; i < data.length; i += 1) {
            const formData = JSON.parse(data[i]);
            console.log(formData);
            let submissionDate = formData.submissionDate;
            if (submissionDate !== undefined) {
                submissionDate = submissionDate.replace(/_/g, ':');
            } else {
                submissionDate = 'NA';
            }

            let order = formData.order;
            if (order !== undefined) {
                order = order.split('|').join(',');
            } else {
                order = 'NA';
            }

            let regionalVehicles = formData['Regional backfill vehicles requested'];
            if (regionalVehicles === undefined) {
                regionalVehicles = 'No';
            }

            let dealerInfo = '';
            if (regionalVehicles !== 'Yes') {
                dealerInfo = `${formData.dealerName}(${formData.dealerCode}), ${
                             formData.dealerContactName}, ${formData.dealerContactEmail}`;
            } else {
                dealerInfo = 'NA';
            }

            htmlBuild += `<tr role="row" id="reportRow" class="brc-form-report__border_rule">
            <td colspan="1" class="brc-form-report__td_padding">${formData.employeeName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.contactInfo}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.canNumber}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.customerBusinessName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${regionalVehicles}</td>
            <td colspan="1" class="brc-form-report__td_padding">${dealerInfo}</td>
            <td colspan="1" class="brc-form-report__td_padding">${order}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.comments}</td>
            <td colspan="1" class="brc-form-report__td_padding">${submissionDate}</td>`;

            let statusArr = ['Submitted', 'Pending', 'Completed'];
            if (formData.adminAccess === 'true') {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                     <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                     data-ref="${formData.position}" disabled=${formData.adminAccess}>`;
            } else {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                      <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                      data-ref="${formData.position}">`;
            }
            if (formData.status !== undefined) {
                statusArr = statusArr.filter(e => e !== formData.status);
                htmlBuild += `
                      <option value="${formData.status}" selected>${formData.status}</option>
                      <option value="${statusArr[0]}">${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                   </select>
                   </td>
                   </tr>`;
            } else {
                htmlBuild += `
                      <option value="${statusArr[0]}" selected>${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                      <option value="${statusArr[2]}">${statusArr[2]}</option>
                   </select>
                   </td>
                   </tr>`;
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }

    displayFleetResult(data) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.BRC_FLEET_REPORT_LISTING}`);
        let htmlBuild = '';
        for (let i = 0; i < data.length; i += 1) {
            const formData = JSON.parse(data[i]);
            console.log(formData);
            let submissionDate = formData.submissionDate;
            if (submissionDate !== undefined) {
                submissionDate = submissionDate.replace(/_/g, ':');
            } else {
                submissionDate = 'NA';
            }

            let order = formData.order;
            if (order !== undefined) {
                order = order.split('|').join(',');
            } else {
                order = 'NA';
            }

            const dealerInfo = `${formData.dealerName}(${formData.dealerCode}), ${
                             formData.dealerContactName}, ${formData.dealerContactEmail}`;

            htmlBuild += `<tr role="row" id="reportRow" class="brc-form-report__border_rule">
            <td colspan="1" class="brc-form-report__td_padding">${formData.employeeName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.contactInfo}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.canNumber}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.customerBusinessName}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.fleetMangementCompany}</td>
            <td colspan="1" class="brc-form-report__td_padding">${dealerInfo}</td>
            <td colspan="1" class="brc-form-report__td_padding">${order}</td>
            <td colspan="1" class="brc-form-report__td_padding">${formData.comments}</td>
            <td colspan="1" class="brc-form-report__td_padding">${submissionDate}</td>`;

            let statusArr = ['Submitted', 'Pending', 'Completed'];
            if (formData.adminAccess === 'true') {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                     <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                     data-ref="${formData.position}" disabled=${formData.adminAccess}>`;
            } else {
                htmlBuild += `<td colspan="1" class="brc-form-report__td_padding">
                      <select name="submissionStatus" id="submissionStatus" class="brc-form-report__control" 
                      data-ref="${formData.position}">`;
            }
            if (formData.status !== undefined) {
                statusArr = statusArr.filter(e => e !== formData.status);
                htmlBuild += `
                      <option value="${formData.status}" selected>${formData.status}</option>
                      <option value="${statusArr[0]}">${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                   </select>
                   </td>
                   </tr>`;
            } else {
                htmlBuild += `
                      <option value="${statusArr[0]}" selected>${statusArr[0]}</option>
                      <option value="${statusArr[1]}">${statusArr[1]}</option>
                      <option value="${statusArr[2]}">${statusArr[2]}</option>
                   </select>
                   </td>
                   </tr>`;
            }
        }
        resultDisplay.innerHTML = htmlBuild;
    }
}
