/**
 * Template for dog vehicle bodystyle explorer - model years
 * Uses ES6 template literal to composite the markup for a
 * dog vehicle bodystyle explorer container - model years
 * @param summary {Array} the array of dog vehicle bodystyle explorer - model years items
 */
export default (modelYearsList = []) => `
    ${modelYearsList.map((modelYear) => {
        let modelYearListItem = '';
        if (modelYear.link && modelYear.text) {
            modelYearListItem = `<a href="${modelYear.link}" `;
            const linkArray = modelYear.link.split('/');
            if (linkArray[linkArray.length - 1].indexOf('.') >= 0 && linkArray[linkArray.length - 1].indexOf('.html') < 0) {
                modelYearListItem += 'target="_blank"';
            } else {
                modelYearListItem += 'target="_self"';
            }
            modelYearListItem += `class="vehicle-bodystyle__list-link">${modelYear.text}</a>
             </li>`;
        } else if (modelYear.text) {
            if (/\d/.test(modelYear.text) && /[a-zA-Z]/.test(modelYear.text)) {
                modelYearListItem = `<p class="vehicle-bodystyle__list-title">${modelYear.text}<p>`;
            } else {
                modelYearListItem = `<p class="vehicle-bodystyle__text">${modelYear.text}<p>`;
            }
        }
        return `
            <li class="vehicle-bodystyle__list-item">
                ${modelYearListItem}
            </li>
        `;
    }).join('')}
`;
