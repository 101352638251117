/**
 * @method stringReplace
 * @description Replace string with replacements using regex to match. String.prototype.replace
 * accepts a recursive function as second parameter. In this case, the replacer function performs
 * an Array.prototype.shift on the replacements that are passed in.
 * @param {String} str - Input string to perform replace operation on
 * @param {Regex} regex - Regex to use for search
 * @param {Array} replacements - Array of values that will be used to replace, for each instance
 * of regex match found
 * @refer https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
 * @return {String} A string with replaced values
 * @example stringReplace('Nearby Dealership(s) (within {0} {1})', /\{\d\}/ig, ['25', 'km'])
 */
export function stringReplace(str, regex, replacements) {
    if (typeof str !== 'string') {
        throw TypeError('Input is not of type string');
    }
    if (str.match(regex) && replacements.length !== str.match(regex).length) {
        throw Error('Not enough replacement values');
    }

    const replacerFn = () => (replacements.shift());
    return str.replace(regex, replacerFn);
}

/**
 * @method tokenReplace
 * @description Replace string with replacements using token to match
 * @param {String} str - Input string to perform replace token operation on. Tokens should be of
 * the format {0}, {1} etc. Replacements will be made based on the number in the token.
 * @param {Array|Object} replacements - Array of values that will be used to replace,
 * for each instance of token found || An object with keyValue pairs to replace in string
 * @return {String} A string with replaced values
 * @example tokenReplace('Nearby Dealership(s) (within {0} {1})', ['25', 'km'])
 * => Nearby Dealership(s) (within 25 km)
 * @example tokenReplace('Nearby Dealership(s) (within {1} {0})', ['25', 'km'])
 * => Nearby Dealership(s) (within km 25)
 * @example tokenReplace('Nearby Dealership(s) (within {distance} {unit})',
 *                       { distance: '25', unit: 'km' }
 *                      )
 * => Nearby Dealership(s) (within 25 km)
 */
export function tokenReplace(str, replacements) {
    if (Array.isArray(replacements)) {
        return replacements.reduce((prev, replacement, index) => (
            stringReplace(prev, new RegExp(`\\{${index}\\}`, 'g'), [replacement])
        ), str);
    } else if (typeof replacements === 'object') {
        return Object.keys(replacements).reduce((prev, replacementKey) => (
            stringReplace(prev, new RegExp(`\\{${replacementKey}\\}`, 'g'), [replacements[replacementKey]])
        ), str);
    }

    return str;
}

/**
 * @method tokenReplaceJSX
 * @description Replace string with replacements using tokens to match.
 * @param {String} str - Input string to perform replace token operation on. Tokens should be of
 * the format {tokenName}. tokenName can be numbered to be used with an array replacements, or named
 * tokens to be used with replacement map
 * @param {Array|Object} replacements - Array of values that will be used to
 *  replace for each instance
 * of token found | An object with keyValue pairs to replace in string.
 * @return {Array} An array of values split based on tokens, with tokens replaced.
 */
export function tokenReplaceJSX(str, replacements) {
    if (Array.isArray(replacements)) {
        return replacements.reduce((prev, current, index) => {
            const indexOfToken = prev.indexOf(`{${index}}`);
            prev.splice(indexOfToken, 1, current);
            return prev;
        }, str.split(/(\{\d+\})/));
    } else if (typeof replacements === 'object') {
        return Object.keys(replacements).reduce((prev, replacementKey) => {
            const indexOfToken = prev.indexOf(`{${replacementKey}}`);
            prev.splice(indexOfToken, 1, replacements[replacementKey]);
            return prev;
        }, str.split(/(\{\w+\})/));
    }

    return str;
}

export default {
    stringReplace,
    tokenReplace,
    tokenReplaceJSX
};

