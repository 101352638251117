import { EVENTS } from 'Constants';
import GetStartedUtil from 'modules/GetStartedUtil';

const ID = {
    GBUTTON1: 'gbutton1',
    GBUTTON2: 'gbutton2',
    GBUTTON3: 'gbutton3',
    GBUTTON4: 'gbutton4',
    GCLASSTEXT1: 'gclass1',
    GCLASSTEXT2: 'gclass2',
    GCLASSTEXT3: 'gclass3',
    GCLASSTEXT4: 'gclass4'
};


export default class GcButton {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.loadModules();
        this.attachEvents();
    }

    loadModules() {
        this.GetStartedUtil = new GetStartedUtil(this.element);
    }

    attachEvents() {
        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.GBUTTON1}`)) {
                e.preventDefault();
                console.log(this);
                const popupOne = document.getElementById(`${ID.GCLASSTEXT1}`).innerHTML;
                const popUpHeaderOne = document.getElementById(`${ID.GBUTTON1}`).innerHTML;
                console.log(popupOne);
                console.log(popUpHeaderOne);
                this.GetStartedUtil.displayPopUpGclass(popupOne,popUpHeaderOne);
            }
            else if (e.target.matches(`#${ID.GBUTTON2}`)) {
                e.preventDefault();
                console.log(this);
                const popupTwo = document.getElementById(`${ID.GCLASSTEXT2}`).innerHTML;
                const popUpHeaderTwo = document.getElementById(`${ID.GBUTTON2}`).innerHTML;
                console.log(popupTwo);
                this.GetStartedUtil.displayPopUpGclass(popupTwo,popUpHeaderTwo);
            }
            else if (e.target.matches(`#${ID.GBUTTON3}`)) {
                e.preventDefault();
                console.log(this);
                const popupThree = document.getElementById(`${ID.GCLASSTEXT3}`).innerHTML;
                const popUpHeaderThree = document.getElementById(`${ID.GBUTTON3}`).innerHTML;
                console.log(popupThree);
                this.GetStartedUtil.displayPopUpGclass(popupThree,popUpHeaderThree);
            }
            else if (e.target.matches(`#${ID.GBUTTON4}`)) {
                e.preventDefault();
                console.log(this);
                const popupFour = document.getElementById(`${ID.GCLASSTEXT4}`).innerHTML;
                const popUpHeaderFour = document.getElementById(`${ID.GBUTTON4}`).innerHTML;
                console.log(popupFour);
                this.GetStartedUtil.displayPopUpGclass(popupFour,popUpHeaderFour);
            }
        });
    }
}
