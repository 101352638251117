// import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';
import DogVehicleBodystyleExplorerTemplate from 'templates/dog-vehicle-bodystyle-explorer';

const CLASSES = {
    VEHICLE_BODYSTYLE_CONTAINER: 'vehicle-bodystyle__content'
};

export default class DogVehicleBodystyleExplorerAMG {
    constructor(element) {
        this.element = element;
        this.className = null;
        this.url = '';
        this.country = window.microsites.pageData.country;
        this.language = window.microsites.pageData.language;
        this.init();
    }

    init() {
        this.loadModules();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
        this.getClassName();
    }

    getClassName() {
        const pageUrl = location.href;
        this.className = pageUrl.split('/').pop().split('.html')[0];
        if (this.className) {
            this.url = `/bin/microsites/editorial/dog/amg-models-bodystyle?class=${this.className}&country=${this.country}&lang=${this.language}`;
            this.loadJSON();
        }
    }

    loadJSON() {
        this.Ajax.readTextFile(this.url, (data) => {
            if (data) {
                // Success!
                const jsonData = Object.entries(JSON.parse(data));
                const bodystyleContainer = document.querySelector(`.${CLASSES.VEHICLE_BODYSTYLE_CONTAINER}`);
                bodystyleContainer.innerHTML = '';
                [].forEach.call(jsonData, (item) => {
                    bodystyleContainer.innerHTML += DogVehicleBodystyleExplorerTemplate(item[1]);
                });
            } else {
                console.log('Error! ');
            }
        });
    }
}
