import { EVENTS } from 'Constants';
import Ajax from 'modules/Ajax';

const ID = {
    ADDFAVORITE: 'addFavorite',
    POPUPFAV: 'pop-up-fav'
};

const CLASSES = {
    POPUP: 'pop-up',
    POPUP_OPEN: 'pop-up--open',
    POPUP_CONTENT_BODY: 'pop-up__content-body',
    POPUP__CONTENT_CLOSE: 'pop-up__content--close'
};

const SITE_NAME = {
    MB_NEW_WARRENTY: 'mb-new-warranty'
}


export default class RemoveFavorites {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
        this.closeButtonList = null;
    }

    init() {
        this.cacheDOMElement();
        this.loadModules();
        this.attachEvents();
    }

    cacheDOMElement() {
        this.favPopup = document.getElementById(`${ID.POPUPFAV}`);
        this.closeButtonList = document.getElementsByClassName(`${CLASSES.POPUP__CONTENT_CLOSE}`);
        this.modalPopUpList = document.getElementsByClassName(`${CLASSES.POPUP}`);
        console.log(this.favPopup);
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    attachEvents() {
        const modalPopUpListItem = this.modalPopUpList;

        document.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.id === 'removeFavorite') {
                this.removeFavoriteFn();
            }
        });

        [].forEach.call(this.closeButtonList, (el) => {
            el.addEventListener(EVENTS.CLICK, () => {
                [].forEach.call(modalPopUpListItem, (element) => {
                    element.classList.remove(`${CLASSES.POPUP_OPEN}`);
                    window.location.reload();
                });
            });
        });
    }

    removeFavoriteFn() {
        this.favoritesCheckbox = document.querySelectorAll('.favorites-list__item');
        const pagesToRemove = [];
        const siteName = document.getElementById('siteName').value;

        [].forEach.call(this.favoritesCheckbox, (el) => {

            if (el.querySelector('input').checked === true) {
                if (siteName === SITE_NAME.MB_NEW_WARRENTY) {
                    const removeLink = el.querySelector('input').nextElementSibling;

                    const listItemHref = removeLink.href;

                    const pathStarting = "/content";
                    const pathEnding = ".html";

                    let indexOfPathStarting = listItemHref.indexOf(pathStarting);
                    let indexOfPathEnding = listItemHref.indexOf(pathEnding);

                    if (indexOfPathStarting === -1) {
                        indexOfPathStarting = 0;
                    }
                    if (indexOfPathEnding === -1) {
                        indexOfPathEnding = listItemHref.length;
                    }
                    const removeItemPathname = listItemHref.slice(indexOfPathStarting, indexOfPathEnding);

                    pagesToRemove.push(decodeURI(removeItemPathname));
                } else {
                    pagesToRemove.push(el.querySelector('input').value);
                }
            }
        });

        if (pagesToRemove.length) {

            let url = '/bin/microsites/remove-favorites';

            if (siteName === SITE_NAME.MB_NEW_WARRENTY) {
                url = `/bin/microsites/my-favourites?siteName=${SITE_NAME.MB_NEW_WARRENTY}&option=remove`
            }

            const data = {
                siteName,
                pagesPath: pagesToRemove
            };

            this.Ajax.ajaxDeleteFn(url, data, (success) => {
                this.makePopup(success.replace(/['"]+/g, ''));
            });
        }
    }

    makePopup(success) {
        const siteName = document.getElementById('siteName').value;
        if (siteName === SITE_NAME.MB_NEW_WARRENTY) {
            let displayMessage = '';
            if (success) {
                displayMessage = 'Selected items removed successfully'    
            } else {
                displayMessage = 'Something went wrong, selected items not removed'
            }
            this.favPopup.getElementsByClassName(`${CLASSES.POPUP_CONTENT_BODY}`)[0].innerHTML = displayMessage;
            this.favPopup.classList.add('pop-up--open');
        } else {
            this.favPopup.getElementsByClassName(`${CLASSES.POPUP_CONTENT_BODY}`)[0].innerHTML = success;
            this.favPopup.classList.add('pop-up--open');
        }
    }
}
