import { EVENTS, CLASSES } from 'Constants';

const CLASS = {
    VEHICLE_END_DATE_UPDATE_CONTROL: 'vehicle-end-date-update__control',
    VEHICLE_END_DATE_UPDATE_CONTROL_ERROR: 'vehicle-end-date-update__control--error',
    VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS: 'vehicle-end-date-update__control--success',
    VEHICLE_END_DATE_UPDATE_ERROR_FIELD_HIDDEN: 'vehicle-end-date-update__error-field--hidden'
};

const ID = {
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content'
};
export default class VehicleEndDateUpdateUtil {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        document.addEventListener(EVENTS.INPUT, (el) => {
            if (el.target.matches(`.${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL}`)) {
                this.validateInput(el.target);
            }
        });
    }

    /**
     * Check if Input Field contain data or not.
     */
    containData(elementValue) {
        console.log(this);
        if (elementValue === '') {
            return false;
        }
        return true;
    }

    containDateOnly(elementValue) {
        console.log(this);
        const regex = /^([0][1-9]|[1][0-2])\/([0][1-9]|[1|2][0-9]|[3][0|1])\/([0-9]{4})$/;
        return regex.test(elementValue);
    }

    /**
     * Check if Input Field contain data or not.
     */
    containDigitsOnly(elementValue) {
        console.log(this);
        const regex = /^[0-9]+$/;
        return regex.test(elementValue);
    }

    containAlphabetAndDigitonly(elementValue) {
        console.log(this);
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[^\s]{17}$/;
        return regex.test(elementValue);
    }


    /**
     * Display Error
     */
    displayError(element) {
        console.log(this);
        element.classList.add(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_ERROR}`);
        element.classList.remove(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.remove(`${CLASS.VEHICLE_END_DATE_UPDATE_ERROR_FIELD_HIDDEN}`);
        }
    }

    displayDefault(element) {
        console.log(this);
        element.classList.remove(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS}`);
        element.classList.remove(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_ERROR}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.VEHICLE_END_DATE_UPDATE_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Hide Error
     */
    hideError(element) {
        console.log(this);
        element.classList.remove(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_ERROR}`);
        element.classList.add(`${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_SUCCESS}`);
        if (element.nextElementSibling != null) {
            element.nextElementSibling.classList.add(`${CLASS.VEHICLE_END_DATE_UPDATE_ERROR_FIELD_HIDDEN}`);
        }
    }

    /**
     * Email Validation
     */
    isValidEmail(email) {
        console.log(this);
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /**
     * Phone Number Validation
     */
    isValidPhoneNumber(phoneNumber) {
        console.log(this);
        const regex = /^\d{10}$/;
        return regex.test(phoneNumber);
    }

    /**
     * Dealer Code Validation
     */
    isValidDealerCode(dealerCode) {
        console.log(this);
        const regex = /^\d{5}$/;
        return regex.test(dealerCode);
    }

    /**
     * Check for Validity of input field for type of validation applied to it.
     */
    validate(el, isDataValid) {
        if (isDataValid) {
            this.hideError(el);
        } else {
            this.displayError(el);
        }
    }

    /**
     * Validate all fields of form.
     */
    validateAllFields() {
        const inputFields = document.querySelectorAll(`.${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL}`);
        const endDate = document.getElementById('endDate').value;
        const startDate = document.getElementById('startDate').value;
        this.validateFields(inputFields);
        this.validateEndEqualStart(startDate, endDate);
        this.validateEndLessStart(startDate, endDate);
        if (this.validateFields(inputFields) === true &&
            this.validateEndEqualStart(startDate, endDate) === true &&
            this.validateEndLessStart(startDate, endDate) === true) {
            return true;
        }
        return false;
    }

    validateEndEqualStart(startDate, endDate) {
        console.log(this);
        let flag = false;
        const startDateValue = new Date(startDate);
        const endDateValue = new Date(endDate);
        const dateError = document.getElementById('startDateEqalComp');
        if (startDateValue.getTime() === endDateValue.getTime()) {
            dateError.classList.remove('vehicle-end-date-update__error-field--hidden');
        } else {
            dateError.classList.add('vehicle-end-date-update__error-field--hidden');
            flag = true;
        }
        return flag;
    }


    validateEndLessStart(startDate, endDate) {
        console.log(this);
        let flag = false;
        const startDateValue = new Date(startDate);
        const endDateValue = new Date(endDate);
        const dateComp = document.getElementById('startDateGreaterEndDate');
        if (startDateValue.getTime() > endDateValue.getTime()) {
            dateComp.classList.remove('vehicle-end-date-update__error-field--hidden');
        } else {
            dateComp.classList.add('vehicle-end-date-update__error-field--hidden');
            flag = true;
        }
        return flag;
    }
    /**
     * Validate fields of form.
     */
    validateFields(inputFields) {
        [].forEach.call(inputFields, (el) => {
            this.validateInput(el);
        });

        for (let i = 0; i < inputFields.length; i += 1) {
            if (inputFields[i].matches(`.${CLASS.VEHICLE_END_DATE_UPDATE_CONTROL_ERROR}`)) {
                inputFields[i].focus();
                return false;
            }
        }
        return true;
    }

    validateInput(el) {
        const fieldRequiredStatus = el.getAttribute('required');
        if (!(el.getAttribute('required') && fieldRequiredStatus === 'false')) {
            this.validate(el, this.containData(el.value));
        }
        if (el.name === 'dealerCode') {
            if (el.value) {
                this.validate(el, this.isValidDealerCode(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'vinno') {
            if (el.value) {
                this.validate(el, this.containAlphabetAndDigitonly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'impactedVin') {
            if (el.value) {
                this.validate(el, this.containAlphabetAndDigitonly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'gvinno') {
            if (el.value) {
                this.validate(el, this.containAlphabetAndDigitonly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        } else if (el.name === 'maybachVin') {
            if (el.value) {
                this.validate(el, this.containAlphabetAndDigitonly(el.value));
            } else if (!el.value && fieldRequiredStatus === 'false') {
                this.displayDefault(el);
            }
        }
    }

    displayPopUp(message, title) {
        console.log(this);
        const popUp = document.querySelector(`#${ID.POPUP_ID}`);
        const mainContent = document.querySelector(`#${ID.CONTENT}`);
        const header = document.querySelector(`.${CLASSES.HEADER}`);
        if (mainContent) {
            mainContent.classList.add(`${CLASSES.UN_TRANSFORM}`);
        }
        if (header) {
            header.classList.add(`${CLASSES.HEADER_POSITION}`);
        }
        if (title) {
            popUp.querySelector('h3').innerHTML = title;
        } else {
            popUp.querySelector('h3').innerHTML = '&nbsp;';
        }
        popUp.querySelector(`.${CLASSES.POPUP__CONTENT_BODY}`).innerHTML = message;
        setTimeout(() => {
            popUp.classList.add(`${CLASSES.POPUP__CONTENT_OPEN}`);
        }, 500);
    }
}
