/**
 * Template for dog vehicle bodystyle explorer
 * Uses ES6 template literal to composite the markup for a dog vehicle bodystyle explorer container
 * @param summary {Array} the array of dog vehicle bodystyle explorer items
 */
import { html } from 'utils';
import TableHead from './table-head';

export default (item, formType) => html`
<div class="sort-table-tools button fepAndEab">
    <div class="entries-amount">
        <span>Show</span>
        <select class="select" id="recordCount">
            <option value="5">5</option>
            <option value="10" selected>10</option>
            <option value="15">15</option>
            <option value="20">20</option>
        </select>
        <span class="hide-mobile">Entries</span>
    </div>
    <button id="exportToExcel" class="button fepAndEab__button">Download Excel</button>
    <div class="search-container">
        <label for="search">Filter: </label>
        <input class="search" id="search" placeholder="Table Search"/>
    </div>
</div>
<div class="sort-table__table-section">
    <table>
        <thead>
            <tr>
                ${TableHead(Object.keys(item[0]), formType)}
            </tr>
        </thead>
        <tbody class="list">
        </tbody>
    </table>
</div>
<div class='pagination-container'>
    <p class="pagination-info"></p>
    <div class="pagination-wrap flex">
        <a class="pagination-prev disabled" href="#">Prev</a>
        <ul class="pagination-layout"></ul>
        <a class="pagination-next" href="#">Next</a>
    </div>
</div>
`.trim();
