
import Ajax from 'modules/Ajax';

const ID = {
    DISPLAY_SECTION: 'readOnlyScreen'

};

let displayData = '';
let values = '';

export default class UISReadOnlyScreen {
    constructor(element) {
        this.element = element;
        this.postAjax = new XMLHttpRequest();
        this.init();
    }

    init() {
        this.loadModules();
        this.ajaxcall();
    }


    loadModules() {
        this.Ajax = new Ajax(this.element);
    }


    ajaxcall() {
        // document.getElementById('brc-field-cast-report').style.display = 'none';
        // const siteName = 'brcFieldCastSubmissionReport';
        const param = new URLSearchParams(window.location.search);
        const position = param.get('ref');
        const url = '/bin/microsites/uis-form';
        const option = 'display';
        const data = position;
        const filedata = {
            option,
            data
        };

        this.Ajax.ajaxPostFn(url, filedata, (success) => {
            setTimeout(() => {
                const reportList = JSON.parse(success);
                const reportListNew = JSON.parse(reportList);
                displayData = reportListNew;

                // document.getElementById('loader').style.display = 'none';
               // document.getElementById('mblease-listing-loader').style.display = 'none';
                // document.getElementById('brc-field-cast-report').style.display = 'block';
                // document.getElementById('brc-field-cast-report').style.width = '100%';
                this.display(displayData);
            }, 5000);
        });
    }

    display(formData) {
        console.log(this);
        const resultDisplay = document.getElementById(`${ID.DISPLAY_SECTION}`);

        const generalInfo = formData.generalInfo;
        const modificationOverview = formData.modificationOverview;

        const specifications = formData.specifications;

        const modificationsInterior = formData.modificationsInterior;


        // const comments = '';
        // const attachments = '';


        const company = generalInfo.company;
        const date = generalInfo.date;
        const emailId = generalInfo.emailId;
        const position = generalInfo.position;
        const name = generalInfo.name;
        const phoneNumber = generalInfo.phoneNumber;

        const gvwr = modificationOverview.gvwr;
        const projectSalesNumber = modificationOverview.projectedSalesNumbers;
        const topicOfUis = modificationOverview.topicOfUis;
        const upfitType = modificationOverview.upfitType;
        const vehicleType = modificationOverview.vehicleType;
        const sampleVin = modificationOverview.sampleVin;

        const height = specifications.height;
        const length = specifications.length;
        const width = specifications.width;
        const rearAxleLoad = specifications.rearAxleLoad;
        const centerOfGravity = specifications.centerOfGravity;
        const uvwAfterUpfit = specifications.uvwAfterUpfit;
        const frontAxleLoad = specifications.frontAxleLoad;


        values = `<div class="read-only-screen__layout">
        <div class="read-only-screen__display">
        <div class="read-only-screen__design">
            <div class="read-only-screen__group">
                <h3 class="read-only-screen__heading">General Info</h3>

            </div>

            <div class="read-only-screen__group">
            <div class="read-only-screen__group1">
            <label class="read-only-screen__label">Name</label>
            <label class="read-only-screen__label1" >${name}</label>
            </div>

            <div class="read-only-screen__group1">
             <label class="read-only-screen__label">Company</label>
            <label class="read-only-screen__label1" >${company}</label>
            </div>

             <div class="read-only-screen__group1">
             <label class="read-only-screen__label">Position</label>
             <label class="read-only-screen__label1">${position}</label>
             </div>

            </div>
            <br>

            <div class="read-only-screen__group">
            <div class="read-only-screen__group1">
             <label class="read-only-screen__label">Email Address</label>
             <label class="read-only-screen__label1">${emailId}</label>
             </div>

             <div class="read-only-screen__group1">
             <label class="read-only-screen__label">Phone Number</label>
             <label class="read-only-screen__label1">${phoneNumber}</label>
             </div>

<div class="read-only-screen__group1">
             <label class="read-only-screen__label">Date</label>
             <label class="read-only-screen__label1">${date}</label>
             </div>

              </div> </div></div>
              <div class="read-only-screen__display">
    <div class="read-only-screen__design">
  <div class="read-only-screen__group">
   <h3 class="read-only-screen__heading">Modification Overview</h3>
    </div>
   <div class="read-only-screen__group">
   <div class="read-only-screen__group2">
    <label class="read-only-screen__label">Topic of the UIS</label>
    <label class="read-only-screen__label1">${topicOfUis}</label>
    </div>

<div class="read-only-screen__group2">
    <label class="read-only-screen__label">Projected Sales Numbers</label>
    <label class="read-only-screen__label1">${projectSalesNumber}</label>
    </div>

<div class="read-only-screen__group2">
    <label class="read-only-screen__label">Vehicle Type</label>
    <label class="read-only-screen__label1">${vehicleType}</label>
    </div>


<div class="read-only-screen__group2">
    <label class="read-only-screen__label">Sample VIN</label>
    <label class="read-only-screen__label1">${sampleVin}</label>
    </div>


<div class="read-only-screen__group2">
    <label class="read-only-screen__label">Upfit Type</label>
    <label class="read-only-screen__label1">${upfitType}</label>
    </div>
    
    <div class="read-only-screen__group2">
   <label class="read-only-screen__label">GVWR</label>
   <label class="read-only-screen__label1">${gvwr}</label>
   </div>

     </div>
</div>
 </div>

 <div class="read-only-screen__display">
<div class="read-only-screen__design">

  <div class="read-only-screen__group">
   <h3 class="read-only-screen__heading">Specification</h3>
  </div>
  <div class="read-only-screen__group">
    <p style="font-size: 15px">Please provide all known specifications. To add a specification, check the appropriate box.</p>
    </div>
    <div class="read-only-screen__group">
   <p style="font-size: 15px"><b>Your request cannot be approved until all specs are known.</b></p>
   </div>
    <div class="read-only-screen__group">
<table  style="width:95%">
<tr>
<td class="read-only-screen__group1">
    <label class="read-only-screen__label">Length (inches)</label>
    <label class="read-only-screen__label1">${length}</label>

</td>
<td class="read-only-screen__group1">
     <label class="read-only-screen__label">Width (inches)</label>
     <label class="read-only-screen__label1">${width}</label>
  </td>
<td class="read-only-screen__group1">
    <label class="read-only-screen__label">Height (inches)</label>
    <label class="read-only-screen__label1">${height}</label>

</td>
<td class="read-only-screen__group1">
     <label class="read-only-screen__label">Center of Gravity (inches)</label>
     <label class="read-only-screen__label1">${centerOfGravity}</label>
     
</td>
</tr>
<br>
<br>

<tr>
<td class="read-only-screen__group1">

     <label class="read-only-screen__label">Front Axle Load (Lbs)</label>
     <label class="read-only-screen__label1">${frontAxleLoad}</label>
 </td>
<td class="read-only-screen__group1">

     <label class="read-only-screen__label">Rear Axle Load (Lbs)</label>
     <label class="read-only-screen__label1">${rearAxleLoad}</label>
</td>

<td class="read-only-screen__group1">
     <label class="read-only-screen__label">UVW After Upfit (Lbs)</label>
     <label class="read-only-screen__label1">${uvwAfterUpfit}</label>
     
</td>
</tr>
     </table>
     </div>
 </div>
</div>`;
        values += `<div class="read-only-screen__display">
            <div class="read-only-screen__design">
            <div class="read-only-screen__group">
            <h3 class="read-only-screen__heading1">Modifications:Interior</h3>
            </div>`;
        Object.keys(modificationsInterior).forEach((key) => {
            values += `<label class="read-only-screen__label">${key}</label>
                    <label class="read-only-screen__label1">${modificationsInterior[key]}</label>`;
        });
        resultDisplay.innerHTML = values;
    }
}
