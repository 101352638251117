/**
 * Template for a Preferred Dealer info.
 * Uses ES6 template literal to composite the markup for a Preferred Dealer
 */
import { htmlNode } from 'utils';

function getDealerUrl(dealer) {
    const prependToUrl = 'http://';
    const url = dealer.scheduleServiceUrl && dealer.scheduleServiceUrl !== 'NO_SCHEDULE_SERVICE_URL' ? dealer.scheduleServiceUrl : dealer.url;
    if (url.indexOf('https') >= 0) {
        return url.replace('https', 'http');
    }
    return url.indexOf('http') < 0 ? prependToUrl.concat(url) : url;
}

function displayDealerName(dealer) {
    if (dealer.url || (dealer.scheduleServiceUrl && dealer.scheduleServiceUrl !== 'NO_SCHEDULE_SERVICE_URL')) {
        return `<a href="${getDealerUrl(dealer)}" class="preferred-dealer__dealer-name link link_bold" target="_blank" rel="noopener">${
            dealer.name
        }</a>`;
    }
    return `<p class="preferred-dealer__dealer-name-label">${dealer.name}</p>`;
}

export default ({
  dealer = {},
  content,
  disableChange = false,
  showMap,
}) => htmlNode`
    <div>
        <div class="preferred-dealer-plugin__sub-heading-container">
            <h3 class="preferred-dealer-plugin__sub-heading">${
              content.preferredDealerHeading
            }</h3>
            <p class="preferred-dealer-plugin__sub-heading-copy dealer-results__sub-heading-copy ${
              disableChange ? 'hide' : ''
            }">
                <a href="#" class="link link_plain-link" data-change-search-cta role="presentation" data-analytics-trigger="change-zip">
                    ${content.changeDealer}
                </a>
            </p>
        </div>
        <div class="preferred-dealer" data-analytics-container="contact-dealer-selected:${
          dealer.name
        }:${dealer.dealerId}">
            <address class="preferred-dealer__address-container-dealer" tabindex="0">
                <div tabindex="0">
                ${displayDealerName(dealer)}
                </div>
                <div tabindex="0" class="preferred-dealer__address preferred-dealer__address--main">${
                  dealer.address
                }, ${dealer.city}, ${dealer.state}, ${dealer.zip}</div>
                <div tabindex="0">
                    <a href="tel:${dealer.phone}
                        " class="preferred-dealer__phone-number">${dealer.phoneFormatted}
                    </a>
        </div>
        </address>
        ${
            showMap
            ? '<div class="preferred-dealer__map" data-dealer-map></div>'
            : ''
        }
        </div>
    </div>
`;

