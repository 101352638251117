import { EVENTS, ATTRIBUTES } from 'Constants';
import Ajax from 'modules/Ajax';

const CONSTANTS = {

    // CLASSES
    FAVOURITES_ROW: 'brc-favorite-list__border_rule',
    BRC_FAVOURITE_TABLE: 'brc-favourites__table-container',
    ALPHABETICAL_SEARCH__INDEX__COLUMN: 'brc-favourites__index__column',
    ALPHABETICAL_SEARCH__ROW: 'brc-favourites__row',
    ALPHABETICAL_SEARCH__INDEX_DISABLED: 'brc-favourites__index--disabled',
    STAR_RED: 'brc-favourites__star-red',
    STAR_BLANK: 'brc-favourites__star-icon'

};

const ID = {
    FAV: 'fav',
    FAV_ICON: 'fav_icon',
    SITE_NAME: 'site-name',
    BRC_FAVOURITES: 'brc-favourites',
    DISPALY_FAVORITE_LIST: 'brcFav-listing',
    FAVOURITE_ERROR: 'favouriteError',
    REPORT_ROW: 'reportRow',
    COL1_TEXT: 'col1Text',
    COL2_TEXT: 'col2Text',
    COL3_TEXT: 'col3Text',
    COL4_TEXT: 'col4Text',
    COL3_LINK: 'col3link',
    POPUP_ID: 'pop-up-id',
    CONTENT: 'content'
};

export default class BrcFavourite {
    constructor(element) {
        this.element = element;
        this.indexList = null;
        this.rowList = null;
        this.init();
        this.postAjax = new XMLHttpRequest();
    }

    init() {
        this.siteName = document.getElementById(`${ID.SITE_NAME}`).value;
        this.url = '/bin/microsites/brc-favourites';
        this.loadModules();
        this.cacheDOMElement();
        this.attachEvents();
    }

    loadModules() {
        this.Ajax = new Ajax(this.element);
    }

    cacheDOMElement() {
        this.indexList = this.element.getElementsByClassName(`${CONSTANTS.ALPHABETICAL_SEARCH__INDEX__COLUMN}`);
        this.rowList = this.element.getElementsByClassName(`${CONSTANTS.ALPHABETICAL_SEARCH__ROW}`);
    }

    attachEvents() {
        const componentId = this.element.id;
        this.element.addEventListener(EVENTS.CLICK, (e) => {
            if (e.target.matches(`#${ID.FAV}`) && e.target.getAttribute('data-getId') === componentId) {
                e.preventDefault();
                const col1text = e.target.getAttribute('data-col1Text');
                const col3link = e.target.getAttribute('data-col3Link');
                if (e.target.classList.contains(`${CONSTANTS.STAR_BLANK}`)) {
                    e.target.classList.remove(`${CONSTANTS.STAR_BLANK}`);
                    e.target.classList.add(`${CONSTANTS.STAR_RED}`);
                    const formData = {
                        col1text,
                        col3link,
                    };
                    formData.siteName = this.siteName;
                    formData.option = 'add';
                    this.Ajax.ajaxPostFn(this.url, formData, (success) => {
                        if (success === 'true') {
                            const favId = `fav${e.target.parentElement.parentElement.id}`;
                            const resultDisplay = document.getElementById(`${ID.DISPALY_FAVORITE_LIST}`);
                            if (resultDisplay.getElementsByClassName(`${CONSTANTS.FAVOURITES_ROW}`).length === 0) {
                                document.getElementById(`${ID.FAVOURITE_ERROR}`).style.display = 'none';
                            }
                            const newData = `<tr id="${favId}" class="brc-favorite-list__border_rule"><td input class="alphabetical-search__star-red" type="checkbox" data-href="${col3link}" data-title="${col1text}"></td><td class="brc-favorite-list__td_padding"><a href="${col3link}" target="_blank" rel="noopener noreferrer">${col1text}</a></td></tr>`;
                            resultDisplay.innerHTML += newData;
                        }
                    });
                } else {
                    e.target.classList.remove(`${CONSTANTS.STAR_RED}`);
                    e.target.classList.add(`${CONSTANTS.STAR_BLANK}`);
                    const formData = {
                        col1text,
                        col3link,
                    };
                    formData.siteName = this.siteName;
                    formData.option = 'remove';
                    this.Ajax.ajaxPostFn(this.url, formData, (success) => {
                        if (success === 'true') {
                            const favId = `fav${e.target.parentElement.parentElement.id}`;
                            const favElement = document.getElementById(favId);
                            favElement.remove();
                            if (document.getElementsByClassName(`${CONSTANTS.FAVOURITES_ROW}`).length === 0) {
                                document.getElementById(`${ID.FAVOURITE_ERROR}`).style.display = 'block';
                            }
                        }
                    });
                }
            }
        });

        const rowListItems = this.rowList;
        const indexListItems = this.indexList;
        let targetChar = `${rowListItems[0].firstElementChild.nextElementSibling.innerHTML.charAt(0).toUpperCase()}`;
        let targets = targetChar;
        rowListItems[0].id = rowListItems[0].id.concat(`target${targetChar}`);
        [].forEach.call(rowListItems, (el) => {
            const firstElement = el.firstElementChild.nextElementSibling;
            const firstChar = firstElement.innerHTML.charAt(0).toUpperCase();
            if (targetChar !== firstChar) {
                targetChar = firstChar;
                el.id = el.id.concat(`target${targetChar}`);
                targets += targetChar;
            }
        });
        this.initializeIndexReferences(indexListItems, targets);
    }

    initializeIndexReferences(indexList, targets) {
        console.log(this);
        [].forEach.call(indexList, (el) => {
            if (targets.indexOf(el.firstElementChild.innerHTML) > -1) {
                el.firstElementChild.setAttribute(ATTRIBUTES.HREF, '#'.concat(el.id.concat(`target${el.firstElementChild.innerHTML}`)));
            } else {
                const e = el.getElementsByTagName('a')[0];
                const d = document.createElement('span');
                d.innerHTML = e.innerHTML;
                e.parentNode.replaceChild(d, e);
                el.firstElementChild.classList.add(`${CONSTANTS.ALPHABETICAL_SEARCH__INDEX_DISABLED}`);
            }
        });
    }
}
