import React, { Component } from "react";

class LoaderSpinnerReact extends Component {
    render() {
        return (
            <div
                className="loader-spinner-react"
                style={{
                    height: this.props.height,
                    width: this.props.height,
                    border: `${this.props.border} solid transparent`,
                    borderTop: `${this.props.border} solid white`,
                    borderRight: `${this.props.border} solid white`,
                    borderLeft: `${this.props.border} solid white`,
                }}
            ></div>
        );
    }
}
export default LoaderSpinnerReact;
